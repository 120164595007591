<template>
  <div>
    <p class="uppercase font-medium text-gray-400 text-xs py-2 px-4 h-8">{{ title }}</p>
    <div
      v-for="(menuItem, index) in items"
      :key="index"
      class="flex flex-col"
      @click="onMenuItemClick(menuItem)"
    >
      <mega-menu-column-item
        :description="menuItem.description"
        :icon="menuItem.icon"
        :secondary-column="secondaryColumn"
        :storyblok-link="menuItem.url"
        :subtitle="menuItem.subtitle"
        :title="menuItem.title"
      />
    </div>
  </div>
</template>
<script>
import MegaMenuColumnItem from '~/components/section/mega-menu/MegaMenuColumnItem.vue';

export default {
  name: 'MegaMenuColumn',
  components: { MegaMenuColumnItem },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    secondaryColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['menuItemClick'],
  methods: {
    onMenuItemClick(menuItem) {
      this.$emit('menuItemClick', menuItem);
    },
  },
};
</script>
