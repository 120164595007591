<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="value-prop-cards pt-16 pb-10 md:py-20 bg-white overflow-hidden"
  >
    <div class="max-w-screen-lg mx-auto px-4">
      <div
        class="content-prop-cards__grid bg-no-repeat bg-contain -m-4 p-4 grid md:grid-cols-4 gap-6"
      >
        <div
          v-for="(card, index) in blok.cards"
          :key="index"
          class="bg-white shadow-lg rounded px-4 sm:px-16 py-6 sm:py-8 pb-6 text-left col-span-2"
          :class="{
            'md:col-start-2':
              index === blok.cards.length - 1 &&
              Number.isInteger((blok.cards.length - 1) / 2),
          }"
        >
          <h1 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
            {{ card.title }}
          </h1>
          <RichText :blok="card.content" class="my-3" />
          <nuxt-link
            v-if="card.href.cached_url"
            :id="card.linkId"
            class="text-rendin-500 font-bold underline"
            :to="$localizedPath(card.href.cached_url)"
          >
            {{ card.linkLabel }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentCards',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
