<template>
  <div
    v-if="(!putProfileInProgress && !componentSizeIsSmall) || componentSizeIsSmall"
    class="tenant-background-check-tab px-4 sm:px-7"
    :class="{ 'py-6': !componentSizeIsSmall }"
  >
    <div v-if="showInput">
      <div
        v-if="!isDataFilled"
        class="mt-2"
        :class="{
          'text-sm': componentSizeIsSmall,
        }"
      >
        <h2
          v-if="
            isAgreementInvite &&
            blok &&
            blok.titleAgreementInvite &&
            !componentSizeIsSmall
          "
          class="font-bold text-xl"
        >
          {{ blok.titleAgreementInvite }}
        </h2>
        <h2
          v-else-if="blok && blok.title && !componentSizeIsSmall"
          class="font-bold text-xl"
        >
          {{ blok.title }}
        </h2>
        <RichText
          v-if="
            blok &&
            typeof blok.contentRichText === 'object' &&
            blok.contentRichText.content.length > 0 &&
            blok.contentRichText.content[0].content
          "
          :blok="blok.contentRichText"
          class="text-justify"
        />
        <div v-if="blok && blok.readMoreNeeded" class="mb-4">
          <r-button
            color="orange"
            :label="blok.readMoreLabel || $t('read_more')"
            no-background
            no-padding
            @click="showOverlay = true"
          />
        </div>

        <div
          v-for="prop in blok.disclaimerWithIcon"
          :key="prop._uuid"
          class="flex flex-row items-center gap-4 mb-4"
        >
          <div v-if="prop.icon" class="inline-block">
            <component
              :is="theBlok.component"
              v-for="theBlok in prop.icon.filter((item) =>
                ['IconBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />
          </div>
          <div>
            <h3 v-if="prop.title" class="font-semibold text-lg leading-5">
              {{ prop.title }}
            </h3>
            <RichText
              v-if="prop.richTextTitle"
              :blok="prop.richTextTitle"
              class="flex-grow"
            />
          </div>
        </div>
      </div>

      <VeeForm
        ref="validator.personalIdCodeForm"
        v-slot="{ meta: formMeta, handleSubmit }"
      >
        <form
          autocomplete="off"
          @submit.prevent="handleSubmit(() => submitPersonalIdCode(formMeta.valid))"
        >
          <div class="grid md:grid-cols-3 gap-4 items-end">
            <div class="md:col-span-2">
              <Field
                v-slot="{ meta }"
                v-model="personalIdCode"
                rules="required|idCode"
                name="personalIdCode"
              >
                <r-input
                  id="personalIdCode"
                  v-model="personalIdCode"
                  :disabled="isDataFilled"
                  :error-text="$t('form.validation.id_code.' + $getCountry())"
                  field-type="text"
                  for="personalIdCode"
                  :label="
                    blok.idCodeLabelOverwrite ||
                    $t('form.label.id_code.' + $getCountry())
                  "
                  name="personalIdCode"
                  :placeholder="$t('form.placeholder.id_code.' + $getCountry())"
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                />
              </Field>
            </div>
            <div class="pt-1 md:pt-7">
              <r-sticky-container :reserve-space="false" :sticky="stickyPrimaryButton">
                <r-button
                  v-if="!isDataFilled && !isLoading"
                  :is-loading="putProfileInProgress"
                  :label="$t('buttons.continue')"
                  size="small"
                  type="submit"
                  width="full"
                />
              </r-sticky-container>
            </div>
          </div>
        </form>
      </VeeForm>
    </div>
    <BackgroundCheckSmallLabel
      v-if="backgroundCheckHaveResult"
      :background-check-approved-label="blok.backgroundCheckApprovedLabel"
      :background-check-declined-label="blok.backgroundCheckDeclinedLabel"
      :background-check-declined-modal-link="blok.backgroundCheckDeclinedModalLink"
      :background-check-pending-label="blok.backgroundCheckPendingLabel"
      :background-check-pending-modal-link="blok.backgroundCheckPendingModalLink"
      :component-size="componentSize"
      :score="score || profile.scoreStatus"
    />

    <r-overlay
      bottom-bar
      size="small"
      :visible="showOverlay"
      @close="showOverlay = false"
    >
      <div class="p-4 md:px-8 pt-12">
        <h2 v-if="blok && blok.title" class="font-bold text-2xl text-center mb-6">
          {{ blok.secondTitle }}
        </h2>
        <RichText
          v-if="
            blok &&
            typeof blok.secondContentRichText === 'object' &&
            blok.secondContentRichText.content.length > 0 &&
            blok.secondContentRichText.content[0].content
          "
          :blok="blok.secondContentRichText"
          class="text-justify"
        />
      </div>
      <template #bottom-bar>
        <div class="px-4 py-2">
          <r-button
            display="block"
            :label="blok.readMoreCloseButtonLabel || $t('buttons.close')"
            :no-background="!blok.readMoreCloseButtonLabel"
            @click="showOverlay = false"
          />
        </div>
      </template>
    </r-overlay>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { ComponentSize } from '~/utils/constants';
import RStickyContainer from '~/components/r-interface-components/r-sticky-container';
import { Form as VeeForm, Field } from 'vee-validate';

export default {
  name: 'TenantBackgroundCheckTab',
  components: { RStickyContainer, VeeForm, Field },
  props: {
    loading: {
      type: Boolean,
    },
    blok: {
      type: Object,
      default: null,
    },
    componentSize: {
      type: String,
      default: ComponentSize.SMALL,
    },
    stickyPrimaryButton: {
      type: Boolean,
      default: false,
    },
    score: {
      type: String,
      default: null,
    },
    isAgreementInvite: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submitIdCode'],

  async setup() {
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return {
      getAnalyticEventNames,
      trackTenantProfileEvent,
    };
  },
  data: () => ({
    validateEventTriggered: false,
    personalIdCode: '',
    scoreStatus: null,
    isDataFilled: false,
    isLoading: true,
    idCodeSubmitted: false,
    profilePersonalIdCode: {
      personalIdCode: undefined,
    },
    componentSizes: ComponentSize,
    cutContent: false,
    showOverlay: false,
  }),
  computed: {
    ...mapGetters({
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),

    componentSizeIsSmall() {
      return this.componentSize === ComponentSize.SMALL;
    },

    showInput() {
      return !(this.isDataFilled && this.componentSize === ComponentSize.MEDIUM);
    },

    backgroundCheckHaveResult() {
      return this.profile && this.profile.scoreStatus && this.profile.idCode;
    },
  },
  watch: {
    profile(profileFromFirebase) {
      if (profileFromFirebase) this.setPersonalIdCodeAndScoreData(profileFromFirebase);
    },
  },

  mounted() {
    this.setPersonalIdCodeAndScoreData(this.profile);
  },

  beforeUnmount() {
    clearInterval(this.pollProfileScoring);
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
      fetchProfile: 'users/fetchProfile',
    }),
    setPersonalIdCodeAndScoreData(profileFromFirebase) {
      if (profileFromFirebase) {
        this.personalIdCode = profileFromFirebase.idCode
          ? profileFromFirebase.idCode
          : '';
        this.scoreStatus = profileFromFirebase.scoreStatus
          ? profileFromFirebase.scoreStatus
          : '';
        if (profileFromFirebase.idCode) this.isDataFilled = true;
        this.isLoading = false;
      }
    },

    async submitPersonalIdCode(isValid) {
      if (!isValid) return;
      /**
       * Submit ID code event
       * Indicates that user has submitted ID code for background check
       *
       * @event loginStart
       * @type {object}
       */
      this.$emit('submitIdCode');
      this.profilePersonalIdCode.personalIdCode = this.personalIdCode;
      await this.actionPutProfile({
        id: this.profile.id,
        profile: { ...this.profilePersonalIdCode },
      });
      this.trackTenantProfileEvent({
        eventName: this.getAnalyticEventNames.TENANT_PROFILE_SUBMIT_BACKGROUND_CHECK,
      });
      this.idCodeSubmitted = true;

      this.pollProfileScoring();

      return true;
    },

    // TODO we need better solution for watching server changes
    pollProfileScoring() {
      let times = 1;
      this.interval = setInterval(() => {
        if (times < 10) {
          this.fetchProfile();
          times++;
        }
      }, 2000);
    },
  },
};
</script>
