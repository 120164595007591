<template>
  <Field
    ref="validator"
    v-slot="{ meta, setTouched }"
    v-model="objectAreaModel"
    validate-on-mount
    name="object-area"
    :rules="{ required: true, isFloat: true }"
  >
    <div
      class="mx-auto text-center px-4 my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
    </div>

    <div
      class="r-fade--delay--1 max-w-sm mx-auto px-4"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <r-input
        v-model="objectAreaModel"
        add-on="m2"
        field-type="tel"
        :focus-on-load="true"
        inputmode="decimal"
        :label="$t('form.label.area')"
        label-class="text-xs sm:text-base"
        name="object-area"
        :placeholder="$t('form.placeholder.area')"
        required
        :validation-failed="meta.touched && !meta.valid"
        :validation-passed="meta.valid"
        @input="setTouched(true)"
        @blur="setTouched(true)"
      />
      <div class="h-14 mt-2 mb-4">
        <div
          v-if="meta.valid"
          class="flex justify-center items-center"
          :class="{ 'r-fade': initialLoad }"
        >
          <r-button
            :id="submitButtonId"
            color="orange"
            :is-loading="requestInProgress"
            @click="meta.valid && !requestInProgress ? $emit('goToNextStep') : null"
          >
            <r-icon class="mr-1 text-lg" icon-name="check-circle" />
            <span class="font-medium">{{ nextButtonLabel }}</span>
          </r-button>
          <div class="flex items-center text-sm mx-2">
            <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
            <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
          </div>
        </div>
      </div>
    </div>
  </Field>
</template>
<script>
import { Field } from 'vee-validate';

export default {
  name: 'StepSelectArea',
  components: { Field },
  props: {
    requestInProgress: {
      type: Boolean,
      required: true,
    },
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    objectArea: {
      type: Number,
      required: false,
      default: null,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
  },
  emits: ['update:objectArea', 'goToNextStep'],
  async setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
    };
  },
  computed: {
    objectAreaModel: {
      get() {
        return this.objectArea;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.$emit('update:objectArea', normalized || null);
      },
    },
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    async submit() {
      const validationResult = await this.validate();
      if (validationResult && !this.requestInProgress) {
        this.$emit('goToNextStep');
      }
    },
    async validate() {
      const validationResult = await this.$refs.validator.validate();
      return validationResult.valid;
    },
  },
};
</script>
