<template>
  <div>
    <div class="bg-gray-50 p-4">
      <div
        v-for="service in sortedServicesAndUtilitiesObject"
        :key="service.type"
        class="py-1"
      >
        <r-checklist-item
          v-model="service.active"
          :data-testid="'component.service-and-utility-checklist.' + service.type"
          :title="servicesAndUtilitiesName(service.type, service.typeName)"
          @input="
            (event) => {
              servicesAndUtilitiesObject[service.key] = {
                ...servicesAndUtilitiesObject[service.key],
                active: event,
              };
            }
          "
        />
      </div>
      <div
        class="mt-2 w-full shadow-sm border rounded-lg p-1 bg-white"
        :class="{ 'p-4': !isAddingService }"
      >
        <div
          v-if="!isAddingService"
          class="hover:text-rendin-500"
          @click="isAddingService = true"
        >
          <r-icon class="pr-2" icon-name="plus" />
          <span> {{ $t('services.label.add_new_service') }}</span>
        </div>
        <r-input
          v-else
          v-model="newServiceName"
          focus-on-load
          maxlength="24"
          name=""
          no-border
          @enter="addCustomServiceToList"
        />
      </div>
    </div>
    <div class="flex justify-center">
      <r-sticky-container sticky>
        <r-button
          v-if="!isAddingService"
          class="w-full sm:w-max"
          data-testid="button.service-and-utility.save-list"
          :is-loading="isLoadingSaveButton"
          width="wide"
          @click="isLoadingSaveButton ? null : emitChoice()"
        >
          {{ $t('buttons.continue') }}
        </r-button>
        <div v-else class="flex flex-row justify-center gap-6 w-full">
          <r-button
            class="w-full sm:w-max"
            inverted
            width="wide"
            @click="cancelAddingCustomService"
          >
            {{ $t('buttons.back') }}
          </r-button>
          <r-button
            class="w-full sm:w-max"
            width="wide"
            @click="addCustomServiceToList"
          >
            {{ $t('buttons.save') }}
          </r-button>
        </div>
      </r-sticky-container>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import {
  defaultEstoniaServicesAndUtilities,
  defaultPolandServicesAndUtilities,
  defaultServicesAndUtilitiesOtherObject,
} from '~/utils/objectStructures';
import { administrativeFeePaymentDetermination, workflow } from '~/utils/constants';
import { servicesAndUtilitiesNames } from '~/composables/servicesAndUtilitiesNames';

export default {
  name: 'AgreementUtilitiesChecklist',
  props: {
    agreementWorkflow: {
      type: String,
      default: '',
    },
    agreementId: {
      type: String,
      default: '',
    },
    isLoadingSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateList'],
  data() {
    return {
      servicesAndUtilitiesObject: [],
      newServiceName: '',
      isAddingService: false,
    };
  },
  computed: {
    sortedServicesAndUtilitiesObject() {
      return this.servicesAndUtilitiesObject.sort((a, b) =>
        this.servicesAndUtilitiesName(a.type, a.typeName).localeCompare(
          this.servicesAndUtilitiesName(b.type, b.typeName),
        ),
      );
    },

    isPoland() {
      return this.agreementWorkflow === workflow.POLAND;
    },
  },

  mounted() {
    this.setServicesAndUtilitiesObject();
  },
  methods: {
    servicesAndUtilitiesName(key, name) {
      return servicesAndUtilitiesNames(key, name);
    },

    transformSourceToLocalUtilityAndService(service, active) {
      const localStructureService = {
        active: active,
        whoIsPaying: service.whoIsPaying,
        typeName: service.typeName,
        type: service.type,
      };
      return localStructureService;
    },

    transformLocalToSourceUtilityAndService(service) {
      const sourceStructureService = {
        agreementFirebaseId: this.agreementId,
        whoIsPaying: service.whoIsPaying,
        typeName: service.typeName,
        type: service.type,
        payment: null,
        paymentMethod: administrativeFeePaymentDetermination.DYNAMIC,
      };
      return sourceStructureService;
    },

    setServicesAndUtilitiesObject() {
      const data = [];
      const defaultList = this.isPoland
        ? defaultPolandServicesAndUtilities()
        : defaultEstoniaServicesAndUtilities();
      for (const keyItem of Object.keys(defaultList)) {
        data.push(
          this.transformSourceToLocalUtilityAndService(
            defaultList[keyItem],
            !this.isPoland,
          ),
        );
      }
      this.servicesAndUtilitiesObject = data;
    },

    addCustomServiceToList() {
      if (!this.newServiceName) {
        return this.cancelAddingCustomService();
      }
      const customServiceAndUtility = {
        ...defaultServicesAndUtilitiesOtherObject(),
        typeName: this.newServiceName,
        active: true,
        type: `OTHER_${uuidv4()}`,
      };

      this.servicesAndUtilitiesObject.push(customServiceAndUtility);
      this.cancelAddingCustomService();
    },

    cancelAddingCustomService() {
      this.newServiceName = '';
      this.isAddingService = false;
    },

    emitChoice() {
      const serviceAndUtilitiesActive = [];
      for (const service of this.servicesAndUtilitiesObject) {
        if (service.active) {
          serviceAndUtilitiesActive.push(
            this.transformLocalToSourceUtilityAndService(service),
          );
        }
      }
      this.$emit('updateList', serviceAndUtilitiesActive);
    },
  },
};
</script>
