import { agreementSourceTypes } from '~/utils/constants.js';
import { fullListOfServicesAndUtilities } from '~/utils/objectStructures';

/**
 * @typedef {string} AgreementId
 */
/**
 * @typedef {string} AdditionalFeeId
 */

/**
 * @typedef {{
 *   firstName?: string,
 *   lastName?:  string,
 *   phoneNumber?:  string,
 *   email?:  string,
 * }} PropertyPublicContactPerson
 */

/**
 * @typedef {'EUR'|'USD'|'PLN'} Currency
 */

/**
 * @typedef {'PL'|'EE'|'EE_ONBOARDING_AGREEMENT'} WorkFlow
 */

/**
 * @typedef {'et_EE'|'pl_PL'|'en_GB'|'ru_RU'} EnabledLocale
 */

/**
 * @typedef {'FIXED'|'TERMLESS'} AgreementType
 */

/**
 * @typedef {string} DateISOString
 * @description Date.prototype.toISOString()
 */

/**
 * @typedef {{
 *   addressFirstLine?: string,
 *   addressCity?: string,
 *   addressCountry?: string,
 *   addressIndex?: string,
 *   objectArea?: number,
 *   numberOfRooms?: number,
 *   creatorProfileId?: string,
 *   agreementType?: string,
 *   kv24Id?: number,
 *   images?: Array<string>,
 *   rentAmount?: number,
 *   invitationId?: string,
 *   insuranceAmountYearly?: number,
 *   insuranceAmount?: number,
 *   currency?: Currency,
 *   hasParking?: boolean,
 *   hasStorage?: boolean,
 *   services?: Array<any>,
 *   petsAllowed?: boolean,
 *   fixedUtilitiesMonthly?: number,
 *   contact?: PropertyPublicContactPerson,
 *   parentType?: 'ADVERTISEMENT' | 'AGREEMENT' ,
 *   parentId?: string,
 *   shortCode?: string,
 *   active?: boolean,
 *   workflow?: WorkFlow,
 * }} AgreementGet
 */

/**
 * @typedef {{
 *   tenantRole: Object.<AgreementId, AgreementGet>[],
 *   landlordRole: Object.<AgreementId, AgreementGet>[],
 *   creatorRole: Object.<AgreementId, AgreementGet>[],
 *   candidateRole: Object.<AgreementId, AgreementGet>[],
 * }} AgreementsGet
 */

/**
 * @typedef {{
 *  addressFirstLine?: string,
 *  addressCity?: string,
 *  addressCountry?: string,
 *  addressIndex?: string,
 *  addressApartmentNumber?: string | null,
 *  addressBuildingExternalId?: string,
 *  addressHouseNumber?: string,
 *  addressStreet?: string,
 *
 *  tenantProfileId?: string,
 *  landlordProfileId?: string,
 *  coTenantProfiles?: string,
 *
 *  language?: EnabledLocale,
 *
 *  bankAccountNumber?: string,
 *  bankAccountOwnerName?: string,
 *  currency?: Currency,
 *
 *  contractType?: AgreementType,
 *
 *  rentAmount?: number,
 *
 *  hasAgentFee?: boolean,
 *  petsAllowed?: boolean,
 *  petsComment?: string,
 *  specialNotes?: string,
 *
 *  handoverDate?: DateISOString,
 *  paymentDay?: number,
 *  startDate?: DateISOString,
 *  endDate?: DateISOString,
 *
 *  deleted?: DateISOString,
 *
 *  objectArea?: number,
 *  numberOfRooms?: number,
 *  hasParking?: boolean,
 *  hasStorage?: boolean,
 *
 *  insuranceInvoiceTo?: string,
 *
 *  workflow?: WorkFlow,

 *  agreementType?: string,
 * }} AgreementSubmit
 */

/**
 * @typedef {{
 *   agreementId?: string,
 *   type?:  string,
 *   paymentInterval?:  string,
 *   amount?:  number,
 * }} AdditionalFeeSubmit
 */

/**
 * @typedef {{
 *   agreementId?: string,
 * }} ServicesAndUtilitiesSubmit
 */

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestGetAgreements = 'getAgreements';
const requestGetAgreement = 'getAgreement';
const requestPutAgreement = 'putAgreement';
const requestPostAgreement = 'postAgreement';
const requestPostAgreementCreateCopy = 'postAgreementCreateCopy';
const requestDeleteAgreement = 'deleteAgreement';
const requestCreateAgreementFromAdvertisement = 'createAgreementFromAdvertisement';
const requestPostAgreementAdditionalFee = 'postAgreementAdditionalFee';
const requestPostAgreementAdministrativeFee = 'postFeeAdministrative';
const requestDeleteAgreementAdditionalFee = 'deleteAgreementAdditionalFee';
const requestAgreementCoTenantAdd = 'agreementCoTenantAdd';
const requestAgreementCoTenantRemove = 'agreementCoTenantRemove';
const requestPutAgreementStatus = 'putAgreementStatus';
const requestDeleteAgreementServicesAndUtilities =
  'deleteAgreementServicesAndUtilities';
const requestPutFeeAdministrative = 'putFeeAdministrative';
const requestPostAgreementServiceAndUtility = 'postAgreementServiceAndUtility';
const requestPutAgreementServiceAndUtility = 'putAgreementServiceAndUtility';
const requestGetAgreementServicesAndUtilities = 'getAgreementServicesAndUtilities';
const requestGetAgreementValidation = 'getAgreementValidation';

const requests = [
  requestGetAgreements,
  requestGetAgreement,
  requestPutAgreement,
  requestPostAgreement,
  requestPostAgreementCreateCopy,
  requestDeleteAgreement,
  requestCreateAgreementFromAdvertisement,
  requestPostAgreementAdditionalFee,
  requestPostAgreementAdministrativeFee,
  requestDeleteAgreementAdditionalFee,
  requestAgreementCoTenantAdd,
  requestAgreementCoTenantRemove,
  requestPutAgreementStatus,
  requestDeleteAgreementServicesAndUtilities,
  requestPutFeeAdministrative,
  requestPostAgreementServiceAndUtility,
  requestPutAgreementServiceAndUtility,
  requestGetAgreementServicesAndUtilities,
  requestGetAgreementValidation,
];
// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = {
  ...setupMutations(requests),

  CLEAR_GET_AGREEMENT: function (state) {
    state.getAgreement = {
      response: null,
      inProgress: false,
      failed: false,
    };
  },
  CLEAR_GET_AGREEMENTS: function (state) {
    state.getAgreements = {
      response: null,
      inProgress: false,
      failed: false,
    };
  },
  CLEAR_PUT_AGREEMENT_STATUS: function (state) {
    state.putAgreementStatus = {
      response: null,
      inProgress: false,
      failed: false,
    };
  },
};

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @return {Promise<{
   *   response: AgreementsGet,
   *   failed: boolean,
   * }>}
   */
  getAgreements: function ({ dispatch }) {
    return dispatch('firebaseRequest', {
      request: requestGetAgreements,
      data: undefined,
      functionRequestOverride: 'getAgreement',
    });
  },

  /**
   * @param {AgreementId} id
   * @return {Promise<{
   *   response: AgreementGet,
   *   failed: boolean,
   * }>}
   */
  getAgreement: function ({ dispatch }, { id }) {
    return dispatch('firebaseRequest', {
      request: requestGetAgreement,
      data: { id: id },
    });
  },

  /**
   * @param {AgreementId} id
   * @param {AgreementSubmit} agreement
   * @param {ServicesAndUtilitiesSubmit} servicesAndUtilities
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  putAgreement: function (
    { dispatch },
    { id, agreement, removeTenant, removeLandlord },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPutAgreement,
      data: {
        request: {
          id: id,
          agreement: agreement,
          removeTenant,
          removeLandlord,
          source: agreementSourceTypes.WEB,
        },
      },
    });
  },

  /**
   * @param {AgreementSubmit} agreement
   * @return {Promise<{
   *   response: AgreementId,
   *   failed: boolean,
   * }>}
   */
  postAgreement: function ({ dispatch }, { agreement }) {
    return dispatch('firebaseRequest', {
      request: requestPostAgreement,
      data: { agreement: agreement },
    });
  },

  copyAgreement: function ({ dispatch }, { id }) {
    return dispatch('firebaseRequest', {
      request: requestPostAgreementCreateCopy,
      data: { id: id },
    });
  },

  deleteAgreement: function ({ dispatch }, { id }) {
    return dispatch('firebaseRequest', {
      request: requestDeleteAgreement,
      data: {
        id: id,
        source: agreementSourceTypes.WEB,
      },
    });
  },

  createAgreementFromAdvertisement: function ({ dispatch }, { id }) {
    return dispatch('firebaseRequest', {
      request: requestCreateAgreementFromAdvertisement,
      data: { id: id },
    });
  },

  /**
   * @param {AdditionalFeeSubmit} additionalFee
   * @return {Promise<{
   *   response: AgreementId,
   *   failed: boolean,
   * }>}
   */
  postAgreementAdditionalFee: function ({ dispatch }, additionalFee) {
    return dispatch('firebaseRequest', {
      request: requestPostAgreementAdditionalFee,
      data: additionalFee,
    });
  },

  /**
   * @param {AgreementId} agreementId
   * @param {AdditionalFeeId} additionalFeeId
   * @return {Promise<{
   *   failed: boolean,
   * }>}
   */
  deleteAgreementAdditionalFee: function (
    { dispatch },
    { agreementId, additionalFeeId },
  ) {
    return dispatch('firebaseRequest', {
      request: requestDeleteAgreementAdditionalFee,
      data: {
        agreementId: agreementId,
        additionalFeeId: additionalFeeId,
      },
    });
  },

  /**
   * @param {administrativeFeeSubmit} administrativeFee
   * @param {string} agreementId
   * @return {Promise<{
   *   response: AgreementId,
   *   failed: boolean,
   * }>}
   */
  postAdministrativeFee: function ({ dispatch }, { agreementId, administrativeFee }) {
    return dispatch('firebaseRequest', {
      request: requestPostAgreementAdministrativeFee,
      data: {
        request: {
          agreement: {
            id: agreementId,
            administrativeFee: {
              paymentIncludedInRent: administrativeFee.paymentIncludedInRent,
              payerRole: administrativeFee.payerRole,
              paymentDetermination: administrativeFee.paymentDetermination || undefined,
              paymentAmount: administrativeFee.paymentAmount || undefined,
              paymentFrequency: administrativeFee.paymentFrequency || undefined,
              paymentRecipient: administrativeFee.paymentRecipient || undefined,
            },
          },
          source: 'WEB',
        },
      },
    });
  },

  /**
   * @param {string} administrativeFeeId
   * @param {string} agreementId
   * @return {Promise<{
   *   response: AgreementId,
   *   failed: boolean,
   * }>}
   */
  deleteAdministrativeFee: function (
    { dispatch },
    { agreementId, administrativeFeeId },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPutFeeAdministrative,
      data: {
        request: {
          agreement: {
            id: agreementId,
            toDelete: {
              administrativeFeeId: administrativeFeeId,
            },
          },
          source: 'WEB',
        },
      },
    });
  },

  /**
   * @param {string} administrativeFee
   * @param {string} administrativeFeeId
   * @param {string} agreementId
   * @return {Promise<{
   *   response: AgreementId,
   *   failed: boolean,
   * }>}
   */
  putAdministrativeFee: function (
    { dispatch },
    { agreementId, administrativeFee, administrativeFeeId },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPutFeeAdministrative,
      data: {
        request: {
          agreement: {
            id: agreementId,
            toUpdate: {
              administrativeFeeId: administrativeFeeId,
              administrativeFee: {
                paymentIncludedInRent: administrativeFee.paymentIncludedInRent,
                payerRole: administrativeFee.payerRole,
                paymentDetermination:
                  administrativeFee.paymentDetermination || undefined,
                paymentAmount: administrativeFee.paymentAmount || undefined,
                paymentFrequency: administrativeFee.paymentFrequency || undefined,
                paymentRecipient: administrativeFee.paymentRecipient || undefined,
              },
            },
          },
          source: 'WEB',
        },
      },
    });
  },

  agreementCoTenantAdd: function ({ dispatch }, { agreementId, profileId }) {
    return dispatch('firebaseRequest', {
      request: requestAgreementCoTenantAdd,
      data: {
        id: agreementId,
        profileId: profileId,
      },
    });
  },

  agreementCoTenantRemove: function ({ dispatch }, { agreementId, profileId }) {
    return dispatch('firebaseRequest', {
      request: requestAgreementCoTenantRemove,
      data: {
        id: agreementId,
        profileId: profileId,
      },
    });
  },

  putAgreementStatus: function ({ dispatch }, { agreementId, toPreSigning }) {
    return dispatch('firebaseRequest', {
      request: requestPutAgreementStatus,
      data: {
        id: agreementId,
        toPreSigning: toPreSigning,
      },
    });
  },

  getAgreementServicesAndUtilities({ dispatch }, { agreementId }) {
    return dispatch('firebaseRequest', {
      request: requestGetAgreementServicesAndUtilities,
      data: {
        request: {
          agreementId: agreementId,
        },
      },
    });
  },

  postAgreementServiceAndUtility: function (
    { dispatch },
    { agreementId, serviceAndUtility },
  ) {
    const paymentInformation = serviceAndUtility.payment
      ? {
          paymentAmount: serviceAndUtility.payment.amount,
          paymentDetails: serviceAndUtility.payment.details,
        }
      : {};
    const serviceAndUtilityType =
      serviceAndUtility.type in fullListOfServicesAndUtilities()
        ? { type: serviceAndUtility.type }
        : { typeName: serviceAndUtility.typeName };
    return dispatch('firebaseRequest', {
      request: requestPostAgreementServiceAndUtility,
      data: {
        request: {
          agreementId: agreementId,
        },
        requestData: {
          whoIsPaying: serviceAndUtility.whoIsPaying,
          ...paymentInformation,
          ...serviceAndUtilityType,
        },
      },
    });
  },

  putAgreementServicesAndUtilities: function (
    { dispatch },
    { agreementId, serviceAndUtility },
  ) {
    const paymentInformation = serviceAndUtility.payment
      ? {
          paymentAmount: serviceAndUtility.payment.amount,
          paymentDetails: serviceAndUtility.payment.details,
        }
      : {};
    const customTypeName = !(serviceAndUtility.type in fullListOfServicesAndUtilities())
      ? { typeName: serviceAndUtility.typeName }
      : {};
    return dispatch('firebaseRequest', {
      request: requestPutAgreementServiceAndUtility,
      data: {
        request: {
          agreementId: agreementId,
          type: serviceAndUtility.type,
        },
        requestData: {
          whoIsPaying: serviceAndUtility.whoIsPaying,
          ...paymentInformation,
          ...customTypeName,
        },
      },
    });
  },

  deleteAgreementServicesAndUtilities: function ({ dispatch }, { agreementId, type }) {
    return dispatch('firebaseRequest', {
      request: requestDeleteAgreementServicesAndUtilities,
      data: {
        id: agreementId,
        type: type,
      },
    });
  },

  getAgreementValidation: function ({ dispatch }, { agreementId }) {
    return dispatch('firebaseRequest', {
      request: requestGetAgreementValidation,
      data: {
        request: {
          id: agreementId,
          returnErrorMessageType: 'ERROR_CODE',
          source: 'WEB',
        },
      },
    });
  },
};
// --------- IMPLEMENT DEFINED ACTIONS ---------
// ---------------------------------------------
