<template>
  <div v-editable="blok" class="flex-1 flex flex-col" :class="classes">
    <component
      :is="childBlok.component"
      v-for="childBlok in blok.content.filter((item) =>
        [
          'ButtonBlok',
          'RichTextBlok',
          'TextBlok',
          'ImageBlok',
          'AnimatedTypewriterBlok',
          'FeatureCheckmarkList',
          'Card',
          'RSpacer',
        ].includes(item.component),
      )"
      :key="childBlok._uid"
      :blok="childBlok"
    />
  </div>
</template>

<script>
import {
  ComponentFlexSpacing,
  Alignment,
  verticalAlignment,
} from '~/utils/constants.js';

export default {
  name: 'SectionBannerColumn',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      const classes = [];
      switch (this.blok.alignment) {
        case Alignment.LEFT:
          classes.push('items-start');
          break;
        case Alignment.CENTER:
          classes.push('items-center');
          break;
        case Alignment.RIGHT:
          classes.push('items-end');
          break;
        default:
          classes.push('items-center');
          break;
      }

      switch (this.blok.verticalPosition) {
        case verticalAlignment.TOP:
          classes.push('justify-start');
          break;
        case verticalAlignment.CENTER:
          classes.push('justify-center');
          break;
        case verticalAlignment.BOTTOM:
          classes.push(' justify-end');
          break;
        default:
          classes.push('justify-center');
          break;
      }

      switch (this.blok.spacing) {
        case ComponentFlexSpacing.NONE:
          break;
        case ComponentFlexSpacing.SMALL:
          classes.push('gap-2');
          break;
        case ComponentFlexSpacing.MEDIUM:
          classes.push('gap-4');
          break;
        case ComponentFlexSpacing.LARGE:
          classes.push('gap-6');
          break;
        default:
          classes.push('gap-4');
          break;
      }
      if (!this.blok.noPadding) {
        classes.push('p-4');
      }

      if (this.blok.hiddenInScreens && this.blok.hiddenInScreens.includes('mobile')) {
        classes.push('hidden md:flex');
      }
      if (this.blok.hiddenInScreens && this.blok.hiddenInScreens.includes('tablet')) {
        classes.push('md:hidden lg:flex');
      }
      if (this.blok.hiddenInScreens && this.blok.hiddenInScreens.includes('desktop')) {
        classes.push('lg:hidden');
      }
      return classes;
    },
  },
};
</script>
