<template>
  <section>
    <client-only>
      <r-overlay
        header-class="z-10"
        :size="ComponentSize.SMALL"
        :visible="showModal"
        @close="closeOverlay()"
      >
        <div class="mx-4 mt-1 flex flex-col h-full">
          <ProfilePrimarySetting
            :submit-button-loading="putProfileInProgress"
            submit-button-wrapper-class="sticky bottom-0 h-18 p-4 bg-white -mx-4"
            type="LANDLORD"
            @submitted-profile-data="(profileData) => putProfileData(profileData)"
          />
        </div>
      </r-overlay>
    </client-only>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ComponentSize, agreementTypes } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'ProfileChangeModal',

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ComponentSize: ComponentSize,
    openProfilePrimarySettings: false,
    agreementTypes: agreementTypes,
    trackingProcessNames: trackingProcessNames,
    agreementTrackingEventNames: agreementTrackingEventNames,
  }),

  computed: {
    ...mapGetters({
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
      actionGetAgreement: 'agreements/getAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.openProfilePrimarySettings = false;
      this.$emit('closeOverlay');
    },

    putProfileData(profileData) {
      if (!this.putProfileInProgress) {
        this.actionPutProfile({ profile: profileData, id: this.profile.id })
          .then((response) => {
            if (!response.failed)
              this.$toast.success(this.$t('profile_saved'), {
                position: 'top-center',
                timeout: 3000,
                hideProgressBar: true,
              });
          })
          .then(() => {
            this.actionGetAgreement({ id: this.agreementId }).then(() => {
              this.$emit('requestTabsValidation');
            });
          })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames
                .CREATOR_PROFILE_UPDATED_IN_AGREEMENT_PARTIES,
            ),
          )
          .finally(this.closeOverlay);
      }
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
