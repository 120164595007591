<template>
  <r-overlay size="small" :visible="showModal" @close="closeOverlay()">
    <div class="bg-peach-300 w-full h-72 flex justify-center items-center">
      <img :alt="currentViewContent.title" :src="currentViewContent.imgSrc" />
    </div>
    <div class="text-center p-4 mx-auto max-w-md">
      <div class="text-2xl my-4">
        {{ currentViewContent.title }}
      </div>
      <div class="text-gray-600 my-4">
        {{ currentViewContent.text }}
      </div>
    </div>
    <div class="flex justify-center p-4">
      <r-snake-animation
        :current-step="currentView + 1"
        food-background-color="bg-peach-300"
        :total-steps="totalViews"
      />
    </div>

    <template v-if="currentView === 2">
      <div class="p-4 mx-auto max-w-md w-full">
        <r-button
          :label="$t('buttons.share_agreement')"
          size="small"
          width="full"
          @click="emitNextButtonClick()"
        />
      </div>
    </template>
    <template v-else>
      <div class="flex gap-4 p-4 mx-auto max-w-md w-full">
        <r-button
          inverted
          :label="$t('buttons.skip')"
          size="small"
          width="full"
          @click="emitNextButtonClick()"
        />
        <r-button
          icon="arrow-right-long"
          icon-class="ml-4"
          icon-position-after
          :label="$t('buttons.next')"
          size="small"
          width="full"
          @click="showNextSlide()"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
export default {
  name: 'AgreementSharingTutorialModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay', 'nextButtonClick'],
  data: (vm) => {
    return {
      currentView: 0,
      totalViews: 3,
      content: [
        {
          imgSrc: '/illustrations/share-send-email.svg',
          title: vm.$t('agreement_sharing_tutorial_modal.slide_1.title'),
          text: vm.$t('agreement_sharing_tutorial_modal.slide_1.text'),
        },
        {
          imgSrc: '/illustrations/list-task.svg',
          title: vm.$t('agreement_sharing_tutorial_modal.slide_2.title'),
          text: vm.$t('agreement_sharing_tutorial_modal.slide_2.text'),
        },
        {
          imgSrc: '/illustrations/hacker-danger-sven.svg',
          title: vm.$t('agreement_sharing_tutorial_modal.slide_3.title'),
          text: vm.$t('agreement_sharing_tutorial_modal.slide_3.text'),
        },
      ],
    };
  },
  computed: {
    currentViewContent() {
      return this.content[this.currentView];
    },
  },

  methods: {
    closeOverlay() {
      this.currentView = 0;
      this.$emit('closeOverlay');
    },

    emitNextButtonClick() {
      this.closeOverlay();
      this.$emit('nextButtonClick');
    },

    showNextSlide() {
      if (this.currentView < this.totalViews) this.currentView++;
    },
  },
};
</script>
