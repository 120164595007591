<template>
  <section
    :id="blok.section_id"
    v-editable="blok"
    class="feature-block py-20 px-3 md:px-0"
    :class="classes"
  >
    <div class="mx-auto max-w-6xl">
      <div
        class="flex flex-col md:flex-row w-full"
        :class="{
          'md:flex-row-reverse': blok.text_position === 'right',
          'items-center': alignCenter,
        }"
      >
        <div
          class="flex-1 flex w-full sm:px-3"
          :class="{
            'justify-end': blok.text_position === 'right',
            'justify-center text-center mx-auto max-w-4xl':
              blok.text_position === 'one-column',
          }"
        >
          <div :class="'w-full md:w-11/12 flex flex-col items-center md:items-start'">
            <p
              v-if="blok.title_badge"
              class="uppercase font-medium tracking-wide text-rendin-500"
              :class="{
                'text-center md:text-left': blok.text_position !== 'one-column',
              }"
            >
              {{ blok.title_badge }}
            </p>
            <h2
              v-if="blok.title"
              class="text-3xl md:text-4xl leading-tight mb-4 md:mb-6 mt-1 font-bold"
              :class="{
                'text-rendin-500': blok.titleColor === 'orange',
                'text-center md:text-left': blok.text_position !== 'one-column',
              }"
            >
              {{ blok.title }}
            </h2>
            <component
              :is="theBlok.component"
              v-for="theBlok in blok.items.filter((item) =>
                [
                  'TextBlok',
                  'RichTextBlok',
                  'FeatureCheckmarkList',
                  'AccordionGroup',
                  'LLInviteForm',
                  'VideoBlok',
                ].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              class="w-full"
              :class="{ 'text-lg': theBlok.component === 'RichTextBlok' }"
            />
            <component
              :is="theBlok.component"
              v-for="theBlok in blok.items.filter((item) =>
                ['ButtonBlok', 'SharingModalButtonBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              :class="{ 'm-auto': blok.text_position === 'one-column' }"
            />
          </div>
        </div>
        <div
          v-if="blok.text_position !== 'one-column'"
          class="flex-1 justify-right flex h-full w-full sm:px-3 mt-10 md:mt-0"
          :class="{
            'bg-circles-1': blok.addCuteCircles === 'circles-var-1',
          }"
        >
          <div v-if="blok.items2 && blok.items2.length" class="w-full">
            <component
              :is="blok.component"
              v-for="blok in blok.items2.filter((item) =>
                ['ImageBlok', 'BlockList', 'VideoBlok'].includes(item.component),
              )"
              :key="blok._uid"
              :blok="blok"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'FeatureBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      alignCenter: true,
      // FeatureBlok use different gray background color, remapping it.
      remappedBackgroundColors: {
        ...backgroundColors,
        gray: 'bg-gray-50',
      },
    };
  },

  computed: {
    classes() {
      const classes = [];
      if (!this.blok.remove_top_bottom_padding) {
        classes.push('md:py-36');
      }

      if (!this.blok.remove_bottom_border) {
        classes.push('border-b border-gray-200');
      }
      if (this.remappedBackgroundColors[this.blok?.backgroundColor]) {
        classes.push(this.remappedBackgroundColors[this.blok.backgroundColor]);
      }

      return classes;
    },
  },

  created() {
    if (
      this.blok.items.filter((item) => item.component === 'AccordionGroup').length > 0
    ) {
      this.alignCenter = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.feature-block {
  .bg-circles-1 {
    @apply bg-contain bg-no-repeat bg-left;
    background-image: url('/images/cute-circles-featureBlok-1.svg');
  }
}
</style>
