<template>
  <div class="flex flex-col">
    <a v-if="blok.link" class="flex flex-rows items-center py-1.5" :href="blok.link">
      <div
        v-if="blok.icon && blok.icon.filename"
        class="w-8 h-8 min-w-min min-h-min bg-rendin-100 rounded-full mr-2 flex justify-center items-center"
      >
        <img
          class="w-5 h-5 mx-4"
          :src="$transformStoryblokImage(blok.icon.filename, '/m/')"
        />
      </div>
      <p class="underline">
        {{ blok.title }}
      </p>
    </a>
    <a v-else class="flex flex-rows items-center py-1.5">
      <div
        v-if="blok.icon && blok.icon.filename"
        class="w-8 h-8 min-w-min min-h-min bg-rendin-100 rounded-full mr-2 flex justify-center items-center"
      >
        <img
          class="w-5 h-5 mx-4"
          :src="$transformStoryblokImage(blok.icon.filename, '/m/')"
        />
      </div>
      <p class="underline">
        {{ blok.title }}
      </p>
    </a>
    <span v-if="blok.additionalInfo" class="italic tracking-wide pt-1 text-gray-500">
      {{ blok.additionalInfo }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'DocumentItem',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
