<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'ImageSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      const classes = [];
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      } else {
        classes.push('bg-white');
      }
      return classes;
    },
  },
};
</script>

<template>
  <div class="flex justify-center py-24" :class="classes">
    <div class="flex flex-col w-11/12">
      <div v-if="blok.title || blok.subTitle" class="flex justify-center pb-16">
        <div class="flex flex-col w-11/12 md:w-3/5">
          <p v-if="blok.title" class="text-4xl font-semibold text-center">
            {{ blok.title }}
          </p>
          <p v-if="blok.subTitle" class="text-center text-xl text-gray-600 pt-5">
            {{ blok.subTitle }}
          </p>
        </div>
      </div>
      <div class="flex justify-center">
        <img
          v-if="blok.image?.filename"
          alt="rendin-image"
          :class="[blok.imageSize ? blok.imageSize : '']"
          :src="blok.image?.filename"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-big-size {
  max-height: 1000px;
}

.image-middle-size {
  max-height: 600px;
}
</style>
