<template>
  <div>
    <div
      class="mx-auto text-center px-4 my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
      <div v-if="imageInstruction">
        <component
          :is="childBlok.component"
          v-for="childBlok in imageInstruction.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-gray-400"
        />
      </div>
    </div>
    <div class="mx-auto px-4">
      <transition
        appear
        :enter-active-class="initialLoad ? 'r-fade-bottom r-fade-bottom-start' : ''"
      >
        <div class="r-fade--delay--1 max-w-3xl mx-auto px-4">
          <r-image-upload-cloudinary
            ref="imageUpload"
            v-model="imagesModel"
            v-model:uploading-in-progress="imageUploadInProgress"
            :add-photo-button="buttonLabelAddPhoto"
            :brows-button-text="buttonLabelBrowsePhotos"
            :brows-label-text="buttonLabelUploadPhotos"
            :help-modal-label="modalHelpLabel"
            :max-file-size-error="fileSizeErrorText"
            :max-upload-file-size="Number(fileSizeMax)"
            :min-image-height="Number(photoSizeMinHeight)"
            :min-image-size-error="photoSizeErrorText"
            :min-image-width="Number(photoSizeMinWidth)"
            :min-no-of-images="minNoOfImages"
            :open-photo-label="buttonLabelOpenPhoto"
            multiple
            :validated="validated"
          >
            <template v-if="modalHelpContent" #modal>
              <component
                :is="childBlok.component"
                v-for="childBlok in modalHelpContent.filter((item) =>
                  ['RichTextBlok', 'AccordionGroup'].includes(item.component),
                )"
                :key="childBlok._uid"
                :blok="childBlok"
              />
            </template>
          </r-image-upload-cloudinary>
        </div>
      </transition>

      <div
        class="r-fade--delay--2 max-w-3xl mx-auto px-4"
        :class="{ 'r-fade-bottom': initialLoad }"
      >
        <component
          :is="childBlok.component"
          v-for="childBlok in photoRequirementsContent.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-gray-600 hidden sm:block"
        />
      </div>
      <r-inline-help-box
        class="r-fade--delay--2 py-6 sm:hidden max-w-sm"
        :class="{ 'r-fade-bottom': initialLoad }"
        icon="circle-question"
        :title="$t('buttons.image_requirements')"
        width="full"
        @click="showRequirementsOverlay = !showRequirementsOverlay"
      />
      <r-overlay
        size="small"
        :visible="showRequirementsOverlay"
        @close="showRequirementsOverlay = false"
      >
        <div class="pb-4">
          <div class="mt-8 mx-4 sm:mx-8">
            <component
              :is="childBlok.component"
              v-for="childBlok in photoRequirementsContent.filter((item) =>
                ['RichTextBlok'].includes(item.component),
              )"
              :key="childBlok._uid"
              :blok="childBlok"
              class="text-gray-600"
            />
          </div>

          <r-button
            display="block"
            :label="$t('buttons.close')"
            no-background
            uppercase
            @click="showRequirementsOverlay = false"
          />
        </div>
      </r-overlay>

      <!-- Use with Firebase integration-->
      <div
        class="r-fade--delay--3 max-w-3xl mx-auto px-4 bg-gray-100 rounded-lg px-4 py-1 mb-12"
        :class="{ 'r-fade-bottom': initialLoad }"
      >
        <component
          :is="childBlok.component"
          v-for="childBlok in photoDisclaimerContent.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-sm text-gray-600"
        />
      </div>
      <div
        class="r-fade--delay--4 max-w-3xl mx-auto sm:flex justify-center my-12 text-center px-4"
        :class="{ 'r-fade-bottom': initialLoad }"
      >
        <r-button
          :id="buttonIdPreviewProperty"
          class="w-full sm:w-auto"
          :is-loading="requestInProgress || imageUploadInProgress"
          no-background
          @click="
            requestInProgress || imageUploadInProgress
              ? null
              : $emit('previewAdvertisement')
          "
        >
          <span>
            {{ buttonLabelPreviewProperty }}
          </span>
        </r-button>

        <r-button
          :id="buttonIdFinalizeProperty"
          class="w-full sm:w-auto"
          :is-loading="requestInProgress || imageUploadInProgress"
          @click="submit"
        >
          <span>
            {{ buttonLabelFinalizeProperty }}
          </span>
        </r-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepSelectImages',
  props: {
    buttonIdPreviewProperty: {
      type: String,
      required: true,
    },
    buttonIdFinalizeProperty: {
      type: String,
      required: true,
    },
    buttonLabelAddPhoto: {
      type: String,
      required: true,
    },
    buttonLabelBrowsePhotos: {
      type: String,
      required: true,
    },
    buttonLabelFinalizeProperty: {
      type: String,
      required: true,
    },
    buttonLabelUploadPhotos: {
      type: String,
      required: true,
    },
    buttonLabelOpenPhoto: {
      type: String,
      required: true,
    },
    buttonLabelPreviewProperty: {
      type: String,
      required: true,
    },
    fileSizeErrorText: {
      type: String,
      required: true,
    },
    fileSizeMax: {
      type: String,
      required: true,
    },
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    images: {
      type: Array,
      required: false,
      default: null,
    },
    modalHelpLabel: {
      type: String,
      required: true,
    },
    modalHelpContent: {
      type: Array,
      required: true,
    },
    photoDisclaimerContent: {
      type: Array,
      required: true,
    },
    photoRequirementsContent: {
      type: Array,
      required: true,
    },
    photoSizeErrorText: {
      type: String,
      required: true,
    },
    minNoOfImages: {
      type: Number,
      default: 0,
    },
    photoSizeMinWidth: {
      type: String,
      required: true,
    },
    photoSizeMinHeight: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
    imageInstruction: {
      type: Array,
      required: false,
    },
  },
  emits: [
    'update:images',
    'imagesUpdated',
    'submitAdvertisement',
    'previewAdvertisement',
  ],
  data() {
    return {
      imageUploadInProgress: false,
      showRequirementsOverlay: false,
      validated: false,
    };
  },
  computed: {
    imagesModel: {
      get() {
        return this.images;
      },
      set(newVal) {
        this.$emit('update:images', newVal);
        this.$emit('imagesUpdated');
      },
    },
  },
  methods: {
    async submit() {
      if (this.requestInProgress || this.imageUploadInProgress) {
        return;
      }

      const isValid = this.validate();
      if (!isValid) {
        this.$scrollTo(this.$refs.imageUpload);
        return;
      }

      this.$emit('submitAdvertisement');
    },
    validate() {
      this.validated = true;
      return this.images.length >= this.minNoOfImages;
    },
  },
};
</script>
