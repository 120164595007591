<template>
  <r-card
    :background="blok.backgroundImage ? $transformStoryblokImage(blok.backgroundImage.filename,'/m/1800x0/') : ''"
    :background-color="blok.backgroundColor"
    class="flex-1 flex flex-col"
    :full-width="blok.fullWidth"
    no-border
    :no-padding="blok.noPadding"
    :shadow="blok.shadow"
  >
    <template v-for="childBlok in blok.content">
      <ImageBlok
        v-if="childBlok.component === 'ImageBlok'"
        :key="childBlok._uid"
        :blok="childBlok"
        :class="childBlok.cover ? '-m-4 sm:-mx-7 w-gutter-4 sm:w-gutter-7' : ''"
      />
      <component
        :is="childBlok.component"
        v-else
        :key="childBlok._uid"
        :blok="childBlok"
      />
    </template>
  </r-card>
</template>
<script>
export default {
  name: 'Card',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
