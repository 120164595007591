/**
 * @typedef {AgreementGet[]} CandidaciesGet
 */

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------

const requestGetCandidacies = 'getCandidacies';
const requestDeleteCandidacy = 'deleteCandidate';
const requestGetCandidates = 'getCandidates';

const requests = [requestGetCandidacies, requestDeleteCandidacy, requestGetCandidates];

// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = { ...setupMutations(requests) };

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @return {Promise<{
   *   response: CandidaciesGet,
   *   failed: boolean,
   * }>}
   */
  getCandidacies: async function ({ dispatch }) {
    return dispatch('firebaseRequest', {
      request: requestGetCandidacies,
      data: {},
    });
  },

  /**
   * @param {Stirng} profileId
   * @param {String} agreementId
   * @param {String} advertisementId
   * @return {Promise<{
   *   response: CandidaciesGet,
   *   failed: boolean,
   * }>}
   */
  deleteCandidacy: async function (
    { dispatch },
    { profileId, agreementId, advertisementId },
  ) {
    return dispatch('firebaseRequest', {
      request: requestDeleteCandidacy,
      data: {
        profileId: profileId,
        agreementId: agreementId,
        advertisementId: advertisementId,
      },
    });
  },

  getCandidates: async function ({ dispatch }, { advertisementId, agreementId }) {
    return dispatch('firebaseRequest', {
      request: requestGetCandidates,
      data: {
        advertisementId: advertisementId,
        agreementId: agreementId,
      },
    });
  },
};
