<template>
  <p
    v-editable="blok"
    class="text-gray-600 mb-8"
    :class="blok.text_size"
  >
    {{ blok.text }}
  </p>
</template>

<script>
export default {
  name: 'RichTextBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
