<template>
  <section>
    <client-only>
      <r-overlay
        :size="ComponentSize.SMALL"
        :visible="showModal"
        @close="closeOverlay()"
      >
        <div class="p-6 pt-12 flex flex-col justify-center items-center">
          <div class="text-center">
            <h1 class="font-medium text-xl mb-4">
              {{ $t('agreement.dialog.handover_date.title') }}
            </h1>
            <p class="mt-2 mb-4 whitespace-pre-line">
              {{ $t('agreement.dialog.handover_date.content') }}
            </p>
          </div>

          <div class="mt-4 w-full max-w-xs mx-auto">
            <r-button
              :label="$t('buttons.got_it')"
              size="small"
              width="full"
              @click="closeOverlay()"
            />
          </div>
        </div>
      </r-overlay>
    </client-only>
  </section>
</template>
<script>
import { ComponentSize } from '~/utils/constants';

export default {
  name: 'PropertyHandoverDateInfoModal',

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  data: () => ({
    ComponentSize: ComponentSize,
  }),

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
