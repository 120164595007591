<template class="">
  <div class="py-8 flex justify-center">
    <div class="max-w-4xl w-full">
      <div v-if="blok.title && blok.title.length > 0" class="px-4">
        <component
          :is="childBlok.component"
          v-for="childBlok in blok.title.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
        />
      </div>
      <div
        v-if="blok.documents && blok.documents.length > 0"
        :class="{ 'sm:grid sm:grid-cols-2 sm:gap-4': blok.documents.length > 1 }"
      >
        <div v-for="documentList in blok.documents" :key="documentList._uid">
          <DocumentList
            v-if="documentList.component === 'DocumentList'"
            :blok="documentList"
          />
        </div>
      </div>
      <div v-if="blok && blok.endLine" class="border w-full mt-8"></div>
    </div>
  </div>
</template>

<script>
import DocumentList from '~/components/snippets/DocumentList';

export default {
  name: 'DocumentsSection',
  components: { DocumentList },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
