<template>
  <div class="w-full mx-auto my-12 px-4">
    <div class="flex justify-between mb-4">
      <r-button
        :id="buttonIdBackToEdit"
        color="gray"
        no-background
        no-padding
        @click="$emit('goToPreviousStep')"
      >
        <span class="inline-block">
          <r-icon class="inline-block text-sm mr-2" icon-name="chevron-left" />
          {{ buttonLabelBackToEdit }}
        </span>
      </r-button>
    </div>
    <r-property
      :additional-fees="additionalFees"
      :area-label="$t('offer.size')"
      :broker-fee-label="$t('offer.additional_fee.type.broker_fee')"
      :contract-fee-label="$t('offer.additional_fee.type.contract_fee')"
      :currency="isPoland ? 'PLN' : 'EUR'"
      :damage-protection-label="$t('offer.damage_protection')"
      :damage-protection-multiplier="country == 'ee' ? '10' : '10'"
      :description-label="$t('offer.description')"
      :fee-label="$t('offer.rendin_fee')"
      :fixed-utilities-label="$t('offer.additional_fee.type.fixed_utilities.pl')"
      :floor="property.floor"
      :floor-label="$t('offer.floor')"
      :floors-total="property.floorsTotal"
      :has-parking="property.hasParking"
      :has-storage="property.hasStorage"
      :images="property.images"
      :insurance-invoice-to="property.insuranceInvoiceTo"
      :object-area="property.objectArea"
      :parking-label="$t('offer.parking')"
      :pets-allowed="property.petsAllowed"
      :pets-allowed-label="$t('offer.pets')"
      :price="property.rentAmount"
      :rendin-fee="rendinFeeCalculation(property.rentAmount, isPoland ? 'PL' : 'EE')"
      :rent-amount="property.rentAmount"
      :rent-label="$t('offer.price')"
      :rooms="property.numberOfRooms"
      :rooms-label="$t('offer.rooms')"
      :storage-label="$t('offer.storage')"
      @click-back-link="$emit('goToPreviousStep')"
    >
      <template #description>
        {{ property.description }}
      </template>
    </r-property>

    <div class="flex justify-center mt-8">
      <r-button
        v-if="showFinalizeAddButton"
        :id="buttonIdFinalizeAdvertisement + 'bottom'"
        :label="buttonLabelFinalizeAdvertisement"
        width="wide"
        @click="$emit('finalizeAdd')"
      />
      <r-button
        v-else
        :id="buttonIdBackToEdit + 'bottom'"
        color="gray"
        icon="chevron-left"
        icon-class="pr-2"
        inverted
        :label="buttonLabelBackToEdit"
        @click="$emit('goToPreviousStep')"
      />
    </div>
  </div>
</template>
<script>
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';

export default {
  name: 'StepPreviewAdvertisement',
  props: {
    buttonIdBackToEdit: {
      type: String,
      required: true,
    },
    buttonIdFinalizeAdvertisement: {
      type: String,
      required: true,
    },
    buttonLabelBackToEdit: {
      type: String,
      required: true,
    },
    buttonLabelFinalizeAdvertisement: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
    additionalFees: {
      type: Array,
    },
    showFinalizeAddButton: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['goToPreviousStep', 'finalizeAdd'],
  setup() {
    return {
      rendinFeeCalculation,
    };
  },
  computed: {
    isPoland() {
      return this.country === 'pl';
    },
  },
};
</script>
