<template>
  <section :id="blok.id" class="flex flex-col">
    <div
      class="hidden flex flex-col items-center gap-6 relative overflow-hidden"
      :class="{ 'lg:block': !blok.useMobileDesign }"
    >
      <div class="absolute inset-0 background"></div>
      <template v-for="(page, i) in pages" :key="`#${blok._uid}-page-${i}`">
        <div :id="`#${blok._uid}-trigger-${i}`" class="spacer s0"></div>
        <SAFEScrollablePage
          :id="`#${blok._uid}-page-${i}`"
          class="w-full"
          :current-step="activeSubstep[i]"
          :page="page"
          :started="lastPageStarted >= i"
        />
      </template>
    </div>
    <div
      class="flex flex-col items-center gap-6 relative overflow-hidden"
      :class="{ 'lg:hidden': !blok.useMobileDesign }"
    >
      <template v-for="(page, i) in pages" :key="i">
        <SAFEMobilePage class="w-full" :page="page" />
      </template>
    </div>
  </section>
</template>

<script>
import SAFEScrollablePage from '~/components/storyblok/scroll-animated-flow-explainer/SAFEScrollablePage.vue';
import SAFEMobilePage from '~/components/storyblok/scroll-animated-flow-explainer/SAFEMobilePage.vue';

export default {
  name: 'ScrollAnimatedFlowExplainer',
  components: { SAFEMobilePage, SAFEScrollablePage },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      controller: null,
      activeSubstep: {},
      lastPageStarted: -1,
      isScrollMagicInit: false,
      isDesktop: false,
    };
  },
  computed: {
    pages() {
      if (!this.blok || !this.blok.pages) return [];

      return this.blok.pages;
    },
  },
  created() {
    /* We need a variable for each page to keep track of individual substeps */
    for (let i = 0; i < this.pages.length; i++) {
      this.activeSubstep[i] = 0;
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkOnDesktop);
    this.$nextTick(() => {
      this.checkOnDesktop();
    });
  },
  beforeUnmount() {
    if (this.controller) {
      this.controller.destroy(true);
    }
    window.removeEventListener('resize', this.checkOnDesktop);
  },
  methods: {
    /* All elements HAVE TO exist before setuping Scroll Magic */
    async initScrollMagicIfNeeded() {
      if (this.isScrollMagicInit) return;
      this.isScrollMagicInit = true;

      const ScrollMagic = await import('scrollmagic');

      this.controller = new ScrollMagic.Controller();

      for (let i = 0; i < this.pages.length; i++) {
        const page = this.pages[i];

        /* We want to keep track of active step in each section */
        const triggerElement = document.getElementById(
          `#${this.blok._uid}-trigger-${i}`,
        );
        const pageElement = document.getElementById(`#${this.blok._uid}-page-${i}`);

        const scene = new ScrollMagic.Scene({
          triggerElement: triggerElement,
          duration: 2000,
          triggerHook: 'onLeave',
        })
          .setPin(pageElement)
          .addTo(this.controller);

        scene.on('progress', (event) => {
          const progress = event.progress;
          const totalSteps = page.sections ? page.sections.length : 0;

          const currentStep = Math.min(
            totalSteps - 1,
            Math.floor(totalSteps * progress),
          );

          if (this.activeSubstep[i] !== currentStep) {
            this.activeSubstep[i] = currentStep;
          }
        });

        scene.on('start', (event) => {
          const SCROLLMAGIC_REVERSE = 'REVERSE';
          const SCROLLMAGIC_FORWARD = 'FORWARD';

          if (event.scrollDirection === SCROLLMAGIC_REVERSE) {
            if (this.lastPageStarted === i) {
              this.lastPageStarted = i - 1;
            }
          } else if (event.scrollDirection === SCROLLMAGIC_FORWARD) {
            if (this.lastPageStarted < i) {
              this.lastPageStarted = i;
            }
          }
        });
      }
    },
    forceUpdateForScrollMagic() {
      const lastScrollPosition = this.controller.scrollPos();

      this.controller.scrollTo(0);

      /* Arbitary time limit to force Scroll Magic refresh current Scene width - fixes bug where width remains at 0 */
      setTimeout(() => {
        this.controller.scrollTo(lastScrollPosition);
      }, 15);
    },
    async checkOnDesktop() {
      const BREAKPOINT_LG = 1024;
      const newWidth = window.innerWidth;

      const isNewScreenDesktopSize = newWidth >= BREAKPOINT_LG;
      if (isNewScreenDesktopSize) {
        await this.initScrollMagicIfNeeded();

        /* We only want trigger rerender only when switching from mobile to desktop */
        if (!this.isDesktop) {
          this.isDesktop = true;
          this.forceUpdateForScrollMagic();
        }
      } else if (this.isDesktop) {
        this.isDesktop = false;
      }
    },
  },
};
</script>
<style lang="css">
.background {
  background-image: url('/illustrations/geometric_background_grey.svg');
  background-repeat: repeat;
  background-size: 1000px;
  background-position-x: center;
}
</style>
