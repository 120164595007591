<template>
  <div>
    <div class="flex flex-col md:flex-row justify-between items-center my-4 gap-2">
      <h3 class="text-2xl font-light">{{ header }}</h3>
      <r-button
        color="black"
        icon="house-chimney-user"
        :label="viewAllAdsButtonLabel"
        no-background
        size="small"
        :to="propertyListingsUrl"
      />
    </div>
    <div class="max-w-5xl flex justify-start gap-6 flex-col md:flex-row">
      <template v-if="loading">
        <div
          v-for="n in MAX_NUMBER_OF_SUGGESTIONS"
          :key="n"
          class="skeleton rounded-lg border border-gray-200 shadow-sm p-3 flex-grow md:w-64 h-80 bg-gray-300 animate-pulse"
        ></div>
      </template>
      <template v-else>
        <r-property-item
          v-for="(suggestion, index) in suggestions"
          :key="index"
          :address="suggestion.address"
          :city="suggestion.city"
          class="flex-grow md:w-64 md:max-w-1/3"
          :currency="suggestion.currency"
          :forced-mobile-view="true"
          :image="suggestion.images[0]"
          link-button-inverted
          link-button-width="full"
          :link-text="ctaButtonLabel"
          :link-url="linkToInvite(suggestion.invitationCode)"
          :price="suggestion.price"
          :rent-label="$t('offer.price')"
          :rooms="suggestion.rooms"
          :rooms-label="$t('offer.rooms')"
          :show-deposit="false"
          :show-rendin-fee="false"
          @header-click="onSuggestionClick(suggestion, 'header')"
          @image-click="onSuggestionClick(suggestion, 'image')"
          @link-click-event="onSuggestionClick(suggestion, 'button')"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { redirectLinks } from '~/utils/constants';

export default {
  name: 'AdvertisementSuggestionsBlock',
  props: {
    advertisementId: {
      type: String,
      required: true,
    },
    ctaButtonLabel: {
      type: String,
      default: 'Check it out!',
    },
    viewAllAdsButtonLabel: {
      type: String,
      default: 'viewAllAdsButtonLabel',
    },
    header: {
      type: String,
      default: 'Other listings nearby',
    },
  },
  data() {
    return {
      suggestions: [],
      loading: true,
      MAX_NUMBER_OF_SUGGESTIONS: 3,
    };
  },
  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
    }),
    propertyListingsUrl() {
      return this.hasSessionUser
        ? this.$localizedPath(redirectLinks.TENANT_PROFILE_OFFERS)
        : '/find-home';
    },
  },
  watch: {
    advertisementId: {
      handler(newValue) {
        if (newValue) {
          this.loading = true;
          this.getSimilarSuggestionsForAdvertisement({ advertisementId: newValue })
            .then((suggestions) => {
              this.suggestions = [
                ...suggestions.slice(0, this.MAX_NUMBER_OF_SUGGESTIONS),
              ];
              this.trackSuggestionsFetchEvent({
                props: {
                  advertisementId: newValue,
                  suggestions: suggestions.map(
                    (suggestion) => suggestion.invitationCode,
                  ),
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getSimilarSuggestionsForAdvertisement:
        'suggestions/getSimilarSuggestionsForAdvertisement',
      trackSuggestionsFetchEvent: 'tracker/trackSuggestionsFetchEvent',
      trackSuggestionsClickEvent: 'tracker/trackSuggestionsClickEvent',
    }),
    linkToInvite(id) {
      return this.$localizedPath(`/invite/form/${id}`);
    },
    onSuggestionClick(suggestion, elementClicked) {
      this.trackSuggestionsClickEvent({
        props: {
          suggestionInvitationCode: suggestion.invitationCode,
          advertisementId: this.advertisementId,
          suggestions: this.suggestions.map((suggestion) => suggestion.invitationCode),
          clickedOn: elementClicked,
        },
      });
    },
  },
};
</script>
