<template>
  <div class="candidates-list w-full">
    <div
      class="w-full flex flex-col-reverse justify-items-stretch md:flex-row md:gap-2 min-h-1/2-screen"
    >
      <template v-if="candidateList && candidateList.length > 0">
        <div class="w-full md:w-3/10 pb-4 flex flex-1 flex-shrink-0 flex-col">
          <div
            class="bg-white flex flex-col pb-4 overflow-hidden rounded-l-lg flex-1 shadow-lg overflow-y-auto"
          >
            <div class="p-2">
              <div class="flex flex-wrap gap-2 justify-between items-center">
                <!--                <div class="font-bold text-gray-600 text-base mt-1 px-2">{{ $t('candidates_list.candidates') }}</div>-->
                <div class="w-full">
                  <r-select
                    v-model="selectedSorting"
                    hide-keyboard
                    name="agreementSorting"
                    option-label="text"
                    :options="selectedSortingOptions"
                    rounded-border
                  />
                </div>
              </div>

              <div class="flex justify-between gap-4 mx-2 mt-2">
                <div class="text-gray-600 font-medium flex items-center">
                  {{ $t('candidates_list.approved_only') }}
                </div>
                <r-switch
                  id="switch-default"
                  name="switch-default"
                  @change="showOnlyApproved = !showOnlyApproved"
                />
              </div>
            </div>

            <div
              v-for="(candidate, index) in sortedCandidateList"
              :key="index"
              class="border-t"
            >
              <r-list-item
                v-if="candidate.candidacyStatus === candidacyStatusResults.APPROVED"
                :date="formatRelativeTime(candidate.created)"
                icon="user-shield"
                icon-class="text-green-600"
                no-focus
                no-indent
                :selected="selectedCandidateIndex === index"
                :subtitle="$t('candidate.bgStatus.approved')"
                :title="(candidate.firstName + ' ' + candidate.lastName).toUpperCase()"
                @click="selectCandidate(index)"
              />
              <r-list-item
                v-else-if="
                  candidate.candidacyStatus === candidacyStatusResults.DECLINED
                "
                :date="formatRelativeTime(candidate.created)"
                icon="user-large-slash"
                icon-class="text-red-800"
                no-focus
                no-indent
                :selected="selectedCandidateIndex === index"
                :subtitle="$t('candidate.bgStatus.declined')"
                subtitle-color="red"
                :title="(candidate.firstName + ' ' + candidate.lastName).toUpperCase()"
                @click="selectCandidate(index)"
              />
              <r-list-item
                v-else-if="
                  candidate.candidacyStatus ===
                    candidacyStatusResults.IN_ANOTHER_ACTIVE_AGREEMENT ||
                  candidate.candidacyStatus === candidacyStatusResults.RETRACTED
                "
                :date="formatRelativeTime(candidate.created)"
                icon="user-xmark"
                icon-class="text-gray-500"
                no-focus
                no-indent
                :selected="selectedCandidateIndex === index"
                :subtitle="$t('candidate.bgStatus.notAvailable')"
                :title="(candidate.firstName + ' ' + candidate.lastName).toUpperCase()"
                @click="selectCandidate(index)"
              />
              <r-list-item
                v-else-if="candidate.candidacyStatus === candidacyStatusResults.PENDING"
                :date="formatRelativeTime(candidate.created)"
                icon="user-clock"
                icon-class="text-yellow-400"
                no-focus
                no-indent
                :selected="selectedCandidateIndex === index"
                :subtitle="$t('candidate.bgStatus.pending')"
                subtitle-color="yellow"
                :title="(candidate.firstName + ' ' + candidate.lastName).toUpperCase()"
                @click="selectCandidate(index)"
              />
            </div>
          </div>
        </div>
        <div class="w-full hidden md:w-4/10 pb-4 md:flex flex-col z-10">
          <div
            class="bg-white flex flex-col flex-1 flex-shrink-0 pb-4 shadow-lg text-lg"
          >
            <candidates-list-candidate-info-card
              v-if="selectedCandidate"
              :key="selectedCandidate?.profileId"
              :add-tenant-in-progress="addTenantInProgress"
              :button-label-add-tenant="buttonLabelAddTenant"
              :candidate="selectedCandidate"
              class="sticky top-12"
              :delete-candidate-in-progress="deleteCandidacyInProgress"
            />
          </div>
        </div>
        <div class="w-full hidden md:flex md:w-3/10 pb-4 flex flex-col flex-shrink-0">
          <div
            class="bg-white flex flex-col flex-1 flex-shrink-0 pb-4 rounded-r-lg shadow-lg text-lg"
          >
            <div v-if="selectedCandidate" class="sticky top-12">
              <template
                v-if="
                  selectedCandidate.candidacyStatus === candidacyStatusResults.APPROVED
                "
              >
                <r-profile-info-card
                  :black-text-button-text="$t('candidate.deleteCandidate')"
                  button-width="full"
                  :email="selectedCandidate.email"
                  :is-loading-needed="deleteCandidacyInProgress"
                  :phone-number="selectedCandidate.phone"
                  :title="contactInfoTitle"
                  @black-text-button-emit-click-event="
                    deleteButtonClicked(selectedCandidate.profileId)
                  "
                >
                  <div class="border-b-1 border-gray-200 my-5"></div>
                  <template v-if="!agreementId">
                    <h4 class="text-lg font-medium">{{ createAgreementTitle }}</h4>
                    <RichText
                      v-if="
                        createAgreementContent &&
                        typeof createAgreementContent === 'object'
                      "
                      :blok="createAgreementContent"
                      class="text-sm"
                    />
                  </template>
                  <r-button
                    border-color="light-gray"
                    color="orange"
                    :is-loading="addTenantInProgress"
                    width="full"
                    @click="addTenantClicked(selectedCandidate.profileId)"
                  >
                    <span>
                      {{ buttonLabelAddTenant }}
                    </span>
                  </r-button>
                </r-profile-info-card>
              </template>
              <template v-else>
                <r-profile-info-card
                  :black-text-button-text="$t('candidate.deleteCandidate')"
                  button-width="full"
                  :is-loading-needed="deleteCandidacyInProgress"
                  :score-description="contactInfoDisabledContent"
                  :title="contactInfoTitle"
                  @black-text-button-emit-click-event="
                    deleteButtonClicked(selectedCandidate.profileId)
                  "
                />
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="!getCandidatesInProgress">
        <div
          class="flex-1 w-full flex flex-col items-center bg-white rounded-lg p-4 gap-4"
        >
          <template v-if="isAdvertisementActive">
            <!--            How to invite accordion content-->
            <div class="max-w-2xl">
              <img
                v-if="noCandidatesImage?.filename"
                :alt="noCandidatesImage.alt"
                class="m-auto max-h-40"
                :src="$transformStoryblokImage(noCandidatesImage.filename, '/m/122x0/')"
              />

              <div v-if="blok.candidateAccordionDescription">
                <RichText :blok="blok.candidateAccordionDescription" />
              </div>
              <div v-if="blok.candidateAccordionExplainer">
                <RichText :blok="blok.candidateAccordionExplainer" />
              </div>

              <r-accordion
                v-if="blok.candidateFirstAccordionTitle"
                :title="blok.candidateFirstAccordionTitle"
              >
                <div v-if="blok.candidateFirstAccordionContent">
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in blok.candidateFirstAccordionContent.filter(
                      (item) => ['RichTextBlok', 'ButtonBlok'].includes(item.component),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                  />
                </div>
                <r-copy-to-clipboard :text-to-copy="candidatesClipboardText" />
              </r-accordion>

              <div
                v-if="blok.candidateAccordionContent?.length > 0"
                class="flex flex-col gap-4 pt-4"
              >
                <component
                  :is="theBlok.component"
                  v-for="theBlok in blok.candidateAccordionContent.filter((item) =>
                    ['RichTextBlok', 'ButtonBlok', 'Accordion'].includes(
                      item.component,
                    ),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </div>

              <!--  Action buttons, different and hardcoded for PL/EE -->
              <div class="mt-6">
                <div v-if="country.toUpperCase() === workflow.ESTONIA">
                  <r-button
                    icon="share"
                    icon-class="ml-2"
                    icon-position-after
                    :label="$t('buttons.share_listing')"
                    size="small"
                    width="full"
                    @click="openSharingModal('Listing Info')"
                  />
                </div>
                <div v-else>
                  <r-copy-to-clipboard
                    :show-text-section="false"
                    :text-to-copy="candidatesClipboardText"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="m-auto max-w-xl">
              <r-title-with-picture
                :content="notActiveDisclaimerText"
                :image="notActiveDisclaimerImage.filename"
                image-size="max-h-40 md:max-h-52"
                :title="notActiveDisclaimerTitle"
                title-style="text-2xl"
              />

              <div class="mb-12 text-center">
                <r-button
                  :label="$t('listing_actions.activate_listing')"
                  width="wide"
                  @click="onClickActivateListing"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div
          class="flex-1 w-full flex justify-center items-center bg-white rounded-lg p-4 gap-4"
        >
          <r-loader type="animatedLogo" />
        </div>
      </template>
    </div>

    <!--    Candidate Profile Modal - mobile-->
    <r-overlay
      body-classes="max-h-rem-54"
      bottom-bar
      fixed-height
      size="small"
      :visible="candidateModalShown"
      @close="closeCandidateProfileModal"
    >
      <candidates-list-candidate-info-card
        v-if="selectedCandidate"
        :key="selectedCandidate?.profileId"
        :add-tenant-in-progress="addTenantInProgress"
        :button-label-add-tenant="buttonLabelAddTenant"
        :candidate="selectedCandidate"
        :create-agreement-content="agreementId ? null : createAgreementContent"
        :create-agreement-title="agreementId ? null : createAgreementTitle"
        :delete-candidacy-in-progress="deleteCandidacyInProgress"
        is-mobile
        secondary-button-icon="trash"
        :secondary-button-text="$t('candidate.deleteCandidate')"
        @add-tenant-clicked="addTenantClicked(selectedCandidate.profileId)"
        @secondary-button-clicked="deleteButtonClicked(selectedCandidate.profileId)"
      />
      <template #bottom-bar>
        <div
          class="flex justify-around p-5 bg-white sm:rounded-b-lg items-center shadow-t-xl"
        >
          <r-button
            class="shadow-md"
            color="white"
            :disabled="sortedCandidateList && sortedCandidateList.length === 1"
            @click="selectPreviousCandidate"
          >
            <p class="flex items-center gap-2">
              <r-icon class="text-gray-600" icon-name="chevron-left" size="lg" />
            </p>
          </r-button>
          <p>
            {{ selectedCandidateIndex + 1 }} /
            {{ sortedCandidateList ? sortedCandidateList.length : '' }}
          </p>
          <r-button
            class="shadow-md"
            color="white"
            :disabled="sortedCandidateList && sortedCandidateList.length === 1"
            @click="selectNextCandidate"
          >
            <p class="flex items-center gap-2">
              <r-icon class="text-gray-600" icon-name="chevron-right" size="lg" />
            </p>
          </r-button>
        </div>
      </template>
    </r-overlay>

    <!-- Sharing modal for Invite -->
    <r-sharing-modal
      :description="listingAddress"
      :modal-button-text="$t('web_sharing_modal.copy_link')"
      :modal-description="$t('web_sharing_modal.or_share_in_social_media')"
      :modal-title="$t('web_sharing_modal.share_this_link')"
      :show-modal="sharingModalOpen"
      :title="listingAddress"
      :url="inviteUrl"
      @close-overlay="sharingModalOpen = false"
      @copy-link-click="
        trackInviteLinkCopyEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          channel: 'Link',
        })
      "
      @custom-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
        })
      "
      @native-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
        })
      "
      @social-network-popup-open="
        (network) =>
          trackInviteLinkCopyEvent({
            objectType: trackingProcessNames.ADVERTISEMENT,
            openingLocation: sharingModalLocation,
            channel: network,
          })
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CandidatesListCandidateInfoCard from '~/components/section/profile-advertisement-object-view/CandidatesListCandidateInfoCard';
import {
  sortingOptionsForTracking,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import RSharingModal from '~/components/r-interface-components/r-sharing-modal';
import {
  sharingModalTypes,
  candidacyStatusResults,
  sortingOptions,
  workflow,
} from '~/utils/constants';
import useDateFromIsoString from '~/composables/useDateFromIsoString.js';

export default {
  name: 'CandidatesList',
  components: { CandidatesListCandidateInfoCard, RSharingModal },
  props: {
    tittle: {
      type: String,
    },
    backgroundCheckApprovedText: {
      type: String,
    },
    backgroundCheckNotApprovedText: {
      type: String,
    },
    candidateList: {
      type: [Array, Object],
      required: true,
    },
    agreementId: {
      type: String,
    },
    advertisementId: {
      type: String,
    },
    isLoadingNeeded: {
      type: Boolean,
      default: false,
    },
    getCandidatesInProgress: {
      type: Boolean,
      default: false,
    },
    deleteCandidacyInProgress: {
      type: Boolean,
      default: false,
    },
    addTenantInProgress: {
      type: Boolean,
      default: false,
    },
    inviteUrl: {
      type: String,
      default: '',
    },
    noCandidatesImage: {
      type: Object,
      required: true,
    },
    noCandidatesTitle: {
      type: String,
      required: true,
    },
    noCandidatesContent: {
      type: Object,
      required: true,
    },
    buttonLabelAddTenant: {
      type: String,
      required: true,
    },
    createAgreementTitle: {
      type: String,
      required: true,
    },
    createAgreementContent: {
      type: Object,
      required: true,
    },
    contactInfoTitle: {
      type: String,
      required: true,
    },
    contactInfoDisabledContent: {
      type: String,
      required: true,
    },
    isAdvertisementActive: {
      type: Boolean,
      required: true,
    },
    notActiveDisclaimerText: {
      type: Object,
      required: true,
    },
    notActiveDisclaimerTitle: {
      type: String,
      required: true,
    },
    notActiveDisclaimerImage: {
      type: Object,
      required: true,
    },
    listingAddress: {
      type: String,
      required: true,
    },
    blok: {
      type: Object,
      required: true,
    },
    candidatesClipboardText: {
      type: String,
      default: null,
    },
  },
  emits: ['deleteButtonClicked', 'addTenantClicked', 'clickActivateListing'],
  async setup() {
    const { formatRelativeTime } = useDateFromIsoString();

    return {
      formatRelativeTime,
    };
  },
  data: (vm) => ({
    selectedCandidateIndex: 0,
    candidateModalShown: false,
    isMobileScreen: false,
    routingDone: false,
    sharingModalOpen: false,
    sharingModalLocation: 'Applicants',
    trackingProcessNames: trackingProcessNames,
    sharingModalTypes: sharingModalTypes,
    candidacyStatusResults,
    showOnlyApproved: false,
    selectedSorting: {
      text: vm.$t('sort_filter.newest_first'),
      value: sortingOptions.NEWEST_FIRST,
      trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
    },
    selectedSortingOptions: [
      {
        text: vm.$t('sort_filter.newest_first'),
        value: sortingOptions.NEWEST_FIRST,
        trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
      },
      {
        text: vm.$t('sort_filter.oldest_first'),
        value: sortingOptions.OLDEST_FIRST,
        trackingValue: sortingOptionsForTracking.OLDEST_FIRST,
      },
    ],
    workflow,
  }),
  computed: {
    ...mapGetters({
      country: 'getCountry',
    }),

    selectedCandidate() {
      if (
        this.sortedCandidateList.length === 0 ||
        this.selectedCandidateIndex < 0 ||
        this.selectedCandidateIndex >= this.sortedCandidateList.length ||
        !this.routingDone
      )
        return null;
      return this.sortedCandidateList[this.selectedCandidateIndex];
    },

    sortedCandidateList() {
      if (!Array.isArray(this.candidateList)) return [];

      let candidateListClone = [...this.candidateList];

      if (this.showOnlyApproved) {
        candidateListClone = candidateListClone.filter(
          (candidate) => candidate.candidacyStatus === candidacyStatusResults.APPROVED,
        );
      }

      if (this.selectedSorting.value === sortingOptions.OLDEST_FIRST) {
        return candidateListClone.sort(function (x, y) {
          return new Date(x.created) - new Date(y.created);
        });
      } else {
        return candidateListClone.sort(function (x, y) {
          return new Date(y.created) - new Date(x.created);
        });
      }
    },
  },
  watch: {
    sortedCandidateList: {
      handler(sortedList) {
        /* We prioritize query params over index on load */
        if (sortedList.length > 0 && !this.routingDone) {
          this.selectCandidateByQueryId();
          this.routingDone = true;
        }

        const isIndexOutOfBounds = this.selectedCandidateIndex >= sortedList.length;
        if (isIndexOutOfBounds) {
          if (sortedList?.length > 0) {
            this.selectedCandidateIndex = 0;
          } else {
            this.selectedCandidateIndex = -1;
          }
        }
      },
      immediate: true,
    },
    selectedCandidate(selectedCandidate) {
      if (selectedCandidate && selectedCandidate.profileId) {
        this.$router.push({
          name: 'object_view',
          params: { objectid: this.$route.params.objectid, lang: this.$getLocale() },
          query: { tab: 'candidates', candidate_id: selectedCandidate.profileId },
        });

        this.trackCandidatesViewCandidateEvent({
          candidateId: selectedCandidate.profileId,
          processName: trackingProcessNames.ADVERTISEMENT,
          advertisementId: this.advertisementId,
          agreementId: this.agreementId,
        });
      }
    },
    $route: function (newRoute) {
      if (
        !this.selectedCandidate ||
        this.selectedCandidate.profileId !== newRoute.query.candidate_id
      ) {
        this.selectCandidateByQueryId();
      }
    },
  },
  beforeMount() {
    this.onResize();
    if (!this.isMobileScreen) {
      if (this.candidateList.length > 0) {
        this.selectedCandidateIndex = 0;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.trackCandidatesViewListEvent({
      processName: trackingProcessNames.ADVERTISEMENT,
      advertisementId: this.advertisementId,
      agreementId: this.agreementId,
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions({
      trackCandidatesViewListEvent: 'tracker/trackCandidatesViewListEvent',
      trackCandidatesViewCandidateEvent: 'tracker/trackCandidatesViewCandidateEvent',
      trackInviteLinkOpenSharingEvent: 'tracker/trackInviteLinkOpenSharingEvent',
      trackInviteLinkCopyEvent: 'tracker/trackInviteLinkCopyEvent',
    }),
    selectCandidateByQueryId() {
      const candidateId = this.$route.query.candidate_id;
      if (this.$route.query.candidate_id) {
        const foundCandidateIndex = this.sortedCandidateList.findIndex(
          (candidate) => candidate.profileId === candidateId,
        );

        if (foundCandidateIndex >= 0) {
          this.selectCandidate(foundCandidateIndex);
        }
      }
    },
    selectCandidate(candidateIndex) {
      this.selectedCandidateIndex = candidateIndex;
      if (this.isMobileScreen && !this.candidateModalShown) {
        this.candidateModalShown = true;
      }
    },
    closeCandidateProfileModal() {
      this.candidateModalShown = false;
      this.selectedCandidateIndex = -1;
    },
    selectNextCandidate() {
      this.selectedCandidateIndex++;
      if (this.selectedCandidateIndex >= this.sortedCandidateList.length)
        this.selectedCandidateIndex = 0;
    },
    selectPreviousCandidate() {
      this.selectedCandidateIndex--;
      if (this.selectedCandidateIndex < 0)
        this.selectedCandidateIndex = this.sortedCandidateList.length - 1;
    },
    deleteButtonClicked(event) {
      /**
       * Click event.
       *
       * @event click
       * @type {object}
       */
      this.$emit('deleteButtonClicked', event);
    },
    addTenantClicked(event) {
      if (!this.addTenantInProgress) {
        /**
         * Add tenant button - click event.
         *
         * @event addTenantClicked
         */
        this.$emit('addTenantClicked', event);
      }
    },

    onResize() {
      const width = window.innerWidth;
      const newIsMobileScreen = width <= 767;
      /* Did we transition from desktop to mobile format */
      if (newIsMobileScreen && !this.isMobileScreen) {
        this.selectedCandidateIndex = -1;
      } else if (!newIsMobileScreen && this.isMobileScreen) {
        this.selectedCandidateIndex = 0;
        this.candidateModalShown = false;
      }
      this.isMobileScreen = newIsMobileScreen;
    },

    openSharingModal() {
      this.sharingModalOpen = true;
    },

    onClickActivateListing() {
      this.$emit('clickActivateListing');
    },
  },
};
</script>
