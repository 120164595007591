<template>
  <div>
    <Field
      v-slot="{ meta, errors }"
      v-model="address.addressCity"
      validate-on-mount
      name="citySearch"
      :rules="{ required: true }"
    >
      <input-city-search
        v-model="address.addressCity"
        :failed-rules="!meta.valid ? errors : null"
        :label="$t('form.label.city')"
        :label-class="labelClass"
        name="citySearch"
        :placeholder="$t('form.placeholder.city')"
        required
        :search-country="$getCountry()"
        :select-no-options-text="$t('form.validation.select_no_options')"
        :source="source"
        :validation-failed="!meta.valid"
        :validation-passed="meta.valid"
        @select-city="onSelectCity"
      />
    </Field>
    <Field
      v-slot="{ meta, errors }"
      v-model="address.addressStreet"
      name="streetName"
      validate-on-mount
      :rules="{ required: true }"
    >
      <r-input
        v-model="address.addressStreet"
        :errors="!meta.valid ? errors : null"
        field-type="text"
        :label="$t('form.label.address_street')"
        :label-class="labelClass"
        name="streetName"
        :placeholder="$t('form.placeholder.address_street')"
        required
        :validation-failed="!meta.valid"
        :validation-passed="meta.valid"
        @blur="onBlur"
        @input="emitInput()"
      />
    </Field>
    <Field
      v-slot="{ meta, errors }"
      v-model="address.addressIndex"
      name="postCode"
      validate-on-mount
      :rules="{ required: true }"
    >
      <r-input
        v-model="address.addressIndex"
        :errors="!meta.valid ? errors : null"
        field-type="text"
        for="postCode"
        :label="$t('form.label.postalCode')"
        :label-class="labelClass"
        name="postCode"
        :placeholder="$t('form.placeholder.postalCode')"
        required
        :validation-failed="!meta.valid"
        :validation-passed="meta.valid"
        @blur="onBlur"
        @input="emitInput()"
      />
    </Field>
    <Field
      v-slot="{ errors, meta }"
      v-model="address.addressHouseNumber"
      validate-on-mount
      name="houseNumber"
      :rules="{ required: true }"
    >
      <r-input
        v-model="address.addressHouseNumber"
        :errors="!meta.valid ? errors : null"
        field-type="text"
        for="houseNumber"
        :label="$t('form.label.address_house_number')"
        :label-class="labelClass"
        name="houseNumber"
        :placeholder="$t('form.placeholder.address_house_number')"
        required
        :validation-failed="!meta.valid"
        :validation-passed="meta.valid"
        @blur="onBlur"
        @input="emitInput()"
      />
    </Field>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import InputCitySearch from '~/components/snippets/InputCitySearch';
import { defaultAddressObject } from '~/utils/objectStructures';
import { Field } from 'vee-validate';

export default {
  name: 'FormAddressManual',
  components: { Field, InputCitySearch },
  props: {
    addressApartmentDisclaimer: {
      type: String,
      required: false,
      default: '',
    },
    source: {
      type: String,
      required: true,
    },
    externalAddress: {
      type: Object,
      required: true,
      default: () => defaultAddressObject(),
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      address: defaultAddressObject(),
    };
  },
  watch: {
    externalAddress: {
      handler(newValue) {
        if (!isEqual(newValue, this.address)) {
          this.address = defaultAddressObject(this.externalAddress);
        }
      },
      deep: true,
    },
  },
  created() {
    this.address = defaultAddressObject(this.externalAddress);
  },
  methods: {
    emitInput() {
      const addressFirstLineComponents = [
        this.address.addressStreet,
        this.address.addressHouseNumber,
      ];
      this.address.addressFirstLine = addressFirstLineComponents.join(' ');
      this.address.addressCountry = this.$getCountry() === 'pl' ? 'Polska' : 'EE';

      this.$emit('input', this.address);
    },
    onBlur() {
      this.$emit('blur', this.address);
    },
    onSelectCity(city) {
      this.address.addressCity = city;
      this.$nextTick(() => {
        this.onBlur();
      });
    },
  },
};
</script>
