<template>
  <div v-editable="blok">
    <r-button
      :id="blok.id"
      :color="blok.color"
      :inverted="blok.style === 'secondary'"
      :label="blok.buttonText"
      :no-background="blok.style === 'link'"
      :size="blok.size"
      :width="blok.width ? blok.width : 'wide'"
      @click="openModal(blok)"
    />
    <!-- Sharing modal -->
    <r-sharing-modal
      :add-description-with-button-url="blok.addDescriptionWithButtonUrl"
      :description="blok.modalShareDescription"
      :modal-button-text="blok.modalButtonText"
      :modal-description="blok.modalDescriptionText"
      :modal-title="blok.modalTitle"
      :show-modal="sharingModalOpen"
      :title="blok.modalShareTitle"
      :url="linkForSharing"
      @close-overlay="sharingModalOpen = false"
      @copy-link-click="
        trackMarketingEvent({
          eventName: marketingEventNames.SHARE_MODAL_COPY_LINK,
          customProps: {
            channel: sharingModalTypes.DESKTOP_MODAL_LINK,
            openingLocation: blok.trackingLocation,
            linkForSharing: linkForSharing,
          },
        })
      "
      @custom-web-sharing-modal-open="
        trackMarketingEvent({
          eventName: marketingEventNames.SHARE_MODAL_OPEN,
          customProps: {
            modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
            openingLocation: blok.trackingLocation,
          },
        })
      "
      @native-web-sharing-modal-open="
        trackMarketingEvent({
          eventName: marketingEventNames.SHARE_MODAL_OPEN,
          customProps: {
            modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
            openingLocation: blok.trackingLocation,
          },
        })
      "
      @social-network-popup-open="
        (network) =>
          trackMarketingEvent({
            eventName: marketingEventNames.SHARE_MODAL_COPY_LINK,
            customProps: { channel: network, openingLocation: blok.trackingLocation },
          })
      "
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RSharingModal from '~/components/r-interface-components/r-sharing-modal';
import { marketingEventNames } from '~/utils/trackerConstants';
import { sharingModalTypes } from '~/utils/constants';

export default {
  name: 'SharingModalButtonBlok',
  components: {
    RSharingModal,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sharingModalOpen: false,
      sharingModalTypes,
      marketingEventNames,
    };
  },

  computed: {
    linkForSharing() {
      const url = `${this.blok.modalShareLink}`;
      if (
        this.blok.parameterValueToAdd &&
        this.blok.parameterName &&
        this.$route.query[this.blok.parameterValueToAdd]
      ) {
        return `${url}?${this.blok.parameterName}=${this.$route.query[this.blok.parameterValueToAdd]} `;
      }

      return url;
    },
  },

  methods: {
    ...mapActions({
      trackMarketingEvent: 'tracker/trackMarketingEvent',
    }),

    openModal() {
      this.sharingModalOpen = true;
    },
  },
};
</script>
