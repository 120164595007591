<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="py-16 md:py-20 border-b border-gray-200 bg-white px-3 md:px-0"
  >
    <div class="max-w-6xl mx-auto">
      <div
        v-if="blok.title || blok.text"
        class="md:w-3/4 lg:w-3/5 mx-auto text-center mb-10 md:mb-20"
      >
        <h2 class="text-3xl md:text-4xl leading-tight mb-4 font-bold">
          {{ blok.title }}
        </h2>
        <p class="text-lg text-gray-800">
          {{ blok.text }}
        </p>
      </div>

      <div
        class="grid grid-cols-1 gap-4 md:gap-6 lg:gap-10"
        :class="{ ['md:grid-cols-' + blok.columns]: blok.columns }"
      >
        <component
          :is="blok.component"
          v-for="blok in blok.benefits"
          :key="blok._uid"
          :blok="blok"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionBenefits',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
