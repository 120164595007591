<template>
  <div
    class="mx-auto px-4 r-fade-bottom"
  >
    <template v-if="sessionCurrentStep === sessionFlowSteps.LOGIN && emailLoginStartScreen">
      <component
        :is="blok.component"
        v-for="(blok, index) in profileLoginIntro.filter(item => ['RichTextBlok'].includes(item.component))"
        :key="blok._uid"
        :blok="blok"
        class="r-fade my-6 md:my-12 md:text-1.5xl"
        :class="'r-fade--delay--'+ (index)"
      />
    </template>
    <template v-else-if="sessionCurrentStep === sessionFlowSteps.FILL_PROFILE">
      <component
        :is="blok.component"
        v-for="blok in newProfileMoreInfo.filter(item => ['RichTextBlok'].includes(item.component))"
        :key="blok._uid"
        :blok="blok"
        class="my-12 md:text-1.5xl"
      />
    </template>
    <SessionFlow
      class="max-w-sm mx-auto r-fade"
      :class="'r-fade--delay--'+ profileLoginIntro.length"
      :indicated-profile-source="profileSourceIndications.WEB_DIGIBROKER"
      no-text
      :profile-initial-type="userRole.LANDLORD"
      show-login-view-back-to-start-link
      :successful-reroute="$localizedPath($router.currentRoute.path)"
      @loginViewPinScreenVisible="emailLoginStartScreen=false"
      @loginViewStartScreenVisible="emailLoginStartScreen=true"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { profileSourceIndications, sessionFlowSteps, userRole } from '~/utils/constants';

export default {
  name: 'StepSessionFlow',
  props: {
    profileLoginIntro: {
      type: Array,
      required: true,
    },
    newProfileMoreInfo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      profileSourceIndications: profileSourceIndications,
      userRole: userRole,
      sessionFlowSteps: sessionFlowSteps,
      emailLoginStartScreen: true,
    };
  },
  computed: {
    ...mapGetters({
      sessionCurrentStep: 'session/currentStep',
    }),
  },
  mounted() {
    this.trackLoginStartEvent({ sessionFlow: this.profileSourceIndications.WEB_DIGIBROKER });
  },
  methods: {
    ...mapActions({
      trackLoginStartEvent: 'tracker/trackLoginStartEvent',
    }),
  },
};
</script>
