import type { FacebookQuery } from 'meta-pixel';

type ExtendedFacebookQuery = FacebookQuery & {
  (command: 'consent', event: 'grant' | 'revoke'): void;
};

export default defineNuxtPlugin({
  name: 'cookiebot',
  dependsOn: ['i18n'],
  setup: () => {
    const { $getLocale, $fbq } = useNuxtApp();
    const pixel = $fbq as ExtendedFacebookQuery;

    // Disable events before user has accepted them
    pixel('consent', 'revoke');

    const script = document.createElement('script');
    script.async = true;
    script.id = 'Cookiebot';
    script.setAttribute('data-cbid', 'bdc624dc-0e2a-416d-9843-394cd8e014fd');
    script.setAttribute('data-culture', $getLocale());
    script.src = `https://consent.cookiebot.com/uc.js`;
    document.head.appendChild(script);
    window.addEventListener(
      'CookiebotOnConsentReady',
      () => {
        const cookiebot = window.Cookiebot;
        if (!cookiebot) {
          throw new Error(
            'Cookiebot Ready was emit, but no access to Cookiebot instance',
          );
        }
        const marketingCookiesEnabled = cookiebot.consent.marketing;
        if (marketingCookiesEnabled) {
          pixel('consent', 'grant');
        }
      },
      false,
    );
  },
});
