import {
  indexVuexStore,
  addressindexVuexStore,
  addsindexVuexStore,
  advertisementsindexVuexStore,
  agreementsindexVuexStore,
  annexindexVuexStore,
  calculatorindexVuexStore,
  candidaciesindexVuexStore,
  firebaseindexVuexStore,
  handoverindexVuexStore,
  inviteindexVuexStore,
  liveChatindexVuexStore,
  notificationsindexVuexStore,
  profilesindexVuexStore,
  routerHistoryindexVuexStore,
  sessionindexVuexStore,
  storyBlokGlobalsindexVuexStore,
  suggestionsindexVuexStore,
  tenantsindexVuexStore,
  terminationindexVuexStore,
  testingindexVuexStore,
  trackerindexVuexStore,
  usersindexVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      address: {
        ...addressindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      adds: {
        ...addsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      advertisements: {
        ...advertisementsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      agreements: {
        ...agreementsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      annex: {
        ...annexindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      calculator: {
        ...calculatorindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      candidacies: {
        ...candidaciesindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      firebase: {
        ...firebaseindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      handover: {
        ...handoverindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      invite: {
        ...inviteindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      liveChat: {
        ...liveChatindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      notifications: {
        ...notificationsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      profiles: {
        ...profilesindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      routerHistory: {
        ...routerHistoryindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      session: {
        ...sessionindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      storyBlokGlobals: {
        ...storyBlokGlobalsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      suggestions: {
        ...suggestionsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      tenants: {
        ...tenantsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      termination: {
        ...terminationindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      testing: {
        ...testingindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      tracker: {
        ...trackerindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      users: {
        ...usersindexVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore