<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="cta-section relative overflow-hidden"
    :class="classes"
  >
    <div
      class="max-w-6xl mx-auto h-full flex flex-col md:flex-row items-center"
      :class="[blok?.image?.filename ? 'cta-section__with-image' : '']"
    >
      <div
        v-intersection="
          withVisuals
            ? { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left' }
            : { class: 'opacity-100 animate-fade-in-opacity' }
        "
        class="opacity-0 p-4 py-8 md:py-12"
        :class="[withVisuals ? 'text-left md:w-1/2' : 'mx-auto text-center']"
      >
        <h2
          v-if="blok.title"
          class="my-4 font-semibold text-gray-900 text-3xl md:text-4xl"
          v-html="blok.title"
        ></h2>
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.content.filter((item) =>
            ['TextBlok', 'RichTextBlok', 'FeatureCheckmarkList'].includes(
              item.component,
            ),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
          class="w-full text-lg md:text-xl lg:my-8"
          :class="{ 'text-center': !withVisuals }"
        />
        <div
          class="flex gap-4 flex-wrap justify-center lg:my-8"
          :class="{ 'md:justify-start': withVisuals }"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.buttons.filter((item) =>
              ['ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-auto"
          />
        </div>
      </div>

      <!--       Video-->
      <div
        v-if="blok.cloudinaryVideoLink"
        v-intersection="{
          class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right',
        }"
        class="opacity-0 md:w-1/2 p-4 sm:p-12 md:py-20"
      >
        <div class="cta-section__with-video bg-white p-6 rounded-xl shadow-lg mx-auto">
          <video autoplay loop muted playsinline>
            <source
              :src="transformedCloudinaryVideo(blok.cloudinaryVideoLink, 'q_auto')"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <!--       Mobile image, if defined explicitly-->
      <div
        v-else-if="blok.image?.filename && blok.imageMobile?.filename"
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="opacity-0 md:hidden max-w-3/4 mx-auto"
      >
        <img
          :alt="blok.imageMobile.alt"
          :src="$transformStoryblokImage(blok.imageMobile.filename, '/m/fit-in/600x0/')"
        />
      </div>
      <!--       Mobile image, if only image defined -->
      <div
        v-else-if="blok.image?.filename && !blok.imageMobile?.filename"
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="opacity-0 md:hidden w-full"
      >
        <img
          :alt="blok.image.alt"
          :src="$transformStoryblokImage(blok.image.filename, '/m/fit-in/780x0/')"
        />
      </div>
      <!--    Desktop image -->
      <div
        v-if="blok.image?.filename"
        v-intersection="{ class: 'opacity-100 animate-slide-in-right' }"
        class="opacity-0 cta-section__image--desktop hidden md:block absolute h-full w-1/2 top-0 bottom-0 left-1/2 bg-no-repeat bg-cover bg-left bg-none"
        :style="{
          'background-image':
            'url(' +
            $transformStoryblokImage(blok.image.filename, '/m/fit-in/0x960') +
            ')',
        }"
      ></div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';
import { transformedCloudinaryVideo } from '~/utils/transformCloudinary.js';

export default {
  name: 'CTASection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      transformedCloudinaryVideo,
    };
  },
  computed: {
    withVisuals() {
      return this.blok?.image?.filename || this.blok.cloudinaryVideoLink;
    },

    classes() {
      const classes = [];
      classes.push(backgroundColors[this.blok?.backgroundColor] ?? 'bg-white');

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-section {
  &__with-image {
    @media screen and (min-width: 768px) {
      min-height: 720px;
    }
  }

  &__with-video {
    aspect-ratio: 1/1;
  }
}
</style>
