<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="flex flex-col text-center h-full p-4 sm:px-12 pt-8">
      <div v-if="annexInSigning">
        <h3 class="font-medium text-lg mb-4">
          {{ $t('agreement.annexing.already_signing.title') }}
        </h3>
        <div class="whitespace-pre-line">
          {{ $t('agreement.annexing.already_signing.content') }}
        </div>
      </div>
      <div v-else>
        <h3 class="font-medium text-lg mb-4">
          {{ $t('agreement.annexing.cannot_renew_yet.title') }}
        </h3>
        <div class="whitespace-pre-line">
          {{ $t('agreement.annexing.cannot_renew_yet.content') }}
        </div>
      </div>
    </div>
    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button :label="$t('buttons.close')" size="small" @click="closeOverlay" />
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgreementRenewalPlModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    annexInSigning: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['closeOverlay'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
});
</script>
