<template>
  <r-overlay
    class="handover-protocol-info-modal"
    size="large"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <section class="pt-12 px-4 pb-6 md:px-8">
      <h1 class="font-medium text-xl">
        {{ $t('agreement.handover_v2.protocol_info_modal.landlord_guide.title') }}
      </h1>
      <p class="text-gray-600 break-words whitespace-pre-line mb-6">
        {{ $t('agreement.handover_v2.protocol_info_modal.landlord_guide.description') }}
      </p>
      <r-accordion-group
        v-if="isTenantView"
        :active-index="accordionGroupActiveIndex"
        flavor="flat"
        :accordions="termsTenant"
        @switch="(index) => (accordionGroupActiveIndex = index)"
      >
        <template #importance>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.importance.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.importance.text_1',
              )
            "
          ></div>
        </template>

        <template #comments>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.text_1',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.subtitle_2',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.text_2',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.subtitle_3',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.text_3',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.subtitle_4',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.text_4',
              )
            "
          ></div>
        </template>
        <template #responsibilities>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.responsibilities.text',
              )
            "
          ></div>
        </template>
        <template #differences>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.tenant_guide.differences.text',
              )
            "
          ></div>
        </template>
      </r-accordion-group>
      <r-accordion-group
        v-else
        :active-index="accordionGroupActiveIndex"
        flavor="flat"
        :accordions="termsLandlord"
        @switch="(index) => (accordionGroupActiveIndex = index)"
      >
        <template #importance>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.importance.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.importance.text_1',
              )
            "
          ></div>
        </template>

        <template #meter_readings>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.text_1',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.subtitle_2',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.text_2',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.subtitle_3',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.text_3',
              )
            "
          ></div>
        </template>
        <template #rooms_condition>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.rooms_condition.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.rooms_condition.text_1',
              )
            "
          ></div>
          <h3
            class="break-words whitespace-pre-line font-medium my-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.rooms_condition.subtitle_2',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.rooms_condition.text_2',
              )
            "
          ></div>
        </template>
        <template #more_information>
          <h3
            class="break-words whitespace-pre-line font-medium mb-4"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.more_information.subtitle_1',
              )
            "
          ></h3>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.more_information.text_1',
              )
            "
          ></div>
        </template>
        <template #resolve_disagreements>
          <div
            class="break-words whitespace-pre-line"
            v-html="
              $t(
                'agreement.handover_v2.protocol_info_modal.landlord_guide.resolve_disagreements.text_1',
              )
            "
          ></div>
        </template>
      </r-accordion-group>
    </section>

    <div class="p-4 max-w-sm mx-auto w-full">
      <r-button :label="$t('buttons.close')" width="full" @click="closeOverlay()" />
    </div>
  </r-overlay>
</template>
<script>
export default {
  name: 'HandoverProtocolInfoModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closeOverlay'],
  data() {
    return {
      accordionGroupActiveIndex: -1,
    };
  },

  computed: {
    termsLandlord() {
      return [
        {
          id: 'importance',
          icon: 'star',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.landlord_guide.importance.title',
          ),
        },
        {
          id: 'meter_readings',
          icon: 'meter',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.landlord_guide.meter_readings.title',
          ),
        },
        {
          id: 'rooms_condition',
          icon: 'house',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.landlord_guide.rooms_condition.title',
          ),
        },
        {
          id: 'more_information',
          icon: 'file-circle-plus',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.landlord_guide.more_information.title',
          ),
        },
        {
          id: 'resolve_disagreements',
          icon: 'circle-exclamation',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.landlord_guide.resolve_disagreements.title',
          ),
        },
      ];
    },
    termsTenant() {
      return [
        {
          id: 'importance',
          icon: 'star',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.tenant_guide.importance.title',
          ),
        },
        {
          id: 'comments',
          icon: 'file-circle-plus',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.tenant_guide.comments.title',
          ),
        },
        {
          id: 'responsibilities',
          icon: 'house-heart',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.tenant_guide.responsibilities.title',
          ),
        },
        {
          id: 'differences',
          icon: 'circle-exclamation',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t(
            'agreement.handover_v2.protocol_info_modal.tenant_guide.differences.title',
          ),
        },
      ];
    },
  },

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
