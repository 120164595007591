<template>
  <section>
    <client-only>
      <r-overlay
        :header-class="openProfilePrimarySettings ? 'bg-white z-10' : ''"
        :size="ComponentSize.SMALL"
        :visible="showModal"
        @close="closeOverlay()"
      >
        <template v-if="openProfilePrimarySettings" #header>
          <div class="flex flex-row items-center">
            <div class="w-9 h-9 flex-shrink-0 m-2">
              <button
                v-if="openProfilePrimarySettings"
                class="bg-gray-100 rounded-full w-9 h-9 hover:shadow focus:shadow"
                @click="openProfilePrimarySettings = false"
              >
                <span class="sr-only">{{ $t('buttons.back') }}</span>
                <r-icon class="text-xl" icon-name="arrow-left-long" />
              </button>
            </div>
            <div class="flex-grow text-center text-xl font-medium py-2">
              {{ $t('buttons.profile') }}
            </div>
          </div>
        </template>

        <div class="m-4 mt-12 flex flex-col h-full">
          <template v-if="openProfilePrimarySettings">
            <ProfilePrimarySetting
              :submit-button-loading="putProfileInProgress"
              submit-button-wrapper-class="sticky bottom-0 h-18 p-4 bg-white -mx-4"
              type="LANDLORD"
              @submitted-profile-data="(profileData) => putProfileData(profileData)"
            />
          </template>

          <template v-else>
            <template
              v-if="
                agreementFromFirebase.agreementTypeFromAgreement ===
                agreementTypes.EE_BASIC
              "
            >
              <div class="text-center">
                <h1 class="font-medium text-xl">{{ $t('dialog.termless.title') }}</h1>
                <p class="mt-2 mb-4">{{ $t('dialog.termless.explanation') }}</p>
              </div>

              <ul class="gap-2 flex flex-col">
                <li class="flex gap-2">
                  <r-icon class="text-rendin-500 text-xl" icon-name="check" />
                  {{ $t('dialog.termless.reason_1') }}
                </li>
                <li class="flex gap-2">
                  <r-icon class="text-rendin-500 text-xl" icon-name="check" />
                  {{ $t('dialog.termless.reason_2') }}
                </li>
                <li class="flex gap-2">
                  <r-icon class="text-rendin-500 text-xl" icon-name="check" />
                  {{ $t('dialog.termless.reason_3') }}
                </li>
              </ul>

              <div class="text-center my-6">
                <p>
                  {{ $t('dialog.termless.blog') }}
                  <nuxt-link
                    class="text-rendin-500 font-bold"
                    :to="$t('dialog.termless.blog_cta')"
                  >
                    {{ $t('dialog.termless.blog_link') }}
                  </nuxt-link>
                </p>
              </div>
            </template>

            <template
              v-else-if="
                agreementFromFirebase.agreementTypeFromAgreement ===
                agreementTypes.PL_NO
              "
            >
              <div class="text-center">
                <h1 class="font-medium text-xl">
                  {{ $t('dialog.agreement_type_pl_no.title') }}
                </h1>
                <p class="my-4">{{ $t('dialog.agreement_type_pl_no.explanation') }}</p>
                <p class="my-4">
                  {{ $t('dialog.agreement_type_pl_no.explanation_1') }}
                </p>
                <p class="my-4">
                  {{ $t('dialog.agreement_type_pl_no.explanation_2') }}
                </p>
              </div>
            </template>

            <template
              v-else-if="
                agreementFromFirebase.agreementTypeFromAgreement ===
                agreementTypes.PL_NI
              "
            >
              <div class="text-center">
                <h1 class="font-medium text-xl">
                  {{ $t('dialog.agreement_type_pl_ni.title') }}
                </h1>
                <p class="my-4">{{ $t('dialog.agreement_type_pl_ni.explanation') }}</p>
                <p class="my-4">
                  {{ $t('dialog.agreement_type_pl_ni.explanation_1') }}
                </p>
                <p class="my-4">
                  {{ $t('dialog.agreement_type_pl_ni.explanation_2') }}
                </p>
              </div>
            </template>

            <template
              v-else-if="
                agreementFromFirebase.agreementTypeFromAgreement ===
                agreementTypes.UNKNOWN
              "
            >
              <div>
                <h1 class="font-medium text-xl text-center">
                  {{ $t('dialog.agreement_type_unknown_pl.title') }}
                </h1>
                <p class="mt-6">
                  {{ $t('dialog.agreement_type_unknown_pl.explanation') }}
                </p>
              </div>
              <ul class="gap-3 flex flex-col my-6">
                <li class="flex items-center gap-3">
                  <r-icon class="text-rendin-500 text-xl" icon-name="user" />
                  <span class="text-sm">{{
                    $t('dialog.agreement_type_unknown_pl.explanation_personal')
                  }}</span>
                </li>
                <li class="flex items-center gap-3">
                  <r-icon class="text-rendin-500 text-xl" icon-name="briefcase" />
                  <span class="text-sm">{{
                    $t('dialog.agreement_type_unknown_pl.explanation_business')
                  }}</span>
                </li>
              </ul>

              <p>
                {{ $t('dialog.agreement_type_unknown_pl.explanation_final') }}
              </p>
            </template>

            <div class="flex flex-col justify-center gap-2 mt-8">
              <r-button
                v-if="
                  agreementFromFirebase.landlordProfileId === profile.id &&
                  (agreementFromFirebase.agreementTypeFromAgreement ===
                    agreementTypes.PL_NO ||
                    agreementFromFirebase.agreementTypeFromAgreement ===
                      agreementTypes.PL_NI)
                "
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('buttons.open_my_profile')"
                size="small"
                width="full"
                @click="openProfilePrimarySettingsView()"
              />
              <r-button
                v-if="
                  agreementFromFirebase.agreementTypeFromAgreement ===
                  agreementTypes.UNKNOWN
                "
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('agreement.button.add_landlord')"
                size="small"
                width="full"
                @click="closeOverlayAndOpenPartiesTab()"
              />
              <r-button
                :label="$t('buttons.close')"
                size="small"
                @click="closeOverlay()"
              />
            </div>
          </template>
        </div>
      </r-overlay>
    </client-only>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ComponentSize, agreementTypes } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'AgreementTypeModal',

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ComponentSize: ComponentSize,
    openProfilePrimarySettings: false,
    agreementTypes: agreementTypes,
    trackingProcessNames: trackingProcessNames,
    agreementTrackingEventNames: agreementTrackingEventNames,
  }),

  computed: {
    ...mapGetters({
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
      actionGetAgreement: 'agreements/getAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.openProfilePrimarySettings = false;
      this.$emit('closeOverlay');
    },

    openProfilePrimarySettingsView() {
      this.openProfilePrimarySettings = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_PROFILE_IN_AGREEMENT_TYPE,
      );
    },

    closeOverlayAndOpenPartiesTab() {
      this.openProfilePrimarySettings = false;

      this.$emit('closeOverlay');
      this.$emit('moveToTab', 'parties');
    },

    putProfileData(profileData) {
      if (!this.putProfileInProgress) {
        this.actionPutProfile({ profile: profileData, id: this.profile.id })
          .then((response) => {
            if (!response.failed)
              this.$toast.success(this.$t('profile_saved'), {
                position: 'top-center',
                timeout: 3000,
                hideProgressBar: true,
              });
          })
          .then(() => {
            this.actionGetAgreement({ id: this.agreementId }).then(() => {
              this.$emit('requestTabsValidation');
            });
          })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames
                .CREATOR_PROFILE_UPDATED_IN_AGREEMENT_TYPE,
            ),
          )
          .finally(this.closeOverlay);
      }
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
