<template>
  <div v-editable="blok" class="flex flex-col py-3 md:pb-8 px-3">
    <div
      v-if="blok.image && blok.image.filename"
      class="flex items-center justify-center w-12 h-12 mb-4"
    >
      <img :src="blok.image.filename" />
    </div>
    <h3 class="mb-2 text-xl font-medium leading-tight text-gray-800">
      {{ blok.title }}
    </h3>
    <p class="text-sm text-gray-800 lg:text-base mb-5">
      {{ blok.text }}
    </p>
    <div v-if="blok.link_path" class="mt-auto text-left">
      <nuxt-link
        class="text-gray-600 hover:text-rendin-500 font-medium no-underline border-b border-dashed border-gray-400 inline w-auto pb-1"
        :to="blok.link_path"
      >
        {{ blok.link_text }} →
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetBenefitItem',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
