<template>
  <div
    v-editable="blok"
    class="icon-text-blok flex gap-4 rounded-lg px-4"
    :class="[
      { 'mb-8 mt-4': !blok.noMargin },
      sectionBackgroundColor,
      { '-mt-12': blok.mergeWithPreviousIconTextBlok },
    ]"
  >
    <div v-if="blok.icon" class="icon-text-blok__icon flex-shrink-0 pt-5">
      <component
        :is="contentBlok.component"
        v-for="contentBlok in blok.icon"
        :key="contentBlok._uid"
        :blok="contentBlok"
      />
    </div>

    <RichText
      v-if="blok.text[0]?.richTextContent"
      :blok="blok.text[0]?.richTextContent"
      :class="blok.textSize"
    />
  </div>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'IconTextBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sectionBackgroundColor() {
      const classes = [];
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push(backgroundColors[this.blok?.backgroundColor]);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
.icon-text-blok {
  &__icon {
    min-width: 2rem;
  }
}
</style>
