<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    header-class="bg-white z-10"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="mt-8 flex flex-col text-center h-full p-4 sm:px-12">
      <r-icon class="text-4xl text-rendin-500" icon-name="clipboard-list-check" />

      <h3 class="font-medium text-lg mt-6 mb-4">
        {{ $t('agreement.dialog.cant_proceed.title') }}
      </h3>

      <p>
        {{ $t('agreement.dialog.cant_proceed.content') }}
      </p>
    </div>
    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button
          display="block"
          :label="$t('buttons.got_it')"
          @click="closeOverlay()"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
export default {
  name: 'CantProceedModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
