<template>
  <section
    class="listing-subscription-form flex justify-center px-4 py-4 md:py-16 min-h-screen"
    :class="classes"
    name="ListingSubscriptionForm"
  >
    <div v-if="!isComponentLoaded" class="flex justify-center items-center">
      <r-loader type="animatedLogo" />
    </div>
    <div
      v-else
      class="px-8 pb-8 pt-4 2xl:w-2/5 lg:w-2/4 md:w-2/3 h-fit shadow-md rounded-2xl flex justify-center bg-white"
    >
      <Form v-if="!submitSuccess" v-slot="{ handleSubmit, validate }">
        <div class="flex flex-col">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.content"
            :key="theBlok._uid"
            :blok="theBlok"
          />
        </div>
        <h3 class="headline-3 my-4 font-semibold text-gray-900 text-xl">
          {{ '1. ' + $t('listing_subscription.choose_your_home_preferences') }}
        </h3>
        <div class="my-6">
          <div class="my-4">
            <label class="font-bold text-gray-600">{{
              $t('preferencesFilter.label.city')
            }}</label>
            <Field
              v-slot="{ meta }"
              v-model="selectedRegion"
              name="selectedRegion"
              rules="required"
            >
              <r-select
                v-model="selectedRegion"
                clearable
                :error-text="$t('form.validation.is_required')"
                :get-option-label="(option) => `${option.city} (${option.adsCount})`"
                :no-options-text="$t('form.validation.select_no_options')"
                option-label="city"
                :options="availableGeoRegions"
                :placeholder="$t('preferencesFilter.placeholder.city')"
                :validation-failed="!meta.valid"
                :validation-passed="meta.valid"
              />
            </Field>
            <div v-if="selectedRegion && selectedRegion.showDistricts" class="mt-3">
              <label class="font-bold text-gray-600">{{
                $t('preferencesFilter.label.districts')
              }}</label>
              <r-select
                v-model="form.districts"
                clearable
                :close-on-select="false"
                deselect-from-dropdown
                :max-displayed-values="maxDisplayedValues"
                multiple
                :no-options-text="$t('form.validation.select_no_options')"
                :options="selectedRegion.districts"
                :placeholder="$t('preferencesFilter.label.districts')"
              />
            </div>
          </div>

          <div class="my-4 flex justify-center w-full">
            <r-range-select
              class="w-full"
              :data="roomsRangeSelectData"
              :max-value-index="form.roomsMax"
              :min-value-index="form.roomsMin"
              :title="$t('preferencesFilter.label.rooms')"
              title-class="font-bold text-gray-600"
              @update-max-value="(value) => (form.roomsMax = value)"
              @update-min-value="(value) => (form.roomsMin = value)"
            />
          </div>

          <div class="my-4">
            <label class="font-bold text-gray-600"
              >{{ $t('preferencesFilter.label.price') }} ({{ currencyLabel }})</label
            >
            <div class="flex gap-2">
              <r-input
                v-model="form.priceMin"
                :add-on="currencyLabel"
                class="flex-grow"
                field-type="number"
                for="priceMin"
                name="priceMin"
                :placeholder="$t('preferencesFilter.placeholder.from')"
              />
              <span class="mt-4 text-gray-600"> — </span>
              <r-input
                v-model="form.priceMax"
                :add-on="currencyLabel"
                class="flex-grow"
                field-type="number"
                for="priceMax"
                :min="form.roomsMin"
                name="priceMax"
                :placeholder="$t('preferencesFilter.placeholder.to')"
              />
            </div>
          </div>

          <div class="my-4">
            <label class="font-bold text-gray-600"
              >{{ $t('preferencesFilter.label.area') }} (m2)</label
            >
            <div class="flex gap-2">
              <r-input
                v-model="form.areaMin"
                add-on="m2"
                class="flex-grow"
                field-type="number"
                for="areaMin"
                name="areaMin"
                :placeholder="$t('preferencesFilter.placeholder.from')"
              />
              <span class="mt-4 text-gray-600"> — </span>
              <r-input
                v-model="form.areaMax"
                add-on="m2"
                class="flex-grow"
                field-type="number"
                for="areaMax"
                :min="form.areaMin"
                name="areaMax"
                :placeholder="$t('preferencesFilter.placeholder.to')"
              />
            </div>
          </div>

          <!--      Mobile floor-->
          <div class="my-4">
            <label class="font-bold text-gray-600">{{
              $t('preferencesFilter.label.floor')
            }}</label>
            <div class="flex gap-2">
              <r-input
                v-model="form.floorMin"
                class="flex-grow"
                field-type="number"
                for="floorMin"
                name="floorMin"
                :placeholder="$t('preferencesFilter.placeholder.from')"
              />
              <span class="mt-4 text-gray-600"> — </span>
              <r-input
                v-model="form.floorMax"
                class="flex-grow"
                field-type="number"
                for="floorMax"
                :min="form.floorMin"
                name="floorMax"
                :placeholder="$t('preferencesFilter.placeholder.to')"
              />
            </div>
          </div>

          <div class="flex flex-wrap gap-4">
            <r-checkbox
              id="petsAllowed"
              v-model="form.petsAllowed"
              :label="$t('form.label.petsAllowed')"
              name="petsAllowed"
              no-padding
            />
            <r-checkbox
              id="hasParking"
              v-model="form.hasParking"
              :label="$t('form.label.parking_space')"
              name="hasParking"
              no-padding
            />
            <r-checkbox
              id="hasStorage"
              v-model="form.hasStorage"
              :label="$t('form.label.hasStorage')"
              name="hasStorage"
              no-padding
            />
          </div>
        </div>
        <h3 class="headline-3 my-4 font-semibold text-gray-900 text-xl">
          {{ '2. ' + $t('listing_subscription.add_your_email_and_subscribe') }}
        </h3>
        <form
          class="flex flex-col gap-2"
          @submit.prevent="
            beforeSubmit()
              .then(() => validate())
              .then((isValid) => (isValid ? handleSubmit(submitSubscription()) : null))
          "
        >
          <Field
            v-slot="{ meta }"
            v-model="email"
            name="email"
            rules="required|email|isNonAnonymousEmail"
          >
            <r-input
              id="email"
              v-model="email"
              data-testid="input.sign-up.email"
              :disabled="!!userProfile"
              :error-text="$t('form.validation.email')"
              field-type="email"
              for="email"
              :label="$t('form.label.email')"
              name="email"
              :placeholder="$t('form.placeholder.email')"
              :validation-failed="!meta.valid"
              :validation-passed="meta.valid"
            />
          </Field>
          <Field
            v-if="!hasAcceptedTermsAndConditions"
            v-slot="{ meta }"
            v-model="acceptedTermsAndConditions"
            name="terms"
            :rules="{ required: { allowFalse: false } }"
          >
            <r-checkbox
              v-model="acceptedTermsAndConditions"
              data-testid="input.sign-up.terms-and-conditions"
              :disabled="hasAcceptedTermsAndConditions"
              :error-text="$t('form.validation.accept_terms')"
              name="terms"
              required
              :validation-failed="!meta.valid"
            >
              <span class="text-sm">
                <span>
                  {{ $t('form.terms_agree') }}
                </span>
                <a
                  class="font-medium transition duration-200 ease-in-out text-rendin-500 hover:text-rendin-300 hover:underline focus:underline"
                  :href="$localizedPath(redirectLinks.LEGAL)"
                  target="_blank"
                >
                  {{
                    $t('form.rendin_terms') +
                    ' ' +
                    $t('and') +
                    ' ' +
                    $t('form.rendin_privacy')
                  }}</a
                >
              </span>
            </r-checkbox>
          </Field>
          <div class="flex flex-col gap-2 w-full pt-2">
            <r-button
              class="w-full"
              data-testid="button.sign-up.submit"
              :is-loading="submitInProgress"
              size="small"
              type="submit"
            >
              {{ $t('buttons.get_email_alerts') }}
            </r-button>
          </div>
        </form>
      </Form>
      <div v-else class="flex flex-col justify-center items-center">
        <p
          v-if="blok.successTitle"
          class="headline-3 my-4 font-semibold text-gray-900 text-xl md:text-2xl"
        >
          {{ blok.successTitle }}
        </p>
        <img
          v-if="blok.successImage?.filename"
          class="w-48 h-48"
          :src="$transformStoryblokImage(blok.successImage.filename, '/m/')"
        />
        <AdvertisementDetailsChips :advertisement-details="form" class="w-full" />
        <div class="flex flex-col">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.successContent"
            :key="theBlok._uid"
            :blok="theBlok"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  defaultListingPreferences,
  defaultRoomsRangeSelectData,
} from '~/utils/objectStructures';
import { backgroundColors, isoLanguageCode, redirectLinks } from '~/utils/constants';
import { listingSubscription } from '~/utils/trackerConstants';
import AdvertisementDetailsChips from '~/components/snippets/AdvertisementDetailsChips.vue';
import { Field, Form } from 'vee-validate';

export default {
  name: 'ListingSubscriptionForm',
  components: { Field, Form, AdvertisementDetailsChips },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: defaultListingPreferences(),
      roomsRangeSelectData: defaultRoomsRangeSelectData(),
      maxDisplayedValues: 3,
      isComponentLoaded: false,
      email: '',
      submitInProgress: false,
      submitSuccess: false,
      acceptedTermsAndConditions: false,
      redirectLinks: redirectLinks,
    };
  },

  computed: {
    ...mapGetters({
      availableGeoRegions: 'suggestions/availableGeoRegions',
      currencyLabel: 'getCurrencyLabel',
      hasAcceptedTermsAndConditions: 'users/hasAcceptedTermsAndConditions',
      userProfile: 'users/profile',
      getLocale: 'getLocale',
    }),

    classes() {
      const classes = [];
      classes.push(
        backgroundColors[this.blok?.backgroundColor] ?? 'md:bg-white bg-gray-50',
      );
      return classes;
    },

    selectedRegion: {
      get() {
        /* If we have regions and proper city name, try to find corresponding region by city, otherwise return null */
        const regions = this.availableGeoRegions;
        if (!Array.isArray(regions) || regions.length === 0) return null;
        if (!this.form || !this.form.city) return null;

        return regions.find((x) => x.city === this.form.city) || null;
      },
      set(value) {
        this.form.city = value ? value.city : '';
        this.form.districts = [];
      },
    },
  },

  watch: {
    userProfile(newVal) {
      this.email = newVal.email;
    },
  },

  beforeMount() {
    this.getAvailableGeoRegions();
  },

  mounted() {
    if (this.userProfile) {
      this.email = this.userProfile.email;
    }
    this.isComponentLoaded = true;
  },

  methods: {
    ...mapActions({
      getAvailableGeoRegions: 'suggestions/getAvailableGeoRegions',
      actionPostAdvertisementSubscription:
        'advertisements/postAdvertisementSubscription',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackListingSubscriptionTrigger: 'tracker/trackListingSubscriptionTrigger',
    }),

    postAdvertisementSubscription() {
      return this.actionPostAdvertisementSubscription({
        email: this.email,
        locale: isoLanguageCode[this.getLocale],
        country: this.$getCountry(),
        city: this.form.city,
        districts: this.form.districts,
        roomsMin: this.form.roomsMin,
        roomsMax: this.form.roomsMax,
        priceMin: this.form.priceMin,
        priceMax: this.form.priceMax,
        areaMin: this.form.areaMin,
        areaMax: this.form.areaMax,
        floorMin: this.form.floorMin,
        floorMax: this.form.floorMax,
        hasStorage: this.form.hasStorage,
        hasParking: this.form.hasParking,
        petsAllowed: this.form.petsAllowed,
        agreedTCAndPrivacyPolicy: this.hasAcceptedTermsAndConditions
          ? true
          : this.acceptedTermsAndConditions,
      });
    },

    async beforeSubmit() {
      this.email = this.email?.trim();
    },

    submitSubscription() {
      if (this.submitInProgress) return;
      this.submitInProgress = true;
      this.postAdvertisementSubscription()
        .then((response) => {
          if (response.response.success) {
            this.submitSuccess = true;
          }
        })
        .then(() => {
          this.actionTrackListingSubscriptionTrigger({
            eventName: listingSubscription.SUBSCRIBE,
            props: {
              userPreferences: this.form,
            },
          });
        })
        .catch((error) => this.actionReportErrorToSentry(error))
        .finally(() => {
          this.submitInProgress = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
