<template>
  <header
    :id="blok.id"
    v-editable="blok"
    class="header-bg-image__img relative"
    :class="classes"
  >
    <div
      class="md:absolute bg-cover block h-40 sm:h-60 md:h-full top-0 left-0"
      :class="{
        'right-0 bg-right md:right-50 w-full md:w-1/2':
          blok.flavor === flavor.IMAGE_LEFT,
        'right-0 w-full md:pb-20': blok.flavor === flavor.WIDE,
        'header-bg-image__video-overlay':
          blok.videoBlok &&
          blok.videoBlok.length > 0 &&
          blok.videoBlok[0].embedVideoLink.cached_url,
      }"
    >
      <div
        class="bg-cover bg-center h-full w-full"
        :style="{
          'background-image':
            'url(' + $transformStoryblokImage(blok.image.filename, '/m/') + ')',
        }"
      ></div>

      <!--      VideoBlok is supported only with flavor 'image-left'-->
      <div
        v-if="
          blok.videoBlok &&
          blok.videoBlok.length > 0 &&
          blok.flavor === flavor.IMAGE_LEFT
        "
        class="flex w-full h-full"
      >
        <component
          :is="blok.component"
          v-for="videoBlok in blok.videoBlok.filter((item) =>
            ['VideoBlok'].includes(item.component),
          )"
          :key="videoBlok._uid"
          :blok="videoBlok"
        />
      </div>
    </div>

    <div
      class="mx-auto max-w-6xl md:grid px-4 py-8"
      :class="{
        'md:grid-cols-2': blok.flavor === flavor.IMAGE_LEFT,
        'lg:grid-cols-2': blok.flavor === flavor.WIDE,
      }"
    >
      <div
        :class="{
          'header-bg-image__img-left__container flex flex-col justify-center col-start-2 md:py-16 md:pl-8 lg:pl-16 ':
            blok.flavor === flavor.IMAGE_LEFT,
          'z-0 md:bg-white md:rounded-xl md:shadow-2xl md:p-8 md:mt-60 lg:mt-60 md:px-8 ':
            blok.flavor === flavor.WIDE,
        }"
      >
        <h1
          class="m-0 font-extrabold text-gray-800 mb-4"
          :class="{
            'text-3xl md:text-4xl': blok.titleFont === 'small',
            'text-4xl md:text-5xl': blok.titleFont !== 'small',
          }"
        >
          {{ blok.title }}
        </h1>

        <RichText :blok="blok.description" class="my-8 text-lg" />

        <div
          class="mt-10 flex flex-wrap gap-4 items-center justify-center md:justify-start"
        >
          <div v-for="btn in blok.buttons" :key="btn.uuid">
            <div v-if="btn && btn.component === 'ButtonBlok'">
              <ButtonBlok :blok="btn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

const flavor = {
  WIDE: 'wide',
  IMAGE_LEFT: 'image-left',
};

export default {
  name: 'HeaderWithBackground',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      flavor: flavor,
    };
  },
  computed: {
    classes() {
      const classes = [];

      classes.push(
        backgroundColors[this.blok?.backgroundColor] ?? 'md:bg-white bg-gray-50',
      );

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.header-bg-image__img-left__container {
  @media only screen and (min-width: 768px) {
    min-height: 500px;
  }
}
</style>
