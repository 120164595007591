/**
 * @typedef {string} InvitationShortCode
 */

/**
 * @typedef {{
 *  idCode?: string,
 *  email?: string,
 *  phone?: string,
 *  hasPets?: boolean,
 *  fullName?: string,
 *  message?: string
 * }} CandidateInfo
 */

/**
 * @typedef {{
 *   addressFirstLine?: string,
 *   addressCity?: string,
 *   addressCountry?: string,
 *   addressIndex?: string,
 *   objectArea?: number,
 *   numberOfRooms?: number,
 *   images?: Array<string>,
 *   rentAmount?: number,
 *   insuranceAmount?: number,
 *   currency?: Currency,
 *   hasParking?: boolean,
 *   hasStorage?: boolean,
 *   petsAllowed?: boolean,
 *   contact?: PropertyPublicContactPerson,
 *   shortCode?: string,
 *   active?: boolean,
 *   workflow?: WorkFlow,
 *   additionalFees?: Array<AdditionalFee>,
 *   isRequesterProfileActiveCandidate?: boolean,
 * }} getPropertyData
 */

/**
 * @typedef {{
 *   firstName?: string,
 *   lastName?:  string,
 *   phoneNumber?:  string,
 *   email?:  string,
 * }} PropertyPublicContactPerson
 */

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestPostCandidateToInvite = 'postCandidateToInvite';
const requestGetInvite = 'getInvite';
const requestGetContactInformation = 'getContactInformation';

const requests = [
  requestPostCandidateToInvite,
  requestGetInvite,
  requestGetContactInformation,
];

// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = { ...setupMutations(requests) };

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @param {InvitationShortCode} inviteShortCode - short code of the invitation.
   * @param {CandidateInfo} candidate - server will ignore some parameters based on the info known by the server.
   * @return {Promise<{
   *   response: string,
   *   failed: boolean,
   * }>}
   */
  postCandidateToInvite: function (
    { dispatch, rootGetters },
    { inviteShortCode, candidate },
  ) {
    const optionsData = {
      getRecalculateScore: true,
    };

    return dispatch('firebaseRequest', {
      request: requestPostCandidateToInvite,
      data: {
        ...candidate,
        inviteShortCode: inviteShortCode,
        getRecalculateScore: true,
        options: optionsData,
      },
    });
  },

  /**
   * @param {String} inviteShortCode
   * @param {String} fromSource
   * @return {Promise<{
   *   response: getInvite,
   * }>}
   */
  getInvite: async function ({ dispatch }, { inviteShortCode, fromSource }) {
    const fromSourceOption = fromSource ? { fromSource: fromSource } : null;

    return dispatch('firebaseRequest', {
      request: requestGetInvite,
      data: {
        request: {
          shortCode: inviteShortCode,
          ...fromSourceOption,
        },
      },
    });
  },

  /**
   * @param {String} inviteShortCode
   * @return {Promise<{
   *   response: getContactInformation,
   * }>}
   */

  getContactInformation: async function ({ dispatch }, { inviteShortCode }) {
    return dispatch('firebaseRequest', {
      request: requestGetContactInformation,
      expectedErrorCodes: ['functions/permission-denied'],
      data: { shortCode: inviteShortCode },
    });
  },

  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------------------------------------------
};
