<template>
  <div v-editable="blok">
    <div class="mb-12">
      <template v-if="!blok.articleTranslationHidden">
        <div class="max-w-7xl mx-auto mt-6 px-4">
          <r-button
            border-color="white"
            color="black"
            icon="arrow-left-long"
            inverted
            :label="$t('see_all_articles')"
            @click="goToPreviousPage($localizedPath(redirectLinks.ARTICLES))"
          />
        </div>
        <!--        Header -->
        <article-hero-section
          :author-name="blok?.authorName"
          :hide-old-content-warning="blok?.hideOldContentWarning"
          :image="blok?.image?.filename"
          :image-desktop="blok?.imageDesktop?.filename"
          :intro="blok?.intro"
          no-top-padding
          :published-date="blok?.first_published_at"
          :show-arrow="blok?.showArrow"
          :title="blok?.title"
        />

        <!--        Content -->
        <article-content v-if="blok.content?.length" :content="blok.content" />

        <!-- Long Text is now deprecated, should use Content field in the future - Oliver 05.08.22 -->
        <!-- WE have ca 28 articles with old structure, older from /articles/5-levinud-muuti-uurituru-kohta  - Kadri 5.12.23-->
        <div v-else-if="longText" class="mx-auto max-w-3xl p-4 mb-12 md:my-20">
          <div class="r-rich-text" v-html="longText"></div>
        </div>
      </template>

      <template v-else>
        <div v-if="globalBlock" class="max-w-3xl mx-auto my-12 w-full min-h-screen">
          <h1 class="text-2xl font-bold">{{ globalBlock.title }}</h1>
          <RichText v-if="globalBlock.content" :blok="globalBlock.content" />

          <r-button
            border-color="light-gray"
            color="black"
            icon="arrow-left-long"
            icon-class="mr-2"
            inverted
            :label="$t('back_to_articles')"
            @click="goToPreviousPage($localizedPath(redirectLinks.ARTICLES))"
          />
        </div>
      </template>
    </div>

    <component
      :is="contentBlok.component"
      v-for="contentBlok in blok.marketingSections"
      :key="contentBlok._uid"
      :blok="contentBlok"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { redirectLinks } from '~/utils/constants';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';
import ArticleHeroSection from '~/components/snippets/article/ArticleHeroSection';
import ArticleContent from '~/components/snippets/article/ArticleContent';
import { useArticleSeo } from '~/composables/useArticleSeo.ts';

export default {
  components: {
    ArticleHeroSection,
    ArticleContent,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { articleHeadMetadata } = useArticleSeo(props.blok);

    useHead(articleHeadMetadata);
    return {
      localizedRenderRichText,
    };
  },
  data() {
    return {
      globalBlock: {},
      redirectLinks,
    };
  },

  computed: {
    ...mapGetters({
      getGlobalBloks: 'getGlobalBloks',
    }),
    notAvailableContent() {
      if (!this.globalBlock) return '';
      return this.localizedRenderRichText(
        this.globalBlock.content,
        this.$localizedPath,
      );
    },
    longText() {
      if (!this.blok.long_text) return '';
      return this.localizedRenderRichText(this.blok.long_text, this.$localizedPath);
    },
  },

  watch: {
    getGlobalBloks: {
      deep: true,
      handler(newVal) {
        this.globalBlock = newVal.ArticleTranslationFallback;
      },
    },
  },

  created() {
    this.globalBlock = this.getGlobalBloks.ArticleTranslationFallback;
  },

  methods: {
    ...mapActions({
      actionGoToPreviousPage: 'routerHistory/goToPreviousPage',
    }),

    goToPreviousPage(fallbackUrl) {
      this.actionGoToPreviousPage(fallbackUrl);
    },
  },
};
</script>
