import defaultTheme from 'tailwindcss/defaultTheme';

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./components/**/*.{js,vue,ts}",
    "./layouts/**/*.vue",
    "./pages/**/*.vue",
    "./plugins/**/*.{js,ts}",
    "./app.vue",
    "./error.vue",
    "nuxt.config.js",
    "utils/**/*.js",
  ],
  theme: {
    extend: {
      typography: {
        DEFAULT: {
          css: {
            a: {
              fontWeight: null,
              color: '#FD5B01',
              transitionProperty: 'color',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              transitionDuration: '200ms',
              '&:hover': {
                color: '#FF7D49',
              },
            },
          },
        },
      },
      borderWidth: {
        1: '1px',
      },
      borderRadius: {
        '7xl': '64px',
        '4xl': '32px',
      },
      transitionProperty: {
        width: 'width',
      },
      flex: {
        'h-screen': '1 1 100vh',
        'h-auto': '1',
      },
      fontFamily: {
        sans: ['Roboto', ...defaultTheme.fontFamily.sans],
      },
      fontSize: {
        md: '.925rem',
        xxs: '.6875rem',
        '1.5xl': ['1.375rem', '1.7rem'],
        '28px': ['28px', '36px'],
      },
      colors: {
        peach: {
          100: '#FCF9F7',
          200: '#FCF0E9',
          300: '#FFE0BB',
          500: '#FFB395',
        },
        lightgray: '#E5E5E5',
        lightaquamarine: '#81FEE94D',
        vividorchid: '#F66EFF4D',
        lightorange: '#F4E9DF',
        rendin: {
          50: '#fffdfc',
          100: '#FBE1D0',
          200: '#FF966E',
          300: '#FF7D49',
          400: '#FF6D2E',
          500: '#FD5B01',
          600: '#E45100',
          700: '#CA4800',
        },
        // green: {
        //   50: '#e6f6f5',
        //   100: '#b3e4e0',
        //   200: '#80d3cc',
        //   300: '#4dc1b8',
        //   400: '#1aafa3',
        //   500: '#00a699',
        //   600: '#00958a',
        // },
        // Violet from our new UI kit, as of 12.12.2023
        violet: {
          25: '#FBFAFF',
          50: '#F5F3FF',
          100: '#ECE9FE',
          200: '#DDD6FE',
          300: '#C3B5FD',
          400: '#A48AFB',
          500: '#875BF7',
          600: '#7839EE',
          700: '#6927DA',
          800: '#5720B7',
          900: '#491C96',
        },
        // Blue dark from our new UI kit, as of 12.12.2023
        blue: {
          25: '#F5F8FF',
          50: '#EFF4FF',
          100: '#D1E0FF',
          200: '#B2CCFF',
          300: '#84ADFF',
          400: '#528BFF',
          500: '#2970FF',
          600: '#155EEF',
          700: '#004EEB',
          800: '#0040C1',
          900: '#00359E',
        },
        grayBlue: {
          100: '#EAECF5',
          500: '#4E5BA6',
        },
        grayCool: {
          400: '#7D89B0',
          500: '#5D6B98',
        },
        gray: {
          50: '#F9FAFB',
          100: '#F2F4F7',
          200: '#EAECF0',
          300: '#D0D5DD',
          400: '#98A2B3',
          500: '#667085',
          600: '#475467',
          700: '#344054',
          800: '#182230',
          900: '#101828',
        },
        success: {
          bg: '#DCFAE6',
          main: '#079455',
          strong: '#074D31',
        },
        error: '#AF1B3F',
        'error-secondary': '#FEE4E2',
      },
      height: {
        120: '30rem',
        156: '39rem',
      },
      maxHeight: {
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        '9/10': '90%',
        'rem-54': '54rem',
        'screen-8/10': '80vh',
      },
      maxWidth: {
        '1/4': '25%',
        '1/3': '33%',
        '1/2': '50%',
        '3/4': '75%',
        '9/10': '90%',
        1920: '1920px',
      },
      width: {
        35: '35rem',
        40: '40rem',
        50: '50rem',
        '3/10': '30%',
        '4/10': '40%',
        'gutter-4': 'calc(100% + 2rem)',
        'gutter-7': 'calc(100% + 3.5rem)',
      },
      minHeight: {
        '1/2-screen': '50vh',
      },
      boxShadow: {
        sm: '0px 0px 10px 1px rgba(0, 0, 0, 0.06)',
        DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        md: '0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.06)',
        lg: '-2px 4px 45px rgba(0, 0, 0, 0.13)',
        xl: '0 20px 25px 5px rgba(0, 0, 0, 0.1), 0 10px 10px 5px rgba(0, 0, 0, 0.04)',
        '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
        inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
        none: 'none',
        't-sm': '0 -1px 2px 0 rgba(0, 0, 0, 0.05)',
        't-md':
          '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        't-lg':
          '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        't-xl':
          '0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        't-2xl': '0 -25px 50px -12px rgba(0, 0, 0, 0.25)',
        't-3xl': '0 -35px 60px -15px rgba(0, 0, 0, 0.3)',
      },
      screens: {
        hoverable: { raw: '(hover: hover)' },
      },
      keyframes: {
        slideInBottom: {
          '0%': { transform: 'translateY(20px)', opacity: 0 },
          '100%': { transform: 'translateY(0);', opacity: 1 },
        },
        slideInLeft: {
          '0%': { transform: 'translateX(-20px)', opacity: 0 },
          '100%': { transform: 'translateX(0);', opacity: 1 },
        },
        slideInRight: {
          '0%': { transform: 'translateX(20px)', opacity: 0 },
          '100%': { transform: 'translateX(0);', opacity: 1 },
        },
        fadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
      },
      animation: {
        'slide-in-bottom': 'slideInBottom 0.4s ease-out',
        'slide-in-left': 'slideInLeft 0.4s ease-out',
        'slide-in-right': 'slideInRight 0.4s ease-out',
        'fade-in-opacity': 'fadeIn 0.4s ease-out',
      },
    },
  },
  plugins: [],
}

