<template>
  <div v-editable="blok" class="flex flex-col py-4 md:pb-8 sm:px-3">
    <div v-if="blok.image.filename" class="relative mb-2 p-6">
      <img alt="" class="w-full h-48" :src="blok.image.filename" />
    </div>
    <span
      class="uppercase font-medium text-md tracking-wide text-rendin-500 text-center md:text-left"
      >{{ blok.title_badge }}</span
    >
    <h2 class="text-xl mb-2 leading-tight mt-2 font-medium text-center md:text-left">
      {{ blok.title }}
    </h2>
    <p class="text-center md:text-left mb-4 text-gray-800">
      {{ blok.text }}
    </p>
    <div v-if="blok.link_path" class="mt-auto text-center md:text-left">
      <nuxt-link
        class="text-gray-600 hover:text-rendin-500 font-semibold inline w-auto pb-1"
        :to="$localizedPath(blok.link_path)"
      >
        {{ blok.link_text }} →
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetPartyType',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
