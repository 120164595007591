import { useStore } from 'vuex';
import type { VueI18n } from 'vue-i18n';
import type { ComputedRef } from 'vue';

export default defineNuxtPlugin({
  name: 'i18n',
  async setup(nuxtApp) {
    const store = useStore();

    // Bit ugly, but this should always exist on nuxtApp, same issue found here: https://github.com/nuxt-modules/i18n/issues/2955
    // Perhaps we should just not use store for that anymore
    const i18n = nuxtApp.$i18n as VueI18n;

    await store.dispatch('setLocale', i18n.locale);
    nuxtApp.hook('i18n:localeSwitched', async ({ newLocale }) => {
      await store.dispatch('setLocale', newLocale);
    });

    // Yes this typing isn't nice, for some reason type from VueI18n is wrong. Likely when we can move away from Options API this will work
    const getLocale = (): string => {
      const locale = i18n.locale as unknown as ComputedRef<string>;
      return locale.value;
    };

    const switchLocalizedPath = (localeCode: string, path: string) => {
      if (localeCode === getLocale()) {
        return path;
      }

      const getDefaultLocale = nuxtApp.$getDefaultLocale as () => string;

      const locale = getLocale();

      const pathWithoutPrefix = path[0] === '/' ? path.slice(1) : path;

      if (locale === getDefaultLocale()) {
        return `/${localeCode}${pathWithoutPrefix.length > 0 ? '/' : ''}${pathWithoutPrefix}`;
      } else if (localeCode === getDefaultLocale()) {
        if (pathWithoutPrefix === `${getLocale()}`) {
          return '/';
        } else {
          return `/${pathWithoutPrefix.slice(3)}`;
        }
      } else {
        return `/${localeCode}/${pathWithoutPrefix.slice(3)}`;
      }
    };

    return {
      provide: {
        getLocale,
        switchLocalizedPath,
      },
    };
  },
});
