<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'PersonaCards',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      const classes = [];
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      } else {
        classes.push('bg-white');
      }
      return classes;
    },
  },
};
</script>

<template>
  <div class="flex justify-center py-24" :class="classes">
    <div class="w-11/12 px-8 flex flex-col">
      <div v-if="blok.header || blok.subHeader" class="flex justify-center pb-16">
        <div class="flex flex-col w-11/12 md:w-3/5">
          <p v-if="blok.header" class="text-5xl font-semibold text-center">
            {{ blok.header }}
          </p>
          <p v-if="blok.subHeader" class="text-center text-xl text-gray-600 pt-5">
            {{ blok.subHeader }}
          </p>
        </div>
      </div>
      <p v-if="blok.title" class="text-3xl font-semibold">{{ blok.title }}</p>
      <div
        class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
      >
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.cards.filter((item) =>
            ['PersonaCard'].includes(item.component),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
        />
      </div>
    </div>
  </div>
</template>
