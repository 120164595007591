<template>
  <div>
    <VeeForm v-slot="{ meta: formMeta, handleSubmit }">
      <form
        autocomplete="off"
        class="flex flex-col gap-3"
        @submit.prevent="handleSubmit(() => submitProfile(formMeta.valid))"
      >
        <Field v-slot="{ meta }" v-model="email" rules="required|email" name="email">
          <r-input
            id="email"
            v-model="email"
            :add-on="showEmailChange ? $t('buttons.change') : ''"
            add-on-class="text-rendin-500 font-semibold cursor-pointer hover:text-rendin-600 focus:text-rendin-600"
            class="mt-3"
            disabled
            :error-text="$t('form.validation.email')"
            field-type="email"
            for="email"
            :label="$t('form.label.email')"
            name="email"
            :placeholder="$t('form.placeholder.email')"
            :validation-failed="!meta.valid"
            :validation-passed="meta.valid"
            @click-add-on="openEmailChangeModal()"
          />
        </Field>
        <Field
          v-slot="{ errors, meta }"
          v-model="firstName"
          validate-on-mount
          rules="required|min:2|isNotUsedCyrillic"
          :bails="false"
          name="firstName"
        >
          <r-input
            id="firstName"
            v-model="firstName"
            class="mt-3"
            :error-text="$t('form.validation.first_name')"
            :errors="errors"
            field-type="text"
            for="firstName"
            :label="$t('form.label.first_name')"
            name="firstName"
            :placeholder="$t('form.placeholder.first_name')"
            :validation-failed="!meta.valid"
            :validation-passed="meta.valid"
          />
        </Field>
        <Field
          v-slot="{ meta, errors }"
          v-model="lastName"
          :bails="false"
          validate-on-mount
          rules="required|min:2|isNotUsedCyrillic"
          name="lastName"
        >
          <r-input
            id="lastName"
            v-model="lastName"
            class="mt-3"
            :error-text="$t('form.validation.last_name')"
            :errors="errors"
            field-type="text"
            for="lastName"
            :label="$t('form.label.last_name')"
            name="lastName"
            :placeholder="$t('form.placeholder.last_name')"
            :validation-failed="!meta.valid"
            :validation-passed="meta.valid"
          />
        </Field>
        <Field
          v-slot="{ meta }"
          v-model="phoneNumberData"
          :rules="`required|isValidTelInput:${phoneNumberData.isValid}`"
          name="phoneNrFull"
        >
          <r-input-tel
            id="phoneNrFull"
            v-model="phoneNumberData"
            class="mt-3"
            :default-country="$getCountry()"
            :error-text="$t('form.validation.phone')"
            for="phoneNrFull"
            :label="$t('form.label.phone')"
            name="phoneNrFull"
            :placeholder="$t('form.placeholder.your_phone')"
            :prefilled-input="prefilledPhoneNumber"
            :prefilled-input-country-code="prefilledPhoneCountryCodeIso"
            :validation-failed="validateEventTriggered ? !meta.valid : null"
            :validation-passed="validateEventTriggered ? meta.valid : null"
            @focus="validateEventTriggered = true"
          />
        </Field>
        <div class="buttons flex gap-3">
          <r-button no-background width="full" @click="closeTab">
            {{ $t('buttons.cancel') }}
          </r-button>
          <r-button type="submit" width="full">
            <span v-if="loading">
              <r-loader class="flex justify-center" size="20" />
            </span>
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </r-button>
        </div>
      </form>
    </VeeForm>

    <EmailChangeModal
      :show-modal="showEmailChangeModal"
      @close-overlay="showEmailChangeModal = false"
    />
  </div>
</template>
<script>
import { trim, get } from 'lodash';
import EmailChangeModal from '~/components/modals/EmailChangeModal.vue';
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { Field, Form as VeeForm } from 'vee-validate';

export default {
  name: 'TenantPersonalInformationTab',
  components: {
    Field,
    VeeForm,
    EmailChangeModal,
  },
  props: {
    profile: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['submit', 'close'],

  async setup() {
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return { getAnalyticEventNames, trackTenantProfileEvent };
  },
  data: () => ({
    validateEventTriggered: false,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumberData: {
      countryCallingCode: undefined,
      countryCode: undefined,
      nationalNumber: undefined,
      isValid: undefined,
    },
    prefilledPhoneNumber: '',
    prefilledPhoneCountryCodeIso: '',
    showEmailChangeModal: false,
    showEmailChange: true,
  }),
  watch: {
    profile() {
      if (this.profile) {
        this.initializeForm(this.profile);
      }
    },
  },
  beforeMount() {
    if (this.profile) {
      this.initializeForm(this.profile);
    }
  },
  methods: {
    initializeForm(initialData) {
      this.firstName = initialData.firstName;
      this.lastName = initialData.lastName;
      this.email = initialData.email;
      this.prefilledPhoneNumber = initialData.phoneNumber;
      this.prefilledPhoneCountryCodeIso = initialData.phoneCountryCodeIso;
    },
    submitProfile(isValid) {
      // validate parameter for disabling initial isValidTelInput false validation
      this.validateEventTriggered = true;

      if (isValid) {
        this.$emit('submit', {
          firstName: trim(this.firstName),
          lastName: trim(this.lastName),
          email: trim(this.email),
          phoneCountryCode: get(this.phoneNumberData, 'countryCallingCode'),
          phoneCountryCodeIso: get(this.phoneNumberData, 'countryCode'),
          phoneNumber: get(this.phoneNumberData, 'nationalNumber'),
        });
        this.trackTenantProfileEvent({
          eventName:
            this.getAnalyticEventNames.TENANT_PROFILE_EDIT_PERSONAL_INFORMATION,
        });
      }
    },
    closeTab() {
      this.$emit('close');
      this.initializeForm(this.profile);
    },

    openEmailChangeModal() {
      this.showEmailChangeModal = true;
    },
  },
};
</script>
