import { values, isUndefined, negate, omit, indexOf } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import '../advertisements/index.js';
import { DigibrokerSuccessModalType } from '~/utils/constants.js';
import { getLocationFromDefaultAddressObject } from '~/utils/objectStructures';

const stateDefaults = {
  uploadedImagesStates: {},
};

/**
 * @typedef {string} ImageUploadStateId
 */

/**
 * @return {{
 * bucketId: string,
 * uploadedImagesStates: {
 *   [id: string]: {
 *    uploading: boolean,
 *    uploadedImageUrl: string,
 *   }
 * },
 * showDigibrokerSuccessModal: boolean
 }}
 */
export const state = () => ({
  bucketId: uuidv4(),
  uploadedImagesStates: { ...stateDefaults.uploadedImagesStates },
  showDigibrokerSuccessModal: false,
  digibrokerSuccessModalType: DigibrokerSuccessModalType.LISTING,
  digibrokerSuccessModalParent: null,
  digibrokerSuccessModalInviteLink: '',
  digibrokerSuccessModalInviteAddress: '',
});

const isDefined = negate(isUndefined);

export const getters = {
  _uploadBucketId: (state) => state.bucketId,
  uploadedImagesStates: (state) => state.uploadedImagesStates,
  uploadedImages: (state) =>
    values(state.uploadedImagesStates)
      .map((o) => o.uploadedImageUrl)
      .filter(isDefined)
      .map((item) => `${item.substr(0, item.lastIndexOf('.'))}.jpg`),
  uploadedImagesIsAnyUploading: (state) =>
    indexOf(
      values(state.uploadedImagesStates).map((o) => o.uploading),
      true,
    ) >= 0,
  _uploadedImagesCloudinaryPublicIds: (state) =>
    values(state.uploadedImagesStates)
      .map((o) => o.cloudinary)
      .filter(isDefined)
      .map((c) => c.public_id)
      .filter(isDefined),
  showDigibrokerSuccessModal: (state) => state.showDigibrokerSuccessModal,
  successModalParent: (state) => state.digibrokerSuccessModalParent,
  digibrokerSuccessModalInviteLink: (state) => state.digibrokerSuccessModalInviteLink,
  digibrokerSuccessModalInviteAddress: (state) =>
    state.digibrokerSuccessModalInviteAddress,
  digibrokerSuccessModalType: (state) => state.digibrokerSuccessModalType,
};

export const mutations = {
  UPSERT_UPLOADED_IMAGES_STATE: function (
    state,
    { id, uploading, uploadedImageUrl = undefined, cloudinary = undefined },
  ) {
    const _uploadedImageUrl = uploadedImageUrl
      ? { uploadedImageUrl: uploadedImageUrl }
      : {};
    const _cloudinary = cloudinary ? { cloudinary: cloudinary } : {};
    state.uploadedImagesStates = {
      ...state.uploadedImagesStates,
      [`${id}`]: { uploading: uploading, ..._uploadedImageUrl, ..._cloudinary },
    };
  },
  REMOVE_UPLOADED_IMAGE_IN_STATE: function (state, { id }) {
    state.uploadedImagesStates = { ...omit({ ...state.uploadedImagesStates }, id) };
  },
  SET_DIGIBROKER_SUCCESS_MODAL: function (state, { visible, type }) {
    state.digibrokerSuccessModalType = type;
    state.showDigibrokerSuccessModal = visible;
  },
  SET_SUCCESS_MODAL_PARENT: function (state, parent) {
    state.digibrokerSuccessModalParent = parent;
  },
  SET_DIGIBROKER_SUCCESS_MODAL_INVITE_LINK: function (state, link) {
    state.digibrokerSuccessModalInviteLink = link;
  },
  SET_DIGIBROKER_SUCCESS_MODAL_INVITE_ADDRESS: function (state, address) {
    state.digibrokerSuccessModalInviteAddress = address;
  },
};

export const actions = {
  /**
   * @param {boolean} visible
   * @param {source} parent
   */
  setDigibrokerSuccessModal({ commit }, { visible, parent, type }) {
    commit('SET_SUCCESS_MODAL_PARENT', parent);
    commit('SET_DIGIBROKER_SUCCESS_MODAL', { visible, type });
  },
  setDigibrokerSuccessModalInvite({ commit }, { link, address }) {
    commit('SET_DIGIBROKER_SUCCESS_MODAL_INVITE_LINK', link);
    commit('SET_DIGIBROKER_SUCCESS_MODAL_INVITE_ADDRESS', address);
  },
  /**
   * @param {string} uploadFilePath
   * @param {boolean} operationAsync - set to true if id of the upload is to be returned.
   * @return {Promise<{uploadedImageUrl:string}>|id:ImageUploadStateId}
   */
  _uploadImage: function (
    { dispatch, getters, rootGetters, commit },
    { uploadFilePath, operationAsync },
  ) {
    const formData = new FormData();
    formData.append('file', uploadFilePath);
    formData.set('_bucket_id', getters._uploadBucketId);
    const fileUploadId = uuidv4();
    commit('UPSERT_UPLOADED_IMAGES_STATE', { id: fileUploadId, uploading: true });
    const uploadImageAndTrackState = () =>
      /* TODO - deprecated action, should be removed entirely later, but out of scope now - this endpoint does not exist anymore */
      this.$axios
        .$post('/api/adds-lite/upload-image', formData, {
          headers: { 'Content-Type': `multipart/form-data` },
        })
        .then((res) => {
          commit('UPSERT_UPLOADED_IMAGES_STATE', {
            id: fileUploadId,
            uploading: false,
            uploadedImageUrl: res.uploadedImageUrl,
            cloudinary: res.cloudinary,
          });
          console.log('_uploadImage');
          return Promise.allSettled([
            { ...res },
            dispatch('tracker/trackDigibrokerUploadImageEvent', undefined, {
              root: true,
            }),
          ]);
        })
        .then(([imagesResult, _]) => imagesResult.value)
        .catch((err) => {
          commit('UPSERT_UPLOADED_IMAGES_STATE', {
            id: fileUploadId,
            uploading: false,
          });
          if (rootGetters.isENVIsDevelopment) {
            console.error(
              `Requested to upload: '${uploadFilePath}' error: '${err.message}'`,
            );
          }
          return dispatch('tracker/reportErrorToSentry', err, { root: true }).then(
            () => undefined,
          ); // In case of error, return undefined.
        });
    if (operationAsync) {
      uploadImageAndTrackState();
      return fileUploadId;
    } else {
      return uploadImageAndTrackState();
    }
  },
  /**
   * @param {string} uploadFilePath
   * @return {Promise<{uploadedImageUrl: string}>}
   */
  uploadImageSync: function ({ dispatch }, { uploadFilePath }) {
    return dispatch('_uploadImage', {
      uploadFilePath: uploadFilePath,
      operationAsync: false,
    });
  },
  /**
   * @param {string} uploadFilePath
   * @return {ImageUploadStateId}
   */
  uploadImageAsync: function ({ dispatch }, { uploadFilePath }) {
    return dispatch('_uploadImage', {
      uploadFilePath: uploadFilePath,
      operationAsync: true,
    });
  },
  /**
   * @param {ImageUploadStateId} id - id of an image to remove
   * @return {undefined}
   */
  removeImage: function ({ dispatch, commit }, { id }) {
    commit('REMOVE_UPLOADED_IMAGE_IN_STATE', { id: id });
    return dispatch('tracker/trackDigibrokerRemoveImageEvent', undefined, {
      root: true,
    });
  },
  /**
   * @typedef {string} FirebaseId
   */

  /**
   * @param {FirebaseId|undefined} id
   * @param {AddData} addData
   * @param {sourceType}
   * @return {Promise<FirebaseId|undefined>}
   */
  _upsertAddToFirebase: function ({ dispatch, rootGetters }, { id, addData, source }) {
    // Secure Ad creation only when in session.
    if (!rootGetters['users/hasSessionUser']) {
      return Promise.resolve(undefined);
    }
    const transformAddDataPostPutAdvertisementActionRequest = (addData) => {
      return {
        addressFirstLine: addData.addressFirstLine,
        addressStreet: addData.addressStreet,
        addressCity: addData.addressCity,
        addressCountry: addData.addressCountry,
        addressIndex: addData.addressIndex,
        addressHouseNumber: addData.addressHouseNumber,
        addressApartmentNumber: addData.addressApartmentNumber,
        addressHouseNumberHidden: addData.addressHouseNumberHidden,
        addressCityDistrict: addData.addressCityDistrict,

        floor: addData.floor,
        floorsTotal: addData.floorsTotal,
        objectArea: addData.objectArea,
        numberOfRooms: addData.numberOfRooms,
        hasParking: addData.hasParking, // TODO: by Sven Kirsimae: do we need to manage null value?
        hasStorage: addData.hasStorage, // TODO: by Sven Kirsimae: do we need to manage null value?
        description: addData.description,
        rentAmount: addData.rentAmount, // TODO: by Sven Kirsimae: needs to be number in protocol?
        insuranceInvoiceTo: addData.insuranceInvoiceTo,
        petsAllowed: addData.petsAllowed, // TODO: by Sven Kirsimae: do we need to manage null value?
        images: addData.images,
        propertyType: addData.propertyType
          ? addData.propertyType.toUpperCase()
          : undefined,

        location: getLocationFromDefaultAddressObject(
          addData,
          addData.internalUserEditedAddressManually,
        ),
      };
    };
    return id
      ? dispatch(
          'advertisements/putAdvertisementFromDigibroker',
          {
            id: id,
            advertisement: transformAddDataPostPutAdvertisementActionRequest(addData),
            source: source,
          },
          { root: true },
        ).then(() => id)
      : dispatch(
          'advertisements/postAdvertisementFromDigibroker',
          {
            advertisement: transformAddDataPostPutAdvertisementActionRequest(addData),
          },
          { root: true },
        ).then(({ failed, response }) => (failed ? undefined : response)); // Response is the firebase id
  },

  /**
   * @param {String|null} firebaseId
   * @param {AddData} addData
   * @param {sourceType} source
   * @return {Promise<String>}
   */
  upsertAdd: function (
    { dispatch, getters, rootGetters },
    { firebaseId, addData, source },
  ) {
    return Promise.resolve()
      .then(() =>
        dispatch('_upsertAddToFirebase', {
          id: firebaseId,
          addData: addData,
          source: source,
        }),
      )
      .then((firebaseId) => firebaseId);
  },

  /**
   * @param {String|null} firebaseId
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<String>}
   */
  finalizeAdd: function ({ dispatch, rootGetters }, { firebaseId, source }) {
    const finalizeAddAtFirebase = () =>
      dispatch(
        'advertisements/putAdvertisementStatusAsActive',
        { id: firebaseId, source: source },
        { root: true },
      );
    return Promise.resolve()
      .then(() => finalizeAddAtFirebase())
      .then(() => firebaseId);
  },
};
