<template>
  <div v-if="score" class="background-check-small-label">
    <!--    APPROVED    -->
    <div v-if="componentSize === componentSizes.SMALL">
      <div v-if="score === scoreStatusApproved" class="flex mt-4">
        <r-icon class="text-xl text-green-600 mr-2" icon-name="shield-check" />

        <span class="text-sm text-gray-800 inline">
          <span class="r-rich-text inline" v-html="approvedLabelRendered"> </span>
        </span>
      </div>

      <!--    DECLINED    -->
      <div v-if="score === scoreStatusDeclined" class="flex mt-4">
        <r-icon class="text-xl text-error mr-2 -mt-0.5" icon-name="user-large-slash" />
        <span class="text-sm text-gray-800 inline">
          <span class="r-rich-text inline" v-html="declinedLabelRendered"> </span>
          <span
            class="text-rendin-500 underline hover:text-rendin-600 focus:text-rendin-600"
            @click="showBackgroundCheckModal = true"
          >
            {{ backgroundCheckDeclinedModalLink }}
          </span>
        </span>
      </div>

      <!--    PENDING    -->
      <div v-if="score === scoreStatusPending" class="flex mt-4">
        <r-icon class="text-xl text-yellow-400 mr-2" icon-name="clipboard-list-check" />
        <span class="text-sm text-gray-800 inline">
          <span class="r-rich-text inline" v-html="pendingLabelRendered"> </span>
          <span
            class="text-rendin-500 underline hover:text-rendin-600 focus:text-rendin-600"
            @click="showBackgroundCheckModal = true"
          >
            {{ backgroundCheckPendingModalLink }}
          </span>
        </span>
      </div>
    </div>
    <BackgroundCheckFailedModal
      :background-check="score"
      :component-size="componentSize"
      :visible="showBackgroundCheckModal"
      @close="showBackgroundCheckModal = false"
    />
  </div>
</template>

<script>
import BackgroundCheckFailedModal from '~/components/modals/BackgroundCheckFailedModal';
import { ComponentSize, BackgroundCheckResult } from '~/utils/constants';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';

export default {
  name: 'BackgroundCheckSmallLabel',
  components: {
    BackgroundCheckFailedModal,
  },

  props: {
    /**
     * score | [ `APPROVED`, `PENDING`, `DECLINED`  ]
     */
    score: {
      type: String,
      default: null,
    },
    backgroundCheckApprovedLabel: {
      type: [String, Object],
      default: '',
    },
    backgroundCheckPendingLabel: {
      type: [String, Object],
      default: '',
    },
    backgroundCheckPendingModalLink: {
      type: String,
      default: '',
    },
    backgroundCheckDeclinedLabel: {
      type: [String, Object],
      default: '',
    },
    backgroundCheckDeclinedModalLink: {
      type: String,
      default: '',
    },
    componentSize: {
      type: String,
      default: ComponentSize.SMALL,
    },
  },
  setup() {
    return {
      localizedRenderRichText,
    };
  },

  data() {
    return {
      showBackgroundCheckModal: false,
      componentSizes: ComponentSize,
      scoreStatusPending: BackgroundCheckResult.PENDING,
      scoreStatusDeclined: BackgroundCheckResult.DECLINED,
      scoreStatusApproved: BackgroundCheckResult.APPROVED,
    };
  },
  computed: {
    approvedLabelRendered() {
      return this.localizedRenderRichText(
        this.backgroundCheckApprovedLabel,
        this.$localizedPath,
      );
    },
    declinedLabelRendered() {
      return this.localizedRenderRichText(
        this.backgroundCheckDeclinedLabel,
        this.$localizedPath,
      );
    },
    pendingLabelRendered() {
      return this.localizedRenderRichText(
        this.backgroundCheckPendingLabel,
        this.$localizedPath,
      );
    },
  },
};
</script>

<style lang="scss">
.background-check-small-label .r-rich-text {
  div,
  p {
    display: inline;
  }
}
</style>
