<script>
import { mapActions } from 'vuex';

const widgets = {
  FEEDBACK: 'FEEDBACK',
  LEADS_GENERATOR: 'LEADS_GENERATOR',
};

const triggerTypes = {
  TIME_ON_PAGE: 'TriggerByTimeOnPage',
  OPEN_POPUP_ON_LEAVE: 'TriggerOpenPopupOnMouseLeave',
  SCROLL_DEPTH: 'TriggerByScrollDepth',
};

const errorPromiseCancelled = 'cancelled';

export default {
  name: 'WidgetTrigger',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cancellationToken: null,
      abortController: null, // Used to clean up lingering event listeners
    };
  },
  mounted() {
    if (!this.blok.triggers) return;

    const cancellationPromise = new Promise((resolve, reject) => {
      this.cancellationToken = reject;
    });

    this.abortController = new AbortController();

    const triggerPromises = this.setupTriggers(this.blok.triggers);
    const popupTriggerPromises = this.setupTriggers(this.blok.popupTriggers);

    /* Final trigger for showing widget BUTTON (STEP 1) */
    Promise.race([cancellationPromise, Promise.any([...triggerPromises])])
      .then(() => {
        this.fireEvent();
      })
      .catch((err) => {
        if (err === errorPromiseCancelled) return;
        this.reportErrorToSentry(err);
      });

    /* We only want to trigger popup, if atleast one trigger there is used */
    if (popupTriggerPromises.length > 0) {
      /* Final trigger for showing widget MODAL or any other FINAL state (STEP 2) */
      Promise.race([
        cancellationPromise,
        Promise.all([...triggerPromises, ...popupTriggerPromises]),
      ])
        .then(() => {
          this.firePopupEvent();
        })
        .catch((err) => {
          if (err === errorPromiseCancelled) return;
          this.reportErrorToSentry(err);
        });
    }
  },
  beforeUnmount() {
    if (this.cancellationToken) {
      this.cancellationToken(errorPromiseCancelled);
    }
    this.abortController.abort();
  },
  methods: {
    ...mapActions({
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      showFeedbackWidget: 'showFeedbackWidget',
      toggleFeedbackWidgetModal: 'toggleFeedbackWidgetModal',
      trackTriggersFireTrigger: 'tracker/trackTriggersFireTrigger',
      showLeadGeneratorWidget: 'showLeadGeneratorWidget',
    }),
    /**
     * Function, which returns a list of promises. If all are resolved, then trigger conditions are solved
     * @return Promise[]
     */
    setupTriggers(triggers) {
      const promises = [];

      if (import.meta.server) {
        this.reportErrorToSentry(
          new Error('Triggers should only be setup in client to avoid memory leaks!'),
        );
        return [];
      }

      if (!triggers) {
        return [];
      }

      for (let i = 0; i < triggers.length; i++) {
        try {
          const trigger = triggers[i];

          /* Trigger will fire X seconds after component is loaded */
          if (trigger.component === triggerTypes.TIME_ON_PAGE) {
            const promise = new Promise((resolve) =>
              setTimeout(resolve, trigger.timeOnPage * 1000),
            );
            promises.push(promise);
          } else if (trigger.component === triggerTypes.OPEN_POPUP_ON_LEAVE) {
            /* Trigger enables popup opening on mouse leave */
            const promise = new Promise((resolve) => {
              const onMouseLeave = () => {
                resolve();
              };
              document.body.addEventListener('mouseleave', onMouseLeave, {
                signal: this.abortController.signal,
              });
            });
            promises.push(promise);
          } else if (trigger.component === triggerTypes.SCROLL_DEPTH) {
            const promise = new Promise((resolve) => {
              const onScroll = () => {
                const scrollDepthRatio = Math.min(
                  1,
                  (window.innerHeight + window.pageYOffset) /
                    document.body.offsetHeight,
                );
                if (scrollDepthRatio >= trigger.minScrollDepth / 100) {
                  resolve();
                }
              };
              window.addEventListener('scroll', onScroll, {
                signal: this.abortController.signal,
              });
            });
            promises.push(promise);
          }
        } catch (err) {
          this.reportErrorToSentry(err);
        }
      }
      return promises;
    },
    fireEvent() {
      const triggerNames = this.blok.triggers.map((trigger) => {
        return trigger.component;
      });
      this.trackTriggersFireTrigger({ triggers: triggerNames });

      if (this.blok.widget === widgets.FEEDBACK) {
        this.showFeedbackWidget();
      } else if (this.blok.widget === widgets.LEADS_GENERATOR) {
        this.showLeadGeneratorWidget();
      }
    },

    firePopupEvent() {
      const popupTriggerNames = this.blok.popupTriggers.map((popupTriggers) => {
        return popupTriggers.component;
      });

      this.trackTriggersFireTrigger({ triggers: popupTriggerNames });
      if (this.blok.widget === widgets.FEEDBACK) {
        this.toggleFeedbackWidgetModal(true);
      } else if (this.blok.widget === widgets.LEADS_GENERATOR) {
        this.showLeadGeneratorWidget();
      }
    },
  },
  /* We don't need any visuals for this component */
  render() {
    return '';
  },
};
</script>
