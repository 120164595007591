<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    :prevent-close="copyAgreementInProgress"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="px-4 sm:px-8 pt-10 pb-4">
      <div class="text-center">
        <h3 class="text-xl font-medium mb-2">
          {{ $t('agreement.dialog.duplicate_agreement.title') }}
        </h3>
        <div v-if="duplicatedAgreementStatusIsSigned && isPLAgreement">
          <p class="break-words whitespace-pre-line">
            {{
              $t('agreement.dialog.duplicate_agreement.duplicate_with_tenant_content')
            }}
          </p>
        </div>
        <div v-else>
          <p class="break-words whitespace-pre-line">
            {{ $t('agreement.dialog.duplicate_agreement.content') }}
          </p>
        </div>
      </div>
    </div>

    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <div v-if="duplicatedAgreementStatusIsSigned && isPLAgreement">
          <div
            class="flex flex-col justify-center items-center gap-4 md:gap-2 mt-8 mb-4"
          >
            <r-button
              v-if="!copyAgreementInProgress"
              border-color="light-gray"
              color="black"
              inverted
              :is-loading="copyAgreementInProgress"
              :label="$t('agreement.dialog.duplicate_agreement.start_with_new_tenant')"
              size="small"
              width="full"
              @click="
                copyAgreementInProgress ? null : duplicateAgreement(agreementData)
              "
            />
            <r-button
              :is-loading="copyAgreementInProgress"
              :label="
                $t('agreement.dialog.duplicate_agreement.duplicate_with_current_tenant')
              "
              size="small"
              width="full"
              @click="
                copyAgreementInProgress ? null : duplicateAgreement(agreementData, true)
              "
            />
          </div>
        </div>
        <div
          v-else
          class="flex flex-col justify-center items-center gap-4 md:gap-2 mt-8 mb-4"
        >
          <r-button
            v-if="!copyAgreementInProgress"
            border-color="light-gray"
            color="black"
            inverted
            :is-loading="copyAgreementInProgress"
            :label="$t('buttons.cancel')"
            size="small"
            width="full"
            @click="closeOverlay()"
          />
          <r-button
            :is-loading="copyAgreementInProgress"
            :label="$t('buttons.duplicate')"
            size="small"
            width="full"
            @click="duplicateAgreement(agreementData)"
          />
        </div>
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { agreementPLTypes, agreementStatuses } from '~/utils/constants';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DuplicateAgreementModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementData: {
      type: Object,
      default: null,
    },
  },
  emits: ['closeOverlay'],

  data: () => {
    return {
      copyAgreementInProgress: false,
      agreementTrackingEventNames,
      trackingProcessNames,
    };
  },

  computed: {
    ...mapGetters({
      profileId: 'users/profileId',
    }),

    duplicatedAgreementStatusIsSigned() {
      const activeStatus = [
        agreementStatuses.READY_TO_SIGN,
        agreementStatuses.PRE_SIGNING,
        agreementStatuses.SIGNED,
        agreementStatuses.ACTIVE,
        agreementStatuses.FINISHED,
      ];

      return activeStatus.includes(this.agreementData?.status);
    },

    isPLAgreement() {
      return Object.values(agreementPLTypes).includes(this.agreementData.agreementType);
    },
  },

  methods: {
    ...mapActions({
      actionCopyAgreement: 'agreements/copyAgreement',
      actionPutAgreement: 'agreements/putAgreement',
      actionAgreementCoTenantAdd: 'agreements/agreementCoTenantAdd',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    async duplicateAgreement(agreementData, duplicateWithTenants) {
      this.copyAgreementInProgress = true;

      const copyAgreementRequest = await this.actionCopyAgreement({
        id: agreementData.id,
      });
      const duplicatedAgreementId = copyAgreementRequest.response;

      if (duplicateWithTenants) {
        const putTenantToAgreement = () => {
          const agreementWithTenant = {
            tenantProfileId: agreementData.tenantProfileId,
          };

          return this.actionPutAgreement({
            id: duplicatedAgreementId,
            agreement: { ...agreementWithTenant },
          });
        };

        const putTenantPromise = putTenantToAgreement();

        const addCoTenantToAgreement = (coTenantId) => {
          return this.actionAgreementCoTenantAdd({
            agreementId: duplicatedAgreementId,
            profileId: coTenantId,
          });
        };

        const coTenantsProfileIds =
          agreementData?.coTenants?.map((coTenant) => coTenant.profileId) || [];

        const coTenantPromises = coTenantsProfileIds.map((coTenantId) =>
          addCoTenantToAgreement(coTenantId),
        );

        // We can add all id-s simultaneously, so we set them up first and then wait for all to be completed
        await Promise.all([putTenantPromise, ...coTenantPromises]);
      }

      const customProperties = {
        oldAgreementId: agreementData.id,
        newAgreementId: duplicatedAgreementId,
        creationFlow: duplicateWithTenants ? 'DUPLICATE WITH TENANTS' : 'DUPLICATE',
      };

      const agreementDataForNew = {
        id: duplicatedAgreementId,
        creatorProfileId: this.profileId,
        workflow: agreementData.workflow,
      };

      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.CREATE_NEW,
        this.trackingProcessNames.AGREEMENT,
        customProperties,
        agreementDataForNew,
      );

      if (duplicatedAgreementId) {
        this.$router.push({
          path: this.$localizedPath(`/dashboard/agreement/${duplicatedAgreementId}`),
        });
        this.copyAgreementInProgress = false;
      }
    },

    emitAgreementTrackerEvent(event, processName, customProperties, agreement) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: processName,
        agreement: agreement || null,
        props: customProperties,
      });
    },

    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
});
</script>
