import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('lazy-background', {
    mounted(el: HTMLElement, binding) {
      const imageUrl: string = binding.value;

      // Create an IntersectionObserver instance
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
              // Set the background image when element is in the viewport
              el.style.backgroundImage = `url('${imageUrl}')`;

              // Stop observing once the image has been loaded
              observer.unobserve(el);
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.1,
        },
      );

      // Start observing the element
      observer.observe(el);
    },
  });

  nuxtApp.vueApp.directive('intersection', {
    mounted(el: HTMLElement, binding) {
      const options = {
        threshold: binding.value.threshold || 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const classes = Array.isArray(binding.value.class)
              ? binding.value.class
              : binding.value.class.split(' ');

            classes.forEach((className: string) => {
              el.classList.add(className);
            });

            observer.disconnect();
          }
        });
      }, options);

      observer.observe(el);
    },
  });
});
