<template>
  <div
    v-editable="blok"
  >
    <r-accordion
      class="bg-opacity-0"
      :flavor="!blok.initialDesign ? 'plain' : 'shadow'"
      :title="blok.title"
      :title-class="!blok.initialDesign ? 'text-rendin-500' : ''"
      :trimmed-title="!blok.initialDesign"
    >
      <RichText
        v-if="blok.content"
        :blok="blok.content"
        class="-my-4"
      />
      <div v-if="blok.contentInBloks?.length > 0">
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.contentInBloks.filter(item => ['RichTextBlok', 'Accordion', 'ButtonBlok'].includes(item.component))"
          :key="theBlok._uid"
          :blok="theBlok"
        />
      </div>
    </r-accordion>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
