<template>
  <div
    v-editable="blok"
    :class="{ 'border-red-700 border-2 m-1 p-1': !isENVIsProduction }"
  >
    <!-- Warning for when people try to make AB test without enabling "AB testing" on root Storyblok page, which will result in undefined behaviour   -->
    <template v-if="!isENVIsProduction">
      <div class="text-md">AB Testing Component</div>
      <p class="text-gray-500 text-sm">
        Note: Did you forget to enable AB Testing on root page?
      </p>
      <p class="text-gray-500 text-sm">
        Experiment ID: {{ blok.experiment.experimentFeatureFlagKey }}
      </p>
      <p class="text-gray-500 text-sm">
        Variation Group ID: {{ blok.experiment.variationGroupId }}
      </p>
    </template>
    <!--  This component should not be seen in regular circumstances, so as a final fallback we'll try to show "fallback" AB test content  -->
    <template v-if="blok.experiment.variationGroupId === fallbackVariationGroupId">
      <component
        :is="childBlok.component"
        v-for="childBlok in blok.content"
        :key="childBlok._uid"
        :blok="childBlok"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AbTest',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fallbackVariationGroupId: 'control',
    };
  },
  computed: {
    ...mapGetters({
      isENVIsProduction: 'isENVIsProduction',
    }),
  },
};
</script>
