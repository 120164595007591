<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="app-download-highlighted bg-gradient-to-r from-rendin-400 to-rendin-600 text-white py-10 md:py-16"
  >
    <div class="max-w-4xl mx-auto px-4 text-center">
      <h2 class="m-0 text-2xl font-extrabold text-white border-solid md:text-4xl mb-4">
        {{ blok.title }}
      </h2>

      <div class="max-w-xs mx-auto mt-4 md:mt-8">
        <AppButtonBlok :blok="blok.buttons[0]" />
      </div>
    </div>
  </section>
</template>

<script>
import AppButtonBlok from '~/components/snippets/AppButtonBlok.vue';

export default {
  name: 'AppDownloadHighlighted',
  components: { AppButtonBlok },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-download-highlighted__link a {
  color: white !important;
}
</style>
