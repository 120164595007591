<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="hero-header-section overflow-hidden relative"
    :class="classes"
  >
    <div
      class="max-w-6xl mx-auto md:grid-cols-2 md:grid md:gap-8 items-center pb-8 md:py-12 animate-fade-in-opacity"
    >
      <div class="hero-header-section__text p-4 md:py-6">
        <h1
          v-if="blok.title"
          class="my-4 font-semibold text-gray-900 text-4xl md:text-5xl"
        >
          <RichText :blok="blok.title" class="leading-tight" />
        </h1>

        <div class="mt-12">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.content.filter((item) =>
              ['TextBlok', 'RichTextBlok', 'FeatureCheckmarkList'].includes(
                item.component,
              ),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full text-lg md:text-xl"
          />
        </div>

        <div class="flex gap-4 flex-wrap justify-center md:justify-start mt-8 mb-4">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.buttons.filter((item) =>
              ['ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-auto"
          />
        </div>
        <div
          v-if="blok.buttomContent?.length > 0"
          class="flex gap-4 flex-wrap justify-center md:justify-start my-8"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.buttomContent.filter((item) =>
              ['RichTextBlok', 'ImageBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-auto"
          />
        </div>
      </div>

      <!--  Diagonal full height image-->
      <div
        v-if="
          blok.image?.filename &&
          blok.imageStyle === imageStyles.FULL_HEIGHT_DIAGONAL_RIGHT
        "
        class="p-4"
      >
        <!--        Desktop image-->
        <div
          class="hero-header-section__visual--diagonal hidden md:block absolute h-full w-1/2 top-0 bottom-0 left-1/2 overflow-hidden bg-no-repeat bg-cover bg-center"
          :style="{
            'background-image':
              'url(' +
              $transformStoryblokImage(blok.image.filename, '/m/fit-in/0x960') +
              ')',
          }"
        ></div>
        <!--        Mobile image-->
        <img
          :alt="blok.image.alt"
          class="md:hidden mx-auto"
          :src="$transformStoryblokImage(blok.image.filename, '/m/700x500/smart')"
        />
      </div>

      <!--  Rectangular image with rounded corners and background grid-->
      <div
        v-else-if="blok.image?.filename"
        class="p-4"
        :class="{
          'hero-header-section__image-left': blok.imageStyle === imageStyles.IMAGE_LEFT,
          'hero-header-section__image-bg--orange-dot-grid':
            blok.backgroundGrid === backgroundGrids.ORANGE_DOT_GRID,
        }"
      >
        <!--        Desktop image-->
        <img
          :alt="blok.image.alt"
          class="hidden md:block relative rounded-tr-7xl rounded-bl-7xl"
          :src="$transformStoryblokImage(blok.image.filename, '/m/560x670/smart/')"
        />
        <!--        Mobile image-->
        <img
          :alt="blok.image.alt"
          class="md:hidden rounded-tr-4xl rounded-bl-4xl mx-auto"
          :src="$transformStoryblokImage(blok.image.filename, '/m/700x500/smart')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

const imageStyles = {
  IMAGE_RIGHT: 'default',
  IMAGE_LEFT: 'imageLeft',
  FULL_HEIGHT_DIAGONAL_RIGHT: 'fullHeight',
};
const backgroundGrids = {
  ORANGE_DOT_GRID: 'orangeDotGrid',
};

export default {
  name: 'HeroHeaderSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imageStyles,
      backgroundGrids,
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      } else {
        classes.push('bg-peach-200');
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
.hero-header-section {
  &__text {
    z-index: 1;
  }

  &__image-left {
    @apply -order-1;
  }

  &__image-bg--orange-dot-grid {
    @apply relative;

    &::before {
      @media screen and (min-width: 768px) {
        @apply absolute block;
        content: '';
        height: 135%;
        width: 135%;
        top: -15%;
        left: -15%;
        background-image: url('/images/dots-grid.png');
      }
    }
  }

  &__visual--diagonal {
    @media screen and (min-width: 768px) {
      clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  .underlined {
    @apply relative inline-block;
    // Google Translate adds font tags and that removed correct word spacing
    > font {
      margin: 0 0.25em;
    }

    &::before {
      @apply absolute block bg-no-repeat bg-contain;
      content: '';
      width: 100%;
      height: 20px;
      top: 85%;
      left: 0;
      z-index: -1;
    }

    &--short::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-short.svg');
    }

    &--medium::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-medium.svg');
    }

    &--long::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-long.svg');
    }
  }
}
</style>
