<template>
  <nuxt-link :to="targetRoute" @click="$emit('click')">
    <BaseNotificationTwoLine
      :created="notification?.created"
      :first-interacted-time="notification?.data.notification.firstInteractedTime"
      :subtitle="notificationSubtitle"
      :title="notificationBlok.title"
    />
  </nuxt-link>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseNotificationTwoLine from '~/components/snippets/notifications/BaseNotificationTwoLine.vue';
import { redirectLinks, userRole } from '~/utils/constants';

export default {
  name: 'NotificationFillProfile',
  components: { BaseNotificationTwoLine },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    notificationBlok: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
    }),
    notificationSubtitle() {
      if (!this.notificationBlok.subtitle) return '';
      return this.notificationBlok.subtitle;
    },
    targetRoute() {
      if (!this.notification) return '';

      if (this.userRole === userRole.LANDLORD) {
        return this.$localizedPath(redirectLinks.LANDLORD_PROFILE_SETTINGS);
      } else if (this.userRole === userRole.TENANT) {
        return this.$localizedPath(redirectLinks.TENANT_PROFILE_SETTINGS);
      }

      return '';
    },
  },
};
</script>
