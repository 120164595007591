<template>
  <div
    v-editable="blok"
    class="w-full"
    :class="classes"
  >
    <hr :class="separatorClasses" />
  </div>
</template>
<script>
import { ComponentSize, borderColors } from '~/utils/constants';

export default {
  name: 'Separator',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.blok.size === ComponentSize.MEDIUM) {
        classes.push('py-12');
      } else if (this.blok.size === ComponentSize.SMALL) {
        classes.push('py-6');
      } else if (this.blok.size === ComponentSize.LARGE) {
        classes.push('py-20');
      } else if (this.blok.size === ComponentSize.XS) {
        classes.push('py-1');
      }

      return classes;
    },

    separatorClasses() {
      const classes = [];
      if (this.blok.separatorColor === borderColors.GRAY_300) {
        classes.push('border-gray-300');
      } else if (this.blok.separatorColor === borderColors.WHITE) {
        classes.push('border-gray-500');
      } else if (this.blok.separatorColor === borderColors.RENDIN_500) {
        classes.push('border-rendin-500');
      }

      return classes;
    },
  },
};
</script>
