import { v4 as uuidv4 } from 'uuid';
import { defineNuxtPlugin } from '#app';
import { useStore } from 'vuex';

class TestingSuite {
  constructor({ distinctId, client, enabledFeatureFlags }) {
    this._distinctId = distinctId;
    this._client = client;
    // any flags provided via this will override server-side feature flags
    this._enabledFeatureFlags = enabledFeatureFlags || {};
  }

  async getFeatureFlag({ experimentId }) {
    if (import.meta.client) {
      const featureFlag = this._client.getFeatureFlag(experimentId);
      return featureFlag;
    } else {
      /* Since Posthog Node itself doesn't have persistence for user feature flags, we try to first compute locally using provided data */
      let featureFlag = this._computeFeatureFlagLocally(experimentId);

      if (featureFlag === false) {
        featureFlag = await this._client.getFeatureFlag(
          experimentId,
          this._distinctId,
          {
            onlyEvaluateLocally: true,
          },
        );
      }

      return featureFlag;
    }
  }

  _computeFeatureFlagLocally(experimentId) {
    if (!this._enabledFeatureFlags || !this._enabledFeatureFlags[experimentId]) {
      return false;
    }
    return this._enabledFeatureFlags[experimentId];
  }

  async getAllFlags() {
    if (import.meta.server) {
      const posthogFeatureFlags = await this._client.getAllFlags(this._distinctId, {
        onlyEvaluateLocally: true,
      });
      return { ...posthogFeatureFlags, ...this._enabledFeatureFlags };
    }

    throw new Error('Tried to call getAllFlags in client!');
  }
}
export default defineNuxtPlugin(async (nuxtApp) => {
  const store = useStore();
  const config = useRuntimeConfig();

  let testingSuite = null;

  if (import.meta.server) {
    const token = config.public.POSTHOG;

    const persistenceCookieKey = `ph_${token}_posthog`;

    const persistenceCookie = useCookie(persistenceCookieKey);

    let distinctId = '';

    if (persistenceCookie.value) {
      distinctId = persistenceCookie.value.distinct_id;
    } else {
      distinctId = uuidv4();
      /* This is first time load for this user (no previous identity data available) */
      store.commit('testing/SET_SERVER_FIRST_TIME_LOAD', true);
    }
    store.commit('testing/SET_SERVER_DISTINCT_ID', distinctId);
    testingSuite = new TestingSuite({
      distinctId: distinctId,
      client: nuxtApp.$posthogServerSide,
    });
    const allFlags = await testingSuite.getAllFlags();
    store.commit('testing/SET_SERVER_BOOTSTRAP', true);
    store.commit('testing/SET_SERVER_FEATURE_FLAGS', allFlags);
  } else {
    testingSuite = new TestingSuite({
      client: nuxtApp.$posthog,
    });
  }

  return {
    provide: {
      testingSuite: testingSuite,
    },
  };
});
