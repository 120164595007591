<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="comparison pt-16 pb-10 md:py-20 bg-white text-gray-600"
    :class="[blok.style === style.PURPLE ? 'comparison--purple' : 'comparison--orange']"
  >
    <div class="max-w-5xl mx-auto">
      <div
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="lg:w-2/3 mx-auto text-center mb-10 px-4 opacity-0"
      >
        <h2 v-if="blok.title">
          <!-- Fallback for field type text-->
          <span
            v-if="typeof blok.title === 'string'"
            class="text-2xl md:text-4xl leading-tight mb-6 font-bold text-gray-900"
            v-html="blok.title"
          ></span>
          <RichText
            v-if="typeof blok.title === 'object'"
            :blok="blok.title"
            class="text-4xl md:text-5xl leading-tight mb-6 text-gray-900"
            plain
          />
        </h2>
      </div>

      <RichText
        v-if="blok.description"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        :blok="blok.description"
        class="lg:w-2/3 mx-auto text-center mb-10 px-4 opacity-0"
      />

      <div
        v-if="blok.depositValue"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="mt-10 sm:mb-10 px-8 overflow-hidden opacity-0"
      >
        <r-slider
          v-model="depositValue"
          :interval="sliderStep"
          :label="blok.depositSliderLabel"
          :locale="$store.state.locale"
          :max="Number(depositMax)"
          :min="Number(depositMin)"
          :plain="!blok.depositSliderLabel"
          :unit="blok.unit"
        />
      </div>

      <div
        v-if="blok.style === style.PURPLE"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="mx-auto text-center my-12 opacity-0"
      >
        <div
          v-if="blok.protectionAmountLabel"
          class="font-semibold text-gray-700 text-2xl md:text-3xl"
        >
          {{ blok.protectionAmountLabel }}
        </div>
        <div
          v-if="blok.protectionAmountMultiplier && depositValue"
          class="font-semibold text-gray-900 text-4xl md:text-5xl my-2 underlined--blue"
        >
          {{
            Intl.NumberFormat($store.state.locale).format(
              depositValue * blok.protectionAmountMultiplier,
            )
          }}
          <span class="text-2xl">{{ blok.unit }}</span>
        </div>
      </div>

      <table
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="table-auto w-full sm:px-3 relative opacity-0 max-w-4xl mx-auto"
        :class="{ 'max-w-4xl mx-auto': blok.hideOtherColumn }"
      >
        <thead scope="col">
          <tr
            class="flex text-left"
            :class="{ 'border-b border-gray-200': blok.style === style.PURPLE }"
          >
            <th
              class="hidden sm:block p-4 py-6"
              :class="{
                'w-1/2 sm:w-1/3': !blok.hideOtherColumn,
                'w-1/2': blok.hideOtherColumn,
              }"
              scope="row"
            ></th>
            <th
              class="p-4 py-6 font-normal text-center sm:border-t sm:border-l sm:border-r sm:rounded-tr-2xl sm:rounded-tl-2xl flex justify-center"
              :class="{
                'w-1/2 sm:w-1/3 comparison__rendin-logo': !blok.hideOtherColumn,
                'w-full sm:w-1/2 comparison__rendin-logo_col_2': blok.hideOtherColumn,
              }"
              scope="col"
            >
              <img class="h-6" src="~/assets/rendin-logo.svg" />
            </th>
            <th
              v-if="!blok.hideOtherColumn"
              class="w-1/2 p-4 py-6 font-medium text-center"
              :class="{
                'sm:w-1/3': !blok.hideOtherColumn,
                'sm:w-1/2': blok.hideOtherColumn,
              }"
              scope="col"
            >
              <span class="mt-1 block">{{ blok.competitor_title }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(row, index) in blok.table_rows"
            :key="row.uuid"
            v-editable="row"
            class="comparison__row"
            :class="{ 'comparison__row--title': row.component === 'rowForTitle' }"
          >
            <!--          Title row-->
            <template v-if="row.component === 'rowForTitle'">
              <th
                class="comparison__col comparison__col--head"
                :class="{
                  'w-1/2': blok.hideOtherColumn,
                  'sm:w-1/3': !blok.hideOtherColumn,
                }"
                scope="row"
              >
                {{ row.title }}
              </th>
              <td
                class="comparison__col comparison__col--rendin"
                :class="{
                  'w-1/2': blok.hideOtherColumn,
                  'sm:w-1/3': !blok.hideOtherColumn,
                }"
              ></td>
              <td
                v-if="!blok.hideOtherColumn"
                class="w-1/2 comparison__col comparison__col--other"
                :class="{
                  'w-1/2': blok.hideOtherColumn,
                  'sm:w-1/3': !blok.hideOtherColumn,
                }"
              ></td>
            </template>

            <!--          Content row-->
            <template v-else>
              <th
                class="comparison__col comparison__col--head"
                :class="{
                  'w-1/2': blok.hideOtherColumn,
                  'sm:w-1/3': !blok.hideOtherColumn,
                }"
                scope="col"
              >
                {{ row?.title }}
                <span v-if="row?.showInfoModal">
                  <r-icon
                    :id="row?.modalId"
                    class="text-xl text-rendin-200 hover:text-rendin-400 focus:text-rendin-400 ml-2 cursor-pointer"
                    icon-name="circle-question"
                    @click="[(showOverlay = !showOverlay), (activeModal = index)]"
                  />
                </span>
              </th>
              <td
                class="comparison__col comparison__col--rendin"
                :class="{
                  'comparison__col--last': blok.table_rows.length - 1 === index,
                  'w-1/2 sm:w-1/3': !blok.hideOtherColumn,
                  'w-full sm:w-1/2': blok.hideOtherColumn,
                }"
              >
                <template v-if="row.rendinValuePrefix">
                  {{ row.rendinValuePrefix }}
                </template>
                <template v-if="row.rendin?.toLowerCase() === 'yes'">
                  <r-icon
                    class="bg-green-500 text-white w-6 h-6 rounded-full flex justify-center items-center"
                    icon-name="check"
                    prefix="solid"
                  />
                </template>
                <template v-else-if="row.rendin?.toLowerCase() === 'no'">
                  <span class="capitalize">{{ $t('no') }}</span>
                </template>
                <template v-else-if="row.rendin">
                  {{ row.rendin }}
                </template>

                <template v-else-if="row.calculateRendinFee">
                  {{
                    rendinFeeCalculation(depositValue, country).toLocaleString(
                      getLocale,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )
                  }}
                  <span v-if="row?.unit" class="px-1">{{ row.unit }}</span>
                  <span v-else-if="blok.unit" class="px-1">{{ blok.unit }}</span>
                </template>
                <template v-else-if="row.rendinDepositMultiplier">
                  {{
                    Intl.NumberFormat($store.state.locale).format(
                      row.rendinDepositMultiplier * depositValue,
                    )
                  }}
                  <span v-if="row?.unit" class="px-1">
                    {{ row.unit }}
                  </span>
                  <span v-else-if="blok.unit" class="px-1">
                    {{ blok.unit }}
                  </span>
                </template>

                <template v-else>
                  {{ row.rendin }}
                </template>
              </td>
              <td
                v-if="!blok.hideOtherColumn"
                class="comparison__col comparison__col--other"
                :class="{
                  'w-1/2': blok.hideOtherColumn,
                  'w-1/2 sm:w-1/3': !blok.hideOtherColumn,
                }"
              >
                <template v-if="row.rendinValuePrefix">
                  {{ row.rendinValuePrefix }}
                </template>
                <template v-if="row.others?.toLowerCase() === 'yes'">
                  <r-icon
                    class="bg-green-500 text-white w-6 h-6 rounded-full flex justify-center items-center"
                    icon-name="check"
                    prefix="solid"
                  />
                </template>
                <template v-else-if="row.others?.toLowerCase() === 'no'">
                  <span class="capitalize">{{ $t('no') }}</span>
                </template>
                <template v-else-if="row.others">
                  {{ row.others }}
                </template>

                <template v-else-if="row.rendinDepositMultiplier">
                  {{
                    Intl.NumberFormat($store.state.locale).format(
                      row.othersDepositMultiplier * depositValue,
                    )
                  }}
                  <span v-if="row.unit" class="px-1">
                    {{ row.unit }}
                  </span>
                  <span v-else-if="blok.unit" class="px-1">
                    {{ blok.unit }}
                  </span>
                </template>

                <template v-else>
                  {{ row.others }}
                </template>
              </td>
            </template>
          </tr>
        </tbody>
      </table>

      <div
        v-for="btn in blok.button"
        :key="btn.uuid"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="flex justify-center mt-8 opacity-0"
      >
        <div v-if="btn && btn.component === 'ButtonBlok'">
          <ButtonBlok ref="cta_button" :blok="btn" />
        </div>
      </div>
      <r-overlay size="small" :visible="showOverlay" @close="showOverlay = false">
        <div v-for="(row, index) in blok.table_rows" :key="row._uid">
          <div v-if="activeModal === index" class="p-4 pt-8 md:pt-10 md:pb-4 md:px-10">
            <h3 class="text-center font-bold text-lg mb-8">
              {{ row.title }}
            </h3>

            <RichText
              :blok="row.modalContent"
              class="my-8"
              @rich-text-link-click="showOverlay = false"
            />

            <r-button
              display="block"
              :label="$t('buttons.close')"
              no-background
              uppercase
              @click="showOverlay = false"
            />
          </div>
        </div>
      </r-overlay>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import RSlider from '~/components/r-interface-components/RSlider.vue';
import ROverlay from '~/components/r-interface-components/r-overlay';
import RButton from '~/components/r-interface-components/r-button';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';

const style = {
  ORANGE: 'orange',
  PURPLE: 'purple',
};

export default {
  name: 'Comparison',
  components: {
    RSlider,
    ROverlay,
    RButton,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      rendinFeeCalculation,
    };
  },

  data() {
    return {
      depositValue: Number(this.blok.depositValue),
      depositMin: Number(this.blok.depositMin),
      depositMax: Number(this.blok.depositMax),
      sliderStep: Number(this.blok.sliderStep),
      showOverlay: false,
      activeModal: null,
      style,
    };
  },

  computed: {
    ...mapGetters({
      country: 'getCountry',
      getLocale: 'getLocale',
    }),
  },

  mounted() {
    const that = this;
    const ctaButton =
      this.$refs.cta_button && this.$refs.cta_button[0].$el
        ? this.$refs.cta_button[0].$el
        : '';
    if (ctaButton) {
      ctaButton.addEventListener(
        'click',
        function () {
          that.$trackEvent(
            'Click CTA',
            {
              pagePath: that.$route.fullPath,
              content: ctaButton.innerText,
              source: 'comparison table',
            },
            {
              services: ['POSTHOG', 'GTAG'],
            },
          );
        },
        false,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.comparison {
  &--purple {
    @apply bg-violet-25;

    @media screen and (min-width: 640px) {
      .comparison__rendin-logo {
        &::after {
          content: '';
          border: 2px solid #764ba2;
          box-shadow: 0px 12px 64px -12px rgba(89, 37, 220, 0.27);

          @apply sm:w-1/3 absolute h-full block top-0 border-2 rounded-2xl;
        }
      }
      .comparison__rendin-logo_col_2 {
        &::after {
          content: '';
          border: 2px solid #764ba2;
          box-shadow: 0px 12px 64px -12px rgba(89, 37, 220, 0.27);

          @apply sm:w-1/2 absolute h-full block top-0 border-2 rounded-2xl;
        }
      }
    }
  }

  &--orange {
    @apply bg-white;

    @media screen and (min-width: 640px) {
      .comparison__rendin-logo {
        &::after {
          content: '';
          @apply w-1/2 sm:w-1/3 absolute h-full block top-0 border-2 rounded-2xl border-rendin-500 shadow-lg;
        }
      }
      .comparison__rendin-logo_col_2 {
        &::after {
          content: '';
          @apply w-1/2 absolute h-full block top-0 border-2 rounded-2xl border-rendin-500 shadow-lg;
        }
      }
    }
  }

  &__row {
    @apply flex text-left flex-wrap bg-white;

    .comparison--purple & {
      @apply sm:even:bg-grayBlue-100 text-sm;
    }

    &--title {
      @apply hidden sm:flex;
    }
  }

  &__col {
    @apply px-2 py-4 sm:px-4 text-gray-600 flex items-center justify-center text-center;

    .comparison--orange & {
      @apply border-t border-gray-200;
    }

    &--head {
      @apply min-w-full sm:min-w-0 sm:text-left font-medium sm:flex sm:items-center sm:justify-between;

      .comparison--purple & {
        @apply bg-blue-25 sm:bg-transparent;
      }

      .comparison--purple .comparison__row--title & {
        @apply text-violet-900 bg-violet-25 text-base font-bold;
      }

      .comparison--orange & {
        @apply bg-gray-100 sm:bg-transparent;
      }
    }

    &--rendin {
      @apply font-semibold border-r;

      .comparison--orange & {
        @apply bg-rendin-50;
        border-top-color: #fbece5;
      }

      .comparison--purple & {
        @apply bg-white bg-opacity-50;
      }

      .comparison--purple .comparison__row--title & {
        @apply bg-white;
      }
    }

    &--last {
      @apply sm:rounded-br-2xl sm:rounded-bl-2xl;
    }

    &--other {
      .comparison--purple .comparison__row--title & {
        @apply text-violet-900 bg-violet-25;
      }
    }
  }

  .underlined--blue {
    @apply relative inline-block;

    &::before {
      @apply absolute block bg-no-repeat bg-contain;
      content: '';
      width: 130%;
      height: 20px;
      top: 100%;
      left: -15%;
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-blue.svg');
    }
  }
}
</style>
