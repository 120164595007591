<template>
  <section
    :id="blok.section_id"
    v-editable="blok"
    class="py-20 md:py-36 border-b border-gray-200 px-3 md:px-0"
  >
    <div class="mx-auto max-w-6xl">
      <div
        class="flex flex-col md:flex-row items-center w-full"
        :class="{ 'md:flex-row-reverse': blok.text_position === 'right' }"
      >
        <div class="flex-1 w-full sm:px-3">
          <div :class="{ 'w-11/12': blok.text_position === 'left' }">
            <span
              v-if="blok.title_badge"
              class="uppercase font-medium tracking-wide text-rendin-500"
              >{{ blok.title_badge }}</span
            >
            <h2 class="text-3xl md:text-4xl leading-tight mb-4 mt-1 font-bold">
              {{ blok.title }}
            </h2>
            <p v-if="blok.text" class="text-lg text-gray-800">
              {{ blok.text }}
            </p>

            <RichText v-if="blok.rich_text" :blok="blok.rich_text" />

            <component
              :is="blok.component"
              v-for="blok in blok.checkmark_list"
              :key="blok._uid"
              :blok="blok"
            />

            <component
              :is="blok.component"
              v-for="blok in blok.testimonial"
              :key="blok._uid"
              :blok="blok"
            />

            <div v-if="blok.button && blok.button[0]" class="mt-10">
              <ButtonBlok :blok="blok.button[0]" />
            </div>
          </div>
        </div>

        <div class="flex-1 justify-right flex h-full w-full sm:px-3 mt-10 md:mt-0">
          <component
            :is="blok.component"
            v-for="blok in blok.block_list"
            :key="blok._uid"
            :blok="blok"
          />
          <component
            :is="blok.component"
            v-for="blok in blok.section_image"
            :key="blok._uid"
            :blok="blok"
          />

          <!-- <div class="text-center" v-if="blok.feature_image">
            <div
              class="hidden md:block"
              :class="{'flex justify-center': blok.text_position === 'left', 'w-11/12': blok.text_position === 'right' && blok.feature_image.filename === 'https://a.storyblok.com/f/103362/2560x1536/035669b428/test.png'}"
              :style="blok.feature_image.filename === 'https://a.storyblok.com/f/103362/2560x1536/035669b428/test.png' ? styleObjectContainer : ''"
            >
              <img
                :class="{'rounded-md shadow-lg border border-gray-300': blok.feature_image.filename === 'https://a.storyblok.com/f/103362/2560x1536/035669b428/test.png'}"
                :src="blok.feature_image.filename"
                :style="blok.feature_image.filename === 'https://a.storyblok.com/f/103362/2560x1536/035669b428/test.png' ? {'margin-left': '-150px'} : ''"
              />
            </div>

            <div class="block md:hidden w-full h-full">
              <img class="w-full h-full" :src="blok.feature_image.filename" />
            </div>
          </div> -->
        </div>
      </div>

      <div v-if="blok.button_center && blok.button_center[0]" class="mt-10 text-center">
        <ButtonBlok :blok="blok.button_center[0]" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionFeature',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      styleObjectContainer: {
        width: '120%',
      },
    };
  },
};
</script>
