<template>
  <div
    class="p-4 md:py-12 flex flex-col"
    :class="{
      'bg-gradient-to-r from-rendin-400 to-rendin-600': isBackgroundOrange,
      'bg-gray-50': isBackgroundGray,
    }"
  >
    <r-title-with-text
      content-font-size="16px"
      :rich-text-content="blok.content"
      text-align="center"
      :title="blok.title"
      title-font-size="24px"
    />
    <div
      class="mx-auto w-full"
      :style="{ 'max-width': blok.videoWidth + 'px', height: blok.videoHeight + 'px' }"
    >
      <r-video
        v-if="blok.embedVideoLink.cached_url && blok.videoHeight && blok.videoWidth"
        class="flex justify-center"
        :embed-video-link="blok.embedVideoLink.cached_url"
        :video-height="blok.videoHeight"
        :video-width="blok.videoWidth"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageVideo',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isBackgroundOrange() {
      if (this.blok) {
        return this.blok.backgroundColor === 'orange';
      }
      return false;
    },
    isBackgroundGray() {
      if (this.blok) {
        return this.blok.backgroundColor === 'gray';
      }
      return false;
    },
  },
};
</script>
