declare global {
  interface Window {
    dataLayer: undefined | unknown[];
    gtag: undefined | object;
  }
}

interface Gtag {
  (
    command: 'config',
    targetId: string,
    config?: ControlParams | EventParams | CustomParams | never,
  ): void;
  (command: 'set', config: CustomParams | boolean): void;
  (command: 'set', targetId: string, config: CustomParams | boolean): void;
  (command: 'js', config: Date): void;
  (
    command: 'event' | 'consent',
    eventName: EventNames | string,
    eventParams?: ControlParams | EventParams | CustomParams,
  ): void;
}

interface CustomParams {
  [key: string]: string | boolean | number;
}

interface ControlParams {
  groups?: string | string[];
  send_to?: string | string[];
  send_page_view?: boolean;
  event_callback?: () => void;
  event_timeout?: number;
}

type EventNames =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results';

interface EventParams {
  checkout_option?: string;
  checkout_step?: number;
  content_id?: string;
  content_type?: string;
  coupon?: string;
  currency?: string;
  description?: string;
  fatal?: boolean;
  items?: Item[];
  method?: string;
  number?: string;
  promotions?: Promotion[];
  screen_name?: string;
  search_term?: string;
  shipping?: Currency;
  tax?: Currency;
  transaction_id?: string;
  value?: number;
  event_label?: string;
  event_category?: string;
}

type Currency = string | number;

interface Item {
  brand?: string;
  category?: string;
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  location_id?: string;
  name?: string;
  price?: Currency;
  quantity?: number;
}

interface Promotion {
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  name?: string;
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  window.dataLayer = window.dataLayer || [];

  // This here is required due to very ancient requirements of gtag - it expects Arguments object (array), not just an array
  // So ...args doesn't work here
  /* eslint-disable prefer-rest-params */
  function gtag() {
    window.dataLayer?.push(arguments);
  }

  const gtagTyped = gtag as Gtag;

  gtagTyped('js', new Date());

  gtagTyped('config', config.public.GOOGLE_ANALYTICS_ID, {
    send_page_view: true,
  });
  gtagTyped('config', config.public.GOOGLE_UNIVERSAL_ANALYTICS_ID, {
    send_page_view: true,
  });
  gtagTyped('config', config.public.GOOGLE_ADS_ID, {
    send_page_view: true,
  });

  gtagTyped('consent', 'default', {
    ad_personalization: 'denied',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 500,
  });
  gtagTyped('set', 'ads_data_redaction', true);
  gtagTyped('set', 'url_passthrough', true);

  window.gtag = window.gtag || gtagTyped;

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js`,
        type: 'text/javascript',
        async: true,
      },
    ],
  });
  return {
    provide: {
      gtag: gtagTyped,
    },
  };
});
