<template>
  <r-storyblok-link
    class="py-4 px-4 mega-menu hover:bg-gray-100 cursor-pointer"
    :storyblok-link="storyblokLink"
  >
    <div v-if="icon" class="mr-1">
      <r-icon class="text-rendin-500 pr-1 block h-5 w-6" :icon-name="icon" />
    </div>
    <div
      class="title text-sm font-medium col-start-2"
      :class="{ 'text-gray-600': secondaryColumn }"
    >
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="text-xs xl:text-sm font-normal text-gray-600 row-start-2 col-start-2"
    >
      {{ subtitle }}
    </div>
  </r-storyblok-link>
</template>
<script>
import { storyblokLinkTypes } from '~/utils/constants';

export default {
  name: 'MegaMenuColumnItem',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    storyblokLink: {
      type: Object,
      required: false,
      default: null,
    },
    secondaryColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      storyblokLinkTypes: storyblokLinkTypes,
    };
  },
};
</script>
<style>
.mega-menu {
  @apply items-center;

  display: grid;
  grid-row-gap: 4px;
  grid-template-columns: min-content 1fr;
}

.item-active .title {
  @apply text-rendin-500;
}
</style>
