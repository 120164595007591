<template>
  <ul v-editable="blok" class="md:ml-20">
    <li
      v-for="item in blok.items"
      :key="item._uid"
      v-editable="item"
      class="pl-3 my-5 border-l-4 border-gray-300"
    >
      <h3 class="text-lg font-medium">
        {{ item.title }}
      </h3>
      <p class="mt-1 text-gray-600">
        {{ item.description }}
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SnippetBlockList',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  }
}
</script>
