<template>
  <header
    v-editable="blok"
    class="px-3 md:px-6 border-b border-gray-200 bg-white"
    :class="{ 'bg-peach-200': isColorLightOrange(blok.backgroundColor) }"
  >
    <div class="mx-auto max-w-5xl py-8 md:py-16 md:pb-20">
      <h1
        class="m-0 text-4xl font-bold leading-none text-gray-800 border-solid sm:text-4xl md:text-5xl text-center"
      >
        {{ blok.title }}
      </h1>
      <p
        v-if="blok.sub_title"
        class="text-lg mt-4 text-gray-800 sm:text-lg lg:text-xl w-11/12 md:w-4/5 text-center mx-auto"
      >
        {{ blok.sub_title }}
      </p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SectionHeaderSimple',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isColorLightOrange(color) {
      return color === 'light-orange';
    },
  },
};
</script>
