<template>
  <section v-editable="blok" class="py-16 md:py-20 bg-white px-3 md:px-0">
    <div class="max-w-2xl mx-auto">
      <div
        v-for="blok in blok.items"
        :key="blok._uid"
        class="w-full mx-auto py-6 sm:px-3 text-left border-b border-gray-200 cursor-pointer relative group"
      >
        <nuxt-link
          v-editable="blok"
          class=""
          :to="$localizedPath(blok.page.cached_url)"
        >
          <svg
            aria-hidden="true"
            class="h-5 w-5 absolute right-2 sm:right-2 top-7 text-gray-400 group-hover:text-rendin-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 5l7 7m0 0l-7 7m7-7H3"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <h3 class="text-xl font-medium group-hover:text-rendin-500">
            {{ blok.title }}
          </h3>
          <p class="mt-1 text-gray-600">
            {{ blok.text }}
          </p>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BoxedContentList",
  props: {
    blok: {
      type: Object,
      required: true,
    },
  }
}
</script>
