import posthog from 'posthog-js';
import { useStore } from 'vuex';

export default defineNuxtPlugin(() => {
  const bootstrap: {
    distinctID: undefined | string;
    featureFlags: Record<string, string | boolean> | undefined;
  } = {
    distinctID: undefined,
    featureFlags: undefined,
  };
  const store = useStore();
  const config = useRuntimeConfig();

  if (store.getters['testing/bootstrap']) {
    if (store.getters['testing/serverFirstTimeLoad']) {
      bootstrap.distinctID = store.getters['testing/serverDistinctId'];
    }
    bootstrap.featureFlags = store.getters['testing/serverFeatureFlags'];
  }
  posthog.init(config.public.POSTHOG, {
    bootstrap: bootstrap,
    persistence: 'localStorage+cookie',
    api_host:
      config.public.rEnv !== 'production'
        ? 'https://demo.analytics.rendin.co'
        : 'https://analytics.rendin.co',
    capture_pageview: false,
    rageclick: true,
    disable_session_recording: false,
  });

  return {
    provide: {
      posthog: posthog,
    },
  };
});
