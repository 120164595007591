<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    header-class="bg-white z-10"
    :prevent-close="preventCloseModal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div v-if="loading" class="mt-8 flex flex-col text-center h-full p-4 sm:px-12">
      <r-loader type="animatedLogo" />
    </div>
    <div v-else class="mt-8 flex flex-col text-center h-full p-4 sm:px-12">
      <h3 class="font-medium text-lg mt-6 mb-4">
        {{ $t('agreement.dialog.finalized.title') }}
      </h3>
      <p>
        {{ $t('agreement.dialog.finalized.content.' + getCountry) }}
      </p>
    </div>
    <template #bottom-bar>
      <div v-if="!loading" class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button
          v-if="!preventCloseModal"
          data-testid="button.signing-modal.close"
          display="block"
          :label="$t('buttons.close')"
          @click="closeOverlay()"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SentToSigningModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    preventCloseModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
    }),
  },

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
