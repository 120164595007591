<template>
  <div
    v-editable="blok"
    class="relative flex flex-col md:flex-row w-full pt-6 md:w-1/2 md:pt-0 px-3"
  >
    <img
      class="box-border w-16 h-16 mt-1 text-gray-800 align-middle rounded-full border border-white shadow"
      :src="$transformStoryblokImage(blok.image.filename, '/m/100x100/smart')"
    />
    <div class="relative w-full md:px-4 text-left">
      <div
        class="box-border mt-4 md:mt-0 text-base md:text-base font-medium text-gray-800"
      >
        {{ blok.text }}
      </div>
      <div class="box-border mt-4 text-sm font-medium text-gray-600 uppercase">
        {{ blok.name }}
      </div>
      <div v-if="blok.story_link" class="mt-2">
        <nuxt-link
          class="text-rendin-500 text-md underline hover:no-underline font-medium"
          :to="$localizedPath(blok.story_link.cached_url)"
        >
          {{ blok.story_link_text }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetTestimonial',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
