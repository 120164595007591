<template>
  <section
    :id="blok.id"
    v-editable="blok"
    v-touch:swipe="swipeHandler"
    class="feedback-slider py-10 bg-gray-50 overflow-hidden"
  >
    <div class="max-w-6xl mx-auto px-4 sm:px-8 md:grid md:grid-cols-2 items-center">
      <div class="text-center md:text-left md:mr-10 lg:mr-20">
        <h2
          class="text-2xl md:text-4xl leading-tight mb-4 md:mb-6 font-bold text-rendin-500"
        >
          {{ blok.title }}
        </h2>
        <RichText :blok="blok.description" class="mb-8" />
      </div>
      <div
        class="feedback-slider__slide bg-white shadow-xl rounded p-4 sm:px-8 sm:pt-8"
      >
        <div class="flex justify-end">
          <r-icon
            class="text-4xl sm:text-7xl text-rendin-100"
            icon-name="quote-right"
            prefix="solid"
          />
        </div>

        <div class="flex flex-row flex-nowrap overflow-hidden">
          <div
            v-for="(feedback, index) in blok.feedback"
            :key="index"
            class="flex flex-col w-full flex-shrink-0"
            :class="index === activeSlide ? 'order-first' : ''"
          >
            <div class="flex-grow" :class="index === activeSlide ? 'fade-in' : ''">
              <div class="flex">
                <img
                  v-if="feedback.image.filename"
                  :alt="feedback.image.alt"
                  class="rounded-full w-14 h-14 mr-4"
                  :src="$transformStoryblokImage(feedback.image.filename, '/m/122x0/')"
                />
                <div>
                  <div class="text-lg font-bold">
                    {{ feedback.name }}
                  </div>
                  <div class="text-sm">
                    {{ feedback.position }}
                  </div>
                </div>
              </div>

              <RichText :blok="feedback.content" class="flex-grow" />
            </div>

            <div class="flex flex-row justify-between items-center">
              <div>
                <div
                  v-if="feedback.from === 'facebook'"
                  class="feedback-slider__feedback-label flex items-center"
                >
                  <r-icon class="text-2xl text-blue-400 mr-2" icon-name="fb-logo" />
                  <RichText :blok="blok.facebookFeedbackLabel" />
                </div>
              </div>
              <div class="flex-shrink-0">
                <r-button
                  class="hover:no-underline focus:no-underline"
                  color="black"
                  no-background
                  @click="prevSlide()"
                >
                  <r-icon class="inline-block" icon-name="chevron-left" />
                </r-button>
                <r-button
                  class="hover:no-underline focus:no-underline"
                  color="black"
                  no-background
                  @click="nextSlide()"
                >
                  <r-icon class="inline-block" icon-name="chevron-right" />
                </r-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeedbackSlider',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeSlide: 0,
    };
  },
  methods: {
    nextSlide() {
      if (this.blok.feedback.length - 1 > this.activeSlide) {
        this.activeSlide = this.activeSlide + 1;
      } else {
        this.activeSlide = 0;
      }
    },
    prevSlide() {
      if (this.activeSlide > 0) {
        this.activeSlide = this.activeSlide - 1;
      } else {
        this.activeSlide = this.blok.feedback.length - 1;
      }
    },

    swipeHandler(direction) {
      if (direction === 'right') {
        this.prevSlide();
      } else if (direction === 'left') {
        this.nextSlide();
      }
    },
  },
};
</script>

<style lang="scss">
.feedback-slider {
  &__feedback-label {
    a {
      color: black !important;
    }

    p {
      margin: 0 !important;
    }
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 1s;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
