<template>
  <div v-editable="blok" :class="blok.bg_class">
    <component
      :is="pageBlok.component"
      v-for="pageBlok in blok.body"
      :key="pageBlok._uid"
      :blok="pageBlok"
    />
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
