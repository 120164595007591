<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="value-prop-cards pt-16 pb-10 md:py-20 bg-white overflow-hidden"
    :class="{ 'value-prop-cards--background-top': blok.backgroundColorTopMobile }"
    :style="{
      'background-image':
        'linear-gradient( ' +
        tailwingHexValue(blok.backgroundColorTopMobile) +
        ' 50%, ' +
        tailwingHexValue('white') +
        ' 50%)',
    }"
  >
    <div class="mx-auto px-4" :class="sectionClasses">
      <div
        v-if="blok.title"
        class="w-11/12 md:w-3/4 mx-auto text-center mb-10 sm:mb-16"
      >
        <h2 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
          {{ blok.title }}
        </h2>
      </div>

      <div class="bg-no-repeat bg-contain overflow-auto" :class="valuePropCardsClasses">
        <div
          v-for="(card, index) in blok.cards"
          :key="index"
          class="bg-white shadow-sm rounded px-6 py-6 pb-4 text-center flex flex-col"
          :class="[
            {
              'md:col-start-2':
                index === blok.cards.length - 1 &&
                Number.isInteger((blok.cards.length - 1) / 3),
            },
            { 'value-prop-cards__card--scroll': blok.horisontalScroll },
          ]"
        >
          <div :class="cardClasses(card.headerAlignment)">
            <div v-if="card.iconBlok" class="inline-block">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.iconBlok.filter((item) =>
                  ['IconBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>
            <div v-else-if="card.icon">
              <r-icon
                class="text-4xl text-rendin-500"
                :icon-name="normalizeIconName(card.icon)"
              />
            </div>

            <h3 class="font-medium text-lg my-4">
              {{ card.title }}
            </h3>
          </div>
          <RichText
            v-if="card.content"
            :blok="card.content"
            class="mb-4 flex-grow"
            :class="cardClasses(card.textAlignment)"
          />
          <div>
            <r-icon
              v-if="card.showInfoModal"
              :id="card.modalId"
              class="text-2xl text-rendin-200 hover:text-rendin-400 focus:text-rendin-400 cursor-pointer"
              icon-name="circle-question"
              @click="[(showOverlay = !showOverlay), (activeModal = index)]"
            />
          </div>
        </div>
      </div>

      <div v-if="blok.button.length > 0" class="mt-10 mb-6 flex justify-center">
        <div v-for="btn in blok.button" :key="btn.uuid">
          <div v-if="btn && btn.component === 'ButtonBlok'">
            <ButtonBlok ref="cta_button" :blok="btn" />
          </div>
        </div>
      </div>
    </div>

    <r-overlay
      bottom-bar
      size="small"
      :visible="showOverlay"
      @close="showOverlay = false"
    >
      <div v-for="(card, index) in blok.cards" :key="card._uid">
        <div v-if="activeModal === index" class="p-4 pt-8 md:pt-10 md:pb-4 md:px-10">
          <h3 class="text-center font-bold text-lg mb-8">
            {{ card.title }}
          </h3>

          <RichText :blok="card.modalContent" class="my-8" />
        </div>
      </div>
      <template #bottom-bar>
        <r-button
          display="block"
          :label="$t('buttons.close')"
          no-background
          uppercase
          @click="showOverlay = false"
        />
      </template>
    </r-overlay>
  </section>
</template>

<script>
import {
  Alignment,
  backgroundColorsHexValue,
  ComponentMaxWidth,
} from '~/utils/constants';

export default {
  name: 'ValuePropCards',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      activeModal: null,
    };
  },

  computed: {
    sectionClasses() {
      const classes = [];
      if (this.blok.sectionMaxWidth) {
        if (this.blok.sectionMaxWidth === ComponentMaxWidth.SMALL)
          classes.push('max-w-2xl');
        if (this.blok.sectionMaxWidth === ComponentMaxWidth.MEDIUM)
          classes.push('max-w-3xl');
        if (this.blok.sectionMaxWidth === ComponentMaxWidth.LARGE)
          classes.push('max-w-4xl');
        if (this.blok.sectionMaxWidth === ComponentMaxWidth.EXTRA_LARGE)
          classes.push('max-w-5xl');
      } else {
        classes.push('max-w-screen-lg');
      }

      return classes;
    },

    valuePropCardsClasses() {
      const classes = [];

      if (this.blok.horisontalScroll) {
        classes.push(
          'flex flex-row items-stretch gap-6 border-3 p-4 -mx-4 lg:grid lg:grid-cols-3',
        );
      } else {
        classes.push('grid sm:grid-cols-2 md:grid-cols-3 gap-6 -m-4 p-4');
      }

      if (this.blok.cuteBubblesBackground !== false) {
        classes.push('value-prop-cards__grid--bubbles');
      }

      return classes;
    },
  },

  methods: {
    /* Migrational step - we need to support both old and new Icon systems to migrate between them */
    /* TODO - report to sentry if any old names found (ref: r-icon) */
    normalizeIconName(iconName) {
      const lowercaseIconName = iconName.toLowerCase();
      switch (lowercaseIconName) {
        case 'arrow-enter':
          return 'arrow-turn-down-left';
        case 'approved':
          return 'user-shield';
        case 'chevron':
          return 'chevron-down';
        case 'devices':
          return 'laptop-mobile';
        case 'document':
          return 'file';
        case 'document-filled':
          return 'file-lines';
        case 'education':
          return 'graduation-cap';
        case 'euro':
          return 'euro-sign';
        case 'home-people':
          return 'house-chimney-user';
        case 'person-check':
          return 'user-check';
        case 'person-declined':
          return 'user-large-slash';
        case 'hands-helping':
          return 'handshake-angle';
        case 'protection-shield':
          return 'shield-halved';
        case 'shield-checked':
          return 'shield-check';
        case 'home-heart':
          return 'house-heart';
        case 'mobile-phone':
          return 'mobile';
        case 'quote':
          return 'quote-right';
        case 'zloty':
          return 'zloty-sign';
        case 'share':
          return 'share-nodes';
        case 'candidates':
          return 'user-group';
        case 'list-board':
          return 'clipboard-list-check';
        case 'email':
          return 'envelope';
        case 'house':
          return 'house-day';
      }

      return iconName;
    },

    cardClasses(textAlignment) {
      const classes = [];
      switch (textAlignment) {
        case Alignment.LEFT:
          classes.push('text-left');
          break;
        case Alignment.CENTER:
          classes.push('text-center');
          break;
        case Alignment.RIGHT:
          classes.push('text-right');
          break;
        case Alignment.JUSTIFY:
          classes.push('text-justify');
          break;
        default:
          classes.push('text-center');
          break;
      }

      return classes;
    },

    tailwingHexValue(color) {
      return backgroundColorsHexValue[color] ? backgroundColorsHexValue[color] : '#fff';
    },
  },
};
</script>

<style scoped lang="scss">
.value-prop-cards {
  &__grid--bubbles {
    background-image: url('/images/cute-circles.svg');
  }

  &__card--scroll {
    min-width: 270px;
  }

  &--background-top {
    @screen sm {
      background-image: none !important;
    }
  }
}
</style>
