<template>
  <div class="p-4 flex justify-center items-center w-full">
    <div class="flex flex-col gap-4 mt-12">
      <!--  Header  -->
      <div class="pl-10 flex-1 flex flex-col items-stretch justify-center gap-4">
        <h1 class="text-2xl font-bold text-left">{{ page.title }}</h1>
        <p class="text-base text-left">{{ page.subtitle }}</p>
      </div>
      <div class="flex-1">
        <div class="flex flex-col gap-6">
          <div v-for="(section, i) in sections" :key="i">
            <div class="step-text-container">
              <div class="flex items-center justify-center p-2">
                <div class="w-4 h-4 rounded-sm bg-rendin-500"></div>
              </div>
              <div class="flex items-center">
                <p class="font-bold">{{ section.title }}</p>
              </div>
              <div class="flex justify-center items-stretch">
                <div class="border-r-2 border-rendin-500"></div>
              </div>
              <div class="flex flex-col md:grid md:grid-cols-2 gap-4">
                <div class="flex flex-col">
                  <div
                    v-if="renderedSections[i]"
                    class="r-rich-text"
                    v-html="renderedSections[i]"
                  ></div>
                  <div>
                    <r-button
                      v-if="section?.modalContent?.content[0]?.content"
                      :label="section.modalButtonLabel || $t('read_more')"
                      no-background
                      no-padding
                      size="small"
                      @click="() => (currentOverlayIndex = i)"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <img class="max-w-sm w-full h-auto" :src="sectionImages[i]" />
                </div>
              </div>
            </div>
            <r-overlay
              size="small"
              :visible="currentOverlayIndex === i"
              @close="closeModal()"
            >
              <div class="p-8">
                <RichText v-if="section.modalContent" :blok="section.modalContent" />
              </div>
            </r-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';

export default {
  name: 'SAFEMobilePage',
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      localizedRenderRichText,
    };
  },
  data() {
    return {
      currentOverlayIndex: null,
    };
  },
  computed: {
    sectionImages() {
      if (!this.page.sections) {
        return [];
      }

      const transformedImageUrls = this.page.sections.map((section) => {
        return this.$transformStoryblokImage(section.image.filename, '/m/');
      });

      return transformedImageUrls;
    },
    sections() {
      if (!this.page || !this.page.sections) return [];

      return this.page.sections;
    },
    renderedSections() {
      if (!this.page || !this.page.sections) return [];

      return this.page.sections.map((section) => {
        return this.localizedRenderRichText(section.body, this.$localizedPath);
      });
    },
  },

  methods: {
    closeModal() {
      this.currentOverlayIndex = null;
    },
  },
};
</script>
<style scoped lang="scss">
.step-text-container {
  @apply gap-2;

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content minmax(1.5rem, min-content);
}
</style>
