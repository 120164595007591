<template>
  <div v-if="blok">
    <div
      v-if="blok.ratingLogosIntro.length > 0 || blok.ratingLogos.length > 0"
      class="px-4 py-12 grid justify-items-center"
      :class="{
        'bg-gradient-to-r from-rendin-400 to-rendin-600': isBackgroundColorOrange(
          blok.ratingLogosBackgroundColor,
        ),
        'bg-gray-50': isBackgroundColorGray(blok.ratingLogosBackgroundColor),
        'bg-lightgray': isBackgroundColorLightGray(blok.ratingLogosBackgroundColor),
        'bg-lightorange': isBackgroundColorLightOrange(blok.ratingLogosBackgroundColor),
        'py-24': blok.addTopAndButtonPadding,
      }"
    >
      <div class="max-w-6xl w-full">
        <div v-if="blok.ratingLogosIntro.length > 0" class="grid justify-items-center">
          <component
            :is="blok.component"
            v-for="blok in blok.ratingLogosIntro.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="blok._uid"
            :blok="blok"
            class="md:w-8/12"
          />
        </div>
        <div class="grid justify-items-center">
          <r-link-images
            :image-width="blok.raitingLogosSize"
            :logo-items="blok.ratingLogos"
            :max-columns="blok.ratingLogosMaxColumns"
          />
        </div>
      </div>
    </div>
    <div
      v-if="blok.companyLogosIntro.length > 0 || blok.companyLogos.length > 0"
      class="px-4 grid justify-items-center py-12 w-full"
      :class="{
        'bg-gradient-to-r from-rendin-400 to-rendin-600': isBackgroundColorOrange(
          blok.companyLogosBackgroundColor,
        ),
        'bg-gray-50': isBackgroundColorGray(blok.companyLogosBackgroundColor),
        'bg-lightgray': isBackgroundColorLightGray(blok.companyLogosBackgroundColor),
        'bg-lightorange': isBackgroundColorLightOrange(
          blok.companyLogosBackgroundColor,
        ),
        'horizontally-split-background-from-extra-light-peach-to-white':
          isBackgroundHorizontallySplit(blok.companyLogosBackgroundColor),
        'py-24': blok.addTopAndButtonPadding,
      }"
    >
      <div
        class="max-w-6xl w-full"
        :class="{ 'rounded-2xl shadow-lg bg-white': blok.addBorderToCompanyLogos }"
      >
        <div
          v-if="blok.companyLogosIntro.length > 0"
          class="grid justify-items-center w-full"
        >
          <component
            :is="blok.component"
            v-for="blok in blok.companyLogosIntro.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="blok._uid"
            :blok="blok"
            class="md:w-8/12"
          />
        </div>
        <div class="grid justify-items-center">
          <r-link-images
            class="md:w-11/12"
            :image-max-height="blok.companyLogosSize"
            :logo-items="blok.companyLogos"
            :max-columns="blok.companyLogosMaxColumns"
          />
        </div>
      </div>
    </div>
    <div
      v-if="blok.feedbackCardsIntro.length > 0 || blok.feedbackCards.length > 0"
      class="px-4 md:px-0 py-12 grid justify-items-center w-full"
      :class="{
        'bg-gradient-to-r from-rendin-400 to-rendin-600': isBackgroundColorOrange(
          blok.feedbackCardsBackgroundColor,
        ),
        'bg-gray-50': isBackgroundColorGray(blok.feedbackCardsBackgroundColor),
        'bg-lightgray': isBackgroundColorLightGray(blok.feedbackCardsBackgroundColor),
        'bg-lightorange': isBackgroundColorLightOrange(
          blok.feedbackCardsBackgroundColor,
        ),
        'py-24': blok.addTopAndButtonPadding,
      }"
    >
      <div class="max-w-6xl w-full">
        <div
          v-if="blok.feedbackCardsIntro.length > 0"
          class="grid justify-items-center w-full"
        >
          <component
            :is="blok.component"
            v-for="blok in blok.feedbackCardsIntro.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="blok._uid"
            :blok="blok"
            class="md:w-8/12"
          />
        </div>
        <div class="grid justify-items-center">
          <feedback-cards
            :cards-size="blok.feedbackCardsSize"
            class="md:w-11/12 xl:w-full"
            :feedback-cards="blok.feedbackCards"
            :max-columns="blok.feedbackCardsMaxColumns"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialProofComponent',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  methods: {
    isBackgroundColorOrange(backgroundColor) {
      return backgroundColor === 'orange';
    },
    isBackgroundColorGray(backgroundColor) {
      return backgroundColor === 'gray';
    },
    isBackgroundColorLightGray(backgroundColor) {
      return backgroundColor === 'light-gray';
    },
    isBackgroundColorLightOrange(backgroundColor) {
      return backgroundColor === 'light-orange';
    },
    isBackgroundHorizontallySplit(backgroundColor) {
      return backgroundColor === 'horizontally-split';
    },
  },
};
</script>

<style>
.horizontally-split-background-from-extra-light-peach-to-white {
  background: linear-gradient(to top, white 50%, #fcf9f7 50%);
}
</style>
