<template>
  <div class="candidate-list-candidate-info-card flex p-5 profile-card">
    <div v-if="candidate" class="flex flex-col flex-1 gap-2">
      <div class="flex flex-col gap-2 items-center">
        <div class="relative flex" style="width: 150px; height: 150px">
          <r-image
            class="bg-white"
            elevated
            rounded
            :src="
              tenantProfile.shareableProfileImage || '/images/no-profile-picture.png'
            "
          />
        </div>
        <div class="flex flex-col items-center">
          <p class="text-xl font-medium leading-tight">
            {{ [candidate.firstName, candidate.lastName].join(' ') }}
          </p>
        </div>
        <r-background-check-label
          :approved-status-tittle="$t('candidate.bgStatus.approved')"
          :background-status="candidate.candidacyStatus"
          :declined-status-tittle="$t('candidate.bgStatus.declined')"
          icon
          :not-available-status-tittle="$t('candidate.bgStatus.notAvailable')"
          :pending-status-tittle="$t('candidate.bgStatus.pending')"
          size="small"
        />
      </div>
      <div v-if="backgroundStatusMessage" class="text-center mt-4 z-80 text-base">
        <span>{{ backgroundStatusMessage }}</span>
        {{ ' ' }}
        <span
          v-if="showBackgroundStatusModal"
          class="inline text-nowrap text-rendin-500 font-bold hover:underline focus:underline"
          @click="openBackgroundExplainerModal"
        >
          {{ $t('read_more') }}
        </span>
      </div>

      <div v-if="isBackgroundStatusApproved" class="flex flex-col gap-3">
        <div class="profile-chips flex flex-wrap gap-2 my-2">
          <r-chip
            v-for="(chip, index) in profileChips"
            :key="index"
            :icon="chip.icon"
            :label="chip.label"
          />
        </div>
        <div v-if="candidate.intro" class="md:mb-9 flex flex-col gap-2 self-start">
          <p class="self-start text-sm">
            {{ candidate.intro }}
          </p>
        </div>

        <div v-if="isMobile">
          <r-profile-info-card
            :email="candidate.email"
            no-padding
            :phone-number="candidate.phone"
          />
          <div class="border-b-1 border-gray-200 my-6"></div>
          <div class="text-center">
            <h4 v-if="createAgreementTitle" class="text-lg font-medium">
              {{ createAgreementTitle }}
            </h4>
            <RichText
              v-if="
                createAgreementContent && typeof createAgreementContent === 'object'
              "
              :blok="createAgreementContent"
              class="text-sm"
            />
          </div>

          <r-button
            v-if="buttonLabelAddTenant"
            border-color="light-gray"
            color="orange"
            :is-loading="addTenantInProgress"
            width="full"
            @click="onAddTenantClicked"
          >
            <span>
              {{ buttonLabelAddTenant }}
            </span>
          </r-button>
        </div>
      </div>
      <r-button
        v-if="secondaryButtonText"
        border-color="light-gray"
        class="mt-3"
        color="black"
        hover="hover:text-rendin-500 hover:shadow-md"
        :icon="secondaryButtonIcon"
        inverted
        :is-loading="deleteCandidacyInProgress"
        width="full"
        @click="secondaryButtonClicked"
      >
        <span>
          {{ secondaryButtonText }}
        </span>
      </r-button>
    </div>

    <background-check-info-modal
      :show-modal="showBackgroundCheckModal"
      @close-overlay="showBackgroundCheckModal = false"
    />
  </div>
</template>

<script>
import { BackgroundCheckResult } from '~/utils/constants';
import BackgroundCheckInfoModal from '~/components/section/agreement/BackgroundCheckInfoModal.vue';
import { useTenantConstants } from '~/composables/useTenantConstants.ts';

export default {
  name: 'CandidatesListCandidateInfoCard',
  components: { BackgroundCheckInfoModal },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    deleteCandidacyInProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
    addTenantInProgress: {
      type: Boolean,
      default: false,
    },
    secondaryButtonText: {
      type: String,
      required: false,
      default: '',
    },
    secondaryButtonIcon: {
      type: String,
      required: false,
      default: '',
    },
    showBackgroundStatusMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonLabelAddTenant: {
      type: String,
      required: true,
    },
    createAgreementTitle: {
      type: String,
      default: null,
    },
    createAgreementContent: {
      type: Object,
      default: null,
    },
  },
  emits: ['secondaryButtonClicked', 'addTenantClicked'],
  async setup() {
    const {
      convertSalaryToString,
      convertEducationValueToLabel,
      convertPetValueToIconName,
      convertPetValueToLabel,
    } = useTenantConstants();

    return {
      convertSalaryToString,
      convertEducationValueToLabel,
      convertPetValueToIconName,
      convertPetValueToLabel,
    };
  },
  data() {
    return {
      showBackgroundCheckModal: false,
    };
  },
  computed: {
    tenantProfile() {
      if (!this.candidate) {
        return {};
      }
      return this.candidate.tenantProfile || {};
    },
    profileChips() {
      const chips = [];
      if (!this.tenantProfile) return chips;
      if (this.tenantProfile.organization)
        chips.push({
          icon: 'briefcase',
          label: this.tenantProfile.organization,
        });
      if (this.tenantProfile.salaryMax)
        chips.push({
          icon: 'coins',
          label: this.convertSalaryToString(
            this.tenantProfile.salaryMin,
            this.tenantProfile.salaryMax,
            this.tenantProfile.salaryCurrency,
          ),
        });
      if (this.tenantProfile.educationLevel)
        chips.push({
          icon: 'graduation-cap',
          label: this.convertEducationValueToLabel(this.tenantProfile.educationLevel),
        });
      const coTenants = this.tenantProfile.coTenantsAmount;
      if (typeof coTenants === 'number') {
        const totalTenants = coTenants + 1;
        chips.push({
          icon: 'house-chimney-user',
          label:
            totalTenants === 1
              ? this.$t('tenant_profile.tabs.co_tenants.chip_persons_singular')
              : this.$t('tenant_profile.tabs.co_tenants.chip_persons_plural', {
                  number: totalTenants,
                }),
        });
      }
      if (this.tenantProfile.hasAPet) {
        chips.push({
          icon: this.convertPetValueToIconName(this.tenantProfile.petType),
          label: this.convertPetValueToLabel(this.tenantProfile.petType),
        });
      } else if (this.candidate.hasPets) {
        chips.push({
          icon: this.petTypesIcons.CAT,
          label: this.$t('form.label.have_pets'),
        });
      }
      return chips;
    },
    backgroundStatusMessage() {
      if (this.candidate.candidacyStatus === BackgroundCheckResult.PENDING) {
        return this.$t('candidate.pendingCandidate');
      } else if (this.candidate.candidacyStatus === BackgroundCheckResult.DECLINED) {
        return this.$t('candidate.declinedCandidate');
      } else if (
        this.candidate.candidacyStatus === 'IN_ANOTHER_ACTIVE_AGREEMENT' ||
        this.candidate.candidacyStatus === 'RETRACTED'
      ) {
        return this.$t('candidate.notAvailable');
      } else {
        return '';
      }
    },

    showBackgroundStatusModal() {
      return (
        this.candidate.candidacyStatus === BackgroundCheckResult.PENDING ||
        this.candidate.candidacyStatus === BackgroundCheckResult.DECLINED
      );
    },

    isBackgroundStatusApproved() {
      return this.candidate.candidacyStatus === BackgroundCheckResult.APPROVED;
    },
  },
  methods: {
    openBackgroundExplainerModal() {
      this.showBackgroundCheckModal = true;
    },

    secondaryButtonClicked(event) {
      /**
       * Secondary button - click event.
       *
       * @event secondaryButtonClicked
       */
      this.$emit('secondaryButtonClicked', event);
    },
    onAddTenantClicked(event) {
      /**
       * Add tenant button - click event.
       *
       * @event addTenantClicked
       */
      this.$emit('addTenantClicked', event);
    },
  },
};
</script>
<style>
.profile-card {
  @apply bg-no-repeat;
  background-size: 100% auto;
  background-image: url('/images/cute-circles-profile.svg');
}
</style>
