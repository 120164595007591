<template>
  <div
    ref="lead-form"
    :class="{
      'min-h-screen': blok?.isFullPageForm,
      'form-section': !blok?.isFullPageForm,
    }"
  >
    <template v-if="currentStep === formSteps.INTRO_SCREEN || $store.state.isStoryblok">
      <multiple-step-lead-magnet-form-section
        :key="formSteps.INTRO_SCREEN"
        :background-color="blok.backgroundColor"
        :image="blok.introImage"
        image-position-left
        :primary-content="blok.introMainContent"
        :secondary-content="blok.introSecondaryContent"
      >
        <template v-if="blok.enableRequireEmail" #primary-content-after>
          <!--   Email Collector <  -->
          <Field
            v-slot="{ meta }"
            v-model="userEmail"
            class="fade-animation"
            name="email"
            rules="required|email"
          >
            <div class="flex flex-col md:max-w-sm w-full gap-y-6 gap-x-4 items-end">
              <r-input
                id="email"
                v-model="userEmail"
                autocomplete="on"
                class="w-full"
                :error-text="$t('form.validation.email')"
                field-type="email"
                :label="$t('form.label.email')"
                name="email"
                :placeholder="$t('form.placeholder.email')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @enter="
                  meta.valid && !submitInProgress ? submitEmailAndMakeNewLead() : ''
                "
                @input="!meta.dirty ? onDataEnteredEvent('email') : ''"
              />
              <r-button
                class="w-full"
                :disabled="!meta.valid"
                :is-loading="submitInProgress"
                :label="blok.introSubmitEmailButtonCTA"
                @click="
                  meta.valid && !submitInProgress ? submitEmailAndMakeNewLead() : ''
                "
              />
            </div>
            <div class="text-xs py-6" v-html="consentHTML"></div>
          </Field>
        </template>
        <template v-else #primary-content-after>
          <r-button
            :label="blok.introDirectDownloadButtonCTA"
            @click="goToSuccessScreen"
          />
        </template>
      </multiple-step-lead-magnet-form-section>
    </template>
    <template
      v-if="currentStep === formSteps.SUCCESS_SCREEN || $store.state.isStoryblok"
    >
      <multiple-step-lead-magnet-form-section
        :key="formSteps.SUCCESS_SCREEN"
        :background-color="blok.backgroundColor"
        :buttons="blok.successButtons"
        :image="blok.successImage"
        :primary-content="blok.successMainContent"
        :secondary-content="blok.successSecondaryContent"
      >
        <template
          v-if="blok?.timerDuration > 0 && !skipDownload"
          #primary-content-before
        >
          <div>
            <span v-if="blok.timerText">
              {{ blok.timerText }}
            </span>
            <span v-if="blok.timerDuration && blok.timerDuration > 0" class="font-bold">
              {{ timeLeft }} {{ $t('form.time.seconds').toLowerCase() }}
            </span>
            <p
              v-if="timeLeft === 0 && blok.timerPopupBlocked"
              class="text-rendin-400 cursor-pointer"
              @click="makeAction()"
            >
              {{ blok.timerPopupBlocked }}
            </p>
          </div>
        </template>
      </multiple-step-lead-magnet-form-section>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { leadMagnetFormEventNames } from '~/utils/trackerConstants';
import MultipleStepLeadMagnetFormSection from '~/components/section/MultipleStepLeadMagnetFormSection.vue';
import { Field } from 'vee-validate';

const formSteps = {
  INTRO_SCREEN: 'INTRO_SCREEN',
  SUCCESS_SCREEN: 'SUCCESS_SCREEN',
};

export default {
  name: 'MultipleStepLeadMagnetForm',
  components: { Field, MultipleStepLeadMagnetFormSection },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formStepIndex: 0,
      formStepsValues: Object.values(formSteps),
      formSteps: formSteps,
      sendingEmailInProgress: false,
      userEmail: '',
      recordId: null,
      submitInProgress: false,
      timeLeft: null,
      timer: null,
      actionType: {
        OPEN_TAB: 'OPEN_TAB',
        REDIRECT: 'REDIRECT',
      },
      skipDownload: false,
    };
  },

  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      profileId: 'users/profileId',
      country: 'getCountry',
      locale: 'getLocale',
    }),

    currentStep() {
      return this.formStepsValues[this.formStepIndex];
    },
    consentHTML() {
      const privacyPolicy = `<a class="font-medium transition duration-200 ease-in-out text-rendin-500 hover:text-rendin-300 hover:underline focus:underline" href="${this.$localizedPath('/privacy')}" target="_blank">${this.$t('form.rendin_privacy')}</a>`;
      const parameters = {
        privacy_policy: privacyPolicy,
        submit_button_label: this.blok.introSubmitEmailButtonCTA,
        consent_company_name: this.$t('form.label.consent_company_name')[
          this.$getCountry()
        ],
      };

      return this.$t('form.label.consent_sales_marketing_email', parameters);
    },
  },
  watch: {
    currentStep(newVal) {
      if (newVal) {
        const eventName = leadMagnetFormEventNames.NEXT_STEP;
        const props = {
          currentStep: newVal,
        };
        this.trackAgreementDraftForm({ eventName, props });
      }
    },
  },
  created() {
    console.log('this is router', this.$router);

    if (this.$route.query.skip_download) {
      this.skipDownload = true;
      this.formStepIndex = 1;
    }
  },
  methods: {
    ...mapActions({
      trackAgreementDraftForm: 'tracker/trackAgreementDraftForm',
    }),

    submitEmailAndMakeNewLead() {
      this.submitInProgress = true;

      const posthogDistinctId = this.$posthog.get_distinct_id();
      const path = window.location.pathname;
      const fullPath = window.location.href;

      const eventName = leadMagnetFormEventNames.NEW_LEAD;
      const eventProps = {
        email: this.userEmail,
      };

      /* LEGAL STUFF - we added information in the modal, which gets multiple consents from the user */
      /* So we're enabling here all consents by default. Might change in the future */
      /* Ref: https://app.clickup.com/t/861n85f0c */
      const data = {
        email: this.userEmail,
        page: path,
        fullPath: fullPath,
        recordId: this.recordId,
        userFirebaseKey: this.profileId,
        posthogDistinctId: posthogDistinctId,
        acceptedTermsAndConditions: true,
        consentMarketingElectronic: true,
        consentSalesPhone: false,
      };

      $fetch('/api/leads/upsert', { method: 'POST', body: data })
        .then((data) => {
          this.recordId = data.recordId;
        })
        .then(() => {
          if (this.userEmail) {
            return this.$setUserProperties({
              services: ['POSTHOG', 'CIO'],
              identify: !!this.userEmail,
              email: this.userEmail,
              internalUserProperties: {
                email: this.userEmail,
                country: this.country,
                locale: this.locale,
                source: 'web_lead_form',
              },
            });
          }
        })
        .then(() =>
          this.trackAgreementDraftForm({
            eventName,
            eventProps,
          }),
        )
        .catch((err) => {
          this.reportErrorToSentry(err);
        })
        .finally(() => {
          this.goToSuccessScreen();
          this.submitInProgress = false;
        });
    },

    onDataEnteredEvent(inputName) {
      const eventName = leadMagnetFormEventNames.ENTER_DATA;
      const props = {
        inputName: inputName,
      };
      this.trackAgreementDraftForm({ eventName, props });
    },
    makeAction() {
      if (this.blok.downloadOrPageLink) {
        if (this.blok.downloadOrPageLink?.linktype === 'story') {
          if (this.blok.timerActionType === this.actionType.REDIRECT) {
            this.$router.push({
              path: this.$localizedPath(this.blok.downloadOrPageLink.cached_url),
            });
          } else if (this.blok.timerActionType === this.actionType.OPEN_TAB) {
            window.open(this.$localizedPath(this.blok.downloadOrPageLink.cached_url));
          }
        } else if (this.blok.downloadOrPageLink?.linktype === 'url') {
          if (this.blok.timerActionType === this.actionType.REDIRECT) {
            window.location.href = this.blok.downloadOrPageLink?.url;
          } else if (this.blok.timerActionType === this.actionType.OPEN_TAB) {
            window.open(this.blok.downloadOrPageLink?.url);
          }
        }
      }
    },

    startTimer(duration) {
      this.timeLeft = duration;
      this.timer = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          clearInterval(this.timer);
          this.timeLeft = 0;
          this.makeAction();
        }
      }, 1000);
    },

    goToSuccessScreen() {
      this.formStepIndex = 1;

      const formTopPosition = this.$refs['lead-form'].offsetTop;

      /* Since we have Sticky Nav, which could cover the top part of our element, will scroll higher by mobile view height amount */
      window.scrollTo({
        top: formTopPosition - 48,
        behavior: 'smooth',
      });

      if (this.blok?.timerDuration > 0) {
        this.startTimer(this.blok?.timerDuration);
      } else {
        this.makeAction();
      }
    },
  },
};
</script>

<style scoped>
.fade-animation {
  animation: fadeIn 3s;
}

.form-section {
  min-height: 600px;
}

@media screen and (max-width: 640px) {
  .form-section {
    min-height: 800px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
