export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const googleMapsApiKey = config.public.GOOGLE_MAPS_API_KEY;

  const loadGoogleMaps = (g) => {
    let h;
    let a;
    let k;
    const p = 'The Google Maps JavaScript API';
    const c = 'google';
    const l = 'importLibrary';
    const q = '__ib__';
    const m = document;
    let b = window;
    b = b[c] || (b[c] = {});
    const d = b.maps || (b.maps = {});
    const r = new Set();
    const e = new URLSearchParams();
    const u = () =>
      h ||
      (h = new Promise((f, n) => {
        a = m.createElement('script');
        e.set('libraries', `${[...r]}`);
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t) => `_${t.toLowerCase()}`),
            g[k],
          );
        e.set('callback', `${c}.maps.${q}`);
        a.src = `https://maps.${c}apis.com/maps/api/js?${e}`;
        d[q] = f;
        a.onerror = () => (h = n(new Error(`${p} could not load.`)));
        a.nonce = m.querySelector('script[nonce]')?.nonce || '';
        m.head.append(a);
      }));
    if (d[l]) {
      console.warn(`${p} only loads once. Ignoring:`, g);
    } else {
      d[l] = (f, ...n) => {
        r.add(f);
        return u().then(() => d[l](f, ...n));
      };
    }
  };

  loadGoogleMaps({
    key: googleMapsApiKey,
    v: 'weekly',
    // Add other bootstrap parameters as needed, using camel case.
  });
});
