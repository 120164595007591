<template>
  <div>
    <r-button @click="test1ButtonClick"> test1 </r-button>
    <r-button> test2 </r-button>
    <r-button> test3 </r-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TrackerPage',

  mounted() {
    const path = window.location.pathname;
    this.$trackEvent(
      'Pageview',
      {
        'Page Path': this.$route.fullPath,
        path: this.$getLocaleFromPath(path)
          ? path.substring(3) === ''
            ? '/'
            : path.substring(3)
          : path,
      },
      {
        services: ['POSTHOG', 'GTAG'],
      },
    );
  },

  methods: {
    ...mapActions({
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
    }),
    test1ButtonClick() {
      console.log('1');
      this.$trackEvent(
        'Test2',
        {
          test1: 'test1',
          email: 'rendin@gmail.com',
        },
        {
          services: ['POSTHOG', 'GTAG', 'CIO'],
        },
      );
      this.$sentry.captureMessage('test 1 , hurra');
    },
  },
};
</script>

<style scoped></style>
