import {
  requestKeys,
  useVuexFirebaseRequests,
} from '~/components/composables/useVuexFirebaseRequests';
import { agreementSourceTypes } from '~/utils/constants.js';

const requestGetHandover = 'getHandover';
const requestPutHandover = 'putHandover';
const requestPostHandoverImage = 'postHandoverImage';
const requestPostHandover = 'postHandover';
const requestDeleteHandover = 'deleteHandover';
const requestPutHandoverImage = 'putHandoverImage';

const requests = [
  requestGetHandover,
  requestPutHandover,
  requestPostHandover,
  requestPostHandoverImage,
  requestDeleteHandover,
  requestPutHandoverImage,
];

const firebaseRequestsSetup = useVuexFirebaseRequests(requests);

export const state = () => ({ ...firebaseRequestsSetup.state });
export const getters = { ...firebaseRequestsSetup.getters };
export const mutations = { ...firebaseRequestsSetup.mutations };

export const actions = {
  ...firebaseRequestsSetup.actions,
  /**
   * @param {string} agreementId
   * @param {string} entityId
   * @param {object} includeData
   * @param {object} includedData
   * @return {Promise<{
   *   response: agreementId,
   *   failed: boolean,
   * }>}
   */
  getHandover: function ({ dispatch }, { agreementId, includeData = {} }) {
    const getHandoverKeys = requestKeys(requestGetHandover);
    const optionalData =
      Object.keys(includeData).length === 0 ? { includeAllData: true } : includeData;
    const request = {
      agreementId: agreementId,
      ...optionalData,
    };
    return dispatch(getHandoverKeys.ACTION_MAKE_REQUEST, { request: request });
  },

  putHandover: function ({ dispatch }, { entityId, includedData }) {
    const putHandoverKeys = requestKeys(requestPutHandover);
    const request = {
      entityId: entityId,
    };
    const requestData = {
      source: agreementSourceTypes.WEB,
      ...includedData,
    };

    return dispatch(putHandoverKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },

  /**
   * @param {string} type
   * @param {string} agreementId
   * @param {object} includedData
   * @return {Promise<{
   *   response: AdvertisementId,
   *   failed: boolean,
   * }>}
   */
  postHandover: function ({ dispatch }, { type, agreementId, includedData }) {
    const postHandoverKeys = requestKeys(requestPostHandover);
    const request = {
      type: type,
    };
    const requestData = {
      source: agreementSourceTypes.WEB,
      agreementId: agreementId,
      ...includedData,
    };

    return dispatch(postHandoverKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },

  postHandoverImage: function ({ dispatch }, { entityId, includedData }) {
    const postHandoverImageKeys = requestKeys(requestPostHandoverImage);
    const request = {
      entityId: entityId,
    };
    const requestData = {
      source: agreementSourceTypes.WEB,
      ...includedData,
    };

    return dispatch(postHandoverImageKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },

  deleteHandover: function ({ dispatch }, { entityId }) {
    const deleteHandoverKeys = requestKeys(requestDeleteHandover);
    const request = {
      entityId: entityId,
    };
    const requestData = {
      source: agreementSourceTypes.WEB,
    };

    return dispatch(deleteHandoverKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },

  putHandoverImage: function ({ dispatch }, { entityId, includedData }) {
    const putHandoverImageKeys = requestKeys(requestPutHandoverImage);
    const request = {
      entityId: entityId,
    };
    const requestData = {
      source: agreementSourceTypes.WEB,
      ...includedData,
    };

    return dispatch(putHandoverImageKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },
};
