<template>
  <div class="rendin-introduction rendin-introduction__bg p-6 flex flex-col">
    <p v-if="title" class="text-2xl pb-4 font-bold">
      {{ title }}
    </p>
    <RichText v-if="content && typeof content === 'object'" :blok="content" />
    <p v-if="accordionsTitle" class="mt-4 mb-2 font-bold">
      {{ accordionsTitle }}
    </p>
    <r-accordion-group
      :active-index="accordionGroupActiveIndex"
      :accordions="accordions"
      @switch="(index) => (accordionGroupActiveIndex = index)"
    >
      <template #low-price>
        <RichText
          v-if="
            accordionsContent &&
            typeof accordionsContent.low_fairs_description_rich === 'object' &&
            accordionsContent.low_fairs_description_rich.content.length > 0 &&
            accordionsContent.low_fairs_description_rich.content[0].content
          "
          :blok="accordionsContent.low_fairs_description_rich"
        />
      </template>
      <template #help>
        <RichText
          v-if="
            accordionsContent &&
            typeof accordionsContent.help_with_problems_description_rich === 'object' &&
            accordionsContent.help_with_problems_description_rich.content.length > 0 &&
            accordionsContent.help_with_problems_description_rich.content[0].content
          "
          :blok="accordionsContent.help_with_problems_description_rich"
        />
      </template>
      <template #digital>
        <RichText
          v-if="
            accordionsContent &&
            typeof accordionsContent.digital_description_rich === 'object' &&
            accordionsContent.digital_description_rich.content.length > 0 &&
            accordionsContent.digital_description_rich.content[0].content
          "
          :blok="accordionsContent.digital_description_rich"
        />
      </template>
      <!--       For listing invite only-->
      <template #fast>
        <RichText
          v-if="
            accordionsContent &&
            typeof accordionsContent.quick_description_rich === 'object' &&
            accordionsContent.quick_description_rich.content.length > 0 &&
            accordionsContent.quick_description_rich.content[0].content
          "
          :blok="accordionsContent.quick_description_rich"
        />
      </template>
    </r-accordion-group>
  </div>
</template>

<script>
export default {
  name: 'RendinIntroduction',
  components: {},

  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default() {
        return {};
      },
    },
    accordionsTitle: {
      type: String,
      default: '',
    },
    accordionsContent: {
      type: Object,
      default() {
        return {};
      },
    },
    isAgreementInvite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      accordionGroupActiveIndex: -1,
    };
  },

  computed: {
    accordions() {
      const accordionsData = [
        {
          icon: 'piggy-bank',
          id: 'low-price',
          title: this.accordionsContent.low_fairs_title,
        },
        {
          icon: 'handshake-angle',
          id: 'help',
          title: this.accordionsContent.help_with_problems_title,
        },
        {
          icon: 'laptop-mobile',
          id: 'digital',
          title: this.accordionsContent.digital_title,
        },
      ];

      if (!this.isAgreementInvite) {
        accordionsData.push({
          icon: 'user-check',
          id: 'fast',
          title: this.accordionsContent.quick_title,
        });
      }

      return accordionsData;
    },
  },
};
</script>

<style scoped>
.rendin-introduction__bg {
  background-image: url('/images/cute-circles-mob.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
