<template>
  <section class="flex">
    <LeadsGeneratorMolecule
      :blok="leadsGeneratorBaseBlok"
      class="flex-1"
      :content-body-classes="'px-8 lg:pl-24 lg:pr-20 py-6 lg:py-20'"
      :content-body-color="backgroundColors['peach-light']"
      :is-email-enabled="blok.isEmailEnabled"
      :source="source"
    >
      <template #title>
        <p
          v-if="leadsGeneratorBaseBlok.startTitle"
          class="text-2xl sm:text-5xl self-stretch"
        >
          {{ leadsGeneratorBaseBlok.startTitle }}
        </p>
        <p
          v-if="blok.boldStartTitle"
          class="text-2xl sm:text-5xl font-bold text-rendin-500 self-stretch -mt-3 sm:-mt-2"
        >
          {{ blok.boldStartTitle }}
        </p>
        <p
          v-if="leadsGeneratorBaseBlok.startDescription"
          class="text-base self-stretch"
        >
          {{ leadsGeneratorBaseBlok.startDescription }}
        </p>
      </template>
    </LeadsGeneratorMolecule>
  </section>
</template>
<script>
import LeadsGeneratorMolecule from '~/components/snippets/LeadsGeneratorMolecule.vue';
import { backgroundColors, LeadsGeneratorSources } from '~/utils/constants';

export default {
  name: 'LeadsGeneratorSection',
  components: {
    LeadsGeneratorMolecule,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      source: LeadsGeneratorSources.SECTION,
      backgroundColors: backgroundColors,
    };
  },
  computed: {
    leadsGeneratorBaseBlok() {
      return this.blok?.leadsGeneratorBase[0];
    },
  },
};
</script>
