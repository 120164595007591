<template>
  <section class="bg-gray-50 px-3 py-8 md:py-12 flex flex-col items-center flex-grow">
    <div class="max-w-6xl flex flex-col sm:flex-row gap-3">
      <client-only>
        <template #placeholder>
          <r-loader type="animatedLogo" />
        </template>
        <r-loader v-if="!profile || !tenantProfile" type="animatedLogo" />
        <div
          v-if="profile && tenantProfile"
          class="flex flex-col gap-3 w-full sm:w-2/5"
        >
          <RCard class="profile-card flex flex-col items-center gap-2">
            <tenant-profile-picture-upload
              :max-file-size-error="
                $t('errors.validation.image.image_file_too_big', { number: 10 })
              "
              :max-upload-file-size="10 * 1024 * 1024"
              :min-image-height="500"
              :min-image-size-error="
                $t('errors.validation.image.image_too_small', {
                  width: 500,
                  height: 500,
                })
              "
              :min-image-width="500"
              :profile-completion-percentage="profileCompletionPercentage"
              :tenant-profile="tenantProfile"
              @submit="onSubmitTenantProfile"
            />
            <r-chip>
              <span class="text-black" v-html="profileCompletedString"></span>
            </r-chip>
            <div class="flex flex-col items-center">
              <p class="text-xl font-medium leading-tight">
                {{ profile ? [profile.firstName, profile.lastName].join(' ') : '' }}
              </p>
              <p
                v-if="profile && profile.scoreStatus === 'APPROVED'"
                class="text-sm text-gray-400 leading-tight"
              >
                <r-icon class="inline-block text-green-400" icon-name="user-check" />
                {{ $t('tenant_profile.card.background_checked') }}
              </p>
            </div>
            <r-title-with-icon
              class="self-start"
              icon="mobile"
              :title="profile ? profile.phoneNumberFull : ''"
            />
            <r-title-with-icon
              class="self-start"
              icon="envelope"
              :title="profile ? profile.email : ''"
            />
            <div class="w-full border-t border-gray-300 my-2"></div>
            <div class="profile-chips flex flex-wrap gap-2">
              <r-chip
                v-for="(chip, index) in profileChips"
                :key="index"
                :icon="chip.icon"
                :label="chip.label"
              />
            </div>
            <p>
              {{ tenantProfile ? tenantProfile.description : '' }}
            </p>
          </RCard>
          <RCard class="flex flex-col gap-2">
            <p class="text-sm">
              {{ $t('tenant_profile.general.suggestions_description') }}
            </p>
            <div v-if="showSuggestions" class="flex flex-col gap-2">
              <r-suggestion
                class="cursor-pointer"
                :completed="personalInformationCompleted"
                :description="
                  $t('tenant_profile.tabs.personal_information.suggestion_body')
                "
                img="images/tenant-profile-suggestion-add-pic.png"
                :title="$t('tenant_profile.tabs.personal_information.suggestion_title')"
                @click="accordionGroupActiveIndex = 0"
              />
              <r-suggestion
                class="cursor-pointer"
                :completed="profilePictureCompleted"
                :description="$t('tenant_profile.tabs.profile_photo.suggestion_body')"
                img="/images/tenant-profile-suggestion-add-pic.png"
                :title="$t('tenant_profile.tabs.profile_photo.suggestion_title')"
              />
              <r-suggestion
                class="cursor-pointer"
                :completed="aboutYouCompleted"
                :description="$t('tenant_profile.tabs.about_you.suggestion_body')"
                img="/images/tenant-profile-suggestion-about-you.png"
                :title="$t('tenant_profile.tabs.about_you.suggestion_title')"
                @click="accordionGroupActiveIndex = 2"
              />
              <r-suggestion
                class="cursor-pointer"
                :completed="employmentCompleted"
                :description="
                  $t('tenant_profile.tabs.education_and_employment.suggestion_body')
                "
                img="/images/tenant-profile-suggestion-employment.png"
                :title="
                  $t('tenant_profile.tabs.education_and_employment.suggestion_title')
                "
                @click="accordionGroupActiveIndex = 3"
              />
              <r-suggestion
                class="cursor-pointer"
                :completed="cotenantsCompleted"
                :description="$t('tenant_profile.tabs.co_tenants.suggestion_body')"
                img="/images/tenant-profile-suggestion-cotenants.png"
                :title="$t('tenant_profile.tabs.co_tenants.suggestion_title')"
                @click="accordionGroupActiveIndex = 4"
              />
            </div>
            <RButton
              :label="
                showSuggestions
                  ? $t('tenant_profile.general.hide_suggestions')
                  : $t('tenant_profile.general.show_suggestions')
              "
              no-background
              @click="showSuggestions = !showSuggestions"
            />
          </RCard>
        </div>
        <div
          v-if="profile && tenantProfile"
          class="flex flex-col gap-3 w-full sm:w-3/5"
        >
          <r-accordion-group
            :active-index="accordionGroupActiveIndex"
            :accordions="accordions"
            @switch="(index) => (accordionGroupActiveIndex = index)"
          >
            <template #personal-information>
              <tenant-personal-information-tab
                :loading="profileSubmitInProgress"
                :profile="profile"
                @close="accordionGroupActiveIndex = -1"
                @submit="onSubmitProfile"
              />
            </template>
            <template #background-check>
              <tenant-background-check-tab
                v-if="blok.ProfilePersonalIdCodeSetting"
                :key="blok.ProfilePersonalIdCodeSetting._uid"
                :blok="blok.ProfilePersonalIdCodeSetting[0]"
                :loading="profileSubmitInProgress"
                :tenant-profile="tenantProfile"
                @close="accordionGroupActiveIndex = -1"
                @submit="onSubmitTenantProfile"
              />
            </template>
            <template #about-you>
              <tenant-about-you-tab
                :loading="profileSubmitInProgress"
                :tenant-profile="tenantProfile"
                @close="accordionGroupActiveIndex = -1"
                @submit="onSubmitTenantProfile"
              />
            </template>
            <template #education-and-employment>
              <tenant-education-and-employment-tab
                :loading="profileSubmitInProgress"
                :tenant-profile="tenantProfile"
                @close="accordionGroupActiveIndex = -1"
                @submit="onSubmitTenantProfile"
              />
            </template>
            <template #co-tenants>
              <tenant-cotenants-tab
                :loading="profileSubmitInProgress"
                :tenant-profile="tenantProfile"
                @close="accordionGroupActiveIndex = -1"
                @submit="onSubmitTenantProfile"
              />
            </template>
          </r-accordion-group>
        </div>
      </client-only>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TenantPersonalInformationTab from '~/components/section/tenant-profile-components/TenantPersonalInformationTab';
import TenantEducationAndEmploymentTab from '~/components/section/tenant-profile-components/TenantEducationAndEmploymentTab';
import TenantCotenantsTab from '~/components/section/tenant-profile-components/TenantCotenantsTab';
import TenantAboutYouTab from '~/components/section/tenant-profile-components/TenantAboutYouTab';
import TenantBackgroundCheckTab from '~/components/section/tenant-profile-components/TenantBackgroundCheckTab';
import TenantProfilePictureUpload from '~/components/section/tenant-profile-components/TenantProfilePictureUpload';
import { useTenantConstants } from '~/composables/useTenantConstants.ts';

export default {
  name: 'TenantProfileSettings',
  components: {
    TenantProfilePictureUpload,
    TenantBackgroundCheckTab,
    TenantAboutYouTab,
    TenantCotenantsTab,
    TenantEducationAndEmploymentTab,
    TenantPersonalInformationTab,
  },
  props: {
    blok: {
      type: Object,
      default: null,
    },
  },

  async setup() {
    const {
      convertEducationValueToLabel,
      convertSalaryToString,
      convertPetValueToLabel,
      convertPetValueToIconName,
    } = useTenantConstants();

    return {
      convertEducationValueToLabel,
      convertSalaryToString,
      convertPetValueToLabel,
      convertPetValueToIconName,
    };
  },

  data: (vm) => ({
    accordions: [
      {
        id: 'personal-information',
        accordionId: 'personal-information',
        title: vm.$t('tenant_profile.tabs.personal_information.title'),
        icon: 'user',
        iconClass: 'text-rendin-500',
      },
      {
        id: 'background-check',
        accordionId: 'background-check',
        title: vm.$t('tenant_profile.tabs.background_check.title'),
        icon: 'user-shield',
        iconClass: 'text-rendin-500',
      },
      {
        id: 'about-you',
        accordionId: 'about-you',
        title: vm.$t('tenant_profile.tabs.about_you.title'),
        icon: 'file-lines',
        iconClass: 'text-rendin-500',
      },
      {
        id: 'education-and-employment',
        accordionId: 'education-and-employment',
        title: vm.$t('tenant_profile.tabs.education_and_employment.title'),
        icon: 'graduation-cap',
        iconClass: 'text-rendin-500',
      },
      {
        id: 'co-tenants',
        accordionId: 'co-tenants',
        title: vm.$t('tenant_profile.tabs.co_tenants.title'),
        icon: 'cat',
        iconClass: 'text-rendin-500',
      },
    ],
    accordionGroupActiveIndex: -1,
    validateEventTriggered: false,
    profileSubmitInProgress: false,
    showSuggestions: true,
  }),
  computed: {
    profileCompletedString() {
      return this.$t('tenant_profile.card.profile_completion', {
        percentage: `<span class="text-rendin-500">${this.profileCompletionPercentage.toFixed(0)}%</span>`,
      });
    },
    ...mapGetters({
      profile: 'users/profile',
      tenantProfileFromFirebase: 'tenants/tenantProfileFromFirebase',
      tenantProfileFromFirebaseCreate: 'tenants/tenantProfileFromFirebaseCreate',
      hasTenantProfileFromFirebase: 'tenants/hasTenantProfileFromFirebase',
      hasSessionUser: 'users/hasSessionUser',
    }),
    tenantProfile() {
      if (this.tenantProfileFromFirebase) return this.tenantProfileFromFirebase;
      if (this.tenantProfileFromFirebaseCreate)
        return this.tenantProfileFromFirebaseCreate;

      return null;
    },
    profileCompletionPercentage() {
      let completion = 0;
      if (!this.profile) return 0;

      /* Personal Information */
      if (this.profile.firstName) completion += 0.05;
      if (this.profile.lastName) completion += 0.05;
      if (this.profile.email) completion += 0.1;
      if (this.profile.phoneNumberFull) completion += 0.05;

      /* Background check */
      if (this.profile.scoreStatus && this.profile.idCode) completion += 0.2;

      if (!this.tenantProfile) return completion * 100;

      /* Education & Employment */
      if (this.tenantProfile.salaryMin >= 0 && this.tenantProfile.salaryMax > 0)
        completion += 0.1;
      if (this.tenantProfile.organization) completion += 0.05;
      if (this.tenantProfile.educationLevel) completion += 0.1;

      /* Profile Picture */
      if (this.tenantProfile.shareableProfileImage) completion += 0.1;

      /* About you */
      if (this.tenantProfile.description) completion += 0.1;

      /* Co-tenants */
      if (typeof this.tenantProfile.coTenantsAmount === 'number') completion += 0.05;
      if (this.tenantProfile.hasAPet || this.tenantProfile.hasAPet === false)
        completion += 0.05;

      return completion * 100;
    },
    personalInformationCompleted() {
      if (!this.profile) return false;
      if (!this.profile.firstName) return false;
      if (!this.profile.lastName) return false;
      if (!this.profile.email) return false;
      if (!this.profile.phoneNumberFull) return false;

      return true;
    },
    profilePictureCompleted() {
      if (!this.tenantProfile) return false;
      if (!this.tenantProfile.shareableProfileImage) return false;

      return true;
    },
    aboutYouCompleted() {
      if (!this.tenantProfile) return false;
      if (!this.tenantProfile.description) return false;

      return true;
    },
    employmentCompleted() {
      if (!this.tenantProfile) return false;
      if (!this.tenantProfile.educationLevel) return false;
      if (!this.tenantProfile.organization) return false;
      if (!this.tenantProfile.salaryMax) return false;

      return true;
    },
    cotenantsCompleted() {
      if (!this.tenantProfile) return false;
      if (typeof this.tenantProfile.coTenantsAmount !== 'number') return false;
      if (
        this.tenantProfile.hasAPet !== false &&
        this.tenantProfile.hasAPet &&
        !this.tenantProfile.petType
      )
        return false;

      return true;
    },
    profileChips() {
      const chips = [];
      if (!this.tenantProfile) return chips;
      if (this.tenantProfile.organization)
        chips.push({
          icon: 'briefcase',
          label: this.tenantProfile.organization,
        });
      if (this.tenantProfile.salaryMax)
        chips.push({
          icon: 'coins',
          label: this.convertSalaryToString(
            this.tenantProfile.salaryMin,
            this.tenantProfile.salaryMax,
            this.tenantProfile.salaryCurrency,
          ),
        });
      if (this.tenantProfile.educationLevel)
        chips.push({
          icon: 'graduation-cap',
          label: this.convertEducationValueToLabel(this.tenantProfile.educationLevel),
        });
      const coTenants = this.tenantProfile.coTenantsAmount;
      if (typeof coTenants === 'number') {
        const totalTenants = coTenants + 1;
        chips.push({
          icon: 'house-chimney-user',
          label:
            totalTenants === 1
              ? this.$t('tenant_profile.tabs.co_tenants.chip_persons_singular')
              : this.$t('tenant_profile.tabs.co_tenants.chip_persons_plural', {
                  number: totalTenants,
                }),
        });
      }
      if (this.tenantProfile.hasAPet)
        chips.push({
          icon: this.convertPetValueToIconName(this.tenantProfile.petType),
          label: this.convertPetValueToLabel(this.tenantProfile.petType),
        });
      return chips;
    },
  },
  watch: {
    profile() {
      if (this.profile && !this.tenantProfile) {
        this.actionGetTenantProfile().then(() => {});
      }
    },
  },
  async beforeMount() {
    if (this.hasSessionUser) {
      if (this.hasSessionUser && !this.tenantProfile)
        await this.actionGetTenantProfile();
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
      actionPutTenantProfile: 'tenants/putTenantProfile',
      actionPostTenantProfile: 'tenants/postTenantProfile',
      actionGetTenantProfile: 'tenants/requestTenantProfile',
      actionFetchProfile: 'users/fetchProfile',
    }),
    onSubmitProfile(newData) {
      if (this.profileSubmitInProgress) {
        return;
      }

      this.profileSubmitInProgress = true;
      this.actionPutProfile({ profile: newData, id: this.profile.id }).then(() => {
        this.profileSubmitInProgress = false;
      });
    },
    onSubmitTenantProfile(newData) {
      if (this.profileSubmitInProgress) {
        return;
      }

      this.profileSubmitInProgress = true;
      if (this.hasTenantProfileFromFirebase) {
        this.actionPutTenantProfile(newData).then(() => {
          this.profileSubmitInProgress = false;
        });
      } else {
        this.actionPostTenantProfile(newData).then(() => {
          this.profileSubmitInProgress = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-card {
  @apply bg-no-repeat;
  background-size: 100% auto;
  background-image: url('/images/cute-circles-profile.svg');
}
</style>
