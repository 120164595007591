<template>
  <client-only>
    <r-overlay
      :bottom-bar="showModal"
      :size="ComponentSize.MEDIUM"
      :visible="showModal"
      @close="closeOverlay()"
    >
      <div class="bg-gray-100">
        <div class="bg-white p-4 pt-8 pr-12 mb-2">
          <h1 class="text-1.5xl">{{ $t('additional_fee.title') }}</h1>
          <p class="opacity-60 pt-2 text-sm">{{ $t('additional_fee.subtitle') }}</p>
        </div>

        <Form ref="form">
          <div class="p-4 bg-white my-2">
            <h3 class="font-medium mb-4">
              {{ $t('additional_fee.choose_service') }}
            </h3>

            <Field
              v-slot="{ meta }"
              v-model="additionalFeeTypeDefault"
              rules="required"
              name="additionalFee"
            >
              <r-select
                v-model="additionalFeeTypeDefault"
                :label="$t('additional_fee.service')"
                label-class="text-xs"
                name="additionalFee"
                :no-options-text="$t('form.validation.select_no_options')"
                option-label="text"
                :options="additionalFeeTypeOptions"
                :placeholder="$t('additional_fee.placeholder.fee_type')"
                required
                :validation-failed="meta?.validated && !meta?.valid"
                :validation-passed="meta?.validated && meta?.valid"
              />
            </Field>
            <Field
              v-slot="{ meta }"
              v-model="feeAmountModel"
              :rules="{ required: true, isFloat: true }"
              name="feeAmount"
            >
              <r-input
                v-model="feeAmountModel"
                :error-text="$t('form.validation.is_float')"
                field-type="tel"
                inputmode="decimal"
                :label="`${$t('additional_fee.cost')} (${agreementFromFirebase.currency})`"
                label-class="text-xs"
                name="feeAmount"
                required
                :validation-failed="meta?.validated && !meta?.valid"
                :validation-passed="meta?.validated && meta?.valid"
              />
            </Field>
          </div>

          <div class="p-4 bg-white my-2">
            <div class="font-medium">
              {{ $t('additional_fee.who_pays_for_the_service') }}
            </div>

            <Field
              v-slot="{ meta }"
              v-model="additionalFee.payerRole"
              rules="required"
              name="payerRole"
            >
              <r-tabs
                ref="paymentDetermination"
                v-model="additionalFee.payerRole"
                :error-text="$t('form.validation.is_required')"
                :tabs="paymentDeterminationTabs"
                tabs-border-class="border-gray-50"
                :validation-failed="meta?.validated && !meta?.valid"
                wrapped
              />
            </Field>
          </div>
        </Form>

        <!--                Failing               -->
        <div v-if="getAgreementFailed" class="flex justify-center text-error">
          {{ $t('errors.something_wrong') }}
        </div>
      </div>

      <template #bottom-bar>
        <div
          class="grid grid-cols-2 justify-center gap-6 bg-white w-full sm:w-max p-4 m-auto"
        >
          <r-button
            inverted
            :label="$t('buttons.back')"
            width="wide"
            @click="closeOverlay()"
          />
          <r-button
            :disabled="getAgreementInProgress"
            :is-loading="postAgreementAdditionalFeeInProgress"
            :label="$t('buttons.save')"
            type="submit"
            width="wide"
            @click="validateAndSubmitInProgress ? null : validateAndSubmit()"
          />
        </div>
      </template>
    </r-overlay>
  </client-only>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ComponentSize, additionalFeeType } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import { payerRoleTabsOptions } from '~/utils/objectStructures';
import { validate, Field, Form } from 'vee-validate';

const defaultAdditionalFee = () => {
  return {
    type: null,
    amount: null,
    paymentInterval: 'ONE_TIME',
    payerRole: 'TENANT',
  };
};

export default {
  name: 'AddAgentAndContractFeesModal',
  components: {
    Field,
    Form,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  data: (vm) => ({
    inputSelectDefault: [],
    id: null,
    additionalFee: defaultAdditionalFee(),
    additionalFeeTypeDefault: null,
    ComponentSize,
    trackingProcessNames,
    agreementTrackingEventNames,
    paymentDeterminationTabs: payerRoleTabsOptions(vm.$t('tenant'), vm.$t('landlord')),
    validateAndSubmitInProgress: false,
  }),

  computed: {
    ...mapGetters({
      getAgreementResponse: 'agreements/getAgreementResponse',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      getAgreementFailed: 'agreements/getAgreementFailed',
      postAgreementAdditionalFeeInProgress:
        'agreements/postAgreementAdditionalFeeInProgress',
    }),

    feeAmountModel: {
      get() {
        return this.additionalFee.amount || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.additionalFee.amount = normalized;
      },
    },

    additionalFeeTypeOptions() {
      const defaultAdditionalFeeTypeOptions = [
        {
          text: `${this.$t('additional_fee.dropdown_types.broker_fee')}`,
          value: additionalFeeType.BROKER_FEE,
        },
        {
          text: `${this.$t('additional_fee.dropdown_types.contract_fee')}`,
          value: additionalFeeType.CONTRACT_FEE,
        },
      ];

      // filtered existing additionalFees
      if (this.agreementFromFirebase.additionalFees?.length > 0) {
        const usedFeeTypes = [];
        this.agreementFromFirebase.additionalFees.forEach((fee) =>
          usedFeeTypes.push(fee.type),
        );

        return defaultAdditionalFeeTypeOptions.filter(
          (fee) => !usedFeeTypes.includes(fee.value),
        );
      }

      return defaultAdditionalFeeTypeOptions;
    },
  },

  watch: {
    additionalFeeTypeDefault(val) {
      if (val) this.additionalFee.type = val.value;
    },
  },

  methods: {
    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionPostAgreementAdditionalFee: 'agreements/postAgreementAdditionalFee',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.resetForm();
      this.$emit('closeOverlay');
    },

    resetForm() {
      this.additionalFeeTypeDefault = defaultAdditionalFee.type;
      this.additionalFee = defaultAdditionalFee();
    },

    async validateAndSubmit() {
      this.validateAndSubmitInProgress = true;

      const validationResult = await this.$refs.form.validate();
      if (validationResult?.valid) {
        this.postAgreementAdditionalFee();
      } else {
        this.validateAndSubmitInProgress = false;
      }
    },

    postAgreementAdditionalFee() {
      if (this.agreementId) {
        this.actionPostAgreementAdditionalFee({
          agreementId: this.agreementId,
          ...this.additionalFee,
        })
          .then(() => {
            const properties = {
              feeType: this.additionalFee.type,
              feeFrequency: this.additionalFee.paymentInterval,
              feeAmount: this.additionalFee.amount,
              feePayerRole: this.additionalFee.payerRole,
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.ADD_FEE,
              properties,
            );
          })
          .then(() => {
            this.getAgreement();
            this.closeOverlay();
            this.validateAndSubmitInProgress = false;
          });
      } else {
        this.validateAndSubmitInProgress = false;
      }
    },

    getAgreement() {
      this.actionGetAgreement({ id: this.agreementId });
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
