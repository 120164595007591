<template>
  <div v-editable="blok" class="w-full flex items-Center" :class="classes">
    <div :class="dividerClasses"></div>
  </div>
</template>
<script>
import { ComponentFlexSpacing } from '~/utils/constants';

export default {
  name: 'RSpacer',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      const classes = [];

      switch (this.blok.size) {
        case ComponentFlexSpacing.SMALL:
          classes.push('h-2');
          break;
        case ComponentFlexSpacing.MEDIUM:
          classes.push('h-4');
          break;
        case ComponentFlexSpacing.LARGE:
          classes.push('h-6');
          break;
        case ComponentFlexSpacing.FULL:
          classes.push('flex-1');
          break;
      }

      return classes;
    },
    dividerClasses() {
      const classes = [];

      if (this.blok.hasDivider) {
        classes.push('h-px w-full');

        if (this.blok.dividerColor) {
          if (this.blok.dividerColor === 'white') classes.push('bg-white');
          if (this.blok.dividerColor === 'gray') classes.push('bg-gray-300');
        }
      }

      return classes;
    },
  },
};
</script>
