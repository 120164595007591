<template>
  <section :id="blok.id" v-editable="blok" class="flipping-cards py-10">
    <div
      class="scene max-w-3xl w-full flex relative overflow-hidden"
      :class="[blok.hasFlippingAnimations ? 'flex-row flex-nowrap' : 'flex-col gap-6']"
    >
      <div
        v-for="(card, index) in blok.flippingCardContent"
        :key="index"
        class="w-full shrink-0 rounded-3xl p-4 sm:px-10 grid sm:grid-cols-2 gap-8"
        :class="[
          cardBackgroundClass(card.backgroundColor),
          blok.hasFlippingAnimations ? 'opacity-0' : '',
          blok.hasFlippingAnimations && cardIsFlipped && index === 1
            ? 'order-first'
            : '',
        ]"
      >
        <div class="flex flex-col justify-between">
          <div v-if="card.cardLabel">
            <div
              class="text-white text-xl md:text-2xl font-medium bg-grayBlue-500 py-2 px-6 -ml-4 sm:-ml-10 mt-2 mb-4 inline-block rounded-r-lg"
            >
              {{ card.cardLabel }}
            </div>
          </div>
          <div class="grow flex flex-col justify-center">
            <component
              :is="theBlok.component"
              v-for="theBlok in card.content.filter((item) =>
                [
                  'RichTextBlok',
                  'FeatureCheckmarkList',
                  'ImageBlok',
                  'ButtonBlok',
                ].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              class="w-full"
              :class="theBlok.component === 'ImageBlok' ? 'sm:hidden' : ''"
            />
          </div>
          <div class="sm:hidden flex flex-col gap-2 my-4">
            <div v-if="card.button?.length > 0" class="flex flex-col gap-2">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.button.filter((item) =>
                  ['ButtonBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>
            <div v-else-if="blok.hasFlippingAnimations" class="ml-auto">
              <r-button
                :icon="index === 0 ? 'long-arrow-right' : 'long-arrow-left'"
                :icon-class="index === 0 ? 'ml-3' : 'mr-3'"
                :icon-position-after="index === 0"
                inverted
                :label="blok.flippingButtonLabel"
                @click="flipCard()"
              />
            </div>
          </div>
        </div>
        <div class="hidden sm:flex flex-col justify-end">
          <div class="grow flex items-center">
            <component
              :is="theBlok.component"
              v-for="theBlok in card.content.filter((item) =>
                ['ImageBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              class="w-full"
            />
          </div>
          <div class="flex flex-col gap-2 my-4">
            <div v-if="card.button?.length > 0" class="flex flex-col gap-2">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.button.filter((item) =>
                  ['ButtonBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>
            <div v-else-if="blok.hasFlippingAnimations" class="ml-auto">
              <r-button
                :icon="index === 0 ? 'long-arrow-right' : 'long-arrow-left'"
                :icon-class="index === 0 ? 'ml-3' : 'mr-3'"
                :icon-position-after="index === 0"
                inverted
                :label="blok.flippingButtonLabel"
                @click="flipCard()"
              />
            </div>
          </div>
        </div>
      </div>

      <!--      Flipping cards-->
      <div
        v-if="blok.hasFlippingAnimations"
        class="flipping-card"
        :class="{ 'is-flipped': cardIsFlipped }"
      >
        <div
          v-for="(card, index) in blok.flippingCardContent"
          :key="index"
          class="w-full shrink-0 rounded-3xl p-4 sm:px-10 grid sm:grid-cols-2 gap-8 flipping-card__face"
          :class="[
            cardBackgroundClass(card.backgroundColor),
            index === 0 ? 'flipping-card__face--front' : 'flipping-card__face--back',
          ]"
        >
          <div class="flex flex-col justify-between">
            <div v-if="card.cardLabel">
              <div
                class="text-white text-xl md:text-2xl font-medium bg-grayBlue-500 py-2 px-6 -ml-4 sm:-ml-10 mt-2 mb-4 inline-block rounded-r-lg"
              >
                {{ card.cardLabel }}
              </div>
            </div>

            <div class="grow flex flex-col justify-center">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.content.filter((item) =>
                  [
                    'RichTextBlok',
                    'FeatureCheckmarkList',
                    'ImageBlok',
                    'ButtonBlok',
                  ].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
                class="w-full"
                :class="theBlok.component === 'ImageBlok' ? 'sm:hidden' : ''"
              />
            </div>
            <div class="sm:hidden flex flex-col gap-2 my-4">
              <div v-if="card.button?.length > 0" class="flex flex-col gap-2">
                <component
                  :is="theBlok.component"
                  v-for="theBlok in card.button.filter((item) =>
                    ['ButtonBlok'].includes(item.component),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </div>
              <div v-else-if="blok.hasFlippingAnimations" class="ml-auto">
                <r-button
                  :icon="index === 0 ? 'long-arrow-right' : 'long-arrow-left'"
                  :icon-class="index === 0 ? 'ml-3' : 'mr-3'"
                  :icon-position-after="index === 0"
                  inverted
                  :label="blok.flippingButtonLabel"
                  @click="flipCard()"
                />
              </div>
            </div>
          </div>

          <div class="hidden sm:flex flex-col justify-end">
            <div class="grow flex items-center">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.content.filter((item) =>
                  ['ImageBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
                class="w-full"
              />
            </div>
            <div class="flex flex-col gap-2 my-4">
              <div v-if="card.button?.length > 0" class="flex flex-col gap-2">
                <component
                  :is="theBlok.component"
                  v-for="theBlok in card.button.filter((item) =>
                    ['ButtonBlok'].includes(item.component),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </div>
              <div v-else-if="blok.hasFlippingAnimations" class="ml-auto">
                <r-button
                  :icon="index === 0 ? 'long-arrow-right' : 'long-arrow-left'"
                  :icon-class="index === 0 ? 'ml-3' : 'mr-3'"
                  :icon-position-after="index === 0"
                  inverted
                  :label="blok.flippingButtonLabel"
                  @click="flipCard()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors, backgroundColorsHexValue } from '~/utils/constants';

export default {
  name: 'FlippingCards',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      backgroundColorsHexValue,
      cardIsFlipped: false,
    };
  },

  methods: {
    cardBackgroundClass(color) {
      return backgroundColors[color] ? backgroundColors[color] : 'bg-white border';
    },

    flipCard() {
      this.cardIsFlipped = !this.cardIsFlipped;
    },
  },
};
</script>

<style scoped lang="scss">
.scene {
  perspective: 2000px;
}

.flipping-card {
  transform-style: preserve-3d;
  transform-origin: right;
  transition: transform 0.2s;

  @apply w-full absolute top-0 bottom-0 h-full left-0 right-0;

  &.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  &__face {
    height: 100%;
    backface-visibility: hidden;

    @apply w-full absolute top-0 bottom-0 h-full left-0 right-0;
  }

  &__face--back {
    transform: rotateY(180deg);
  }
}
</style>
