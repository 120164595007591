<template>
  <client-only>
    <r-overlay
      class="add-administrative-rent-modal"
      fixed-height
      :size="ComponentSize.LARGE"
      :visible="showModal"
      @close="closeOverlay()"
    >
      <Form ref="administrativeFeeObserver" v-slot="{ meta }" class="h-full">
        <div class="flex flex-col pt-10 gap-2">
          <div class="px-6 py-4">
            <span class="py-2 text-1.5xl">
              {{ titleValue }}
            </span>
            <p class="opacity-60 pt-2 text-sm">
              {{ subtitleValue }}
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-2 py-2 bg-gray-50 h-full">
          <div class="bg-white px-6 py-4 flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <span class="font-medium">
                {{ $t('administrative_rent.included_or_separate_title') }}
              </span>
              <p class="opacity-60 text-sm">
                {{ $t('administrative_rent.included_or_separate_subtitle') }}
              </p>
            </div>
            <Field
              v-slot="{ meta }"
              v-model="administrativeFee.payerRole"
              rules="required"
              vid="payerRole"
              name="payerRole"
            >
              <r-tabs
                ref="payerRole"
                v-model="administrativeFee.payerRole"
                :error-text="$t('form.validation.is_required')"
                :tabs="payerRoleTabs"
                tabs-border-class="border-gray-50"
                :validation-failed="!meta.validated && !meta.valid"
                wrapped
              />
            </Field>
          </div>
          <div
            v-if="administrativeFee.paymentIncludedInRent"
            class="bg-white px-6 py-4 flex flex-col gap-2"
          >
            <span class="font-medium">
              {{ $t('administrative_rent.payment_description_title') }}
            </span>
            <p class="opacity-60 text-sm">
              {{ $t('administrative_rent.payment_description_subtitle') }}
            </p>
          </div>
          <div v-else class="flex flex-col gap-2">
            <!-- Fixed or Dynamic cost-->
            <div class="bg-white px-6 py-4 flex flex-col gap-4">
              <div class="flex flex-col gap-2">
                <span class="font-medium">
                  {{ $t('administrative_rent.payment_determination_title') }}
                </span>
                <p class="opacity-60 text-sm">
                  {{ $t('administrative_rent.payment_determination_subtitle') }}
                </p>
              </div>
              <Field
                v-slot="{ meta }"
                v-model="administrativeFee.paymentDetermination"
                rules="required"
                vid="paymentDetermination"
                name="paymentDetermination"
              >
                <r-tabs
                  ref="paymentDetermination"
                  v-model="administrativeFee.paymentDetermination"
                  :error-text="$t('form.validation.is_required')"
                  :tabs="paymentDeterminationTabs"
                  tabs-border-class="border-gray-50"
                  :validation-failed="!meta.validated && !meta.valid"
                  wrapped
                />
              </Field>
            </div>

            <!-- Payment amount and date -->
            <div v-if="isFixedCost" class="bg-white px-6 py-4 flex flex-col gap-4">
              <div class="flex flex-col gap-2">
                <span class="font-medium">
                  {{ $t('administrative_rent.payment_amount_and_date_title') }}
                </span>
                <p class="opacity-60 text-sm">
                  {{ $t('administrative_rent.payment_amount_and_date_subtitle') }}
                </p>
              </div>
              <div class="flex flex-col gap-2">
                <Field
                  v-slot="{ meta }"
                  v-model="paymentAmountModel"
                  :rules="{ required: true, isFloat: true }"
                  vid="rentAmount"
                  name="administrativeRentAmount"
                >
                  <r-input
                    ref="rentAmount"
                    v-model="paymentAmountModel"
                    :error-text="$t('additional_fee.validation.numeric')"
                    field-type="tel"
                    inputmode="decimal"
                    :label="
                      $t('agreement.label.administrative_fee_amount') +
                      ' ' +
                      '(' +
                      agreementFromFirebase.currency +
                      ')'
                    "
                    label-class="text-xs"
                    name="administrativeRentAmount"
                    required
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                  />
                </Field>
                <Field
                  v-slot="{ meta }"
                  v-model="paymentFrequencyModel"
                  :rules="{
                    required: true,
                    numeric: true,
                    min_value: 1,
                    max_value: 31,
                  }"
                  vid="paymentFrequency"
                  name="payment-frequency"
                >
                  <r-select
                    ref="paymentFrequency"
                    v-model="paymentFrequencyModel"
                    :error-text="$t('form.validation.day_of_the_month')"
                    :label="$t('agreement.label.monthly_rent_payment_day')"
                    label-class="text-xs"
                    name="payment-frequency"
                    :no-options-text="$t('form.validation.day_of_the_month')"
                    option-label="value"
                    :options="dateOptions()"
                    placeholder="1"
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                  />
                </Field>
              </div>
            </div>
            <!-- Payment recipient -->
            <div class="bg-white px-6 py-4 flex flex-col gap-4 mb-20">
              <div class="flex flex-col gap-2">
                <span class="font-medium">
                  {{ $t('administrative_rent.payer_role_title') }}
                </span>
                <p class="opacity-60 text-sm">
                  {{ $t('administrative_rent.payer_role_subtitle') }}
                </p>
              </div>
              <Field
                v-slot="{ failed }"
                v-model="administrativeFee.paymentRecipient"
                rules="required"
                vid="paymentRecipient"
                name="paymentRecipient"
              >
                <r-tabs
                  ref="paymentRecipient"
                  v-model="administrativeFee.paymentRecipient"
                  :error-text="$t('form.validation.is_required')"
                  :tabs="paymentRecipientRoleTabs"
                  tabs-border-class="border-gray-50"
                  :validation-failed="failed"
                  wrapped
                />
              </Field>
            </div>
          </div>
        </div>
        <r-sticky-container :reserve-space="false" sticky>
          <div class="grid grid-cols-2 gap-4">
            <r-button
              v-if="isAddFeeView"
              :disabled="isPutOrPostAdministrativeFeeInProgress"
              inverted
              :label="$t('buttons.back')"
              @click="!isPutOrPostAdministrativeFeeInProgress ? closeOverlay() : null"
            />
            <r-button
              v-if="isEditFeeView"
              :disabled="isPutOrPostAdministrativeFeeInProgress"
              icon="trash"
              inverted
              :is-loading="isDeleteAdministrativeFeeInProgress"
              :label="$t('buttons.delete')"
              @click="
                !isPutOrPostAdministrativeFeeInProgress &&
                !isDeleteAdministrativeFeeInProgress
                  ? deleteAdministrativeFee()
                  : null
              "
            />
            <r-button
              :disabled="isDeleteAdministrativeFeeInProgress"
              :is-loading="isPutOrPostAdministrativeFeeInProgress"
              :label="$t('buttons.save')"
              width="wide"
              @click="
                meta?.valid &&
                !isPutOrPostAdministrativeFeeInProgress &&
                !isDeleteAdministrativeFeeInProgress
                  ? isEditFeeView
                    ? putAdministrativeFee()
                    : postAdministrativeFee()
                  : validateAndScrollToFailedValidation()
              "
            />
          </div>
        </r-sticky-container>
      </Form>
    </r-overlay>
  </client-only>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ComponentSize,
  administrativeFeeModalSteps,
  administrativeFeePaymentDetermination,
  administrativeFeePaymentPartyType,
} from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

import { payerRoleTabsOptions, paymentTypeTabsOptions } from '~/utils/objectStructures';
import { validate, Field, Form } from 'vee-validate';

export default {
  name: 'AddAdministrativeRentModal',
  components: {
    Field,
    Form,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['closeOverlay', 'saveAdministrativeFee', 'deleteAdministrativeFee'],

  data() {
    return {
      isPutOrPostAdministrativeFeeInProgress: false,
      isDeleteAdministrativeFeeInProgress: false,
      ComponentSize: ComponentSize,
      trackingProcessNames: trackingProcessNames,
      agreementTrackingEventNames: agreementTrackingEventNames,
      showAddFeeView: false,
      payerRoleTabs: payerRoleTabsOptions(
        this.$t('administrative_rent.separate'),
        this.$t('administrative_rent.included'),
      ).reverse(),
      paymentDeterminationTabs: paymentTypeTabsOptions(
        this.$t('administrative_rent.no'),
        this.$t('administrative_rent.yes'),
      ),
      paymentRecipientRoleTabs: [
        {
          id: 'LANDLORD',
          title: this.$t('administrative_rent.payer_landlord'),
        },
        {
          id: 'SERVICE_PROVIDER',
          title: this.$t('administrative_rent.payer_service_provider'),
        },
      ],
      administrativeFee: {
        paymentIncludedInRent: true,
        payerRole: administrativeFeePaymentPartyType.LANDLORD,
        paymentDetermination: null,
        paymentRecipient: null,
        paymentAmount: null,
        paymentFrequency: null,
        id: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getAgreementInProgress: 'agreements/getAgreementInProgress',
    }),
    paymentAmountModel: {
      get() {
        return this.administrativeFee.paymentAmount || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.administrativeFee.paymentAmount = normalized || null;
      },
    },
    paymentFrequencyModel: {
      get() {
        return this.administrativeFee.paymentFrequency;
      },
      set(newVal) {
        this.administrativeFee.paymentFrequency = newVal.value;
      },
    },

    modalStep() {
      if (this.showAddFeeView) {
        return administrativeFeeModalSteps.ADD_FEE;
      } else {
        return administrativeFeeModalSteps.EDIT_FEE;
      }
    },

    isFixedCost() {
      return (
        this.administrativeFee?.paymentDetermination ===
        administrativeFeePaymentDetermination.FIXED
      );
    },

    isAddFeeView() {
      return this.modalStep === administrativeFeeModalSteps.ADD_FEE;
    },

    isEditFeeView() {
      return this.modalStep === administrativeFeeModalSteps.EDIT_FEE;
    },

    titleValue() {
      return this.$t('administrative_rent.title');
    },

    subtitleValue() {
      return this.$t('administrative_rent.subtitle');
    },
  },

  watch: {
    administrativeFee: {
      deep: true,
      handler(newVal) {
        if (newVal.payerRole === administrativeFeePaymentPartyType.LANDLORD) {
          this.administrativeFee.paymentIncludedInRent = true;
        } else if (newVal.payerRole === administrativeFeePaymentPartyType.TENANT) {
          this.administrativeFee.paymentIncludedInRent = false;
          if (!this.administrativeFee.paymentDetermination) {
            this.administrativeFee.paymentDetermination =
              administrativeFeePaymentDetermination.DYNAMIC;
          }
          if (!this.administrativeFee.paymentRecipient) {
            this.administrativeFee.paymentRecipient =
              administrativeFeePaymentPartyType.SERVICE_PROVIDER;
          }
        }
      },
    },
  },

  mounted() {
    this.setAdministrativeFee();
  },

  methods: {
    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      actionPostAdministrativeFee: 'agreements/postAdministrativeFee',
      actionDeleteAdministrativeFee: 'agreements/deleteAdministrativeFee',
      actionPutAdministrativeFee: 'agreements/putAdministrativeFee',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
    }),

    finalAdministrativeFee() {
      return Object.fromEntries(
        Object.entries(this.administrativeFee).filter(([_, v]) => v != null),
      );
    },

    validateAndScrollToFailedValidation() {
      const form = this.$refs.administrativeFeeObserver;
      form.validate().then(() => {
        if (form.errors && Object.keys(form.errors).length > 0) {
          for (const failedRef of Object.keys(form.errors)) {
            if (this.$refs[failedRef] && form.errors[failedRef].length > 0) {
              this.$refs[failedRef].focus();
            }
          }
        }
      });
    },

    setAdministrativeFee() {
      if (this.agreementFromFirebase?.administrativeFees?.length > 0) {
        this.administrativeFee = {
          paymentIncludedInRent:
            this.agreementFromFirebase.administrativeFees[0].paymentIncludedInRent,
          payerRole: this.agreementFromFirebase.administrativeFees[0].payerRole,
          paymentDetermination:
            this.agreementFromFirebase.administrativeFees[0].paymentDetermination,
          paymentRecipient:
            this.agreementFromFirebase.administrativeFees[0].paymentRecipient,
          paymentAmount: this.agreementFromFirebase.administrativeFees[0].paymentAmount,
          paymentFrequency:
            this.agreementFromFirebase.administrativeFees[0].paymentFrequency,
          id: this.agreementFromFirebase.administrativeFees[0].id,
        };
      } else {
        this.showAddFeeView = true;
      }
    },

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    postAdministrativeFee() {
      if (this.agreementId) {
        const administrativeFeeData = this.finalAdministrativeFee();
        this.isPutOrPostAdministrativeFeeInProgress = true;
        this.actionPostAdministrativeFee({
          agreementId: this.agreementId,
          administrativeFee: administrativeFeeData,
        })
          .then(() => {
            const properties = {
              feeType: 'ADMINISTRATIVE_FEE',
              feeFrequency: administrativeFeeData.paymentFrequency,
              feeAmount: administrativeFeeData.paymentAmount,
              feeIncludedInRent: administrativeFeeData.paymentIncludedInRent,
              feeRecipient: administrativeFeeData.paymentRecipient,
              feeDetermination: administrativeFeeData.paymentDetermination,
              feeId: administrativeFeeData.id,
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.ADD_ADMINISTRATIVE_RENT,
              properties,
            );
          })
          .then(() => {
            this.$emit('saveAdministrativeFee', administrativeFeeData);
            this.closeOverlay();
            this.isPutOrPostAdministrativeFeeInProgress = false;
          })
          .then(() => this.actionGetAgreement({ id: this.agreementId }))
          .catch((err) => this.actionReportErrorToSentry(err));
      }
    },

    putAdministrativeFee() {
      if (this.agreementId && this.administrativeFee.id) {
        const administrativeFeeData = this.finalAdministrativeFee();
        this.isPutOrPostAdministrativeFeeInProgress = true;
        const properties = {
          oldFeeIncludedInRent:
            this.agreementFromFirebase.administrativeFees[0].paymentIncludedInRent,
          payerRole: this.agreementFromFirebase.administrativeFees[0].payerRole,
          oldFeeDetermination:
            this.agreementFromFirebase.administrativeFees[0].paymentDetermination,
          oldFeeRecipient:
            this.agreementFromFirebase.administrativeFees[0].paymentRecipient,
          oldFeeAmount: this.agreementFromFirebase.administrativeFees[0].paymentAmount,
          oldFeeFrequency:
            this.agreementFromFirebase.administrativeFees[0].paymentFrequency,
          id: this.agreementFromFirebase.administrativeFees[0].id,
          oldFeeType: 'ADMINISTRATIVE_FEE',
        };

        this.actionPutAdministrativeFee({
          agreementId: this.agreementId,
          administrativeFee: administrativeFeeData,
          administrativeFeeId: administrativeFeeData.id,
        })
          .then(() => {
            const finalProperties = {
              ...properties,
              feeType: 'ADMINISTRATIVE_FEE',
              feeFrequency: administrativeFeeData.paymentFrequency,
              feeAmount: administrativeFeeData.paymentAmount,
              feeIncludedInRent: administrativeFeeData.paymentIncludedInRent,
              feeRecipient: administrativeFeeData.paymentRecipient,
              feeDetermination: administrativeFeeData.paymentDetermination,
              feeId: administrativeFeeData.id,
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.CHANGE_ADMINISTRATIVE_FEE,
              finalProperties,
            );
          })
          .then(() => {
            this.$emit('saveAdministrativeFee', administrativeFeeData);
            this.closeOverlay();
            this.isPutOrPostAdministrativeFeeInProgress = false;
          })
          .then(() => this.actionGetAgreement({ id: this.agreementId }))
          .catch((err) => this.actionReportErrorToSentry(err));
      }
    },

    deleteAdministrativeFee() {
      if (this.agreementId && this.administrativeFee.id) {
        const administrativeFeeData = this.finalAdministrativeFee();
        this.isDeleteAdministrativeFeeInProgress = true;
        this.actionDeleteAdministrativeFee({
          agreementId: this.agreementId,
          administrativeFeeId: administrativeFeeData.id,
        })
          .then(() => {
            const properties = {
              feeType: 'ADMINISTRATIVE_FEE',
              feeFrequency: administrativeFeeData.paymentFrequency,
              feeAmount: administrativeFeeData.paymentAmount,
              feeIncludedInRent: administrativeFeeData.paymentIncludedInRent,
              feeRecipient: administrativeFeeData.paymentRecipient,
              feeDetermination: administrativeFeeData.paymentDetermination,
              feeId: administrativeFeeData.id,
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.DELETE_ADMINISTRATIVE_FEE,
              properties,
            );
          })
          .then(() => {
            this.isDeleteAdministrativeFeeInProgress = false;
            this.closeOverlay();
            this.$emit('deleteAdministrativeFee');
          })
          .then(() => this.actionGetAgreement({ id: this.agreementId }))
          .catch((err) => this.actionReportErrorToSentry(err));
      }
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    dateOptions() {
      const MAX_DATE = 31;
      return [...Array(MAX_DATE).keys()].map((v) => ({
        value: (v + 1).toString(),
      }));
    },
  },
};
</script>
