<template>
  <div class="bg-white rounded-lg pt-8 pb-4 px-3">
    <div class="max-w-2xl m-auto">
      <div class="max-w-2xl m-auto text-center">
        <h2 class="text-1.5xl">
          {{ $t('agreement.agreementTypeSelect.agreement_types_title') }}
        </h2>
        <div class="text-gray-600 my-6">
          {{ $t('agreement.agreementTypeSelect.agreement_types_description') }}
        </div>
      </div>

      <r-tabs-switch v-model="tabsSwitchValue" :tabs="tabsSwitchData" />

      <r-radio
        v-model="selectedAgreementType"
        flavor="bordered"
        name="radio-disabled"
        :options="
          tabsSwitchValue === agreementCategories.PRIVATE
            ? agreementTypeOptionsForPrivate
            : agreementTypeOptionsForBusiness
        "
      />
    </div>
  </div>
</template>
<script>
import { agreementCategories, agreementTypes } from '~/utils/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgreementTypeSelectAtom',
  props: {
    allowedAgreementTypes: {
      type: Array,
      required: false,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data: (vm) => {
    return {
      tabsSwitchData: [
        {
          id: agreementCategories.PRIVATE,
          title: vm.$t('agreement.agreementTypeSelect.private'),
        },
        {
          id: agreementCategories.BUSINESS,
          title: vm.$t('agreement.agreementTypeSelect.business'),
        },
      ],
      tabsSwitchValue: agreementCategories.PRIVATE,
      selectedAgreementType: vm.value,
      agreementCategories: agreementCategories,
    };
  },
  computed: {
    agreementTypeOptionsForPrivate() {
      const options = [];
      if (
        this.allowedAgreementTypes === undefined ||
        this.allowedAgreementTypes.includes(agreementTypes.PL_NO)
      )
        options.push(this.PL_NO_Option);
      if (
        this.allowedAgreementTypes === undefined ||
        this.allowedAgreementTypes.includes(agreementTypes.PL_RE)
      )
        options.push(this.PL_REGULAR_Option);

      return options;
    },

    agreementTypeOptionsForBusiness() {
      const options = [];
      if (
        this.allowedAgreementTypes === undefined ||
        this.allowedAgreementTypes.includes(agreementTypes.PL_NI)
      )
        options.push(this.PL_NI_Option);
      if (
        this.allowedAgreementTypes === undefined ||
        this.allowedAgreementTypes.includes(agreementTypes.PL_RE)
      )
        options.push(this.PL_REGULAR_Option);

      return options;
    },

    PL_NO_Option() {
      return {
        value: agreementTypes.PL_NO,
        label: this.$t('agreement.agreementTypeSelect.pl_no.title'),
        bulletPoints: [
          this.$t('agreement.agreementTypeSelect.pl_no.arguments.argument_1'),
          this.$t('agreement.agreementTypeSelect.pl_no.arguments.argument_2'),
          this.$t('agreement.agreementTypeSelect.pl_no.arguments.argument_3'),
        ],
      };
    },

    PL_NI_Option() {
      return {
        value: agreementTypes.PL_NI,
        label: this.$t('agreement.agreementTypeSelect.pl_ni.title'),
        bulletPoints: [
          this.$t('agreement.agreementTypeSelect.pl_ni.arguments.argument_1'),
          this.$t('agreement.agreementTypeSelect.pl_ni.arguments.argument_2'),
          this.$t('agreement.agreementTypeSelect.pl_ni.arguments.argument_3'),
        ],
      };
    },

    PL_REGULAR_Option() {
      return {
        value: agreementTypes.PL_RE,
        label: this.$t('agreement.agreementTypeSelect.pl_regular.title'),
        bulletPoints: [
          this.$t('agreement.agreementTypeSelect.pl_regular.arguments.argument_1'),
          this.$t('agreement.agreementTypeSelect.pl_regular.arguments.argument_2'),
          this.$t('agreement.agreementTypeSelect.pl_regular.arguments.argument_3'),
        ],
      };
    },
  },
  watch: {
    tabsSwitchValue(val) {
      if (
        val === agreementCategories.PRIVATE &&
        this.selectedAgreementType === agreementTypes.PL_NI
      ) {
        this.selectedAgreementType = agreementTypes.PL_NO;
      } else if (
        val === agreementCategories.BUSINESS &&
        this.selectedAgreementType === agreementTypes.PL_NO
      ) {
        this.selectedAgreementType = agreementTypes.PL_NI;
      }
    },
    value(val) {
      this.selectedAgreementType = val;

      this.onExternallyChangeAgreementType();
    },
    selectedAgreementType(val) {
      this.$emit('update:modelValue', val);
    },
  },
  methods: {
    onExternallyChangeAgreementType() {
      if (
        this.agreementTypeOptionsForPrivate.some(
          (e) => e.value === this.selectedAgreementType,
        )
      ) {
        this.tabsSwitchValue = agreementCategories.PRIVATE;
      } else if (
        this.agreementTypeOptionsForBusiness.some(
          (e) => e.value === this.selectedAgreementType,
        )
      ) {
        this.tabsSwitchValue = agreementCategories.BUSINESS;
      }
    },
  },
});
</script>
