<template>
  <section :id="blok.id" v-editable="blok" class="feature-tables-section bg-violet-25">
    <div class="max-w-6xl mx-auto px-4 py-12">
      <div class="max-w-3xl mx-auto text-gray-600 text-center mb-12">
        <h3
          v-if="blok.title"
          v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
          class="my-4 font-semibold text-gray-900 text-2xl md:text-3xl opacity-0"
          v-html="blok.title"
        ></h3>
        <RichText
          v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
          :blok="blok.description"
          class="opacity-0"
        />
      </div>
      <div
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="opacity-0"
      >
        <div v-if="switchTabs?.length > 1" class="max-w-2xl mx-auto my-6">
          <r-tabs-switch v-model="activeTab" :tabs="switchTabs" />
        </div>
        <div v-for="table in blok.tables" :key="table._uid">
          <div v-if="activeTab === table._uid">
            <comparison-table-universal :blok="table.table[0]" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureTablesSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: this.blok.tables[0]?._uid,
    };
  },

  computed: {
    switchTabs() {
      return this.blok.tables.map((el) => ({
        id: el._uid,
        title: el.tabLabel,
      }));
    },
  },
};
</script>
