<template>
  <section
    v-if="!blok.hide"
    :id="blok.id"
    class="articles py-10 md:py-20 border-b border-gray-200 px-4"
  >
    <div class="max-w-6xl mx-auto">
      <div class="text-center mb-10">
        <h3 class="text-3xl md:text-4xl leading-tight font-bold text-gray-800">
          {{ blok.title }}
        </h3>
      </div>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-10"
      >
        <div v-for="article in articles" :key="article._uid">
          <r-article-card
            :author-name="article.content.authorName"
            class="h-full"
            :date="article.first_published_at"
            :image="article.content.image.filename"
            :intro="article.content.intro"
            :intro-max-length="Number(120)"
            :slug="article.full_slug"
            :tags="article.content.caseStudyCardTags"
            :title="article.content.title"
          />
        </div>
      </div>

      <div class="text-center mt-12">
        <r-button
          inverted
          :label="$t('see_all_articles')"
          :to="$localizedPath('articles')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import RArticleCard from '~/components/r-interface-components/r-article-card';

export default {
  name: 'SectionArticles',
  components: {
    RArticleCard,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup() {
    const storyblokApi = useStoryblokApi();
    const nuxtApp = useNuxtApp();
    const { data: articles, error } = await useAsyncData(async () => {
      const articleTranslationHiddenKey =
        nuxtApp.$getLocale() === 'en'
          ? 'articleTranslationHidden'
          : `articleTranslationHidden__i18n__${nuxtApp.$getLocale()}`;
      const options = {
        version: nuxtApp.$config.public.STORYBLOK_VERSION,
        language: nuxtApp.$getLocale(),
        starts_with: `${nuxtApp.$getCountry() === 'pl' ? 'poland' : 'estonia'}/articles/`,
        is_startpage: 0,
        filter_query: {
          [articleTranslationHiddenKey]: {
            not_in: true,
          },
        },
        per_page: 3,
      };

      const response = await storyblokApi.get(`cdn/stories`, options);
      const stories = response.data.stories;

      return stories;
    });

    if (error.value) {
      console.error(error.value);
    }

    return {
      articles,
    };
  },
};
</script>
