<template>
  <div class="background-check-window flex justify-center items-center">
    <background-check-loading
      v-if="putProfileInProgress || delayNeeded"
      class="mb-2 self-center"
      :class="{ 'bg-white rounded-lg p-6': !mobileVersion }"
    />
    <div v-else>
      <TenantBackgroundCheckTab
        v-if="blok"
        id="backgroundCheck"
        :blok="findComponentInArray('ProfilePersonalIdCodeSetting', blok)"
        :component-size="componentSizes.MEDIUM"
        :is-agreement-invite="isAgreementInvite"
        :score="score"
        :sticky-primary-button="stickyPrimaryButton"
        @submit-id-code="$emit('submitIdCode')"
      />
    </div>
  </div>
</template>

<script>
/** Submit Id Code event
 * Indicates that user has successfully filled and submitted ID code for background check
 * @event submitIdCode
 * @type void
 * */
import { mapGetters } from 'vuex';
import TenantBackgroundCheckTab from '~/components/section/tenant-profile-components/TenantBackgroundCheckTab';
import { ComponentSize } from '~/utils/constants';

export default {
  name: 'BackgroundCheckWindow',
  components: { TenantBackgroundCheckTab },
  props: {
    blok: [Object, Array],
    stickyPrimaryButton: {
      type: Boolean,
      default: false,
    },
    mobileVersion: {
      type: Boolean,
      default: false,
    },
    score: {
      type: String,
      default: null,
    },
    isAgreementInvite: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submitIdCode'],

  data() {
    return {
      componentSizes: ComponentSize,
      delayNeeded: false,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),
  },

  watch: {
    async putProfileInProgress() {
      this.delayNeeded = true;
      await setTimeout(() => {
        this.delayNeeded = false;
      }, 5000);
    },
  },

  methods: {
    findComponentInArray(componentName, componentsArray) {
      for (const component in componentsArray) {
        if (
          componentsArray[component].component &&
          componentsArray[component].component === componentName
        ) {
          return componentsArray[component];
        }
      }
      return false;
    },
  },
};
</script>
