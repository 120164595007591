<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="pricing-cards-section relative overflow-hidden"
    :class="classes"
  >
    <div class="max-w-3xl mx-auto px-4 py-12">
      <div class="text-center max-w-2xl mx-auto">
        <h2
          v-if="blok.title"
          class="text-4xl md:text-5xl leading-tight mb-6 text-gray-900"
        >
          <RichText :blok="blok.title" plain />
        </h2>

        <RichText
          v-if="blok.description"
          :blok="blok.description"
          class="w-full text-lg md:text-xl lg:my-8 mb-8 text-gray-600"
          plain
        />
      </div>

      <div class="grid sm:grid-cols-2 gap-6">
        <div v-for="card in blok.cards" :key="card._uid">
          <div class="border bg-white rounded-lg">
            <div class="p-4 border-b">
              <div class="flex flex-row items-center gap-2 flex-wrap">
                <h4 class="text-2xl font-semibold">{{ card.title }}</h4>
                <span v-if="card.titleLabel">
                  <r-chip :label="card.titleLabel" size="small" />
                </span>
              </div>

              <div v-if="card.description" class="text-gray-600 text-sm mb-4">
                {{ card.description }}
              </div>

              <div v-if="card.feeCalculation.length > 0">
                <component
                  :is="theBlok.component"
                  v-for="theBlok in card.feeCalculation"
                  :key="theBlok._uid"
                  :blok="theBlok"
                />
              </div>
              <div
                v-else-if="card.feeCalculationPlaceholder"
                class="text-3xl md:text-4xl font-medium"
              >
                {{ card.feeCalculationPlaceholder }}
              </div>
            </div>

            <div class="p-4 border-b">
              <div v-if="card.contentTitle" class="font-semibold mb-4">
                {{ card.contentTitle }}
              </div>
              <component
                :is="theBlok.component"
                v-for="theBlok in card.content.filter((item) =>
                  ['RichTextBlok', 'FeatureCheckmarkList'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>

            <div class="p-4">
              <component
                :is="theBlok.component"
                v-for="theBlok in card.button.filter((item) =>
                  ['ButtonBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'PricingCardsSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      const classes = [];
      classes.push(backgroundColors[this.blok?.backgroundColor] ?? 'bg-white');

      return classes;
    },
  },
};
</script>
