<template>
  <p>
    <span v-for="(part, index) in textParts" :key="index">
      <template v-if="part.type === partTypes.TEXT">
        {{ part.source }}
      </template>
      <template v-else-if="part.type === partTypes.SLOT">
        <slot :name="part.slotName">
          {{ part.source }}
        </slot>
      </template>
    </span>
  </p>
</template>

<script>
const partTypes = {
  TEXT: 'text',
  SLOT: 'slot',
};

export default {
  name: 'TextWithSlots',
  props: {
    // Example Usage - I have a {deposit_amount} currently in the bank account and <template #deposit_amount>...</template>
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      partTypes,
    };
  },
  computed: {
    textParts() {
      const parts = [];
      if (this.text.length === 0) return parts;

      const regex = /<slot>([a-zA-Z0-9_]+)<\/slot>/g;
      let match;

      let lastIndex = 0;

      while ((match = regex.exec(this.text)) !== null) {
        if (lastIndex < match.index) {
          // Add the text before the match.
          parts.push({
            type: partTypes.TEXT,
            source: this.text.substring(lastIndex, match.index),
          });
        }

        // Add the slot part.
        parts.push({
          type: partTypes.SLOT,
          slotName: match[1],
          source: match[0],
        });

        lastIndex = regex.lastIndex;
      }

      if (lastIndex < this.text.length) {
        // Add the remaining text after the last match.
        parts.push({
          type: partTypes.TEXT,
          source: this.text.substring(lastIndex),
        });
      }

      return parts;
    },
  },
};
</script>
