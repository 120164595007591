<template>
  <section class="profile-personal-id-code-setting">
    <div class="mx-auto max-w-2xl w-full bg-white p-4 sm:px-7 rounded-lg shadow-xl">
      <h3 class="text-xl font-bold mt-2">
        {{ blok.title }}
      </h3>
      <div v-if="!isDataFilled" class="text-gray-600 text-sm mt-2">
        {{ blok.content }}
      </div>
      <VeeForm
        ref="validator.personalIdCodeForm"
        v-slot="{ meta: formMeta, handleSubmit }"
        as="div"
      >
        <form
          autocomplete="off"
          @submit.prevent="handleSubmit(() => submitPersonalIdCode(formMeta.valid))"
        >
          <div class="grid sm:grid-cols-3 gap-4 mt-2">
            <div class="sm:col-span-2">
              <Field
                v-slot="{ meta }"
                v-model="personalIdCode"
                rules="required|idCode"
                name="personalIdCode"
              >
                <r-input
                  id="personalIdCode"
                  v-model="personalIdCode"
                  :disabled="isDataFilled"
                  :error-text="$t('form.validation.id_code.' + $getCountry())"
                  field-type="text"
                  for="personalIdCode"
                  :label="$t('form.label.id_code.' + $getCountry())"
                  name="personalIdCode"
                  :placeholder="$t('form.placeholder.id_code.' + $getCountry())"
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                />
              </Field>
            </div>
            <div class="pt-1 sm:pt-7">
              <r-button
                v-if="!isDataFilled && !isLoading"
                size="small"
                type="submit"
                width="full"
              >
                <span v-if="putProfileInProgress && idCodeSubmitted">
                  <r-loader class="flex justify-center" size="20" />
                </span>
                <span v-else>
                  {{ $t('buttons.continue') }}
                </span>
              </r-button>
            </div>
          </div>
        </form>
      </VeeForm>
      <BackgroundCheckSmallLabel
        v-if="profile && profile.scoreStatus && profile.idCode"
        :background-check-approved-label="blok.backgroundCheckApprovedLabel"
        :background-check-declined-label="blok.backgroundCheckDeclinedLabel"
        :background-check-declined-modal-link="blok.backgroundCheckDeclinedModalLink"
        :background-check-pending-label="blok.backgroundCheckPendingLabel"
        :background-check-pending-modal-link="blok.backgroundCheckPendingModalLink"
        :score="profile.scoreStatus"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Field, Form as VeeForm } from 'vee-validate';
import BackgroundCheckSmallLabel from '~/components/snippets/BackgroundCheckSmallLabel.vue';

export default {
  name: 'ProfilePersonalIdCodeSetting',
  components: {
    Field,
    VeeForm,
    BackgroundCheckSmallLabel,
  },

  props: {
    blok: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    personalIdCode: null,
    scoreStatus: null,
    isDataFilled: false,
    isLoading: true,
    idCodeSubmitted: false,
    profilePersonalIdCode: {
      personalIdCode: undefined,
    },
  }),

  computed: {
    ...mapGetters({
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),
  },

  watch: {
    profile(profileFromFirebase) {
      if (profileFromFirebase) this.setPersonalIdCodeAndScoreData(profileFromFirebase);
    },
  },

  mounted() {
    this.setPersonalIdCodeAndScoreData(this.profile);
  },

  beforeUnmount() {
    clearInterval(this.pollProfileScoring);
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
      fetchProfile: 'users/fetchProfile',
    }),

    setPersonalIdCodeAndScoreData(profileFromFirebase) {
      if (profileFromFirebase) {
        this.personalIdCode = profileFromFirebase.idCode
          ? profileFromFirebase.idCode
          : '';
        this.scoreStatus = profileFromFirebase.scoreStatus
          ? profileFromFirebase.scoreStatus
          : '';
        if (profileFromFirebase.idCode) this.isDataFilled = true;
        this.isLoading = false;
      }
    },

    async submitPersonalIdCode(isValid) {
      if (!isValid) return;

      this.profilePersonalIdCode.personalIdCode = this.personalIdCode.trim();
      await this.actionPutProfile({
        id: this.profile.id,
        profile: { ...this.profilePersonalIdCode },
      });
      this.idCodeSubmitted = true;

      this.pollProfileScoring();

      return true;
    },

    // TODO we need better solution for watching server changes
    pollProfileScoring() {
      let times = 1;
      this.interval = setInterval(() => {
        if (times < 10) {
          this.fetchProfile();
          times++;
        }
      }, 2000);
    },
  },
};
</script>
