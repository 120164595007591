<template>
  <client-only>
    <span class="p-1 flex flex-row items-center font-bold text-3xl text-rendin-500">
      <span
        >{{ currentPhrase }}
        <span class="font-light border-r-2 border-black h-9 animate-blink -ml-2"
          >&nbsp;</span
        >
      </span>
    </span>
  </client-only>
</template>

<script>
const PAUSE_BETWEEN_WORDS = 1500;

const writingStates = {
  WRITING: 'WRITING',
  WAITING: 'WAITING',
  DELETING: 'DELETING',
};

export default {
  name: 'AnimatedTypewriterText',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phraseIndex: 0,
      characterIndex: 1,
      writingState: writingStates.WRITING,
      timer: null,
    };
  },
  computed: {
    phrases() {
      return this.blok.content.map((content) => {
        return content.text.trim();
      });
    },
    currentPhrase() {
      if (this.phrases.length <= this.phraseIndex) {
        return '';
      }

      return this.phrases[this.phraseIndex].slice(0, this.characterIndex);
    },
  },
  mounted() {
    if (import.meta.client) this.write();
  },

  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    write() {
      if (this.writingState === writingStates.WRITING) {
        this.characterIndex++;

        if (this.characterIndex >= this.phrases[this.phraseIndex].length) {
          this.writingState = writingStates.WAITING;
        }
      } else if (this.writingState === writingStates.DELETING) {
        this.characterIndex--;

        /* We have deleted everything */
        if (this.characterIndex === 0) {
          this.writingState = writingStates.WRITING;
          this.phraseIndex++;
          if (this.phraseIndex >= this.phrases.length) this.phraseIndex = 0;
        }
      } else {
        this.writingState = writingStates.DELETING;
      }

      let delay;
      switch (this.writingState) {
        case writingStates.WRITING:
          delay = 125 + Math.random() * 50;
          break;
        case writingStates.WAITING:
          delay = PAUSE_BETWEEN_WORDS;
          break;
        case writingStates.DELETING:
          delay = 50 + Math.random() * 25;
          break;
      }

      this.timer = setTimeout(this.write, delay);
    },
  },
};
</script>
<style>
.animate-blink {
  animation: blinker 1s step-end infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
