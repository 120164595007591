<template>
  <div v-editable="blok" class="font-medium mt-12 my-4 md:mt-16 md:my-6">
    <component
      :is="blok.titleStyle ? blok.titleStyle : 'h2'"
      :id="
        replaceSpacesWithDashAndLowercase(
          blok?.titleForTableOfContent ? blok?.titleForTableOfContent : blok?.title,
        )
      "
      :class="classes"
    >
      <span v-html="blok.title"></span>
    </component>
  </div>
</template>

<script>
import { replaceSpacesWithDashAndLowercase } from '~/utils/replaceSpacesWithDashAndLowercase.js';

export default {
  name: 'ArticleTitle',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      replaceSpacesWithDashAndLowercase,
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (this.blok.titleStyle === 'h2') {
        classes.push('text-2xl md:text-3xl');
      } else if (this.blok.titleStyle === 'h3') {
        classes.push('text-xl md:text-2xl');
      }

      return classes;
    },
  },
};
</script>
