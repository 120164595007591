import {
  requestKeys,
  useVuexFirebaseRequests,
} from '~/components/composables/useVuexFirebaseRequests';

const requestGetMarketData = 'getMarketData';
const requests = [requestGetMarketData];

const firebaseRequestsSetup = useVuexFirebaseRequests(requests);

export const state = () => ({ ...firebaseRequestsSetup.state });
export const getters = { ...firebaseRequestsSetup.getters };
export const mutations = { ...firebaseRequestsSetup.mutations };

export const actions = {
  ...firebaseRequestsSetup.actions,
  getMarketData: function (
    { dispatch },
    { latitude, longitude, region, buildingYear, objectArea, numberOfRooms, type }
  ) {
    const getMarketDataKeys = requestKeys(requestGetMarketData);

    const request = {
      getDataFor: 'PREDICTION',
    };

    const requestData = {
      property: {
        latitude: latitude,
        longitude: longitude,
        region: region,
        buildingYear: buildingYear,
        objectArea: objectArea,
        numberOfRooms: numberOfRooms,
        type: type,
      },
    };

    return dispatch(getMarketDataKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },
};
