<template>
  <div
    ref="lead-form"
    class="flex justify-center items-center px-4"
    :class="{
      'min-h-screen': blok?.isFullPageForm,
      'form-section': !blok?.isFullPageForm,
    }"
  >
    <div class="w-full sm:w-10/12 flex flex-col justify-center sm:flex-row gap-4">
      <div
        v-if="assetUrl"
        class="w-full flex justify-center items-center"
        :class="{
          'fade-animation':
            (!blok.skipIntroductionStep && formStepIndex === 1) || formStepIndex > 1,
        }"
      >
        <img class="w-full pt-4 sm:pt-0" :src="assetUrl" />
      </div>
      <div
        class="flex items-center w-full py-4"
        :class="{ 'md:w-10/12 lg:w-8/12': !assetUrl }"
      >
        <div v-if="currentStep === formSteps.INTRODUCTION" class="flex flex-col gap-8">
          <div v-if="blok?.introContent?.length > 0">
            <component
              :is="childBlok.component"
              v-for="childBlok in blok.introContent.filter((item) =>
                ['RichTextBlok', 'FeatureCheckmarkList'].includes(item.component),
              )"
              :key="childBlok._uid"
              :blok="childBlok"
            />
          </div>
          <div class="flex justify-center">
            <r-button
              class="w-full sm:w-max"
              :label="blok.introButtonLabel"
              @click="nextStep"
            />
          </div>
        </div>
        <div
          v-else-if="currentStep === formSteps.EMAIL_COLLECTOR"
          class="flex flex-col w-full"
        >
          <div
            v-if="blok?.emailCollectorContent?.length > 0"
            :class="{ 'fade-animation': !blok.skipIntroductionStep }"
          >
            <component
              :is="childBlok.component"
              v-for="childBlok in blok.emailCollectorContent.filter((item) =>
                ['RichTextBlok', 'FeatureCheckmarkList'].includes(item.component),
              )"
              :key="childBlok._uid"
              :blok="childBlok"
            />
          </div>
          <Field
            v-slot="{ meta }"
            v-model="userEmail"
            name="email"
            :class="{ 'fade-animation': !blok.skipIntroductionStep }"
            rules="required|email"
          >
            <div class="flex flex-row gap-4">
              <r-input
                id="email"
                v-model="userEmail"
                autocomplete="on"
                class="w-full"
                :error-text="$t('form.validation.email')"
                field-type="email"
                :label="$t('form.label.email')"
                name="email"
                :placeholder="$t('form.placeholder.email')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @enter="
                  meta.valid && !submitInProgress ? submitEmailAndMakeNewLead() : ''
                "
                @input="!meta.dirty ? onDataEnteredEvent('email') : ''"
              />
              <div class="hidden md:block pt-6">
                <r-button
                  class="w-max"
                  :disabled="!meta.valid"
                  :is-loading="submitInProgress"
                  :label="blok.emailCollectorButtonLabel"
                  @click="
                    meta.valid && !submitInProgress ? submitEmailAndMakeNewLead() : ''
                  "
                />
              </div>
            </div>
            <r-button
              class="w-full md:hidden mt-4"
              :disabled="!meta.valid"
              :is-loading="submitInProgress"
              :label="blok.emailCollectorButtonLabel"
              @click="
                meta.valid && !submitInProgress ? submitEmailAndMakeNewLead() : ''
              "
            />
          </Field>
        </div>
        <div
          v-if="currentStep === formSteps.SUCCESS_SCREEN"
          class="flex justify-center items-center w-full fade-animation"
        >
          <div>
            <div class="flex flex-col sm:flex-col">
              <component
                :is="childBlok.component"
                v-for="childBlok in blok.successScreenContent.filter((item) =>
                  ['RichTextBlok', 'FeatureCheckmarkList', 'ButtonBlok'].includes(
                    item.component,
                  ),
                )"
                :key="childBlok._uid"
                :blok="childBlok"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { leadMagnetFormEventNames } from '~/utils/trackerConstants';
import { Field } from 'vee-validate';

const leadMagnetFormSteps = {
  INTRODUCTION: 'INTRODUCTION',
  EMAIL_COLLECTOR: 'EMAIL_COLLECTOR',
  SUCCESS_SCREEN: 'SUCCESS_SCREEN',
};

export default {
  name: 'EmailLeadMagnetForm',
  components: { Field },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formStepIndex: 0,
      formStepsValues: Object.values(leadMagnetFormSteps),
      formSteps: leadMagnetFormSteps,
      sendingEmailInProgress: false,
      userEmail: '',
      recordId: null,
      submitInProgress: false,
    };
  },

  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      profileId: 'users/profileId',
      country: 'getCountry',
      locale: 'getLocale',
    }),

    currentStep() {
      if (this.blok.skipIntroductionStep && this.formStepIndex === 0) {
        return this.formStepsValues[this.formStepIndex + 1];
      }
      return this.formStepsValues[this.formStepIndex];
    },

    assetUrl() {
      if (this.currentStep === this.formSteps.INTRODUCTION) {
        return this.blok?.introAsset?.filename;
      } else if (this.currentStep === this.formSteps.EMAIL_COLLECTOR) {
        return this.blok?.emailCollectorAsset?.filename;
      } else if (this.currentStep === this.formSteps.SUCCESS_SCREEN) {
        return this.blok?.successScreenAsset?.filename;
      } else {
        return null;
      }
    },
  },

  watch: {
    currentStep(newVal) {
      if (newVal) {
        const eventName = leadMagnetFormEventNames.NEXT_STEP;
        const props = {
          currentStep: newVal,
        };
        this.trackAgreementDraftForm({ eventName, props });
      }
    },
  },

  created() {
    if (this.blok.skipIntroductionStep) {
      this.formStepIndex = 1;
    }
  },

  methods: {
    ...mapActions({
      trackAgreementDraftForm: 'tracker/trackAgreementDraftForm',
    }),

    submitEmailAndMakeNewLead() {
      this.submitInProgress = true;

      const posthogDistinctId = this.$posthog.get_distinct_id();
      const path = window.location.pathname;
      const fullPath = window.location.href;

      const eventName = leadMagnetFormEventNames.NEW_LEAD;
      const eventProps = {
        email: this.userEmail,
      };

      const data = {
        email: this.userEmail,
        page: path,
        fullPath: fullPath,
        recordId: this.recordId,
        userFirebaseKey: this.profileId,
        posthogDistinctId: posthogDistinctId,
      };
      $fetch('/api/leads/upsert', { method: 'POST', body: data })
        .then((data) => {
          this.recordId = data.recordId;
        })
        .then(() => {
          if (this.userEmail) {
            return this.$setUserProperties({
              services: ['POSTHOG', 'CIO'],
              identify: !!this.userEmail,
              email: this.userEmail,
              internalUserProperties: {
                email: this.userEmail,
                country: this.country,
                locale: this.locale,
                source: 'web_lead_form',
              },
            });
          }
        })
        .then(() =>
          this.trackAgreementDraftForm({
            eventName,
            eventProps,
          }),
        )
        .catch((err) => {
          this.reportErrorToSentry(err);
        })
        .finally(() => {
          this.nextStep();
          this.submitInProgress = false;
        });
    },

    onDataEnteredEvent(inputName) {
      const eventName = leadMagnetFormEventNames.ENTER_DATA;
      const props = {
        inputName: inputName,
      };
      this.trackAgreementDraftForm({ eventName, props });
    },

    nextStep() {
      if (this.formStepIndex < 3) {
        this.formStepIndex += 1;
        this.$refs['lead-form'].scrollIntoView({ behavior: 'smooth' });
      }
    },

    goToStep(stepNumber) {
      this.formStepIndex = stepNumber;
    },
  },
};
</script>

<style scoped>
.fade-animation {
  animation: fadeIn 3s;
}

.form-section {
  min-height: 600px;
}

@media screen and (max-width: 640px) {
  .form-section {
    min-height: 800px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
