<template>
  <header
    v-editable="blok"
    class="border-b border-t border-rendin-100 px-3 md:px-0"
    :class="classes"
  >
    <div class="mx-auto max-w-6xl">
      <div
        class="py-16 md:py-24 md:pb-24 grid gap-4 md:gap-6 lg:gap-10 grid-cols-1"
        :class="{ 'md:grid-cols-2': blok.columns }"
      >
        <div
          class="flex content-center flex-wrap sm:px-3"
          :class="{
            'text-center': !blok.columns,
            ' text-center md:text-left': blok.columns,
          }"
        >
          <div class="w-full">
            <h1
              class="m-0 text-4xl font-extrabold text-gray-800 border-solid sm:text-4xl md:text-5xl mb-4"
              :class="{ 'mb-8': blok.columns }"
              style="line-height: 1.2em"
            >
              {{ blok.title }}
            </h1>
            <p
              class="text-body text-gray-800 text-lg lg:text-xl"
              :class="{ 'w-11/12 md:w-4/5 mx-auto': !blok.columns }"
            >
              {{ blok.description }}
            </p>

            <div
              v-if="blok.button?.length"
              class="mt-10 flex flex-wrap gap-4 items-center"
              :class="{
                'justify-center': !blok.columns,
                'justify-center md:justify-start': blok.columns,
              }"
            >
              <div v-for="btn in blok.button" :key="btn.uuid">
                {{ btn.title }}
                <div v-if="btn && btn.component === 'ButtonBlok'">
                  <ButtonBlok :blok="btn" />
                </div>

                <div v-if="btn && btn.component === 'AppButtonBlok'" class="mt-10">
                  <AppButtonBlok :blok="btn" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <pre>{{blok}}</pre> -->

        <div v-if="blok.columns">
          <component
            :is="blok.component"
            v-for="blok in blok.section_image"
            :key="blok._uid"
            :blok="blok"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'SectionHeader',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      const classes = [];
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      } else {
        classes.push('bg-rendin-50');
      }

      return classes;
    },
  },
  methods: {
    getButtonType(button) {
      if (!button) {
        return false;
      }

      return button[0].component;
    },
  },
};
</script>

<style scoped></style>
