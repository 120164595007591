<template>
  <div>
    <div>
      <span class="pt-3 pb-2"> {{ $t('country.choose_country') }}: </span>
      <div class="pb-6">
        <r-country-select
          country-name
          :options="selectCountryOptions"
          :model-value="selectCountryDefault"
          @change="changeCountry($event)"
        />
      </div>
    </div>
    <div>
      <span> {{ $t('choose_language') }}: </span>
      <a
        v-for="(locale, index) in $store.state.locales[$getCountry()].list"
        v-show="!$store.state.isStoryblok"
        :key="locale + index"
        :href="$switchLocalizedPath(locale, $route.path)"
      >
        <r-button
          v-if="$store.state.locale !== locale && languageNames[locale]"
          border-color="light-gray"
          class="mt-4"
          color="black"
          hover="hover:text-rendin-500 hover:shadow-md"
          inverted
          width="full"
          @click="$emit('close') && trackLanguageChange(locale)"
        >
          {{ languageNames[locale] }}
        </r-button>

        <r-button
          v-if="$store.state.locale === locale && languageNames[locale]"
          class="mt-4"
          inverted
          width="full"
        >
          {{ languageNames[locale] }}
        </r-button>
      </a>
    </div>

    <r-button
      display="block"
      :label="$t('buttons.close')"
      no-background
      uppercase
      @click="$emit('close')"
    />
  </div>
</template>
<script>
import { mapGetters, useStore } from 'vuex';

export default {
  name: 'LanguageSelector',
  emits: ['close'],
  async setup() {
    const store = useStore();
    const route = useRoute();
    const nuxtApp = useNuxtApp();

    const changeCountry = (newCountry) => {
      /* Are we in DEMO environment */
      if (store.state.showCountrySelect) {
        const rendinDemoCookie = useCookie('rendin_demo_country', {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
          secure: true,
          sameSite: 'None',
        });
        rendinDemoCookie.value = newCountry.value;

        if (route.query._storyblok) {
          window.location.href = `${nuxtApp.$getHost()}${
            route.path
          }?${new URLSearchParams(route.query).toString()}`;
        } else {
          window.location.href = nuxtApp.$getHost() + route.path;
        }
      } else {
        window.location.href = newCountry.url + route.path;
      }
    };

    return {
      changeCountry,
    };
  },
  data() {
    return {
      languageNames: {
        et: 'Eesti',
        en: 'English',
        ru: 'Русский',
        pl: 'Polski',
      },
      selectCountryDefault: {
        text: this.countryToText(this.$store.state.country),
        value: this.$store.state.country,
      },
      selectCountryOptions: [
        {
          text: this.$t('country.country_select.text_estonia'),
          value: this.$t('country.country_select.value_estonia'),
          url: 'https://rendin.ee',
        },
        {
          text: this.$t('country.country_select.text_poland'),
          value: this.$t('country.country_select.value_poland'),
          url: 'https://rendin.pl',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      locale: 'getLocale',
    }),
  },
  methods: {
    countryToText(country) {
      if (!country) return 'UNSUPPORTED COUNTRY';
      switch (country.toLowerCase()) {
        case 'ee':
          return this.$t('country.estonia');
        case 'pl':
          return this.$t('country.poland');
        default:
          return 'UNSUPPORTED COUNTRY';
      }
    },
    trackLanguageChange(newLocale) {
      this.$trackEvent(
        'Change Language',
        {
          from: this.$store.state.locale,
          to: newLocale,
          source: 'navigation',
        },
        {
          services: ['POSTHOG'],
        },
      );
    },
  },
};
</script>
