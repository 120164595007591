<template>
  <div
    v-if="content?.length > 0"
    class="p-4 max-w-6xl mx-auto flex justify-between gap-8 my-8"
  >
    <div v-if="contentsOfTable.length > 0" class="hidden lg:block">
      <div class="sticky w-80 top-16">
        <r-table-of-contents
          class="border-t-1 border-b-1 py-4"
          :contents="contentsOfTable"
          :title="$t('table_of_contents')"
        />
      </div>
    </div>
    <div class="max-w-3xl m-auto w-full article__content">
      <component
        :is="contentBlok.component"
        v-for="contentBlok in content"
        :id="contentBlok._uid"
        :key="contentBlok._uid"
        :blok="contentBlok"
        :class="[contentBlok.component === 'VideoBlok' ? 'my-8' : '']"
      />
    </div>
  </div>
</template>

<script>
import { replaceSpacesWithDashAndLowercase } from '~/utils/replaceSpacesWithDashAndLowercase.js';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';
import RTableOfContents from '~/components/r-interface-components/r-table-of-contents';
import ArticleSection from '~/components/snippets/article/ArticleSection';
import ArticleTitle from '~/components/snippets/article/ArticleTitle';
import Separator from '~/components/snippets/Separator';

export default {
  name: 'ArticleContent',
  components: {
    RTableOfContents,
    ArticleSection,
    ArticleTitle,
    Separator,
  },

  props: {
    content: {
      type: [Object, Array],
      default: null,
    },
  },

  setup() {
    return {
      replaceSpacesWithDashAndLowercase,
      localizedRenderRichText,
    };
  },

  data() {
    return {
      visibleComponentId: null,
    };
  },

  computed: {
    contentsOfTable() {
      const array = [];
      const articleTitleComponents = this.content.filter((item) =>
        ['ArticleTitle', 'ArticleSection'].includes(item.component),
      );

      //  TitleForTableOfContent, showInTableOfContent
      articleTitleComponents.forEach((el) => {
        if (el.showInTableOfContent) {
          const elTitle = el.titleForTableOfContent
            ? el.titleForTableOfContent
            : el.title;
          array.push({
            title: elTitle,
            anchorId: this.replaceSpacesWithDashAndLowercase(elTitle),
            componentId: el._uid,
            visible: this.visibleComponentId === el._uid,
          });
        }
      });
      return array;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.content.forEach((el) => {
        // add observer only if component is included in contentsOfTable
        if (this.contentsOfTable.some((obj) => obj.componentId === el._uid)) {
          this.observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  this.visibleComponentId = el._uid;
                }
              });
            },
            {
              threshold: 0.5,
            },
          );

          this.observer.observe(document.getElementById(el._uid));
        }
      });
    });
  },

  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss">
.article__content > .r-rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mt-12 md:mt-16;
  }
}
</style>
