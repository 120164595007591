<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="social-proof-single-testimonial py-20 px-4"
  >
    <div
      v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
      class="max-w-6xl mx-auto bg-violet-50 rounded-xl p-8 lg:px-12 opacity-0"
    >
      <div class="flex flex-col md:flex-row gap-8 justify-center items-center">
        <div :class="{ 'w-full': blok.wrapContent }">
          <RichText
            :blok="blok.testimonial"
            class="text-xl md:text-2xl font-medium italic text-gray-900"
          />
          <div class="flex gap-4">
            <div>
              <img
                :alt="blok.thumbnail.alt"
                class="rounded-full"
                :src="
                  $transformStoryblokImage(blok.thumbnail.filename, '/m/56x56/smart/')
                "
              />
            </div>
            <div>
              <div class="font-medium font-lg">
                {{ blok.name }}
              </div>
              <div class="text-gray-600">
                {{ blok.profession }}
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'w-full flex justify-center': blok.wrapContent }">
          <img
            :alt="blok.ratingLogos.alt"
            class="md:max-w-xs p-8"
            :src="$transformStoryblokImage(blok.ratingLogos.filename, '/m/250x0')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SocialProofSingleTestimonial',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
