<template>
  <div class="p-4 py-20 flex justify-center items-start scrollable-page">
    <div class="max-w-screen-lg flex flex-col gap-12">
      <!--  Header  -->
      <div class="flex-1 flex flex-col items-center justify-center gap-4">
        <h1 class="text-2xl font-bold">{{ page.title }}</h1>
        <p class="text-base max-w-3xl">{{ page.subtitle }}</p>
      </div>
      <div class="flex-1 grid grid-cols-2 gap-4">
        <div class="flex flex-col gap-2 bg-white bg-opacity-60 rounded-2xl">
          <SAFECollapsibleSection
            v-for="(section, i) in sections"
            :key="i"
            :expanded="started && currentStep === i"
            :is-already-seen="started && currentStep >= i"
            :section="section"
          />
        </div>
        <div class="relative shadow-lg rounded-2xl overflow-hidden section-image">
          <div
            v-for="(sectionImage, index) in sectionImages"
            :key="index"
            class="absolute"
          >
            <Transition mode="in-out" name="slide">
              <img
                v-if="currentStep === index"
                :key="sectionImage"
                :src="sectionImage"
              />
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SAFECollapsibleSection from '~/components/storyblok/scroll-animated-flow-explainer/SAFECollapsibleSection.vue';

export default {
  name: 'SAFEScrollablePage',
  components: { SAFECollapsibleSection },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    started: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    sectionImages() {
      if (!this.page.sections) {
        return [];
      }

      const transformedImageUrls = this.page.sections.map((section) => {
        return this.$transformStoryblokImage(section.image.filename, '/m/');
      });

      return transformedImageUrls;
    },
    sections() {
      if (!this.page || !this.page.sections) return [];

      return this.page.sections;
    },
  },
};
</script>
<style scoped lang="css">
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.section-image {
  width: 460px;
  height: 400px;
}
</style>
