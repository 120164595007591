<template>
  <section :id="blok.id" v-editable="blok" class="features-section" :class="classes">
    <div class="max-w-6xl mx-auto md:grid-cols-2 md:grid items-center gap-12 px-4 py-8">
      <div
        v-intersection="
          blok.imagePositionLeft
            ? { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right' }
            : { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left' }
        "
        class="opacity-0 py-4 text-gray-600 h-full md:flex flex-col justify-center"
      >
        <h3
          v-if="blok.title"
          class="my-4 font-semibold text-gray-900 text-2xl md:text-3xl"
          v-html="blok.title"
        ></h3>
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.content.filter((item) =>
            [
              'TextBlok',
              'RichTextBlok',
              'FeatureCheckmarkList',
              'TitleIconBlok',
              'DelayActionBlok',
            ].includes(item.component),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
          class="w-full md:text-lg"
        />

        <div v-if="blok.buttons?.length" class="my-4 flex gap-4 flex-wrap items-center">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.buttons.filter((item) =>
              ['ButtonBlok', 'Accordion', 'ButtonWithContentModal'].includes(
                item.component,
              ),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-max"
            @click="$emit('buttonClick', theBlok)"
          />
        </div>
      </div>

      <div
        v-intersection="
          blok.imagePositionLeft
            ? { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left' }
            : { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right' }
        "
        class="opacity-0"
        :class="[blok.imagePositionLeft ? 'md:order-first' : '']"
      >
        <div v-if="blok.image?.filename">
          <img
            :alt="blok.image.alt"
            class="rounded-bl-3xl rounded-br-3xl mx-auto"
            :class="[
              blok.imagePositionLeft
                ? 'md:rounded-tl-3xl md:rounded-bl-3xl md:rounded-br-none'
                : 'md:rounded-tr-3xl md:rounded-br-3xl md:rounded-bl-none',
              blok.fullRoundedImage
                ? 'rounded-3xl md:rounded-br-3xl md:rounded-bl-3xl'
                : '',
              blok.imageHasShadow ? ' shadow-sm' : '',
            ]"
            :src="$transformStoryblokImage(blok.image.filename, '/m/600x670/')"
          />
        </div>
        <div v-if="blok.rightContent?.length > 0">
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.rightContent.filter((item) =>
              ['VideoBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:text-lg"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'FeaturesSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  emits: ['buttonClick'],
  computed: {
    classes() {
      const classes = [];
      classes.push(backgroundColors[this.blok?.backgroundColor] ?? 'bg-white');

      return classes;
    },
  },
};
</script>
