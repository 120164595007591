<template>
  <div v-editable="blok" class="video-blok__img flex w-full justify-center relative">
    <div v-if="blok.image && blok.image.filename" class="shadow-xl">
      <img
        :alt="blok.image.alt"
        :src="$transformStoryblokImage(blok.image.filename, '/m/fit-in/800x0/')"
      />
    </div>
    <div
      v-if="
        (blok.embedVideoLink && blok.embedVideoLink.cached_url) ||
        blok.storyblokVideo?.filename
      "
      class="absolute w-full h-full top-0 left-0"
    >
      <div class="flex justify-center items-center h-full">
        <div
          class="video-blok__btn h-12 w-12 sm:h-20 sm:w-20 bg-gradient-to-r from-rendin-600 to-rendin-300 text-white relative rounded-full flex justify-center items-center cursor-pointer z-10"
          @click="showOverlay = true"
        >
          <r-icon class="pl-1 sm:pl-1.5 text-2xl sm:text-4xl" icon-name="play" />
        </div>
      </div>
    </div>

    <!--    Video modal modal-->
    <r-overlay
      v-if="
        (showOverlay && blok.embedVideoLink && blok.embedVideoLink.cached_url) ||
        blok.storyblokVideo?.filename
      "
      header-class="z-10"
      size="large"
      :visible="showOverlay"
      @close="showOverlay = false"
    >
      <div class="bg-black py-24 sm:py-0">
        <r-video
          :auto-play="blok.autoPlayYouTube"
          class="flex justify-center"
          :embed-video-link="
            blok.embedVideoLink.cached_url
              ? blok.embedVideoLink.cached_url
              : blok.storyblokVideo?.filename
          "
          video-height="450"
          video-width="800"
        />
      </div>
    </r-overlay>
  </div>
</template>

<script>
export default {
  name: 'VideoBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
    };
  },
};
</script>
<style scoped lang="scss">
.video-blok__img::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-blok__btn::before {
  content: '';
  display: block;
  @apply animate-ping absolute inline-flex h-10 w-10 sm:h-14 sm:w-14  rounded-full bg-rendin-300 opacity-75;
}
</style>
