<template>
  <section class="handover-edit-view-meters">
    <h2 class="text-xl md:text-3xl font-medium mb-6">
      {{ handoverTypeTranslation('add_entity_view_title', handoverEntityType) }}
    </h2>

    <div class="flex flex-col md:flex-row justify-between md:items-center gap-6">
      <div class="text-gray-600">
        {{ handoverTypeTranslation('add_entity_view_description', handoverEntityType) }}
      </div>

      <r-button
        border-color="light-gray"
        class="shrink-0 max-w-full"
        color="black"
        icon="plus"
        icon-class="mr-2"
        inverted
        :label="handoverTypeTranslation('add_entity_view_button', handoverEntityType)"
        label-class="text-ellipsis"
        size="small"
        width="small"
        @click="openHandoverEditViewEntityAddOrEditModal()"
      />
    </div>

    <ul
      v-if="handoverEntityType === handoverDataTypes.OTHERS"
      class="my-8 flex flex-col gap-4 bg-peach-100 p-4 rounded-lg"
    >
      <li class="flex">
        <r-icon class="text-rendin-700 text-xl w-8 shrink-0" icon-name="key" />
        <div
          v-html="
            handoverTypeTranslation('keys_number_of_sets_type_etc', handoverEntityType)
          "
        ></div>
      </li>
      <li class="flex">
        <r-icon class="text-rendin-700 text-xl w-8 shrink-0" icon-name="hammer" />
        <div
          v-html="
            handoverTypeTranslation('damage_describe_any_damage', handoverEntityType)
          "
        ></div>
      </li>
      <li class="flex">
        <r-icon class="text-rendin-700 text-xl w-8 shrink-0" icon-name="gem" />
        <div
          v-html="
            handoverTypeTranslation(
              'special_items_mention_valuables',
              handoverEntityType,
            )
          "
        ></div>
      </li>
    </ul>

    <div v-if="agreementHandover?.[handoverEntityType]?.length > 0">
      <div class="grid gap-4 my-6 md:my-12">
        <div v-for="entity in agreementHandover?.[handoverEntityType]" :key="entity.id">
          <r-handover-card
            :card-title="
              entity.name || handoverTypeTranslation(entity.type, handoverEntityType)
            "
            :description="entity.description"
            :description-label="$t('agreement.handover_v2.description')"
            :images-data="entity.files"
            :images-description-label="$t('agreement.handover_v2.image_comments')"
            show-edit-button
            :value="entity.value"
            value-icon="meter"
            @edit-button-click="
              openHandoverEditViewEntityAddOrEditModal(entity.entityId)
            "
          />
        </div>
      </div>
    </div>

    <HandoverEditViewEntityAddOrEditModal
      v-if="showEntityModal"
      :active-entity-id="activeEntityId"
      :agreement-handover="agreementHandover"
      :agreement-id="agreementId"
      :handover-entity-type="handoverEntityType"
      :show-modal="showEntityModal"
      @close-overlay="closeHandoverEditViewEntityAddOrEditModal"
    />
  </section>
</template>
<script>
import HandoverEditViewEntityAddOrEditModal from '~/components/section/agreement/handover/HandoverEditViewEntityAddOrEditModal.vue';
import { handoverDataTypes } from '~/utils/constants.js';
import { handoverTypeTranslation } from '~/composables/handoverTranslationsConstructor.js';

export default {
  name: 'HandoverEditViewEntity',
  components: {
    HandoverEditViewEntityAddOrEditModal,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    handoverEntityType: {
      type: String,
      default: null,
    },
  },

  setup() {
    return {
      handoverTypeTranslation,
    };
  },

  data: () => ({
    showEntityModal: false,
    activeEntityId: null,
    handoverDataTypes,
    condition: null,
  }),

  methods: {
    openHandoverEditViewEntityAddOrEditModal(entity) {
      console.log('openHandoverEditViewEntityAddOrEditModal');
      console.log(entity);
      if (entity) {
        this.activeEntityId = entity;
        this.showEntityModal = true;
      } else {
        this.showEntityModal = true;
      }
    },

    closeHandoverEditViewEntityAddOrEditModal() {
      this.activeEntityId = null;
      this.showEntityModal = false;
    },
  },
};
</script>
