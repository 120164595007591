<template>
  <div>
    <div class="chart-container">
      <canvas id="priceRangeChart" ref="priceRangeChartCanvas"></canvas>
    </div>
  </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
import { DateTime } from 'luxon';

export default {
  name: 'PriceCalculatorMonthlyPriceChart',
  props: {
    statistics: {
      type: Array,
      required: true,
    },
    xAxisTitle: {
      type: String,
      required: true,
    },
    yAxisTitle: {
      type: String,
      required: true,
    },
    medianRentAmountLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    Chart.register(...registerables);

    this.renderChart(this.statistics, this.xAxisTitle, this.yAxisTitle);
  },
  methods: {
    renderChart(statistics, xAxisTitle, yAxisTitle) {
      const labels = statistics.map((item) => {
        const date = DateTime.local(item.year, item.month);

        return date.toFormat('MMM yyyy');
      });

      // Updated datasets to include both rent amount and administrative rent
      const medianRentAmountData = statistics.map((item) => item.medianRentAmount);
      const medianAdministrativeRentData = statistics.map(
        (item) => item.medianAdministrativeRent,
      );

      const maxYValue =
        Math.max(...medianRentAmountData, ...medianAdministrativeRentData) * (100 / 80);

      const minYValue = Math.min(...medianRentAmountData) * (80 / 100);

      if (this.chart) {
        this.chart.destroy(); // Ensure to destroy the previous instance before creating a new one
      }

      const ctx = this.$refs.priceRangeChartCanvas.getContext('2d');
      if (!ctx) return;

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: this.medianRentAmountLabel,
              data: medianRentAmountData,
              borderColor: 'orange',
              backgroundColor: 'transparent',
              fill: false,
            },
            /*             {
                          label: 'Administrative Rent',
                          data: medianAdministrativeRentData,
                          borderColor: 'darkgray',
                          backgroundColor: 'transparent',
                          fill: false,
                        }, */
          ],
        },
        options: {
          plugins: {
            legend: {
              labels: {
                boxHeight: 0,
              },
            },
          },
          scales: {
            y: {
              /*               beginAtZero: true, */
              suggestedMax: maxYValue,
              suggestedMin: minYValue,
              title: {
                display: true,
                text: yAxisTitle,
              },
            },
            x: {
              title: {
                display: true,
                text: xAxisTitle,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: true,
        },
      });
    },
  },
};
</script>
<style scoped></style>
