<template>
  <VeeForm ref="validator.educationForm" v-slot="{ meta: formMeta, handleSubmit }">
    <form
      autocomplete="off"
      class="flex flex-col gap-3"
      @submit.prevent="handleSubmit(() => submitForm(formMeta.valid))"
    >
      <Field v-slot="{ meta }" v-model="coTenantsAmount" name="coTenantsAmount">
        <r-select
          v-model="coTenantsAmount"
          :label="$t('tenant_profile.tabs.co_tenants.cotenants_amount_label')"
          option-label="label"
          :options="coTenantsOptions"
          :placeholder="
            $t('tenant_profile.tabs.co_tenants.cotenants_amount_placeholder')
          "
          :reduce="(coTenantsOption) => coTenantsOption.value"
          :validation-failed="meta.validated && !meta.valid"
          :validation-passed="meta.validated && meta.valid"
        />
      </Field>
      <r-radio
        v-model="hasAPet"
        flavor="medium"
        horizontal
        justify="between"
        name="hasAPet"
        option-label="label"
        :options="petOptions"
      />
      <Field
        v-if="hasAPet"
        v-slot="{ meta }"
        v-model="petType"
        name="petType"
        rules="required|min:1"
      >
        <r-select
          v-model="petType"
          :label="$t('tenant_profile.tabs.co_tenants.pet_type_label')"
          option-label="label"
          :options="petTypes"
          :placeholder="$t('tenant_profile.tabs.co_tenants.pet_type_placeholder')"
          :reduce="(petOption) => petOption.value"
          :validation-failed="meta.validated && !meta.valid"
          :validation-passed="meta.validated && meta.valid"
        />
      </Field>
      <div class="buttons flex gap-3">
        <r-button no-background width="full" @click="closeTab">
          {{ $t('buttons.cancel') }}
        </r-button>
        <r-button type="submit" width="full">
          <span v-if="loading">
            <r-loader class="flex justify-center" size="20" />
          </span>
          <span v-else>
            {{ $t('buttons.save') }}
          </span>
        </r-button>
      </div>
    </form>
  </VeeForm>
</template>
<script>
import { useTenantConstants } from '~/composables/useTenantConstants.ts';
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { Form as VeeForm, Field } from 'vee-validate';

export default {
  name: 'TenantCotenantsTab',

  components: {
    VeeForm,
    Field,
  },

  props: {
    tenantProfile: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['submit', 'close'],

  async setup() {
    const { petTypes } = useTenantConstants();
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return {
      petTypes,
      getAnalyticEventNames,
      trackTenantProfileEvent,
    };
  },

  data: (vm) => ({
    coTenantsAmount: null,
    hasAPet: null,
    petType: null,
    validateEventTriggered: false,
    petOptions: [
      {
        label: vm.$t('form.label.have_pets'),
        value: true,
      },
      {
        label: vm.$t('form.label.not_have_pets'),
        value: false,
      },
    ],
  }),
  computed: {
    coTenantsOptions() {
      const maxNumberOfCotenants = 9;

      const coTenantsOptions = [];
      coTenantsOptions.push({
        label: this.$t('tenant_profile.tabs.co_tenants.cotenants_value_none'),
        value: 0,
      });
      for (let i = 1; i <= maxNumberOfCotenants; i++) {
        const computedLabel =
          i === 1
            ? this.$t('tenant_profile.tabs.co_tenants.cotenants_value_singular')
            : this.$t('tenant_profile.tabs.co_tenants.cotenants_value_plural', {
                number: i,
              });
        coTenantsOptions.push({ label: computedLabel, value: i });
      }
      return coTenantsOptions;
    },
  },
  watch: {
    tenantProfile() {
      if (this.tenantProfile) {
        this.initializeForm(this.tenantProfile);
      }
    },
  },
  beforeMount() {
    if (this.tenantProfile) {
      this.initializeForm(this.tenantProfile);
    }
  },
  methods: {
    initializeForm(initialData) {
      this.hasAPet = initialData.hasAPet;
      this.petType = initialData.petType;
      this.coTenantsAmount =
        typeof initialData.coTenantsAmount === 'number'
          ? initialData.coTenantsAmount
          : null;
    },
    submitForm(isValid) {
      this.validateEventTriggered = true;

      if (isValid) {
        this.$emit('submit', {
          hasAPet: this.hasAPet !== null ? this.hasAPet : undefined,
          petType: this.petType ? this.petType : '',
          coTenantsAmount:
            typeof this.coTenantsAmount === 'number' ? this.coTenantsAmount : null,
        });
      }
      this.trackTenantProfileEvent({
        eventName: this.getAnalyticEventNames.TENANT_PROFILE_EDIT_COTENANTS,
      });
    },
    closeTab() {
      this.$emit('close');
      this.initializeForm(this.tenantProfile);
    },
  },
};
</script>
