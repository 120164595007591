import { trim } from 'lodash';
import {
  profileSourceIndications,
  sessionFlowSteps,
  sessionFlowTypes,
} from '~/utils/constants.js';
import { sessionEventName } from '~/utils/trackerConstants';

const stateDefaults = {
  needToReauthenticate: false,
  formProfileData: {
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneCountryCodeIso: '',
    phoneNumber: '',
    acceptedTermsAndConditions: false,
  },
  showAgreementInformationInSignInModal: false,
  sessionFlowTypeInSignInModal: sessionFlowTypes.GENERIC,
  showSignInModal: false,
  currentAuthProvider: '',
  authProviderEmailLoginMethod: '',
  createOrUpdateAuthAndProfileInProgress: false,
  loginUserInProgress: false,
  overriddenSuccessfulRedirect: '',
  signInModalDisabledByOtherFlow: false,
  signupFlow: {
    errorCode: null,
  },
};

export const state = () => ({
  needToReauthenticate: stateDefaults.needToReauthenticate,
  formProfileData: {
    firstName: stateDefaults.formProfileData.firstName,
    lastName: stateDefaults.formProfileData.lastName,
    email: stateDefaults.formProfileData.email,
    phoneCountryCode: stateDefaults.formProfileData.phoneCountryCode,
    phoneCountryCodeIso: stateDefaults.formProfileData.phoneCountryCodeIso,
    phoneNumber: stateDefaults.formProfileData.phoneNumber,
    acceptedTermsAndConditions:
      stateDefaults.formProfileData.acceptedTermsAndConditions,
  },
  showAgreementInformationInSignInModal:
    stateDefaults.showAgreementInformationInSignInModal,
  sessionFlowTypeInSignInModal: stateDefaults.sessionFlowTypeInSignInModal,
  showSignInModal: stateDefaults.showSignInModal,
  currentAuthProvider: stateDefaults.currentAuthProvider,
  authProviderEmailLoginMethod: stateDefaults.authProviderEmailLoginMethod,
  createOrUpdateAuthAndProfileInProgress:
    stateDefaults.createOrUpdateAuthAndProfileInProgress,
  loginUserInProgress: stateDefaults.loginUserInProgress,
  overriddenSuccessfulRedirect: stateDefaults.overriddenSuccessfulRedirect,
  signInModalDisabledByOtherFlow: stateDefaults.signInModalDisabledByOtherFlow,
  signupFlow: {
    errorCode: stateDefaults.signupFlow.errorCode,
  },
});

export const getters = {
  needToReauthenticate: (state) => state.needToReauthenticate,
  formFirstName: (state) => state.formProfileData.firstName,
  formLastName: (state) => state.formProfileData.lastName,
  formEmail: (state) => state.formProfileData.email,
  formPhoneNumber: (state) => state.formProfileData.phoneNumber,
  formPhoneCountryCode: (state) => state.formProfileData.phoneCountryCode,
  formPhoneCountryCodeIso: (state) => state.formProfileData.phoneCountryCodeIso,
  formAcceptedTermsAndConditions: (state) =>
    state.formProfileData.acceptedTermsAndConditions,
  showAgreementInformationInSignInModal: (state) =>
    state.showAgreementInformationInSignInModal,
  sessionFlowTypeInSignInModal: (state) => state.sessionFlowTypeInSignInModal,
  showSignInModal: (state) => state.showSignInModal,
  currentStep: (state, getters, rootState, rootGetters) => {
    if (
      rootGetters['users/hasSessionUser'] &&
      rootGetters['users/profileHasBasicInformation'] &&
      rootGetters['users/hasAcceptedTermsAndConditions'] &&
      !getters.createOrUpdateAuthAndProfileInProgress
    ) {
      return sessionFlowSteps.COMPLETED;
    }

    const loginCompletedAndReauthenticationNotNeeded =
      !getters.loginUserInProgress &&
      rootGetters['users/hasSessionUser'] &&
      !state.needToReauthenticate;

    if (loginCompletedAndReauthenticationNotNeeded) {
      return sessionFlowSteps.FILL_PROFILE;
    }

    return sessionFlowSteps.LOGIN;
  },
  currentAuthProvider: (state) => state.currentAuthProvider,
  authProviderEmailLoginMethod: (state) => state.authProviderEmailLoginMethod,
  createOrUpdateAuthAndProfileInProgress: (state) =>
    state.createOrUpdateAuthAndProfileInProgress,
  loginUserInProgress: (state) => state.loginUserInProgress,
  overriddenSuccessfulRedirect: (state) => state.overriddenSuccessfulRedirect,
  signInModalDisabledByOtherFlow: (state) => state.signInModalDisabledByOtherFlow,
};

export const mutations = {
  SIGNUP_FLOW_SET_ERROR_CODE: function (state, errorCode) {
    state.signupFlow.errorCode = errorCode;
  },
  SET_NEED_TO_REAUTHENTICATE: function (state, needToReauthenticate) {
    state.needToReauthenticate = needToReauthenticate;
  },
  SET_FORM_FIRST_NAME: function (state, firstName) {
    state.formProfileData.firstName = firstName;
  },
  SET_FORM_LAST_NAME: function (state, lastName) {
    state.formProfileData.lastName = lastName;
  },
  SET_FORM_EMAIL: function (state, email) {
    state.formProfileData.email = email;
  },
  SET_FORM_PHONE_NUMBER_DATA: function (
    state,
    { phoneNumber, phoneCountryCode, phoneCountryCodeIso },
  ) {
    state.formProfileData.phoneCountryCode = phoneCountryCode;
    state.formProfileData.phoneCountryCodeIso = phoneCountryCodeIso;
    state.formProfileData.phoneNumber = phoneNumber;
  },
  SET_FORM_ACCEPTED_TERMS_AND_CONDITIONS: function (state, acceptedTermsAndConditions) {
    state.formProfileData.acceptedTermsAndConditions = acceptedTermsAndConditions;
  },
  SET_SIGN_IN_MODAL(
    state,
    { isSignInModalOpen, isAgreementFlowNeeded, sessionFlowType },
  ) {
    const nuxtApp = useNuxtApp();
    if (
      state.showSignInModal !== isSignInModalOpen ||
      state.showAgreementInformationInSignInModal !== isAgreementFlowNeeded ||
      state.sessionFlowTypeInSignInModal !== sessionFlowType
    ) {
      state.showAgreementInformationInSignInModal = isAgreementFlowNeeded;
      state.sessionFlowTypeInSignInModal =
        sessionFlowType ?? stateDefaults.sessionFlowTypeInSignInModal;

      state.showSignInModal = isSignInModalOpen;
      if (isSignInModalOpen) {
        nuxtApp.$trackEvent(
          sessionEventName.OPEN_MODAL,
          { sessionFLow: profileSourceIndications.WEB },
          nuxtApp.$store.getters['tracker/getDefaultTrackerProviderOptions'],
        );
      }
    }
  },
  SET_CURRENT_AUTH_PROVIDER(state, currentAuthProvider) {
    const nuxtApp = useNuxtApp();

    state.currentAuthProvider = currentAuthProvider;
    if (currentAuthProvider) {
      nuxtApp.$trackEvent(
        sessionEventName.AUTH_PROVIDER,
        state.formProfileData.email
          ? {
              auth_provider: currentAuthProvider,
              email: state.formProfileData.email,
            }
          : {
              auth_provider: currentAuthProvider,
            },
        nuxtApp.$store.getters['tracker/getDefaultTrackerProviderOptions'],
      );
    }
  },
  SET_AUTH_PROVIDER_EMAIL_LOGIN_METHOD(state, status) {
    state.authProviderEmailLoginMethod = status;
  },
  SET_AUTH_IN_PROGRESS(state, status) {
    state.createOrUpdateAuthAndProfileInProgress = status;
  },
  SET_LOGIN_IN_PROGRESS(state, status) {
    state.loginUserInProgress = status;
  },
  SET_OVERRIDDEN_SUCCESSFUL_REDIRECT(state, redirect) {
    state.overriddenSuccessfulRedirect = redirect;
  },
  RESET_OVERRIDDEN_SUCCESSFUL_REDIRECT(state) {
    state.overriddenSuccessfulRedirect = stateDefaults.overriddenSuccessfulRedirect;
  },
  SET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW(state) {
    state.signInModalDisabledByOtherFlow = true;
  },
  RESET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW(state) {
    state.signInModalDisabledByOtherFlow = stateDefaults.signInModalDisabledByOtherFlow;
  },
};

export const actions = {
  /**
   * Generic function in sign up flow - trigger when you want to create or update a profile
   * When auth is not defined, we assume that email login was used and we need to create both auth and profile
   * If user used Apple Anonyomous Email, we'll also update email in auth, if profile has not yet been created
   * @param {string} email - email that will be used to create the account or new email for Apple Anonymous users.
   * @param {string} id - Additional profile data to create or update user profile from.
   * @param {Profile} profileData - Additional profile data to create or update user profile from.
   * @param {ProfileSourceIndication} indicatedProfileSource - indication of the flow where the profile is created from.
   * @param {string} profileInitialType - User Role, which should be set if creating a new profile
   * */
  createOrUpdateAuthAndProfile: function (
    { dispatch, commit, getters, rootGetters },
    { email, indicatedProfileSource, profileInitialType },
  ) {
    commit('SET_AUTH_IN_PROGRESS', true);
    return (
      Promise.resolve()
        /*       .then(() =>
              Promise.reject(
                new Error('auth/requires-recent-login', {
                  code: 'auth/requires-recent-login',
                })
              )
            ) */
        .then(() => {
          return rootGetters['users/hasSessionUser']
            ? Promise.resolve()
            : dispatch(
                'users/_signUpWithEmailAndGeneratedPassword',
                { setEmail: email },
                { root: true },
              );
        })
        .then(() => {
          return rootGetters['users/isAppleAnonymousUser']
            ? dispatch('users/updateEmail', { email: email }, { root: true })
            : Promise.resolve();
        })
        .then(() => {
          const profileData = {
            firstName: trim(getters.formFirstName),
            lastName: trim(getters.formLastName),
            email: trim(email),
            phoneCountryCode: getters.formPhoneCountryCode,
            phoneCountryCodeIso: getters.formPhoneCountryCodeIso,
            phoneNumber: getters.formPhoneNumber,
            acceptedTermsAndConditions: getters.formAcceptedTermsAndConditions,
            profileInitialType: profileInitialType,
          };
          return rootGetters['users/profile']
            ? dispatch(
                'profiles/putProfile',
                { profile: profileData, id: rootGetters['users/profile'].id },
                { root: true },
              )
            : dispatch(
                'users/postProfile',
                {
                  email: email,
                  profileData: profileData,
                  functionToCall: 'postProfile',
                  indicatedProfileSource: indicatedProfileSource,
                },
                { root: true },
              );
        })
        .catch((err) => {
          if (err.code === 'auth/requires-recent-login') {
            commit('SET_NEED_TO_REAUTHENTICATE', true);
          }
          /* We let original caller decide how they'll clean up based on the error */
          throw err;
        })
        .finally(() => {
          commit('SET_AUTH_IN_PROGRESS', false);
        })
    );
  },
  _splitNameToFirstAndLast({ commit }, name = '') {
    const nameTrimmed = typeof name === 'string' ? name.trim() : '';
    let firstNameToReturn = '';
    let lastNameToReturn = '';
    if (nameTrimmed.length > 0) {
      const singleSpace = ' ';
      const names = nameTrimmed.split(singleSpace).filter((s) => s.trim().length > 0);
      const onlyOneNameWasPassed = names.length === 1;
      firstNameToReturn = onlyOneNameWasPassed
        ? names[0]
        : names.slice(0, -1).join(singleSpace);
      lastNameToReturn = onlyOneNameWasPassed ? '' : names[names.length - 1];
    }
    return Promise.resolve({
      firstName: firstNameToReturn,
      lastName: lastNameToReturn,
    });
  },
  async prefillFromFirebaseSessionAndProfile({
    commit,
    dispatch,
    rootGetters,
    getters,
  }) {
    const profile = rootGetters['users/profile'];
    const sessionProfile = rootGetters['users/sessionUser'];

    const parsedNamesFromAuth = await dispatch(
      '_splitNameToFirstAndLast',
      sessionProfile.displayName,
    );

    if (!getters.formEmail) {
      commit(
        'SET_FORM_EMAIL',
        profile && profile.email ? profile.email : sessionProfile.email,
      );
    }

    if (!getters.formFirstName) {
      commit(
        'SET_FORM_FIRST_NAME',
        profile && profile.firstName
          ? profile.firstName
          : parsedNamesFromAuth.firstName,
      );
    }
    if (!getters.formLastName) {
      commit(
        'SET_FORM_LAST_NAME',
        profile && profile.lastName ? profile.lastName : parsedNamesFromAuth.lastName,
      );
    }
    if (!getters.formPhoneNumber) {
      commit('SET_FORM_PHONE_NUMBER_DATA', {
        phoneNumber: profile?.phoneNumber ?? stateDefaults.formProfileData.phoneNumber,
        phoneCountryCode:
          profile?.phoneCountryCode ?? stateDefaults.formProfileData.phoneCountryCode,
        phoneCountryCodeIso:
          profile?.phoneCountryCodeIso ??
          stateDefaults.formProfileData.phoneCountryCodeIso,
      });
    }

    if (rootGetters['users/hasAcceptedTermsAndConditions']) {
      commit('SET_FORM_ACCEPTED_TERMS_AND_CONDITIONS', true);
    }
  },
  reset: async function ({ commit, dispatch }) {
    commit('SET_FORM_FIRST_NAME', stateDefaults.formProfileData.firstName);
    commit('SET_FORM_LAST_NAME', stateDefaults.formProfileData.lastName);
    commit('SET_FORM_EMAIL', stateDefaults.formProfileData.email);
    commit('SET_FORM_PHONE_NUMBER_DATA', {
      phoneNumber: stateDefaults.formProfileData.phoneNumber,
      phoneCountryCode: stateDefaults.formProfileData.phoneCountryCode,
      phoneCountryCodeIso: stateDefaults.formProfileData.phoneCountryCodeIso,
    });
    commit(
      'SET_FORM_ACCEPTED_TERMS_AND_CONDITIONS',
      stateDefaults.formProfileData.acceptedTermsAndConditions,
    );
    commit('SET_NEED_TO_REAUTHENTICATE', stateDefaults.needToReauthenticate);
    commit('SET_LOGIN_IN_PROGRESS', stateDefaults.loginUserInProgress);
    commit(
      'SET_AUTH_IN_PROGRESS',
      stateDefaults.createOrUpdateAuthAndProfileInProgress,
    );
    commit('SET_CURRENT_AUTH_PROVIDER', stateDefaults.currentAuthProvider);
    commit('RESET_OVERRIDDEN_SUCCESSFUL_REDIRECT', stateDefaults.currentAuthProvider);
    await dispatch('prefillFromFirebaseSessionAndProfile');
    return Promise.resolve();
  },
};
