<template>
  <section class="pt-10 pb-0 sm:pt-24 sm:pb-16">
    <div class="mx-auto max-w-6xl">
      <div class="flex flex-col items-center content-center justify-center text-lg">
        <svg
          v-scroll-to="'#profile-read-more'"
          class="w-14 h-14 text-gray-300 mt-2 hover:text-gray-600 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionReadmoreSpace',
};
</script>
