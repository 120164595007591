import { get } from 'lodash';

/**
 * @typedef {string} ProfileId
 */

/**
 * @typedef {'WEB'|'WEB_DIGIBROKER'|'WEB_ADVERTISEMENT_INVITE'|'WEB_BY_OTHER'} ProfileSourceTypeDef
 */

/**
 * @typedef {'BUSINESS'|'PERSONAL'} ProfileType
 */

/**
 * @typedef {{
 *   email?: string,
 *   firstName?: string,
 *   lastName?: string,

 *   personalIdCode?: string,
 *   personalIdCodeCountryCode?: string,
 *
 *   phoneNumber?: string,
 *   phoneCountryCodeIso?: string,
 *   phoneCountryCode?: string,
 *
 *   type?: ProfileType,
 *   companyName?: string,
 *   companyNumber?: string,
 *   companyTaxNumber?: string,
 *   address?: string,
 *   city?: string,
 *   postalCode?: string,
 *   country?: string,
 *   language?: string,
 *   workflow?: string,
 * }} ProfileSubmit
 */

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestPutProfile = 'putProfile';
const requestPostProfile = 'postProfile';

const requests = [requestPutProfile, requestPostProfile];

// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = { ...setupMutations(requests) };

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @param {ProfileSubmit} profile
   * @param {ProfileId} id
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  putProfile: function ({ dispatch }, { profile, id }) {
    return Promise.resolve()
      .then(() =>
        dispatch('firebaseRequest', {
          request: requestPutProfile,
          data: { profile: { ...profile, id: id } },
        }),
      )
      .then((resultOfPutProfile) =>
        Promise.allSettled([
          resultOfPutProfile,
          dispatch('users/fetchProfile', undefined, { root: true }),
        ]),
      )
      .then(([resultOfPutProfile, _]) => resultOfPutProfile.value);
  },

  /**
   * @param {ProfileSubmit} profileData
   * @param {ProfileSourceTypeDef} indicatedProfileSource
   * @return {Promise<{
   *   response: String,
   *   failed: boolean,
   * }>}
   */
  postProfile: function (
    { dispatch, rootGetters },
    { profileData, indicatedProfileSource },
  ) {
    // historical server side mapping typo
    // TODO manipulate backend to fix server
    if (profileData && profileData.addressFirstLine) {
      Object.defineProperty(
        profileData,
        'addressFirstline',
        Object.getOwnPropertyDescriptor(profileData, 'addressFirstLine'),
      );
      delete profileData.addressFirstLine;
    }

    return Promise.resolve().then(() =>
      dispatch('firebaseRequest', {
        request: requestPostProfile,
        data: {
          ...profileData,
          workflow: profileData.workflow
            ? profileData.workflow
            : rootGetters.getCountry.toUpperCase(),
          indicatedProfileSource: indicatedProfileSource,
        },
      }),
    );
  },

  /**
   * @param {string} email
   * @return {Promise<{
   *   isTenant?: boolean,
   *   isLandlord?: boolean,
   *   isExistingProfile?: boolean,
   * }>}
   */
  checkUserProfile: function ({ dispatch }, { email }) {
    const requestData =
      typeof email === 'string'
        ? { searchString: email.trim().toLowerCase() }
        : undefined;
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'getUserProfileByEmailLimited', data: requestData },
          { root: true },
        ),
      )
      .then((response) =>
        // We map undefined to allowed non-defined type.
        get(response, 'data.profileInitialType', ''),
      )
      .then((profileInitialTypeFromDatabase) => {
        // In any case, if we do not error from the response, we are an existing account.
        const profileInitialType =
          typeof profileInitialTypeFromDatabase !== 'string'
            ? ''
            : profileInitialTypeFromDatabase.trim().toUpperCase();
        const isTenant = profileInitialType === 'TENANT';
        const isLandlord =
          profileInitialType === 'LANDLORD' || profileInitialType === 'BROKER';
        return {
          isTenant: isTenant,
          isLandlord: isLandlord,
          isExistingProfile: isTenant || isLandlord || profileInitialType === '',
        };
      })
      .catch((err) => {
        // In case the user is not found.
        const failedResponse = {
          isTenant: undefined,
          isLandlord: undefined,
        };
        // User not found.
        if (err && err.code === 'functions/not-found') {
          return {
            ...failedResponse,
            isExistingProfile: false,
          };
        } else {
          // In any case, we return because we do not know.
          dispatch('tracker/reportErrorToSentry', err, { root: true });
          return {
            ...failedResponse,
            isExistingProfile: undefined,
          };
        }
      });
  },

  /**
   * @param profileId {string|undefined}
   * @param searchString {string|undefined}
   */
  _getProfileInfoForOther: function ({ dispatch }, { profileId, searchString } = {}) {
    const dataProfileId = typeof profileId === 'string' ? { profileId: profileId } : {};
    const dataSearchString =
      typeof searchString === 'string' ? { searchString: searchString } : {};

    return Promise.resolve().then(() =>
      dispatch(
        'firebase/functionCallRegionEU',
        {
          functionName: 'getProfileInfoWeb',
          data: {
            ...dataProfileId,
            ...dataSearchString,
          },
        },
        { root: true },
      ),
    );
  },

  /**
   * @param emailOrPersonalIdOrPhoneNr {string}
   */
  getProfileInfoSearch: function ({ dispatch }, emailOrPersonalIdOrPhoneNr) {
    if (
      typeof emailOrPersonalIdOrPhoneNr !== 'string' ||
      emailOrPersonalIdOrPhoneNr.trim().length === 0
    ) {
      return new Error(
        'invalid argument - expecting emailOrPersonalIdOrPhoneNr to be defined',
      );
    } else {
      return dispatch('_getProfileInfoForOther', {
        searchString: emailOrPersonalIdOrPhoneNr,
      }).then((response) => response.data);
    }
  },
};
