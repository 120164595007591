import VueScrollTo from 'vue-scrollto';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueScrollTo, {
    duration: 300,
    offset: -70,
  });

  return {
    provide: {
      scrollTo: VueScrollTo.scrollTo,
    },
  };
});
