import { useStore } from 'vuex';

export default defineNuxtRouteMiddleware((to) => {
  const store = useStore(); // Access the Vuex store
  const hasSessionUser = store.getters['users/hasSessionUser'];
  const isDashboardPath = to.path.includes('/dashboard');
  const redirectUrl = to.path;

  if (!hasSessionUser && isDashboardPath) {
    return navigateTo({
      path: '/',
      query: {
        login: 'open',
        redirectUrl: redirectUrl,
      },
    });
  }
});
