<template>
  <div
    :class="[
      {
        'md:grid md:grid-cols-2': feedbackCards.length === 2 && Number(maxColumns) >= 2,
      },
      {
        'lg:grid lg:grid-cols-3':
          feedbackCards.length <= 4 &&
          feedbackCards.length >= 3 &&
          Number(maxColumns) >= 3,
      },
      {
        'xl:grid xl:grid-cols-4':
          feedbackCards.length <= 6 &&
          feedbackCards.length >= 4 &&
          Number(maxColumns) >= 4,
      },
      {
        'columns-1 sm:columns-2 gap-x-0':
          feedbackCards.length >= 3 && Number(maxColumns) >= 2,
      },
      {
        'columns-1 md:columns-3 gap-x-0':
          feedbackCards.length >= 5 && Number(maxColumns) >= 3,
      },
      {
        'columns-1 lg:columns-4 gap-x-0':
          feedbackCards.length >= 7 && Number(maxColumns) >= 4,
      },
    ]"
  >
    <r-feedback-card
      v-for="feedbackCard in feedbackCards"
      :key="feedbackCard._uid"
      class="m-2"
      :content="feedbackCard.content"
      :feedback-parent="feedbackCard.from"
      :image="feedbackCard.image"
      :link="feedbackCard.feedbackLink"
      :name="feedbackCard.name"
      :position="feedbackCard.position"
      :size="cardsSize"
      :title="feedbackCard.title"
    />
  </div>
</template>

<script>
import { ComponentSize } from '~/utils/constants';

export default {
  name: 'FeedbackCards',

  props: {
    feedbackCards: {
      type: [Array],
      default: () => [],
    },
    maxColumns: {
      type: String,
      default: '4',
    },
    cardsSize: {
      type: String,
      default: ComponentSize.MEDIUM,
    },
  },
};
</script>
