<template>
  <div class="applied-home-detail-view bg-white p-4 rounded-lg shadow-md flex flex-col">
    <div class="py-2">
      <div class="mb-4 flex justify-center">
        <r-icon class="text-green-600 text-3xl" icon-name="check-circle" />
      </div>
      <div class="lg:px-4 flex flex-col gap-3 mb-6">
        <h2 class="text-xl font-semibold text-center">
          <span v-if="isAgreementInvite">{{
            $t('advertisement_invite.applied_home_view.title_agreement_invite')
          }}</span>
          <span v-else>{{ $t('advertisement_invite.applied_home_view.title') }}</span>
        </h2>

        <div v-if="isAgreementInvite">
          {{ $t('advertisement_invite.applied_home_view.content_agreement_invite') }}
        </div>
        <div v-else>
          <p class="mb-4">
            {{ $t('advertisement_invite.applied_home_view.content_first_time_2') }}
          </p>
          <p class="my-4">
            {{ $t('advertisement_invite.applied_home_view.content_first_time') }}
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <r-profile-info-card
          v-if="ownerVisible"
          class="bg-peach-100 rounded-lg"
          :email="ownerCardEmail"
          :email-label="$t('agreement.card.label.email')"
          :full-name="ownerCardFullName"
          :full-name-label="$t('agreement.card.label.name')"
          :phone-number="ownerCardFullPhoneNumber"
          :phone-number-label="$t('agreement.card.label.phone')"
          :title="ownerCardTitle"
          title-class="text-sm"
        />
        <r-profile-info-card
          class="bg-peach-100 rounded-lg"
          :email="profileCardEmail"
          :email-label="$t('agreement.card.label.email')"
          :full-name="profileCardFullName"
          :full-name-label="$t('agreement.card.label.name')"
          :is-loading-needed="profileCardRedButtonIsLoading"
          :phone-number="profileCardPhoneNumber"
          :phone-number-label="$t('agreement.card.label.phone')"
          :title="profileCardTitle"
          title-class="text-sm"
        >
          <slot name="tenant"></slot>
        </r-profile-info-card>

        <r-button
          color="red"
          :is-loading="profileCardRedButtonIsLoading"
          :label="profileCardRedButtonText"
          size="small"
          width="full"
          @click="
            profileCardRedButtonIsLoading ? null : profileCardRedButtonClickEvent()
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppliedHomeDetailView',

  props: {
    ownerCardTitle: {
      type: String,
      default: null,
    },
    ownerCardFullName: {
      type: String,
      default: null,
    },
    ownerCardEmail: {
      type: String,
      default: null,
    },
    ownerCardFullPhoneNumber: {
      type: String,
      default: null,
    },
    profileCardTitle: {
      type: String,
      default: null,
    },
    profileCardFullName: {
      type: String,
      default: null,
    },
    profileCardPhoneNumber: {
      type: String,
      default: null,
    },
    profileCardEmail: {
      type: String,
      default: null,
    },
    profileCardRedButtonText: {
      type: String,
      default: null,
    },
    profileCardRedButtonIsLoading: {
      type: Boolean,
      default: false,
    },
    ownerVisible: {
      type: [Boolean, Object],
      default: true,
    },
    isAgreementInvite: {
      type: Boolean,
      default: false,
    },
    firstTimeSignUp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['profileCardRedButtonClicked'],

  methods: {
    profileCardRedButtonClickEvent(event) {
      this.$emit('profileCardRedButtonClicked', event);
    },
  },
};
</script>
