<template>
  <div v-editable="blok">
    <div class="flex w-full justify-center" :class="blok.cover ? 'flex-1' : ''">
      <img
        :alt="blok.image.alt"
        :class="classes"
        :src="$transformStoryblokImage(blok.image.filename, '/m/fit-in/1800x0/')"
      />
    </div>
    <div v-if="blok?.image?.name" class="text-gray-600 text-sm my-2">
      {{ blok.image.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.blok.cover) {
        /* TODO - move back to native classes when Tailwind is updated and 'not-prose' class starts working */
        classes.push('img-cover');
      } else {
        classes.push('max-h-64 md:max-h-full');
      }

      switch (this.blok.aspectRatio) {
        case 'square':
          classes.push('aspect-square');
          break;
        case 'widescreen':
          classes.push('aspect-widescreen');
          break;
        case 'screen':
          classes.push('aspect-screen');
          break;
        case 'vertical-screen':
          classes.push('aspect-vertical-screen');
          break;
      }

      switch (this.blok.mobileAspectRatio) {
        case 'square':
          classes.push('m-aspect-square');
          break;
        case 'widescreen':
          classes.push('m-aspect-widescreen');
          break;
        case 'screen':
          classes.push('m-aspect-screen');
          break;
        case 'vertical-screen':
          classes.push('m-aspect-vertical-screen');
          break;
      }
      switch (this.blok.border) {
        case 'rounded':
          classes.push('rounded-xl');
          break;
      }

      return classes;
    },
  },
};
</script>
<style scoped>
img.img-cover {
  @apply my-0 object-cover w-full;
}

.m-aspect-square {
  aspect-ratio: 1;
}

.m-aspect-widescreen {
  aspect-ratio: 16/9;
}

.m-aspect-screen {
  aspect-ratio: 4/3;
}

.m-aspect-vertical-screen {
  aspect-ratio: 3/4;
}

@screen sm {
  .aspect-square {
    aspect-ratio: 1;
  }

  .aspect-widescreen {
    aspect-ratio: 16/9;
  }

  .aspect-screen {
    aspect-ratio: 4/3;
  }

  .aspect-vertical-screen {
    aspect-ratio: 3/4;
  }
}
</style>
