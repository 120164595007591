<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="flex flex-col text-center h-full px-4 sm:px-8 pt-10 pb-4">
      <h3 class="font-medium text-lg mb-4">
        {{ $t('agreement.annexing.cancel_renewal.title') }}
      </h3>
      <div class="whitespace-pre-line">
        {{ $t('agreement.annexing.cancel_renewal.content') }}
      </div>
    </div>
    <template #bottom-bar>
      <div class="p-4 sm:px-12 flex gap-2 flex-col">
        <r-button
          display="block"
          :label="$t('buttons.got_it')"
          size="small"
          @click="closeOverlay"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnnexCancelModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
});
</script>
