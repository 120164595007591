import type { Props } from 'tippy.js';
import TippyJs from 'tippy.js';

export default defineNuxtPlugin(() => {
  const transformStoryblokImage = (imageURL: string, options: string) => {
    if (!imageURL) return '';
    if (!options) return '';
    if (imageURL.includes('.svg')) return imageURL;

    const path = imageURL.replace('//img2.storyblok.com', '//a.storyblok.com');

    return path + options;
  };

  const tippy = (target: HTMLElement, options: Props) => {
    return TippyJs(target, options);
  };

  return {
    provide: {
      transformStoryblokImage,
      tippy,
    },
  };
});
