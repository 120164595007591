<template>
  <div>
    <!-- ad intro -->
    <div class="mx-auto text-center px-4 my-6">
      <component
        :is="childBlok.component"
        v-for="(childBlok, index) in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component)
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
        :class="['r-fade--delay--' + index, initialLoad ? 'r-fade-bottom' : '']"
      />
    </div>

    <!-- Tabs for select if property is house or apartment-->
    <div>
      <div
        class="r-fade--delay--1 my-6 md:my-12 max-w-xl mx-auto grid grid-cols-2 gap-4 md:gap-8 px-4 pb-8"
        :class="{ 'r-fade-bottom': initialLoad }"
      >
        <div
          v-for="tab in tabs"
          :id="tab.tabId"
          :key="tab._uid"
          class="digibroker__tab bg-white text-center shadow-lg rounded-xl p-4 border border-white hover:border-rendin-500 focus:border-rendin-500"
          :class="{
            'digibroker__tab--active': propertyType === tab.propertyType.toUpperCase(),
          }"
          @click="$emit('selectPropertyType', tab.propertyType.toUpperCase())"
        >
          <r-icon
            v-if="tab.propertyType.toUpperCase() === adPropertyTypes.HOUSE"
            class="text-2xl sm:text-8xl"
            icon-name="house-day"
          />
          <r-icon
            v-else-if="tab.propertyType.toUpperCase() === adPropertyTypes.APARTMENT"
            class="text-2xl sm:text-8xl"
            icon-name="building"
          />
          <r-icon
            v-else-if="tab.propertyType.toUpperCase() === adPropertyTypes.ROOM"
            class="text-2xl sm:text-8xl"
            icon-name="door-open"
          />
          <h4 class="pt-4 sm:text-xl font-medium">
            {{ tab.title }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { adPropertyTypes } from "~/utils/constants";

export default {
  name: 'StepSelectTypeOfProperty',
  props: {
    introText: {
      type: Array,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    propertyType: {
      type: String,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    adPropertyTypes() {
      return adPropertyTypes;
    }
  },
};
</script>
