<template>
  <div>
    <div class="divide-y divide-gray-50 space-y-4">
      <template v-if="hasSessionUser">
        <div>
          <p class="uppercase text-xs text-gray-400 py-2 font-medium">
            {{ $t('navigation.use_platform_as') }}
          </p>
          <div
            class="flex bg-gray-50 rounded-full py-2 relative mt-4 cursor-pointer"
            @click="toggleUserRole"
          >
            <div
              class="absolute top-0 w-1/2 h-full bg-white shadow-sm rounded-full transition-all"
              :class="userRole === userRoles.TENANT ? 'pill-right' : 'left-0'"
            ></div>
            <p
              class="w-1/2 text-center z-10 transition-colors"
              :class="{
                'text-rendin-500 font-medium': userRole === userRoles.LANDLORD,
              }"
            >
              {{ $t('landlord') }}
            </p>
            <p
              class="w-1/2 text-center z-10 transition-colors"
              :class="{ 'text-rendin-500 font-medium': userRole === userRoles.TENANT }"
            >
              {{ $t('tenant') }}
            </p>
          </div>
          <div class="py-2">
            <template v-if="userRole === userRoles.TENANT">
              <navigation-menu-section
                :menu-items="getGlobalBloks.DashboardNavigation.tenantDashboard"
                mobile
                @menu-item-click="$emit('menuItemClick')"
              />
            </template>
            <template v-else-if="userRole === userRoles.LANDLORD">
              <navigation-menu-section
                :menu-items="getGlobalBloks.DashboardNavigation.landlordDashboard"
                mobile
                @menu-item-click="$emit('menuItemClick')"
              />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!hasSessionUser">
          <div class="flex justify-between items-center">
            <!--   Login button     -->
            <button
              class="flex items-center gap-2 text-rendin-500 cursor-pointer py-4"
              data-testid="button.navigation.sign-in"
              @click="openSignInModal"
            >
              <r-icon icon-name="user" />
              <span class="font-normal text-center">
                {{ getGlobalBloks.HeaderNavigation.sign_in_button_text }}
              </span>
            </button>
            <!--      Language Selector      -->
            <div
              class="flex items-center gap-1 cursor-pointer"
              @click="showLanguageSelectorModel = true"
            >
              <r-flag class="shadow-sm" :country="$store.state.country" rounded-full />
              <span
                class="uppercase font-normal text-gray-600 text-center"
                :class="{ 'text-gray-900 ': showLanguageSelectorModel }"
              >
                {{ locale }}
              </span>
            </div>
          </div>
        </template>
      </template>

      <div>
        <p class="uppercase text-xs text-gray-400 py-2 font-medium">
          {{ $t('navigation.rendin_name') }}
        </p>
        <navigation-menu-section
          :menu-items="getGlobalBloks.NavigationPrimaryBar.items"
          mobile
          @menu-item-click="$emit('menuItemClick')"
        />
      </div>
      <template v-if="hasSessionUser">
        <div class="flex justify-between items-center">
          <button
            class="flex gap-2 items-center cursor-pointer hover:text-rendin-500 text-gray-600"
            @click="logOut"
          >
            <span class="text-base">{{
              getGlobalBloks.HeaderNavigation.sign_out_button_text
            }}</span>
            <r-icon icon-name="arrow-right-from-bracket" />
          </button>
          <!--      Language Selector      -->
          <div
            class="flex items-center gap-1 cursor-pointer"
            @click="showLanguageSelectorModel = true"
          >
            <r-flag class="shadow-sm" :country="$store.state.country" rounded-full />
            <span
              class="uppercase font-normal text-gray-600 text-center"
              :class="{ 'text-gray-900 ': showLanguageSelectorModel }"
            >
              {{ locale }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="showLanguageSelectorModel"
      class="absolute top-0 inset-x-0 transition transform origin-top-right bg-white w-full overflow-y-auto z-40 bottom-0"
    >
      <LanguageSelector class="p-4" @close="showLanguageSelectorModel = false" />
    </div>
    <div
      v-if="showActivityHub"
      class="absolute top-0 inset-x-0 transition transform origin-top-right bg-white w-full overflow-y-auto z-40 bottom-0"
    >
      <ActivityHub @close="$emit('closeActivityHub')" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LanguageSelector from '~/components/section/LanguageSelector.vue';
import { sessionFlowTypes, userRole } from '~/utils/constants';
import ActivityHub from '~/components/snippets/ActivityHub.vue';

export default {
  name: 'NavMobileFull',
  components: { ActivityHub, LanguageSelector },
  props: {
    showLanguageSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    showActivityHub: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'menuItemClick',
    'closeActivityHub',
    'update:showLanguageSelector',
    'selectedUserRole',
  ],
  data() {
    return {
      userRoles: userRole,
      userRoleSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      getGlobalBloks: 'getGlobalBloks',
      locale: 'getLocale',
      userRoleFromProfile: 'users/userRole',
      hasSessionUser: 'users/hasSessionUser',
    }),
    userRole() {
      return this.userRoleSelected || this.userRoleFromProfile;
    },
    showLanguageSelectorModel: {
      get() {
        return this.showLanguageSelector;
      },
      set(newVal) {
        this.$emit('update:showLanguageSelector', newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      setUserRole: 'users/setUserRole',
      actionLogOut: 'users/logout',
      trackRolePickerSwitchRole: 'tracker/trackRolePickerSwitchRole',
    }),
    ...mapMutations({
      setSignInModal: 'session/SET_SIGN_IN_MODAL',
    }),
    openSignInModal() {
      this.$emit('menuItemClick');
      this.setSignInModal({
        isSignInModalOpen: true,
        isAgreementFlowNeeded: false,
        sessionFlowType: sessionFlowTypes.SIGN_IN,
      });
    },
    toggleUserRole() {
      if (this.userRole === userRole.LANDLORD) {
        this.userRoleSelected = userRole.TENANT;
        this.setUserRole(userRole.TENANT);
        this.trackRolePickerSwitchRole({ newRole: userRole.TENANT });
      } else {
        this.userRoleSelected = userRole.LANDLORD;
        this.setUserRole(userRole.LANDLORD);
        this.trackRolePickerSwitchRole({ newRole: userRole.LANDLORD });
      }
      this.$emit('selectedUserRole', this.userRole);
      this.$router.push({ path: this.$localizedPath('/dashboard') });
    },
    logOut() {
      this.$emit('menuItemClick');

      this.$router.push({ path: this.$localizedPath('/') });
      this.actionLogOut();
    },
  },
};
</script>
<style scoped>
.pill-right {
  left: 50%;
}
</style>
