<template>
  <div>
    <ul class="mt-2 space-x-6 flex" :class="classValue">
      <li>
        <a
          ref="appButton"
          class="block"
          data-event="Click App Store"
          :data-location="blok.button_location ? blok.button_location : 'AppButtonBlok'"
          data-os="apple"
          :href="blok.ios_link ? blok.ios_link : ios_link"
          target="_blank"
          @click="buttonClick($event)"
        >
          <img
            alt="Get it on Apple App Store"
            :src="
              blok.ios_image && blok.ios_image.filename
                ? blok.ios_image.filename
                : ios_image
            "
          />
        </a>
      </li>
      <li>
        <a
          ref="appButton"
          data-event="Click App Store"
          :data-location="blok.button_location ? blok.button_location : 'AppButtonBlok'"
          data-os="google"
          :href="blok.google_link ? blok.google_link : google_link"
          target="_blank"
          @click="buttonClick($event)"
        >
          <img
            alt="Get it on Google Play"
            :src="
              blok.google_image && blok.google_image.filename
                ? blok.google_image.filename
                : google_image
            "
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppButtonBlok',
  props: {
    blok: {
      type: Object,
    },
  },

  data() {
    return {
      ios_image: 'https://a.storyblok.com/f/103362/x/14bd771c2e/app-store-dark.svg',
      ios_link: 'https://onelink.to/rendin',
      google_image:
        'https://a.storyblok.com/f/103362/x/c0f471fc3a/google-play-dark.svg',
      google_link: 'https://onelink.to/rendin',
      classValue: this.blok.class ? this.blok.class : 'justify-center md:justify-start',
    };
  },

  methods: {
    buttonClick(event) {
      this.$trackEvent(
        event.srcElement.parentElement.dataset.event,
        event.srcElement.parentElement.dataset,
        {
          services: ['POSTHOG', 'GTAG', 'FB'],
        },
      );
    },
  },
};
</script>
