<template>
  <div class="px-4">
    <div class="mx-auto text-center my-6" :class="{ 'r-fade-bottom': initialLoad }">
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
    </div>

    <div class="r-fade--delay--1" :class="{ 'r-fade-bottom': initialLoad }">
      <div class="grid md:grid-cols-3 md:gap-4 items-start">
        <r-checkbox id="hasParking" v-model="hasParkingModel" name="parking">
          {{ $t('form.label.hasParking') }}
        </r-checkbox>
        <r-checkbox id="hasStorage" v-model="hasStorageModel" name="hasStorage">
          {{ $t('form.label.hasStorage') }}
        </r-checkbox>
        <div>
          <r-checkbox id="petsAllowed" v-model="petsAllowedModel" name="petsAllowed">
            {{ $t('form.label.petsAllowed') }}
          </r-checkbox>
          <span class="text-xs sm:text-base text-gray-500">
            {{ $t('form.label.petsAllowedDescription') }}
          </span>
        </div>
      </div>
      <div class="h-14 mt-2 mb-4">
        <div
          class="flex justify-center items-center r-fade--delay--2"
          :class="{ 'r-fade': initialLoad }"
        >
          <r-button
            :id="submitButtonId"
            color="orange"
            :is-loading="requestInProgress"
            @click="!requestInProgress ? $emit('goToNextStep') : null"
          >
            <r-icon class="mr-1 text-lg" icon-name="check-circle" />
            <span class="font-medium">{{ nextButtonLabel }}</span>
          </r-button>
          <div class="flex items-center text-sm mx-2">
            <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
            <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepSelectAdditionalDetails',
  props: {
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasStorage: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasParking: {
      type: Boolean,
      required: false,
      default: false,
    },
    petsAllowed: {
      type: Boolean,
      required: false,
      default: false,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'goToNextStep',
    'update:hasParking',
    'update:petsAllowed',
    'update:hasStorage',
  ],
  async setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
    };
  },
  computed: {
    hasParkingModel: {
      get() {
        return this.hasParking;
      },
      set(newVal) {
        this.$emit('update:hasParking', newVal);
      },
    },
    hasStorageModel: {
      get() {
        return this.hasStorage;
      },
      set(newVal) {
        this.$emit('update:hasStorage', newVal);
      },
    },
    petsAllowedModel: {
      get() {
        return this.petsAllowed;
      },
      set(newVal) {
        this.$emit('update:petsAllowed', newVal);
      },
    },
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    async submit() {
      const isValid = await this.validate();
      if (isValid && !this.requestInProgress) {
        this.$emit('goToNextStep');
      }
    },
    async validate() {
      /* No validation in this step */
      return true;
    },
  },
};
</script>
