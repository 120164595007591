<template>
  <div class="step-text-container">
    <div class="flex items-center justify-center p-2">
      <div
        class="w-4 h-4 rounded-sm transition-colors"
        :class="isAlreadySeen ? 'bg-rendin-500' : 'bg-rendin-100'"
      ></div>
    </div>
    <div class="flex items-center">
      <p class="font-bold">{{ section.title }}</p>
    </div>
    <div class="flex justify-center items-stretch transition-colors">
      <div
        class="border-r-2"
        :class="isAlreadySeen ? 'border-rendin-500' : 'border-rendin-100 border-dashed'"
      ></div>
    </div>
    <div
      class="collapsible-container flex items-center"
      :class="{ 'max-h-4': !expanded }"
    >
      <div class="flex collapsible-body">
        <div class="step-description" :class="{ collapsed: !expanded }">
          <div v-if="content" class="r-rich-text" v-html="content"></div>
          <div>
            <r-button
              v-if="section?.modalContent?.content[0]?.content"
              :label="section.modalButtonLabel || $t('read_more')"
              no-background
              no-padding
              size="small"
              @click="() => (showModal = true)"
            />
          </div>
        </div>
      </div>
    </div>
    <r-overlay size="small" :visible="showModal" @close="closeModal()">
      <div class="p-8">
        <RichText v-if="section.modalContent" :blok="section.modalContent" />
      </div>
    </r-overlay>
  </div>
</template>
<script>
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';

export default {
  name: 'SAFECollapsibleSection',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      required: true,
    },
    isAlreadySeen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      localizedRenderRichText,
    };
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    content() {
      if (!this.section.body) return '';
      return this.localizedRenderRichText(this.section.body, this.$localizedPath);
    },
  },

  watch: {
    expanded() {
      this.closeModal();
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped lang="scss">
.step-text-container {
  @apply gap-2;

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content minmax(1.5rem, min-content);
}

/* Transition based on opacity and font-size */
.step-description {
  font-size: 1rem;
  line-height: 1.5rem;
  transition:
    line-height 0.5s ease,
    opacity 0.2s 0.2s;

  &.collapsed {
    /* CSSNano problem - cannot use 0 https://github.com/cssnano/cssnano/pull/801 */
    line-height: 0.01px;
    opacity: 0;
    transition:
      opacity 0.2s,
      line-height 0.5s ease;
  }
}
</style>
