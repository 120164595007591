<template>
  <section>
    <div class="py-10 md:py-20 flex flex-col">
      <div class="flex justify-center">
        <div class="max-w-xs text-center">
          <div v-if="blok.image" class="max-w-xs grid justify-items-center">
            <img
              alt="Image not found"
              class="w-4/6 md:w-full"
              :src="blok.image.filename"
            />
          </div>
          <h3 v-if="blok.title" class="pt-4 pb-2 text-4xl md:text-2xl font-bold">
            {{ blok.title }}
          </h3>
          <RichText
            v-if="blok.firstParagraph"
            :blok="blok.firstParagraph"
            class="mb-6 md:text-lg font-light"
          />
          <RichText
            v-if="blok.secondParagraph"
            :blok="blok.secondParagraph"
            class="mb-6 md:text-lg font-light"
          />
        </div>
      </div>
      <r-button
        v-if="blok.buttonText"
        no-background
        :to="$localizedPath(blok.buttonLink.cached_url)"
      >
        {{ blok.buttonText }}
      </r-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InvalidLogin',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
