<template>
  <section :id="blok.id">
    <div
      v-if="blok.enabledDesktop"
      class="hidden lg:block pt-16 pb-10 md:py-20 bg-white overflow-hidden"
    >
      <div class="max-w-screen-lg mx-auto px-4">
        <div class="w-11/12 md:w-3/4 mx-auto text-center mb-10 sm:mb-16">
          <h2 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
            {{ blok.title }}
          </h2>
        </div>
        <div class="relative mx-auto">
          <div class="absolute" style="top: 29px; left: 112px">
            <div
              v-if="!linkIsLoaded"
              class="desktop-wrapper flex justify-center items-center"
            >
              <r-loader />
            </div>
            <client-only>
              <iframe
                allowfullscreen
                :class="[!linkIsLoaded ? 'invisible' : 'desktop-wrapper']"
                :src="desktopIframeSrc"
                @load="iFrameLoaded()"
              ></iframe>
            </client-only>
          </div>
          <img
            class="how-to-use__img__frame z-10"
            height="635"
            src="/images/howToUseLaptop.png"
            width="1008"
          />
        </div>

        <div class="mt-10 mb-6 flex justify-center">
          <div v-for="childBlok in blok.footer" :key="childBlok.uuid">
            <component :is="childBlok.component" :blok="childBlok" class="text-2xl" />
          </div>
        </div>
      </div>
    </div>
    <!--  TODO - make it scaleable, currently Disabled  -->
    <div
      v-if="blok.enabledMobile && false"
      class="lg:hidden pt-16 pb-10 md:py-20 bg-white overflow-hidden"
    >
      <div class="max-w-screen-lg mx-auto px-4">
        <div class="w-11/12 md:w-3/4 mx-auto text-center mb-10 sm:mb-16">
          <h2 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
            {{ blok.title }}
          </h2>
        </div>
        <div class="flex justify-center">
          <div class="relative">
            <div class="absolute" style="top: 50px; left: 59px">
              <client-only>
                <iframe
                  allowfullscreen
                  height="630"
                  :src="mobileIframeSrc"
                  width="312"
                ></iframe>
              </client-only>
            </div>
            <img
              class="how-to-use__img__frame z-10"
              height="780"
              src="/images/howToUsePhone.png"
              width="400"
            />
          </div>
        </div>
        <div class="mt-10 mb-6 flex justify-center">
          <div v-for="childBlok in blok.footer" :key="childBlok.uuid">
            <component :is="childBlok.component" :blok="childBlok" class="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
const parseAndFormatFigmaUrl = (input) => {
  if (!input) return '';

  /* We'd like to support both full embedd code or just simple URL  */
  /* NB! matchedUrl has length of two and we don't use global, because Safari doesn't support positive lookback yet */
  /* Ref: https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group */
  const regex = /(?:src=")(.*)(?=")/;
  const matchedUrl = input.match(regex);

  const url =
    matchedUrl && matchedUrl.length === 2 ? new URL(matchedUrl[1]) : new URL(input);

  /* We want to be sure that hide-ui query parameter is enabled */
  const newParams = {
    'hide-ui': 1,
    scaling: 'scale-down-width',
  };

  const mergedParams = new URLSearchParams([
    ...Array.from(url.searchParams.entries()), // [["a","hello"],["b","world"]]
    ...Object.entries(newParams), // [["c","a"],["d","2"],["e","false"]]
  ]).toString();

  const finalURL = new URL(`${url.origin}${url.pathname}?${mergedParams}`).toString();

  return finalURL;
};

export default {
  name: 'FigmaPrototype',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      linkIsLoaded: false,
    };
  },

  computed: {
    desktopIframeSrc() {
      return parseAndFormatFigmaUrl(this.blok.prototypeDesktop);
    },
    mobileIframeSrc() {
      return parseAndFormatFigmaUrl(this.blok.prototypeMobile);
    },
  },

  methods: {
    iFrameLoaded() {
      this.linkIsLoaded = true;
    },
  },
};
</script>

<style>
.desktop-wrapper {
  height: 482px;
  width: 772px;
}
</style>
