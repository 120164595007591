const stateDefaults = {
  showLiveChatLauncher: true,
  showLiveChat: false,
  zendeskChatToken: null,
};

export const state = () => ({
  showLiveChatLauncher: stateDefaults.showLiveChatLauncher,
  showLiveChat: stateDefaults.showLiveChat,
  zendeskChatToken: stateDefaults.zendeskChatToken,
});

export const getters = {
  showLiveChatLauncher: (state) => state.showLiveChatLauncher,
  showLiveChat: (state) => state.showLiveChat,
  zendeskChatToken: (state) => state.zendeskChatToken,
};

export const mutations = {
  SET_SHOW_LIVE_CHAT_LAUNCHER: function (state, showLiveChatLauncher) {
    state.showLiveChatLauncher = showLiveChatLauncher;
  },
  SET_SHOW_LIVE_CHAT: function (state, showLiveChat) {
    state.showLiveChat = showLiveChat;
  },
  SET_ZENDESK_CHAT_TOKEN: function (state, token) {
    state.zendeskChatToken = token;
  },
};

export const actions = {
  openChat({ commit, getters, dispatch }) {
    commit('SET_SHOW_LIVE_CHAT', true);

    if (!getters.showLiveChatLauncher) {
      commit('SET_SHOW_LIVE_CHAT_LAUNCHER', true);
    }

    dispatch('tracker/trackLiveChatToggle', { wasOpened: true }, { root: true });
  },
  closeChat({ commit, dispatch }) {
    commit('SET_SHOW_LIVE_CHAT', false);

    dispatch('tracker/trackLiveChatToggle', { wasOpened: false }, { root: true });
  },
  hideLauncher({ commit, getters }) {
    commit('SET_SHOW_LIVE_CHAT_LAUNCHER', false);

    if (getters.showLiveChat) {
      commit('SET_SHOW_LIVE_CHAT', false);
    }
  },
  showLauncher({ commit }) {
    commit('SET_SHOW_LIVE_CHAT_LAUNCHER', true);
  },
  getZendeskChatToken: function ({ dispatch, commit }) {
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          {
            functionName: 'getTokenForChat',
            data: {
              request: {
                provider: 'ZENDESK',
              },
            },
          },
          { root: true },
        ),
      )
      .then((response) => {
        const token = response.data.data.token;
        commit('SET_ZENDESK_CHAT_TOKEN', token);
        return token;
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
};
