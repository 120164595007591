<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="features-advantages-list-section"
    :class="classes"
  >
    <div class="max-w-6xl mx-auto md:grid-cols-2 grid gap-6 px-4 py-12 md:py-20">
      <!--      Text-->
      <div
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="opacity-0"
      >
        <h3 v-if="blok.title">
          <RichText
            :blok="blok.title"
            class="my-4 font-semibold text-gray-900 text-2xl md:text-3xl"
          />
        </h3>
        <RichText
          v-if="blok.description"
          :blok="blok.description"
          class="text-gray-600"
        />
      </div>

      <!--      Logos-->
      <div
        v-if="blok.imageNextToTitle?.filename"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="opacity-0"
      >
        <img
          :alt="blok.imageNextToTitle.alt"
          class="mx-auto"
          :src="
            $transformStoryblokImage(
              blok.imageNextToTitle.filename,
              '/m/fit-in/500x150/',
            )
          "
        />
      </div>

      <!--     Content-->
      <div
        v-intersection="{
          class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right',
        }"
        class="md:col-start-2 opacity-0"
        :class="{ 'md:row-start-1 md:row-span-3': !blok.imageNextToTitle?.filename }"
      >
        <div class="h-full flex flex-col justify-center">
          <div class="grid gap-4" :class="cardsGrid">
            <div
              v-for="item in blok.content"
              :key="item._uid"
              v-editable="item"
              class="flex gap-4 items-center"
              :class="{ 'sm:block': blok.cardsColumns === '2' }"
            >
              <r-icon
                v-if="item.icon"
                class="h-12 w-12 block rounded-full bg-gray-200 text-gray-400 text-xl flex justify-center items-center flex-shrink-0"
                :icon-name="item.icon"
              />
              <div>
                <h3 v-if="item.title" class="text-2xl md:text-3xl font-medium">
                  {{ item.title }}
                </h3>
                <RichText
                  v-if="item.content"
                  :blok="item.content"
                  class="features-advantages-list-section__list__text"
                />
              </div>
            </div>
          </div>
          <div
            v-if="blok.buttons?.length > 0"
            class="my-6 flex gap-4 flex-wrap items-center"
          >
            <component
              :is="theBlok.component"
              v-for="theBlok in blok.buttons.filter((item) =>
                ['ButtonBlok', 'Accordion'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
              class="w-full sm:w-auto"
            />
          </div>
        </div>
      </div>

      <!--      Image-->
      <div
        v-intersection="{
          class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left',
        }"
        class="opacity-0 md:col-start-1 md:row-start-2"
      >
        <img
          v-if="blok.image?.filename"
          :alt="blok.image.alt"
          class="mx-auto"
          :src="$transformStoryblokImage(blok.image.filename, '/m/fit-in/600x600/')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

const gridCols = {
  2: 'sm:grid-cols-2',
};

export default {
  name: 'FeaturesAdvantagesListSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      const classes = [];
      classes.push(backgroundColors[this.blok?.backgroundColor] ?? 'bg-white');

      return classes;
    },

    cardsGrid() {
      const classes = [];
      if (this.blok.cardsColumns === '2') {
        classes.push(gridCols['2']);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
.features-advantages-list-section {
  &__list__text {
    p {
      @apply my-2;
    }
  }

  .underlined {
    @apply relative inline-block;

    &::before {
      @apply absolute block bg-no-repeat bg-contain;
      content: '';
      width: 100%;
      height: 20px;
      top: 100%;
      left: 0;
    }

    &--short::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-short.svg');
    }

    &--medium::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-medium.svg');
    }

    &--long::before {
      background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-long.svg');
    }
  }
}
</style>
