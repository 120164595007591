<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="hero-header-wide-background-section bg-no-repeat bg-cover bg-center"
    :style="{
      'background-image':
        'url(' +
        $transformStoryblokImage(blok.backgroundImage.filename, '/m/fit-in/1920x720') +
        ')',
    }"
  >
    <div
      class="hero-header-wide-background-section__content flex flex-col justify-center bg-gray-900 bg-opacity-70"
    >
      <div
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="max-w-6xl mx-auto h-full p-4 sm:py-12 lg:py-20 opacity-0"
      >
        <h1
          v-if="blok.title"
          class="my-4 text-white text-center font-semibold text-4xl md:text-5xl"
        >
          <RichText :blok="blok.title" />
        </h1>
        <div v-if="blok.description" class="text-white text-center md:text-lg">
          <RichText :blok="blok.description" />
        </div>
        <div
          v-if="blok.buttons?.length"
          class="flex gap-4 flex-wrap justify-center mt-6"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.buttons.filter((item) =>
              ['ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-auto"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroHeaderWideBackgroundSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.hero-header-wide-background-section__content {
  @media screen and (min-width: 768px) {
    min-height: 480px;
  }
}
</style>
