<template>
  <div
    id="page-content"
    v-editable="blok"
    class="mx-auto px-6 max-w-5xl py-8 md:py-16 md:pb-20 w-full"
  >
    <RichText :blok="blok.content" />
  </div>
</template>

<script>
export default {
  name: 'SectionContent',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
