import type { DirectiveBinding } from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted(el: HTMLElement, binding: DirectiveBinding<Function>) {
      el.clickOutsideEvent = (event: MouseEvent) => {
        if (!(el === event.target || el.contains(event.target as Node))) {
          binding.value(event);
        }
      };

      setTimeout(() => {
        document.addEventListener('click', el.clickOutsideEvent as EventListener);
      }, 0);
    },
    unmounted(el: HTMLElement) {
      document.removeEventListener('click', el.clickOutsideEvent as EventListener);
    },
  });
});
