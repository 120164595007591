const stateDefaults = {
  serverFirstTimeLoad: false,
  serverDistinctId: '',
  serverFeatureFlags: [],
  bootstrap: false,
};

export const state = () => ({
  serverFirstTimeLoad: stateDefaults.serverFirstTimeLoad,
  serverDistinctId: stateDefaults.serverDistinctId,
  serverFeatureFlags: stateDefaults.serverFeatureFlags,
  bootstrap: stateDefaults.bootstrap,
});

export const getters = {
  serverFirstTimeLoad: (state) => state.serverFirstTimeLoad,
  serverDistinctId: (state) => state.serverDistinctId,
  serverFeatureFlags: (state) => state.serverFeatureFlags,
  bootstrap: (state) => state.bootstrap,
};

export const mutations = {
  SET_SERVER_FIRST_TIME_LOAD: function (state, isFirstTimeLoad) {
    state.serverFirstTimeLoad = isFirstTimeLoad;
  },
  SET_SERVER_DISTINCT_ID: function (state, distinctId) {
    state.serverDistinctId = distinctId;
  },
  SET_SERVER_FEATURE_FLAGS: function (state, serverFeatureFlags) {
    state.serverFeatureFlags = serverFeatureFlags;
  },
  SET_SERVER_BOOTSTRAP: function (state, shouldBootstrapFromServerData) {
    state.bootstrap = shouldBootstrapFromServerData;
  },
};
