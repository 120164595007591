<template>
  <div :class="classes">
    <div class="max-w-6xl mx-auto md:grid-cols-2 md:grid items-center gap-12 px-4 py-8">
      <div
        v-intersection="
          imagePositionLeft
            ? { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right' }
            : { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left' }
        "
        class="opacity-0 py-4 text-gray-600 h-full md:flex flex-col justify-center"
      >
        <slot name="primary-content-before"></slot>

        <h3
          v-if="title"
          class="my-4 font-semibold text-gray-900 text-2xl md:text-3xl"
          v-html="title"
        ></h3>
        <template v-if="primaryContent">
          <component
            :is="theBlok.component"
            v-for="theBlok in primaryContent.filter((item) =>
              [
                'TextBlok',
                'RichTextBlok',
                'FeatureCheckmarkList',
                'TitleIconBlok',
                'DelayActionBlok',
              ].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:text-lg"
          />
        </template>

        <div v-if="buttons?.length" class="my-4 flex gap-4 flex-wrap items-center">
          <component
            :is="theBlok.component"
            v-for="theBlok in buttons.filter((item) =>
              ['ButtonBlok', 'Accordion', 'ButtonWithContentModal'].includes(
                item.component,
              ),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:w-max"
          />
        </div>

        <slot name="primary-content-after"></slot>
      </div>

      <div
        v-intersection="
          imagePositionLeft
            ? { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-left' }
            : { class: 'opacity-100 animate-slide-in-bottom md:animate-slide-in-right' }
        "
        class="opacity-0"
        :class="[imagePositionLeft ? 'md:order-first' : '']"
      >
        <slot name="secondary-content-before"></slot>
        <div v-if="image.filename">
          <img
            :alt="image.alt"
            class="rounded-bl-3xl rounded-br-3xl mx-auto"
            :class="[
              imagePositionLeft
                ? 'md:rounded-tl-3xl md:rounded-bl-3xl md:rounded-br-none'
                : 'md:rounded-tr-3xl md:rounded-br-3xl md:rounded-bl-none',
              fullRoundedImage ? 'rounded-3xl md:rounded-br-3xl md:rounded-bl-3xl' : '',
            ]"
            :src="$transformStoryblokImage(image.filename, '/m/600x670/')"
          />
        </div>
        <div v-if="secondaryContent?.length > 0">
          <component
            :is="theBlok.component"
            v-for="theBlok in secondaryContent.filter((item) =>
              ['VideoBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="w-full md:text-lg"
          />
        </div>
        <slot name="secondary-content-after"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'MultipleStepLeadMagnetFormSection',
  props: {
    imagePositionLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    primaryContent: {
      type: Array,
      required: false,
      default: () => null,
    },
    buttons: {
      type: Array,
      required: false,
      default: () => null,
    },
    image: {
      type: Object,
      required: false,
      default: () => null,
    },
    fullRoundedImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondaryContent: {
      type: Array,
      required: false,
      default: () => null,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(
        backgroundColors[this.backgroundColor || this.blok?.backgroundColor] ??
          'bg-white',
      );

      return classes;
    },
  },
};
</script>
