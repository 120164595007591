<template>
  <Field
    ref="validator"
    v-slot="{ meta, setTouched }"
    v-model="descriptionModel"
    validate-on-mount
    name="description"
    rules="required"
  >
    <div class="mx-auto text-center px-4 my-6">
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
        :class="{ 'r-fade-bottom': initialLoad }"
      />
      <component
        :is="childBlok.component"
        v-for="childBlok in descriptionText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="my-6 r-fade--delay--1 text-gray-400"
        :class="{ 'r-fade-bottom': initialLoad }"
      />
    </div>
    <transition
      appear
      :enter-active-class="initialLoad ? 'r-fade-bottom r-fade-bottom-start' : ''"
    >
      <div class="r-fade--delay--2 mx-auto px-4">
        <r-textarea
          v-model="descriptionModel"
          :error-text="descriptionTriggersErrorMessage"
          :focus-on-load="true"
          :help-modal-label="modalLabel"
          :highlight-triggers-words="highlightTriggersWords"
          :label="$t('form.label.description')"
          label-class="text-xs sm:text-base"
          :maxlength="descriptionMaxLength"
          name="description"
          :placeholder="$t('form.placeholder.description')"
          rows="12"
          :trigger-words-regex-array="descriptionTriggersRegex"
          :validation-failed="
            (meta.touched && !meta.valid) || !!descriptionTriggersErrorMessage
          "
          :validation-passed="meta.valid"
          @input="setTouched(true)"
          @blur="setTouched(true)"
        >
          <template #modal>
            <component
              :is="childBlok.component"
              v-for="childBlok in modalContent.filter((item) =>
                ['RichTextBlok', 'AccordionGroup'].includes(item.component),
              )"
              :key="childBlok._uid"
              :blok="childBlok"
            />
          </template>
        </r-textarea>
        <div class="h-14 mt-2 mb-4">
          <div
            v-if="meta.valid"
            class="flex justify-center items-center"
            :class="{ 'r-fade': initialLoad }"
          >
            <r-button
              :id="submitButtonId"
              color="orange"
              :is-loading="requestInProgress"
              @click="meta.valid && !requestInProgress ? submit() : null"
            >
              <r-icon class="mr-1 text-lg" icon-name="check-circle" />
              <span class="font-medium">{{ nextButtonLabel }}</span>
            </r-button>
          </div>
        </div>
      </div>
    </transition>
  </Field>
</template>
<script>
import { mapActions } from 'vuex';
import { censorshipEventNames } from '~/utils/trackerConstants';
import { webSourceType } from '~/utils/constants';
import { Field } from 'vee-validate';

export default {
  name: 'StepSelectDescription',
  components: { Field },
  props: {
    introText: {
      type: Array,
      required: true,
    },
    descriptionText: {
      type: Array,
      required: true,
    },
    descriptionMaxLength: {
      type: String,
      required: true,
    },
    modalContent: {
      type: Array,
      required: true,
    },
    modalLabel: {
      type: String,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
    descriptionTriggersRegex: {
      type: Array,
      default() {
        return [];
      },
    },
    highlightTriggersWords: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:description', 'goToNextStep'],

  data() {
    return {
      userTriggeredDescriptionRegex: false,
    };
  },

  computed: {
    descriptionModel: {
      get() {
        return this.description;
      },
      set(newVal) {
        this.$emit('update:description', newVal);
      },
    },

    descriptionTriggeredRegex() {
      const triggeredRegex = [];
      this.descriptionTriggersRegex.forEach((regexItem) => {
        if (regexItem.regex.test(this.descriptionModel)) {
          if (!triggeredRegex.find((item) => item.key === regexItem.key)) {
            triggeredRegex.push(regexItem);
          }
        }
      });
      return triggeredRegex.length > 0 ? triggeredRegex : null;
    },

    descriptionTriggersErrorMessage() {
      if (this.descriptionTriggeredRegex) {
        const errorMessagesArray = this.descriptionTriggeredRegex.map(
          (item) => item.errorMessage,
        );
        const errorMessagesString = errorMessagesArray.join(', ');
        return this.$t('censorship.general') + ' ' + errorMessagesString;
      }
      return null;
    },
  },

  watch: {
    descriptionTriggeredRegex(newVal) {
      if (newVal && !this.userTriggeredDescriptionRegex) {
        this.userTriggeredDescriptionRegex = true;
        const eventName = censorshipEventNames.USED;
        const properties = {
          source: webSourceType.WEB_ADVERTISEMENT_EDIT,
          triggerKey: newVal.key,
          regex: newVal.regex,
          content: this.descriptionModel,
        };
        this.actionTrackListingDescriptionTrigger({
          eventName: eventName,
          props: properties,
        });
      }
    },
  },

  methods: {
    ...mapActions({
      actionTrackListingDescriptionTrigger: 'tracker/trackListingDescriptionTrigger',
    }),

    async submit() {
      const isValid = await this.validate();
      if (isValid && !this.requestInProgress) {
        this.$emit('goToNextStep');
        if (this.userTriggeredDescriptionRegex) {
          if (!this.descriptionTriggeredRegex) {
            const eventName = censorshipEventNames.SUCCESS;
            const properties = {
              source: webSourceType.WEB_DIGIBROKER,
              content: this.descriptionModel,
            };
            this.actionTrackListingDescriptionTrigger({
              eventName: eventName,
              props: properties,
            });
          } else {
            const eventName = censorshipEventNames.IGNORED;
            const properties = {
              source: webSourceType.WEB_DIGIBROKER,
              triggerKey: this.descriptionTriggeredRegex.key,
              regex: this.descriptionTriggeredRegex.regex,
              content: this.descriptionModel,
            };
            this.actionTrackListingDescriptionTrigger({
              eventName: eventName,
              props: properties,
            });
          }
        }
      }
    },
    async validate() {
      const validationResult = await this.$refs.validator.validate();
      return validationResult.valid;
    },
  },
};
</script>
