import { useStore } from 'vuex';

export default defineNuxtPlugin({
  name: 'serverInit',
  dependsOn: ['init', 'i18n'],
  async setup() {
    const store = useStore();

    await store.dispatch('nuxtServerInit');
  },
});
