<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    header-class="bg-white z-10"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <template #header>
      <div class="flex flex-row items-center">
        <div class="w-9 h-9 flex-shrink-0 m-2">
          <button
            v-if="showBackToStartButton"
            class="bg-gray-100 rounded-full w-9 h-9 hover:shadow focus:shadow"
            @click="backToStartView()"
          >
            <span class="sr-only">{{ $t('buttons.back') }}</span>
            <r-icon class="text-xl" icon-name="arrow-left-long" />
          </button>
        </div>
        <div class="flex-grow text-center text-xl font-medium py-2">
          <span v-if="newProfileType === newProfileTypeByOther.COTENANT">
            {{ $t('agreement.button.add_cotenant') }}
          </span>
          <span
            v-else-if="
              currentView === 'INCOMPLETE_CREATOR_PROFILE' ||
              currentView === 'EDIT_CREATOR_PROFILE'
            "
          >
            {{ $t('buttons.profile') }}
          </span>
          <span v-else-if="currentView === 'CONFIRMATION'"></span>
          <span v-else>
            {{ $t('agreement.button.add_landlord') }}
          </span>
        </div>
      </div>
    </template>
    <div class="m-4 mt-16 flex flex-col h-full">
      <!--    Start view -->
      <template v-if="currentView === addOrSearchNewProfileViews.START">
        <div class="flex-grow">
          <img
            :alt="$t('agreement.button.add_landlord')"
            class="mx-auto w-52"
            src="/illustrations/search-candidates-choice.svg"
          />
          <h3 class="text-center text-xl font-medium my-4">
            {{ $t('search_or_add_new_profile_modal.dialog.select_the_landlord') }}
          </h3>
        </div>
        <div class="flex flex-col gap-3">
          <r-button
            :label="$t('agreement.button.myself')"
            size="small"
            width="full"
            @click="validateCreatorProfileAndAddAsLandlord()"
          />
          <r-button
            border-color="light-gray"
            inverted
            :label="$t('agreement.button.someone_else')"
            size="small"
            width="full"
            @click="showFirstSearchView()"
          />
        </div>
      </template>

      <!--    First search -->
      <template v-else-if="currentView === addOrSearchNewProfileViews.FIRST_SEARCH">
        <img
          :alt="$t('agreement.button.add_landlord')"
          class="mx-auto w-52"
          src="/illustrations/sailor-with-binoculars.svg"
        />
        <h3 class="text-center text-xl font-medium my-4">
          <span v-if="newProfileType === newProfileTypeByOther.COTENANT">
            {{
              $t(
                'search_or_add_new_profile_modal.dialog.add_co_tenant_by_email_or_id.' +
                  getCountry,
              )
            }}
          </span>
          <span v-else>
            {{
              $t(
                'search_or_add_new_profile_modal.dialog.add_landlord_by_email_or_id.' +
                  getCountry,
              )
            }}
          </span>
        </h3>

        <Form
          autocomplete="off"
          class="h-full flex flex-col"
          @submit="getProfileInfoSearch(searchString)"
        >
          <Field
            v-slot="{ meta }"
            v-model="searchString"
            class="flex-grow"
            rules="required|idCodeOrEmail"
            name="search"
          >
            <r-input
              id="personalIdCode"
              v-model="searchString"
              :error-text="$t('form.validation.id_code_or_email.' + getCountry)"
              field-type="text"
              for="search"
              :label="$t('form.label.id_code_or_email.' + getCountry)"
              name="search"
              :placeholder="$t('form.placeholder.id_code_or_email.' + getCountry)"
              :validation-failed="meta.validated && !meta.valid"
              :validation-passed="meta.validated && meta.valid"
            />
          </Field>
          <r-button
            class="mt-4"
            :label="$t('buttons.continue')"
            size="small"
            type="submit"
            width="full"
          />
        </Form>
      </template>

      <!--    Search loader -->
      <template v-else-if="currentView === addOrSearchNewProfileViews.IS_SEARCHING">
        <div class="pb-8 text-center">
          <h3 class="text-rendin-500 text-2xl font-medium">
            {{ $t('search_or_add_new_profile_modal.validation_result.loading_main') }}
          </h3>

          <r-loader class="w-12 h-10 my-10" type="animatedLogo" />
          {{
            $t('search_or_add_new_profile_modal.validation_result.loading_description')
          }}
        </div>
      </template>

      <!--    Error -->
      <template v-else-if="currentView === addOrSearchNewProfileViews.ERROR">
        <div
          class="text-center text-error flex-grow flex justify-center items-center my-12"
        >
          {{ $t('errors.something_wrong') }}
        </div>
        <r-button
          display="block"
          :label="$t('buttons.back')"
          no-background
          uppercase
          @click="backToStartView()"
        />
      </template>

      <!--    Search results list -->
      <template
        v-else-if="currentView === addOrSearchNewProfileViews.SEARCH_RESULTS_LIST"
      >
        <img
          :alt="$t('agreement.button.add_landlord')"
          class="mx-auto w-52"
          src="/illustrations/sailor-with-binoculars.svg"
        />
        <h3 class="text-center text-xl font-medium my-4">
          {{ $t('search_or_add_new_profile_modal.dialog.account_existing') }}
        </h3>
        <div class="text-gray-600 text-center my-4">
          {{
            $t(
              'search_or_add_new_profile_modal.dialog.account_existing_disclaimer.' +
                getCountry,
            )
          }}
        </div>
        <div
          v-for="(profile, index) in searchResult"
          :key="index"
          class="p-2 my-4 bg-peach-100 rounded-lg flex gap-2 justify-between items-center"
        >
          <div class="text-sm font-medium uppercase">
            <r-icon class="mx-2" icon-name="user" />
            {{ profile.name }}
          </div>

          <r-button
            border-color="light-gray"
            color="black"
            inverted
            :label="$t('buttons.add')"
            size="small"
            @click="emitSelectedProfileId(profile.id)"
          />
        </div>
      </template>

      <!--    No result for first search -->
      <template
        v-else-if="
          currentView === addOrSearchNewProfileViews.NO_RESULTS_START_NEW_SEARCH
        "
      >
        <div class="flex-grow text-center">
          <img
            :alt="$t('agreement.button.add_landlord')"
            class="mx-auto w-52"
            src="/illustrations/list-task.svg"
          />
          <h3 class="text-center text-xl font-medium my-4">
            {{ $t('search_or_add_new_profile_modal.dialog.account_not_found') }}
          </h3>

          <div v-if="newProfileData.email && !newProfileData.personalIdCode">
            <div class="text-center text-gray-600 my-4">
              {{ $t('search_or_add_new_profile_modal.dialog.no_account_with_e-mail') }}
            </div>

            <div class="font-medium my-4">{{ newProfileData.email }}</div>
          </div>

          <div v-if="newProfileData.personalIdCode && !newProfileData.email">
            <div class="text-center text-gray-600 my-4">
              {{
                $t(
                  'search_or_add_new_profile_modal.dialog.no_account_with_id.' +
                    getCountry,
                )
              }}
            </div>
            <div class="font-medium my-4">{{ newProfileData.personalIdCode }}</div>
          </div>
          <div class="text-center text-gray-600 my-4">
            <span v-if="newProfileType === newProfileTypeByOther.COTENANT">
              {{ $t('search_or_add_new_profile_modal.dialog.add_co_tenant_manually') }}
            </span>
            <span v-else>
              {{ $t('search_or_add_new_profile_modal.dialog.add_landlord_manually') }}
            </span>
          </div>
        </div>
        <div class="grid gap-4">
          <r-button
            :label="$t('buttons.add_manually')"
            size="small"
            width="full"
            @click="additionalCheckForEmailOrId = true"
          />
        </div>
      </template>

      <!--    Additional check for Id or email -->
      <template
        v-else-if="currentView === addOrSearchNewProfileViews.ADDITIONAL_PROFILE_CHECK"
      >
        <img
          :alt="$t('agreement.button.add_landlord')"
          class="mx-auto w-52"
          src="/illustrations/list-task.svg"
        />
        <div v-if="newProfileData.personalIdCode" class="flex-grow flex flex-col">
          <h3 class="font-medium text-center text-xl">
            <span v-if="newProfileType === newProfileTypeByOther.COTENANT">
              {{
                $t('search_or_add_new_profile_modal.dialog.fill_co_tenant_information')
              }}
            </span>
            <span v-else>
              {{
                $t('search_or_add_new_profile_modal.dialog.fill_landlord_information')
              }}
            </span>
          </h3>
          <div class="text-gray-600 text-center my-4">
            {{ $t('search_or_add_new_profile_modal.dialog.start_with_email') }}
          </div>

          <Form
            autocomplete="off"
            class="flex-grow flex flex-col"
            @submit="getProfileInfoSearch(searchString)"
          >
            <Field
              v-slot="{ meta }"
              v-model="searchString"
              class="flex-grow"
              rules="required|email"
              name="email"
            >
              <r-input
                id="email"
                v-model="searchString"
                :error-text="$t('form.validation.email')"
                field-type="text"
                for="email"
                :label="$t('form.label.email')"
                name="email"
                :placeholder="$t('form.placeholder.email')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
              />
            </Field>
            <r-button
              class="mt-4"
              :label="$t('buttons.continue')"
              size="small"
              type="submit"
              width="full"
            />
          </Form>
        </div>
        <div v-if="newProfileData.email" class="flex-grow flex flex-col">
          <h3 class="font-medium text-center text-xl">
            <span v-if="newProfileType === newProfileTypeByOther.COTENANT">
              {{
                $t('search_or_add_new_profile_modal.dialog.fill_co_tenant_information')
              }}
            </span>
            <span v-else>
              {{
                $t('search_or_add_new_profile_modal.dialog.fill_landlord_information')
              }}
            </span>
          </h3>

          <div class="text-gray-600 text-center my-4">
            {{
              $t('search_or_add_new_profile_modal.dialog.start_with_id.' + getCountry)
            }}
          </div>

          <Form
            autocomplete="off"
            class="flex-grow flex flex-col"
            @submit="getProfileInfoSearch(searchString)"
          >
            <Field
              v-slot="{ meta }"
              v-model="searchString"
              class="flex-grow"
              rules="required|idCode"
              name="id_code"
            >
              <r-input
                id="id_code"
                v-model="searchString"
                :error-text="$t('form.validation.id_code.' + getCountry)"
                field-type="text"
                for="id_code"
                :label="$t('form.label.id_code.' + getCountry)"
                name="id_code"
                :placeholder="
                  newProfileType === newProfileTypeByOther.LANDLORD
                    ? $t('form.placeholder.landlord_id_code.' + getCountry)
                    : $t('form.placeholder.co_tenant_id_code.' + getCountry)
                "
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
              />
            </Field>
            <r-button
              class="mt-4"
              :label="$t('buttons.continue')"
              size="small"
              type="submit"
              width="full"
            />
          </Form>
        </div>
      </template>

      <!--   New profile form-->
      <template v-else-if="currentView === addOrSearchNewProfileViews.NEW_PROFILE_FORM">
        <div class="rounded-b-xl shadow-xl p-4">
          <img
            :alt="$t('agreement.button.add_landlord')"
            class="mx-auto w-52"
            src="/illustrations/list-task.svg"
          />
          <h3 class="font-medium text-center text-xl">
            {{
              $t('search_or_add_new_profile_modal.dialog.add_other_missing_information')
            }}
          </h3>
          <div class="text-gray-600 text-center my-4">
            {{
              $t(
                'search_or_add_new_profile_modal.dialog.correct_information_disclaimer',
              )
            }}
          </div>
        </div>

        <ProfilePrimarySetting
          v-if="!newProfileResultLoading"
          :is-new-profile="newProfileType.length > 0"
          :new-profile-data="newProfileData"
          :submit-button-label="$t('buttons.add_to_agreement')"
          submit-button-wrapper-class="sticky bottom-0 h-18 p-4 bg-white -mx-4"
          :type="newProfileType"
          @submitted-profile-data="(profileData) => emitNewProfileId(profileData)"
        />

        <div v-if="newProfileResultLoading" class="bg-white rounded-t-xl pt-4 mt-4">
          <r-loader class="w-20 h-20" type="animatedLogo" />
        </div>
      </template>

      <template
        v-else-if="
          currentView === addOrSearchNewProfileViews.INCOMPLETE_CREATOR_PROFILE
        "
      >
        <div class="flex-grow">
          <img
            :alt="$t('agreement.button.add_landlord')"
            class="mx-auto w-52"
            src="/illustrations/list-task.svg"
          />
          <h3 class="text-center text-xl font-medium my-4">
            {{
              $t('search_or_add_new_profile_modal.dialog.your_profile_is_incomplete')
            }}
          </h3>
          <div class="text-center text-gray-600">
            <p class="my-4">
              {{
                $t(
                  'search_or_add_new_profile_modal.dialog.complete_your_profile_explanation',
                )
              }}
            </p>
          </div>
        </div>

        <r-button
          class="mt-4"
          :label="$t('buttons.continue')"
          size="small"
          width="full"
          @click="[(incompleteCreatorProfile = false), (editCreatorProfile = true)]"
        />
      </template>
      <template
        v-else-if="currentView === addOrSearchNewProfileViews.EDIT_CREATOR_PROFILE"
      >
        <div class="p-4 pb-6 sm:px-7 bg-white rounded-lg shadow-lg">
          <h3 class="text-center text-xl font-medium my-4">
            {{ $t('search_or_add_new_profile_modal.dialog.information_missing') }}
          </h3>
          <div class="text-center text-gray-600">
            <p class="my-4">
              {{
                $t(
                  'search_or_add_new_profile_modal.dialog.information_missing_explanation',
                )
              }}
            </p>
          </div>
        </div>

        <ProfilePrimarySetting
          :submit-button-label="$t('buttons.continue')"
          :submit-button-loading="putProfileInProgress"
          submit-button-wrapper-class="sticky bottom-0 h-18 p-4 bg-white -mx-4"
          :type="newProfileTypeByOther.LANDLORD"
          @submitted-profile-data="(profileData) => putProfileData(profileData)"
        />
      </template>
    </div>
  </r-overlay>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { profileSourceIndications, newProfileTypeByOther } from '~/utils/constants';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { ErrorMessage, Field, Form } from 'vee-validate';

const addOrSearchNewProfileViews = {
  START: 'START',
  FIRST_SEARCH: 'FIRST_SEARCH',
  IS_SEARCHING: 'IS_SEARCHING',
  SEARCH_RESULTS_LIST: 'SEARCH_RESULTS_LIST',
  NO_RESULTS_START_NEW_SEARCH: 'NO_RESULTS_START_NEW_SEARCH',
  ADDITIONAL_PROFILE_CHECK: 'ADDITIONAL_PROFILE_CHECK',
  NEW_PROFILE_FORM: 'NEW_PROFILE_FORM',
  ERROR: 'ERROR',
  INCOMPLETE_CREATOR_PROFILE: 'INCOMPLETE_CREATOR_PROFILE',
  EDIT_CREATOR_PROFILE: 'EDIT_CREATOR_PROFILE',
};

/**
 * Component for searching existing profile by email or id or creating new, emits profile id
 */
export default {
  name: 'SearchOrAddNewProfileModal',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    /**
     * newProfileType | [ `LANDLORD`, `COTENANT` ]
     */
    newProfileType: {
      type: String,
      default: null,
    },
    startFromFirstSearchView: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },

  emits: ['selectedProfileId', 'closeOverlay'],

  data() {
    return {
      searchString: null,
      searchResult: null,
      isSearching: false,
      searchError: null,
      newProfileData: {},
      additionalCheckForEmailOrId: false,
      showNewProfileForm: false,
      startView: true,
      firstSearchView: false,
      newProfileResultLoading: false,
      incompleteCreatorProfile: false,
      editCreatorProfile: false,
      addOrSearchNewProfileViews: addOrSearchNewProfileViews,
      isProfileIncomplete: false,
      trackingProcessNames: trackingProcessNames,
      agreementTrackingEventNames: agreementTrackingEventNames,
      newProfileTypeByOther,
    };
  },
  computed: {
    ...mapGetters({
      profileId: 'users/profileId',
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
      getCountry: 'getCountry',
    }),

    currentView() {
      if (this.firstSearchView) {
        return addOrSearchNewProfileViews.FIRST_SEARCH;
      } else if (this.isSearching) {
        this.firstSearchView = false;
        return addOrSearchNewProfileViews.IS_SEARCHING;
      } else if (this.searchError) {
        return addOrSearchNewProfileViews.ERROR;
      } else if (this.searchResult && this.searchResult.length > 0) {
        return addOrSearchNewProfileViews.SEARCH_RESULTS_LIST;
      } else if (
        this.searchResult &&
        this.searchResult.length === 0 &&
        !this.additionalCheckForEmailOrId &&
        !this.showNewProfileForm
      ) {
        return addOrSearchNewProfileViews.NO_RESULTS_START_NEW_SEARCH;
      } else if (
        this.searchResult &&
        this.searchResult.length === 0 &&
        this.additionalCheckForEmailOrId &&
        !this.showNewProfileForm
      ) {
        return addOrSearchNewProfileViews.ADDITIONAL_PROFILE_CHECK;
      } else if (
        this.searchResult &&
        this.searchResult.length === 0 &&
        this.showNewProfileForm
      ) {
        return addOrSearchNewProfileViews.NEW_PROFILE_FORM;
      } else if (this.incompleteCreatorProfile) {
        return addOrSearchNewProfileViews.INCOMPLETE_CREATOR_PROFILE;
      } else if (this.editCreatorProfile) {
        return addOrSearchNewProfileViews.EDIT_CREATOR_PROFILE;
      }

      return addOrSearchNewProfileViews.START;
    },

    showBackToStartButton() {
      if (
        this.newProfileType === newProfileTypeByOther.COTENANT ||
        this.startFromFirstSearchView
      ) {
        return (
          this.currentView !== 'FIRST_SEARCH' &&
          this.currentView !== 'ADDITIONAL_PROFILE_CHECK' &&
          this.currentView !== 'NEW_PROFILE_FORM'
        );
      }
      return (
        this.currentView !== 'START' &&
        this.currentView !== 'ADDITIONAL_PROFILE_CHECK' &&
        this.currentView !== 'NEW_PROFILE_FORM'
      );
    },
  },

  watch: {
    startFromFirstSearchView() {
      this.backToStartView();
    },
  },

  mounted() {
    this.backToStartView();
  },

  methods: {
    ...mapActions({
      actionGetProfileInfoSearch: 'profiles/getProfileInfoSearch',
      actionPostProfile: 'profiles/postProfile',
      actionPutProfile: 'profiles/putProfile',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
    }),

    getProfileInfoSearch(searchStr) {
      this.firstSearchView = false;
      this.isSearching = true;

      Promise.resolve()
        .then(() => this.actionGetProfileInfoSearch(searchStr))
        .then((response) => {
          this.searchResult = response;
          this.isSearching = false;
          this.checkStringType(this.searchString);

          if (this.additionalCheckForEmailOrId && this.searchResult.length === 0) {
            this.showNewProfileForm = true;
          }

          const resultsArray = response.map((profile) => profile.id);
          const searchResultPropsForTracking = {
            resultsCount: resultsArray.length,
            results: resultsArray,
          };

          if (this.newProfileType === newProfileTypeByOther.COTENANT) {
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.SEARCH_CO_TENANT,
              searchResultPropsForTracking,
            );
          } else if (this.newProfileType === newProfileTypeByOther.LANDLORD) {
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.SEARCH_LANDLORD,
              searchResultPropsForTracking,
            );
          }
        })
        .catch((err) => (this.searchError = err.message));
    },

    checkStringType(str) {
      const emailValidationRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (emailValidationRegex.test(str)) {
        this.newProfileData.email = str;
      } else {
        this.newProfileData.personalIdCode = str;
      }
      this.searchString = null;
    },

    emitSelectedProfileId(id) {
      this.$emit('selectedProfileId', id);
      this.closeOverlay();
      this.backToStartView();
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.CREATOR_IS_LANDLORD,
      );
    },

    validateProfileIncomplete() {
      if (this.profile) {
        const profileMain =
          !this.profile.firstName ||
          !this.profile.lastName ||
          !this.profile.phoneNumber ||
          !this.profile.email ||
          !this.profile.idCode;
        const profileAddress =
          !this.profile.addressCity ||
          !this.profile.addressCountry ||
          !this.profile.addressPostalCode ||
          !this.profile.addressFirstLine;

        let profileBusinessName = false;
        let profileCompanyIdentificationNumbers = false;
        if (this.profile.type === 'BUSINESS') {
          if (this.profile.addressCountry && this.profile.addressCountry === 'PL') {
            profileCompanyIdentificationNumbers = !this.profile.companyTaxNumber;
          } else {
            profileCompanyIdentificationNumbers = !this.profile.companyNumber;
          }
          profileBusinessName = !this.profile.companyName;
        }

        this.isProfileIncomplete =
          profileMain ||
          profileAddress ||
          profileBusinessName ||
          profileCompanyIdentificationNumbers;
      }
    },

    validateCreatorProfileAndAddAsLandlord() {
      this.validateProfileIncomplete();

      if (this.isProfileIncomplete) {
        this.incompleteCreatorProfile = true;
        this.emitAgreementTrackerEvent(
          this.agreementTrackingEventNames.CREATOR_PROFILE_INCOMPLETE,
        );
      } else {
        this.emitSelectedProfileId(this.profileId);
      }
    },

    emitNewProfileId(profileData) {
      this.newProfileResultLoading = true;
      const data = {
        ...profileData,
        profileInitialType:
          this.newProfileType === 'LANDLORD' ? this.newProfileType : 'TENANT',
      };

      this.actionPostProfile({
        profileData: data,
        indicatedProfileSource: profileSourceIndications.WEB_BY_OTHER,
      })
        .then((result) => {
          if (result.response) {
            if (result.response.success && result.response.data?.created?.profile?.id) {
              const profileId = result.response.data.created.profile.id;
              this.$emit('selectedProfileId', profileId);
              this.backToStartView();
            } else if (result.response.error) {
              this.reportErrorToSentry(result.response.error);
            }

            this.newProfileResultLoading = false;
          }
        })
        .catch((err) => {
          console.error('Failed: ', err);
        });
    },

    putProfileData(profileData) {
      if (!this.putProfileInProgress) {
        this.actionPutProfile({ profile: profileData, id: this.profile.id })
          .then((response) => {
            if (!response.failed) {
              this.$toast.success(this.$t('profile_saved'), {
                position: 'top-center',
                timeout: 3000,
                hideProgressBar: true,
              });

              // validate profile
              this.validateProfileIncomplete();
              if (!this.isProfileIncomplete) {
                this.emitSelectedProfileId(this.profile.id);
                this.editCreatorProfile = false;
              } else {
                this.editCreatorProfile = false;
                this.incompleteCreatorProfile = true;
              }
            }
          })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.CREATOR_PROFILE_UPDATED,
            ),
          );
      }
    },

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    backToStartView() {
      if (
        this.newProfileType === newProfileTypeByOther.COTENANT ||
        this.startFromFirstSearchView
      ) {
        this.startView = false;
        this.firstSearchView = true;
      } else {
        this.startView = true;
        this.firstSearchView = false;
      }

      this.showNewProfileForm = false;
      this.searchString = null;
      this.searchResult = null;
      this.isSearching = false;
      this.searchError = null;
      this.newProfileData = {};
      this.additionalCheckForEmailOrId = false;
      this.incompleteCreatorProfile = false;
      this.editCreatorProfile = false;
    },

    showFirstSearchView() {
      this.startView = false;
      this.firstSearchView = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.SOMEONE_ELSE_IS_LANDLORD,
      );
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
