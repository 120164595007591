<template>
  <section>
    <client-only>
      <r-overlay
        :size="ComponentSize.MEDIUM"
        :visible="showModal"
        @close="closeOverlay()"
      >
        <div class="sm:px-8">
          <agreement-type-edit
            :agreement-from-firebase="agreementFromFirebase"
            :agreement-id="agreementId"
            :button-label="$t('buttons.save_and_close')"
            @selected-agreement-type-updated="
              [closeOverlay(), $emit('agreementTypeChanged')]
            "
          />
        </div>
      </r-overlay>
    </client-only>
  </section>
</template>
<script>
import AgreementTypeEdit from '~/components/section/agreement/AgreementTypeEdit.vue';
import { ComponentSize } from '~/utils/constants';

export default {
  name: 'AgreementTypeSelectModal',
  components: {
    AgreementTypeEdit,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay', 'agreementTypeChanged'],
  data: () => ({
    ComponentSize,
  }),

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
