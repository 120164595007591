<template>
  <section
    :id="blok.section_id"
    v-editable="blok"
    class="w-full flex justify-center"
    :class="sectionBackgroundColor"
  >
    <div
      class="flex-1 flex md:grid md:grid-cols-12 bg-cover"
      :class="cardClasses"
      :style="{
        'background-image':
          'url(' +
          $transformStoryblokImage(blok.backgroundImage.filename, '/m/1800x0/') +
          ')',
      }"
    >
      <component
        :is="column.component"
        v-for="column in blok.columns.filter((item) =>
          ['SectionBannerColumn'].includes(item.component),
        )"
        :key="column._uid"
        :blok="column"
        :class="gridColumnSpan[column?.gridColumnWidth || calculatedColumnSpan]"
      />
    </div>
  </section>
</template>

<script>
import {
  backgroundColors,
  ComponentFlexSpacing,
  ComponentMaxWidth,
} from '~/utils/constants.js';

const gridColumnSpan = {
  1: 'md:col-span-1',
  2: 'md:col-span-2',
  3: 'md:col-span-3',
  4: 'md:col-span-4',
  5: 'md:col-span-5',
  6: 'md:col-span-6',
  7: 'md:col-span-7',
  8: 'md:col-span-8',
  9: 'md:col-span-9',
  10: 'md:col-span-10',
  11: 'md:col-span-11',
  12: 'md:col-span-12',
};

export default {
  name: 'SectionBanner',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return { gridColumnSpan: gridColumnSpan };
  },

  computed: {
    cardClasses() {
      const classes = [];
      if (this.blok.hasShadow) {
        classes.push('m-4 rounded-lg shadow-md');
      }

      if (this.blok.mobileReverseOrder) {
        classes.push('flex-col-reverse');
      } else {
        classes.push('flex-col');
      }

      if (this.blok.backgroundColor && !this.blok.backgroundColorEdgeToEdge) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      }

      if (this.blok.maxWidth) {
        if (this.blok.maxWidth === ComponentMaxWidth.SMALL) classes.push('max-w-2xl');
        if (this.blok.maxWidth === ComponentMaxWidth.MEDIUM) classes.push('max-w-3xl');
        if (this.blok.maxWidth === ComponentMaxWidth.LARGE) classes.push('max-w-4xl');
        if (this.blok.maxWidth === ComponentMaxWidth.EXTRA_LARGE)
          classes.push('max-w-5xl');
      }

      switch (this.blok.spacing) {
        case ComponentFlexSpacing.NONE:
          break;
        case ComponentFlexSpacing.SMALL:
          classes.push('gap-2');
          break;
        case ComponentFlexSpacing.MEDIUM:
          classes.push('gap-4');
          break;
        case ComponentFlexSpacing.LARGE:
          classes.push('gap-6');
          break;
      }

      switch (this.blok.padding) {
        case ComponentFlexSpacing.NONE:
          break;
        case ComponentFlexSpacing.SMALL:
          classes.push('p-2');
          break;
        case ComponentFlexSpacing.MEDIUM:
          classes.push('p-4');
          break;
        case ComponentFlexSpacing.LARGE:
          classes.push('p-6');
          break;
      }

      return classes;
    },

    sectionBackgroundColor() {
      const classes = [];
      if (
        backgroundColors[this.blok.backgroundColor] &&
        this.blok.backgroundColorEdgeToEdge
      ) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      }

      return classes;
    },

    calculatedColumnSpan() {
      const grid = 12;
      const columnsGridSpans = [];
      const columns = this.blok.columns;
      let span;
      let unknownColumns = 0;

      if (columns) {
        columns.forEach((el) => {
          if (el.gridColumnWidth) {
            columnsGridSpans.push(Number(el.gridColumnWidth));
          } else {
            ++unknownColumns;
            columnsGridSpans.push(0);
          }
        });

        const sumOfColSpans = columnsGridSpans.reduce((a, b) => a + b);
        const remainder = sumOfColSpans % grid;
        span =
          unknownColumns > 0 ? Math.round((grid - remainder) / unknownColumns) : span;
      }

      return span;
    },
  },
};
</script>
