<template>
  <div class="bg-white w-full h-full flex flex-col items-stretch">
    <div class="h-12 lg:h-16">
      <div
        id="navwrap"
        class="main-nav h-12 lg:h-16 bg-white shadow-sm fixed left-0 top-0 right-0 z-50"
      >
        <!--  Desktop Nav  -->
        <div
          class="main-nav-inner-wrap flex-row justify-between items-center px-6 h-12 lg:h-16 bg-white hidden lg:flex"
        >
          <nuxt-link :to="$localizedPath('/')">
            <img alt="Rendin" class="h-6 w-20" src="~/assets/rendin-logo.svg" />
          </nuxt-link>
          <!--   In session / Out of session buttons   -->
        </div>
        <!--  Mobile Nav  -->
        <div
          class="flex lg:hidden flex-row justify-between items-center px-6 h-12 relative"
        >
          <nuxt-link :to="$localizedPath('/')">
            <img alt="Rendin" class="h-6 w-20" src="~/assets/rendin-logo.svg" />
          </nuxt-link>
        </div>
      </div>
    </div>
    <div class="sm:pt-9 overflow-x-hidden flex-1 w-full">
      <div class="max-w-4xl mx-auto w-full p-4">
        <div class="sm:mt-12 flex flex-col">
          <div class="text-4xl text-center">{{ blok?.title }}</div>
          <div class="text-xl font-medium text-center mt-2 sm:mt-4 mb-6 sm:mb-12">
            {{ blok?.subtitle }}
          </div>
          <div class="text-sm text text-gray-600">{{ blok?.lastUpdated }}</div>
        </div>
        <div class="mb-6">
          <RichText v-if="blok.richIntroCalculator" :blok="blok.richIntroCalculator" />
        </div>
        <div class="bg-rendin-50 rounded-md border-1 overflow-hidden">
          <div>
            <div class="p-4 px-6 bg-rendin-400">
              <div class="text-sm uppercase text-white font-bold">
                {{ blok?.labelInputData }}
              </div>
            </div>
            <div
              class="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center p-4 px-6 sm:pr-10"
            >
              <div class="text-sm font-medium h-full">
                <div class="mt-5 -mb-3">
                  {{ blok?.labelMonthlyIncome }}
                </div>
              </div>
              <div>
                <div
                  v-for="(income, index) in monthlyIncome"
                  :key="index"
                  class="flex justify-between items-center relative"
                >
                  <r-input
                    v-model="income.rentAmount"
                    :add-on="currencyLabel"
                    class="flex-grow"
                    field-type="tel"
                    :focus-on-load="true"
                    inputmode="decimal"
                    label-class="text-xs sm:text-base"
                    name="rent"
                    :placeholder="$t('form.placeholder.rentAmount')"
                    required
                  />
                  <r-icon
                    v-if="index > 0"
                    class="text-xl text-gray-500 cursor-pointer hover:text-rendin-500 pt-1 absolute -right-4 sm:-right-6"
                    icon-name="xmark"
                    @click="removeAnotherProperty(index)"
                  />
                </div>
                <div
                  class="cursor-pointer text-rendin-500 text-sm font-medium py-2 text-right"
                  @click="onAddAnotherProperty"
                >
                  + {{ blok?.buttonAddAdditionalProperty }}
                </div>
              </div>
              <div>
                <div class="flex gap-4 items-center">
                  <div class="text-sm font-medium">
                    {{ blok?.labelTaxPaymentPeriod }}
                  </div>
                  <r-help-button>
                    <RichText
                      v-if="blok.helpRichTaxPaymentPeriod"
                      :blok="blok.helpRichTaxPaymentPeriod"
                    />
                  </r-help-button>
                </div>
              </div>
              <div
                class="flex bg-gray-50 rounded-full py-2 relative cursor-pointer"
                @click="toggleTaxPaymentPeriod"
              >
                <div
                  class="absolute top-0 w-1/2 h-full bg-white shadow-sm rounded-full transition-all"
                  :class="
                    taxPaymentPeriod === taxPaymentPeriods.MONTHLY
                      ? 'pill-right'
                      : 'left-0'
                  "
                ></div>
                <p
                  class="w-1/2 text-center z-10 transition-colors"
                  :class="{
                    'text-rendin-500 font-medium':
                      taxPaymentPeriod === taxPaymentPeriods.QUARTERLY,
                  }"
                >
                  {{ blok?.buttonPeriodQuarterly }}
                </p>
                <p
                  class="w-1/2 text-center z-10 transition-colors"
                  :class="{
                    'text-rendin-500 font-medium':
                      taxPaymentPeriod === taxPaymentPeriods.MONTHLY,
                  }"
                >
                  {{ blok?.buttonPeriodMonthly }}
                </p>
              </div>
              <div>
                <div class="flex gap-4 items-center">
                  <div class="text-sm font-medium">
                    {{ blok?.labelHasSpouse }}
                  </div>
                  <r-help-button>
                    <RichText
                      v-if="blok.helpRichHasSpouse"
                      :blok="blok.helpRichHasSpouse"
                    />
                  </r-help-button>
                </div>
              </div>
              <div
                class="flex bg-gray-50 rounded-full py-2 relative cursor-pointer"
                @click="toggleHasSpouse"
              >
                <div
                  class="absolute top-0 w-1/2 h-full bg-white shadow-sm rounded-full transition-all"
                  :class="hasSpouse ? 'pill-right' : 'left-0'"
                ></div>
                <p
                  class="w-1/2 text-center z-10 transition-colors first-letter:capitalize"
                  :class="{ 'text-rendin-500 font-medium': !hasSpouse }"
                >
                  {{ $t('no') }}
                </p>
                <p
                  class="w-1/2 text-center z-10 transition-colors first-letter:capitalize"
                  :class="{ 'text-rendin-500 font-medium': hasSpouse }"
                >
                  {{ $t('yes') }}
                </p>
              </div>
              <!--
              <div>
              </div>
              <div>
                <r-checkbox
                  id="includePropertyTax"
                  v-model="includePropertyTax"
                  name="includePropertyTax"
                >
                  <div class="text-sm font-medium">
                    <div class="flex gap-4 items-center">
                      <div>
                        {{ blok?.labelIncludeMunicipalPropertyTax }}
                      </div>
                      <r-help-button>
                        <RichText v-if="blok.helpRichMunicipalPropertyTax" :blok="blok.helpRichMunicipalPropertyTax" />
                      </r-help-button>
                    </div>
                  </div>
                </r-checkbox>
              </div> -->
              <!--            <r-button
                            v-if="taxCalculationComplete === false"
                            class="col-span-2"
                            @click="calculateTaxes"
                          >Calculate
                          </r-button>-->
            </div>
          </div>
        </div>
        <div
          class="purple-blue-50 rounded-md border-1 overflow-hidden flex flex-col gap-4 mt-4"
        >
          <div class="text-sm uppercase font-bold p-4 px-6 purple-blue-200">
            {{ blok?.labelResults }}
          </div>

          <div class="p-2 grid grid-cols-1 sm:grid-cols-2 gap-y-2 items-stretch">
            <p class="font-medium text-sm py-2 px-4">{{ blok?.labelYearlyIncome }}</p>
            <p class="px-4 sm:pl-0">
              {{
                yearlyIncome.toLocaleString(locale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }}
              zł
            </p>
            <p class="font-medium text-sm py-2 px-4">
              {{ blok?.labelLowerTaxBracketLimit }}
            </p>
            <p class="px-4 sm:pl-0">
              {{
                taxBracketCeiling.toLocaleString(locale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }}
              zł
            </p>
            <p class="font-medium text-sm purple-blue-100 py-2 px-4 flex items-center">
              {{ blok?.labelYearlyRentalTax }}
            </p>
            <p class="purple-blue-100 py-2 px-4 sm:pl-0 -mt-2 sm:mt-0">
              {{
                displayedYearlyTaxes.toLocaleString(locale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }}
              zł
            </p>
            <p class="font-medium text-sm py-2 px-4">
              {{ blok?.labelTaxOfficeObligations }}
            </p>
            <div class="grid grid-cols-2 items-center text-sm py-2 px-4 sm:pl-0">
              <template v-if="taxPaymentPeriod === taxPaymentPeriods.MONTHLY">
                <template v-for="month in paymentPeriodMonthlyDueDates" :key="month">
                  <div>{{ month }}</div>
                  <p class="text-right sm:text-left">
                    {{
                      taxPaidMonthly.toLocaleString(locale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}
                    zł
                  </p>
                </template>
              </template>
              <template v-else-if="taxPaymentPeriod === taxPaymentPeriods.QUARTERLY">
                <template
                  v-for="quarter in paymentPeriodQuarterlyDueDates"
                  :key="quarter"
                >
                  <div>{{ quarter }}</div>
                  <p class="text-right sm:text-left">
                    {{
                      taxPaidQuarterly.toLocaleString(locale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}
                    zł
                  </p>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="text-xs my-2">
          <RichText
            v-if="blok.richDisclaimerCalculator"
            :blok="blok.richDisclaimerCalculator"
          />
        </div>
        <div
          class="purple-blue-50 rounded-md border-1 overflow-hidden flex flex-col gap-4 mt-4"
        >
          <div class="p-2 grid grid-cols-1 sm:grid-cols-2 gap-y-2 items-stretch">
            <p class="font-medium text-sm py-2 px-4">{{ blok.feedbackTitle }}</p>
            <p
              class="cursor-pointer text-rendin-500 text-sm font-medium py-2 px-4 sm:pl-0 text-right"
              @click="onOpenFeedbackModal"
            >
              {{ blok.feedbackButtonTitle }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="blok.content" class="my-12 w-full flex flex-col gap-6">
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.content.filter((item) =>
            ['RichTextBlok', 'FeaturesSection'].includes(item.component),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
          :class="
            theBlok.component === 'RichTextBlok' ? 'mx-auto max-w-4xl p-4' : 'w-full'
          "
        />
      </div>
    </div>
    <WidgetTrigger v-if="blokFeedbackTrigger" :blok="blokFeedbackTrigger" />
    <FeedbackModal v-if="showFeedbackWidget" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RichText from '~/components/snippets/RichText.vue';
import RHelpButton from '~/components/r-interface-components/r-help-button.vue';
import RichTextBlok from '~/components/snippets/RichTextBlok.vue';
import WidgetTrigger from '~/components/snippets/WidgetTrigger.vue';

const FeedbackModal = () =>
  Promise.all([import('~/components/modals/FeedbackModal.vue')]).then(
    ([FeedbackModal]) => FeedbackModal.default,
  );

const taxPaymentPeriods = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
};

export default {
  name: 'Taxes',
  components: {
    RHelpButton,
    //  RCheckbox,
    RichTextBlok,
    RichText,
    WidgetTrigger,
    FeedbackModal,
    // RButton,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      monthlyIncome: [
        {
          rentAmount: 6000,
        },
      ],
      taxCalculationComplete: false,
      hasSpouse: false,
      taxPaymentPeriods: taxPaymentPeriods,
      taxPaymentPeriod: taxPaymentPeriods.MONTHLY,
      months: [
        'JANUARY',
        'FEBRUARY',
        'MARCH',
        'APRIL',
        'MAY',
        'JUNE',
        'JULY',
        'AUGUST',
        'SEPTEMBER',
        'OCTOBER',
        'NOVEMBER',
        'DECEMBER',
      ],
      quarterPaymentDates: [
        'APRIL 20TH',
        'JULY 20TH',
        'OCTOBER 20TH',
        'END OF JANUARY',
      ],
      includePropertyTax: false,
    };
  },
  computed: {
    ...mapGetters({
      currentSessionStep: 'session/currentStep',
      showFeedbackWidget: 'showFeedbackWidget',
    }),
    blokFeedbackTrigger() {
      return this.blok?.widgetTrigger?.[0];
    },
    paymentPeriodMonthlyDueDates() {
      if (!this.blok) return [];

      const date = this.blok;
      return [
        date.paymentJanuary,
        date.paymentFebruary,
        date.paymentMarch,
        date.paymentApril,
        date.paymentJune,
        date.paymentJuly,
        date.paymentAugust,
        date.paymentSeptember,
        date.paymentOctober,
        date.paymentNovember,
        date.paymentDecember,
      ];
    },
    paymentPeriodQuarterlyDueDates() {
      if (!this.blok) return [];

      const date = this.blok;
      return [
        date.paymentJanuary,
        date.paymentApril,
        date.paymentJuly,
        date.paymentOctober,
      ];
    },
    locale() {
      return 'pl';
    },
    currencyLabel() {
      return 'zł';
    },
    yearlyIncome() {
      const sumOfMonthlyIncomes = this.monthlyIncome.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.rentAmount),
        0,
      );

      return sumOfMonthlyIncomes * 12;
    },
    taxBracketCeiling() {
      return this.hasSpouse ? 200000 : 100000;
    },
    rawYearlyTaxesWithoutRounding() {
      const amountOfIncomeTaxedLower = Math.min(
        this.yearlyIncome,
        this.taxBracketCeiling,
      );
      const amountOfIncomeTaxedHigher = Math.max(
        this.yearlyIncome - this.taxBracketCeiling,
        0,
      );

      const lowerTaxRate = 0.085; // 8.5% in lower bracket
      const higherTaxRate = 0.125; // 12.5% in higher bracket

      const taxesFromLowerBracket = lowerTaxRate * amountOfIncomeTaxedLower;
      const taxesFromHigherBracket = higherTaxRate * amountOfIncomeTaxedHigher;

      return taxesFromHigherBracket + taxesFromLowerBracket;
    },
    displayedYearlyTaxes() {
      if (this.taxPaymentPeriod === taxPaymentPeriods.MONTHLY) {
        return this.taxPaidMonthly * 12;
      } else if (this.taxPaymentPeriod === taxPaymentPeriods.QUARTERLY) {
        return this.taxPaidQuarterly * 4;
      }

      return '';
    },
    taxPaidMonthly() {
      return Math.round(this.rawYearlyTaxesWithoutRounding / 12);
    },
    taxPaidQuarterly() {
      return Math.round(this.rawYearlyTaxesWithoutRounding / 4);
    },
  },
  methods: {
    ...mapActions({
      toggleFeedbackWidgetModal: 'toggleFeedbackWidgetModal',
    }),
    onAddAnotherProperty() {
      this.monthlyIncome.push({
        rentAmount: 0,
      });
    },
    removeAnotherProperty(index) {
      this.monthlyIncome.splice(index, 1);
    },
    toggleHasSpouse() {
      this.hasSpouse = !this.hasSpouse;
    },
    toggleTaxPaymentPeriod() {
      this.taxPaymentPeriod =
        this.taxPaymentPeriod === taxPaymentPeriods.MONTHLY
          ? taxPaymentPeriods.QUARTERLY
          : taxPaymentPeriods.MONTHLY;
    },
    onOpenFeedbackModal() {
      this.toggleFeedbackWidgetModal(true);
    },
  },
};
</script>
<style lang="css" scoped>
.component-fade-enter-active {
  -webkit-animation-name: customFadeInRight;
  animation-name: customFadeInRight;
  animation-duration: 300ms;
}

.component-fade-leave-active {
  -webkit-animation-name: customFadeOutLeft;
  animation-name: customFadeOutLeft;
  animation-duration: 300ms;
}

@keyframes customFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes customFadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
}
</style>
<style scoped>
.pill-right {
  left: 50%;
}

.purple-blue-200 {
  background-color: #b3b8db;
}

.purple-blue-100 {
  background-color: #eaecf5;
}

.purple-blue-50 {
  background-color: #f8f9fc;
}
</style>
