<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="comparison-table-universal"
    :class="[
      blok.withoutSectionPadding ? '' : 'py-10  px-4 md:py-20 bg-gray-50',
      blok.style === style.PURPLE
        ? 'comparison-table--purple'
        : 'comparison-table--orange',
    ]"
  >
    <div class="max-w-5xl mx-auto">
      <div v-if="blok.title" class="w-11/12 md:w-3/4 mx-auto text-center mb-10">
        <h2 class="text-2xl md:text-4xl leading-tight font-bold">
          {{ blok.title }}
        </h2>
      </div>

      <!--      Table -->
      <div
        class="comparison-table overflow-x-auto rounded-2xl"
        :class="{
          'comparison-table--row-collapse': blok.tableMobileStyle === 'rowCollapse',
          'comparison-table--first-col-sticked':
            blok.tableMobileStyle === 'firstColumnSticked',
        }"
      >
        <table ref="table" class="table-fixed w-full relative">
          <tbody class="relative11">
            <!--      Table row  for sticky -->
            <tr
              v-for="(row, rowIndex) in blok.table"
              :key="row._uid"
              class="comparison-table__row grid"
              :class="{
                'comparison-table__row__cols-2': blok.numberOfColumns === '2',
                'comparison-table__row__cols-3': blok.numberOfColumns === '3',
                'comparison-table__row__cols-4': blok.numberOfColumns === '4',
              }"
            >
              <!--      Table row header cell - th-->
              <th
                v-for="(cell, cellIndex) in row.cell.slice(0, 1)"
                :key="cell._uid"
                class="comparison-table__row__cell comparison-table__row__cell--header text-sm lg:text-base font-normal flex flex-col items-center justify-center p-4"
                :class="{
                  'comparison-table__row__cell--sticky':
                    blok.tableMobileStyle === 'firstColumnSticked',
                  'col-span-full md:col-auto': blok.tableMobileStyle === 'rowCollapse',
                  'comparison-table__row__cell--white':
                    cell.cellBackgroundColor === 'white',
                  'comparison-table__row__cell--light-gray':
                    cell.cellBackgroundColor === 'lightGray',
                  'comparison-table__row__cell--active--first':
                    Number(blok.activeColumn) === cellIndex + 1,
                }"
              >
                <div
                  class="w-full flex items-center"
                  :class="{
                    'justify-center md:justify-between':
                      blok.tableMobileStyle === 'rowCollapse',
                    'justify-between':
                      blok.tableMobileStyle !== 'rowCollapse' && cell.showInfoModal,
                  }"
                >
                  <div class="flex flex-col">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in cell.cell.filter((item) =>
                        ['RichTextBlok', 'ButtonBlok', 'IconBlok'].includes(
                          item.component,
                        ),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                      :class="[theBlok.component === 'RichTextBlok' ? '-my-4' : '']"
                    />
                  </div>
                  <!--          Info modal icon-->
                  <span v-if="cell.showInfoModal">
                    <r-icon
                      class="text-xl text-rendin-200 hover:text-rendin-400 focus:text-rendin-400 ml-2 cursor-pointer"
                      icon-name="circle-question"
                      @click="[(showOverlay = true), (activeModal = cell._uid)]"
                    />
                  </span>
                </div>
              </th>

              <!--      Table row cell - td -->
              <td
                v-for="(cell, cellIndex) in row.cell.slice(1, blok.numberOfColumns)"
                :key="cell._uid"
                class="comparison-table__row__cell text-sm md:text-base flex flex-col items-center justify-center p-4"
                :class="{
                  'comparison-table__row__cell--white':
                    cell.cellBackgroundColor === 'white',
                  'comparison-table__row__cell--light-gray':
                    cell.cellBackgroundColor === 'lightGray',
                  'comparison-table__row__cell--active--first':
                    Number(blok.activeColumn) === cellIndex + 2 && rowIndex === 0,
                  'comparison-table__row__cell--active':
                    Number(blok.activeColumn) === cellIndex + 2,
                }"
              >
                <div
                  class="w-full flex items-center"
                  :class="{
                    'justify-center': !cell.showInfoModal,
                    'justify-between': cell.showInfoModal,
                  }"
                >
                  <div class="w-full flex flex-col">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in cell.cell.filter((item) =>
                        ['RichTextBlok', 'ButtonBlok', 'IconBlok'].includes(
                          item.component,
                        ),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                      :class="[theBlok.component === 'RichTextBlok' ? '-my-4' : '']"
                    />
                  </div>
                  <!--          Info modal icon-->
                  <span v-if="cell.showInfoModal">
                    <r-icon
                      class="text-xl text-rendin-200 hover:text-rendin-400 focus:text-rendin-400 ml-2 cursor-pointer"
                      icon-name="circle-question"
                      @click="[(showOverlay = true), (activeModal = cell._uid)]"
                    />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>

          <!--   Sticky head for mobile, separately rendered to avoid height change and flickering-->
          <div
            v-if="stickyTableHead && blok.tableMobileStyle === 'rowCollapse'"
            ref="sticky"
            class="comparison-table--head-sticky fixed md:hidden md:w-full shadow rounded-tr-lg rounded-tl-lg"
          >
            <div
              v-for="row in blok.table.slice(0, 1)"
              :key="row._uid"
              class="comparison-table__row grid"
              :class="{
                'comparison-table__row__cols-2': blok.numberOfColumns === '2',
                'comparison-table__row__cols-3': blok.numberOfColumns === '3',
                'comparison-table__row__cols-4': blok.numberOfColumns === '4',
              }"
            >
              <div
                v-for="(cell, cellIndex) in row.cell.slice(1, blok.numberOfColumns)"
                :key="cell._uid"
                class="comparison-table__row__cell text-sm md:text-base flex flex-col items-center justify-center p-4"
                :class="{
                  'comparison-table__row__cell--white':
                    cell.cellBackgroundColor === 'white',
                  'comparison-table__row__cell--light-gray':
                    cell.cellBackgroundColor === 'lightGray',
                  'comparison-table__row__cell--active--first':
                    Number(blok.activeColumn) === cellIndex + 2,
                }"
              >
                <div
                  class="w-full flex items-center"
                  :class="{
                    'justify-center': !cell.showInfoModal,
                    'justify-between': cell.showInfoModal,
                  }"
                >
                  <div class="w-full flex flex-col">
                    <component
                      :is="theBlok.component"
                      v-for="theBlok in cell.cell.filter((item) =>
                        ['RichTextBlok', 'ButtonBlok', 'IconBlok'].includes(
                          item.component,
                        ),
                      )"
                      :key="theBlok._uid"
                      :blok="theBlok"
                      :class="[theBlok.component === 'RichTextBlok' ? '-my-4' : '']"
                    />
                  </div>
                  <!--          Info modal icon-->
                  <span v-if="cell.showInfoModal">
                    <r-icon
                      class="text-xl text-rendin-200 hover:text-rendin-400 focus:text-rendin-400 ml-2 cursor-pointer"
                      icon-name="circle-question"
                      @click="[(showOverlay = true), (activeModal = cell._uid)]"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </table>
      </div>
      <div v-if="blok.footer?.length" class="flex flex-col items-center mt-8">
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.footer.filter((item) =>
            ['RichTextBlok', 'ButtonBlok'].includes(item.component),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
        />
      </div>

      <!--    Info modal-->
      <r-overlay size="small" :visible="showOverlay" @close="showOverlay = false">
        <div class="p-4 pt-8 md:pt-10 md:pb-4 md:px-10">
          <div v-for="cell in modalContent" :key="cell._uid">
            <RichText
              v-show="activeModal === cell._uid"
              :blok="cell.modalContent"
              class="my-8"
            />
          </div>

          <r-button
            display="block"
            :label="$t('buttons.close')"
            no-background
            uppercase
            @click="showOverlay = false"
          />
        </div>
      </r-overlay>
    </div>
  </section>
</template>

<script>
const style = {
  ORANGE: 'orange',
  PURPLE: 'purple',
};

export default {
  name: 'ComparisonTableUniversal',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
      activeModal: null,
      stickyTableHead: false,
      style,
    };
  },

  computed: {
    modalContent() {
      const content = [];

      this.blok.table.forEach((row) => {
        row.cell.forEach((cell) => {
          if (cell.showInfoModal && cell.modalContent) {
            content.push({
              _uid: cell._uid,
              modalContent: cell.modalContent,
            });
          }
        });
      });

      return content;
    },
  },
  mounted() {
    if (import.meta.browser) {
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll();
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (this.blok.tableMobileStyle === 'rowCollapse') {
        // mobile sticky menu fixed height
        const navHeight = document.getElementById('navwrap')
          ? document.getElementById('navwrap').offsetHeight
          : 0;
        this.stickyTableHead =
          window.pageYOffset + navHeight > this.$refs.table.offsetTop &&
          window.pageYOffset + navHeight <
            this.$refs.table.offsetTop + this.$refs.table.offsetHeight;
        const sticky = this.$refs.sticky;

        if (this.stickyTableHead && sticky) {
          sticky.style.top = `${navHeight}px`;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.comparison-table {
  .comparison-table__row__cols-2 .comparison-table__row__cell--active--first::after {
    @apply w-1/2;
  }

  .comparison-table__row__cols-3 .comparison-table__row__cell--active--first::after {
    @apply w-1/3;
  }

  .comparison-table__row__cols-4 .comparison-table__row__cell--active--first::after {
    @apply w-1/4;
  }

  &--purple {
    @apply bg-violet-25;

    .comparison-table__row__cell--active--first {
      &::after {
        content: '';
        border: 2px solid #764ba2;
        box-shadow: 0px 12px 64px -12px rgba(89, 37, 220, 0.27);

        @apply absolute h-full block top-0 border-2 rounded-2xl;
      }
    }

    .comparison-table__row__cell--active {
      @media screen and (max-width: 768px) {
        @apply bg-gray-50;
      }
    }
  }

  &--orange {
    @apply bg-white;

    .comparison-table__row__cell--active--first {
      &::after {
        content: '';
        @apply absolute h-full block top-0 border-2 rounded-2xl border-rendin-500 shadow-lg;
      }
    }

    .comparison-table__row__cell--active {
      @media screen and (max-width: 768px) {
        @apply bg-rendin-50 font-semibold;
      }
    }
  }

  &__row {
    &__cols-2 {
      @apply grid-cols-2;
    }

    &__cols-3 {
      @apply grid-cols-3;
    }

    &__cols-4 {
      @apply grid-cols-4;
    }

    &__cell {
      @apply bg-white md:bg-transparent;

      .comparison-table--purple &--header {
        @apply bg-blue-50 md:bg-transparent;
      }

      .comparison-table--purple &--light-gray {
        @apply md:bg-blue-25;
      }

      .comparison-table--purple &--white {
        @apply md:bg-white;
      }

      .comparison-table--orange &--header {
        @apply bg-gray-100 md:bg-transparent;
      }

      .comparison-table--orange &--light-gray {
        @apply md:bg-gray-100;
      }

      .comparison-table--purple &--white {
        @apply md:bg-white;
      }

      //To align images same way as text
      img {
        display: inline-block;
      }
    }

    .comparison-table--orange &:not(:last-child) {
      .comparison-table__row__cell {
        @apply border-b;
        border-bottom-color: #e5e7eb;
      }
    }
  }

  &.comparison-table--first-col-sticked {
    .comparison-table__row {
      &__cols-2 {
        min-width: 300px;
      }

      &__cols-3 {
        min-width: 450px;
      }

      &__cols-4 {
        min-width: 600px;
      }

      &__cell--sticky:first-child {
        @apply sticky left-0;
        z-index: 11;
      }

      .comparison-table--purple & {
        .comparison-table__row__cell--white {
          @apply bg-transparent md:bg-white;
        }

        .comparison-table__row__cell--light-gray {
          @apply bg-transparent md:bg-blue-25;
        }
      }

      .comparison-table__row__cell--active--first::after {
        @media screen and (max-width: 768px) {
          content: none;
        }
      }
    }
  }

  &.comparison-table--row-collapse {
    .comparison-table__row {
      &__cols-2 {
        @apply grid-cols-2;
      }

      &__cols-3 {
        @apply grid-cols-2 md:grid-cols-3;
      }

      &__cols-4 {
        @apply grid-cols-3 md:grid-cols-4;
      }

      .comparison-table__row__cell {
        .comparison-table--orange &--header {
          @apply border-b-0  md:border-b;
        }
      }

      &:first-child {
        .comparison-table__row__cell--header {
          @apply hidden md:block;
        }
      }

      &:last-child {
        .comparison-table__row__cell--header {
          @apply border-b-0;
        }
      }

      .comparison-table__row__cell--active--first::after {
        @media screen and (max-width: 768px) {
          content: none;
        }
      }
    }

    .comparison-table--head-sticky {
      @media screen and (max-width: 767px) {
        width: calc(100% - 32px);
        z-index: 11;
      }
    }
  }
}
</style>
