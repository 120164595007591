<template>
  <r-overlay
    :bottom-bar="showModal"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <section class="p-4 md:px-8">
      <div
        class="bg-amber-100 w-12 h-12 rounded-full flex justify-center items-center mb-4"
      >
        <r-icon class="text-rendin-300 text-2xl" icon-name="circle-info" />
      </div>

      <div>
        <h2 class="text-lg font-semibold my-2">
          {{ $t('remove_listing_modal.title') }}
        </h2>

        <div class="text-gray-600">
          {{ $t('remove_listing_modal.disclaimer') }}
        </div>
      </div>
    </section>

    <div class="p-4 w-full flex gap-4 items-center justify-center">
      <r-button
        border-color="light-gray"
        color="black"
        inverted
        :label="$t('buttons.cancel')"
        width="full"
        @click="closeOverlay()"
      />
      <r-button
        :label="$t('remove_listing_modal.button')"
        width="full"
        @click="onClickRemoveListing()"
      />
    </div>
  </r-overlay>
</template>
<script>
export default {
  name: 'RemoveListingModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay', 'clickRemoveListing'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },

    onClickRemoveListing() {
      this.$emit('clickRemoveListing');
      this.closeOverlay();
    },
  },
};
</script>
