import {
  censorshipTriggersKeys,
  servicesAndUtilitiesPartyType,
} from '~/utils/constants.js';

export function defaultAddressObject(addressOverride) {
  return {
    addressFirstLine: addressOverride?.addressFirstLine ?? null,
    addressCity: addressOverride?.addressCity ?? null,
    addressStreet: addressOverride?.addressStreet ?? null,
    addressCountry: addressOverride?.addressCountry ?? null,
    addressIndex: addressOverride?.addressIndex ?? null,
    addressHouseNumber: addressOverride?.addressHouseNumber ?? null,
    addressHouseNumberHidden: addressOverride?.addressHouseNumberHidden ?? false,
    addressApartmentNumber: addressOverride?.addressApartmentNumber ?? null,
    addressCityDistrict: addressOverride?.addressCityDistrict ?? null,
    hasApartmentNumber: addressOverride?.hasApartmentNumber ?? true,
    internalUserEditedAddressManually:
      addressOverride?.internalUserEditedAddressManually ?? false,
    addressCoordinateLatitude: addressOverride?.addressCoordinateLatitude ?? null,
    addressCoordinateLongitude: addressOverride?.addressCoordinateLongitude ?? null,
    addressCoordinatesSource: addressOverride?.addressCoordinatesSource ?? null,
    addressCoordinatesPlaceId: addressOverride?.addressCoordinatesPlaceId ?? null,
  };
}

export function getLocationFromDefaultAddressObject(
  defaultAddressObject,
  userEditedAddressManually,
) {
  const latitude = defaultAddressObject?.addressCoordinateLatitude;
  const longitude = defaultAddressObject?.addressCoordinateLongitude;
  const placeId = defaultAddressObject?.addressCoordinatesPlaceId;
  const source = defaultAddressObject?.addressCoordinatesSource;
  // We expect all or nothing.
  if (latitude && longitude && placeId && source) {
    return {
      coordinates: {
        latitude: latitude,
        longitude: longitude,
      },
      source: source,
      placeId: placeId,
      accuracy: {
        addressParametersEditedManually: userEditedAddressManually,
      },
    };
  }
}

export function defaultEstoniaServicesAndUtilities() {
  return {
    HEATING: {
      typeName: 'HEATING',
      type: 'HEATING',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    GAS: {
      typeName: 'GAS',
      type: 'GAS',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    GENERAL_ELECTRICITY: {
      typeName: 'General Electricity',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
      type: 'GENERAL_ELECTRICITY',
    },
    ELECTRICITY: {
      typeName: 'ELECTRICITY',
      type: 'ELECTRICITY',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    GENERAL_WATER: {
      typeName: 'GENERAL_WATER',
      type: 'GENERAL_WATER',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    WATER: {
      typeName: 'WATER',
      type: 'WATER',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    WATER_HEATING: {
      typeName: 'WATER_HEATING',
      type: 'WATER_HEATING',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    TRASH: {
      typeName: 'TRASH',
      type: 'TRASH',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    COMMUNICATION_SERVICE: {
      typeName: 'COMMUNICATION_SERVICE',
      type: 'COMMUNICATION_SERVICE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    MANAGEMENT_FEE: {
      typeName: 'MANAGEMENT_FEE',
      type: 'MANAGEMENT_FEE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    MAINTENANCE_FEE: {
      typeName: 'MAINTENANCE_FEE',
      type: 'MAINTENANCE_FEE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    REPAIR_FUND: {
      typeName: 'REPAIR_FUND',
      type: 'REPAIR_FUND',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    HOUSE_INSURANCE: {
      typeName: 'HOUSE_INSURANCE',
      type: 'HOUSE_INSURANCE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
  };
}

export function defaultPolandServicesAndUtilities() {
  return {
    ELECTRICITY: {
      typeName: 'ELECTRICITY',
      type: 'ELECTRICITY',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    CLEANING: {
      typeName: 'CLEANING',
      type: 'CLEANING',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    GAS: {
      typeName: 'GAS',
      type: 'GAS',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    INSURANCE: {
      typeName: 'INSURANCE',
      type: 'INSURANCE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    INTERNET: {
      typeName: 'INTERNET',
      type: 'INTERNET',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    CABLE_TV: {
      typeName: 'CABLE_TV',
      type: 'CABLE_TV',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
  };
}

export function fullListOfServicesAndUtilities() {
  return {
    ...defaultEstoniaServicesAndUtilities(),
    ...defaultPolandServicesAndUtilities(),
    COLD_WATER: {
      typeName: 'COLD_WATER',
      type: 'COLD_WATER',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    HOT_WATER: {
      typeName: 'HOT_WATER',
      type: 'HOT_WATER',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    CLEANING: {
      typeName: 'CLEANING',
      type: 'CLEANING',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    SECURITY_SERVICES: {
      typeName: 'SECURITY_SERVICES',
      type: 'SECURITY_SERVICES',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    INSURANCE: {
      typeName: 'INSURANCE',
      type: 'INSURANCE',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
    LOAN_REPAYMENT: {
      typeName: 'LOAN_REPAYMENT',
      type: 'LOAN_REPAYMENT',
      whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
    },
  };
}

export function defaultServicesAndUtilitiesOtherObject() {
  return {
    typeName: 'Custom service',
    type: 'OTHER',
    whoIsPaying: servicesAndUtilitiesPartyType.TENANT,
  };
}

export function payerRoleTabsOptions(titleForTenantTab, titleForLandlordTab) {
  return [
    {
      id: 'TENANT',
      value: 'TENANT',
      title: titleForTenantTab,
      label: titleForTenantTab,
    },
    {
      id: 'LANDLORD',
      value: 'LANDLORD',
      title: titleForLandlordTab,
      label: titleForLandlordTab,
    },
  ];
}

export function paymentTypeTabsOptions(titleForDynamicTab, titleForFixedTab) {
  return [
    {
      id: 'DYNAMIC',
      title: titleForDynamicTab,
    },
    {
      id: 'FIXED',
      title: titleForFixedTab,
    },
  ];
}

export function depositRegexArray(errorMessage) {
  return [
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /\b(d\s*e\s*p\s*o\s*s\s*i\s*[td])\b/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /d\s*o\s*w\s*n\s*\s*p\s*a\s*y\s*m\s*e\s*n\s*t/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /\b(d\s*[eе]\s*p\s*[oо]\s*s\s*i\s*i\s*[td])\b/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /\b(t\s*a\s*g\s*a\s*t\s*i\s*s)\b/i,
    },
    // eng and rus letters mix
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /д\s*[еe]\s*п\s*[оo]\s*з\s*и\s*т/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /з\s*а\s*л\s*о\s*г/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /п\s*р\s*е\s*д\s*о\s*п\s*л\s*а\s*т/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /з\s*а\s*л\s*о\s*г/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /k\s*a\s*u\s*c\s*j/i,
    },
    {
      key: censorshipTriggersKeys.DEPOSIT,
      errorMessage: errorMessage,
      regex: /d\s*e\s*p\s*o\s*z\s*y\s*t/i,
    },
  ];
}

export function shortTermRegexArray(errorMessage) {
  return [
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /к\s*р\s*а\s*т\s*к\s*о\s*с\s*р\s*о\s*ч\s*н/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /н\s*а\s*\s*с\s*р\s*о\s*к\s*\s*д\s*о/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /l\s*[üy]\s*h\s*i\s*a\s*j\s*a\s*l\s*i/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /l\s*[üy]\s*h\s*i\s*a\s*j\s*a\s*l\s*i\s*n\s*e/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /l\s*ü\s*h\s*i\s*a\s*j\s*a\s*l\s*i\s*s\s*e\s*k\s*s/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /p\s*e\s*r\s*i\s*o\s*o\s*d/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /t\s*[ä2]\s*h\s*t\s*a\s*j\s*a\s*l\s*i/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /s\s*h\s*o\s*r\s*t\s*-\s*t\s*e\s*r\s*m/i,
    },
    {
      key: censorshipTriggersKeys.SHORT_TERM,
      errorMessage: errorMessage,
      regex: /p\s*e\s*r\s*i\s*o\s*d/i,
    },
  ];
}

export function defaultListingPreferences() {
  return {
    city: '',
    districts: [],
    propertyTypes: [],
    roomsMin: null,
    roomsMax: null,
    priceMin: null,
    priceMax: null,
    areaMin: null,
    areaMax: null,
    floorMin: null,
    floorMax: null,
    hasStorage: false,
    hasParking: false,
    petsAllowed: false,
  };
}

export function defaultRoomsRangeSelectData() {
  return [
    {
      value: 1,
      title: '1',
    },
    {
      value: 2,
      title: '2',
    },
    {
      value: 3,
      title: '3',
    },
    {
      value: 4,
      title: '4',
    },
    {
      value: 5,
      title: '5+',
    },
  ];
}
