<template>
  <div class="h-screen flex items-center justify-center p-4">
    <div class="md:max-w-screen-sm">
      <div
        v-if="!unsubscribeFailed && !unsubscribeSuccess && getSubscriptionSuccess"
        class="flex flex-col gap-4"
      >
        <p class="text-3xl font-semibold">
          {{ $t('listing_subscription.unsubscribe_title') }}
        </p>
        <p>
          <span>{{ $t('listing_subscription.unsubscribe_email') + ': ' }}</span>
          <span class="font-semibold">{{ email }}</span>
        </p>
        <p>
          {{ $t('listing_subscription.unsubscribe_content') }}
        </p>
        <client-only>
          <r-radio
            v-model="feedbackValue"
            :label="$t('unsubscribe_feedback.please_tell_us')"
            name="radio-feedback-unsubscribe"
            option-label="label"
            :options="radioOptions"
          />
        </client-only>
        <div>
          <r-button
            :is-loading="unsubscribeInProgress"
            :label="$t('buttons.unsubscribe')"
            size="small"
            @click="unsubscribeListing"
          />
        </div>
      </div>
      <div v-else-if="unsubscribeSuccess" class="flex flex-col gap-4">
        <p class="text-3xl font-semibold">
          {{ $t('listing_subscription.unsubscribe_success_title') }}
        </p>
        <p>
          {{ $t('listing_subscription.unsubscribe_success_content') }}
        </p>
        <p class="font-semibold">
          {{ $t('listing_subscription.explore_more') }}
        </p>
        <p>
          {{ $t('listing_subscription.we_have_rental_homes') }}
        </p>
        <div class="pt-4">
          <r-button
            :label="$t('buttons.browse_rental_homes')"
            size="small"
            :to="$localizedPath(redirectLinks.FIND_HOME)"
          />
        </div>
      </div>
      <div v-else-if="unsubscribeFailed" class="flex flex-col gap-4">
        <p class="text-3xl font-semibold">
          {{ $t('listing_subscription.unsubscribe_failed_title') }}
        </p>
        <p v-if="getSubscriptionSuccess">
          {{ $t('listing_subscription.unsubscribe_success_content') }}
        </p>
        <p>
          {{ $t('listing_subscription.please_try_again_later') }}
        </p>
      </div>
      <div v-else>
        <r-loader type="animatedLogo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { redirectLinks } from '~/utils/constants';
import { listingSubscription } from '~/utils/trackerConstants';

export default {
  name: 'ListingUnsubscribe',

  data() {
    return {
      unsubscribeSuccess: false,
      unsubscribeFailed: false,
      getSubscriptionSuccess: false,
      unsubscribeInProgress: false,
      subscriptionToken: null,
      redirectLinks: redirectLinks,
      feedbackValue: '',
      email: '',
      radioOptions: [
        {
          value: 'FOUND_PLACE',
          label: this.$t('unsubscribe_feedback.found_place'),
        },
        {
          value: 'NO_MORE_LOOKING_RENTAL',
          label: this.$t('unsubscribe_feedback.no_more_looking_rental'),
        },
        {
          value: 'TOO_MANY_EMAILS',
          label: this.$t('unsubscribe_feedback.too_many_email'),
        },
        {
          value: 'NO_RELEVANT',
          label: this.$t('unsubscribe_feedback.not_relevant'),
        },
        {
          value: 'CHANGE_FILTER',
          label: this.$t('unsubscribe_feedback.change_filter'),
        },
      ],
    };
  },

  mounted() {
    if (this.$route.query?.subscription) {
      this.subscriptionToken = this.$route.query.subscription;
      this.getAdvertisementSubscription()
        .then((response) => {
          if (response.response.success) {
            this.email = response.response.data.email;
            this.getSubscriptionSuccess = true;
          } else {
            this.unsubscribeFailed = true;
          }
        })
        .catch((error) => {
          this.actionReportErrorToSentry(error);
          this.unsubscribeFailed = true;
        });
    } else {
      this.unsubscribeFailed = true;
    }
  },

  methods: {
    ...mapActions({
      actionDeleteAdvertisementSubscription:
        'advertisements/deleteAdvertisementSubscription',
      actionGetAdvertisementSubscription: 'advertisements/getAdvertisementSubscription',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackListingSubscriptionTrigger: 'tracker/trackListingSubscriptionTrigger',
    }),

    deleteAdvertisementSubscription() {
      return this.actionDeleteAdvertisementSubscription({
        subscriptionToken: this.subscriptionToken,
      });
    },

    getAdvertisementSubscription() {
      return this.actionGetAdvertisementSubscription({
        subscriptionToken: this.subscriptionToken,
      });
    },

    unsubscribeListing() {
      if (this.unsubscribeInProgress) return;
      this.unsubscribeInProgress = true;
      this.deleteAdvertisementSubscription()
        .then((response) => {
          if (response.response.success) {
            this.unsubscribeSuccess = true;
          } else {
            this.unsubscribeFailed = true;
          }
        })
        .then(() => {
          this.actionTrackListingSubscriptionTrigger({
            eventName: listingSubscription.UNSUBSCRIBE,
            props: {
              feedback: this.feedbackValue,
            },
          });
        })
        .catch((error) => this.actionReportErrorToSentry(error))
        .finally(() => {
          this.unsubscribeInProgress = false;
        });
    },
  },
};
</script>
