<template>
  <div
    class="article-hero-section w-full px-4 py-8 md:py-20 md:mb-10 max-w-7xl mx-auto relative md:flex flex-col"
    :class="{ 'md:mt-10': !noTopPadding }"
  >
    <div class="relative z-10 w-full md:w-2/3">
      <h1 v-if="title" class="text-gray-900 font-semibold text-4xl md:text-5xl">
        <span
          v-if="typeof title === 'string'"
          class="article-hero-section__title"
          v-html="title"
        ></span>
        <RichText
          v-else-if="typeof title === 'object'"
          :blok="title"
          class="article-hero-section__rich-title"
          plain
        />
      </h1>

      <div class="md:w-2/3 md:pr-4">
        <div v-if="intro" class="text-lg text-gray-600 md:text-xl my-6 md:my-12">
          <div v-if="typeof intro === 'string'" v-html="intro"></div>
          <RichText v-else-if="typeof intro === 'object'" :blok="intro" />
        </div>
      </div>
      <div v-if="authorName" class="text-gray-900 font-semibold md:text-lg">
        {{ authorName }}
      </div>
      <div v-if="publishedDate">
        <div class="date-created text-gray-600">
          {{ $t('published_date') }} {{ formattedDate }}
        </div>
        <div
          v-if="articleOutdated && !hideOldContentWarning"
          class="bg-gray-50 border border-gray-200 py-1 px-2 rounded-lg inline-block text-xs font-medium text-gray-700 my-4"
        >
          {{ $t('article_outdated') }}
        </div>
      </div>
    </div>

    <!--        Mobile image-->
    <div class="md:hidden mt-12">
      <img
        v-if="image"
        :alt="title"
        :src="$transformStoryblokImage(image, '/m/740x390/smart')"
      />
    </div>
    <!--        Desktop image-->
    <div
      class="article-hero-section__image--desktop hidden md:block absolute top-0 bottom-0 left-1/2 right-0 w-1/2 bg-cover bg-center"
      :style="{
        'background-image':
          'url(' + $transformStoryblokImage(imageForDesktop, '/m/560x640') + ')',
      }"
    ></div>
    <div
      class="hidden md:block absolute bottom-6 left-1/2 -translate-x-1/2 transform w-80 h-80 bg-no-repeat bg-contain bg-bottom"
      :class="arrowStyle"
    ></div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ArticleHeroSection',

  props: {
    title: {
      type: [String, Object],
      default: '',
    },
    intro: {
      type: [String, Object],
      default: '',
    },
    authorName: {
      type: String,
      default: '',
    },
    publishedDate: {
      type: String,
      default: '',
    },
    hideOldContentWarning: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    imageDesktop: {
      type: String,
      default: '',
    },
    showArrow: {
      type: String,
      default: 'default',
    },
    noTopPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedDate() {
      return dayjs(this.publishedDate).format('DD.MM.YYYY');
    },
    articleOutdated() {
      return dayjs().diff(dayjs(this.publishedDate), 'month') >= 6;
    },
    imageForDesktop() {
      return this.imageDesktop ? this.imageDesktop : this.image;
    },

    arrowStyle() {
      if (this.showArrow === 'none') {
        return '';
      }
      if (this.showArrow) {
        return `article-hero-section__${this.showArrow}`;
      }
      return `article-hero-section__default`;
    },
  },
};
</script>

<style lang="scss">
.article-hero-section {
  @media screen and (min-width: 768px) {
    min-height: 40rem;
  }

  &__title,
  &__rich-title p {
    @media screen and (min-width: 768px) {
      background: rgba(255, 255, 255, 0.5);
      display: inline;
      line-height: 56px;

      &::after {
        content: '';
        display: block;
      }
    }
  }

  &__image--desktop {
    min-height: 40rem;
    width: 35rem;
    max-width: 50%;
  }

  &__default {
    background-image: url('/illustrations/hand-drawn-arrows/Vector.svg');
  }

  &__arrow1 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow1.svg');
  }

  &__arrow2 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow2.svg');
  }

  &__arrow3 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow3.svg');
  }

  &__arrow4 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow4.svg');
  }

  &__arrow5 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow5.svg');
  }

  &__arrow6 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow6.svg');
  }

  &__arrow7 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow7.svg');
  }

  &__arrow8 {
    background-image: url('/illustrations/hand-drawn-arrows/Arrow8.svg');
  }
}
</style>
