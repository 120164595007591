<template>
  <div class="py-12 pb-8 px-3 md:px-6 border-b border-gray-200">
    <div class="max-w-5xl mx-auto">
      <!-- <h3
        class="text-lg text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-lg"
      >
        Trusted by these companies / Publications covering Rendin
      </h3> -->
      <ul
        class="list-none flex flex-col sm:flex-row justify-center items-center content-center mt-3"
      >
        <li class="p-4 md:px-4">
          <img alt="Testimonial Logo by X" class="w-10" src="/logos/logo-1.png" />
        </li>
        <li class="p-4 md:px-4">
          <img alt="Testimonial Logo by X" class="w-10" src="/logos/logo-2.png" />
        </li>
        <li class="p-4 md:px-4">
          <img alt="Testimonial Logo by X" class="w-10" src="/logos/logo-3.png" />
        </li>
        <li class="p-4 md:px-4">
          <img alt="Testimonial Logo by X" class="w-10" src="/logos/logo-4.png" />
        </li>
        <li class="p-4 md:px-4">
          <img alt="Testimonial Logo by X" class="w-10" src="/logos/logo-5.png" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetCompanies',
};
</script>
