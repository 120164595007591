import { useStore } from 'vuex';

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const store = useStore();

  window.onpopstate = () => {
    if (
      document.location.pathname + document.location.search ===
      store.getters['routerHistory/getPreviousPagePath']
    ) {
      store.dispatch('routerHistory/deletePreviousPageUrl');
      store.dispatch('routerHistory/pressBackButton');
    }
  };

  router.afterEach(function (to, from) {
    if (
      to.name &&
      from.name &&
      to.path !== from.path &&
      !store.getters['routerHistory/isBackButtonClicked']
    ) {
      store.dispatch('routerHistory/addPageUrl', from.fullPath);
    } else if (store.getters['routerHistory/isBackButtonClicked']) {
      store.dispatch('routerHistory/addPageUrl', from.fullPath);
      store.dispatch('routerHistory/deletePreviousPageUrl');
      store.dispatch('routerHistory/unpressBackButton');
    }
  });
});
