<template>
  <section :id="blok.id" v-editable="blok" class="metrics-section">
    <div class="max-w-6xl mx-auto px-4 py-12 text-center">
      <div class="max-w-3xl mx-auto text-gray-600">
        <component
          :is="blok.titleStyle ? blok.titleStyle : 'h2'"
          v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
          class="my-4 font-semibold text-gray-900 opacity-0"
          :class="[
            blok.titleStyle === 'h3' ? ' text-2xl md:text-3xl' : 'text-3xl md:text-4xl',
          ]"
        >
          <span v-if="blok.title" v-html="blok.title"></span>
        </component>
        <RichText
          v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
          :blok="blok.description"
          class="opacity-0"
        />
      </div>
      <div
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="grid gap-4 py-4 mt-12 opacity-0"
        :class="[
          blok.image?.filename
            ? 'lg:grid-cols-2 justify-center'
            : 'bg-violet-50 rounded-xl shadow-lg w-full',
        ]"
      >
        <div class="md:flex flex-row justify-evenly gap-6 text-gray-900">
          <div v-for="(metric, index) in blok.metrics" :key="index" class="my-8">
            <div class="text-5xl font-medium">
              {{ metric.number }}{{ metric.sufix }}
            </div>
            <div class="font-medium">
              {{ metric.label }}
            </div>
            <RichText
              v-if="metric.description"
              :blok="metric.description"
              class="-my-2"
            />
            <component
              :is="theBlok.component"
              v-for="theBlok in metric.button?.filter((item) =>
                ['ButtonBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />
          </div>
        </div>
        <div
          v-if="blok.image?.filename"
          v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
          class="w-full opacity-0"
        >
          <img
            :alt="blok.image?.alt"
            class="mx-auto"
            :src="$transformStoryblokImage(blok.image?.filename, '/m/fit-in/780x0/')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MetricsSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
