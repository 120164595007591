<template>
  <section class="add-meters-modal">
    <r-overlay
      body-classes="flex-h-screen sm:flex-h-auto"
      bottom-bar
      prevent-close-on-overlay
      size="large"
      :visible="showModal"
      @close="closeOverlay()"
    >
      <div class="px-4 md:px-8 pt-12 relative grow flex flex-col">
        <h3 class="font-medium text-2xl md:text-3xl my-4">
          {{ handoverTypeTranslation('add_or_edit_modal_title', handoverEntityType) }}
        </h3>

        <Form ref="entityDataForm" autocomplete="off" class="flex flex-col grow">
          <div class="flex flex-col gap-2 min-h-72 grow">
            <Field
              v-slot="{ meta }"
              v-model="selectedType"
              rules="required"
              name="entity-type"
            >
              <r-select
                v-model="selectedType"
                :error-text="$t('form.validation.is_required')"
                :label="
                  handoverTypeTranslation(
                    'add_or_edit_modal_type_label',
                    handoverEntityType,
                  )
                "
                name="entity-type"
                option-label="text"
                :options="entityTypeCategorySelect"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @input="validateAndPutEntity()"
              />
            </Field>
            <Field
              v-if="
                entityData?.type === mapHandoverDataType.OTHER &&
                handoverEntityType !== handoverDataTypes.OTHERS
              "
              v-slot="{ meta }"
              v-model="entityData.name"
              :rules="{ required: true }"
              name="entity-name"
            >
              <r-input
                v-model="entityData.name"
                :error-text="$t('form.validation.is_required')"
                :label="
                  handoverTypeTranslation(
                    'add_or_edit_modal_name_label',
                    handoverEntityType,
                  )
                "
                name="entity-name"
                required
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @input="validateAndPutEntity()"
              />
            </Field>

            <Field
              v-if="entityId && handoverEntityType === handoverDataTypes.METERS"
              v-slot="{ meta }"
              v-model="meterValueModel"
              :rules="{ isFloat: true, max: 20 }"
              name="entity-value"
            >
              <r-input
                v-model="meterValueModel"
                :error-text="$t('form.validation.meter_reading_number')"
                field-type="tel"
                inputmode="decimal"
                :label="
                  handoverTypeTranslation(
                    'add_or_edit_modal_value_label',
                    handoverEntityType,
                  )
                "
                name="entity-value"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @blur="validateAndPutEntity()"
              />
            </Field>
            <div v-if="entityId">
              <r-textarea
                v-model="entityData.description"
                :label="
                  handoverTypeTranslation(
                    'add_or_edit_modal_description_label',
                    handoverEntityType,
                  )
                "
                maxlength="3000"
                name="tenant-feedback"
                rows="4"
                @blur="validateAndPutEntity()"
              />
              <div class="text-gray-600 text-sm">
                {{
                  handoverTypeTranslation(
                    'add_or_edit_modal_description_disclaimer',
                    handoverEntityType,
                  )
                }}
              </div>
            </div>
          </div>

          <div v-if="entityId" class="flex justify-end my-8">
            <r-image-upload
              :accept="acceptedImageTypes"
              :add-photo-button="$t('image_upload.add_photo_button')"
              @selected-image-data="handleSelectedImages"
            />
          </div>

          <div v-if="listOfFailedUploadImages.length" class="w-full text-error">
            <r-icon class="text-error mr-2" icon-name="circle-exclamation" />
            {{ $t('agreement.handover_v2.image_uploading_modal.max_image_size_error') }}
            <ul class="list-disc mt-2 ml-4">
              <li
                v-for="(fileName, index) in listOfFailedUploadImages"
                :key="index"
                class="text-error text-sm"
              >
                {{ fileName }}
              </li>
            </ul>
          </div>

          <div v-if="entityId" class="grid md:grid-cols-2 gap-6 my-8">
            <div v-for="index in totalNumberOfUploadImages" :key="index">
              <r-image-upload-card show-skeleton />
            </div>

            <div v-for="file in savedImagesData" :key="file.entityId">
              <r-image-upload-card
                :image-description="file.description"
                :image-description-label="$t('agreement.handover_v2.image_comments')"
                :img-src="file.fileUrl"
                @delete-button-click="deleteFeedbackImage(file.entityId)"
                @on-blur-description="putImageDescription(file.entityId, $event)"
              />
            </div>
            <r-image-upload
              :accept="acceptedImageTypes"
              :browse-button-text="$t('image_upload.click_to_upload')"
              :browse-label-sub-text="$t('image_upload.allowed_file_types')"
              :browse-label-text="$t('image_upload.or_drag_and_drop')"
              class="h-64 sm:h-80"
              :class="{
                'md:col-span-2':
                  !savedImagesData?.length && totalNumberOfUploadImages === 0,
              }"
              is-drag-and-drop
              @selected-image-data="handleSelectedImages"
            />
          </div>

          <div
            v-if="!entityId"
            class="flex md:flex-row w-full justify-end gap-2 bg-white py-4 sticky bottom-0"
          >
            <r-button
              :is-loading="postHandoverQueueLocked"
              :label="$t('buttons.close')"
              @click="!postHandoverQueueLocked ? closeOverlay() : null"
            />
          </div>

          <div
            v-else
            class="flex md:flex-row w-full justify-end gap-2 bg-white py-4 sticky bottom-0"
          >
            <r-button
              color="black"
              icon="trash"
              icon-class="mr-2"
              :is-loading="deleteEntityInProgress"
              :label="$t('agreement.button.remove')"
              no-background
              width="small"
              @click="deleteHandoverEntity(entityId)"
            />
            <r-button
              v-if="!deleteEntityInProgress"
              for="entityDataForm"
              :is-loading="saveAndCloseInProgress"
              :label="$t('buttons.save_and_close')"
              @click="!saveAndCloseInProgress ? saveAndClose() : null"
            />
          </div>
        </Form>
      </div>
    </r-overlay>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  handoverStatuses,
  handoverDataTypes,
  handoverDataMeterTypes,
  handoverDataRoomTypes,
  handoverDataOthersTypes,
  handoverEntryTypes,
} from '~/utils/constants.js';
import { handoverTypeTranslation } from '~/composables/handoverTranslationsConstructor.js';
import { prepareDataForHandoverApiSubmit } from '~/utils/handoverDataConstructor.js';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants.js';
import { debounce } from 'lodash';
import { Field, Form } from 'vee-validate';

export default {
  name: 'HandoverEditViewEntityAddOrEditModal',

  components: {
    Field,
    Form,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    activeEntityId: {
      type: String,
      default: null,
    },
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    handoverEntityType: {
      type: String,
      default: null,
    },
  },
  emits: ['closeOverlay'],

  setup() {
    return {
      handoverTypeTranslation,
    };
  },

  data: () => ({
    showAddModal: false,
    entityId: null,
    entityData: {
      type: null,
      name: undefined,
      value: null,
      description: null,
    },
    selectedType: null,
    handoverDataTypes,
    handoverDataMeterTypes,
    handoverDataRoomTypes,
    handoverDataOthersTypes,
    savedImagesData: [],
    totalNumberOfUploadImages: 0,
    handoverStatuses,
    maxImageUploadSize: 7.5 * 1024 * 1024,
    listOfFailedUploadImages: [],
    // postHandoverImage allowed extension types
    acceptedImageTypes:
      'image/bmp,image/gif,image/jpg,image/jpe,image/jpeg,image/png,.heic,.heif',
    deleteEntityInProgress: false,
    postEntityInProgress: false,
    stopUploadingProcess: false,
    saveAndCloseInProgress: false,
    actions: {
      putHandoverEntityDebounced: () => undefined,
    },
  }),

  computed: {
    ...mapGetters({
      postHandoverQueueLocked: 'handover/postHandoverQueueLocked',
      putHandoverQueueLocked: 'handover/putHandoverQueueLocked',
      postHandoverImageQueueLocked: 'handover/postHandoverImageQueueLocked',
    }),

    meterValueModel: {
      get() {
        return this.entityData.value || null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.entityData.value = isFinite(normalized) ? normalized : newVal;
      },
    },

    mapHandoverDataType() {
      const map = {
        [handoverDataTypes.METERS]: this.handoverDataMeterTypes,
        [handoverDataTypes.ROOMS]: this.handoverDataRoomTypes,
        [handoverDataTypes.OTHERS]: this.handoverDataOthersTypes,
      };

      return map[this.handoverEntityType];
    },

    entityTypeCategorySelect() {
      const typeSelectOption = [];
      Object.keys(this.mapHandoverDataType).forEach((type) => {
        // All types accessible for new entity or if handoverEntityType is "others"
        if (!this.entityId || this.handoverEntityType === handoverDataTypes.OTHERS) {
          typeSelectOption.push({
            text: this.handoverTypeTranslation(type, this.handoverEntityType),
            value: type,
          });
          // Existing type is not OTHER then can not change to OTHER
        } else if (
          this.entityId &&
          this.entityData.type !== this.mapHandoverDataType.OTHER &&
          type !== this.mapHandoverDataType.OTHER
        ) {
          typeSelectOption.push({
            text: this.handoverTypeTranslation(type, this.handoverEntityType),
            value: type,
          });
        }
        // Existing type is OTHER only OTHER allowed
        if (
          this.entityId &&
          this.entityData.type === this.mapHandoverDataType.OTHER &&
          type === this.mapHandoverDataType.OTHER
        ) {
          typeSelectOption.push({
            text: this.handoverTypeTranslation(type, this.handoverEntityType),
            value: type,
          });
        }
      });

      return typeSelectOption;
    },

    entryType() {
      if (this.handoverEntityType === handoverDataTypes.METERS) {
        return handoverEntryTypes.METER;
      } else if (this.handoverEntityType === handoverDataTypes.ROOMS) {
        return handoverEntryTypes.ROOM;
      }
      return handoverEntryTypes.OTHER;
    },
  },

  watch: {
    selectedType(val) {
      if (val) this.entityData.type = val.value;
      if (val?.value !== this.mapHandoverDataType.OTHER) this.entityData.name = null;
    },

    showModal(val) {
      if (val) {
        this.fillEditModeData();
      } else {
        // on modal close update handover and reset data
        this.getHandover();
        this.resetEntityData();
        this.resetImageUploadData();
        this.$refs.entityDataForm?.reset();
      }
    },

    activeEntityId() {
      this.fillEditModeData();
    },

    agreementHandover() {
      this.fillEditModeData();
    },
  },

  mounted() {
    this.fillEditModeData();

    this.actions.putHandoverEntityDebounced = debounce(() => {
      const includedData = this.prepareDataForHandoverApiSubmit(
        this.entityData,
        this.entityId,
      );

      if (!this.entityId) {
        if (this.postEntityInProgress) return;
        // type OTHER requires name
        if (
          this.handoverEntityType !== handoverDataTypes.OTHERS &&
          this.selectedType.value === this.mapHandoverDataType.OTHER &&
          !this.entityData.name
        )
          return;

        this.postEntityInProgress = true;
        return this.actionPostHandover({
          type: this.entryType,
          agreementId: this.agreementId,
          includedData: includedData,
        })
          .then((response) => {
            this.entityId = response?.data?.data?.entityId;
            // Fill with posted data if modal closed before response
            this.entityData.type = response?.data?.requestData?.type;
            this.entityData.name = response?.data?.requestData?.name;
            this.selectedType = {
              text: this.handoverTypeTranslation(
                this.entityData.type,
                this.handoverEntityType,
              ),
              value: this.entityData.type,
            };
          })
          .finally(() => (this.postEntityInProgress = false));
      } else {
        return this.actionPutHandover({
          entityId: this.entityId,
          includedData: includedData,
        });
      }
    }, 400);
  },

  beforeUnmount() {
    this.actions.putHandoverEntityDebounced = () => undefined;
  },

  methods: {
    prepareDataForHandoverApiSubmit,
    ...mapActions({
      actionPostHandover: 'handover/postHandover',
      actionPostHandoverImage: 'handover/postHandoverImage',
      actionPutHandoverImage: 'handover/putHandoverImage',
      actionPutHandover: 'handover/putHandover',
      actionGetHandover: 'handover/getHandover',
      actionDeleteHandover: 'handover/deleteHandover',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    fillEditModeData() {
      this.entityId = this.entityId || this.activeEntityId;

      if (this.entityId) {
        const activeEntityData = this.agreementHandover[
          this.handoverEntityType
        ]?.filter((obj) => obj?.entityId === this.entityId)[0];
        this.entityData.type = this.entityData?.type || activeEntityData?.type || null;
        this.entityData.name =
          this.entityData?.name || activeEntityData?.name || undefined;
        this.entityData.value =
          this.entityData?.value || activeEntityData?.value || null;
        this.entityData.description =
          this.entityData?.description || activeEntityData?.description || null;
        this.selectedType = {
          text: this.handoverTypeTranslation(
            this.entityData?.type || activeEntityData?.type,
            this.handoverEntityType,
          ),
          value: this.entityData?.type || activeEntityData?.type,
        };
        this.savedImagesData = activeEntityData?.files?.map((item) => ({
          entityId: item.entityId,
          fileUrl: item.jpeg92FileUrl || item.fileUrl,
          description: item.description,
        }));
      } else {
        this.resetEntityData();
      }
    },

    resetImageUploadData() {
      this.listOfFailedUploadImages = [];
      this.totalNumberOfUploadImages = 0;
    },

    resetEntityData() {
      this.entityId = null;
      this.entityData = {
        type: null,
        name: undefined,
        value: null,
        description: null,
      };
      this.savedImagesData = null;
      this.selectedType = null;
    },

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    deleteHandoverEntity(entityId) {
      this.deleteEntityInProgress = true;
      this.actionDeleteHandover({ entityId: entityId })
        .then((response) => {
          this.entityId = response?.data?.data?.entityId;
          this.getHandover();
        })
        .then(() => {
          this.deleteEntityInProgress = false;
          this.$emit('closeOverlay');
        });
    },

    validateAndPutEntity() {
      this.$nextTick(() => {
        this.$refs.entityDataForm.validate().then((isValidData) => {
          if (isValidData) this.actions.putHandoverEntityDebounced();
        });
      });
    },

    saveAndClose() {
      if (this.entityId) {
        this.$refs.entityDataForm.validate().then((isValidData) => {
          if (isValidData) {
            // stop image uploads
            this.stopUploadingProcess = true;
            this.saveAndCloseInProgress = true;
            const includedData = this.prepareDataForHandoverApiSubmit(
              this.entityData,
              this.entityId,
            );
            this.actionPutHandover({
              entityId: this.entityId,
              includedData: includedData,
            })
              .then(() => this.getHandover())
              .finally(() => {
                this.closeOverlay();
                this.saveAndCloseInProgress = false;
              });
          }
        });
      } else {
        this.closeOverlay();
      }
    },

    getHandover() {
      return this.actionGetHandover({ agreementId: this.agreementId });
    },

    deleteFeedbackImage(deletedEntityId) {
      this.savedImagesData = this.savedImagesData.filter(
        (img) => img.entityId !== deletedEntityId,
      );
      this.actionDeleteHandover({ entityId: deletedEntityId }).then(() =>
        this.getHandover(),
      );
    },

    postHandoverImage(asBase64, extension, description) {
      const includedData = {
        file: {
          asBase64: asBase64,
          extension: extension.toLowerCase(),
        },
        description: description,
      };

      return this.actionPostHandoverImage({ entityId: this.entityId, includedData });
    },

    async handleSelectedImages(imagesData) {
      this.totalNumberOfUploadImages = imagesData?.length;
      this.stopUploadingProcess = false;
      this.listOfFailedUploadImages = [];

      try {
        for (let index = 0; index < imagesData.length; index++) {
          const item = imagesData[index];

          if (this.stopUploadingProcess) {
            break;
          }
          if (item.size > this.maxImageUploadSize) {
            this.listOfFailedUploadImages.push(item.fileName);
            this.emitAgreementTrackerEvent(
              agreementTrackingEventNames.HANDOVER_FILE_SIZE,
            );

            continue;
          }

          await this.postHandoverImage(item.base64Data, item.fileExtension);
        }

        await this.getHandover().then(() => {
          this.totalNumberOfUploadImages = 0;
        });
      } catch (error) {
        await this.reportErrorToSentry(error);
      }
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
      });
    },

    putImageDescription(fileId, description) {
      const imageDescription = {
        description: description,
      };
      const includedData = this.prepareDataForHandoverApiSubmit(
        imageDescription,
        fileId,
      );
      this.actionPutHandoverImage({ entityId: fileId, includedData: includedData });
    },
  },
};
</script>
