<template>
  <div class="py-24 flex flex-col px-4 max-w-6xl mx-auto">
    <p class="text-center text-rendin-700 font-semibold leading-6">
      {{ blok.secondaryTitle }}
    </p>
    <RichText v-if="blok.title" :blok="blok.title" class="text-center" />
    <div
      v-if="articlesForDisplay?.length > 0"
      class="mt-8 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-10 items-stretch"
    >
      <div
        v-for="article in articlesForDisplay"
        :key="article._uid"
        class="flex items-stretch"
        :class="{ hidden: article.content.articleTranslationHidden }"
      >
        <r-article-card
          :author-name="article.content.authorName"
          class="h-full"
          :date="article.first_published_at"
          :image="article.content.image.filename"
          :intro="article.content.intro"
          partly-hide-intro
          :slug="article.full_slug"
          :title="article.content.title"
        />
      </div>
    </div>
    <div v-if="blok.redirectionButton" class="flex justify-center mt-4">
      <r-button
        :label="$t('see_all_articles')"
        shape="sharp"
        :to="redirectLinks.ARTICLES"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RArticleCard from '~/components/r-interface-components/r-article-card.vue';
import { redirectLinks } from '~/utils/constants';

export default {
  name: 'ArticlesSuggestion',
  components: { RArticleCard },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup() {
    const storyblokApi = useStoryblokApi();
    const nuxtApp = useNuxtApp();

    const takeFirstThreeArticles = (articles) => {
      const articlesLimit = 3;
      return articles.splice(0, articlesLimit);
    };

    const { data: allArticles, error } = await useAsyncData(async () => {
      const articleTranslationHiddenKey =
        nuxtApp.$getLocale() === 'en'
          ? 'articleTranslationHidden'
          : `articleTranslationHidden__i18n__${nuxtApp.$getLocale()}`;

      const options = {
        version: nuxtApp.$config.public.STORYBLOK_VERSION,
        language: nuxtApp.$getLocale(),
        starts_with: `${nuxtApp.$getCountry() === 'pl' ? 'poland' : 'estonia'}/articles/`,
        is_startpage: 0,
        page: 1,
        content_type: 'Article',
        filter_query: {
          [articleTranslationHiddenKey]: {
            not_in: true,
          },
        },
        sort_by: 'first_published_at:desc',
      };

      const response = await storyblokApi.get(`cdn/stories`, options);

      return response?.data?.stories ?? null;
    });

    const articles = computed(() => takeFirstThreeArticles(allArticles.value));

    if (error.value) {
      console.error(error.value);
    }

    return {
      articles,
    };
  },
  data() {
    return {
      redirectLinks: redirectLinks,
    };
  },
  computed: {
    ...mapGetters({
      getLocale: 'getLocale',
    }),

    articlesForDisplay() {
      return this.blok.suggestedArticles?.length > 0
        ? this.blok.suggestedArticles
        : this.articles;
    },
  },
};
</script>

<style scoped lang="scss"></style>
