<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="py-12 px-4 bg-gray-50"
    :class="{ 'py-20': blok.flavor === 'with-illustration' }"
  >
    <div
      class="mx-auto max-w-5xl p-6 py-10 md:px-12 md:py-10 flex flex-col md:flex-row gap-6"
      :class="{
        'bg-white rounded-xl p-6 py-10 md:p-16 items-center justify-center':
          blok.flavor === 'with-illustration',
      }"
    >
      <div
        v-if="blok.image && blok.image.filename && blok.flavor === 'with-illustration'"
        class="md:w-1/3 self-stretch flex justify-center"
      >
        <img
          :alt="blok.image.alt"
          class="h-full object-cover"
          :src="blok.image.filename"
        />
      </div>

      <!-- E-mail form screen     -->
      <div v-if="!multiStep || step === 1" class="flex-1">
        <div
          class="flex-1 mb-8 md:mb-12"
          :class="{
            'md:w-3/4 mx-auto text-center': blok.flavor !== 'with-illustration',
            'text-center md:text-left': blok.flavor === 'with-illustration',
          }"
        >
          <h2 class="text-2xl md:text-4xl leading-tight mb-4 font-semibold">
            {{ blok.title }}
          </h2>
          <p class="md:text-xl text-gray-600">
            {{ blok.text }}
          </p>
        </div>
        <VeeForm
          ref="validator"
          autocomplete="off"
          @submit="multiStep ? continueToSegments($event) : newsletterSubscribe($event)"
        >
          <div>
            <div class="max-w-lg m-auto">
              <div v-show="showSuccess" class="text-center text-lg text-success-main">
                {{ blok.success_msg }}
              </div>
              <div v-show="!showSuccess">
                <div class="sm:flex gap-4">
                  <div class="flex-1">
                    <Field
                      v-slot="{ meta }"
                      v-model="form.email"
                      name="email"
                      rules="required|email"
                    >
                      <input
                        v-model="form.email"
                        class="inside-block w-full focus:ring-rendin-500 focus:outline-none w-full text-base leading-5 text-gray-800 placeholder-gray-400 rounded-lg shadow-sm px-3 py-3.5"
                        :class="{
                          'focus:border-rendin-500': !meta.valid,
                          'border border-gray-300': blok.flavor !== 'with-illustration',
                          'bg-gray-100': blok.flavor === 'with-illustration',
                        }"
                        name="email"
                        :placeholder="blok.placeholder"
                        type="email"
                      />
                    </Field>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <r-button
                      :disabled="submitLoading"
                      :is-loading="submitLoading"
                      :label="multiStep ? blok.continueButtonCTA : blok.button_cta"
                      shape="sharp"
                      type="submit"
                      width="full"
                    />
                  </div>
                </div>
                <RichText
                  v-if="blok.disclaimer"
                  :blok="blok.disclaimer"
                  class="-mt-2 text-gray-600 text-sm"
                />
              </div>
            </div>
          </div>
        </VeeForm>
      </div>
      <!--  Select segments screen    -->
      <div v-else class="flex-1">
        <div
          class="mb-8 md:mb-12 text-center"
          :class="{
            'md:w-3/4 mx-auto': blok.flavor !== 'with-illustration',
          }"
        >
          <p class="text-lg text-gray-800">
            {{ blok.segmentsScreenIntro }}
          </p>
          <p class="text-xs text-gray-500 py-2">
            {{ blok.segmentsScreenHelper }}
          </p>
          <div class="flex flex-row flex-wrap gap-2 justify-center">
            <template v-if="loadingSegments">
              <r-loader size="20" />
            </template>
            <template v-else>
              <r-chip
                v-for="segment in segments"
                :key="segment.key"
                :background-class="
                  segment.selected ? 'bg-rendin-500 shadow-sm' : 'bg-white shadow-sm'
                "
                class="cursor-pointer"
                :label="segment.value"
                :text-class="
                  segment.selected ? 'text-sm text-white' : 'text-sm text-black'
                "
                @click="segment.selected = !segment.selected"
              />
            </template>
          </div>
        </div>
        <form autocomplete="off" @submit.prevent="newsletterSubscribe">
          <div
            class=" "
            :class="{
              'sm:grid sm:grid-cols-10 lg:grid-cols-12 gap-4 md:gap-6 lg:gap-10':
                blok.flavor !== 'with-illustration',
              'lg:grid11 lg:grid-cols-1211': blok.flavor === 'with-illustration',
            }"
          >
            <div
              class="sm:col-start-2 sm:col-span-8 md:col-start-3 md:col-span-6 lg:col-start-4 lg:col-span-6"
            >
              <div v-show="showSuccess" class="text-center text-lg">
                {{ blok.success_msg }}
              </div>
              <div v-show="!showSuccess" class="sm:flex shadow-sm sm:shadow-none">
                <r-button
                  :disabled="
                    submitLoading || !atleastOneSegmentSelected || loadingSegments
                  "
                  :is-loading="submitLoading"
                  type="submit"
                  width="full"
                >
                  <span class="flex justify-center">{{ blok.button_cta }} </span>
                </r-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { storyblokDatasources } from '~/utils/constants';
import { Field, Form as VeeForm } from 'vee-validate';

export default {
  name: 'SectionNewsletter',
  components: { Field, VeeForm },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup() {
    const storyblokApi = useStoryblokApi();

    return {
      storyblokApi,
    };
  },
  data() {
    return {
      errors: [],
      form: {
        email: null,
      },
      showSuccess: false,
      showError: false,
      submitLoading: false,
      storyblokSegments: [],
      segments: [],
      step: 1,
      loadingSegments: true,
    };
  },
  computed: {
    multiStep() {
      return (
        this.blok && Array.isArray(this.blok.segments) && this.blok.segments.length > 1
      );
    },
    atleastOneSegmentSelected() {
      return this.segments.some((segment) => segment.selected);
    },
  },
  watch: {
    blok() {
      this.refreshSegments();
    },
    storyblokSegments() {
      this.refreshSegments();
    },
    step(newValue) {
      if (newValue === 2) {
        this.fetchTranslatedSegments();
      }
    },
  },
  methods: {
    ...mapActions({
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
    }),
    fetchTranslatedSegments() {
      this.loadingSegments = true;
      return Promise.resolve()
        .then(() => {
          const options = {
            datasource: storyblokDatasources.NEWSLETTER_SEGMENTS,
            dimension: this.$getLocale(),
          };

          return this.storyblokApi.get(`cdn/datasource_entries`, options);
        })
        .then((response) => (this.storyblokSegments = response.data.datasource_entries))
        .catch((error) => this.actionReportErrorToSentry(error))
        .finally(() => (this.loadingSegments = false));
    },
    refreshSegments() {
      const segments = [];

      if (
        this.blok &&
        Array.isArray(this.blok.segments) &&
        Array.isArray(this.storyblokSegments)
      ) {
        this.blok.segments.forEach((segment) => {
          // Should this segment be also preselected
          let isPreselected = false;
          if (
            this.blok &&
            Array.isArray(this.blok.preselectedSegments) &&
            this.blok.preselectedSegments.find(
              (preselectedSegment) => preselectedSegment === segment,
            )
          ) {
            isPreselected = true;
          }

          const match = this.storyblokSegments.find((entry) => entry.value === segment);
          if (match) {
            segments.push({
              key: segment,
              value: match.dimension_value || match.value,
              selected: isPreselected,
            });
          } else {
            segments.push({
              key: segment,
              value: segment,
              selected: isPreselected,
            });
          }
        });
      }

      this.segments = segments;
    },
    continueToSegments() {
      this.step = 2;
    },
    newsletterSubscribe() {
      if (this.segments.length > 0 && !this.atleastOneSegmentSelected) {
        return;
      }
      this.submitLoading = true;

      this.$cio.identify({
        id: this.form.email,
        email: this.form.email,
        country: this.$getCountry(),
        locale: this.$getLocale(),
      });

      this.$cio.track('Newsletter Subscribe', {
        locale: this.$getLocale(),
        country: this.$getCountry(),
        user_type: this.blok.user_type === 'none' ? '' : this.blok.user_type,
        segments: this.segments
          .filter((segment) => segment.selected)
          .map((segment) => segment.key),
      });

      this.$trackEvent(
        'Newsletter Subscription',
        {
          pagePath: this.$route.fullPath,
          userType: this.blok.user_type === 'none' ? '' : this.blok.user_type,
        },
        {
          services: ['POSTHOG', 'GTAG', 'FB'],
        },
      );

      this.submitLoading = false;
      this.showSuccess = true;
    },
  },
};
</script>
