<template>
  <div
    v-if="advertisementDetails && Object.keys(advertisementDetails).length > 0"
    class="flex flex-col"
  >
    <p v-if="hasAnyDetails" class="font-semibold pb-2">
      {{ $t('listing_subscription.your_criteria') + ':' }}
    </p>
    <div class="flex flex-wrap gap-2">
      <r-chip v-if="advertisementDetails?.city" color="blue">
        {{ advertisementDetails.city }}
      </r-chip>
      <r-chip v-if="advertisementDetails.districts?.length > 0" color="blue">
        {{ advertisementDetails.districts.join(', ') }}
      </r-chip>
      <r-chip
        v-if="advertisementDetails?.roomsMin || advertisementDetails?.roomsMax"
        color="blue"
      >
        <span>{{
          advertisementDetails.roomsMin ? advertisementDetails.roomsMin : '...'
        }}</span>
        <span> {{ ' - ' }} </span>
        <span> {{ roomsMaxValue ? roomsMaxValue : '...' }}</span>
        <span class="pl-1">
          {{ $t('preferencesFilter.label.rooms_select').toLowerCase() }}</span
        >
      </r-chip>
      <r-chip
        v-if="advertisementDetails?.priceMin || advertisementDetails?.priceMax"
        color="blue"
      >
        <span>{{
          advertisementDetails.priceMin
            ? advertisementDetails.priceMin + currencyLabel
            : '...'
        }}</span>
        <span> {{ ' - ' }} </span>
        <span>
          {{
            advertisementDetails.priceMax
              ? advertisementDetails.priceMax + currencyLabel
              : '...'
          }}</span
        >
      </r-chip>
      <r-chip
        v-if="advertisementDetails?.areaMin || advertisementDetails?.areaMax"
        color="blue"
      >
        <span>{{
          advertisementDetails.areaMin ? advertisementDetails.areaMin + 'm2' : '...'
        }}</span>
        <span> {{ ' - ' }} </span>
        <span>
          {{
            advertisementDetails.areaMax ? advertisementDetails.areaMax + 'm2' : '...'
          }}</span
        >
      </r-chip>
      <r-chip
        v-if="advertisementDetails?.floorMin || advertisementDetails?.floorMax"
        color="blue"
      >
        <span>{{
          advertisementDetails.floorMin ? advertisementDetails.floorMin : '...'
        }}</span>
        <span> {{ ' - ' }} </span>
        <span>
          {{
            advertisementDetails.floorMax ? advertisementDetails.floorMax : '...'
          }}</span
        >
        <span class="pl-1">
          {{ $t('preferencesFilter.label.floor').toLowerCase() }}</span
        >
      </r-chip>
      <r-chip v-if="advertisementDetails?.petsAllowed" color="blue">
        <span> {{ $t('preferencesFilter.label.pets_friendly') }} </span>
      </r-chip>
      <r-chip v-if="advertisementDetails?.hasParking" color="blue">
        <span> {{ $t('preferencesFilter.label.parking_spot') }} </span>
      </r-chip>
      <r-chip v-if="advertisementDetails?.hasStorage" color="blue">
        <span> {{ $t('preferencesFilter.label.storage_room') }} </span>
      </r-chip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdvertisementDetailsChips',

  props: {
    advertisementDetails: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      currencyLabel: 'getCurrencyLabel',
    }),

    hasAnyDetails() {
      return Object.values(this.advertisementDetails).some(
        (value) =>
          value !== undefined &&
          value !== null &&
          value !== false &&
          !(Array.isArray(value) && value.length === 0) &&
          value !== '',
      );
    },

    roomsMaxValue() {
      return this.advertisementDetails?.roomsMax === 5
        ? `${this.advertisementDetails.roomsMax}+`
        : this.advertisementDetails?.roomsMax;
    },
  },
};
</script>
