<template>
  <div>
    <!-- Into content-->
    <div class="max-w-3xl mx-auto text-center px-4">
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="my-6 r-fade--delay--1 sm:text-1.5xl"
        :class="{ 'r-fade-bottom': initialLoad }"
      />
    </div>
    <!-- Tabs for select if landlord has tenant or not -->
    <div
      class="r-fade--delay--2 max-w-xl mx-auto grid grid-cols-2 gap-4 md:gap-8 px-4 mb-12"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <div
        v-for="tab in tabs"
        :id="tab.tabId"
        :key="tab._uid"
        class="bg-white text-center shadow-lg rounded-xl p-4 border border-white hover:border-rendin-500 focus:border-rendin-500"
        :class="{ 'border-rendin-500 ': alreadyHasTenant === tab.hasTenant }"
        @click="$emit('selectHasTenant', tab.hasTenant)"
      >
        <span
          v-if="tab.image && tab.image.filename"
          class="h-20 md:h-32 w-full block bg-no-repeat bg-contain bg-center mb-2"
          :style="{ 'background-image': 'url(' + tab.image.filename + ')' }"
        ></span>

        <h4 class="sm:text-lg md:text-xl leading-4 font-medium">
          {{ tab.title }}
        </h4>
        <div class="text-sm font-light mt-2">
          {{ tab.content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'StepDoesAlreadyHaveTenant',
  props: {
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    alreadyHasTenant: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getAnalyticEventProperties: 'tracker/getAnalyticEventProperties',
      hasSessionUser: 'users/hasSessionUser',
    }),
  },
  methods: {
    ...mapActions({
      actionTrackDigibrokerEvent: 'tracker/trackDigibrokerEvent',
    }),
    ...mapMutations({
      setSignInModal: 'session/SET_SIGN_IN_MODAL',
    }),
  },
};
</script>
