<template>
  <div v-editable="blok" class="flex flex-col gap-2">
    <div class="bg-gray-100 rounded-lg p-3">
      <r-input
        v-model="rentAmount"
        :add-on="blok.currency"
        field-type="number"
        :label="blok.rentInputLabel"
        name="amount"
      />
    </div>

    <div class="bg-gray-100 rounded-lg p-3">
      <div>{{ blok.feeLabel }}</div>
      <div class="text-3xl md:text-4xl font-medium">
        {{
          rendinFeeCalculation(rentAmount, getCountry).toLocaleString(getLocale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}
        {{ blok.currency }}
      </div>

      <div
        v-if="blok.modalLabel"
        class="text-rendin-500 font-semibold hover:text-rendin-400 focus:text-rendin-400 cursor-pointer my-2"
        @click="showOverlay = !showOverlay"
      >
        <r-icon icon-name="circle-question" />
        {{ blok.modalLabel }}
      </div>
    </div>

    <r-overlay
      bottom-bar
      size="small"
      :visible="showOverlay"
      @close="showOverlay = false"
    >
      <div class="p-4 pt-8 md:pt-10 md:pb-4 md:px-10">
        <RichText v-if="blok.modalContent" :blok="blok.modalContent" />
      </div>

      <template #bottom-bar>
        <r-button
          display="block"
          :label="$t('buttons.close')"
          no-background
          uppercase
          @click="showOverlay = false"
        />
      </template>
    </r-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';

export default {
  name: 'RendinFeeCalculationBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      rendinFeeCalculation,
    };
  },

  data: () => ({
    rentAmount: 0,
    showOverlay: false,
  }),

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
      getLocale: 'getLocale',
    }),
  },

  watch: {
    blok: {
      handler(newValue) {
        this.rentAmount = newValue.rentAmount || 0;
      },
      immediate: true,
    },
  },
};
</script>
