import { toUpper, isObject, isString, get, isNumber } from 'lodash';

const stateDefaults = {
  tenantProfileFromFirebaseBase: null,
  tenantProfileFromFirebase: null,
};

export const state = () => ({
  showOfferModal: false,
  showProfileShareModal: false,
  tenantProfileFromFirebaseBase: stateDefaults.tenantProfileFromFirebaseBase,
  tenantProfileFromFirebase: stateDefaults.tenantProfileFromFirebase,
});

export const mutations = {
  TOGGLE_SHARE_MODAL: function (state) {
    state.showProfileShareModal = !state.showProfileShareModal;
  },
  SET_TENANT_PROFILE_CREATE_FROM_FIREBASE: function (
    state,
    tenantProfileData = undefined,
  ) {
    state.tenantProfileFromFirebaseBase = tenantProfileData
      ? { ...tenantProfileData }
      : { ...stateDefaults.tenantProfileFromFirebaseBase };
  },
  SET_TENANT_PROFILE_FROM_FIREBASE: function (state, tenantProfileData = undefined) {
    state.tenantProfileFromFirebase = tenantProfileData
      ? { ...tenantProfileData }
      : { ...stateDefaults.tenantProfileFromFirebase };
  },
};

export const getters = {
  showOfferModal: (state) => state.showOfferModal,
  showProfileShareModal: (state) => state.showProfileShareModal,
  // By default, if not reported otherwise from the BE, profiles are public.
  isTenantProfilePublic: (state, getters) =>
    get(getters.tenantProfileFromFirebase, 'isProfilePublic', true),
  getTenantProfileBackgroundCheckValue: (state, getters) =>
    get(getters.tenantProfileFromFirebase, 'scoringValue'),
  isTenantProfileBackgroundCheckPassed: (state, getters) =>
    getters.getTenantProfileBackgroundCheckValue === 'PASSED',
  getTenantProfilePublicId: (state, getters) =>
    get(getters.tenantProfileFromFirebase, '_id', ''),
  tenantProfileFromFirebaseCreate: (state) => state.tenantProfileFromFirebaseBase,
  tenantProfileFromFirebase: (state) => state.tenantProfileFromFirebase,
  tenantProfileEmail: (state, getters, rootState, rootGetters) =>
    rootGetters['users/sessionUser'].email || null,
  hasTenantProfileFromFirebase: (state, getters) =>
    getters.tenantProfileFromFirebase !== null &&
    Object.keys(getters.tenantProfileFromFirebase).length !== 0,
};

/**
 * @typedef {string} arrayOfStringsSeparatedWithCommas
 */

/**
 * @typedef {string} TenantProfilePublicId
 */

/**
 * @typedef {string} DateISOString
 */

/**
 * @typedef {'EUR'|'USD'|'PLN'} Currency
 */

/**
 * @typedef {string} URL
 */

/**
 * @typedef {{
 *  image?: URL,
 *  description?: string,
 *  price?: number,
 *  priceMin?: number,
 *  priceMax?: number,
 *  furnitureNeeded?: boolean,
 *  parkingSpotNeeded?: boolean,
 *  employmentOrganization?: string,
 *  needFurniture?: boolean,
 *  salaryMin?: number,
 *  salaryMax?: number,
 *  salaryCurrency?: Currency,
 *  coTenantsAmount?: number,
 *  hasPets?: boolean,
 *  petType?: string,
 *  hasAPet?: boolean,
 *  numberOfRooms?: arrayOfStringsSeparatedWithCommas,
 *  roomsMin?: number,
 *  roomsMax?: number,
 *  occupation?: string,
 *  organization?: string,
 *  location?: string,
 *  alternateMoveInTime?: DateISOString,
 *  shareableProfileImage?: URL,
 *  termsAccepted?: boolean,
 *  isProfilePublic?: boolean
 * }} TenantProfileBase
 */

/**
 * @typedef {TenantProfileBase & Profile} TenantProfile
 */

export const actions = {
  toggleProfileShareModal({ commit }) {
    commit('TOGGLE_SHARE_MODAL');
  },
  /**
   * Calls into {@link getters.tenantProfileFromFirebase} in case the tenant profile is found.
   * Calls into {@link getters.tenantProfileFromFirebaseCreate} in case the tenant profile is not found.
   * In any case, {@link getters.tenantProfileEmail} is filled properly for the reference.
   *
   * and to provide some prefill data for it.
   */
  requestTenantProfile: function ({ commit, dispatch, getters, rootGetters }) {
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'getTenantProfile' },
          { root: true },
        ),
      )
      .then(({ data }) => {
        const tenantProfile = data.tenantProfile;
        const tenantProfileCreateBase = data.tenantProfileCreateBase;
        // Error trap for debugging through logging!
        // TODO: by Sven Kirsimae: There is a better way: https://app.clickup.com/t/1wtz54m
        if (
          !isObject(tenantProfileCreateBase) &&
          isObject(tenantProfile) &&
          (!isString(tenantProfile.firstName) ||
            !isString(tenantProfile.lastName) ||
            !isString(tenantProfile.scoringValue))
        ) {
          const sessionUser = rootGetters['users/sessionUser'];
          dispatch(
            'tracker/reportErrorToSentry',
            new Error(
              `Broken tenant profile:` +
                ` tenantProfile: ${
                  tenantProfile ? JSON.stringify(tenantProfile) : tenantProfile
                }` +
                ` data: ${data ? JSON.stringify(data) : data}` +
                ` sessionUser: ${
                  sessionUser ? JSON.stringify(sessionUser) : sessionUser
                },`,
            ),
            { root: true },
          );
        }
        // Error trap for debugging through logging!
        // TODO: by Sven Kirsimae: There is a better way: https://app.clickup.com/t/1wtz54m
        if (!isObject(tenantProfile) && !isObject(tenantProfileCreateBase)) {
          const sessionUser = rootGetters['users/sessionUser'];
          dispatch(
            'tracker/reportErrorToSentry',
            new Error(
              `Tenant profile and create base was not returned:` +
                ` data: ${data ? JSON.stringify(data) : data}` +
                ` sessionUser: ${
                  sessionUser ? JSON.stringify(sessionUser) : sessionUser
                },`,
            ),
            { root: true },
          );
        }
        if (tenantProfile) {
          commit('SET_TENANT_PROFILE_FROM_FIREBASE', tenantProfile);
        }
        if (tenantProfileCreateBase) {
          commit('SET_TENANT_PROFILE_CREATE_FROM_FIREBASE', tenantProfileCreateBase);
        }
        console.log('get tenant profile - finished');
        return Promise.resolve()
          .then(() =>
            dispatch(
              'users/setupUserTrackers',
              {
                email: getters.tenantProfileEmail,
                tenantProfileFromFirebase: getters.tenantProfileFromFirebase,
              },
              { root: true },
            ),
          )
          .then(() => {
            // Due to removal of TP lets not fetch fetchSuggestedApartments ahead of time. Keeping implementation for later removal.
            return Promise.resolve();

            // Escape the processing if tenant profile does not exist.
            if (!isObject(tenantProfile)) {
              return Promise.resolve();
            }
            // Fetch suggested apartments for the profile.
            const requestData = {
              price: tenantProfile.price,
              // Currently patching with https://app.clickup.com/t/1xpe2mr but proper BE works needed!
              rooms: tenantProfile.roomsMin ? tenantProfile.roomsMin : 1,
              location: tenantProfile.location,
            };
            const fetchSuggestedApartmentsCanBeFetched =
              isNumber(requestData.price) &&
              isNumber(requestData.rooms) &&
              isString(requestData.location);
            // Fetch suggested apartments only when tenantProfile exists.
            return fetchSuggestedApartmentsCanBeFetched
              ? dispatch('suggestions/fetchSuggestedApartments', requestData, {
                  root: true,
                }).catch((err) => {
                  return dispatch(
                    'tracker/reportErrorToSentry',
                    new Error(
                      `fetchSuggestedApartments failed:` +
                        ` err: ${err.message}` +
                        ` tenantProfile: ${
                          tenantProfile ? JSON.stringify(tenantProfile) : tenantProfile
                        }`,
                    ),
                    { root: true },
                  );
                })
              : // Error trap for debugging through logging!
                dispatch(
                  'tracker/reportErrorToSentry',
                  new Error(
                    `Cannot request fetchSuggestedApartments:` +
                      ` fetchSuggestedApartmentsCanBeFetched: ${fetchSuggestedApartmentsCanBeFetched}` +
                      ` e: ${getters.tenantProfileEmail}` +
                      ` rd: ${JSON.stringify(requestData)}`,
                  ),
                  { root: true },
                );
          });
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
  /**
   * @param {TenantProfile} tenantProfile
   */
  postTenantProfile: function ({ dispatch, rootGetters }, tenantProfile) {
    return Promise.resolve()
      .then((data) =>
        dispatch(
          'firebase/functionCallRegionEU',
          {
            functionName: 'postTenantProfile',
            data: {
              ...tenantProfile,
              workflow: toUpper(rootGetters.getCountry),
              locale: rootGetters.getLocale,
            },
          },
          { root: true },
        ),
      )
      .then(() => dispatch('tenants/requestTenantProfile', undefined, { root: true }))
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
  /**
   * @param {TenantProfile} tenantProfile
   */
  putTenantProfile: function ({ dispatch }, tenantProfile) {
    return Promise.resolve()
      .then((data) =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'putTenantProfile', data: tenantProfile },
          { root: true },
        ),
      )
      .then(() => dispatch('tenants/requestTenantProfile', undefined, { root: true }))
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
  clearTenantProfileDataFromStore: function ({ commit }) {
    commit('SET_TENANT_PROFILE_CREATE_FROM_FIREBASE');
    commit('SET_TENANT_PROFILE_FROM_FIREBASE');
  },
  shareProfileInFacebook: function ({ getters, rootState, rootGetters }) {
    return Promise.resolve()
      .then(() => getters.tenantProfileFromFirebase)
      .then((tenantProfile) =>
        this.$axios.$post('/api/zapier-send', {
          // Zapier zap: https://zapier.com/app/editor/128368271/nodes/128368271/sample
          url: 'https://hooks.zapier.com/hooks/catch/9636938/b2i9fmh/',
          pl: {
            email: getters.tenantProfileEmail,
            first_name: tenantProfile.firstName,
            tenant_check: tenantProfile.scoringValue,
            country: rootState.country,
            meta_is_dev: rootGetters.isENVIsDevelopment,
            meta_description: tenantProfile.description,
          },
        }),
      );
  },
  setTenantProfileHidden: function ({ dispatch }) {
    return dispatch('setTenantProfileVisibility', false);
  },
  setTenantProfilePublic: function ({ dispatch }) {
    return dispatch('setTenantProfileVisibility', true);
  },
  setTenantProfileVisibility: function ({ dispatch }, isProfilePublic) {
    return Promise.resolve()
      .then(() => dispatch('putTenantProfile', { isProfilePublic: !!isProfilePublic }))
      .then(() => dispatch('tenants/requestTenantProfile', undefined, { root: true }))
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
};
