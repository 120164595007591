<template>
  <nuxt-link :to="targetRoute" @click="$emit('click')">
    <BaseNotificationTwoLine
      :created="notification?.created"
      :first-interacted-time="notification?.data.notification.firstInteractedTime"
      :subtitle="notificationSubtitle"
      :title="notificationBlok.title"
    />
  </nuxt-link>
</template>
<script>
import { get } from 'lodash';
import { replaceWithParameters } from '~/utils/strings';
import BaseNotificationTwoLine from '~/components/snippets/notifications/BaseNotificationTwoLine.vue';

export default {
  name: 'NotificationNewCandidate',
  components: { BaseNotificationTwoLine },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    notificationBlok: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
  computed: {
    notificationSubtitle() {
      if (!this.notificationBlok.subtitle) return '';
      return replaceWithParameters(this.notificationBlok.subtitle, {
        address: get(this.notification, 'data.address', ''),
      });
    },
    targetRoute() {
      if (!this.notification) return '';

      const advertisementId = get(this.notification, 'data.advertisementId', null);
      const agreementId = get(this.notification, 'data.agreementId', null);
      const candidateId = get(this.notification, 'data.candidateId', null);

      if (advertisementId) {
        return {
          name: 'object_view',
          params: { objectid: advertisementId, lang: this.$getLocale() },
          query: { tab: 'candidates', candidate_id: candidateId },
        };
        // return this.$localizedPath(`/dashboard/object/${advertisementId}`);
      } else if (agreementId) {
        return {
          name: 'agreement_view',
          params: { agreementid: agreementId, lang: this.$getLocale() },
          query: { tab: 'parties' },
        };
      }

      return '';
    },
  },
};
</script>
