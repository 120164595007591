<template>
  <r-overlay
    :prevent-close="!uploadingFinished"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <div class="image-upload-modal text-center px-4 sm:px-8 pt-12 pb-4">
      <div class="flex-grow flex flex-col justify-center mb-8 text-center">
        <div v-if="uploadingFinished">
          <r-icon
            v-if="numberOfLoadedImages > 0"
            class="text-4xl text-green-500"
            icon-name="circle-check"
          />
          <r-icon
            v-else
            class="text-4xl text-gray-500"
            icon-name="circle-exclamation"
          />
          <h3 class="font-medium text-xl mt-6 mb-4">
            {{ numberOfLoadedImages }}
            {{ $t('agreement.handover_v2.image_uploading_modal.out_of') }}
            {{ totalNumberOfImages }}
            {{ $t('agreement.handover_v2.image_uploading_modal.photos_uploaded') }}
          </h3>
        </div>

        <div v-else>
          <r-icon class="text-4xl text-rendin-500" icon-name="cloud-arrow-up" />
          <h3 class="font-medium text-xl mt-6 mb-4">
            {{ $t('agreement.handover_v2.image_uploading_modal.title') }}
          </h3>
          {{ $t('agreement.handover_v2.image_uploading_modal.disclaimer') }}

          <div class="text-gray-500 mt-4">
            {{ numberOfLoadedImages }}/{{ totalNumberOfImages }}
          </div>
          <div
            class="w-full max-w-sm m-auto h-1.5 bg-gray-200 mt-1 rounded-full relative animate-pulse overflow-hidden"
          >
            <span
              class="absolute bg-rendin-500 h-1.5 w-full rounded-full top-0 right-0 transition duration-300"
              :style="{
                transform: 'translate(' + calculatedTranslatePercentage + '%, 0)',
              }"
            ></span>
          </div>
        </div>

        <div
          v-if="showImagesMaxSizeError && maxImageSizeError"
          class="w-full max-w-sm m-auto text-error text-left mt-4"
        >
          <r-icon class="text-error mr-2" icon-name="circle-exclamation" />
          {{ maxImageSizeError }}
          <ul class="list-disc mt-2 ml-4">
            <li
              v-for="(fileName, index) in listOfFailedUploadImages"
              :key="index"
              class="text-error text-sm"
            >
              {{ fileName }}
            </li>
          </ul>
        </div>
      </div>

      <div class="flex justify-center items-center gap-6 mt-8 mb-4">
        <r-button
          v-if="uploadingFinished"
          :label="$t('buttons.close')"
          no-background
          no-padding
          size="small"
          @click="closeOverlay()"
        />
        <r-button
          v-else
          :label="$t('buttons.cancel')"
          no-background
          no-padding
          size="small"
          @click="cancelLoading()"
        />
      </div>
    </div>
  </r-overlay>
</template>
<script>
export default {
  name: 'ImageUploadModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    totalNumberOfImages: {
      type: Number,
      default: 0,
    },
    numberOfLoadedImages: {
      type: Number,
      default: 0,
    },
    showImagesMaxSizeError: {
      type: Boolean,
      default: true,
    },
    maxImageSizeError: {
      type: String,
      default: 'Image should not be bigger than 7.5MB',
    },
    listOfFailedUploadImages: {
      type: Array,
      default: null,
    },
    uploadingFinished: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay', 'cancelLoading'],

  computed: {
    calculatedTranslatePercentage() {
      return this.numberOfLoadedImages
        ? ((this.numberOfLoadedImages - this.totalNumberOfImages) /
            this.totalNumberOfImages) *
            100
        : -100;
    },
  },

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },

    cancelLoading() {
      this.$emit('cancelLoading');
      this.closeOverlay();
    },
  },
};
</script>
