<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="how-to-use pt-16 pb-10 md:py-20 bg-white overflow-hidden"
  >
    <div class="max-w-screen-lg mx-auto px-4 sm:px-8">
      <div class="w-11/12 md:w-3/4 mx-auto text-center mb-10 sm:mb-16">
        <h2 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
          {{ blok.title }}
        </h2>
      </div>

      <div class="md:grid md:grid-cols-2">
        <div class="z-10">
          <div v-if="blok.tabs.length > 1" class="text-center md:text-left">
            <r-button
              v-for="(tabLabel, tabIndex) in blok.tabs"
              :key="tabIndex"
              class="mx-1 mb-4"
              :inverted="tabIndex !== activeTab"
              :label="tabLabel.tabTitle"
              @click="activeTab = tabIndex"
            />
          </div>

          <div v-for="(tab, contentIndex) in blok.tabs" :key="contentIndex">
            <div v-if="contentIndex === activeTab">
              <div
                v-for="(step, stepIndex) in tab.steps"
                :key="stepIndex"
                class="how-to-use__step transition duration-200 ease-in-out relative rounded-md border-2 border-transparent my-3 p-4 pl-20 flex shadow-lg md:cursor-pointer"
                :class="{
                  'md:border-rendin-500 how-to-use__step--active':
                    activeStep === stepIndex,
                }"
                @click="activeStep = stepIndex"
                @mouseenter="activeStep = stepIndex"
              >
                <span
                  class="font-bold text-2xl bg-white w-9 h-9 rounded-full flex justify-center items-center mr-8 shadow-lg absolute left-4"
                >
                  {{ stepIndex + 1 }}
                </span>
                <div class="text-sm">
                  <h4 class="font-bold uppercase">
                    {{ step.stepTitle }}
                  </h4>
                  <RichText :blok="step.stepDescription" class="-my-2" />
                </div>
              </div>

              <div class="mt-8 md:mt-12 text-center md:text-left">
                <div v-for="btn in tab.button" :key="btn.uuid">
                  <div v-if="btn && btn.component === 'ButtonBlok'">
                    <ButtonBlok ref="cta_button" :blok="btn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="how-to-use__img hidden md:flex justify-center items-center">
          <div class="relative inline-block">
            <div v-for="(tab, contentIndex) in blok.tabs" :key="contentIndex">
              <div v-if="contentIndex === activeTab">
                <div
                  v-for="(step, stepIndex) in tab.steps"
                  :key="stepIndex"
                  class="how-to-use__img__screen__wrap"
                >
                  <div v-if="stepIndex === activeStep && tab.illustration === 'mobile'">
                    <img
                      class="how-to-use__img__frame"
                      height="780"
                      src="/images/howToUsePhone.png"
                      width="400"
                    />
                    <div
                      class="how-to-use__img__screen-sm absolute block bg-no-repeat bg-contain"
                      :style="{
                        'background-image': `url('${$transformStoryblokImage(step.stepImage.filename, '/m/fit-in/600x0/')}')`,
                      }"
                    ></div>
                  </div>

                  <div v-if="stepIndex === activeStep && tab.illustration === 'laptop'">
                    <img
                      class="how-to-use__img__frame ml-32"
                      height="635"
                      src="/images/howToUseLaptop.png"
                      width="1008"
                    />
                    <div
                      class="how-to-use__img__screen-lg absolute block bg-no-repeat bg-contain"
                      :style="{
                        'background-image':
                          'url(' +
                          $transformStoryblokImage(
                            step.stepImage.filename,
                            '/m/fit-in/1800x0/',
                          ) +
                          ')',
                      }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowToUse',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: 0,
      activeStep: 0,
    };
  },
  watch: {
    activeTab: function () {
      this.activeStep = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.how-to-use {
  &__step {
    background: rgba(255, 255, 255, 0.9);

    &--active {
      background: white;
    }
  }

  &__img {
    &__frame {
      max-width: initial;
    }

    &__screen-sm {
      top: 51px;
      right: 27px;
      width: 315px;
      height: 628px;
      border-radius: 20px;
      animation-name: fadeIn;
      animation-duration: 1s;
    }

    &__screen-lg {
      top: 31px;
      right: 119px;
      width: 770px;
      height: 480px;
      background-color: white;
      background-position: center;
      animation-name: fadeIn;
      animation-duration: 1s;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
