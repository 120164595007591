<template>
  <div class="mt-1 r-input-address">
    <div>
      <label
        v-if="label || $slots.default"
        :class="[
          labelClass,
          {
            'text-error': hasValidationError,
            'font-medium': flavor === 'medium',
          },
        ]"
        :for="id"
      >
        <slot>{{ label }}</slot>
        <span v-if="required">{{ requiredLabel }}</span>
      </label>
      <div v-click-outside="hideDropdown" class="relative">
        <input
          :id="id"
          ref="cityInput"
          v-model="cityInputValue"
          autocomplete="off"
          class="focus:outline-none w-full text-sm leading-4 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm px-3 py-3 pr-10 h-12"
          :class="classes"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          type="text"
          @input="onCityInput"
        />
        <span
          class="absolute z-10 right-3 top-2 text-sm text-gray-500"
          @click="[findCity(), showDropdown()]"
        >
          <r-icon class="text-lg" icon-name="search" />
        </span>

        <div
          v-if="searchDropdownOpen"
          class="absolute z-10 right-0 left-0 top-13 w-full bg-white shadow max-w-full max-h-72 overflow-y-scroll"
        >
          <div v-if="findAddressFailed" class="text-error p-4">
            {{ $t('form.validation.address_search_failed') }}
          </div>
          <div
            v-if="findAddressInProgress && searchResults.length === 0"
            class="flex p-2"
          >
            <r-loader size="20" />
          </div>
          <ul v-else>
            <div v-if="searchResults?.length > 0">
              <li
                v-for="(result, index) in searchResults"
                :key="index"
                class="py-2 px-4 hover:bg-gray-50 focus:bg-gray-50 border-b"
                @click="selectCity(result)"
              >
                <span
                  v-html="
                    highlightSubstringsInStringAsHTML(
                      result.addressCity,
                      cityInputValue,
                    )
                  "
                ></span>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="hasValidationError"
      class="text-error text-sm"
      :class="{ 'font-medium': flavor === 'medium' }"
    >
      <div v-for="(rule, index) in failedRules" :key="index">
        {{ rule }}
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
import { isEqual, debounce } from 'lodash';

export default {
  name: 'InputCitySearch',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Flavor for label font weight | [ `default`, `medium`,]
     */
    flavor: {
      type: String,
      default: 'default',
    },
    validationFailed: {
      type: Boolean,
    },
    validationPassed: {
      type: Boolean,
    },
    focusOnLoad: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
    },
    requiredLabel: {
      type: String,
      default: '*',
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    failedRules: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
    searchCountry: {
      type: String,
      required: true,
      validate(value) {
        return value === 'ee' || value === 'pl';
      },
    },
  },
  emits: ['selectCity', 'update:modelValue'],
  async setup() {
    const id = useId();

    return {
      id,
    };
  },
  data() {
    return {
      cityInputValue: '',
      searchResults: [],
      searchDropdownOpen: false,
      actions: {
        findAddressDebounced: () => undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      isENVIsProduction: 'isENVIsProduction',
      findAddressInProgress: 'address/findAddressInProgress',
      findAddressFailed: 'address/findAddressFailed',
    }),
    classes() {
      const classes = [];

      if (this.hasValidationError) {
        classes.push('border-error focus:border-error hover:border-error');
      }
      if (this.validationPassed) {
        classes.push(
          'border-success-strong focus:border-success-strong hover:border-success-strong',
        );
      }
      if (!this.disabled) {
        classes.push('focus:border-gray-500 hover:border-gray-500');
      } else {
        classes.push('cursor-not-allowed');
      }

      return classes;
    },
    hasValidationError() {
      const hasFailedRules = this.failedRules?.length > 0;

      return this.validationFailed || hasFailedRules;
    },
  },
  watch: {
    modelValue: {
      handler(newCity) {
        if (newCity) {
          this.cityInputValue = newCity;
        }
      },
      deep: true,
    },
  },
  created() {
    this.actions.findAddressDebounced = debounce(() => {
      if (typeof this.cityInputValue !== 'string') {
        return;
      }
      // start search when more than 2 letters inserted
      if (this.cityInputValue.length > 2) {
        this.actionFindCity({
          city: this.cityInputValue,
          country: this.searchCountry,
        }).then(({ response }) => {
          this.searchResults = [...response.results];
          this.trackCitySearchEvent({
            addressInputValue: this.cityInputValue,
            findAddressResult: response.results,
            source: this.source,
          });
        });
      }
    }, 400);

    this.cityInputValue = this.modelValue;
  },
  mounted() {
    if (this.focusOnLoad) {
      this.$nextTick(() => {
        this.$refs.cityInput.focus();
      });
    }
  },
  methods: {
    ...mapActions({
      actionFindCity: 'address/findCity',
      trackCitySearchEvent: 'tracker/trackCitySearchEvent',
      trackCitySelectClickEvent: 'tracker/trackCitySelectClickEvent',
    }),
    findCity() {
      this.actions.findAddressDebounced();
    },
    showDropdown() {
      this.searchDropdownOpen = true;
    },
    hideDropdown() {
      this.searchDropdownOpen = false;

      this.clearSearchResults();
    },
    selectCity(city) {
      this.trackCitySelectClickEvent({
        selectedAddress: city,
        addressInputValue: this.cityInputValue,
        findAddressResult: this.searchResults,
        source: this.source,
      });

      this.cityInputValue = city.addressCity;
      this.searchDropdownOpen = false;

      // emit selected address object on
      this.$emit('update:modelValue', city.addressCity);
      this.$emit('selectCity', city.addressCity);
    },

    clearSearchResults() {
      this.searchResults = [];
    },
    highlightSubstringsInStringAsHTML(string, searchWord) {
      if (searchWord?.length >= 1) {
        return string.replace(new RegExp(searchWord, 'gi'), (match) => {
          return `<span class="font-bold">${match}</span>`;
        });
      } else {
        return string;
      }
    },
    onCityInput() {
      this.searchDropdownOpen = true;
      if (!isEqual(this.cityInputValue, '')) {
        this.resetAddress();
      }

      if (this.cityInputValue < 3) {
        this.clearSearchResults();
      }

      this.findCity();
    },
    resetAddress() {
      if (this.modelValue !== null && this.modelValue !== '') {
        this.$emit('update:modelValue', null);
      }
    },
  },
};
</script>
