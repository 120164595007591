<template>
  <div class="advertisement-invite-apply-form">
    <div
      v-if="!isAgreementInvite"
      class="bg-peach-100 rounded-lg p-4 mb-6 hidden md:block"
    >
      <h3 class="text-lg font-semibold">
        {{ $t('advertisement_invite.contact_the_landlord') }}
      </h3>
      <r-profile-info-card
        :copiable="landlordInfoCopiable"
        :email="invite?.contact?.email"
        :full-name="invite?.contact?.firstName + ' ' + invite?.contact?.lastName"
        no-padding
        :phone-number="
          constructPhoneNr(
            invite?.contact?.phoneCountryCode,
            invite?.contact?.phoneNumber,
          )
        "
      />
    </div>
    <r-title-with-text
      class="pb-4"
      :content="
        isAgreementInvite
          ? $t('advertisement_invite.apply_form.content_agreement_invite')
          : $t('advertisement_invite.apply_form.content')
      "
      content-class="whitespace-pre-line"
      content-font-size="16px"
      :title="
        isAgreementInvite
          ? $t('advertisement_invite.apply_form.title_agreement_invite')
          : $t('advertisement_invite.apply_form.title')
      "
      :title-class="
        isAgreementInvite ? 'text-2xl font-semibold' : 'text-xl font-semibold'
      "
    />
    <r-profile-info-card
      v-if="profile"
      black-button-icon="pen"
      :black-text-button-text="$t('buttons.change_information')"
      button-width="full"
      class="bg-peach-100 rounded-lg"
      :email="profile.email"
      :full-name="profile.fullName"
      :phone-number="constructPhoneNr(profile?.phoneCountryCode, profile?.phoneNumber)"
      :title="$t('advertisement_invite.apply_form.profile_card_title')"
      @black-text-button-emit-click-event="redirectToTenantProfileSettings"
    />
    <r-textarea
      v-if="!isAgreementInvite"
      v-model="message"
      class="pb-6"
      :label="$t('advertisement_invite.apply_form.message_label')"
      name="message"
    />
    <div v-if="isAgreementInvite" class="font-bold my-6">
      {{ $t('advertisement_invite.apply_form.correct_data_note') }}
    </div>

    <r-sticky-container reserve-space :sticky="stickyPrimaryButton">
      <r-button
        :is-loading="emitApplyInProgress"
        :label="
          isAgreementInvite
            ? $t('advertisement_invite.apply_form.accept_invite')
            : $t('advertisement_invite.apply_form.button_show_contact_information')
        "
        width="full"
        @click="applyButtonEmitClickEvent"
      />
    </r-sticky-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { redirectLinks } from '~/utils/constants';
import RStickyContainer from '~/components/r-interface-components/r-sticky-container';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';

export default {
  name: 'AdvertisementInviteApplyForm',
  components: { RStickyContainer },
  props: {
    profile: {
      type: Object,
    },
    emitApplyInProgress: {
      type: Boolean,
      default: false,
    },
    stickyPrimaryButton: {
      type: Boolean,
      default: false,
    },
    isAgreementInvite: {
      type: Boolean,
      default: false,
    },
    invite: {
      type: Object,
      default: null,
    },
  },
  emits: ['applyButtonClicked', 'textareaChange'],

  setup() {
    return {
      constructPhoneNr,
    };
  },

  data() {
    return {
      message: '',
      landlordInfoCopiable: false,
    };
  },

  computed: {
    ...mapGetters({
      tenantProfileFromFirebase: 'tenants/tenantProfileFromFirebase',
      getCountry: 'getCountry',
    }),
  },

  watch: {
    message() {
      this.textareaChange();
    },
    tenantProfileFromFirebase() {
      this.message =
        this.tenantProfileFromFirebase?.description ||
        this.$t('advertisement_invite.apply_form.message_placeholder')[this.getCountry];
    },
  },

  mounted() {
    this.message =
      this.tenantProfileFromFirebase?.description ||
      this.$t('advertisement_invite.apply_form.message_placeholder')[this.getCountry];
  },

  methods: {
    redirectToTenantProfileSettings() {
      this.$router.push({
        path: this.$localizedPath(redirectLinks.TENANT_PROFILE_SETTINGS),
      });
    },

    applyButtonEmitClickEvent(event) {
      /**
       * Click event.
       *
       * @event click
       * @type {object}
       */
      this.$emit('applyButtonClicked', event);
    },
    textareaChange(event) {
      /**
       * Click event.
       *
       * @event click
       * @type {object}
       */
      this.$emit('textareaChange', this.message);
    },
  },
};
</script>
