<template>
  <div v-if="blok.country === $getCountry()" class="py-4">
    <div class="max-w-5xl mx-auto">
      <ul class="grid grid-cols-3 md:flex justify-center">
        <li
          v-for="item in blok.items"
          :key="item._uid"
          class="py-1 md:py-4 flex justify-center items-center content-center px-2 md:px-6 h-20"
        >
          <a :href="item.link" rel="nofollow" target="_blank">
            <img
              :alt="item.image.alt"
              class="w-10/12 md:w-auto md:h-auto"
              :src="$transformStoryblokImage(item.image.filename, '/m/')"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetLogos',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
