<template>
  <r-overlay prevent-close size="xsmall" :visible="showModal" @close="closeOverlay()">
    <section class="p-4 min- h-40 flex">
      <r-loader />
    </section>
  </r-overlay>
</template>
<script>
export default {
  name: 'LoadingModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeOverlay'],

  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>
