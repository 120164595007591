<template>
  <div
    v-editable="blok"
    class="flex flex-col items-start justify-start w-full h-auto mt-12 mb-12 lg:mb-0 border-2 border-gray-200 rounded-md p-3 shadow-sm"
  >
    <blockquote
      class="text-md text-gray-600"
      :class="{ 'mb-2': !blok.image.filename, 'mb-4': blok.image.filename }"
    >
      {{ blok.text }}
    </blockquote>
    <div class="flex items-center justify-center">
      <div
        v-if="blok.image.filename"
        class="w-10 h-10 mr-3 overflow-hidden bg-gray-200 rounded-full"
      >
        <img class="object-cover w-full h-full" :src="blok.image.filename" />
      </div>
      <div class="flex flex-col items-start justify-center">
        <h4 class="font-medium text-gray-800">- {{ blok.name }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnippetFeatureTestimonial',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
