<template>
  <section
    :id="blok.section_id"
    v-editable="blok"
    class="py-16 md:py-20 border-b border-gray-200 px-3 md:px-0"
  >
    <div class="mx-auto max-w-6xl">
      <div
        v-if="blok.title || blok.description"
        class="md:w-3/4 lg:w-3/5 mx-auto text-center mb-10 md:mb-20"
      >
        <h2 class="text-3xl md:text-4xl leading-tight mb-4 font-bold">
          {{ blok.title }}
        </h2>
        <p class="text-lg text-gray-800">
          {{ blok.description }}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-10">
        <component
          :is="blok.component"
          v-for="blok in blok.parties"
          :key="blok._uid"
          :blok="blok"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionParties',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
