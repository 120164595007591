<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="content-user-stories pt-10 md:pt-20 px-4 bg-gray-50 overflow-hidden"
  >
    <div class="w-11/12 md:w-3/4 mx-auto text-center mb-10 sm:mb-16">
      <h2 class="text-2xl md:text-4xl leading-tight mb-6 font-bold">
        {{ blok.title }}
      </h2>
    </div>

    <div class="max-w-6xl mx-auto">
      <div
        v-for="section in blok.section"
        :key="section.uuid"
        class="md:grid md:grid-cols-2 my-10 md:my-20"
      >
        <div>
          <h3 class="text md:text-2xl uppercase font-bold">
            {{ section.title }}
          </h3>
          <RichText v-if="section.content" :blok="section.content" class="my-6" />
          <RichText
            v-if="section.contentHighlighted"
            :blok="section.contentHighlighted"
            class="text-rendin-500 my-6"
          />

          <div class="bg-white rounded-lg shadow-xl p-8 pb-4">
            <h4 class="text-rendin-500 font-bold uppercase text-sm">
              {{ section.userStoryTitle }}
            </h4>
            <RichText
              v-if="section.userStoryContent"
              :blok="section.userStoryContent"
              class="text-sm"
            />
          </div>
        </div>
        <div
          :class="{
            'md:order-first': section.imagePosition === 'left',
          }"
        >
          <div
            class="bg-no-repeat bg-contain bg-top -mx-4 my-4 p-4 md:my-0 flex justify-center"
            :class="{
              'content-user-stories__img-left md:justify-start md:bg-left md:mr-4 md:pt-8 md:pb-24 md:pr-8 lg:-ml-24 lg:pl-24 xl:pl-20 xl:mr-16':
                section.imagePosition === 'left',
              'content-user-stories__img-right md:justify-end md:bg-left md:ml-4 md:pt-12 md:pb-8 md:pl-8 xl:-mr-8':
                section.imagePosition === 'right',
            }"
          >
            <img
              :alt="section.image.alt"
              class="rounded-lg shadow-xl"
              :src="
                $transformStoryblokImage(section.image.filename, '/m/480x380/smart/')
              "
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentWithUserStories',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.content-user-stories {
  &__img-left {
    background-image: url('/images/cute-circles-left.svg');
  }

  &__img-right {
    background-image: url('/images/cute-circles-right.svg');
  }
}
</style>
