<template>
  <section class="handover-edit-view-condition-or-cleaning">
    <h2 class="text-xl md:text-3xl font-medium mb-6">{{ titleTranslation }}*</h2>

    <Form v-slot="{ meta }" ref="cleaningConditionDataForm">
      <Field v-model="status" rules="required" name="condition">
        <div v-if="!meta.valid" class="text-error flex items-center">
          <r-icon class="md:text-lg w-8 shrink-0" icon-name="circle-info" />
          {{ $t('form.validation.is_required') }}
        </div>
        <r-radio
          v-model="status"
          flavor="border-between"
          name="condition"
          option-label="label"
          :options="dataOption"
          radio-box-right
          @change="putHandoverData()"
        />
      </Field>

      <r-textarea
        v-model="comment"
        class="mt-6"
        flavor="medium"
        :label="$t('agreement.handover_v2.add_comments')"
        name="condition-comment"
        rows="4"
        @blur="meta.valid ? putHandoverData() : null"
        @input="$refs.cleaningConditionDataForm.validate()"
      />
    </Form>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import { handoverDataTypes } from '~/utils/constants.js';
import { Field, Form } from 'vee-validate';

const handoverConditionOrCleaningStatus = {
  BAD: 'BAD',
  MEDIOCRE: 'MEDIOCRE',
  GOOD: 'GOOD',
  GREAT: 'GREAT',
  EXCELLENT: 'EXCELLENT',
};

export default {
  name: 'HandoverEditViewConditionOrCleaning',
  components: {
    Form,
    Field,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    /*  'condition' or 'cleaning' */
    handoverDataType: {
      type: String,
      default: handoverDataTypes.CONDITION,
    },
    validate: {
      type: Number,
      default: null,
    },
  },

  emits: ['inputDataForEntity'],

  data: (vm) => ({
    status: null,
    comment: null,
    pureStr: null,

    handoverDataTypes,
    conditionOptions: [
      {
        value: handoverConditionOrCleaningStatus.BAD,
        label: vm.$t('agreement.handover_v2.condition.bad'),
        icon: 'house-crack',
      },
      {
        value: handoverConditionOrCleaningStatus.MEDIOCRE,
        label: vm.$t('agreement.handover_v2.condition.mediocre'),
        icon: 'clock',
      },
      {
        value: handoverConditionOrCleaningStatus.GOOD,
        label: vm.$t('agreement.handover_v2.condition.good'),
        icon: 'hammer',
      },
      {
        value: handoverConditionOrCleaningStatus.GREAT,
        label: vm.$t('agreement.handover_v2.condition.great'),
        icon: 'paint-roller',
      },
      {
        value: handoverConditionOrCleaningStatus.EXCELLENT,
        label: vm.$t('agreement.handover_v2.condition.excellent'),
        icon: 'stars',
      },
    ],
    cleaningOptions: [
      {
        value: handoverConditionOrCleaningStatus.BAD,
        label: vm.$t('agreement.handover_v2.cleanliness.bad'),
        icon: 'circle-xmark',
      },
      {
        value: handoverConditionOrCleaningStatus.MEDIOCRE,
        label: vm.$t('agreement.handover_v2.cleanliness.mediocre'),
        icon: 'broom',
      },
      {
        value: handoverConditionOrCleaningStatus.GOOD,
        label: vm.$t('agreement.handover_v2.cleanliness.good'),
        icon: 'vacuum',
      },
      {
        value: handoverConditionOrCleaningStatus.GREAT,
        label: vm.$t('agreement.handover_v2.cleanliness.great'),
        icon: 'soap',
      },
      {
        value: handoverConditionOrCleaningStatus.EXCELLENT,
        label: vm.$t('agreement.handover_v2.cleanliness.excellent'),
        icon: 'hand-sparkles',
      },
    ],
  }),

  computed: {
    titleTranslation() {
      return this.handoverDataType === handoverDataTypes.CLEANING
        ? this.$t('agreement.handover_v2.cleanliness.how_clean')
        : this.$t('agreement.handover_v2.condition.how_condition');
    },

    dataOption() {
      return this.handoverDataType === handoverDataTypes.CLEANING
        ? this.cleaningOptions
        : this.conditionOptions;
    },
  },

  watch: {
    handoverDataType() {
      this.fillEditModeData();
      this.$refs.cleaningConditionDataForm.resetForm();
    },
    validate(val) {
      if (val) {
        this.$refs.cleaningConditionDataForm.validate();
      }
    },
  },

  mounted() {
    this.fillEditModeData();
    this.$refs.cleaningConditionDataForm.resetForm();
  },

  methods: {
    ...mapActions({
      actionPutHandover: 'handover/putHandover',
      actionGetHandover: 'handover/getHandover',
    }),

    fillEditModeData() {
      if (
        this.handoverDataType === handoverDataTypes.CLEANING &&
        this.agreementHandover?.handover?.cleaning
      ) {
        this.status = this.agreementHandover.handover.cleaning?.status;
        this.comment = this.agreementHandover.handover.cleaning?.comment;
        this.pureStr = this.agreementHandover.handover.cleaning?.pureStr;
      } else if (
        this.handoverDataType === handoverDataTypes.CONDITION &&
        this.agreementHandover?.handover?.condition
      ) {
        this.status = this.agreementHandover.handover.condition?.status;
        this.comment = this.agreementHandover.handover.condition?.comment;
        this.pureStr = this.agreementHandover.handover.condition?.pureStr;
      } else {
        this.status = null;
        this.comment = null;
        this.pureStr = null;
      }
    },

    getHandover() {
      return this.actionGetHandover({ agreementId: this.agreementId });
    },

    putHandoverData() {
      const includedData = {
        [this.handoverDataType]: {
          status: this.status,
          comment: this.comment || null,
          pureStr: this.dataOption.filter((el) => el.value === this.status)[0]?.label,
        },
      };

      if (this.agreementHandover?.handover?.entityId) {
        // send data to server
        this.actionPutHandover({
          entityId: this.agreementHandover?.handover?.entityId,
          includedData: includedData,
        }).then(() => {
          this.getHandover();
        });

        // emit data information about sending info for parent components
        this.$emit('inputDataForEntity', includedData);
      }
    },
  },
};
</script>
