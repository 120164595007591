<template>
  <section v-if="agreementValidationsFromServer?.length > 0" class="mb-2">
    <r-alert :type="alertTypes.WARNING">
      <ul class="list-disc ml-4">
        <li v-for="(error, index) in agreementValidationsFromServer" :key="index">
          {{ agreementValidationErrorTranslation(error) }}
        </li>
      </ul>
    </r-alert>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import { agreementValidationErrorTranslation } from '~/composables/agreementValidationTranslationsConstructor.js';
import { alertTypes } from '~/components/r-interface-components/utils/constants';

/**
 * Component for rendering errors from if status changes
 */
export default {
  name: 'ServerErrorsForAgreementStatusChangeToSigning',

  props: {
    agreementValidationsFromServer: {
      type: Array,
      default: null,
    },
  },

  setup() {
    return {
      agreementValidationErrorTranslation,
    };
  },

  data() {
    return {
      alertTypes,
    };
  },

  beforeUnmount() {
    this.mutationClearPutAgreementStatus();
  },

  methods: {
    ...mapMutations({
      mutationClearPutAgreementStatus: 'agreements/CLEAR_PUT_AGREEMENT_STATUS',
    }),
  },
};
</script>
