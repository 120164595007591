<template>
  <div>
    <span v-if="blok.text">
      {{ blok.text }}
    </span>
    <span v-if="blok.seconds && blok.seconds > 0" class="font-bold">
      {{ timeLeft }} {{ $t('form.time.seconds').toLowerCase() }}
    </span>
    <p
      v-if="timeLeft === 0 && blok.actionBlockedText"
      class="text-rendin-400 cursor-pointer"
      @click="makeAction()"
    >
      {{ blok.actionBlockedText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DelayActionBlok',
  props: {
    blok: {
      type: Object,
    },
  },

  data() {
    return {
      timeLeft: null,
      timer: null,
      actionType: {
        OPEN_TAB: 'OPEN_TAB',
        REDIRECT: 'REDIRECT',
      },
    };
  },
  mounted() {
    if (this.blok.seconds && this.blok.seconds > 0) this.startTimer(this.blok.seconds);
  },

  methods: {
    makeAction() {
      if (this.blok.actionLink) {
        if (this.blok.actionLink?.linktype === 'story') {
          if (this.blok.action === this.actionType.REDIRECT) {
            this.$router.push({
              path: this.$localizedPath(this.blok.actionLink.cached_url),
            });
          } else if (this.blok.action === this.actionType.OPEN_TAB) {
            window.open(this.$localizedPath(this.blok.actionLink.cached_url));
          }
        } else if (this.blok.actionLink?.linktype === 'url') {
          if (this.blok.action === this.actionType.REDIRECT) {
            window.location.href = this.blok.actionLink?.url;
          } else if (this.blok.action === this.actionType.OPEN_TAB) {
            window.open(this.blok.actionLink?.url);
          }
        }
      }
    },

    startTimer(duration) {
      this.timeLeft = duration;
      this.timer = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          clearInterval(this.timer);
          this.timeLeft = 0;
          this.makeAction();
        }
      }, 1000);
    },
  },
};
</script>
