<template>
  <div v-editable="blok">
    <div class="mb-12">
      <template v-if="!blok.articleTranslationHidden">
        <div class="max-w-7xl mx-auto mt-6 px-4">
          <r-button
            border-color="white"
            color="black"
            icon="arrow-left-long"
            inverted
            :label="$t('see_all_articles')"
            @click="goToPreviousPage($localizedPath(redirectLinks.CASE_STUDIES))"
          />
        </div>
        <!--        Header -->
        <article-hero-section
          :author-name="blok?.authorName"
          :hide-old-content-warning="blok?.hideOldContentWarning"
          :image="blok?.image?.filename"
          :image-desktop="blok?.imageDesktop?.filename"
          :intro="blok?.intro"
          no-top-padding
          :published-date="blok?.first_published_at"
          :title="blok?.title"
        />
        <!--        Content -->
        <article-content :content="blok.content" />

        <div>
          <component
            :is="contentBlok.component"
            v-for="contentBlok in blok.postContent"
            :key="contentBlok._uid"
            :blok="contentBlok"
          />
        </div>

        <div class="max-w-5xl mx-auto">
          <component
            :is="contentBlok.component"
            v-for="contentBlok in blok.caseStudies"
            :key="contentBlok._uid"
            :blok="contentBlok"
          />
        </div>
      </template>

      <template v-else>
        <div v-if="globalBlock" class="max-w-3xl mx-auto my-12 w-full min-h-screen">
          <h1 class="text-2xl font-bold">{{ globalBlock.title }}</h1>
          <RichText v-if="globalBlock.content" :blok="globalBlock.content" />

          <r-button
            border-color="light-gray"
            color="black"
            icon="arrow-left-long"
            icon-class="mr-2"
            inverted
            :label="$t('buttons.back')"
            @click="goToPreviousPage($localizedPath(redirectLinks.CASE_STUDIES))"
          />
        </div>
      </template>
    </div>

    <component
      :is="contentBlok.component"
      v-for="contentBlok in blok.marketingSections"
      :key="contentBlok._uid"
      :blok="contentBlok"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { redirectLinks } from '~/utils/constants';
import ArticleHeroSection from '~/components/snippets/article/ArticleHeroSection';
import ArticleContent from '~/components/snippets/article/ArticleContent';
import { useArticleSeo } from '~/composables/useArticleSeo.ts';

export default {
  components: {
    ArticleHeroSection,
    ArticleContent,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup(props) {
    const { articleHeadMetadata } = useArticleSeo(props.blok);

    useHead(articleHeadMetadata);
  },
  data() {
    return {
      globalBlock: {},
      redirectLinks,
    };
  },

  computed: {
    ...mapGetters({
      getGlobalBloks: 'getGlobalBloks',
    }),
  },

  watch: {
    getGlobalBloks: {
      deep: true,
      handler(newVal) {
        this.globalBlock = newVal.ArticleTranslationFallback;
      },
    },
  },

  created() {
    this.globalBlock = this.getGlobalBloks.ArticleTranslationFallback;
  },

  methods: {
    ...mapActions({
      actionGoToPreviousPage: 'routerHistory/goToPreviousPage',
    }),

    goToPreviousPage(fallbackUrl) {
      this.actionGoToPreviousPage(fallbackUrl);
    },
  },
};
</script>
