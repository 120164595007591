<template>
  <div class="listing-actions">
    <div
      :class="{
        'listing-actions__overlay fixed z-40 top-0 bottom-0 right-0 left-0 bg-gray-800 opacity-30':
          showOverlay,
      }"
    ></div>

    <r-dropdown v-model="showOverlay" align="right">
      <div class="button-wrapper relative">
        <r-button
          border-color="light-gray"
          color="black"
          hover="hover:text-rendin-500 hover:shadow-md"
          icon="ellipsis-vertical"
          icon-class="ml-2"
          icon-position-after
          icon-prefix="solid"
          inverted
          :label="$t('listing_actions.listing_actions')"
        />
      </div>

      <template #dropdown>
        <ul
          class="listing-actions__dropdown border bg-white px-6 py-4 rounder-lg flex flex-col gap-5"
        >
          <li>
            <r-button
              color="black"
              icon="pen"
              icon-class="mr-2"
              :label="$t('listing_actions.edit_listing')"
              no-background
              no-padding
              @click="onClickEditListing"
            />
          </li>
          <li v-if="listingStatus === advertisementStatuses.ACTIVE">
            <r-button
              color="black"
              icon="share-nodes"
              icon-class="mr-2"
              :label="$t('listing_actions.share_listing')"
              no-background
              no-padding
              @click="onClickShareListing"
            />
          </li>
          <li>
            <r-button
              color="black"
              icon="copy"
              icon-class="mr-2"
              :label="$t('listing_actions.duplicate_listing')"
              no-background
              no-padding
              @click="onClickDuplicateListing"
            />
          </li>
          <li
            v-if="
              showStatusChangeSwitch &&
              listingStatus === advertisementStatuses.NOT_ACTIVE
            "
          >
            <r-button
              color="black"
              icon="play"
              icon-class="mr-2"
              :label="$t('listing_actions.activate_listing')"
              no-background
              no-padding
              @click="onClickActivateListing"
            />
          </li>
          <li
            v-if="
              showStatusChangeSwitch && listingStatus === advertisementStatuses.ACTIVE
            "
          >
            <r-button
              color="black"
              icon="circle-pause"
              icon-class="mr-2"
              :label="$t('listing_actions.deactivate_listing')"
              no-background
              no-padding
              @click="onClickDeactivateListing"
            />
          </li>
          <li>
            <r-button
              color="red"
              icon="trash"
              icon-class="mr-2"
              :label="$t('listing_actions.remove_listing')"
              no-background
              no-padding
              @click="onClickRemoveListing"
            />
          </li>
        </ul>
      </template>
    </r-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RDropdown from '~/components/r-interface-components/r-dropdown.vue';
import { advertisementStatuses } from '~/utils/constants';

export default {
  name: 'ListingActions',
  components: { RDropdown },
  props: {
    listingStatus: {
      type: String,
      default: '',
    },
    showStatusChangeSwitch: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'clickEditListing',
    'clickShareListing',
    'clickDuplicateListing',
    'clickDeactivateListing',
    'clickActivateListing',
    'clickRemoveListing',
  ],

  data() {
    return {
      showOverlay: false,
      advertisementStatuses,
    };
  },

  beforeUnmount() {
    this.removeBodyClassOverflowHidden();
  },

  methods: {
    ...mapActions({
      addBodyClassOverflowHidden: 'addBodyClassOverflowHidden',
      removeBodyClassOverflowHidden: 'removeBodyClassOverflowHidden',
    }),

    isOpen(state) {
      if (state) {
        this.openOverlay();
      } else {
        this.closeOverlay();
      }
    },

    openOverlay() {
      this.showOverlay = true;
      this.addBodyClassOverflowHidden();
    },

    closeOverlay() {
      this.showOverlay = false;
      this.removeBodyClassOverflowHidden();
    },

    onClickEditListing() {
      this.$emit('clickEditListing');
      this.closeOverlay();
    },
    onClickShareListing() {
      this.$emit('clickShareListing');
      this.closeOverlay();
    },
    onClickDuplicateListing() {
      this.$emit('clickDuplicateListing');
      this.closeOverlay();
    },
    onClickDeactivateListing() {
      this.$emit('clickDeactivateListing');
      this.closeOverlay();
    },
    onClickActivateListing() {
      this.$emit('clickActivateListing');
      this.closeOverlay();
    },
    onClickRemoveListing() {
      this.$emit('clickRemoveListing');
      this.closeOverlay();
    },
  },
};
</script>
