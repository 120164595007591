<template>
  <div v-editable="blok">
    <r-button
      :border-color="blok.borderColor"
      :color="blok.buttonColor"
      :icon="blok.buttonIcon"
      icon-class="ml-2 -mr-1"
      icon-position-after
      :inverted="blok.buttonStyle === 'secondary'"
      :label="blok.buttonLabel"
      :no-background="blok.buttonStyle === 'link'"
      :shape="blok.buttonShape"
      :size="blok.buttonSize"
      :width="blok.buttonWidth ? blok.buttonWidth : 'wide'"
      @click="showOverlay = !showOverlay"
    />
    <r-overlay size="small" :visible="showOverlay" @close="showOverlay = false">
      <div class="p-4 sm:px-12 py-8">
        <RichText v-if="blok.modalContent" :blok="blok.modalContent" />

        <r-button
          display="block"
          :label="$t('buttons.close')"
          no-background
          uppercase
          @click="showOverlay = false"
        />
      </div>
    </r-overlay>
  </div>
</template>

<script>
export default {
  name: 'ButtonWithContentModal',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
    };
  },
};
</script>
