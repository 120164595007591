<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="progress-counter py-10 md:py-16 px-4 bg-white"
  >
    <div
      ref="counterSection"
      class="max-w-6xl mx-auto shadow-lg rounded-xl sm:flex sm:justify-around p-6"
    >
      <div
        v-for="el in blok.list"
        :key="el._uid"
        class="text-center sm:text-left sm:border-b-0 border-b sm:border-l-2 border-rendin-500 px-4 py-1 my-2"
      >
        <div class="text-3xl font-bold block">
          <span ref="counterNum" :counterMax="el.number">0</span>
          <span class="-ml-1">{{ el.sufix }}</span>
        </div>
        <span class="font-light block">{{ el.label }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProgressCounter',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sectionVisible: false,
    };
  },

  watch: {
    sectionVisible: function () {
      this.startCounter();
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    startCounter() {
      const counters = this.$refs.counterNum;
      const speed = 25;

      counters.forEach((counter) => {
        const animate = () => {
          const value = Number(counter.getAttribute('counterMax'));
          const data = +counter.innerText;
          const increment = Math.ceil(value / speed);

          if (data < value) {
            counter.innerText = data + increment;
            setTimeout(animate, 30);
          } else {
            counter.innerText = Intl.NumberFormat(this.$store.state.locale).format(
              value,
            );
          }
        };

        animate();
      });
    },

    handleScroll() {
      if (
        window.scrollY +
          window.innerHeight -
          this.$refs.counterSection.offsetHeight / 2 >
        this.$refs.counterSection.offsetTop
      ) {
        this.sectionVisible = true;
      }
    },
  },
};
</script>
