<template>
  <section
    class="agreement-edit-view-property bg-white rounded-lg shadow-lg pt-4 pb-10 px-4 md:px-10"
  >
    <Form ref="propertyDataForm" autocomplete="on">
      <div class="text-rendin-700 uppercase font-medium my-4">
        {{ $t('agreement.label.rental_object_properties') }}
      </div>
      <!-- address info -->
      <div>
        <Field ref="fullAddressForm" v-model="fullAddressModel" name="fullAddressModel">
          <address-search-molecule
            v-model="fullAddressModel"
            :external-address="fullAddressModel"
            :override-failed="validationExtraAttention"
            required
            :source="source"
            @blur="onAddressInput"
          />
        </Field>
      </div>
      <div class="grid sm:grid-cols-2 gap-4 my-3">
        <!-- rooms info -->
        <Field
          ref="numberOfRoomsRef"
          v-slot="{ meta }"
          v-model="objectData.numberOfRooms"
          name="numberOfRooms"
          rules="required|numeric|min_value:1"
        >
          <r-input
            v-model="objectData.numberOfRooms"
            data-testid="input.agreement-edit-property.number-of-rooms"
            :error-text="$t('form.validation.numberOfRooms')"
            field-type="tel"
            :label="$t('form.label.numberOfRooms')"
            name="numberOfRooms"
            :placeholder="$t('form.placeholder.numberOfRooms')"
            required
            :validation-failed="
              (meta.validated && !meta.valid && meta.touched) ||
              (meta.validated && !meta.valid && !!objectData.numberOfRooms) ||
              (meta.validated && !meta.valid && validationExtraAttention)
            "
            :validation-passed="meta.validated && meta.valid"
            @blur="
              validateAndPutAgreement(
                {
                  numberOfRooms: objectData.numberOfRooms,
                },
                'numberOfRoomsRef',
              );
              meta.touched = true;
            "
          />
        </Field>
        <!-- area info -->

        <Field
          ref="objectAreaRef"
          v-slot="{ meta }"
          v-model="objectAreaModel"
          name="area"
          :rules="{ required: true, min_value: 0.1, isFloat: true }"
        >
          <r-input
            v-model="objectAreaModel"
            add-on="m2"
            data-testid="input.agreement-edit-property.object-area"
            :error-text="$t('form.validation.objectArea')"
            field-type="tel"
            inputmode="decimal"
            :label="$t('form.label.area')"
            name="area"
            :placeholder="$t('form.placeholder.area')"
            required
            :validation-failed="
              (meta.validated && !meta.valid && meta.touched) ||
              (meta.validated && !meta.valid && !!objectAreaModel) ||
              (meta.validated && !meta.valid && validationExtraAttention)
            "
            :validation-passed="meta.validated && meta.valid"
            @blur="
              validateAndPutAgreement(
                { objectArea: objectData.objectArea },
                'objectAreaRef',
              );
              meta.touched = true;
            "
          />
        </Field>
      </div>

      <div class="text-rendin-700 uppercase font-medium mt-10 mb-2">
        {{ $t('agreement.label.amenities') }}
      </div>

      <!-- additional info -->
      <div>
        <r-checkbox
          id="hasParking"
          v-model="objectData.hasParking"
          name="parking"
          @change="validateAndPutAgreement({ hasParking: objectData.hasParking })"
        >
          {{ $t('form.label.hasParking') }}
        </r-checkbox>
        <r-checkbox
          id="hasStorage"
          v-model="objectData.hasStorage"
          name="hasStorage"
          @change="validateAndPutAgreement({ hasStorage: objectData.hasStorage })"
        >
          {{ $t('form.label.hasStorage') }}
        </r-checkbox>
      </div>

      <!-- Pets-->
      <div class="text-rendin-700 uppercase font-medium mt-10 mb-2">
        {{ $t('agreement.label.pet_policy') }}
      </div>
      <div class="text-gray-600">
        {{ $t('agreement.label.pet_policy_disclaimer') }}
      </div>
      <r-checkbox
        id="petsAllowed"
        v-model="objectData.petsAllowed"
        name="petsAllowed"
        @change="validateAndPutAgreement({ petsAllowed: objectData.petsAllowed })"
      >
        {{ $t('form.label.petsAllowed') }}
      </r-checkbox>
      <!--    Agreement special notes -->
      <r-textarea
        v-if="objectData.petsAllowed"
        v-model="objectData.petsComment"
        :label="$t('agreement.label.pets_special_conditions')"
        name="agreementPetsComment"
        :placeholder="$t('form.placeholder.pets_special_conditions')"
        @blur="validateAndPutAgreement({ petsComment: objectData.petsComment })"
      />

      <!--        Showing button for agreement creation only-->
      <div v-if="!agreementId" class="max-w-sm mx-auto px-4 h-14 mt-12"></div>
    </Form>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { validate, Field, Form } from 'vee-validate';
import { webSourceType, agreementTypes, workflow } from '~/utils/constants';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import { defaultAddressObject } from '~/utils/objectStructures';
import { debounce } from 'lodash';

export default {
  name: 'AgreementEditViewProperty',
  components: {
    Form,
    Field,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    selectedAgreementType: {
      type: String,
      default: null,
    },
    validate: {
      type: Number,
      default: null,
    },
    validationExtraAttention: {
      type: Boolean,
      default: false,
    },
    postNewAgreementRequest: {
      type: Number,
      default: null,
    },
  },
  emits: ['requestServerValidation', 'agreementPropertyFormValidation'],

  data: () => ({
    id: null,
    addressInput: defaultAddressObject(),
    source: webSourceType.WEB_AGREEMENT,
    workflow: workflow,
    showManualAddressContent: false,
    objectData: {
      addressFirstLine: null,
      addressCity: null,
      addressStreet: null,
      addressCountry: null,
      addressIndex: null,
      addressHouseNumber: null,
      addressApartmentNumber: null,
      objectArea: '',
      numberOfRooms: null,
      hasParking: false,
      hasStorage: false,
      petsAllowed: false,
      petsComment: null,
    },
    trackingProcessNames,
    agreementTrackingEventNames,

    actions: {
      putAgreementDebounced: () => undefined,
    },
  }),

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      putAgreementInProgress: 'agreements/putAgreementInProgress',
      postAgreementInProgress: 'agreements/postAgreementInProgress',
      profile: 'users/profile',
      profileId: 'users/profileId',
    }),
    fullAddressModel: {
      get() {
        return this.addressInput;
      },
      set(newVal) {
        if (newVal) {
          const finalValue = { ...this.addressInput, ...newVal };
          this.addressInput = finalValue;
        }
      },
    },
    objectAreaModel: {
      get() {
        return this.objectData.objectArea;
      },
      set(newVal) {
        this.objectData.objectArea = Number(newVal.replace(',', '.'));
      },
    },
    addressValidationRules() {
      const houseNumberRequired = this.getCountry === 'pl';

      return {
        isValidAddressInput: true,
        isValidAddressInputCity: true,
        isValidAddressInputStreet: true,
        isValidAddressInputCountry: true,
        isValidAddressInputIndex: true,
        isValidAddressInputWithApartmentNr: true,
        isValidAddressInputHouseNumber: houseNumberRequired,
      };
    },
    propertyTabErrorInHtml() {
      let line1 = '';
      let line2 = '';

      if (!this.objectData.addressFirstLine) {
        line1 = `<div>${this.$t('agreement.error.add_object_info')}</div>`;
      } else if (!this.objectData.addressCity) {
        line2 = `<div>${this.$t('form.validation.address_city')}</div>`;
      } else if (
        !this.objectData.addressApartmentNumber &&
        this.addressInput?.hasApartmentNumber !== false
      ) {
        line2 = `<div>${this.$t('form.validation.address_apartment_number')}</div>`;
      } else if (!this.objectData.numberOfRooms) {
        line2 = `<div>${this.$t('form.validation.numberOfRooms')}</div>`;
      } else if (!this.objectData.objectArea) {
        line2 = `<div>${this.$t('form.validation.objectArea')}</div>`;
      } else {
        line1 = `<div class="text-gray-900">${this.objectData.addressFirstLine}</div>`;
        line2 = `<div class="text-gray-900">${this.objectData.numberOfRooms} ${this.$t('agreement.label.rooms')}, ${this.objectData.objectArea} m<sup>2<sub></div>`;
      }

      return `${line1} ${line2}`;
    },
  },

  watch: {
    addressInput(val) {
      if (val) {
        this.objectData.addressFirstLine = val.addressFirstLine;
        this.objectData.addressCity = val.addressCity;
        this.objectData.addressStreet = val.addressStreet;
        this.objectData.addressCountry = val.addressCountry;
        this.objectData.addressIndex = val.addressIndex;
        this.objectData.addressHouseNumber = val.addressHouseNumber;
        this.objectData.addressApartmentNumber = val.addressApartmentNumber;
      }
    },

    validate(val) {
      if (val) {
        this.fillEditModeData();
        this.validateAgreementPropertyForm();
      }
    },

    postNewAgreementRequest(val) {
      if (
        val &&
        (!this.postAgreementInProgress ||
          !this.getAgreementInProgress ||
          !this.putAgreementInProgress)
      ) {
        this.createNewAgreement(this.objectData);
      }
    },
  },

  mounted() {
    this.fillEditModeData();
    this.validateAgreementPropertyForm();

    this.actions.putAgreementDebounced = debounce((data, ref) => {
      if (ref) {
        const agreementId = this.agreementId;
        this.$refs[ref].validate().then((response) => {
          if (response.valid) {
            this.actionPutAgreement({
              id: agreementId,
              agreement: { ...data },
            })
              .then(() => this.getAgreement(agreementId))
              .then(() => {
                if (this.validationExtraAttention)
                  this.$emit('requestServerValidation');
              });

            this.emitAgreementTrackerEvent(
              this.dataInputEventName(data),
              this.dataCustomProperty(data),
            );
          }
        });
      } else {
        const agreementId = this.agreementId;
        this.actionPutAgreement({ id: agreementId, agreement: { ...data } })
          .then(() => this.getAgreement(agreementId))
          .then(() => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          });

        this.emitAgreementTrackerEvent(
          this.dataInputEventName(data),
          this.dataCustomProperty(data),
        );
      }

      // Put only if agreement is DRAFT
    }, 500);

    // Agreement Flow: Open New
    if (!this.agreementId) {
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_NEW);
    } else {
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN);
    }
  },

  methods: {
    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionPutAgreement: 'agreements/putAgreement',
      actionPostAgreement: 'agreements/postAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      trackManualAddressAdded: 'tracker/trackManualAddressAdded',
    }),

    fillEditModeData() {
      if (this.agreementFromFirebase) {
        this.addressInput = {
          addressFirstLine: this.agreementFromFirebase.addressFirstLine,
          addressCity: this.agreementFromFirebase.addressCity,
          addressStreet: this.agreementFromFirebase.addressStreet,
          addressCountry: this.agreementFromFirebase.addressCountry,
          addressIndex: this.agreementFromFirebase.addressIndex,
          addressHouseNumber: this.agreementFromFirebase.addressHouseNumber,
          addressApartmentNumber: this.agreementFromFirebase.addressApartmentNumber,
          hasApartmentNumber: !!this.agreementFromFirebase.addressApartmentNumber,
        };
        this.objectData.objectArea = this.agreementFromFirebase.objectArea
          ? this.agreementFromFirebase.objectArea
          : '';
        this.objectData.numberOfRooms = this.agreementFromFirebase.numberOfRooms
          ? this.agreementFromFirebase.numberOfRooms
          : '';
        this.objectData.hasParking = this.agreementFromFirebase.hasParking
          ? this.agreementFromFirebase.hasParking
          : false;
        this.objectData.hasStorage = this.agreementFromFirebase.hasStorage
          ? this.agreementFromFirebase.hasStorage
          : false;
        this.objectData.petsAllowed = this.agreementFromFirebase.petsAllowed
          ? this.agreementFromFirebase.petsAllowed
          : false;
        this.objectData.petsComment = this.agreementFromFirebase.petsComment
          ? this.agreementFromFirebase.petsComment
          : '';
      }
    },

    validateForm() {
      return this.$refs.propertyDataForm?.validate().then((response) => {
        const result = {
          success: response?.valid,
          error: this.propertyTabErrorInHtml,
        };
        this.$emit('agreementPropertyFormValidation', result);

        return result;
      });
    },

    validateAgreementPropertyForm() {
      this.$nextTick(() => {
        this.validateForm();
      });
    },

    validateAndPutAgreement(data, ref) {
      this.validateAgreementPropertyForm();

      if (this.agreementId && this.agreementFromFirebase?.status === 'DRAFT') {
        this.actions.putAgreementDebounced(data, ref);
      } else {
        // tracking for events before agreement is created
        this.emitAgreementTrackerEvent(
          this.dataInputEventName(data),
          this.dataCustomProperty(data),
        );
      }
    },
    async onAddressInput(newAddress) {
      this.fullAddressModel = newAddress;

      // fullAddressForm
      await this.$refs.fullAddressForm.validate();
      const validation = await validate(
        this.fullAddressModel,
        this.addressValidationRules,
      );

      if (validation.valid) {
        if (this.fullAddressModel.internalUserEditedAddressManually) {
          await this.trackManualAddressAdded({
            addressCity: this.fullAddressModel.addressCity,
            addressStreet: this.fullAddressModel.addressStreet,
            addressPostalCode: this.fullAddressModel.addressIndex,
            addressHouseNumber: this.fullAddressModel.addressHouseNumber,
            addressCountry: this.fullAddressModel.addressCountry,
            addressHouseNumberHidden: this.fullAddressModel.addressHouseNumberHidden,
            addressApartmentNumber: this.fullAddressModel.addressApartmentNumber,
            addressFirstLine: this.fullAddressModel.addressFirstLine,
            source: this.source,
          });
        }
        this.validateAndPutAgreement(this.fullAddressModel);
      }
    },
    createNewAgreement(data) {
      let selectedAgreementTypeFromProfile = null;
      if (
        this.profile?.allowedAgreementTypesToPost.includes(agreementTypes.EE_LUMIKODUD)
      ) {
        selectedAgreementTypeFromProfile = agreementTypes.EE_LUMIKODUD;
      } else {
        selectedAgreementTypeFromProfile = agreementTypes.EE_BASIC;
      }

      const agreementToPost = {
        workflow: this.getCountry.toUpperCase(),
        agreementType: this.selectedAgreementType
          ? this.selectedAgreementType
          : selectedAgreementTypeFromProfile,
        landlordProfileId: this.profileId,
        ...data,
      };

      if (!this.agreementId) {
        this.actionPostAgreement({ agreement: agreementToPost })
          .then((result) => {
            this.id = result.response;
            this.$router.push(`${this.$route.path}/${this.id}?tab=parties`);

            const customAgreementData = {
              id: result.response,
              creationFlow: 'NEW',
            };
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.CREATE_NEW,
              customAgreementData,
            );
          })
          .then(() => this.getAgreement());
      }
    },

    dataInputEventName(dataInput) {
      let eventName = '';

      if (dataInput) {
        if (dataInput.addressFirstLine)
          eventName = this.agreementTrackingEventNames.SET_ADDRESS;
        else if (dataInput.numberOfRooms)
          eventName = this.agreementTrackingEventNames.SET_ROOMS;
        else if (dataInput.objectArea)
          eventName = this.agreementTrackingEventNames.SET_AREA;
        else if ('hasParking' in dataInput)
          eventName = this.agreementTrackingEventNames.SET_PARKING;
        else if ('hasStorage' in dataInput)
          eventName = this.agreementTrackingEventNames.SET_STORAGE;
        else if ('petsAllowed' in dataInput)
          eventName = this.agreementTrackingEventNames.SET_PETS_POLICY;
        else if ('petsComment' in dataInput)
          eventName = this.agreementTrackingEventNames.SET_PETS_SPECIAL_CONDITIONS;
      }

      return eventName;
    },

    dataCustomProperty(dataInput) {
      let property = '';
      if (dataInput) {
        if (dataInput.addressFirstLine) property = { value: dataInput };
        else if (dataInput.numberOfRooms) property = { value: dataInput.numberOfRooms };
        else if (dataInput.objectArea) property = { value: dataInput.objectArea };
        else if ('hasParking' in dataInput) property = { value: dataInput.hasParking };
        else if ('hasStorage' in dataInput) property = { value: dataInput.hasStorage };
        else if ('petsAllowed' in dataInput)
          property = { value: dataInput.petsAllowed };
        else if ('petsComment' in dataInput)
          property = { value: dataInput.petsComment };
      }

      return property;
    },

    emitAgreementTrackerEvent(event, customProperties, customAgreementData) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName:
          customAgreementData || this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    getAgreement(id) {
      if (this.id === null && id === null) {
        return;
      }
      this.actionGetAgreement({ id: id ?? this.id }).then((result) => {
        if (!result.response.failed) {
          this.fillEditModeData();
        }
      });
    },
  },
};
</script>
