<template>
  <div class="w-full">
    <div v-if="blok.content && blok.content.length > 0" class="px-4">
      <div v-for="blokItem in blok.content" :key="blokItem._uid">
        <DocumentItem v-if="blokItem.component === 'DocumentItem'" :blok="blokItem" />
        <RichTextBlok v-if="blokItem.component === 'RichTextBlok'" :blok="blokItem" />
      </div>
    </div>
  </div>
</template>

<script>
import DocumentItem from '~/components/snippets/DocumentItem';
export default {
  name: 'DocumentList',
  components: { DocumentItem },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
