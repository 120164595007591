import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';

/* Composable: Setup interface between Firebase Functions and VueX store */
/* Main goal is to provide awaitable, linear queue for our API to reduce risk of racer conditions and reduce complexity on higher-level components  */
/* Working principles */
/* 1. First setup VueX store with appropriate getters, setters, actions and mutations (dynamic keys based on request name) */
/* 2. Provide low-level queue built on async promises for REQUESTS and LISTENERS */
/* 3. Resolve queue with FIFO method, every time waiting until the appropriate queued item returns "finished" before continuing */

const removeValuesFromObjectAndReturnCopy = (obj, valuesToRemove = [undefined]) =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj)
    ? Object.entries({ ...obj })
        .filter(([k, v]) => !valuesToRemove.includes(v))
        .reduce(
          (r, [key, value]) => ({
            ...r,
            [key]: removeValuesFromObjectAndReturnCopy(value),
          }),
          {},
        )
    : obj;

const _functionCallRegionEU = (name, dataObject = undefined, useEmulator = false) => {
  // For Firebase, all undefined values become nulls. Let us escape this.
  const data = removeValuesFromObjectAndReturnCopy(dataObject, [undefined]);
  const functionsRegionEU = getFunctions(undefined, 'europe-west1');
  if (useEmulator) {
    const emulatorHost = '127.0.0.1';
    const emulatorFunctionsPort = 5001;
    connectFunctionsEmulator(functionsRegionEU, emulatorHost, emulatorFunctionsPort);
  }
  return httpsCallable(functionsRegionEU, name)(data);
};

export const requestKeys = (request, storePrefix = '') => {
  const pascalCaseRequest = request[0].toUpperCase() + request.slice(1);

  const prefix = storePrefix ? `${storePrefix}/` : '';

  return {
    // state
    STATE_REQUEST_LAST_RESPONSE: `${prefix}${request}LatestResponse`,
    STATE_REQUEST_QUEUE: `${prefix}_internal${pascalCaseRequest}Queue`,
    STATE_QUEUE_LOCKED: `${prefix}_internal${pascalCaseRequest}QueueLocked`,
    // mutations
    MUTATION_SET_REQUEST_LAST_RESPONSE: `${prefix}SET_${request.toUpperCase()}_LATEST_RESPONSE`,
    MUTATION_REQUEST_QUEUE_PUSH: `${prefix}PUSH_${request.toUpperCase()}_REQUEST_QUEUE`,
    MUTATION_SET_REQUEST_QUEUE: `${prefix}SET_${request.toUpperCase()}_REQUEST_QUEUE`,
    MUTATION_SET_REQUEST_QUEUE_LOCK: `${prefix}SET_${request.toUpperCase()}_QUEUE_LOCK`,
    // actions
    ACTION_MAKE_REQUEST: `${prefix}${request}Request`,
    ACTION_WAIT_UNTIL_CURRENT_QUEUE_FINISHED: `${prefix}${request}WaitCurrentRequestsFinished`,
    ACTION_INTERNAL_RUN_NEXT_REQUEST_IN_QUEUE_IF_POSSIBLE: `${prefix}${request}RunNextRequestInQueueIfPossible`,
    ACTION_INTERNAL_WAIT_IN_QUEUE_AND_RETURN_PROMISE_TO_BE_RESOLVED_AFTER_FINISHED: `${prefix}${request}_internalWaitInQueueAndReturnPromiseToBeResolvedAfterFinished`,
    // getters
    GETTERS_LATEST_RESPONSE: `${prefix}${request}LatestResponse`,
    GETTERS_QUEUE: `${prefix}${request}Queue`,
    GETTERS_QUEUE_LOCK: `${prefix}${request}QueueLocked`,
  };
};

const promiseTypes = {
  REQUEST: Symbol('REQUEST'),
  LISTENER: Symbol('LISTENER'),
};

const setupNewActions = (requestName) => {
  const keys = requestKeys(requestName);

  const makeFirebaseRequest = async (
    { commit, dispatch, rootGetters },
    { request, requestData, functionRequestOverride, expectedErrorCodes },
  ) => {
    const requestResolved = functionRequestOverride || requestName;

    const body = {
      request: request,
      requestData: requestData,
    };

    const toBeResolvedAfterFinished = await dispatch(
      keys.ACTION_INTERNAL_WAIT_IN_QUEUE_AND_RETURN_PROMISE_TO_BE_RESOLVED_AFTER_FINISHED,
      { type: promiseTypes.REQUEST },
    );

    return Promise.resolve()
      .then(() => {
        return _functionCallRegionEU(requestResolved, body, rootGetters.useEmulator);
      })
      .then((response) => {
        commit(keys.MUTATION_SET_REQUEST_LAST_RESPONSE, response.data);
        return response;
      })
      .catch((err) => {
        if (rootGetters.isENVIsDevelopment) {
          console.error(`Requested: '${requestResolved}' error: '${err.message}'`);
        }
        if (Array.isArray(expectedErrorCodes)) {
          if (expectedErrorCodes.includes(err.code)) {
            return Promise.resolve();
          }
        } else if (rootGetters.isENVIsDevelopment) {
          console.log(
            `If this error is expected behaviour, please add expectedErrorCodes: ['${err.code}'] to your firebaseRequest,
            otherwise this error will be always reported to Sentry.`,
          );
        }
        dispatch('tracker/reportErrorToSentry', err, { root: true });

        throw err;
      })
      .finally(() => toBeResolvedAfterFinished());
  };

  const waitUntilCurrentRequestsFinished = async ({ dispatch }) => {
    const toBeResolvedAfterFinished = await dispatch(
      keys.ACTION_INTERNAL_WAIT_IN_QUEUE_AND_RETURN_PROMISE_TO_BE_RESOLVED_AFTER_FINISHED,
      { type: promiseTypes.LISTENER },
    );

    // This function doesn't do any internal processing, so we can signal finished immediately
    toBeResolvedAfterFinished();
  };

  const _addToQueueAndExpectReturnedPromiseResolvedAfterActionCompleted = async (
    { commit, state, dispatch },
    payload = {},
  ) => {
    const { type } = payload;
    const promise = new Promise((resolve, reject) => {
      const queueItem = {
        id: uuidv4(),
        type: type,
        resolve,
        reject,
      };
      commit(keys.MUTATION_REQUEST_QUEUE_PUSH, queueItem);
      dispatch(keys.ACTION_INTERNAL_RUN_NEXT_REQUEST_IN_QUEUE_IF_POSSIBLE);
    });
    return promise;
  };

  const _runNextPromiseInQueue = async ({ commit, state }) => {
    const nextPromise = await _fetchNextPromiseInQueue({ state, commit });

    const nextPromiseResolved = await new Promise((resolve) => {
      nextPromise.resolve(resolve);
    });

    await nextPromiseResolved;
  };

  const _fetchNextPromiseInQueue = async ({ state, commit }) => {
    const queue = [...state[keys.STATE_REQUEST_QUEUE]];
    const nextPromise = queue.shift();
    commit(keys.MUTATION_SET_REQUEST_QUEUE, queue);

    return nextPromise;
  };

  const _tryRunRequestQueue = async ({
    commit,
    dispatch,
    getters,
    state,
    rootGetters,
  }) => {
    /* If the queue is already locked by another queue runner, exit immediately */
    if (getters[keys.GETTERS_QUEUE_LOCK]) {
      return;
    }

    commit(keys.MUTATION_SET_REQUEST_QUEUE_LOCK, true);
    while (getters[keys.GETTERS_QUEUE].length > 0) {
      await _runNextPromiseInQueue({ commit, dispatch, state, getters, rootGetters });
    }
    commit(keys.MUTATION_SET_REQUEST_QUEUE_LOCK, false);
  };

  return {
    [keys.ACTION_MAKE_REQUEST]: makeFirebaseRequest,
    [keys.ACTION_WAIT_UNTIL_CURRENT_QUEUE_FINISHED]: waitUntilCurrentRequestsFinished,
    [keys.ACTION_INTERNAL_RUN_NEXT_REQUEST_IN_QUEUE_IF_POSSIBLE]: _tryRunRequestQueue,
    [keys.ACTION_INTERNAL_WAIT_IN_QUEUE_AND_RETURN_PROMISE_TO_BE_RESOLVED_AFTER_FINISHED]:
      _addToQueueAndExpectReturnedPromiseResolvedAfterActionCompleted,
  };
};

export const setupNewMutations = (request) => {
  const keys = requestKeys(request);

  return {
    [keys.MUTATION_SET_REQUEST_LAST_RESPONSE]: (state, data) => {
      state[keys.STATE_REQUEST_LAST_RESPONSE] = structuredClone(data);
    },
    [keys.MUTATION_REQUEST_QUEUE_PUSH]: (state, request) => {
      state[keys.STATE_REQUEST_QUEUE].push(request);
    },
    [keys.MUTATION_SET_REQUEST_QUEUE]: (state, queue) => {
      state[keys.STATE_REQUEST_QUEUE] = queue;
    },
    [keys.MUTATION_SET_REQUEST_QUEUE_LOCK]: (state, isLocked) => {
      return (state[keys.STATE_QUEUE_LOCKED] = isLocked);
    },
  };
};

const setupNewState = (request) => {
  const keys = requestKeys(request);

  return {
    [keys.STATE_REQUEST_LAST_RESPONSE]: null,
    [keys.STATE_REQUEST_QUEUE]: [],
    [keys.STATE_QUEUE_LOCKED]: false,
  };
};

const setupNewGetters = (request) => {
  const keys = requestKeys(request);

  return {
    [keys.GETTERS_LATEST_RESPONSE]: (state) => state[keys.STATE_REQUEST_LAST_RESPONSE],
    [keys.GETTERS_QUEUE]: (state) => state[keys.STATE_REQUEST_QUEUE],
    [keys.GETTERS_QUEUE_LOCK]: (state) => state[keys.STATE_QUEUE_LOCKED],
  };
};

export const useVuexFirebaseRequests = (requests) => {
  const store = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  };

  for (let i = 0; i < requests.length; i++) {
    const request = requests[i];
    store.state = {
      ...store.state,
      ...setupNewState(request),
    };
    store.getters = {
      ...store.getters,
      ...setupNewGetters(request),
    };
    store.mutations = {
      ...store.mutations,
      ...setupNewMutations(request),
    };
    store.actions = {
      ...store.actions,
      ...setupNewActions(request),
    };
  }

  return store;
};
