<template>
  <VeeForm ref="validator.aboutYouForm" v-slot="{ meta: formMeta, handleSubmit }">
    <form
      autocomplete="off"
      class="flex flex-col gap-3"
      @submit.prevent="handleSubmit(() => submitForm(formMeta.valid))"
    >
      <Field v-slot="{ meta }" v-model="description" rules="min:2" name="description">
        <r-textarea
          id="description"
          v-model="description"
          :label="$t('tenant_profile.tabs.about_you.description_label')"
          name="description"
          :placeholder="$t('tenant_profile.tabs.about_you.description_placeholder')"
          rows="8"
          :validation-failed="!meta.valid"
          :validation-passed="meta.valid"
        />
      </Field>
      <div class="buttons flex gap-3">
        <r-button no-background width="full" @click="closeTab">
          {{ $t('buttons.cancel') }}
        </r-button>
        <r-button type="submit" width="full">
          <span v-if="loading">
            <r-loader class="flex justify-center" size="20" />
          </span>
          <span v-else>
            {{ $t('buttons.save') }}
          </span>
        </r-button>
      </div>
    </form>
  </VeeForm>
</template>
<script>
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { Form as VeeForm, Field } from 'vee-validate';

export default {
  name: 'TenantAboutYouTab',

  components: {
    VeeForm,
    Field,
  },

  props: {
    tenantProfile: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['submit', 'close'],

  async setup() {
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return {
      getAnalyticEventNames,
      trackTenantProfileEvent,
    };
  },

  data: () => ({
    description: null,
    validateEventTriggered: false,
  }),
  watch: {
    tenantProfile() {
      if (this.tenantProfile) {
        this.initializeForm(this.tenantProfile);
      }
    },
  },
  beforeMount() {
    if (this.tenantProfile) {
      this.initializeForm(this.tenantProfile);
    }
  },
  methods: {
    initializeForm(initialData) {
      this.description = initialData.description;
    },
    submitForm(isValid) {
      // validate parameter for disabling initial isValidTelInput false validation
      this.validateEventTriggered = true;

      if (isValid) {
        this.$emit('submit', {
          description: this.description !== null ? this.description : '',
        });
        this.trackTenantProfileEvent({
          eventName: this.getAnalyticEventNames.TENANT_PROFILE_EDIT_ABOUT_YOU,
        });
      }
    },
    closeTab() {
      this.$emit('close');
      this.initializeForm(this.tenantProfile);
    },
  },
};
</script>
