<template>
  <div class="flex flex-col">
    <div
      class="flex justify-center rounded-2xl mb-6 h-full"
      :class="[blok.backgroundColor ? blok.backgroundColor : '']"
    >
      <img
        v-if="blok.image?.filename"
        alt="rendin-persona"
        :src="$transformStoryblokImage(blok.image?.filename, '/m/1800x2000/smart')"
      />
    </div>
    <p class="text-xl font-semibold">{{ blok.name }}</p>
    <p class="text-lg text-violet-800">{{ blok.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'PersonaCard',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.persona-card-bg-yellow {
  background: #faedcb;
}

.persona-card-bg-green {
  background: #c9e4de;
}

.persona-card-bg-blue {
  background: #c6def1;
}

.persona-card-bg-violet {
  background: #dbcdf0;
}

.persona-card-bg-orange {
  background: #f7d9c4;
}

.persona-card-bg-rose {
  background: #f2c6de;
}
</style>
