<template>
  <section>
    <client-only>
      <div ref="profile-primary-settings-form" class="mt-3">
        <Form
          v-slot="{ meta: formMeta, validate }"
          autocomplete="off"
          class="relative"
          @submit.prevent
        >
          <div
            class="mx-auto max-w-2xl w-full bg-white p-4 pb-6 sm:px-7 rounded-lg shadow-lg"
          >
            <h3 class="text-left text-xl md:text-2xl mt-2 font-bold">
              {{ $t('form.title.profile_information') }}
            </h3>
            <r-radio
              v-if="isTypeLandlord"
              v-model="profileData.type"
              class="pt-3"
              horizontal
              :label="$t('form.label.profile_type')"
              name="profile-type"
              option-label="text"
              :options="profileTypeOptions"
              @change="profileInfoChange"
            />
            <Field
              v-slot="{ meta, errors }"
              v-model="profileData.firstName"
              :bails="false"
              :validate-on-mount="!isNewProfile"
              name="firstName"
              rules="required|min:2|isNotUsedCyrillic"
            >
              <r-input
                id="firstName"
                ref="firstName"
                v-model="profileData.firstName"
                class="mt-3"
                :error-text="$t('form.validation.first_name')"
                :errors="errors"
                field-type="text"
                for="firstName"
                :label="$t('form.label.first_name')"
                name="firstName"
                :placeholder="$t('form.placeholder.first_name')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <Field
              v-slot="{ meta, errors }"
              v-model="profileData.lastName"
              :bails="false"
              :validate-on-mount="!isNewProfile"
              name="lastName"
              rules="required|min:2|isNotUsedCyrillic"
            >
              <r-input
                id="lastName"
                ref="lastName"
                v-model="profileData.lastName"
                class="mt-3"
                :error-text="$t('form.validation.last_name')"
                :errors="errors"
                field-type="text"
                for="lastName"
                :label="$t('form.label.last_name')"
                name="lastName"
                :placeholder="$t('form.placeholder.last_name')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <Field
              v-slot="{ meta }"
              v-model="profileData.email"
              name="email"
              rules="required|email"
              vid="email"
            >
              <r-input
                id="email"
                ref="email"
                v-model="profileData.email"
                :add-on="showEmailChange ? $t('buttons.change') : ''"
                add-on-class="text-rendin-500 font-semibold cursor-pointer hover:text-rendin-600 focus:text-rendin-600"
                class="mt-3"
                name="email"
                disabled
                :error-text="$t('form.validation.email')"
                field-type="email"
                for="email"
                :label="$t('form.label.email')"
                :placeholder="$t('form.placeholder.email')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @click-add-on="openEmailChangeModal()"
                @focus="showEmailChange ? openEmailChangeModal() : profileInfoChange()"
              />
            </Field>
            <Field
              v-slot="{ meta }"
              v-model="phoneNumberData"
              name="phoneNrFull"
              :rules="`required|isValidTelInput:${phoneNumberData.isValid}`"
            >
              <r-input-tel
                id="phoneNrFull"
                ref="phoneNrFull"
                v-model="phoneNumberData"
                class="mt-3"
                :default-country="$getCountry()"
                :error-text="$t('form.validation.phone')"
                for="phoneNrFull"
                :label="$t('form.label.phone')"
                name="phoneNrFull"
                :placeholder="$t('form.placeholder.your_phone')"
                :prefilled-input="prefilledPhoneNumber"
                :prefilled-input-country-code="prefilledPhoneCountryCodeIso"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="
                  [(validateEventTriggered = true), (profileInfoIsChanged = true)]
                "
              />
            </Field>
            <Field
              v-if="country.toUpperCase() === workflowData.value"
              v-slot="{ meta }"
              v-model="profileData.personalIdCode"
              validate-on-mount
              name="personalIdCode"
              rules="required|idCode"
            >
              <r-input
                id="personalIdCode"
                ref="personalIdCode"
                v-model="profileData.personalIdCode"
                :disabled="
                  (meta.valid && typeof profile?.idCode === 'string') ||
                  (meta.valid && newProfileData)
                "
                :error-text="$t('form.validation.id_code.' + $getCountry())"
                field-type="text"
                for="personalIdCode"
                :label="$t('form.label.id_code.' + $getCountry())"
                name="personalIdCode"
                :placeholder="$t('form.placeholder.id_code.' + $getCountry())"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @input="profileInfoChange"
              />
            </Field>
          </div>
          <div
            v-if="isTypeLandlord"
            class="mt-3 mx-auto max-w-2xl w-full bg-white p-4 pb-6 sm:px-7 rounded-lg shadow-lg"
          >
            <h3
              v-if="isProfilePrivate"
              class="text-left text-xl md:text-2xl mt-2 font-bold"
            >
              {{ $t('form.title.home_address') }}
            </h3>
            <h3
              v-if="!isProfilePrivate"
              class="text-left text-xl md:text-2xl mt-2 font-bold"
            >
              {{ $t('form.title.company_information') }}
            </h3>
            <Field
              v-slot="{ meta }"
              v-model="profileData.addressCountry"
              name="addressCountry"
              rules="required"
              vid="addressCountry"
            >
              <r-country-select-all-countries
                ref="addressCountry"
                v-model="profileData.addressCountry"
                class="mt-3"
                :error-text="$t('form.validation.country')"
                :label="$t('form.label.country')"
                :locale="getLocale"
                :placeholder="$t('form.label.country')"
                :preferred-countries="preferredCountriesForCountrySelect"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @change="profileInfoChange"
              />
            </Field>
            <Field
              v-if="!isProfilePrivate"
              v-slot="{ meta }"
              v-model="profileData.companyName"
              name="companyName"
              rules="required|min:2"
              vid="companyName"
            >
              <r-input
                id="companyName"
                ref="companyName"
                v-model="profileData.companyName"
                class="mt-3"
                :error-text="$t('form.validation.full_name')"
                field-type="text"
                for="companyName"
                :label="$t('form.label.company_name')"
                name="companyName"
                :placeholder="$t('form.placeholder.company_name')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <Field
              v-if="!isProfilePrivate && profileData.addressCountry === 'PL'"
              v-slot="{ meta }"
              v-model="profileData.companyTaxNumber"
              name="companyTaxNumber"
              rules="required|min:10|max:10"
              vid="companyTaxNumber"
            >
              <r-input
                id="companyTaxNumber"
                ref="companyTaxNumber"
                v-model="profileData.companyTaxNumber"
                class="mt-3"
                :error-text="$t('form.validation.company_tax_nr')"
                field-type="text"
                for="companyTaxNumber"
                :label="$t('form.label.company_tax_nr')"
                name="companyTaxNumber"
                :placeholder="$t('form.placeholder.company_tax_nr')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <Field
              v-if="!isProfilePrivate"
              v-slot="{ meta }"
              v-model="profileData.companyNumber"
              name="companyNumber"
              :rules="
                profileData.addressCountry === 'PL'
                  ? ''
                  : { required: true, min: 1, numeric: true }
              "
              vid="companyNumber"
            >
              <r-input
                id="companyNumber"
                ref="companyNumber"
                v-model="profileData.companyNumber"
                class="mt-3"
                :description="
                  profileData.addressCountry === 'PL'
                    ? $t('form.description.company_registration_nr')
                    : ''
                "
                :error-text="$t('form.validation.company_registration_nr')"
                field-type="text"
                for="companyNumber"
                :label="$t('form.label.company_registration_nr.' + $getCountry())"
                name="companyNumber"
                :placeholder="$t('form.placeholder.company_registration_nr')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <Field
              v-slot="{ meta, errors }"
              v-model="profileData.addressFirstLine"
              :bails="false"
              name="addressBusiness"
              rules="required|min:2|isNotUsedCyrillic"
              vid="addressBusiness"
            >
              <r-input
                id="addressBusiness"
                ref="addressBusiness"
                v-model="profileData.addressFirstLine"
                class="mt-3"
                :error-text="$t('form.validation.address')"
                :errors="errors"
                field-type="text"
                for="addressBusiness"
                :label="$t('form.label.address')"
                name="addressBusiness"
                :placeholder="$t('form.label.address')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @focus="profileInfoChange"
              />
            </Field>
            <div class="grid sm:grid-cols-2">
              <Field
                v-slot="{ meta, errors }"
                v-model="profileData.addressCity"
                :bails="false"
                name="city"
                rules="required|min:2|isNotUsedCyrillic"
                vid="city"
              >
                <r-input
                  id="city"
                  ref="city"
                  v-model="profileData.addressCity"
                  class="mt-3 w-full pr-1"
                  :error-text="$t('form.validation.city')"
                  :errors="errors"
                  field-type="text"
                  for="city"
                  :label="$t('form.label.city')"
                  name="city"
                  :placeholder="$t('form.label.city')"
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                  @focus="profileInfoChange"
                />
              </Field>
              <Field
                v-slot="{ meta }"
                v-model="profileData.addressPostalCode"
                name="postCode"
                rules="required|min:1"
                vid="postCode"
              >
                <r-input
                  id="postCode"
                  ref="postCode"
                  v-model="profileData.addressPostalCode"
                  class="mt-3 w-full pl-1"
                  :error-text="$t('form.validation.postalCode')"
                  field-type="text"
                  for="postCode"
                  :label="$t('form.label.postalCode')"
                  name="postCode"
                  :placeholder="$t('form.placeholder.postalCode')"
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                  @focus="profileInfoChange"
                />
              </Field>
            </div>
          </div>
          <div
            v-if="isTypeLandlord"
            class="my-3 mx-auto max-w-2xl w-full bg-white p-4 pb-6 sm:px-7 rounded-lg shadow-lg"
          >
            <h3 class="text-left text-xl md:text-2xl mt-2 font-bold">
              {{ $t('form.title.preferences') }}
            </h3>
            <div class="pt-2">
              <Field
                v-slot="{ meta }"
                v-model="workflowData.value"
                name="country"
                :rules="`required|isWorkflowCorrect:${workflowData.value}`"
                vid="country"
              >
                <r-country-select
                  id="country"
                  ref="country"
                  v-model="workflowData"
                  country-name
                  :error-text="$t('form.validation.country')"
                  :options="selectCountryOptions"
                  :validation-failed="meta.validated && !meta.valid"
                  :validation-passed="meta.validated && meta.valid"
                />
              </Field>
            </div>
            <Field
              v-slot="{ meta }"
              v-model="languageData.value"
              name="language"
              :rules="`required|isLanguageCorrect:${getLanguageValue.value}`"
              vid="country"
            >
              <r-select
                id="language"
                ref="language"
                v-model="languageData"
                class="mt-3"
                :error-text="$t('form.validation.language')"
                :label="$t('language')"
                name="language"
                option-label="text"
                :options="
                  workflowData.value === 'EE' || country === 'ee'
                    ? selectESTLanguageOptions
                    : selectPLLanguageOptions
                "
                :placeholder="$t('choose_language')"
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
                @change="profileInfoChange"
                @close="lastDropDownOpen = false"
                @open="lastDropDownOpen = true"
              />
            </Field>
          </div>
          <div
            class="p-4"
            :class="[
              submitButtonWrapperClass,
              profileInfoIsChanged && lastDropDownOpen ? 'mt-32' : 'mt-8',
              { 'sticky bottom-0 left-0 right-0 z-10': profileInfoIsChanged },
            ]"
          >
            <div class="mx-auto max-w-2xl w-full">
              <r-button
                :is-loading="submitButtonLoading"
                :label="submitButtonLabel ? submitButtonLabel : $t('buttons.save')"
                size="small"
                width="full"
                @click="submitData(formMeta, validate)"
              />
            </div>
          </div>
        </Form>
      </div>
      <EmailChangeModal
        :show-modal="showEmailChangeModal"
        @close-overlay="showEmailChangeModal = false"
      />
    </client-only>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import RCountrySelectAllCountries from '~/components/r-interface-components/r-country-select-all-countries';
import EmailChangeModal from '~/components/modals/EmailChangeModal.vue';
import { Form, Field } from 'vee-validate';
import { trim, get } from 'lodash';

export default {
  name: 'ProfilePrimarySetting',
  components: {
    RCountrySelectAllCountries,
    EmailChangeModal,
    Form,
    Field,
  },

  props: {
    blok: {
      type: Object,
      default: null,
    },
    /**
     * Define type if StoryBlok blok is not used | [ `landlord`, `tenant`, `coTenant` ]
     */
    type: {
      type: String,
      default: '',
    },
    /**
     * Define isNewProfile for new profile created for third person
     * used in agreement landlord and coTenant flows
     */
    isNewProfile: {
      type: Boolean,
      default: false,
    },
    /**
     * Data for new profile form prefill
     * used in agreement landlord and coTenant flows
     */
    newProfileData: {
      type: Object,
      default: null,
    },
    submitButtonLabel: {
      type: String,
      default: '',
    },
    submitButtonLoading: {
      type: Boolean,
      default: false,
    },
    submitButtonWrapperClass: {
      type: String,
      default: '',
    },
    showEmailChange: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submittedProfileData'],

  data() {
    return {
      profileData: {
        firstName: null,
        lastName: null,
        email: null,
        type: 'PERSONAL',
        phoneCountryCode: null,
        phoneCountryCodeIso: null,
        phoneNumber: null,
        companyName: null,
        companyTaxNumber: null,
        companyNumber: null,
        addressFirstLine: null,
        addressCity: null,
        addressPostalCode: null,
        country: null,
        language: null,
        workflow: null,
        personalIdCode: null,
      },
      validateEventTriggered: false,
      prefilledPhoneNumber: '',
      prefilledPhoneCountryCodeIso: '',
      phoneNumberData: {},
      languageData: {
        text: null,
        value: null,
      },
      workflowData: {
        text: null,
        value: null,
      },
      selectESTLanguageOptions: [
        {
          text: 'English',
          value: 'en',
        },
        {
          text: 'Eesti',
          value: 'et',
        },
        {
          text: 'Русский',
          value: 'ru',
        },
      ],
      selectPLLanguageOptions: [
        {
          text: 'English',
          value: 'en',
        },
        {
          text: 'Polski',
          value: 'pl',
        },
      ],
      profileInfoIsChanged: false,
      lastDropDownOpen: false,
      showEmailChangeModal: false,
    };
  },

  computed: {
    ...mapGetters({
      profile: 'users/profile',
      putProfileInProgress: 'profiles/putProfileInProgress',
      country: 'getCountry',
      getLocale: 'getLocale',
    }),

    selectCountryOptions() {
      if (this.workflowData) {
        return new Array(this.workflowData);
      } else if (this.country === 'pl') {
        return [
          {
            text: this.$t('country.country_select.text_poland'),
            value: 'PL',
          },
        ];
      } else {
        return [
          {
            text: this.$t('country.country_select.text_estonia'),
            value: 'EE',
          },
        ];
      }
    },

    preferredCountriesForCountrySelect() {
      if (this.country === 'ee') {
        return ['EE', 'PL'];
      } else {
        return ['PL', 'EE'];
      }
    },

    profileTypeOptions() {
      const profileOptions = [
        {
          value: 'PERSONAL',
          text: this.$t('private'),
        },
        {
          value: 'BUSINESS',
          text: this.$t('business'),
        },
      ];
      return profileOptions;
    },

    isTypeLandlord: function () {
      if (this.blok) {
        return this.blok.type === 'landlord';
      } else return this.type === 'LANDLORD';
    },

    getLanguageValue: function () {
      if (this.languageData) {
        return this.languageData;
      } else {
        return { value: null };
      }
    },

    isProfilePrivate: function () {
      return this.profileData.type === 'PERSONAL';
    },
  },

  watch: {
    profile(profileFromFirebase) {
      if (profileFromFirebase && !this.isNewProfile) {
        this.transformAndSetProfile(profileFromFirebase);
      }
    },

    phoneNumberData(val) {
      if (val) {
        this.profileData.phoneCountryCode = val.countryCallingCode;
        this.profileData.phoneCountryCodeIso = val.countryCode;
        this.profileData.phoneNumber = val.nationalNumber;
      }
    },
  },
  mounted() {
    if (this.profile && !this.isNewProfile) {
      this.transformAndSetProfile(this.profile);
    } else if (this.isNewProfile && this.newProfileData) {
      this.setDataForNewProfile(this.newProfileData);
    }
  },

  methods: {
    transformAndSetProfile(profileFromFirebase) {
      if (profileFromFirebase) {
        this.profileData.email = profileFromFirebase.email;
        this.profileData.firstName = profileFromFirebase.firstName;
        this.profileData.lastName = profileFromFirebase.lastName;
        this.prefilledPhoneNumber = profileFromFirebase.phoneNumber;
        this.prefilledPhoneCountryCodeIso = profileFromFirebase.phoneCountryCodeIso;
        this.profileData.addressCountry = profileFromFirebase.addressCountry;

        this.profileData.companyName = profileFromFirebase.companyName;
        this.profileData.companyTaxNumber = profileFromFirebase.companyTaxNumber;
        this.profileData.companyNumber = profileFromFirebase.companyNumber;
        this.profileData.type = profileFromFirebase.type
          ? profileFromFirebase.type
          : 'PERSONAL';
        this.profileData.addressFirstLine = profileFromFirebase.addressFirstLine;
        this.profileData.addressCity = profileFromFirebase.addressCity;
        this.profileData.addressPostalCode = profileFromFirebase.addressPostalCode;

        this.profileData.language = profileFromFirebase.language;
        this.profileData.workflow = profileFromFirebase.workflow;

        this.profileData.personalIdCode = profileFromFirebase.idCode;

        this.workflowData = {
          text: this.countryToText(
            profileFromFirebase.workflow
              ? profileFromFirebase.workflow
              : this.country === 'ee'
                ? 'EE'
                : 'PL',
          ),
          value: profileFromFirebase.workflow
            ? profileFromFirebase.workflow
            : this.country === 'ee'
              ? 'EE'
              : 'PL',
        };
        this.languageData = {
          text: this.languageToText(profileFromFirebase.language),
          value: profileFromFirebase.language,
        };
      }
    },

    setDataForNewProfile: function (newProfileData) {
      this.profileData.email = newProfileData.email;
      this.profileData.personalIdCode = newProfileData.personalIdCode;

      if (this.country === 'ee') {
        this.languageData = {
          text: 'Eesti',
          value: 'et',
        };
        this.workflowData = {
          text: this.$t('country.country_select.text_estonia'),
          value: 'EE',
        };
      } else if (this.country === 'pl') {
        this.languageData = {
          text: 'Polski',
          value: 'pl',
        };
        this.workflowData = {
          text: this.$t('country.country_select.text_poland'),
          value: 'PL',
        };
      }
    },

    profileInfoChange() {
      this.profileInfoIsChanged = true;
    },

    countryToText(countryId) {
      if (countryId === 'EE') {
        return this.$t('country.estonia');
      } else if (countryId === 'PL') {
        return this.$t('country.poland');
      }
      return null;
    },

    languageToText(languageId) {
      if (languageId === 'et') {
        return 'Eesti';
      } else if (languageId === 'pl') {
        return 'Polski';
      } else if (languageId === 'en') {
        return 'English';
      } else if (languageId === 'ru') {
        return 'Русский';
      }
      return null;
    },

    submitData(meta, validate) {
      this.validateEventTriggered = true;

      validate().then((result) => {
        if (result.valid) {
          const data = {
            firstName: trim(this.profileData.firstName),
            lastName: trim(this.profileData.lastName),
            email: trim(this.profileData.email),
            type: trim(this.profileData.type),

            phoneCountryCode: get(this.phoneNumberData, 'countryCallingCode'),
            phoneCountryCodeIso: get(this.phoneNumberData, 'countryCode'),
            phoneNumber: get(this.phoneNumberData, 'nationalNumber'),
            personalIdCode: trim(this.profileData.personalIdCode),

            companyName: trim(this.profileData.companyName),
            companyTaxNumber: trim(this.profileData.companyTaxNumber),
            companyNumber: trim(this.profileData.companyNumber),
            addressFirstLine: trim(this.profileData.addressFirstLine),
            addressCity: trim(this.profileData.addressCity),
            addressPostalCode: trim(this.profileData.addressPostalCode),
            addressCountry: trim(this.profileData.addressCountry),

            language: trim(this.languageData.value),
            workflow: trim(this.workflowData.value.toUpperCase()),
          };

          this.$emit('submittedProfileData', data);
        } else {
          this.scrollToFailedValidation(result);
        }
      });
    },

    scrollToFailedValidation(validationResult) {
      const invalidField = Object.keys(validationResult.results).find(
        (field) => !validationResult.results[field].valid,
      );
      if (invalidField) {
        const fieldRef = this.$refs[invalidField];
        if (fieldRef && fieldRef.$el) {
          fieldRef.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },

    openEmailChangeModal() {
      this.showEmailChangeModal = true;
    },
  },
};
</script>
