<template>
  <div
    class="transition duration-200 ease-in-out relative rounded-md border-2 my-3 p-4 flex bg-gray-50 md:cursor-pointer font-normal text-sm hover:border-rendin-500 focus:border-rendin-500"
  >
    <div
      class="list-none flex flex-col sm:flex-row justify-center items-center content-start"
    >
      <div class="w-10 h-10 mr-5 ml-2 my-auto flex">
        <img
          :alt="activityIconAlt ? activityIconAlt : ''"
          :src="activityIconFilename"
        />
      </div>
    </div>
    <div class="text-m sm:text-lg text-left">
      <h4 class="font-medium">
        {{ activityTitle }}
      </h4>
      <RichText :blok="activityDescription" class="-my-3 text-gray-600" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionLine',
  props: {
    activityIconAlt: {
      type: String,
      default: '',
    },
    activityIconFilename: {
      type: String,
      default: '',
    },
    activityTitle: {
      type: String,
      default: '',
    },
    activityDescription: {
      type: Object,
    },
  },
};
</script>
