<template>
  <section id="article-items-container" v-editable="blok" class="article-items py-12">
    <div class="mx-auto max-w-6xl px-4">
      <div v-if="blok.highLightedArticle && paginationPage === 1">
        <r-article-card
          :author-name="blok.highLightedArticle?.content.authorName"
          class="h-full"
          :date="blok.highLightedArticle?.first_published_at"
          highlighted
          :image="blok.highLightedArticle?.content.image.filename"
          :intro="blok.highLightedArticle?.content.intro"
          :slug="blok.highLightedArticle?.full_slug"
          :title="blok.highLightedArticle?.content.title"
        />
      </div>
      <div
        class="mt-8 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-6 lg:gap-10 lg:max-w-none"
      >
        <div v-for="article in articles" :key="article._uid">
          <r-article-card
            :author-name="article.content.authorName"
            class="h-full"
            :date="article.first_published_at"
            :image="article.content.image.filename"
            :intro="article.content.intro"
            :intro-max-length="Number(120)"
            :slug="article.full_slug"
            :title="article.content.title"
          />
        </div>
      </div>

      <r-pagination
        :active-page="paginationPage"
        class="my-12"
        :total-pages-number="paginationTotalPages"
        @click-page-change="goToPage($event)"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import RArticleCard from '~/components/r-interface-components/r-article-card';
import RPagination from '~/components/r-interface-components/r-pagination';

export default {
  name: 'ArticleItems',
  components: {
    RArticleCard,
    RPagination,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup() {
    const storyblokApi = useStoryblokApi();

    return {
      storyblokApi,
    };
  },

  data() {
    return {
      articles: [],
      totalArticles: null,
      paginationPage: 1,
      paginationTotalPages: null,
      articlesOnPage: 12,
      articlesOnFirstPageIfHighlighted: 12,
    };
  },
  computed: {
    ...mapGetters({
      getLocale: 'getLocale',
    }),

    articlesOnFirstPage() {
      return this.blok.highLightedArticle
        ? this.articlesOnFirstPageIfHighlighted
        : this.articlesOnPage;
    },
    articlesPerPage() {
      return this.paginationPage === 1 && this.blok.highLightedArticle
        ? this.articlesOnFirstPage
        : this.articlesOnPage;
    },
  },

  mounted() {
    const pageQueryParam = parseInt(this.$route.query.page);
    if (!isNaN(pageQueryParam) && pageQueryParam > 1) {
      this.paginationPage = pageQueryParam;
    }

    this.fetchPages();
  },

  methods: {
    goToPage(page) {
      this.paginationPage = Number(page);
      this.fetchPages();
      this.scrollComponentUp();

      let pageQuery = null;
      if (this.paginationPage > 1) {
        pageQuery = {
          page: this.paginationPage,
        };
      }

      this.$router.replace({
        path: this.$route.path,
        params: this.$route.params,
        query: { ...pageQuery },
      });
    },

    async fetchPages() {
      const articleTranslationHiddenKey =
        this.getLocale === 'en'
          ? 'articleTranslationHidden'
          : `articleTranslationHidden__i18n__${this.getLocale}`;
      const options = {
        version: this.$config.public.STORYBLOK_VERSION,
        language: this.getLocale,
        starts_with: `${this.$getCountry() === 'pl' ? 'poland' : 'estonia'}/articles/`,
        is_startpage: false,
        per_page: this.articlesPerPage ? this.articlesPerPage : 3,
        page: this.paginationPage,
        excluding_ids: this.blok.highLightedArticle?.id
          ? this.blok.highLightedArticle.id
          : null,
        filter_query: {
          [articleTranslationHiddenKey]: {
            not_in: true,
          },
        },
        sort_by: 'first_published_at:desc',
      };

      await this.storyblokApi.get(`cdn/stories`, options).then((response) => {
        if (response) {
          this.articles = response.data.stories;
          this.totalArticles = response.headers.total;
          this.paginationTotalPages =
            Math.ceil(
              (this.totalArticles - this.articlesOnFirstPage) / this.articlesOnPage,
            ) + 1;
        }
      });
    },

    scrollComponentUp() {
      this.$scrollTo('#article-items-container', { x: true, y: true });
    },
  },
};
</script>
