<template>
  <div v-editable="blok" class="flex flex-col gap-4 items-center">
    <div class="max-w-3xl px-4 flex flex-col gap-4 pt-4">
      <h1 class="text-5xl text-center font-bold">{{ blok.title }}</h1>
      <RichText :blok="blok.intro" />
      <div class="bg-peach-200 border-1 border-gray-800 p-4 flex flex-col">
        <p class="font-bold uppercase text-xl">TABLE OF CONTENTS</p>
        <a
          v-for="(section, index) in blok.sections"
          :key="index + '-link'"
          class="text-gray-600 hover:text-gray-900"
          :href="'#' + getSectionId(section)"
          >{{ index + 1 }}. {{ section.title }}</a
        >
      </div>
    </div>
    <template v-for="(section, index) in blok.sections" :key="index">
      <div
        :id="getSectionId(section)"
        class="m-3 border-1 border-gray-200 self-stretch"
      ></div>
      <div class="self-stretch flex flex-col items-center">
        <div class="max-w-3xl pl-4 w-full">
          <h2 class="text-3xl">{{ index + 1 }}. {{ section.title }}</h2>
          <a
            class="text-rendin-500 font-medium hover:text-rendin-300 text-xs uppercase"
            :href="getStoryblokLink(section)"
            target="_blank"
            >Link to Storyblok</a
          >
          <RichText :blok="section.description" />
        </div>
        <div class="self-stretch">
          <component
            :is="sectionExample.component"
            v-for="sectionExample in section.examples"
            :key="sectionExample._uid"
            :blok="sectionExample"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSectionId(section) {
      if (!section.title) {
        return '';
      }
      return section.title.toLowerCase().replace(/ /g, '-');
    },
    getStoryblokLink(section) {
      const storyId = this.blok.story_id;
      const blokId = section._uid;

      return `https://app.storyblok.com/#/me/spaces/103362/stories/0/0/${storyId}/blok/${blokId}`;
    },
  },
};
</script>
