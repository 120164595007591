<template>
  <div
    v-if="backgroundCheckIntroInfo"
    class="bg-white rounded-lg py-4 sm:p-4 flex justify-center shadow-md"
  >
    <div class="sm:w-2/3">
      <h2
        v-if="backgroundCheckIntroInfo.title"
        class="text-xl font-bold text-center pb-6"
      >
        {{ backgroundCheckIntroInfo.title }}
      </h2>
      <RichText
        v-if="
          backgroundCheckIntroInfo &&
          typeof backgroundCheckIntroInfo.contentRichText === 'object' &&
          backgroundCheckIntroInfo.contentRichText.content.length > 0 &&
          backgroundCheckIntroInfo.contentRichText.content[0].content
        "
        :blok="backgroundCheckIntroInfo.contentRichText"
        class="text-sm text-center"
      />
      <p
        v-if="
          backgroundCheckIntroInfo &&
          typeof backgroundCheckIntroInfo.secondContentRichText === 'object' &&
          backgroundCheckIntroInfo.secondContentRichText.content.length > 0 &&
          backgroundCheckIntroInfo.secondContentRichText.content[0].content
        "
        @click="emitClickEvent"
      >
        <RichText
          :blok="backgroundCheckIntroInfo.secondContentRichText"
          class="text-rendin-400 text-center font-bold"
        />
      </p>
    </div>
    <div v-if="backgroundCheckIntroInfo.image" class="flex items-center sm:w-1/3">
      <img alt="Image not found" :src="backgroundCheckIntroInfo.image.filename" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackgroundCheckIntro',

  props: {
    backgroundCheckIntroInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ['secondContentClick'],

  methods: {
    emitClickEvent(event) {
      /**
       * Click event.
       *
       * @event click
       * @type {object}
       */
      this.$emit('secondContentClick', event);
    },
  },
};
</script>
