<template>
  <div
    v-editable="blok"
    class="px-4 py-1 md:px-8 md:py-4 rounded-xl"
    :class="backgroundClasses"
  >
    <div class="font-medium mb-6 mt-4">
      <component
        :is="titleComponent"
        :id="
          replaceSpacesWithDashAndLowercase(
            blok?.titleForTableOfContent ? blok?.titleForTableOfContent : blok?.title,
          )
        "
        :class="classes"
      >
        <span v-html="blok.title"></span>
      </component>
    </div>

    <div class="r-rich-text" v-html="content"></div>
  </div>
</template>

<script>
import { replaceSpacesWithDashAndLowercase } from '~/utils/replaceSpacesWithDashAndLowercase.js';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'ArticleSection',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      replaceSpacesWithDashAndLowercase,
      localizedRenderRichText,
    };
  },
  computed: {
    titleComponent() {
      if (this.blok?.titleStyle?.length > 0) {
        return this.blok.titleStyle;
      }

      return 'h2';
    },
    content() {
      return this.localizedRenderRichText(this.blok.content, this.$localizedPath);
    },

    classes() {
      const classes = [];
      if (this.blok.titleStyle === 'h2') {
        classes.push('text-2xl md:text-3xl');
      } else if (this.blok.titleStyle === 'h3') {
        classes.push('text-xl md:text-2xl');
      }

      return classes;
    },

    backgroundClasses() {
      const classes = [];
      if (this.blok.backgroundColor) {
        classes.push(backgroundColors[this.blok.backgroundColor]);
      }

      return classes;
    },
  },
};
</script>
