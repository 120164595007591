<template>
  <section :id="blok.id" v-editable="blok" class="features-comparison-view-section">
    <div class="max-w-6xl mx-auto p-4 py-12 md:py-20">
      <h2
        v-if="blok.title"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="my-4 font-semibold text-3xl md:text-4xl opacity-0"
        v-html="blok.title"
      ></h2>
      <RichText
        v-if="blok.description"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        :blok="blok.description"
        class="max-w-3xl opacity-0"
      />

      <div
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="opacity-0"
      >
        <div v-if="switchTabs?.length > 1" class="max-w-2xl mx-auto my-6">
          <r-tabs-switch v-model="activeTab" :tabs="switchTabs" />
        </div>

        <div
          v-for="card in blok.cards"
          :key="card._uid"
          v-editable="card"
          class="rounded-3xl overflow-hidden relative"
          :class="cardBackground(card.backgroundColor)"
        >
          <div
            v-if="card._uid === activeTab"
            class="max-w-6xl mx-auto md:grid-cols-2 md:grid"
          >
            <div class="py-4 text-gray-600 p-4 sm:p-8 lg:px-12">
              <div>
                <h3
                  v-if="card.title"
                  class="my-4 font-semibold text-gray-900 text-2xl md:text-3xl"
                  v-html="card.title"
                ></h3>
                <component
                  :is="theBlok.component"
                  v-for="theBlok in card.content.filter((item) =>
                    ['TextBlok', 'RichTextBlok', 'FeatureCheckmarkList'].includes(
                      item.component,
                    ),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                  class="w-full"
                />
              </div>
              <div class="my-4 flex gap-4 flex-wrap items-center">
                <component
                  :is="theBlok.component"
                  v-for="theBlok in card.buttons.filter((item) =>
                    ['ButtonBlok', 'ButtonWithContentModal'].includes(item.component),
                  )"
                  :key="theBlok._uid"
                  :blok="theBlok"
                  class="w-full sm:w-auto"
                />
              </div>
            </div>

            <!--         Mobile image-->
            <div v-if="card.image?.filename" class="md:hidden">
              <img
                :alt="card.image.alt"
                class="rounded-bl-3xl rounded-br-3xl mx-auto"
                :src="$transformStoryblokImage(card.image.filename, '/m/740x0/')"
              />
            </div>

            <!--          Desktop image-->
            <div
              v-if="card.image?.filename"
              class="hidden md:block bg-no-repeat bg-cover bg-center"
              :class="[
                card.imagePositionLeft
                  ? 'md:order-first'
                  : 'absolute h-full w-1/2 top-0 bottom-0 left-1/2',
              ]"
              :style="{
                'background-image':
                  'url(' +
                  $transformStoryblokImage(card.image.filename, '/m/fit-in/600x0') +
                  ')',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'FeaturesComparisonViewSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab:
        this.blok?.cards?.[
          this.blok?.activeCardNumber &&
          this.blok?.activeCardNumber <= this.blok?.cards?.length
            ? this.blok?.activeCardNumber - 1
            : 0
        ]?._uid,
    };
  },

  computed: {
    switchTabs() {
      return this.blok.cards.map((el) => ({
        id: el._uid,
        title: el.tabLabel,
      }));
    },
  },

  methods: {
    cardBackground(backgroundColor) {
      const classes = [];
      classes.push(backgroundColors[backgroundColor] ?? 'bg-white');

      return classes;
    },
  },
};
</script>
