<template>
  <div class="flex flex-col justify-center">
    <r-loader class="pb-6" />
    <r-title-with-text
      :content='$t("bgCheck.running")'
      content-font-size="16px"
      text-align="center"
      :title='$t("please_wait")'
      title-font-size="24px"
    />
  </div>
</template>

<script>
export default {
  name: 'BackgroundCheckLoading',
};
</script>
