<template>
  <div v-editable="blok" class="md:flex-wrap h-4/6 mt-10 md:mt-0">
    <img class="mx-auto md:w-4/6 md:-mt-16" :src="blok.image.filename" />
  </div>
</template>

<script>
export default {
  name: 'SectionImage',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  methods: {},
};
</script>

<style scoped>
/* img {
    height: 400px;
  }

  @media (min-width: 767px) {
    img {
      height: auto
    }
  } */
</style>
