<template>
  <r-overlay
    :back-button="isEditServiceView || isAddServiceView"
    :fixed-height="isUtilitiesView"
    :prevent-close="
      isResettingServicesAndUtilities || isSettingServicesAndUtilitiesAfterReset
    "
    :size="ComponentSize.LARGE"
    :visible="showModal"
    @back-button-clicked="goBackFromServiceItemView()"
    @close="closeOverlay()"
  >
    <div class="h-full">
      <div ref="serviceAndUtilityHeader" class="flex flex-col p-6 gap-2">
        <div :class="{ 'mt-6': isAddServiceView || isEditServiceView }">
          <span
            class="py-2 text-1.5xl"
            :class="{ 'flex justify-center': isDeleteListView }"
          >
            {{ titleValue }}
          </span>
          <p
            class="opacity-60 pt-2 text-sm"
            :class="{
              'text-xs': isSelectUtilitiesView,
              'flex justify-center': isDeleteListView,
            }"
          >
            <span v-html="subtitleValue"></span>
          </p>
        </div>
        <div v-if="isUtilitiesView">
          <div
            class="bg-peach-200 p-3 text-gray-600 rounded-lg text-sm my-4 flex items-center"
          >
            <r-icon class="mr-2 text-base" icon-name="pen" />
            <span v-html="$t('services.label.add_service_explanation')"></span>
          </div>
          <div class="flex flex-wrap justify-between gap-2">
            <r-button
              data-testid="button.services-and-utilities.reset-list"
              icon="arrow-rotate-left"
              :label="$t('buttons.delete_list')"
              no-background
              no-padding
              @click="showDeleteListView = true"
            />
            <r-button
              class="pr-4 pl-4"
              data-testid="button.services-and-utilities.add-service"
              icon="plus"
              inverted
              :label="$t('buttons.add_service')"
              size="small"
              @click="showAddServiceView = true"
            />
          </div>
        </div>
      </div>
      <div class="h-full">
        <!-- Select list view -->
        <agreement-utilities-checklist
          v-if="isSelectUtilitiesView"
          :agreement-id="agreementId"
          :agreement-workflow="agreementWorkflow"
          :is-loading-save-button="isSettingServicesAndUtilitiesAfterReset"
          @update-list="setAfterResetServicesAndUtilities"
        />

        <!-- Delete list view -->
        <div v-else-if="isDeleteListView" class="p-6 flex justify-center gap-6">
          <r-button
            border-color="light-gray"
            color="black"
            :disabled="isResettingServicesAndUtilities"
            inverted
            :label="$t('buttons.keep_the_list')"
            @click="
              isResettingServicesAndUtilities ? null : (showDeleteListView = false)
            "
          />
          <r-button
            :is-loading="isResettingServicesAndUtilities"
            :label="$t('buttons.delete_the_list')"
            @click="
              isResettingServicesAndUtilities
                ? null
                : resetSelectedServicesAndUtilities()
            "
          />
        </div>
        <!-- Add or Edit service view -->
        <div v-else-if="isAddServiceView || isEditServiceView">
          <agreement-utilities-list-item-view
            :agreement-id="agreementId"
            :agreement-workflow="agreementWorkflow"
            :currency="currency"
            :selected-services-and-utilities="servicesAndUtilitiesSelected"
            :service="selectedService"
            :view-mode="modalStep"
            @add-service="addServiceAndUtility($event)"
            @back-button-clicked="goBackFromServiceItemView"
            @delete-service="deleteServiceFromList($event)"
            @update-service="updateServiceAndUtility($event)"
          />
        </div>
        <!-- Utilities list view -->
        <div v-else-if="isUtilitiesView" class="h-full">
          <agreement-utilities-list-view
            class="bg-gray-50 h-full"
            :currency="currency"
            :is-delete-in-progress="isDeleteItemInProgress"
            :services-and-utilities-list="servicesAndUtilitiesSelected"
            @delete-service="deleteServiceFromList($event)"
            @edit-service="editServiceFromList($event)"
          />
        </div>
      </div>
    </div>
    <r-sticky-container v-if="isUtilitiesView" :reserve-space="false" sticky>
      <div class="flex justify-center w-full">
        <r-button
          class="w-full sm:w-max"
          data-testid="button.services-and-utilities.save"
          :is-loading="putAgreementInProgress"
          :label="$t('buttons.save_and_close')"
          width="wide"
          @click="closeOverlay()"
        />
      </div>
    </r-sticky-container>
  </r-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ComponentSize,
  utilitiesAndServicesModalSteps,
  workflow,
} from '~/utils/constants';
import AgreementUtilitiesChecklist from '~/components/section/agreement/AgreementUtilitiesChecklist';
import AgreementUtilitiesListView from '~/components/section/agreement/AgreementUtilitiesListView';
import AgreementUtilitiesListItemView from '~/components/section/agreement/AgreementUtilitiesListItemView';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import { defaultServicesAndUtilitiesOtherObject } from '~/utils/objectStructures';

export default {
  name: 'AgreementUtilitiesAndServicesModal',
  components: {
    AgreementUtilitiesListItemView,
    AgreementUtilitiesListView,
    AgreementUtilitiesChecklist,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementServicesAndUtilitiesFromFirebase: {
      type: Array,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementId: {
      type: String,
      default: null,
    },
    agreementWorkflow: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: 'EUR',
    },
  },

  emits: ['closeOverlay'],

  data() {
    return {
      servicesAndUtilitiesSelected: [],
      showSelectServicesView: false,
      showAddServiceView: false,
      showDeleteListView: false,
      showEditServiceView: false,
      selectedService: {},
      ComponentSize: ComponentSize,
      isResettingServicesAndUtilities: false,
      isSettingServicesAndUtilitiesAfterReset: false,
      isDeleteItemInProgress: false,
      agreementTrackingEventNames: agreementTrackingEventNames,
    };
  },

  computed: {
    ...mapGetters({
      putAgreementInProgress: 'agreements/putAgreementInProgress',
    }),

    modalStep() {
      if (this.showSelectServicesView) {
        return utilitiesAndServicesModalSteps.SELECT_UTILITIES;
      } else if (this.showEditServiceView) {
        return utilitiesAndServicesModalSteps.EDIT_SERVICE_VIEW;
      } else if (this.showDeleteListView) {
        return utilitiesAndServicesModalSteps.DELETE_LIST_VIEW;
      } else if (this.showAddServiceView) {
        return utilitiesAndServicesModalSteps.ADD_SERVICE_VIEW;
      } else {
        return utilitiesAndServicesModalSteps.UTILITIES_VIEW;
      }
    },

    isSelectUtilitiesView() {
      return this.modalStep === utilitiesAndServicesModalSteps.SELECT_UTILITIES;
    },

    isUtilitiesView() {
      return this.modalStep === utilitiesAndServicesModalSteps.UTILITIES_VIEW;
    },

    isDeleteListView() {
      return this.modalStep === utilitiesAndServicesModalSteps.DELETE_LIST_VIEW;
    },

    isAddServiceView() {
      return this.modalStep === utilitiesAndServicesModalSteps.ADD_SERVICE_VIEW;
    },

    isEditServiceView() {
      const isEditView =
        this.modalStep === utilitiesAndServicesModalSteps.EDIT_SERVICE_VIEW;
      if (isEditView) {
        this.emitAgreementTrackerEvent(
          this.agreementTrackingEventNames.EDIT_UTILITY_VIEW,
        );
      }
      return isEditView;
    },

    isPoland() {
      return this.agreementWorkflow === workflow.POLAND;
    },

    titleValue() {
      if (this.isSelectUtilitiesView) {
        return this.$t('services.label.select_utilities');
      } else if (this.isUtilitiesView) {
        return this.$t('services.label.services_and_utilities');
      } else if (this.isDeleteListView) {
        return this.$t('services.label.delete_list');
      } else if (this.isAddServiceView) {
        return this.$t('services.label.add_new_service');
      } else if (this.isEditServiceView) {
        return this.$t('services.label.edit_service');
      }
      return '';
    },

    subtitleValue() {
      if (this.isSelectUtilitiesView) {
        return this.isPoland
          ? this.$t('services.label.select_utilities_subtitle.pl')
          : this.$t('services.label.select_utilities_subtitle.ee');
      } else if (this.isUtilitiesView) {
        return this.$t('services.label.services_and_other_subtitle');
      } else if (this.isDeleteListView) {
        return this.$t('services.label.delete_list_subtitle');
      } else if (this.isAddServiceView) {
        return this.$t('services.label.add_new_service_subtitle');
      } else if (this.isEditServiceView) {
        return this.$t('services.label.edit_service_subtitle');
      }
      return '';
    },
  },

  watch: {
    agreementServicesAndUtilitiesFromFirebase: {
      handler(newValue) {
        if (newValue) {
          this.servicesAndUtilitiesSelected = [...newValue];
        } else {
          this.servicesAndUtilitiesSelected = [];
        }
      },
    },
    modalStep: {
      handler() {
        this.$refs.serviceAndUtilityHeader.scrollIntoView({ behavior: 'smooth' });
      },
    },
  },

  mounted() {
    if (!this.agreementServicesAndUtilitiesFromFirebase) {
      this.showSelectServicesView = true;
    } else {
      this.servicesAndUtilitiesSelected = [
        ...this.agreementServicesAndUtilitiesFromFirebase,
      ];
    }
  },

  methods: {
    ...mapActions({
      actionPutAgreement: 'agreements/putAgreement',
      actionGetAgreement: 'agreements/getAgreement',
      actionPostAgreementServiceAndUtility: 'agreements/postAgreementServiceAndUtility',
      actionPutAgreementServicesAndUtilities:
        'agreements/putAgreementServicesAndUtilities',
      actionGetAgreementServicesAndUtilities:
        'agreements/getAgreementServicesAndUtilities',
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionDeleteAgreementServicesAndUtilities:
        'agreements/deleteAgreementServicesAndUtilities',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),
    closeOverlay() {
      if (
        !this.isResettingServicesAndUtilities &&
        !this.isSettingServicesAndUtilitiesAfterReset
      ) {
        this.getAgreementServicesAndUtilities();
        this.$emit('closeOverlay');
      }
    },

    getAgreementServicesAndUtilities() {
      this.actionGetAgreementServicesAndUtilities({ agreementId: this.agreementId });
    },

    addServiceAndUtility(service) {
      this.servicesAndUtilitiesSelected.push(service);
      if (this.isAddServiceView || this.isEditServiceView) {
        this.goBackFromServiceItemView();
      }
      this.postAgreementServiceAndUtility(service)
        .catch((err) => this.reportErrorToSentry(err))
        .finally(() => {
          this.getAgreementServicesAndUtilities();
        });
    },

    deleteServiceFromList(service) {
      this.isDeleteItemInProgress = true;
      const indexOfService = this.findServiceTypeIndex(
        service,
        this.servicesAndUtilitiesSelected,
      );
      if (Number.isInteger(indexOfService)) {
        this.servicesAndUtilitiesSelected.splice(indexOfService, 1);
      }
      if (this.isAddServiceView || this.isEditServiceView) {
        this.goBackFromServiceItemView();
      }
      return this.deleteAgreementServiceAndUtility(service)
        .catch((err) => this.reportErrorToSentry(err))
        .finally(() => {
          this.isDeleteItemInProgress = false;
          this.getAgreementServicesAndUtilities();
        });
    },

    updateServiceAndUtility(service) {
      if (service && Object.keys(service).length > 0) {
        let oldServiceAndUtility = {};
        const indexOfService = this.findServiceTypeIndex(
          service,
          this.servicesAndUtilitiesSelected,
        );
        if (Number.isInteger(indexOfService)) {
          oldServiceAndUtility = {
            ...this.servicesAndUtilitiesSelected[indexOfService],
          };
          this.servicesAndUtilitiesSelected[indexOfService] = service;
        }
        if (this.isAddServiceView || this.isEditServiceView) {
          this.goBackFromServiceItemView();
        }
        return this.actionPutAgreementServicesAndUtilities({
          agreementId: this.agreementId,
          serviceAndUtility: service,
        })
          .then((response) => {
            if (response?.response?.success) {
              this.emitAgreementTrackerEvent(
                agreementTrackingEventNames.CHANGE_UTILITY,
                {
                  utilityName: service.typeName,
                  utilityPayer: service.whoIsPaying,
                  customUtilityName: service.type.includes('OTHER')
                    ? service.typeName
                    : null,
                  utilityPaymentMethod: service.paymentMethod,
                  utilityPaymentAmount: service.payment?.amount,
                  utilityPaymentDetails: service.payment?.details,
                  utilityId: service.type,
                  oldUtilityPayer: oldServiceAndUtility.whoIsPaying,
                  oldCustomUtilityName: service.type.includes('OTHER')
                    ? oldServiceAndUtility.typeName
                    : null,
                  oldUtilityPaymentMethod: oldServiceAndUtility.paymentMethod,
                  oldUtilityPaymentAmount: oldServiceAndUtility.payment?.amount,
                  oldUtilityPaymentDetails: oldServiceAndUtility.payment?.details,
                },
              );
            }
            return response;
          })
          .catch((err) => this.reportErrorToSentry(err))
          .finally(() => {
            this.getAgreementServicesAndUtilities();
          });
      }
    },

    async setAfterResetServicesAndUtilities(servicesAndUtilities) {
      try {
        this.isSettingServicesAndUtilitiesAfterReset = true;
        if (this.servicesAndUtilitiesSelected && servicesAndUtilities.length === 0) {
          this.servicesAndUtilitiesSelected = [];
          return this.actionPostAgreementServiceAndUtility({
            agreementId: this.agreementId,
            serviceAndUtility: defaultServicesAndUtilitiesOtherObject(),
          })
            .then((response) =>
              this.deleteAgreementServiceAndUtility({
                type: response.response.data.created.type,
              }),
            )
            .finally(() => {
              this.getAgreementServicesAndUtilities();
              this.showSelectServicesView = false;
              this.isSettingServicesAndUtilitiesAfterReset = false;
            });
        }
        this.servicesAndUtilitiesSelected = servicesAndUtilities;
        const promiseArray = [];
        for (const service of servicesAndUtilities) {
          promiseArray.push(this.postAgreementServiceAndUtility(service));
        }
        const resultArray = await Promise.all(promiseArray).finally(() => {
          this.getAgreementServicesAndUtilities();
          this.showSelectServicesView = false;
          this.isSettingServicesAndUtilitiesAfterReset = false;
        });
      } catch (err) {
        this.reportErrorToSentry(err);
      }
    },

    async resetSelectedServicesAndUtilities() {
      try {
        this.isResettingServicesAndUtilities = true;
        this.emitAgreementTrackerEvent(
          this.agreementTrackingEventNames.RESET_UTILITIES,
        );
        const servicesAndUtilitiesDeleted = [...this.servicesAndUtilitiesSelected];
        const promiseArray = [];
        for (const service of servicesAndUtilitiesDeleted) {
          promiseArray.push(this.deleteAgreementServiceAndUtility(service));
        }
        const resultArray = await Promise.all(promiseArray).finally(() => {
          this.getAgreementServicesAndUtilities();
          this.showDeleteListView = false;
          this.showSelectServicesView = true;
          this.isResettingServicesAndUtilities = false;
        });
      } catch (err) {
        this.reportErrorToSentry(err);
      }
    },

    postAgreementServiceAndUtility(service) {
      return this.actionPostAgreementServiceAndUtility({
        agreementId: this.agreementId,
        serviceAndUtility: service,
      }).then((response) => {
        if (response?.response?.success) {
          this.emitAgreementTrackerEvent(agreementTrackingEventNames.ADD_UTILITY, {
            utilityName: service.typeName,
            utilityPayer: service.whoIsPaying,
            customUtilityName: service.type.includes('OTHER') ? service.typeName : null,
            utilityPaymentMethod: service.paymentMethod,
            utilityPaymentAmount: service.payment?.amount,
            utilityPaymentDetails: service.payment?.details,
            utilityId: service.type,
          });
        }
        return response;
      });
    },

    deleteAgreementServiceAndUtility(service) {
      return this.actionDeleteAgreementServicesAndUtilities({
        agreementId: this.agreementId,
        type: service.type,
      }).then((response) => {
        if (!response.failed) {
          this.emitAgreementTrackerEvent(agreementTrackingEventNames.REMOVE_UTILITY, {
            utilityName: service.typeName,
            utilityPayer: service.whoIsPaying,
            customUtilityName: service.type.includes('OTHER') ? service.typeName : null,
            utilityPaymentMethod: service.paymentMethod,
            utilityPaymentAmount: service.payment?.amount,
            utilityPaymentDetails: service.payment?.details,
            utilityId: service.type,
          });
        }
        return response;
      });
    },

    goBackFromServiceItemView() {
      this.selectedService = {};
      this.showEditServiceView = false;
      this.showAddServiceView = false;
    },

    findServiceTypeIndex(service, servicesArray) {
      for (let i = 0; i < servicesArray.length; i++) {
        if (servicesArray[i].type === service.type) {
          return i;
        }
      }
      return null;
    },

    editServiceFromList(service) {
      this.selectedService = service;
      this.showEditServiceView = true;
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
