import { cloneDeep } from 'lodash';
import {
  httpsCallable,
  getFunctions,
  connectFunctionsEmulator,
} from 'firebase/functions';

const removeValuesFromObjectAndReturnCopy = (obj, valuesToRemove = [undefined]) =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj)
    ? Object.entries({ ...obj })
        .filter(([k, v]) => !valuesToRemove.includes(v))
        .reduce(
          (r, [key, value]) => ({
            ...r,
            [key]: removeValuesFromObjectAndReturnCopy(value),
          }),
          {},
        )
    : obj;

const _functionCallRegionEU = (name, dataObject = undefined, useEmulator = false) => {
  // For Firebase, all undefined values become nulls. Let us escape this.
  const data = removeValuesFromObjectAndReturnCopy(dataObject, [undefined]);
  const functionsRegionEU = getFunctions(undefined, 'europe-west1');
  if (useEmulator) {
    const emulatorHost = '127.0.0.1';
    const emulatorFunctionsPort = 5001;
    connectFunctionsEmulator(functionsRegionEU, emulatorHost, emulatorFunctionsPort);
  }
  return httpsCallable(functionsRegionEU, name)(data);
};

/**
 * Mutations generated to relate {actionFirebaseRequest} and {setupState}
 *
 * @param {[string]} requests - array of requests to support. The same for {setupState} and {setupGetters}
 */
export const setupMutations = (requests) => ({
  ...requests.reduce(
    (mutations, request) => ({
      ...mutations,
      [`${request}Response`]: (state, data) =>
        (state[request].response = cloneDeep(data)),
      [`${request}InProgress`]: (state, inProgress) =>
        (state[request].inProgress = inProgress),
      [`${request}Failed`]: (state, failed) => (state[request].failed = failed),
    }),
    {},
  ),
});

/**
 * Exposes getters for the user. Creates a getter for every request. For example ['getUserData'] creates:
 * <pre>
 *   1. getUserDataInProgress - true if request in progress, false otherwise.
 *   2. getUserDataFailed - true if request failed, false otherwise.
 *   3. getUserDataResponse - represents response of the request, once arrived.
 *                            Expects getUserDataInProgress and getUserDataFailed both to be false.
 * </pre>
 *
 * @param {[string]} requests - array of requests to support. The same for {setupState} and {setupMutations}
 * @see setupMutations
 */
export const setupGetters = (requests) => ({
  ...requests.reduce(
    (getters, request) => ({
      ...getters,
      [`${request}Response`]: (state) => state[request].response,
      [`${request}InProgress`]: (state) => state[request].inProgress,
      [`${request}Failed`]: (state) => state[request].failed,
    }),
    {},
  ),
});

/**
 * Required for the {setupGetters}, {setupMutations} and actions (setup with {actionFirebaseRequest}) to share state.
 *
 * @param {[string]} requests - array of requests to support. The same for {setupGetters} and {setupMutations}
 * @return {object} state to destruct into the one been defined.
 */
export const setupState = (requests) => ({
  ...requests.reduce(
    (state, request) => ({
      ...state,
      [`${request}`]: { response: null, inProgress: false, failed: false },
    }),
    {},
  ),
});

/**
 * @typedef FirebaseResponse
 * @type {Promise}
 * @property {object|undefined} response - firebase function call return value.
 * @property {boolean} failed - indication if the request failed or not.
 */

/**
 * @callback FirebaseRequest
 * @param {string} request - name of the firebase function to call. Used to reference state, getters and mutators.
 * @param {object|undefined} data - data to pass with the function call.
 * @param {string|undefined} functionRequestOverride - Keep request to use the state, getters and mutators
 *                                                          but use this firebase function call instead.
 * @param {[string]|undefined} expectedFunctionErrorCodes - An array of firebase error response codes we will handle in the calling action, should not report to sentry
 *                                                          Reference - https://firebase.google.com/docs/reference/node/firebase.functions#functionserrorcode
 * @returns {FirebaseResponse}
 */

/**
 * Need to be setup with a function call. Returns an action to dispatch firebase requests to.
 *
 * @return {FirebaseRequest}
 */
export const actionFirebaseRequest = function () {
  return function (
    { commit, dispatch, getters, rootGetters },
    {
      request,
      data = undefined,
      functionRequestOverride = undefined,
      expectedErrorCodes = undefined,
    },
  ) {
    const requestResolved = functionRequestOverride || request;
    commit(`${request}Failed`, false);
    commit(`${request}InProgress`, true);
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: requestResolved, data: data },
          { root: true },
        ),
      )
      .then((response) => commit(`${request}Response`, response.data))
      .catch((err) => {
        if (rootGetters.isENVIsDevelopment) {
          console.error(`Requested: '${requestResolved}' error: '${err.message}'`);
        }
        commit(`${request}Failed`, true);
        if (Array.isArray(expectedErrorCodes)) {
          if (expectedErrorCodes.includes(err.code)) {
            return Promise.resolve();
          }
        } else if (rootGetters.isENVIsDevelopment) {
          console.log(
            `If this error is expected behaviour, please add expectedErrorCodes: ['${err.code}'] to your firebaseRequest,
            otherwise this error will be always reported to Sentry.`,
          );
        }
        return dispatch('tracker/reportErrorToSentry', err, { root: true });
      })
      .finally(() => commit(`${request}InProgress`, false))
      .then(() => {
        const ret = {
          response: getters[`${request}Response`],
          failed: getters[`${request}Failed`],
        };
        if (rootGetters.isENVIsDevelopment) {
          console.log(`Requested: '${requestResolved}' returning:`, ret);
        }
        return ret;
      });
  };
};

export const actions = {
  /**
   * @param {string} functionName
   * @param {object} data
   */
  functionCallRegionEU: function ({ rootGetters }, { functionName, data = undefined }) {
    return _functionCallRegionEU(functionName, data, rootGetters.useEmulator);
  },
};
