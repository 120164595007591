<template>
  <section
    v-if="blok.country === $getCountry()"
    :id="blok.id"
    v-editable="blok"
    class="box-border relative block w-full py-10 md:py-20 overflow-hidden leading-6 text-left text-white bg-gray-50 border-b border-gray-200 px-3 md:px-0"
  >
    <div class="max-w-5xl mx-auto leading-6 text-left">
      <div
        class="box-border flex flex-wrap justify-center text-center text-gray-800 lg:justify-start lg:text-left"
      >
        <component
          :is="blok.component"
          v-for="blok in blok.testimonials"
          :key="blok._uid"
          :blok="blok"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionTestimonials',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
