<template>
  <div class="flex items-stretch" :class="{ 'flex-col': mobile, 'flex-row': !mobile }">
    <div
      v-for="(column, index) in columns"
      :key="index"
      class="flex flex-col border-gray-100"
      :class="{
        'border-l-1': column.secondaryColumn && !mobile,
        'border-t-1 pt-4': mobile,
        'w-60': !mobile,
      }"
    >
      <mega-menu-column
        :items="column.items"
        :secondary-column="column.secondaryColumn"
        :title="column.title"
        @menu-item-click="(menuItem) => $emit('menuItemClick', menuItem)"
      />
    </div>
  </div>
</template>
<script>
import MegaMenuColumn from '~/components/section/mega-menu/MegaMenuColumn.vue';

export default {
  name: 'MegaMenu',
  components: { MegaMenuColumn },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['menuItemClick'],
  computed: {
    NoOfColumns() {
      return this.columns.length;
    },
  },
};
</script>
