<template>
  <div class="mx-auto grid justify-items-center content-center">
    <svg
      v-if="type === 'spinner'"
      class="animate-spin text-grey"
      :class="{
        'h-10 w-10': size == 24,
        'h-6 w-6': size == 20,
        'mb-6': phrases,
      }"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      />
    </svg>
    <span v-if="type === 'animatedLogo'">
      <img src="/images/R-animated.svg" />
    </span>
    <span
      v-if="type === 'animatedBar'"
      class="r-loader_animated h-1.5 bg-gray-300 fixed w-full z-10"
    ></span>
    <div v-if="phrases" class="h-6 font-light text-center max-w-2xl mx-auto px-2">
      <transition v-if="phrases && phrases.length > 0" name="appearance">
        <p v-if="show">
          {{ randomPhraseFromArray(phrases) }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RLoader',
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    /**
     * Loader type | [ `spinner`, `animatedLogo`,`animatedBar` ]
     */
    type: {
      type: String,
      default: 'spinner',
    },
    /**
     * Is phrases needed or not
     */
    phrases: {
      type: Array,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  mounted() {
    if (this.phrases) {
      this.startPhrasesAnimation();
    }
  },

  methods: {
    randomPhraseFromArray(loaderStringArray) {
      return loaderStringArray[Math.floor(Math.random() * loaderStringArray.length)]
        .Phrase;
    },

    startPhrasesAnimation() {
      if (this.show) {
        setTimeout(() => {
          this.show = !this.show;
          this.startPhrasesAnimation();
        }, 4000);
      } else {
        setTimeout(() => {
          this.show = !this.show;
          this.startPhrasesAnimation();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
.appearance-enter-active {
  animation: appearance-in 0.5s;
}

.appearance-leave-active {
  animation: appearance-in 0.5s reverse;
}

@keyframes appearance-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.r-loader_animated {
  @apply overflow-hidden;

  &::after {
    @apply absolute top-0 left-0 h-full w-1/5 bg-gray-200;
    content: '';
    animation: loading-animation 2s infinite;
  }
}

@keyframes loading-animation {
  0% {
    left: 0;
    right: 100%;
    width: 0;
  }
  10% {
    left: 0;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0;
    width: 0;
  }
}
</style>
