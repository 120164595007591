<template>
  <VeeForm ref="validator.educationForm" v-slot="{ meta: formMeta, handleSubmit }">
    <form
      autocomplete="off"
      class="flex flex-col gap-3"
      @submit.prevent="handleSubmit(() => submitForm(formMeta.valid))"
    >
      <r-select
        v-model="educationLevel"
        :label="
          $t('tenant_profile.tabs.education_and_employment.education_level_label')
        "
        option-label="label"
        :options="educationLevels"
        :placeholder="
          $t('tenant_profile.tabs.education_and_employment.education_level_placeholder')
        "
        :reduce="(educationLevelOption) => educationLevelOption.value"
      />
      <Field v-slot="{ meta }" v-model="organization" rules="min:2" name="organization">
        <r-input
          id="organization"
          v-model="organization"
          class="mt-3"
          field-type="text"
          :label="$t('tenant_profile.tabs.education_and_employment.organization_label')"
          name="organization"
          :placeholder="
            $t('tenant_profile.tabs.education_and_employment.organization_placeholder')
          "
          :validation-failed="meta.validated && !meta.valid"
          :validation-passed="meta.validated && meta.valid"
        />
      </Field>
      <Field v-slot="{ meta }" v-model="salary" name="salaryRange">
        <r-select
          v-model="salary"
          :label="$t('tenant_profile.tabs.education_and_employment.salary_range_label')"
          option-label="label"
          :options="salaryRangeOptions"
          :placeholder="
            $t('tenant_profile.tabs.education_and_employment.salary_range_placeholder')
          "
          :reduce="(salaryRangeOption) => salaryRangeOption.value"
          :validation-failed="meta.validated && !meta.valid"
          :validation-passed="meta.validated && meta.valid"
        />
      </Field>
      <div class="buttons flex gap-3">
        <r-button no-background width="full" @click="closeTab">
          {{ $t('buttons.cancel') }}
        </r-button>
        <r-button type="submit" width="full">
          <span v-if="loading">
            <r-loader class="flex justify-center" size="20" />
          </span>
          <span v-else>
            {{ $t('buttons.save') }}
          </span>
        </r-button>
      </div>
    </form>
  </VeeForm>
</template>
<script>
import { useTenantConstants } from '~/composables/useTenantConstants.ts';
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { Form as VeeForm, Field } from 'vee-validate';

export default {
  name: 'TenantEducationAndEmploymentTab',
  components: {
    VeeForm,
    Field,
  },

  props: {
    tenantProfile: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['submit', 'close'],

  async setup() {
    const { educationLevels, convertSalaryToString } = useTenantConstants();
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return {
      educationLevels,
      convertSalaryToString,
      getAnalyticEventNames,
      trackTenantProfileEvent,
    };
  },
  data: () => ({
    salary: null,
    educationLevel: null,
    organization: null,
    validateEventTriggered: false,
    salaryRangesEur: [
      {
        salaryMin: 0,
        salaryMax: 649,
        salaryCurrency: 'EUR',
      },
      {
        salaryMin: 650,
        salaryMax: 1299,
        salaryCurrency: 'EUR',
      },
      {
        salaryMin: 1300,
        salaryMax: 1999,
        salaryCurrency: 'EUR',
      },
      {
        salaryMin: 2000,
        salaryMax: 2999,
        salaryCurrency: 'EUR',
      },
      {
        salaryMin: 3000,
        salaryMax: Number.MAX_SAFE_INTEGER,
        salaryCurrency: 'EUR',
      },
    ],
    salaryRangesPln: [
      {
        salaryMin: 2000,
        salaryMax: 4500,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 4500,
        salaryMax: 6000,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 6000,
        salaryMax: 7500,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 7500,
        salaryMax: 9000,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 9000,
        salaryMax: 10500,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 10500,
        salaryMax: 12000,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 12000,
        salaryMax: 13500,
        salaryCurrency: 'PLN',
      },
      {
        salaryMin: 13500,
        salaryMax: Number.MAX_SAFE_INTEGER,
        salaryCurrency: 'PLN',
      },
    ],
  }),
  computed: {
    salaryRangeOptions() {
      const salaryRangeOptions = [];
      let salaryRanges = [];
      if (this.$getCountry() === 'pl') {
        salaryRanges = this.salaryRangesPln;
      } else {
        salaryRanges = this.salaryRangesEur;
      }

      for (let i = 0; i < salaryRanges.length; i++) {
        const salary = salaryRanges[i];
        const computedLabel = this.convertSalaryToString(
          salary.salaryMin,
          salary.salaryMax,
          salary.salaryCurrency,
        );
        salaryRangeOptions.push({ label: computedLabel, value: salary });
      }
      return salaryRangeOptions;
    },
  },
  watch: {
    tenantProfile() {
      if (this.tenantProfile) {
        this.initializeForm(this.tenantProfile);
      }
    },
  },
  beforeMount() {
    if (this.tenantProfile) {
      this.initializeForm(this.tenantProfile);
    }
  },
  methods: {
    initializeForm(initialData) {
      this.salary = null;
      this.organization = null;
      this.educationLevel = null;

      // Algo - We want to find closest bracket based on salaryMin, either direct match or nearest one lower than salaryMin
      let nearestSalary = null;

      // Edge case - salaryMin == 0 && salaryMax == 0 means that no option is selected
      if (initialData.salaryMin >= 0 && initialData.salaryMax > 0) {
        const salaryRanges =
          initialData.salaryCurrency === 'PLN'
            ? this.salaryRangesPln
            : this.salaryRangesEur;
        for (let i = 0; i < salaryRanges.length; i++) {
          const rangeSalaryMin = salaryRanges[i].salaryMin;
          if (initialData.salaryMin === rangeSalaryMin) {
            this.salary = salaryRanges[i];
            break;
          }
          if (
            initialData.salaryMin > rangeSalaryMin &&
            nearestSalary !== null &&
            nearestSalary.salaryMin < rangeSalaryMin
          ) {
            nearestSalary = salaryRanges[i];
          }
        }
      }
      if (this.salary === null && nearestSalary !== null) this.salary = nearestSalary;

      this.organization = initialData.organization;
      this.educationLevel = initialData.educationLevel;
    },
    submitForm(isValid) {
      // validate parameter for disabling initial isValidTelInput false validation
      this.validateEventTriggered = true;
      if (isValid) {
        this.$emit('submit', {
          salaryMin: this.salary ? this.salary.salaryMin : 0,
          salaryMax: this.salary ? this.salary.salaryMax : 0,
          salaryCurrency: this.salary ? this.salary.salaryCurrency : '',
          educationLevel: this.educationLevel ? this.educationLevel : '',
          organization: this.organization ? this.organization : '',
        });
        this.trackTenantProfileEvent({
          eventName:
            this.getAnalyticEventNames.TENANT_PROFILE_EDIT_EDUCATION_AND_EMPLOYMENT,
        });
      }
    },
    closeTab() {
      this.$emit('close');
      this.initializeForm(this.tenantProfile);
    },
  },
};
</script>
