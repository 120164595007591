<template>
  <div v-editable="blok" :class="blok.bg_class">
    <header class="border-b border-t border-success-main bg-success-main px-3 md:px-0">
      <div class="mx-auto max-w-6xl">
        <div class="pb-8 pt-12 md:pt-16 md:pb-16">
          <h1
            class="m-0 text-3xl font-extrabold text-center text-white border-solid sm:text-4xl md:text-5xl mb-4"
            style="line-height: 1.2em"
          >
            {{ blok.title }}
          </h1>
        </div>
      </div>
    </header>

    <section class="mx-auto max-w-6xl">
      <div
        class="box-border flex flex-col justify-center w-full h-full px-3 md:p-8 pt-14 text-gray-900 border-solid lg:w-3/4 lg:mx-auto md:p-16 lg:p-0 lg:py-20 lg:pl-10 lg:pr-20"
      >
        <h2
          class="m-0 text-3xl font-semibold leading-tight text-left text-black sm:text-3xl md:text-4xl text-center"
        >
          {{ blok.sub_title }}
        </h2>
        <div
          class="grid gap-8 leading-7 border-0 border-gray-200 sm:gap-8 lg:gap-14 my-20 mt-14"
        >
          <div class="box-border flex items-start text-gray-900 border-solid">
            <div
              class="flex items-center justify-center w-10 h-10 md:w-14 md:h-14 pt-px bg-rendin-50 border border-rendin-500 rounded-full"
            >
              <p
                class="box-border m-0 p-0 text-xl md:text-3xl font-semibold text-rendin-500"
              >
                1
              </p>
            </div>
            <div class="flex-1 ml-3 md:ml-6 leading-7 border-0 border-gray-200">
              <p
                class="box-border text-lg lg:text-xl leading-normal font-medium text-gray-900 border-solid"
              >
                {{ blok.point_1 }}
              </p>
            </div>
          </div>

          <div class="box-border flex items-start text-gray-900 border-solid">
            <div
              class="flex items-center justify-center w-10 h-10 md:w-14 md:h-14 pt-px bg-rendin-50 border border-rendin-500 rounded-full"
            >
              <p
                class="box-border m-0 p-0 text-xl md:text-3xl font-semibold text-rendin-500"
              >
                2
              </p>
            </div>
            <div class="flex-1 ml-3 md:ml-6 leading-7 border-0 border-gray-200">
              <p
                class="box-border text-lg lg:text-xl leading-normal font-medium text-gray-900 border-solid"
              >
                {{ blok.point_2 }}
              </p>
            </div>
          </div>

          <div class="box-border flex items-start text-gray-900 border-solid">
            <div
              class="flex items-center justify-center w-10 h-10 md:w-14 md:h-14 pt-px bg-rendin-50 border border-rendin-500 rounded-full"
            >
              <p
                class="box-border m-0 p-0 text-xl md:text-3xl font-semibold text-rendin-500"
              >
                3
              </p>
            </div>
            <div class="flex-1 ml-3 md:ml-6 leading-7 border-0 border-gray-200">
              <p
                class="box-border text-lg lg:text-xl leading-normal font-medium text-gray-900 border-solid"
              >
                {{ blok.point_3 }}
              </p>
            </div>
          </div>

          <div class="box-border flex items-start text-gray-900 border-solid">
            <div
              class="flex items-center justify-center w-10 h-10 md:w-14 md:h-14 pt-px bg-rendin-50 border border-rendin-500 rounded-full"
            >
              <p
                class="box-border m-0 p-0 text-xl md:text-3xl font-semibold text-rendin-500"
              >
                4
              </p>
            </div>
            <div class="flex-1 ml-3 md:ml-6 leading-7 border-0 border-gray-200">
              <p
                class="box-border text-lg lg:text-xl leading-normal font-medium text-gray-900 border-solid"
              >
                {{ blok.point_4 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
