/**
 * @typedef {string} Address
 */
import { workflow as Workflow } from '~/utils/constants.js';

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestFindAddress = 'findAddress';

const requests = [requestFindAddress];

// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = { ...setupMutations(requests) };

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @param {address} address
   * @return {Promise<{
   *   response: string,
   *   failed: boolean,
   * }>}
   */
  findAddress: function ({ dispatch, rootGetters }, { address }) {
    let useAutocomplete;
    let workflow;
    if (rootGetters.getCountryIsEstonia) {
      workflow = 'EE';
      useAutocomplete = false;
    }
    if (rootGetters.getCountryIsPoland) {
      workflow = 'PL';
      useAutocomplete = true;
    }
    return dispatch('firebaseRequest', {
      request: requestFindAddress,
      data: {
        address: address,
        workflow: workflow,
        ifGoogleUseAutocomplete: useAutocomplete,
      },
    });
  },

  /**
   * @param { cityString } city
   * @param { countryString } country
   * @return {Promise<{
   *   response: string,
   *   failed: boolean,
   * }>}
   */

  findCity: function ({ dispatch, rootGetters }, { city, country }) {
    let useAutocomplete;
    let workflow;
    if (country === 'ee') {
      workflow = 'EE';
      useAutocomplete = false;
    }
    if (country === 'pl') {
      workflow = 'PL';
      useAutocomplete = true;
    }
    return dispatch('firebaseRequest', {
      request: requestFindAddress,
      data: {
        address: city,
        workflow: workflow,
        ifGoogleUseAutocomplete: useAutocomplete,
        onlyCities: true,
      },
    });
  },
  findPlaceById: function ({ dispatch, rootGetters }, { placeId, country, language }) {
    const workflow = country === 'ee' ? Workflow.ESTONIA : Workflow.POLAND;
    return dispatch('firebaseRequest', {
      request: requestFindAddress,
      data: {
        address: placeId,
        workflow: workflow,
        ifGoogleUsePlace: true,
      },
    }).then(({ response }) => {
      if (response.results?.length === 0) {
        return null;
      } else {
        return response.results[0];
      }
    });
  },

  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------------------------------------------
};
