<template>
  <div
    ref="calculator"
    v-editable="blok"
    class="min-h-screen flex justify-center items-center pb-12"
  >
    <div v-if="currentStep === steps.START">
      <component
        :is="childBlok.component"
        v-for="childBlok in blok.startContent.filter((item) =>
          ['FeaturesSection'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        @button-click="onStartClicked"
      />
    </div>
    <div
      v-else-if="currentStep === steps.CHOOSE_PROPERTY"
      class="flex flex-col items-center gap-6"
    >
      <div class="text-xl">{{ blok.propertyTypeTitle }}</div>
      <div class="grid grid-cols-2 gap-6 justify-stretch items-stretch font-medium">
        <div
          class="bg-white rounded-md flex flex-col items-center p-4 gap-4 hoverable:hover:border-rendin-500 border cursor-pointer"
          :class="
            propertyType === propertyTypes.APARTMENT
              ? 'shadow-lg border-rendin-500'
              : 'shadow-md border-transparent'
          "
          @click="onSelectPropertyType(propertyTypes.APARTMENT)"
        >
          <r-icon icon-name="building" />
          <div>{{ blok.typeApartmentTitle }}</div>
        </div>
        <div
          class="bg-white rounded-md flex flex-col items-center p-4 gap-4 hoverable:hover:border-rendin-500 border"
          :class="
            propertyType === propertyTypes.STUDIO_APARTMENT
              ? 'shadow-lg border-rendin-500'
              : 'shadow-md border-transparent'
          "
          @click="onSelectPropertyType(propertyTypes.STUDIO_APARTMENT)"
        >
          <r-icon icon-name="building" />
          <div>{{ blok.typeStudioApartmentTitle }}</div>
        </div>
        <div
          class="bg-white rounded-md flex flex-col items-center p-4 gap-4 hoverable:hover:border-rendin-500 border"
          :class="
            propertyType === propertyTypes.ROOMS
              ? 'shadow-lg border-rendin-500'
              : 'shadow-md border-transparent'
          "
          @click="onSelectPropertyType(propertyTypes.ROOMS)"
        >
          <r-icon icon-name="building" />
          <div>{{ blok.typeRoomTitle }}</div>
        </div>
        <div
          class="bg-white rounded-md flex flex-col items-center p-4 gap-4 hoverable:hover:border-rendin-500 border"
          :class="
            propertyType === propertyTypes.HOUSE
              ? 'shadow-lg border-rendin-500'
              : 'shadow-md border-transparent'
          "
          @click="onSelectPropertyType(propertyTypes.HOUSE)"
        >
          <r-icon icon-name="building" />
          <div>{{ blok.typeHouseTitle }}</div>
        </div>
      </div>
      <r-button v-if="propertyType" shape="sharp" width="wide" @click="goToNextStep">
        {{ blok.buttonPropertyTypeContinue }}
      </r-button>
    </div>
    <div
      v-else-if="currentStep === steps.ADD_DETAILS"
      class="flex flex-col items-center gap-6"
    >
      <div class="flex flex-col gap-2 items-center px-4">
        <div class="text-xl text-center">
          {{ blok.propertyDetailsTitle }}
        </div>
        <div class="text-lg text-center">
          {{ blok.propertyDetailsSubtitle }}
        </div>
      </div>
      <VeeForm ref="propertyDataForm" tag="div">
        <form autocomplete="on">
          <div class="grid grid-cols-1 gap-4 my-3 min-w-96 px-4">
            <!-- rooms info -->
            <Field
              ref="numberOfRoomsRef"
              v-slot="{ meta }"
              v-model="propertyNoOfRooms"
              name="numberOfRooms"
              rules="required|numeric|min_value:1"
              tag="div"
            >
              <r-input
                v-model="propertyNoOfRooms"
                :error-text="$t('form.validation.numberOfRooms')"
                field-type="tel"
                :label="$t('form.label.numberOfRooms')"
                name="numberOfRooms"
                :placeholder="$t('form.placeholder.numberOfRooms')"
                required
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
              />
            </Field>
            <!-- area info -->
            <Field
              ref="objectAreaRef"
              v-slot="{ meta }"
              v-model="propertyObjectArea"
              name="area"
              :rules="{ required: true, min_value: 0.1, isFloat: true }"
              tag="div"
            >
              <r-input
                v-model="propertyObjectArea"
                add-on="m2"
                :error-text="$t('form.validation.objectArea')"
                field-type="tel"
                inputmode="decimal"
                :label="$t('form.label.area')"
                name="area"
                :placeholder="$t('form.placeholder.area')"
                required
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
              />
            </Field>
            <Field
              ref="buildingYearRef"
              v-slot="{ meta }"
              v-model="propertyBuildingYear"
              name="buildingYear"
              rules="required|numeric|min_value:1900"
              tag="div"
            >
              <r-input
                v-model="propertyBuildingYear"
                :error-text="$t('form.validation.is_required')"
                field-type="tel"
                :label="blok.labelBuildingYear"
                name="buildingYear"
                placeholder="1941"
                required
                :validation-failed="meta.validated && !meta.valid"
                :validation-passed="meta.validated && meta.valid"
              />
            </Field>
            <div>
              <VeeForm ref="fullAddressForm" slim>
                <Field
                  ref="providerAddressSearchRef"
                  v-slot="{ meta, errors }"
                  v-model="propertyAddress"
                  name="addressSearchInput"
                  rules="required"
                  tag="div"
                >
                  <input-google-raw-address-search
                    :failed-rules="errors"
                    :label="$t('form.label.address')"
                    name="addressSearchInput"
                    :open-dropdown-on-focus="meta.validated && !meta.valid"
                    :placeholder="$t('form.placeholder.address_search')"
                    required
                    :select-no-options-text="$t('form.validation.select_no_options')"
                    :source="source"
                    :validation-failed="meta.validated && !meta.valid"
                    :validation-passed="meta.validated && meta.valid"
                    :model-value="propertyAddress"
                    @input="onSelectAddress"
                  />
                </Field>
              </VeeForm>
            </div>
          </div>
        </form>
      </VeeForm>
      <r-button shape="sharp" width="wide" @click="onClickSubmitForm">
        {{ blok.buttonPropertyDetailsContinue }}
      </r-button>
    </div>
    <div
      v-else-if="currentStep === steps.LOADING"
      class="flex flex-col items-center gap-6 p-4"
    >
      <video autoplay loop muted playsinline>
        <source
          :src="transformedCloudinaryVideo(blok.cloudinaryLoadingVideoLink, 'q_auto')"
          type="video/webm"
        />
      </video>

      <transition mode="out-in" name="slide-fade">
        <div :key="loaderIndex" class="text-center text-gray-500 r-fade">
          {{ loaderPhrases[loaderIndex] }}
        </div>
      </transition>
    </div>
    <div
      v-else-if="currentStep === steps.RESULTS"
      class="flex flex-col gap-4 mx-auto max-w-6xl"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in blok.resultsIntroContent.filter((item) =>
          ['FeaturesSection'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        @button-click="onCheckResultsClicked"
      />
      <div class="flex flex-col p-4 gap-4">
        <div
          ref="results-prediction"
          class="rounded-md shadow-sm p-4 flex flex-col gap-6"
        >
          <component
            :is="childBlok.component"
            v-for="childBlok in blok.resultsRentPredictionIntro.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="childBlok._uid"
            :blok="childBlok"
            class="px-4"
          />
          <div class="text-xl px-6 text-center">
            {{ blok.resultsRentAmountTitle }}
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-items-center"
          >
            <div
              class="p-6 border rounded-lg border-gray-300 flex flex-col gap-4 items-center justify-between"
            >
              <div class="text-center">{{ blok.labelResultsRentAmountMinimum }}</div>
              <div class="text-xl">
                {{
                  results.predictionResults.minRent.toLocaleString(locale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
                zł
              </div>
            </div>
            <div
              class="p-10 border rounded-lg border-gray-300 bg-gray-50 flex flex-col gap-4 items-center justify-between"
            >
              <div class="font-semibold text-rendin-500 text-xl text-center">
                {{ blok.labelResultsRentAmountMedian }}
              </div>
              <div class="text-xl font-semibold">
                {{
                  results.predictionResults.medianRent.toLocaleString(locale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
                zł
              </div>
            </div>
            <div
              class="p-6 border rounded-lg border-gray-300 flex flex-col gap-4 items-center justify-between"
            >
              <div class="text-center">{{ blok.labelResultsRentAmountMaximum }}</div>
              <div class="text-xl">
                {{
                  results.predictionResults.maxRent.toLocaleString(locale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
                zł
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-6">
            <div class="text-xl px-6 text-center">
              {{ blok.resultsAdministrativeRentTitle }}
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-items-center"
            >
              <div
                class="md:col-start-2 p-6 border rounded-lg border-gray-300 flex flex-col gap-4 items-center justify-between"
              >
                <div class="text-center">
                  {{ blok.labelResultsAdministrativeRentMedian }}
                </div>
                <div class="text-xl">
                  {{
                    results.predictionResults.medianAdministrativeRent.toLocaleString(
                      locale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                    )
                  }}
                  zł
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-md shadow-sm p-4 flex flex-col gap-6">
          <div class="text-xl md:text-2xl">
            {{ blok.resultsMonthlyPriceChartTitle }}
            <span class="font-semibold">{{ propertyRegion }}</span>
          </div>
          <price-calculator-monthly-price-chart
            :median-rent-amount-label="blok.labelResultChartMedianRentAmount"
            :statistics="results?.statisticsResults"
            :x-axis-title="blok.resultsMonthlyPriceChartXAxisTitle"
            :y-axis-title="blok.resultsMonthlyPriceChartYAxisTitle"
          />
        </div>
        <div class="rounded-md shadow-sm p-4 flex flex-col gap-6">
          <div class="flex flex-col gap-3 items-center p-4">
            <div class="text-xl md:text-2xl font-semibold w-full">
              {{ blok.resultsAccuracyIndicatorTitle }}
            </div>
            <div class="text-sm">
              {{ blok.resultsAccuracyIndicatorContent }}
            </div>
            <div class="relative w-full mx-auto">
              <div class="flex mb-6 items-center justify-between relative">
                <div
                  class="absolute -translate-x-1/2"
                  :style="{ left: accuracyWidth + '%' }"
                >
                  <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full"
                    :class="[accuracyBgColor, accuracyTextColor]"
                  >
                    {{ accuracyLabel }}
                  </span>
                </div>
                <div class="text-right"></div>
              </div>
              <div class="flex rounded-full h-2 bg-gray-200">
                <div
                  class="rounded-full bg-lime-600"
                  :style="{ width: accuracyWidth + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-md shadow-sm p-4 flex flex-col">
          <component
            :is="childBlok.component"
            v-for="childBlok in blok.resultsRentPredictionNextSteps.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="childBlok._uid"
            :blok="childBlok"
            class="px-4"
          />
          <r-property-item
            :id="'placeholder'"
            :address="propertyAddress?.formatted_address"
            :area-label="$t('offer.size')"
            currency="zł"
            :deposit-label="$t('offer.deposit')"
            :fee-label="$t('offer.rendin_fee')"
            :image="'https://res.cloudinary.com/dmsxfecue/image/upload/v1713427963/marketing_assets/pl_advertisement_placeholder.jpg'"
            :link-text="blok.nextStepsFinalizeYourListing"
            :link-url="'#'"
            :object-area="propertyObjectArea"
            :parking="blok.nextStepsValueToBeDecided"
            :parking-label="$t('offer.parking')"
            :price="Math.round(results?.predictionResults?.medianRent)"
            :rendin-fee="
              rendinFeeCalculation(
                results?.predictionResults?.medianAdministrativeRent,
                country ? country : 'PL',
              )
            "
            :rendin-fee-percent="rendinFee"
            :rent-label="$t('offer.price')"
            :rooms="propertyNoOfRooms"
            :rooms-label="$t('offer.rooms')"
            @link-click-event="onStartDigibroker()"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="currentStep === steps.NO_RESULTS"
      class="flex flex-col gap-4 mx-auto max-w-6xl"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in blok.noResultsContent.filter((item) =>
          ['FeaturesSection'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        @button-click="onRestartClicked"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import InputGoogleRawAddressSearch from '~/components/snippets/InputGoogleRawAddressSearch.vue';
import { transformedCloudinaryVideo } from '~/utils/transformCloudinary.js';
import { priceCalculatorFormEventNames } from '~/utils/trackerConstants';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';
import { LocalStorageKeys } from '~/utils/constants';
import { Field, Form as VeeForm } from 'vee-validate';

const PriceCalculatorMonthlyPriceChart = () =>
  Promise.all([
    import('~/components/section/calculator/PriceCalculatorMonthlyPriceChart.vue'),
  ]).then(
    ([PriceCalculatorMonthlyPriceChart]) => PriceCalculatorMonthlyPriceChart.default,
  );

const steps = {
  START: 'START',
  CHOOSE_PROPERTY: 'CHOOSE_PROPERTY',
  ADD_DETAILS: 'ADD_DETAILS',
  LOADING: 'LOADING',
  RESULTS: 'RESULTS',
  NO_RESULTS: 'NO_RESULTS',
};

const propertyTypes = {
  APARTMENT: 'apartments',
  STUDIO_APARTMENT: 'studioApartments',
  HOUSE: 'houses',
  ROOMS: 'rooms',
};

export default {
  name: 'PriceCalculator',
  components: {
    Field,
    VeeForm,
    PriceCalculatorMonthlyPriceChart,
    InputGoogleRawAddressSearch,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStepIndex: 0,
      steps: steps,
      source: 'OTHER',
      stepsList: Object.values(steps),
      propertyTypes: propertyTypes,
      propertyType: null,
      propertyAddress: null,
      propertyLatitude: null,
      propertyLongitude: null,
      propertyRegion: null,
      propertyBuildingYear: '',
      propertyObjectArea: '',
      propertyNoOfRooms: '',
      results: null,
      success: false,
      loaderTimer: null,
      loaderIndex: 0,
      rendinFee: 2.5,
      country: workflow.POLAND,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
      isENVIsDevelopment: 'isENVIsDevelopment',
    }),
    currentStep() {
      return this.stepsList[this.currentStepIndex];
    },
    accuracyWidth() {
      const accuracyMapping = {
        GENERIC: 30,
        BAD: 50,
        AVERAGE: 70,
        BEST: 100,
      };

      return accuracyMapping[this.results?.predictionResults?.accuracy] ?? '0';
    },
    accuracyBgColor() {
      const accuracyMapping = {
        GENERIC: 'bg-red-200',
        BAD: 'bg-orange-200',
        AVERAGE: 'bg-lime-200',
        BEST: 'bg-blue-200',
      };

      return accuracyMapping[this.results?.predictionResults?.accuracy] ?? '0';
    },
    accuracyTextColor() {
      const accuracyMapping = {
        GENERIC: 'text-red-500',
        BAD: 'text-orange-500',
        AVERAGE: 'text-lime-500',
        BEST: 'text-blue-500',
      };

      return accuracyMapping[this.results?.predictionResults?.accuracy] ?? '0';
    },
    accuracyLabel() {
      const accuracyMapping = {
        GENERIC: this.blok.resultsAccuracyGeneric,
        BAD: this.blok.resultsAccuracyBad,
        AVERAGE: this.blok.resultsAccuracyAverage,
        BEST: this.blok.resultsAccuracyBest,
      };

      return (
        accuracyMapping[this.results?.predictionResults?.accuracy] ??
        this.results?.predictionResults?.accuracy
      );
    },
    loaderPhrases() {
      return [
        this.blok.loaderTextFirst,
        this.blok.loaderTextSecond,
        this.blok.loaderTextThird,
      ];
    },
  },
  created() {
    // Prefill data in development
    if (this.isENVIsDevelopment) {
      this.propertyType = propertyTypes.APARTMENT;
      this.propertyLatitude = 52.234693;
      this.propertyLongitude = 20.9998049;
      this.propertyNoOfRooms = 2;
      this.propertyObjectArea = 45;
      this.propertyRegion = 'Mazowieckie';
      this.propertyBuildingYear = 2017;
    }
  },
  mounted() {
    // Call step change event when START-ing
    this.onStepChangedEvent();
  },
  methods: {
    rendinFeeCalculation,
    transformedCloudinaryVideo,
    ...mapActions({
      getMarketData: 'calculator/getMarketData',
      trackPriceCalculatorEvent: 'tracker/trackPriceCalculatorEvent',
    }),
    goToNextStep() {
      if (this.currentStepIndex >= this.stepsList.length - 1) return;

      const nextStep = this.stepsList[this.currentStepIndex + 1];

      this.goToStep(nextStep);
    },
    goToPreviousStep() {
      if (this.currentStepIndex <= 0) return;

      const previousStep = this.stepsList[this.currentStepIndex - 1];

      this.goToStep(previousStep);
    },
    goToStep(step) {
      this.currentStepIndex = this.stepsList.findIndex((x) => x === step);

      const formTopPosition = this.$refs.calculator.offsetTop;

      /* Since we have Sticky Nav, which could cover the top part of our element, will scroll higher by mobile view height amount */
      window.scrollTo({
        top: formTopPosition - 48,
        behavior: 'smooth',
      });

      this.onStepChangedEvent();
    },
    onSelectPropertyType(type) {
      this.propertyType = type;
    },
    onSelectAddress(address) {
      this.propertyAddress = address;
      this.propertyLatitude = address.geometry.location.lat();
      this.propertyLongitude = address.geometry.location.lng();

      const regionAddressCompoonentType = 'administrative_area_level_1';
      this.propertyRegion = address.address_components.find((x) =>
        x.types.includes(regionAddressCompoonentType),
      ).long_name;
    },
    async onClickSubmitForm() {
      const form = await this.$refs.propertyDataForm.validate();

      if (form.valid !== true) return;

      this.goToStep(steps.LOADING);

      this.startLoading();

      // Region cleanup
      const region = this.propertyRegion.replace('Województwo', '').trim();

      const data = {
        latitude: this.propertyLatitude,
        longitude: this.propertyLongitude,
        region: region,
        buildingYear: this.propertyBuildingYear,
        objectArea: this.propertyObjectArea,
        numberOfRooms: this.propertyNoOfRooms,
        type: this.propertyType,
      };

      const minimumWaitTime = this.wait(6000);

      const response = await this.getMarketData(data);
      await minimumWaitTime;

      if (response.data.success) {
        this.results = response?.data?.data;
        this.goToNextStep();
      } else {
        this.success = false;
        this.goToStep(steps.NO_RESULTS);
      }

      this.finishLoading();
    },
    startLoading() {
      this.loaderTimer = setInterval(() => {
        this.loaderIndex++;
        if (this.loaderIndex >= this.loaderPhrases.length) {
          this.loaderIndex = 0;
        }
      }, 2000);
    },
    finishLoading() {
      window.clearInterval(this.loaderTimer);
    },
    onStartClicked() {
      this.goToNextStep();
    },
    onRestartClicked() {
      this.goToStep(steps.CHOOSE_PROPERTY);
    },
    // Simply forces the async operation to wait for a set duration
    wait(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    },
    onStepChangedEvent() {
      const mapStepToEvent = {
        [steps.START]: priceCalculatorFormEventNames.START,
        [steps.CHOOSE_PROPERTY]: priceCalculatorFormEventNames.PROPERTY_TYPE,
        [steps.ADD_DETAILS]: priceCalculatorFormEventNames.PROPERTY_DETAILS,
        [steps.LOADING]: priceCalculatorFormEventNames.LOADING,
        [steps.RESULTS]: priceCalculatorFormEventNames.RESULTS,
        [steps.NO_RESULTS]: priceCalculatorFormEventNames.NO_RESULTS,
      };

      const eventName = mapStepToEvent[this.currentStep];

      const props = {
        property: {
          latitude: this.propertyLatitude,
          longitude: this.propertyLongitude,
          region: this.propertyRegion,
          buildingYear: this.propertyBuildingYear,
          objectArea: this.propertyObjectArea,
          numberOfRooms: this.propertyNoOfRooms,
          type: this.propertyType,
          accuracyLevel: this.results?.predictionResults?.accuracy,
        },
      };

      this.trackPriceCalculatorEvent({ eventName: eventName, props: props });
    },
    onCheckResultsClicked() {
      this.$refs['results-prediction'].scrollIntoView({ behavior: 'smooth' });
    },
    onStartDigibroker() {
      this.rewriteDigibrokerCache();
      this.$router.push({ path: this.$localizedPath('/digibroker') });
    },
    /* Introduces hard-coupling with Digibroker - if this works, we need to decouple them again and give those inputs to digibroker via Query parameter */
    rewriteDigibrokerCache() {
      const addressCity = this.propertyAddress.address_components.find((x) =>
        x.types.includes('locality'),
      )?.long_name;
      const addressStreet = this.propertyAddress.address_components.find((x) =>
        x.types.includes('route'),
      )?.long_name;
      const addressHouseNumber = this.propertyAddress.address_components.find((x) =>
        x.types.includes('street_number'),
      )?.long_name;
      const addressFirstLine =
        addressStreet && addressHouseNumber
          ? `${addressStreet} ${addressHouseNumber}`
          : null;
      const addressIndex = this.propertyAddress.address_components.find((x) =>
        x.types.includes('postal_code'),
      )?.long_name;
      const addressCountry = 'Polska'; // Hard-coded currently due to only rollout to Poland

      const cache = {
        currentStepIndex: 2,
        advertisementFirebaseKey: null,
        agreementFirebaseKey: null,
        validatedData: {
          agreementType: null,
          propertyType:
            this.propertyType === propertyTypes.HOUSE ? 'house' : 'apartment',
          address: {
            addressFirstLine: addressFirstLine,
            addressCity: addressCity,
            addressStreet: addressStreet,
            addressCountry: addressCountry,
            addressIndex: addressIndex,
            addressHouseNumber: addressHouseNumber,
            addressHouseNumberHidden: false,
            addressApartmentNumber: null,
            hasApartmentNumber: true,
            internalUserEditedAddressManually: false,
          },
          floor: null,
          floorsTotal: null,
          objectArea: this.propertyObjectArea,
          numberOfRooms: this.propertyNoOfRooms,
          hasStorage: false,
          hasParking: false,
          petsAllowed: false,
          description: null,
          rentAmount: Math.round(this.results.predictionResults.medianRent),
          additionalFees: [],
          images: [],
        },
        visitedSteps: [],
        additionalFee: {
          type: 'FIXED_UTILITIES',
          paymentInterval: 'MONTHLY',
          source: 'WEB_DIGIBROKER',
          amount: Math.round(this.results.predictionResults.medianAdministrativeRent),
          lastPostAmount: null,
          id: null,
        },
        alreadyHasTenant: false,
      };

      localStorage.setItem(LocalStorageKeys.DIGIBROKER_CACHE, JSON.stringify(cache));
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
  transform: translateY(-10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
