import { default as indexOg8s9uI1AGMeta } from "/workspace/pages/examples/index.vue?macro=true";
import { default as r_45interface_45componentsWvBLFR7TqOMeta } from "/workspace/pages/r-interface-components.vue?macro=true";
import { default as _91_46_46_46page_93TnZK5Y8EGAMeta } from "/workspace/pages/[...page].vue?macro=true";
import { default as _91_46_46_46page_93G2N8thPrnQMeta } from "/workspace/pages/non-storybloks/[...page].vue?macro=true";
import { default as component_45stubmKyKxp5WTxMeta } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmKyKxp5WTx } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "examples",
    path: "/examples",
    component: () => import("/workspace/pages/examples/index.vue")
  },
  {
    name: "r-interface-components",
    path: "/r-interface-components",
    component: () => import("/workspace/pages/r-interface-components.vue")
  },
  {
    name: "object_view",
    path: "/:lang?/dashboard/object/:objectid",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "agreement_view",
    path: "/:lang?/dashboard/agreement/:agreementid",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "profile_unique_id",
    path: "/:lang?/tenants/profile/:profileid",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "invite_unique_id",
    path: "/:lang?/invite/form/:code",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "invite_unique_id_agreement",
    path: "/:lang?/invite/agreement/:code",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "dashboard",
    path: "/:lang?/nstbc/:page",
    component: () => import("/workspace/pages/non-storybloks/[...page].vue")
  },
  {
    name: "page",
    path: "/:page(.*)*",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: "non-storybloks-page",
    path: "/non-storybloks/:page(.*)*",
    component: () => import("/workspace/pages/non-storybloks/[...page].vue")
  },
  {
    name: "custom",
    path: "/:pathMatch(.*)*",
    component: () => import("/workspace/pages/[...page].vue")
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/sitemap-ee-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  },
  {
    name: component_45stubmKyKxp5WTxMeta?.name,
    path: "/sitemap-pl-sitemap.xml",
    component: component_45stubmKyKxp5WTx
  }
]