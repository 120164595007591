<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="features-cards-section"
    :class="sectionClasses"
  >
    <div class="max-w-6xl mx-auto p-4 py-12">
      <h2
        v-if="blok.title"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="my-4 font-semibold text-3xl md:text-4xl opacity-0"
        :class="sectionTextColor"
        v-html="blok.title"
      ></h2>

      <RichText
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        :blok="blok.description"
        class="max-w-3xl opacity-0"
        :class="sectionTextColor"
      />
      <div
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="grid justify-evenly gap-6 my-6 md:my-12 opacity-0"
        :class="cardsGrid"
      >
        <div
          v-for="(card, index) in blok.cards"
          :key="index"
          v-editable="card"
          class="features-cards-section__card max-w-xl shadow-sm rounded-xl p-4 my-1 flex flex-col"
          :class="[
            card.isHighlighted ? 'bg-blue-50' : 'bg-white',
            isLightTheme ? 'shadow-xl' : 'box-shadow-violet',
          ]"
        >
          <div
            class="h-12 w-12 rounded-full bg-blue-50 flex justify-center items-center"
          >
            <r-icon class="text-gray-900" :icon-name="card.icon" size="lg" />
          </div>
          <h4 class="font-medium mt-3 font-semibold text-gray-900 text-xl md:text-xl">
            {{ card.title }}
          </h4>
          <RichText
            v-if="card.content"
            :blok="card.content"
            class="flex-grow text-gray-600"
          />
          <div v-if="card.button?.length" class="w-full">
            <component
              :is="theBlok.component"
              v-for="theBlok in card.button.filter((item) =>
                ['ButtonBlok'].includes(item.component),
              )"
              :key="theBlok._uid"
              :blok="theBlok"
            />
          </div>
          <div
            v-else-if="card.buttonPlaceholder"
            class="text-center font-semibold text-gray-600 p-4"
          >
            {{ card.buttonPlaceholder }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const gridCols = {
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-2 lg:grid-cols-4',
};

const sectionThemes = {
  gradientViolet: 'gradient-violet',
  lightOrange: 'light-orange',
  white: 'white',
  lightViolet: 'light-violet',
};
export default {
  name: 'FeaturesCardsSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardsGrid() {
      const classes = [];
      if (this.blok.cardsColumns) {
        if (this.blok.cardsColumns === '2') classes.push(gridCols['2']);
        else if (this.blok.cardsColumns === '3') classes.push(gridCols['3']);
        else if (this.blok.cardsColumns === '4') classes.push(gridCols['4']);
      } else if (this.blok.cards?.length) {
        if (this.blok.cards?.length === 2) classes.push(gridCols['2']);
        else if (this.blok.cards?.length === 3) classes.push(gridCols['3']);
        else if (this.blok.cards?.length >= 4) classes.push(gridCols['4']);
      }

      return classes;
    },
    sectionClasses() {
      const classes = [];
      if (this.blok.theme === sectionThemes.lightOrange) {
        classes.push('orange-dark-25');
      } else if (this.blok.theme === sectionThemes.white) {
        classes.push('bg-white');
      } else if (this.blok.theme === sectionThemes.lightViolet) {
        classes.push('bg-violet-25');
      } else {
        classes.push('violet-to-blue-gradient');
      }

      return classes;
    },
    sectionTextColor() {
      if (this.isLightTheme) {
        return 'text-black';
      } else {
        return 'text-white';
      }
    },
    isLightTheme() {
      const lightThemes = [
        sectionThemes.lightOrange,
        sectionThemes.white,
        sectionThemes.lightViolet,
      ];
      return lightThemes.includes(this.blok.theme);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-shadow-violet {
  box-shadow: 15px 23px 4px 0px #430a8c;
}

.orange-dark-25 {
  background: #fff9f5;
}
</style>
