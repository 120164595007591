<template>
  <section>
    <AgreementTypeSelectAtom
      v-model="selectedAgreementType"
      :allowed-agreement-types="profile?.allowedAgreementTypesToPost"
    />
    <div class="m-auto sm:max-w-sm my-6 px-3">
      <r-button
        :disabled="!selectedAgreementType"
        :is-loading="putAgreementInProgress || getAgreementInProgress"
        :label="buttonLabel"
        width="full"
        @click="
          !selectedAgreementType || putAgreementInProgress ? '' : emitAgreementType()
        "
      />
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import AgreementTypeSelectAtom from '~/components/section/agreement/AgreementTypeSelectAtom.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgreementTypeEdit',
  components: { AgreementTypeSelectAtom },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: null,
    },
  },

  emits: ['selectedAgreementTypeUpdated'],

  data: () => ({
    selectedAgreementType: null,
  }),

  computed: {
    ...mapGetters({
      putAgreementInProgress: 'agreements/putAgreementInProgress',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      profile: 'users/profile',
    }),
  },

  watch: {
    agreementFromFirebase() {
      this.checkTypeFromServer();
    },
  },

  mounted() {
    this.checkTypeFromServer();
  },
  methods: {
    ...mapActions({
      actionPutAgreement: 'agreements/putAgreement',
      actionGetAgreement: 'agreements/getAgreement',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    checkTypeFromServer() {
      // check selectedAgreementType
      if (
        this.agreementFromFirebase &&
        this.agreementFromFirebase.agreementTypeFromAgreement
      ) {
        this.selectedAgreementType =
          this.agreementFromFirebase.agreementTypeFromAgreement;
      } else if (
        this.agreementFromFirebase &&
        (this.agreementFromFirebase.agreementTypeFromResolver ||
          this.agreementFromFirebase.agreementTypeFromResolver !== 'UNKNOWN')
      ) {
        this.selectedAgreementType =
          this.agreementFromFirebase.agreementTypeFromResolver;
      }
    },

    emitAgreementType() {
      if (this.agreementId) {
        const typeData = { agreementType: this.selectedAgreementType };
        this.actionPutAgreement({ id: this.agreementId, agreement: { ...typeData } })
          .then(() => this.actionGetAgreement({ id: this.agreementId }))
          .then(() => {
            this.$emit('selectedAgreementTypeUpdated', this.selectedAgreementType);
            this.emitAgreementTrackerEvent(
              agreementTrackingEventNames.CHANGE_AGREEMENT_TYPE,
              { value: this.selectedAgreementType },
            );
          });
      } else {
        this.$emit('selectedAgreementTypeUpdated', this.selectedAgreementType);
        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.CHOOSE_AGREEMENT_TYPE,
          { value: this.selectedAgreementType },
        );
      }
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName:
          this.agreementFromFirebase &&
          (this.agreementFromFirebase.status === 'READY_TO_SIGN' ||
            this.agreementFromFirebase.status === 'PRE_SIGNING' ||
            this.agreementFromFirebase.status === 'SIGNED')
            ? trackingProcessNames.AGREEMENT
            : trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
});
</script>
