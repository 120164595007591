<template>
  <div class="min-h-1/2-screen">
    <div
      class="mx-auto text-center px-4 my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
    </div>
    <Form v-slot="{ meta: formMeta }" ref="validator">
      <Field
        v-slot="{ meta, setTouched }"
        v-model="floorModel"
        validate-on-mount
        name="floor"
        :rules="{ required: true, selectValueIsNumber: true, numeric: true }"
      >
        <div
          class="max-w-sm mx-auto px-4"
          :class="{ 'r-fade-bottom--select': initialLoad }"
        >
          <r-input
            v-model="floorModel"
            field-type="tel"
            :focus-on-load="true"
            :label="$t('form.label.floor')"
            label-class="text-xs sm:text-base"
            name="property-floor"
            :placeholder="$t('form.placeholder.floor')"
            required
            :validation-failed="meta.touched && !meta.valid"
            :validation-passed="meta.valid"
            @input="setTouched(true)"
            @blur="setTouched(true)"
          />
          <!--          <r-select
                      v-model="floorModel"
                      :label="$t('form.label.floor')"
                      name="property-floor"
                      option-label="value"
                      :options="floorOptions"
                      :placeholder="$t('form.placeholder.floor')"
                      required
                      :validation-failed="dirty && failed"
                      :validation-passed="passed"
                    />-->
        </div>
      </Field>

      <Field
        v-slot="{ meta, setTouched }"
        v-model="floorsTotalModel"
        validate-on-mount
        name="property-floors-total"
        rules="required|numeric|selectValueIsNumber|floorsTotalValidation:@floor"
      >
        <div
          class="max-w-sm mx-auto p-4"
          :class="{ 'r-fade-bottom--select': initialLoad }"
        >
          <r-input
            v-model="floorsTotalModel"
            field-type="tel"
            :label="$t('form.label.floorsTotal')"
            label-class="text-xs sm:text-base"
            name="property-floors-total"
            :placeholder="$t('form.placeholder.floorsTotal')"
            required
            :validation-failed="meta.touched && !meta.valid"
            :validation-passed="meta.valid"
            @input="setTouched(true)"
            @blur="setTouched(true)"
          />
          <!--          <r-select
                      v-model="floorsTotalModel"
                      :label="$t('form.label.floorsTotal')"
                      name="property-floors-total"
                      option-label="value"
                      :options="floorsTotalOptions"
                      :placeholder="$t('form.placeholder.floorsTotal')"
                      required
                      :validation-failed="dirty && failed"
                      :validation-passed="passed"
                    />-->

          <div class="h-14 mt-2 mb-4">
            <div
              v-if="formMeta.valid"
              class="flex justify-center items-center"
              :class="{ 'r-fade': initialLoad }"
            >
              <r-button
                :id="submitButtonId"
                color="orange"
                :is-loading="requestInProgress"
                @click="
                  formMeta.valid && !requestInProgress ? $emit('goToNextStep') : null
                "
              >
                <r-icon class="mr-1 text-lg" icon-name="check-circle" />
                <span class="font-medium">{{ nextButtonLabel }}</span>
              </r-button>
              <div class="flex items-center text-sm mx-2">
                <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
                <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
              </div>
            </div>
          </div>
        </div>
      </Field>
    </Form>
  </div>
</template>
<script>
import { Field, Form } from 'vee-validate';

export default {
  name: 'StepSelectFloors',
  components: { Field, Form },
  props: {
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMobileScreen: {
      type: Boolean,
      required: true,
    },
    floor: {
      type: [String, Number],
      required: false,
      default: null,
    },
    floorsTotal: {
      type: [String, Number],
      required: false,
      default: null,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['goToNextStep', 'update:floor', 'update:floorsTotal'],
  async setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
    };
  },
  computed: {
    floorModel: {
      get() {
        return this.floor;
      },
      set(newVal) {
        const normalized = Number(newVal);
        this.$emit('update:floor', normalized || (normalized === 0 ? 0 : null));
      },
    },
    floorsTotalModel: {
      get() {
        return this.floorsTotal;
      },
      set(newVal) {
        const normalized = Number(newVal);
        this.$emit('update:floorsTotal', normalized || null);
      },
    },
    floorOptions() {
      /* Return a list of floors from 0 to MAX_FLOOR-1 */
      const MAX_FLOOR = 25;
      return [...Array(MAX_FLOOR).keys()].map((v) => ({
        value: v,
      }));
    },
    floorsTotalOptions() {
      /* Returns a list of floors from selected floor to MAX_FLOOR */
      return this.floorOptions.filter((x) => x.value >= (this.floor || 1));
    },
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    async submit() {
      const isValid = await this.validate();
      if (isValid && !this.requestInProgress) {
        this.$emit('goToNextStep');
      }
    },
    async validate() {
      const validationResult = await this.$refs.validator.validate();
      return validationResult.valid;
    },
  },
};
</script>
