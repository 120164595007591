<template>
  <div class="px-4">
    <div
      v-if="introText"
      class="mx-auto text-center my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <RichTextBlok
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
    </div>

    <div class="r-fade--delay--1" :class="{ 'r-fade-bottom': initialLoad }">
      <agreement-type-select-atom v-model="selectedAgreementTypeModel" />
      <div class="h-14 mt-2 mb-4">
        <div
          v-if="selectedAgreementTypeModel"
          class="flex justify-center items-center r-fade--delay--2"
        >
          <r-button
            color="orange"
            :is-loading="requestInProgress"
            @click="!requestInProgress ? $emit('goToNextStep') : null"
          >
            <r-icon class="mr-1 text-lg" icon-name="check-circle" />
            <span class="font-medium">{{ nextButtonLabel }}</span>
          </r-button>
          <div class="flex items-center text-sm mx-2">
            <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
            <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { agreementTypes } from '~/utils/constants';

export default {
  name: 'StepSelectAgreementType',
  props: {
    country: {
      type: String,
      required: true,
    },
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedAgreementType: {
      type: String,
      required: false,
      default: null,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['goToNextStep', 'update:selectedAgreementType', 'autoSelect'],
  async setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
    };
  },
  computed: {
    selectedAgreementTypeModel: {
      get() {
        return this.selectedAgreementType;
      },
      set(newVal) {
        this.$emit('update:selectedAgreementType', newVal);
      },
    },
  },
  created() {
    if (this.country === 'ee') {
      this.selectedAgreementTypeModel = agreementTypes.EE_BASIC;
      this.$emit('autoSelect');
    }
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    async submit() {
      const isValid = await this.validate();
      if (isValid && !this.requestInProgress) {
        this.$emit('goToNextStep');
      }
    },
    async validate() {
      return !!this.selectedAgreementType;
    },
  },
};
</script>
