<template>
  <section
    class="listing-subscription-section rounded-lg border border-gray-200 overflow-hidden shadow-sm p-3 flex flex-col flex-grow"
    :class="sectionBackgroundColor()"
  >
    <div
      class="flex-grow flex flex-col sm:flex-row sm:gap-4 md:gap-0"
      :class="{
        'md:flex md:flex-row': !forcedMobileView,
        'gap-6 items-center': isSectionSizeSmall(),
      }"
    >
      <div
        v-if="blok.image?.filename"
        class="w-100 h-48 flex-shrink-0 rounded-lg overflow-hidden flex"
        :class="{
          'md:w-64': !forcedMobileView,
          'sm:h-64 sm:w-[15rem] md:h-64 md:w-[20rem] lg:h-72 lg:w-[24rem]':
            findListingView && !forcedMobileView && !isSectionSizeSmall(),
          'md:h-32 md:w-[10rem] lg:h-40 lg:w-[12rem]': isSectionSizeSmall(),
        }"
      >
        <img
          alt="listingSubscriptionImage"
          class="mx-auto rounded-lg object-cover w-full h-full"
          :src="blok.image.filename"
        />
      </div>
      <div class="w-full flex flex-col flex-grow">
        <div
          class="w-full flex flex-grow"
          :class="{
            'md:px-4 md:mt-0': !forcedMobileView,
            'justify-between': findListingView,
            'flex-col sm:flex-row md:gap-6': isSectionSizeSmall(),
            'mt-5 flex-col': !isSectionSizeSmall(),
          }"
        >
          <div class="flex flex-col justify-center">
            <component
              :is="theBlok.component"
              v-for="theBlok in blok.content"
              :key="theBlok._uid"
              :blok="theBlok"
            />
            <AdvertisementDetailsChips
              :advertisement-details="searchDetails"
              class="pb-4"
            />
          </div>
          <div class="flex items-center justify-center md:justify-end">
            <r-button
              class="w-max"
              :label="blok.buttonTitle"
              @click="emitButtonClick()"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors, ComponentSize } from '~/utils/constants';
import AdvertisementDetailsChips from '~/components/snippets/AdvertisementDetailsChips.vue';

export default {
  name: 'ListingSubscriptionSection',
  components: { AdvertisementDetailsChips },

  props: {
    blok: {
      type: Object,
      required: true,
    },
    forcedMobileView: {
      type: Boolean,
      default: false,
    },
    findListingView: {
      type: Boolean,
      default: true,
    },
    searchDetails: {
      type: Object,
      default: null,
    },
  },
  emits: ['buttonClick'],

  methods: {
    sectionBackgroundColor() {
      return backgroundColors[this.blok.backgroundColor]
        ? backgroundColors[this.blok.backgroundColor]
        : 'bg-white';
    },

    emitButtonClick() {
      this.$emit('buttonClick');
    },

    isSectionSizeSmall() {
      return this.blok?.sectionSize === ComponentSize.SMALL;
    },
  },
};
</script>
