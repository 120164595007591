<template>
  <r-overlay
    body-classes="flex-h-screen sm:flex-h-auto"
    :bottom-bar="showModal"
    header-class="bg-white z-10"
    size="small"
    :visible="showModal"
    @close="closeOverlay()"
  >
    <template #header>
      <div
        v-if="!showRemoveConfirmation"
        class="pl-10 py-4 w-full text-center text-xl font-medium p-2"
      >
        {{ $t('agreement.button.add_tenant') }}
      </div>
    </template>

    <!--    Confirmation for candidacy removal -->
    <template v-if="showRemoveConfirmation">
      <div class="p-4 mt-16 h-full flex flex-col">
        <h3 class="text-center text-xl flex-grow">
          {{ $t('candidate.are_you_sure_you_want_to_remove_this_candidate') }}
        </h3>
        <div class="flex flex-col gap-4 mt-20">
          <r-button
            :is-loading="deleteCandidacyInProgress"
            :label="$t('candidate.deleteCandidate')"
            label-class="font-normal whitespace-nowrap"
            size="small"
            width="full"
            @click="deleteCandidacy(candidateProfileIdToRemove)"
          />
          <r-button
            border-color="light-gray"
            color="black"
            inverted
            :label="$t('buttons.cancel')"
            label-class="font-normal whitespace-nowrap"
            size="small"
            width="full"
            @click="closeRemoveConfirmation()"
          />
        </div>
      </div>
    </template>

    <!--   Candidacy view-->
    <div v-else class="mt-16 flex flex-col h-full">
      <!-- Advertisement invite link-->
      <div class="text-center bg-white shadow-lg rounded-lg p-4">
        <h3 class="font-medium text-lg mb-4">
          {{ $t('agreement.label.invite_tenant_to_agreement') }}
        </h3>
        <div class="mb-6">
          {{ $t('agreement.label.invite_tenant_to_agreement_instructions') }}
        </div>
        <r-button
          border-color="light-gray"
          class="no-underline hover:no-underline focus:no-underline border"
          color="black"
          :label="$t('buttons.how_to_invite_tenant')"
          no-background
          size="small"
          width="full"
          @click="shareLinkClicked()"
        />
      </div>
      <!-- Candidates list -->
      <div v-if="getCandidatesInProgress && candidacies.length === 0">
        <r-loader class="w-12 h-10 mt-10" size="20" />
      </div>
      <div v-else-if="getCandidatesFailed" class="text-error text-center">
        {{ $t('errors.something_wrong') }}
      </div>

      <div v-else-if="getCandidatesResponse && getCandidatesResponse.length > 0">
        <div
          v-for="(candidate, index) in filterAddedTenantFromCandidacyList"
          :key="index"
          class="bg-white shadow-lg rounded-lg my-2 p-4"
        >
          <div class="mb-2">
            <!--         candidacyStatus: "APPROVED" | "IN_ANOTHER_ACTIVE_AGREEMENT" | "PENDING" | "DECLINED" | "RETRACTED",-->
            <div
              v-if="candidate.candidacyStatus === 'APPROVED'"
              class="flex items-center leading-4 w-full"
            >
              <r-icon class="text-green-600 w-6 mr-3" icon-name="user-shield" />
              <div class="w-full">
                <div class="flex justify-between items-center w-full">
                  <div class="font-bold uppercase text-sm">
                    {{ candidate.firstName + ' ' + candidate.lastName }}
                  </div>
                  <div class="text-gray-600 text-sm">
                    {{ formatRelativeTime(candidate.created) }}
                  </div>
                </div>
                <div class="text-gray-600 text-xs">
                  {{ $t('candidate.bgStatus.approved') }}
                </div>
              </div>
            </div>
            <div
              v-if="candidate.candidacyStatus === 'IN_ANOTHER_ACTIVE_AGREEMENT'"
              class="flex items-center leading-4 w-full"
            >
              <r-icon class="text-gray-500 w-6 mr-3" icon-name="user-xmark" />
              <div class="w-full">
                <div class="flex justify-between items-center w-full">
                  <div class="font-bold uppercase text-sm">
                    {{ candidate.firstName + ' ' + candidate.lastName }}
                  </div>
                  <div class="text-gray-600 text-sm">
                    {{ formatRelativeTime(candidate.created) }}
                  </div>
                </div>
                <div class="text-gray-600 text-xs">
                  {{ $t('candidate.bgStatus.inAnotherActiveAgreement') }}
                </div>
              </div>
            </div>
            <div v-else-if="candidate.candidacyStatus === 'PENDING'">
              <div class="flex items-center leading-4 w-full">
                <r-icon
                  class="text-yellow-500 w-6 mr-3"
                  icon-name="clipboard-list-check"
                />
                <div class="w-full">
                  <div class="flex justify-between items-center w-full">
                    <div class="font-bold uppercase text-sm">
                      {{ candidate.firstName + ' ' + candidate.lastName }}
                    </div>
                    <div class="text-gray-600 text-sm">
                      {{ formatRelativeTime(candidate.created) }}
                    </div>
                  </div>
                  <div class="text-yellow-500 text-xs">
                    {{ $t('candidate.bgStatus.pending') }}
                  </div>
                </div>
              </div>
              <div class="text-gray-600 text-sm my-4">
                {{ $t('candidate.pendingCandidate') }}
                <r-button
                  :label="$t('read_more')"
                  label-class="font-normal whitespace-nowrap"
                  no-background
                  no-padding
                  @click="emitReadMoreBackgroundCheck()"
                />
              </div>
            </div>
            <div
              v-else-if="candidate.candidacyStatus === 'RETRACTED'"
              class="flex items-center leading-4 w-full"
            >
              <r-icon class="text-gray-500 w-6 mr-3" icon-name="user-xmark" />
              <div class="w-full">
                <div class="flex justify-between items-center w-full">
                  <div class="font-bold uppercase text-sm">
                    {{ candidate.firstName + ' ' + candidate.lastName }}
                  </div>
                  <div class="text-gray-600 text-sm">
                    {{ formatRelativeTime(candidate.created) }}
                  </div>
                </div>
                <div class="text-gray-600 text-xs">
                  {{ $t('candidate.bgStatus.notAvailable') }}
                </div>
              </div>
            </div>
            <div v-else-if="candidate.candidacyStatus === 'DECLINED'">
              <div class="flex items-center leading-4 w-full">
                <r-icon class="text-error w-6 mr-3" icon-name="user-large-slash" />
                <div class="w-full">
                  <div class="flex justify-between items-center w-full">
                    <div class="font-bold uppercase text-sm">
                      {{ candidate.firstName + ' ' + candidate.lastName }}
                    </div>
                    <div class="text-gray-600 text-sm">
                      {{ formatRelativeTime(candidate.created) }}
                    </div>
                  </div>
                  <div class="text-error text-xs">
                    {{ $t('candidate.bgStatus.declined') }}
                  </div>
                </div>
              </div>

              <div class="text-gray-600 text-sm my-4">
                {{ $t('candidate.declinedCandidate') }}
                <r-button
                  :label="$t('read_more')"
                  label-class="font-normal whitespace-nowrap"
                  no-background
                  no-padding
                  @click="emitReadMoreBackgroundCheck()"
                />
              </div>
            </div>
            <div v-if="candidate.candidacyStatus === 'APPROVED'" class="mt-2">
              <r-title-with-icon
                v-if="candidate.phone"
                class="my-2"
                icon="mobile"
                :title="candidate.phone"
              />
              <r-title-with-icon
                v-if="candidate.email"
                class="my-2"
                icon="envelope"
                :title="candidate.email"
              />
              <r-button
                border-color="light-gray"
                :label="$t('agreement.label.accept_to_agreement')"
                size="small"
                width="full"
                @click="emitSelectedProfileId(candidate.profileId)"
              />
            </div>
          </div>
          <r-button
            border-color="light-gray"
            color="black"
            inverted
            :label="$t('agreement.button.remove')"
            label-class="font-normal whitespace-nowrap"
            size="small"
            width="full"
            @click="openRemoveConfirmation(candidate.profileId)"
          />
        </div>
      </div>
    </div>

    <template #bottom-bar>
      <div v-if="!showRemoveConfirmation" class="text-center font-medium p-2">
        <r-button
          display="block"
          :label="$t('buttons.close')"
          no-background
          uppercase
          @click="closeOverlay()"
        />
      </div>
    </template>
  </r-overlay>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import useDateFromIsoString from '~/composables/useDateFromIsoString.js';

/**
 * Component for showing candidacies, emits profile id
 */
export default {
  name: 'CandidacyListModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    inviteLink: {
      type: String,
      default: '',
    },
    agreementId: {
      type: String,
      default: '',
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    openingSource: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { formatRelativeTime } = useDateFromIsoString();
    return {
      formatRelativeTime,
    };
  },

  data: () => ({
    trackingProcessNames: trackingProcessNames,
    agreementTrackingEventNames: agreementTrackingEventNames,
    candidacies: [],
    showRemoveConfirmation: false,
    deleteCandidacyInProgress: false,
    candidateProfileIdToRemove: null,
  }),

  computed: {
    ...mapGetters({
      getCandidatesResponse: 'candidacies/getCandidatesResponse',
      getCandidatesInProgress: 'candidacies/getCandidatesInProgress',
      getCandidatesFailed: 'candidacies/getCandidatesFailed',
    }),

    filterAddedTenantFromCandidacyList() {
      return this.candidacies.filter(
        (candidate) =>
          candidate.profileId !== this.agreementFromFirebase?.tenantProfileId,
      );
    },
  },

  watch: {
    showModal(val) {
      if (val) {
        this.getCandidates();
      }
    },
  },

  methods: {
    ...mapActions({
      actionGetCandidates: 'candidacies/getCandidates',
      actionDeleteCandidacy: 'candidacies/deleteCandidacy',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      trackCandidatesRemoveCandidateEvent:
        'tracker/trackCandidatesRemoveCandidateEvent',
    }),

    getCandidates() {
      if (this.agreementId) {
        this.actionGetCandidates({ agreementId: this.agreementId }).then((result) => {
          this.candidacies = result.response;
        });
      }
    },

    emitSelectedProfileId(id) {
      this.$emit('selectedProfileId', id);
    },
    deleteCandidacy(emitCandidateProfileId) {
      this.deleteCandidacyInProgress = true;
      // remove from component local array "candidacies"
      this.candidacies = this.candidacies.filter(
        (candidacy) => candidacy.profileId !== emitCandidateProfileId,
      );

      this.actionDeleteCandidacy({
        profileId: emitCandidateProfileId,
        agreementId: this.agreementId,
      })
        .then(() => {
          this.closeRemoveConfirmation();
          this.deleteCandidacyInProgress = false;
        })
        .then(() =>
          this.trackCandidatesRemoveCandidateEvent({
            candidateId: emitCandidateProfileId,
            processName: trackingProcessNames.AGREEMENT_DRAFTING,
            agreementId: this.agreementId,
          }),
        )
        .finally(() => this.getCandidates())
        .catch((err) => this.actionReportErrorToSentry(err));
    },

    openRemoveConfirmation(candidateProfileId) {
      this.candidateProfileIdToRemove = candidateProfileId;
      this.showRemoveConfirmation = true;
    },

    closeRemoveConfirmation() {
      this.candidateProfileIdToRemove = null;
      this.showRemoveConfirmation = false;
    },

    closeOverlay() {
      this.closeRemoveConfirmation();
      this.$emit('closeOverlay');
    },

    shareLinkClicked() {
      this.$emit('shareLink');
    },

    emitReadMoreBackgroundCheck() {
      this.$emit('readMoreAboutBackgroundCheck');
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
