<template>
  <section :id="blok.id" v-editable="blok" class="faq-section">
    <div class="max-w-6xl mx-auto px-4 py-12">
      <h3
        v-if="blok.title"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        class="text-center py-8 my-4 font-semibold text-gray-900 text-2xl md:text-3xl opacity-0"
        v-html="blok.title"
      ></h3>
      <component
        :is="theBlok.component"
        v-for="theBlok in blok.content.filter((item) =>
          ['RichTextBlok', 'AccordionGroup'].includes(item.component),
        )"
        :key="theBlok._uid"
        v-intersection="{ class: 'opacity-100 animate-fade-in-opacity' }"
        :blok="theBlok"
        class="w-full text-gray-600 opacity-0"
      />

      <div
        v-if="
          blok.ctaSectionTitle ||
          blok.ctaSectionDescription?.content?.[0].content ||
          blok.ctaSectionButton?.length
        "
        v-intersection="{ class: 'opacity-100 animate-slide-in-bottom' }"
        class="violet-to-blue-gradient rounded-xl p-4 text-center my-8 md:my-12 opacity-0"
      >
        <h3
          v-if="blok.ctaSectionTitle"
          class="text-white my-4 font-semibold text-2xl md:text-3xl"
          v-html="blok.ctaSectionTitle"
        ></h3>
        <RichText :blok="blok.ctaSectionDescription" class="text-white" />
        <component
          :is="theBlok.component"
          v-for="theBlok in blok.ctaSectionButton.filter((item) =>
            ['ButtonBlok'].includes(item.component),
          )"
          :key="theBlok._uid"
          :blok="theBlok"
          class="w-full md:w-auto my-4"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
</script>
