import { toUpper, get } from 'lodash';
import { AdvertisementSourceType } from '~/utils/constants.js';

/**
 * @typedef {string} AdvertisementId
 */

/**
 * @typedef {'ACTIVE'|'NOT_ACTIVE'} AdvertisementStatus
 */

/**
 * @typedef {'KV24'|'WEB_DIGIBROKER'|'WEB_ADVERTISEMENT_EDIT'|'WEB_COPY'} AdvertisementSourceTypeDef
 */

/**
 * @typedef {'HOUSE'|'APARTMENT'} AdvertisementPropertyType
 */

/**
 * @typedef {AdvertisementGet[]} AdvertisementsGet
 */

/**
 * @typedef {{
 *   addressFirstLine: string,
 *   addressStreet?: string,
 *   addressHouseNumber?: string,
 *   addressApartmentNumber?: string,
 *   addressCity?: string,
 *   addressCountry?: string,
 *   addressIndex?: string,
 *   addressBuildingExternalId?: string,
 *
 *   floor?: number,
 *   floorsTotal?: number,
 *   objectArea?: number,
 *   numberOfRooms?: number,
 *   hasParking?: boolean,
 *   hasStorage?: boolean,
 *   hasBalcony?: boolean,
 *   propertyType: AdvertisementPropertyType,
 *
 *   description?: string,
 *   rentAmount?: number,
 *   petsAllowed: boolean,
 *   images?: Array<string>,
 *   status: AdvertisementStatus,
 *
 *   lastUpdatedSource: AdvertisementSourceTypeDef,
 *   initialSource: AdvertisementSourceTypeDef,
 *
 *   delete?: boolean
 * }} AdvertisementSubmit
 */

/**
 * @typedef {{
 *  creatorEmail: string,
 *
 *   id: string,
 *
 *   addressFirstLine: string,
 *   addressStreet?: string,
 *   addressHouseNumber?: string,
 *   addressApartmentNumber?: string,
 *   addressCity?: string,
 *   addressCountry?: string,
 *   addressIndex?: string,
 *   addressBuildingExternalId?: string,
 *
 *   floor?: number,
 *   floorsTotal?: number,
 *   objectArea?: number,
 *   numberOfRooms?: number,
 *   hasParking: boolean,
 *   hasStorage: boolean,
 *   hasBalcony: boolean,
 *   propertyType?: AdvertisementPropertyType,
 *
 *   description: string,
 *   rentAmount?: number,
 *   petsAllowed: boolean,
 *   images: Array<string>,
 *   status: AdvertisementStatus,
 *
 *   updated: admin.firestore.Timestamp,
 *   created: admin.firestore.Timestamp,
 *   deleted?: admin.firestore.Timestamp,
 *
 *   lastUpdatedSource: AdvertisementSourceTypeDef,
 *   initialSource: AdvertisementSourceTypeDef,
 *
 *   invitationUrl?: string,
 *   invitationShortCode?: string,
 *
 *   currency?: 'EUR' | 'USD' | 'PLN'
 * }} AdvertisementGet
 */

import {
  setupState,
  setupMutations,
  setupGetters,
  actionFirebaseRequest,
} from '../firebase/index.js';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestGetAdvertisement = 'getAdvertisement';
const requestGetAdvertisements = 'getAdvertisements';
const requestPostAdvertisement = 'postAdvertisement';
const requestPutAdvertisement = 'putAdvertisement';
const requestPostAdvertisementCreateCopy = 'postAdvertisementCreateCopy';

const requestPostAdvertisementAdditionalFee = 'postAdvertisementAdditionalFee';
const requestDeleteAdvertisementAdditionalFee = 'deleteAdvertisementAdditionalFee';
const requestPutAdvertisementAdditionalFee = 'putAdvertisementAdditionalFee';

const requestPostAdvertisementSubscription = 'postAdvertisementSubscription';
const requestDeleteAdvertisementSubscription = 'deleteAdvertisementSubscription';
const requestGetAdvertisementSubscription = 'getAdvertisementSubscription';

const requests = [
  requestGetAdvertisement,
  requestGetAdvertisements,
  requestPutAdvertisement,
  requestPostAdvertisement,
  requestPostAdvertisementCreateCopy,
  requestPostAdvertisementAdditionalFee,
  requestDeleteAdvertisementAdditionalFee,
  requestPutAdvertisementAdditionalFee,
  requestPostAdvertisementSubscription,
  requestDeleteAdvertisementSubscription,
  requestGetAdvertisementSubscription,
];

// -------------- DEFINE REQUESTS ---------------
// ----------------------------------------------

export const state = () => ({ ...setupState(requests) });
export const getters = { ...setupGetters(requests) };
export const mutations = { ...setupMutations(requests) };

export const actions = {
  /**
   * Firebase request as action that can be dispatched.
   */
  firebaseRequest: actionFirebaseRequest(),

  // ---------------------------------------------
  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------- they are defined above -----------

  /**
   * @param {AdvertisementId} id
   * @return {Promise<{
   *   response: AdvertisementGet,
   *   failed: boolean,
   * }>}
   */
  getAdvertisement: function ({ dispatch }, { id }) {
    return dispatch('firebaseRequest', {
      request: requestGetAdvertisement,
      data: { id: id },
    });
  },

  /**
   * @return {Promise<{
   *   response: AdvertisementsGet,
   *   failed: boolean,
   * }>}
   */
  getAdvertisements: async function ({ dispatch }) {
    return dispatch('firebaseRequest', {
      request: requestGetAdvertisements,
      data: { status: ['ACTIVE', 'NOT_ACTIVE', 'DRAFT'] },
      functionRequestOverride: 'getAdvertisement',
    });
  },

  /**
   * @param {AdvertisementSubmit} advertisement
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: AdvertisementId,
   *   failed: boolean,
   * }>}
   */
  postAdvertisement: function ({ dispatch, rootGetters }, { advertisement, source }) {
    const getCurrency = () => (rootGetters.getCountryIsPoland ? 'PLN' : 'EUR');
    return dispatch('firebaseRequest', {
      request: requestPostAdvertisement,
      data: {
        advertisement: {
          ...advertisement,
          currency: get(advertisement, 'currency', getCurrency()),
        },
        source: source,
      },
    });
  },

  /**
   * @param {AdvertisementSubmit} advertisement
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: AdvertisementId,
   *   failed: boolean,
   * }>}
   */
  postAdvertisementFromDigibroker: function (
    { dispatch, rootGetters },
    { advertisement, source },
  ) {
    return dispatch('postAdvertisement', {
      advertisement: {
        ...advertisement,
        workflow: toUpper(rootGetters.getCountry),
      },
      source: AdvertisementSourceType.WEB_DIGIBROKER,
    });
  },

  /**
   * @param {AdvertisementId} id
   * @param {AdvertisementSubmit} advertisement
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  putAdvertisement: function ({ dispatch }, { id, advertisement, source }) {
    return dispatch('firebaseRequest', {
      request: requestPutAdvertisement,
      data: { id: id, advertisement: advertisement, source: source },
    });
  },

  /**
   * @param {AdvertisementId} id
   * @param {AdvertisementSubmit} advertisement
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  putAdvertisementFromDigibroker: function ({ dispatch }, { id, advertisement }) {
    return dispatch('putAdvertisement', {
      id: id,
      source: AdvertisementSourceType.WEB_DIGIBROKER,
      advertisement: {
        ...advertisement,
        lastUpdatedSource: AdvertisementSourceType.WEB_DIGIBROKER,
      },
    });
  },

  /**
   * @param {AdvertisementId} id
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  putAdvertisementStatusAsActive: function ({ dispatch }, { id, source }) {
    return dispatch('putAdvertisement', {
      id: id,
      source: source,
      advertisement: {
        status: 'ACTIVE',
      },
    });
  },

  /**
   * @param {AdvertisementId} id
   * @param {boolean} deleteStatus
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: undefined,
   *   failed: boolean,
   * }>}
   */
  deleteAdvertisement: function ({ dispatch }, { id, deleteStatus, source }) {
    return dispatch('firebaseRequest', {
      request: requestPutAdvertisement,
      data: { id: id, delete: deleteStatus, source: source },
    });
  },

  /**
   * @param {AdvertisementId} id
   * @param {AdvertisementSourceTypeDef} source
   * @return {Promise<{
   *   response: String,
   *   failed: boolean,
   * }>}
   */
  postAdvertisementCreateCopy: function ({ dispatch }, { id, source }) {
    return dispatch('firebaseRequest', {
      request: requestPostAdvertisementCreateCopy,
      data: {
        id: id,
        source: source,
      },
    });
  },

  /**
   * @param {String} paymentInterval
   * @param {String} additionalFeeType
   * @param {String} advertisementId
   * @param {Integer} amount
   * @param {String} source
   *
   */
  postAdvertisementAdditionalFee: function (
    { dispatch },
    { advertisementId, additionalFeeType, paymentInterval, amount, source },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPostAdvertisementAdditionalFee,
      data: {
        id: advertisementId,
        type: additionalFeeType,
        paymentInterval: paymentInterval,
        amount: amount,
        source: source,
      },
    });
  },

  /**
   * @param {String} advertisementId
   * @param {String} additionalFeeId
   * @param {Integer} amount
   * @param {String} source
   *
   */
  putAdvertisementAdditionalFee: function (
    { dispatch },
    { advertisementId, additionalFeeId, amount, source },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPutAdvertisementAdditionalFee,
      data: {
        id: advertisementId,
        additionalFeeId: additionalFeeId,
        source: source,
        amount: amount,
      },
    });
  },

  /**
   * @param {String} advertisementId
   * @param {String} additionalFeeId
   * @param {String} source
   *
   */

  deleteAdvertisementAdditionalFee: function (
    { dispatch },
    { advertisementId, additionalFeeId, source },
  ) {
    return dispatch('firebaseRequest', {
      request: requestDeleteAdvertisementAdditionalFee,
      data: {
        id: advertisementId,
        additionalFeeId: additionalFeeId,
        source: source,
      },
    });
  },

  /**
   *
   */
  postAdvertisementSubscription: function (
    { dispatch },
    {
      email,
      locale,
      country,
      city,
      districts,
      roomsMin,
      roomsMax,
      priceMin,
      priceMax,
      areaMin,
      areaMax,
      floorMin,
      floorMax,
      hasStorage,
      hasParking,
      petsAllowed,
      agreedTCAndPrivacyPolicy,
    },
  ) {
    const requestData = {
      email: email,
      locale: locale,
      searchCountry: country,
      searchCity: city,
      agreedTCAndPrivacyPolicy: agreedTCAndPrivacyPolicy,
    };
    if (districts.length > 0) {
      requestData.searchCityDistricts = districts;
    }
    if (priceMin) {
      requestData.searchPriceMin = priceMin;
    }
    if (priceMax) {
      requestData.searchPriceMax = priceMax;
    }
    if (areaMin) {
      requestData.searchAreaMin = areaMin;
    }
    if (areaMax) {
      requestData.searchAreaMax = areaMax;
    }
    if (roomsMin) {
      requestData.searchRoomsMin = roomsMin;
    }
    if (roomsMax) {
      requestData.searchRoomsMax = roomsMax;
    }
    if (floorMin) {
      requestData.searchFloorMin = floorMin;
    }
    if (floorMax) {
      requestData.searchFloorMax = floorMax;
    }
    if (petsAllowed) {
      requestData.searchPetsAllowed = petsAllowed;
    }
    if (hasParking) {
      requestData.searchHasParking = hasParking;
    }
    if (hasStorage) {
      requestData.searchHasStorage = hasStorage;
    }
    return dispatch('firebaseRequest', {
      request: requestPostAdvertisementSubscription,
      data: {
        requestData: requestData,
      },
    });
  },

  /**
   *
   */

  deleteAdvertisementSubscription: function ({ dispatch }, { subscriptionToken }) {
    return dispatch('firebaseRequest', {
      request: requestDeleteAdvertisementSubscription,
      data: {
        request: {
          subscriptionToken: subscriptionToken,
        },
      },
    });
  },

  /**
   *
   */

  getAdvertisementSubscription: function ({ dispatch }, { subscriptionToken }) {
    return dispatch('firebaseRequest', {
      request: requestGetAdvertisementSubscription,
      data: {
        request: {
          subscriptionToken: subscriptionToken,
        },
      },
    });
  },

  // --------- IMPLEMENT DEFINED ACTIONS ---------
  // ---------------------------------------------
};
