<template>
  <div v-editable="blok" class="icon-blok relative" :class="iconBlokClasses">
    <div v-if="blok.iconBackgroundColor" :class="backgroundClasses"></div>
    <r-icon
      class="r-icon block text-center relative"
      :class="classes"
      fixed-width
      :icon-name="normalizeIconName(blok.iconName)"
      :prefix="blok.iconFontStyle ? blok.iconFontStyle : ''"
    />
  </div>
</template>

<script>
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'IconBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /* Migrational system in place to enable both old and icons while we migrate the last ones away */
    isOldIcon() {
      if (this.blok && this.blok.iconName && this.blok.iconName.startsWith('r-')) {
        const path = this.$nuxt.$route.path;
        this.$sentry.captureMessage(
          `Replace and remove icon - ${this.blok.iconName} in component IconBlok.vue, route ${path}`,
        );

        return true;
      }
      return false;
    },
    classes() {
      const classes = [];
      if (this.blok.iconSize) {
        classes.push(this.blok.iconSize);
      }
      if (this.blok.iconColor) {
        classes.push(this.blok.iconColor);
      }

      return classes;
    },
    iconBlokClasses() {
      const classes = [];
      if (this.blok.iconBackgroundColor) {
        classes.push('inline-block p-2 text-center');
      }
      return classes;
    },
    backgroundClasses() {
      const classes = [];
      if (this.blok.iconBackgroundColor) {
        classes.push(backgroundColors[this.blok?.iconBackgroundColor]);
        classes.push(
          'absolute inline-block aspect-square top-0 bottom-0 left-50 transform -translate-x-1/2 rounded-full',
        );
      }
      return classes;
    },
  },
  methods: {
    /* Migrational step - we need to support both old and new Icon systems to migrate between them */
    normalizeIconName(iconName) {
      if (!this.isOldIcon) {
        return iconName;
      }

      const lowercaseIconName = iconName.slice(2).toLowerCase();
      switch (lowercaseIconName) {
        case 'arrow-enter':
          return 'arrow-turn-down-left';
        case 'approved':
          return 'user-shield';
        case 'chevron':
          return 'chevron-down';
        case 'devices':
          return 'laptop-mobile';
        case 'document':
          return 'file';
        case 'document-filled':
          return 'file-lines';
        case 'education':
          return 'graduation-cap';
        case 'euro':
          return 'euro-sign';
        case 'home-people':
          return 'house-chimney-user';
        case 'person-check':
          return 'user-check';
        case 'person-declined':
          return 'user-large-slash';
        case 'hands-helping':
          return 'handshake-angle';
        case 'protection-shield':
          return 'shield-halved';
        case 'shield-checked':
          return 'shield-check';
        case 'home-heart':
          return 'house-heart';
        case 'mobile-phone':
          return 'mobile';
        case 'quote':
          return 'quote-right';
        case 'zloty':
          return 'zloty-sign';
        case 'share':
          return 'share-nodes';
        case 'candidates':
          return 'user-group';
        case 'list-board':
          return 'clipboard-list-check';
        case 'email':
          return 'envelope';
        case 'house':
          return 'house-day';
      }

      return lowercaseIconName;
    },
  },
};
</script>
