<template>
  <section class="agreement-active-view">
    <AgreementAlerts
      :agreement-annexes="agreementAnnexes"
      :agreement-terminations="agreementTerminations"
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-handover="agreementHandover"
      :is-agreement-type-pl="isAgreementTypePl"
      :is-tenant-view="isTenantView"
      :agreement-time-zone="agreementTimeZone"
      @click-open-handover-protocol="openHandoverActClick()"
      @click-open-renewal="onClickOpenAnnex"
      @click-open-termination="onClickOpenTermination(agreementTerminations?.pending)"
      @click-link-to-final-handover="openWebAppForFinalHandover"
      @click-open-termination-change="
        onClickOpenTermination(agreementTerminations?.pending)
      "
      @click-cancel-termination="
        cancelInSigningTermination(agreementTerminationModals.CANCELING_ACTIVITY_CARD)
      "
    />
    <div class="flex flex-col md:flex-row gap-4">
      <div class="w-full md:w-5/12 max-w-xl mx-auto">
        <r-agreement-info-card
          :agreement-from-firebase="agreementFromFirebase"
          :is-pl-agreement="isAgreementTypePl"
          :is-tenant-view="isTenantView"
          :has-terminations="hasTerminationActive || hasTerminationInSigning"
          :agreement-time-zone="agreementTimeZone"
          @click-agreement-renewal-pl="plAgreementRenewalActions"
          @click-duplicate-agreement="openDuplicateAgreementModal"
          @click-i-have-a-problem="openIHaveAProblem"
          @click-open-web-app="openWebApp"
          @click-terminate-agreement="plAgreementTerminationActions"
          @click-terminate-agreement-for-tenant="openTerminationTenantModal"
        />

        <!--    Agreement Parties accordion -->
        <r-accordion
          class="mt-4"
          icon="users"
          :title="$t('agreement.label.agreement_parties')"
        >
          <!--     Landlord information -->
          <r-profile-info-card
            v-if="agreementFromFirebase?.landlord"
            class="bg-peach-100 rounded-lg"
            :email="agreementFromFirebase.landlord?.email"
            :email-label="$t('agreement.card.label.email')"
            :full-name="constructAgreementName(agreementFromFirebase.landlord)"
            :full-name-label="$t('agreement.card.label.name')"
            :phone-number="
              constructPhoneNr(
                agreementFromFirebase.landlord?.phoneCountryCode,
                agreementFromFirebase.landlord?.phoneNumber,
              )
            "
            :phone-number-label="$t('agreement.card.label.phone')"
            :title="$t('agreement.card.label.landlord')"
            title-class="text-sm"
          />

          <!--     Tenant information -->
          <div class="my-4">
            <r-profile-info-card
              v-if="agreementFromFirebase?.tenant"
              class="bg-peach-100 rounded-lg"
              :email="agreementFromFirebase.tenant?.email"
              :email-label="$t('agreement.card.label.email')"
              :full-name="constructAgreementName(agreementFromFirebase.tenant)"
              :full-name-label="$t('agreement.card.label.name')"
              :phone-number="
                constructPhoneNr(
                  agreementFromFirebase.tenant?.phoneCountryCode,
                  agreementFromFirebase.tenant?.phoneNumber,
                )
              "
              :phone-number-label="$t('agreement.card.label.phone')"
              :title="$t('agreement.card.label.tenant')"
              title-class="text-sm"
            />
          </div>

          <!--     CoTenants information -->
          <div
            v-if="agreementFromFirebase?.coTenants?.length"
            class="bg-peach-100 rounded-lg p-4"
          >
            <h4 class="font-medium">
              {{ $t('agreement.card.label.co_tenant') }}
            </h4>
            <div
              v-for="coTenant in agreementFromFirebase.coTenants"
              :key="coTenant.profileId"
            >
              <r-title-with-icon
                v-if="constructAgreementName(coTenant)"
                class="mt-3"
                icon="user"
                :label="$t('agreement.card.label.name')"
                :title="constructAgreementName(coTenant)"
              />
            </div>
          </div>
        </r-accordion>
      </div>

      <div class="w-full md:w-7/12 max-w-xl mx-auto">
        <div class="flex flex-col gap-4 w-full bg-white rounded-md shadow-md p-4">
          <!--  Agreement status card -->
          <div class="bg-peach-100 rounded-lg p-4">
            <div class="flex flex-row flex-wrap-reverse items-center gap-2">
              <r-title-with-icon
                icon="file"
                icon-class="text-gray-900"
                :title="$t('agreement.label.agreement')"
                title-class="font-semibold uppercase"
              />
              <div>
                <r-chip
                  :color="agreementChipColor"
                  :label="agreementStatusLabel(agreementFromFirebase.status)"
                />
              </div>
            </div>
            <div class="my-4">{{ agreementStatusDescription }}</div>
            <r-button
              border-color="light-gray"
              color="black"
              inverted
              :label="$t('buttons.view_agreement')"
              size="small"
              width="full"
              @click="openAgreementPreviewClick()"
            />
          </div>

          <!--  Handover status card -->
          <div class="bg-peach-100 rounded-lg p-4">
            <div class="flex flex-row flex-wrap-reverse items-center gap-2">
              <r-title-with-icon
                icon="file"
                icon-class="text-gray-900"
                :title="$t('agreement.label.handover_act')"
                title-class="font-semibold uppercase"
              />
              <div>
                <r-chip :color="handoverChipColor" :label="handoverStatusLabel" />
              </div>
            </div>

            <div class="my-4">{{ handoverStatusDescription }}</div>
            <r-button
              v-if="hasLegacyHandover"
              border-color="light-gray"
              color="black"
              inverted
              :label="$t('buttons.view_handover_act')"
              size="small"
              :to="appHandoverLink"
              width="full"
            />
            <r-button
              v-else-if="
                agreementHandover?.handover?.status === handoverStatuses.COMPLETED &&
                isTenantView
              "
              :label="$t('buttons.review_handover_act')"
              size="small"
              width="full"
              @click="openHandoverActClick()"
            />
            <r-button
              v-else-if="
                !isTenantView ||
                (isTenantView &&
                  agreementHandover?.handover?.status === handoverStatuses.FINISHED)
              "
              border-color="light-gray"
              color="black"
              inverted
              :label="$t('buttons.view_handover_act')"
              size="small"
              width="full"
              @click="openHandoverActClick()"
            />
          </div>
          <!--  Annexes status cards -->
          <div v-for="annex in agreementAnnexesData" :key="annex.id">
            <div
              v-if="annex.status === annexStatus.IN_PROCESSING"
              class="bg-peach-100 rounded-lg p-4 w-full h-40 animate-pulse"
            >
              <r-title-with-icon
                icon="file"
                icon-class="text-gray-300"
                :title="$t('agreement.annexing.annex')"
                title-class="font-semibold uppercase text-gray-300"
              />
            </div>
            <div v-else class="bg-peach-100 rounded-lg p-4">
              <div class="flex flex-row flex-wrap-reverse items-center gap-2">
                <r-title-with-icon
                  icon="file"
                  icon-class="text-gray-900"
                  :title="$t('agreement.annexing.annex') + annex?.titleCountNr"
                  title-class="font-semibold uppercase"
                />
                <div>
                  <r-chip
                    :color="annexChipColor(annex.status)"
                    :label="annexStatusLabel(annex.status)"
                  />
                </div>
              </div>
              <div class="my-4">{{ annexStatusDescription(annex.status) }}</div>

              <div class="flex flex-col gap-2">
                <r-button
                  border-color="light-gray"
                  color="black"
                  inverted
                  :label="$t('agreement.annexing.view_annex')"
                  size="small"
                  width="full"
                  @click="onClickOpenAnnex(annex)"
                />
                <r-button
                  v-if="annex.status === annexStatus.IN_SIGNING"
                  border-color="light-gray"
                  color="black"
                  inverted
                  :label="$t('agreement.annexing.cancel_signing')"
                  size="small"
                  width="full"
                  @click="openAnnexCancelModal"
                />
              </div>
            </div>
          </div>
          <!--  Termination active card -->
          <div v-if="showTerminationActiveCard" class="bg-peach-100 rounded-lg p-4">
            <div class="flex flex-row flex-wrap-reverse items-center gap-2">
              <r-title-with-icon
                icon="calendar-circle-minus"
                icon-class="text-gray-900"
                :title="$t('agreement.label.termination')"
                title-class="font-semibold uppercase"
              />
              <div>
                <r-chip
                  :color="ComponentColor.GREEN"
                  :label="terminationStatusLabel(agreementTerminations?.active?.status)"
                />
              </div>
            </div>

            <div class="my-4">
              {{ $t('agreement.termination.active_termination_content') }}
              <b
                >{{
                  constructDateAndTimeFromIsoString(
                    agreementTerminations?.active?.endDate,
                    agreementTimeZone,
                  )
                }}
              </b>
            </div>

            <r-button
              border-color="light-gray"
              color="black"
              inverted
              :label="$t('buttons.view_termination')"
              size="small"
              width="full"
              @click="onClickOpenTermination(agreementTerminations?.active)"
            />
          </div>

          <!--  Termination pending card -->
          <div v-if="showTerminationPendingCard" class="bg-peach-100 rounded-lg p-4">
            <div class="flex flex-row flex-wrap-reverse items-center gap-2">
              <r-title-with-icon
                icon="calendar-circle-minus"
                icon-class="text-gray-900"
                :title="$t('agreement.label.termination')"
                title-class="font-semibold uppercase"
              />
              <div>
                <r-chip
                  :color="ComponentColor.BLUE"
                  :label="
                    terminationStatusLabel(agreementTerminations?.pending?.status)
                  "
                />
              </div>
            </div>

            <div class="my-4">
              {{ $t('agreement.termination.pending_termination_content') }}
            </div>
            <div class="flex flex-col gap-3">
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('buttons.view_termination')"
                size="small"
                width="full"
                @click="onClickOpenTermination(agreementTerminations?.pending)"
              />
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('buttons.cancel_signing')"
                size="small"
                width="full"
                @click="
                  cancelInSigningTermination(agreementTerminationModals.CANCELING_TILE)
                "
              />
            </div>
          </div>
        </div>
        <!-- Documents accordion -->
        <r-accordion
          v-if="agreementFromFirebase.documents"
          class="my-4 shadow-lg"
          icon="folder-open"
          :title="documentAccordionTitle"
        >
          <p>{{ $t('agreement.landlord_dialog.documents_explanation') }}</p>

          <div
            v-for="(document, index) in agreementDocumentsFlattenedList"
            :key="index"
            class="my-3"
          >
            <r-button-download
              :href="document.url"
              :icon="document.type === 'CONTAINER' ? 'file-signature' : 'file-pdf'"
              :label="documentTypeTranslation(document.type, document.index)"
            />
          </div>
        </r-accordion>

        <!-- Problem accordion -->
        <r-accordion
          class="my-4 shadow-lg"
          icon="triangle-exclamation"
          :title="$t('agreement.landlord_dialog.problem_title')"
        >
          <p>{{ $t('agreement.landlord_dialog.problem_explanation') }}</p>

          <r-button
            class="mt-6"
            display="block"
            icon="copy"
            icon-class="ml-3"
            icon-position-after
            size="small"
            @click="copyToClipboard($refs.copyAccordion)"
          >
            <span ref="copyAccordion">{{ $t('buttons.copy_email') }}</span>
          </r-button>
        </r-accordion>

        <!-- Agreement termination accordion -->
        <r-accordion
          v-if="
            !isAgreementTypePl &&
            (agreementFromFirebase.status === agreementStatuses.SIGNED ||
              agreementFromFirebase.status === agreementStatuses.ACTIVE)
          "
          class="my-4 shadow-lg"
          icon="file-excel"
          :title="$t('agreement.landlord_dialog.terminate_title')"
        >
          <div>
            <p class="whitespace-pre-line">
              {{ $t('agreement.dialog.terminate_agreement.content') }}
            </p>

            <r-button
              class="mt-8"
              display="block"
              :label="$t('agreement.dialog.terminate_agreement.cta_button')"
              size="small"
              @click="openWebApp"
            />
          </div>
        </r-accordion>

        <r-accordion
          v-if="showDialogHandoverFinal"
          class="my-4 shadow-lg"
          icon="ballot-check"
          :title="$t('agreement.landlord_dialog.handover_final_title')"
        >
          <p class="whitespace-pre-line">
            {{ $t('agreement.dialog.handover_final.content') }}
          </p>
          <r-button
            class="mt-8"
            display="block"
            :label="$t('agreement.dialog.handover_final.cta_button')"
            size="small"
            @click="openWebAppForFinalHandover"
          />
        </r-accordion>
      </div>
    </div>

    <IHaveAProblemModal
      :show-modal="showIHaveAProblemModal"
      @close-overlay="showIHaveAProblemModal = false"
      @copy-email-click="copyToClipboard"
    />

    <AgreementRenewalPlModal
      :annex-in-signing="hasAnnexInSigning"
      :show-modal="showAgreementRenewalPlModal"
      @close-overlay="showAgreementRenewalPlModal = false"
    />

    <DuplicateAgreementModal
      :agreement-data="agreementFromFirebase"
      :show-modal="showDuplicateAgreementModal"
      @close-overlay="showDuplicateAgreementModal = false"
    />

    <AnnexCancelModal
      :show-modal="showAnnexCancelModal"
      @close-overlay="showAnnexCancelModal = false"
    />

    <TerminationCancelInSigningModal
      :show-modal="showTerminationCancelInSigning"
      @close-overlay="showTerminationCancelInSigning = false"
    />

    <TerminationInSigningForRenewalModal
      :show-modal="showTerminationInSigningForRenewal"
      @close-overlay="showTerminationInSigningForRenewal = false"
    />

    <TerminationInSigningForNewTerminationModal
      :show-modal="showTerminationInSigningForNewTermination"
      @close-overlay="showTerminationInSigningForNewTermination = false"
    />

    <TerminationActiveForRenewalModal
      :show-modal="showTerminationActiveForRenewal"
      @close-overlay="showTerminationActiveForRenewal = false"
    />

    <TerminationForTenantModal
      :show-modal="showTerminationTenantModal"
      @close-overlay="showTerminationTenantModal = false"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import IHaveAProblemModal from '~/components/section/agreement/IHaveAProblemModal';
import AgreementRenewalPlModal from '~/components/section/agreement/AgreementRenewalPlModal';
import DuplicateAgreementModal from '~/components/section/agreement/DuplicateAgreementModal';
import AgreementAlerts from '~/components/section/agreement/AgreementAlerts';
import AnnexCancelModal from '~/components/section/agreement/annex/AnnexCancelModal.vue';
import {
  additionalFeeType,
  administrativeFeePaymentDetermination,
  administrativeFeePaymentPartyType,
  agreementStatuses,
  annexStatus,
  ComponentColor,
  handoverStatuses,
  insuranceInvoicePaymentDay,
  insuranceInvoiceTo,
  languageNames,
  oldHandoverStatuses,
  workflow,
} from '~/utils/constants';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
import {
  constructDateFromIsoString,
  constructDateAndTimeFromIsoString,
} from '~/utils/dateFromIsoStringConstructor.ts';
import { servicesAndUtilitiesNames } from '~/composables/servicesAndUtilitiesNames';
import { localizedCurrencyFormat } from '~/utils/localizedCurrencyFormat';
import { constructAgreementName } from '~/utils/agreementNameConstructor';
import {
  agreementStatusLabel,
  terminationStatusLabel,
} from '~/composables/agreementTranslationsConstructor';
import {
  agreementTerminationModals,
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { defineComponent } from 'vue';
import TerminationCancelInSigningModal from '~/components/section/agreement/termination/TerminationCancelInSigningModal.vue';
import TerminationInSigningForNewTerminationModal from '~/components/section/agreement/termination/TerminationInSigningForNewTerminationModal.vue';
import TerminationForTenantModal from '~/components/section/agreement/termination/TerminationForTenantModal.vue';

import { copyToClipboard } from '~/composables/copyToClipboard.ts';

export default defineComponent({
  name: 'AgreementActiveView',
  components: {
    TerminationForTenantModal,
    TerminationInSigningForNewTerminationModal,
    TerminationCancelInSigningModal,
    IHaveAProblemModal,
    AgreementRenewalPlModal,
    DuplicateAgreementModal,
    AgreementAlerts,
    AnnexCancelModal,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementAnnexes: {
      type: Array,
      default: null,
    },
    agreementTerminations: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    isAgreementTypePl: {
      type: Boolean,
      default: false,
    },
    agreementTimeZone: {
      type: String,
      default: null,
    },
  },

  emits: [
    'openAgreementPreviewClick',
    'openHandoverActClick',
    'clickOpenAnnex',
    'clickOpenTermination',
  ],

  setup() {
    return {
      constructPhoneNr,
      constructDateFromIsoString,
      constructDateAndTimeFromIsoString,
      constructAgreementName,
      localizedCurrencyFormat,
      servicesAndUtilitiesNames,
      agreementStatusLabel,
      terminationStatusLabel,
      copyToClipboard,
    };
  },

  data: () => ({
    languageNames,
    additionalFeeType,
    administrativeFeePaymentDetermination,
    administrativeFeePaymentPartyType,
    insuranceInvoiceTo,
    insuranceInvoicePaymentDay,

    handoverStatuses,
    oldHandoverStatuses,
    ComponentColor,

    showIHaveAProblemModal: false,
    showAgreementRenewalPlModal: false,
    showDuplicateAgreementModal: false,
    showAnnexCancelModal: false,
    annexStatus,

    showTerminationCancelInSigning: false,
    showTerminationInSigningForNewTermination: false,
    showTerminationInSigningForRenewal: false,
    showTerminationActiveForRenewal: false,
    showTerminationTenantModal: false,
  }),

  computed: {
    ...mapGetters({
      getGlobalAppButtonLinksAndImages:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImages',
    }),

    showDialogHandoverFinal() {
      if (this.isTenantView) return false;

      if (
        this.agreementFromFirebase.status !== agreementStatuses.DRAFT ||
        (this.agreementFromFirebase.status !== agreementStatuses.READY_TO_SIGN &&
          constructDateFromIsoString(this.agreementFromFirebase.terminationDate) !==
            null)
      ) {
        return true;
      }
      if (this.agreementFromFirebase.workflow === workflow.POLAND) {
        const endDateInDbExists = constructDateFromIsoString(
          this.agreementFromFirebase.endDate,
        );
        if (endDateInDbExists !== null) {
          const endDate = DateTime.fromISO(this.agreementFromFirebase.endDate, {
            zone: 'Europe/Warsaw',
          });
          return endDate.diffNow('days').days <= 43;
        }
      }
      return false;
    },

    showTerminationPendingCard() {
      return (
        this.agreementTerminations?.pending &&
        !this.isTenantView &&
        (this.agreementFromFirebase?.status === agreementStatuses.SIGNED ||
          this.agreementFromFirebase?.status === agreementStatuses.ACTIVE)
      );
    },

    showTerminationActiveCard() {
      return (
        this.agreementTerminations?.active &&
        !this.isTenantView &&
        (this.agreementFromFirebase?.status === agreementStatuses.SIGNED ||
          this.agreementFromFirebase?.status === agreementStatuses.ACTIVE)
      );
    },

    agreementStatusDescription() {
      if (this.agreementFromFirebase.status === agreementStatuses.READY_TO_SIGN) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.agreement_ready_to_sign')
          : 'view not visible for landlord';
      } else if (this.agreementFromFirebase.status === agreementStatuses.SIGNED) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.agreement_signed')
          : this.$t('agreement.landlord_dialog.agreement_signed');
      } else if (this.agreementFromFirebase.status === agreementStatuses.ACTIVE) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.agreement_signed')
          : this.$t('agreement.landlord_dialog.agreement_active');
      } else if (
        this.agreementFromFirebase.status === agreementStatuses.FINISHED ||
        this.agreementFromFirebase.status === agreementStatuses.ARCHIVED
      ) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.agreement_finished')
          : this.$t('agreement.landlord_dialog.agreement_finished');
      }

      return '';
    },

    agreementChipColor() {
      if (
        this.agreementFromFirebase.status === agreementStatuses.READY_TO_SIGN ||
        this.agreementFromFirebase.status === agreementStatuses.SIGNED ||
        this.agreementFromFirebase.status === agreementStatuses.PRE_SIGNING
      ) {
        return ComponentColor.BLUE;
      } else if (this.agreementFromFirebase.status === agreementStatuses.ACTIVE) {
        return ComponentColor.GREEN;
      } else if (this.agreementFromFirebase.status === agreementStatuses.FINISHED) {
        return ComponentColor.LIGHT_GRAY;
      }
      return ComponentColor.GRAY;
    },

    hasLegacyHandover() {
      return !!this.agreementFromFirebase?.handover?.status;
    },

    appHandoverLink() {
      const appLink = `handover?agreementId=${this.agreementId}`;
      const encodedRedirect = encodeURIComponent(appLink);
      return `/nstbc/login?mode=alta&redirect=${encodedRedirect}`;
    },

    handoverStatusDescription() {
      if (
        this.handoverStatus === oldHandoverStatuses.DRAFT ||
        this.handoverStatus === handoverStatuses.DRAFT ||
        this.handoverStatus === handoverStatuses.READY_TO_SEND
      ) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.handover_draft')
          : this.$t('agreement.landlord_dialog.handover_draft');
      } else if (
        this.handoverStatus === oldHandoverStatuses.COMPLETE ||
        this.handoverStatus === handoverStatuses.COMPLETED
      ) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.handover_needs_confirmation')
          : this.$t('agreement.landlord_dialog.handover_needs_confirmation');
      } else if (
        this.handoverStatus === oldHandoverStatuses.ACCEPTED ||
        this.handoverStatus === handoverStatuses.FINISHED
      ) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.handover_confirmed')
          : this.$t('agreement.landlord_dialog.handover_confirmed');
      } else if (this.handoverStatus === oldHandoverStatuses.DECLINED) {
        return this.isTenantView
          ? this.$t('agreement.tenant_dialog.handover_declined')
          : this.$t('agreement.landlord_dialog.handover_declined');
      }

      return this.isTenantView
        ? this.$t('agreement.tenant_dialog.handover_not_started')
        : this.$t('agreement.landlord_dialog.handover_not_started');
    },

    handoverStatus() {
      let status;
      // old handover structure
      if (this.agreementFromFirebase?.handover?.status) {
        status = this.agreementFromFirebase.handover?.status;
        // new handover structure
      } else if (this.agreementHandover?.handover?.status) {
        status = this.agreementHandover?.handover?.status;
      } else {
        status = null;
      }

      return status;
    },

    handoverChipColor() {
      if (this.handoverStatus === oldHandoverStatuses.DECLINED) {
        return ComponentColor.RED;
      } else if (
        this.handoverStatus === oldHandoverStatuses.ACCEPTED ||
        this.handoverStatus === handoverStatuses.FINISHED
      ) {
        return ComponentColor.GREEN;
      } else if (
        this.handoverStatus === oldHandoverStatuses.COMPLETE ||
        this.handoverStatus === handoverStatuses.COMPLETED
      ) {
        return ComponentColor.YELLOW;
      }
      return ComponentColor.GRAY;
    },

    handoverStatusLabel() {
      let label;

      if (
        this.handoverStatus === oldHandoverStatuses.DRAFT ||
        this.handoverStatus === handoverStatuses.DRAFT ||
        this.handoverStatus === handoverStatuses.READY_TO_SEND
      ) {
        label = this.$t(`agreement.handover_status.draft`);
      } else if (
        this.handoverStatus === oldHandoverStatuses.COMPLETE ||
        this.handoverStatus === handoverStatuses.COMPLETED
      ) {
        label = this.$t(`agreement.handover_status.complete`);
      } else if (
        this.handoverStatus === oldHandoverStatuses.ACCEPTED ||
        this.handoverStatus === handoverStatuses.FINISHED
      ) {
        label = this.$t(`agreement.handover_status.accepted`);
      } else if (this.handoverStatus === oldHandoverStatuses.DECLINED) {
        label = this.$t(`agreement.handover_status.declined`);
      } else {
        label = this.$t(`agreement.handover_status.not_started`);
      }

      return label;
    },

    documentAccordionTitle() {
      const title = this.$t('agreement.landlord_dialog.documents_title');

      const lengthOfObject = Object.keys(this.agreementDocumentsFlattenedList)?.length;
      if (lengthOfObject) {
        return `${title} (${lengthOfObject})`;
      }

      return title;
    },

    agreementDocumentsFlattenedList() {
      const documents = this.agreementFromFirebase.documents;
      let flatList = [];

      for (const key in documents) {
        if (Array.isArray(documents[key])) {
          flatList = flatList.concat(
            documents[key].map((doc, index) => ({ ...doc, index: index + 1 })),
          );
        } else if (typeof documents[key] === 'object') {
          flatList.push(documents[key]);
        }
      }

      return flatList;
    },

    // Agreement renewal is available for PL agreement 3 months before endDate
    agreementRenewalIsAllowed() {
      const today = DateTime.now().setZone('Europe/Warsaw').startOf('day');

      const renewalStartDate = DateTime.fromISO(this.agreementFromFirebase.endDate, {
        zone: 'Europe/Warsaw',
      })
        .minus({ months: 3 })
        .startOf('day');

      return (
        this.isAgreementTypePl && !this.hasAnnexInSigning && today > renewalStartDate
      );
    },

    hasTerminationInSigning() {
      return !!this.agreementTerminations?.pending;
    },

    hasTerminationActive() {
      return !!this.agreementTerminations?.active;
    },

    hasAnnexInSigning() {
      return this.agreementAnnexes?.some(
        (annex) => annex?.status === annexStatus.IN_SIGNING,
      );
    },

    agreementAnnexesData() {
      const sortedAnnexes = cloneDeep(this.agreementAnnexes);

      // annex.annexIdBaseIncrement
      sortedAnnexes?.sort((a, b) => a.annexIdBaseIncrement - b.annexIdBaseIncrement);

      let countNr = 0;
      return sortedAnnexes?.map((annex) => {
        if (annex.status === annexStatus.ACTIVE) ++countNr;
        return {
          ...annex,
          titleCountNr: annex.status === annexStatus.ACTIVE ? ` #${countNr}` : '',
        };
      });
    },
  },

  methods: {
    ...mapActions({
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    openAgreementPreviewClick() {
      this.$emit('openAgreementPreviewClick');
    },

    openHandoverActClick() {
      this.$emit('openHandoverActClick');
    },

    documentTypeTranslation(type, index) {
      if (type && index) {
        return `${this.$t(`agreement.label.document.${type.toLowerCase()}`)} #${index}`;
      } else if (type) {
        return this.$t(`agreement.label.document.${type.toLowerCase()}`);
      }
    },

    openIHaveAProblem() {
      this.showIHaveAProblemModal = true;
    },

    openAgreementRenewalPlModal() {
      this.showAgreementRenewalPlModal = true;
    },

    openDuplicateAgreementModal() {
      this.showDuplicateAgreementModal = true;
    },

    openTerminationTenantModal() {
      this.showTerminationTenantModal = true;
    },

    openWebAppForFinalHandover() {
      const encodedGoToAgreementListInWebApp = encodeURIComponent(
        `agreementList=true&agreementId=${this.agreementFromFirebase?.id}`,
      );
      const route = `/nstbc/login?mode=alta&aqpe=${encodedGoToAgreementListInWebApp}`;
      window.open(route, '_blank');
    },

    openWebApp() {
      window.open(
        this.getGlobalAppButtonLinksAndImages.bloks.AppButtonBlok.web_app_login_link,
        '_blank',
      );
    },

    onClickOpenAnnex(annex) {
      this.$emit('clickOpenAnnex', annex);
    },

    openAnnexCancelModal() {
      this.showAnnexCancelModal = true;
      this.emitAgreementTrackerEvent(agreementTrackingEventNames.OPEN_CANCEL_ANNEX);
    },

    annexStatusLabel(status) {
      const string = status?.toLowerCase();
      return string ? this.$t(`agreement.annexing.status.${string}`) : null;
    },

    annexChipColor(status) {
      if (status === annexStatus.IN_SIGNING) {
        return ComponentColor.BLUE;
      } else if (status === annexStatus.ACTIVE) {
        return ComponentColor.GREEN;
      }
      return ComponentColor.RED;
    },

    annexStatusDescription(status) {
      const string = `${status?.toLowerCase()}_sub`;
      return string ? this.$t(`agreement.annexing.status.${string}`) : null;
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    onClickOpenTermination(termination) {
      this.$emit('clickOpenTermination', termination);
    },

    cancelInSigningTermination(location) {
      this.showTerminationCancelInSigning = true;

      this.emitAgreementTrackerEvent(
        agreementTrackingEventNames.TERMINATION_OPEN_MODAL,
        location,
      );
    },

    plAgreementRenewalActions() {
      if (this.hasTerminationInSigning) {
        this.showTerminationInSigningForRenewal = true;

        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.TERMINATION_OPEN_MODAL,
          agreementTerminationModals.AGREEMENT_RENEWAL_TERMINATION_IN_SIGNING,
        );
      } else if (this.hasTerminationActive) {
        this.showTerminationActiveForRenewal = true;

        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.TERMINATION_OPEN_MODAL,
          agreementTerminationModals.AGREEMENT_RENEWAL_TERMINATION_ACTIVE,
        );
      } else if (this.agreementRenewalIsAllowed) {
        this.onClickOpenAnnex();
      } else {
        this.openAgreementRenewalPlModal();
      }
    },

    plAgreementTerminationActions() {
      if (this.hasTerminationInSigning) {
        this.showTerminationInSigningForNewTermination = true;

        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.TERMINATION_OPEN_MODAL,
          agreementTerminationModals.IN_SIGNING,
        );
      } else {
        this.onClickOpenTermination();
      }
    },
  },
});
</script>
