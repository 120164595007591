<template>
  <div class="min-h-1/2-screen">
    <div class="max-w-3xl mx-auto px-4">
      <div class="min-h-1/2-screen w-full flex flex-col justify-center items-center">
        <img class="w-32 mx-auto" src="/images/R-animated.svg" />
        <ul class="text-xl font-light text-center">
          <li v-if="loadingLines.length > 0" class="r-fade">
            {{ loadingLines[currentLineIndex] }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepSubmitAdvertisement',
  props: {
    loadingLines: {
      type: Array,
      required: true,
    },
  },
  emits: ['finalizeAdd'],
  data: function () {
    return {
      currentLineIndex: 0,
      timer: null,
    };
  },
  mounted() {
    this.$emit('finalizeAdd');
    this.timer = setInterval(() => {
      this.currentLineIndex++;
      if (this.currentLineIndex >= this.loadingLines.length) {
        this.currentLineIndex = 0;
      }
    }, 2500);
  },
  beforeUnmount() {
    window.clearInterval(this.timer);
  },
};
</script>
