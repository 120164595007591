<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="animated-rotating-cards pt-16 pb-10 md:py-20 bg-white overflow-hidden px-4"
    :style="{
      background:
        'linear-gradient( ' +
        tailwingHexValue(blok.backgroundColorTop) +
        ' 50%, ' +
        tailwingHexValue(blok.backgroundColorBottom) +
        ' 50%)',
    }"
  >
    <RichText
      v-if="blok.title"
      :blok="blok.title"
      class="max-w-5xl mx-auto text-center text-2xl md:text-4xl leading-tight mb-10 sm:mb-16"
    />
    <div class="scene flex justify-center relative m-4">
      <!--      Desktop cards, invisible in mobile for correct  height spacing for flipping cards-->
      <div class="flex flex-row flex-nowrap gap-6 invisible md:visible">
        <div
          v-for="(card, index) in blok.cards"
          :key="index"
          class="max-w-sm rounded-lg p-4 px-6 shadow-lg flex flex-col w-80 md:w-auto"
          :class="cardBackgroundClass(card.backgroundColor)"
        >
          <RichText v-if="card.title" :blok="card.title" class="text-xl text-center" />
          <img
            class="m-auto"
            :src="$transformStoryblokImage(card.image.filename, '/m/600x0')"
          />

          <div class="flex flex-col justify-between flex-1">
            <RichText v-if="card.content" :blok="card.content" />
            <r-button
              border-color="light-gray"
              class="md:hidden"
              icon="long-arrow-right"
              icon-class="mx-2"
              icon-position-after
              inverted
              :label="card.buttonLabel"
            />
          </div>
        </div>
      </div>

      <!--      Mobile flipping cards-->
      <div class="flipping-card md:hidden" :class="{ 'is-flipped': cardIsFlipped }">
        <div
          v-for="(card, index) in blok.cards"
          :key="index"
          class="max-w-sm rounded-lg p-4 px-6 shadow-lg flex flex-col flipping-card__face"
          :class="[
            cardBackgroundClass(card.backgroundColor),
            index === 0 ? 'flipping-card__face--front' : 'flipping-card__face--back',
          ]"
        >
          <RichText v-if="card.title" :blok="card.title" class="text-xl text-center" />
          <img
            class="m-auto"
            :src="$transformStoryblokImage(card.image.filename, '/m/600x0')"
          />

          <div class="flex flex-col justify-between flex-1">
            <RichText v-if="card.content" :blok="card.content" />
            <r-button
              border-color="light-gray"
              :icon="index === 0 ? 'long-arrow-right' : 'long-arrow-left'"
              icon-class="mr-3 ml-3"
              :icon-position-after="index === 0"
              inverted
              :label="card.buttonLabel"
              @click="flippCard()"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { backgroundColors, backgroundColorsHexValue } from '~/utils/constants';

export default {
  name: 'AnimatedRotatingCards',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      backgroundColorsHexValue,
      cardIsFlipped: false,
    };
  },

  methods: {
    cardBackgroundClass(color) {
      return backgroundColors[color] ? backgroundColors[color] : 'bg-white';
    },

    tailwingHexValue(color) {
      return backgroundColorsHexValue[color] ? backgroundColorsHexValue[color] : '#fff';
    },

    flippCard() {
      this.cardIsFlipped = !this.cardIsFlipped;
    },
  },
};
</script>

<style scoped lang="scss">
.scene {
  perspective: 2000px;
}

.flipping-card {
  transform-style: preserve-3d;
  transform-origin: right;
  transition: transform 0.2s;
  @apply w-80 absolute top-0 h-full;

  &.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  &__face {
    position: absolute;
    height: 100%;
    backface-visibility: hidden;
  }

  &__face--back {
    transform: rotateY(180deg);
  }
}
</style>
