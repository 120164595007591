<template>
  <div class="bg-white rounded-lg shadow-md p-6">
    <r-title-with-text
      content-font-size="16px"
      :rich-text-content="contentRich"
      text-align="center"
      :title="title"
      title-font-size="24px"
    />
    <VeeForm v-slot="{ validate, meta: formMeta, handleSubmit }">
      <form
        autocomplete="on"
        @submit.prevent="handleSubmit(() => submitProfile(formMeta.valid))"
      >
        <Field
          v-slot="{ meta }"
          v-model="inputEmailData"
          name="inputEmailData"
          rules="required|email"
        >
          <r-input
            v-model="inputEmailData"
            disabled
            :error-text="$t('form.validation.email')"
            field-type="email"
            for="emailfor"
            label="E-mail"
            name="e-mailname"
            :placeholder="$t('form.placeholder.email')"
            required
            :validation-failed="meta.validated && !meta.valid"
            :validation-passed="meta.validated && meta.valid"
          />
        </Field>
        <Field
          v-slot="{ meta, errors }"
          v-model="inputFirstNameData"
          :bails="false"
          rules="required|min:1|isNotUsedCyrillic"
          name="inputFirstNameData"
        >
          <r-input
            v-model="inputFirstNameData"
            :error-text="$t('form.validation.first_name')"
            :errors="errors"
            field-type="text"
            for="enam"
            :label="$t('form.label.first_name')"
            name="firstName"
            :placeholder="$t('form.placeholder.first_name')"
            required
            :validation-failed="meta.validated && !meta.valid"
            :validation-passed="meta.validated && meta.valid"
          />
        </Field>
        <Field
          v-slot="{ meta, errors }"
          v-model="inputLastNameData"
          name="inputLastNameData"
          :bails="false"
          rules="required|min:1|isNotUsedCyrillic"
        >
          <r-input
            v-model="inputLastNameData"
            :error-text="$t('form.validation.last_name')"
            :errors="errors"
            field-type="text"
            for="enam"
            :label="$t('form.label.last_name')"
            name="lastName"
            :placeholder="$t('form.placeholder.last_name')"
            required
            :validation-failed="meta.validated && !meta.valid"
            :validation-passed="meta.validated && meta.valid"
          />
        </Field>
        <Field
          v-slot="{ meta }"
          v-model="inputPhoneTelData"
          :rules="`required|isValidTelInput:${inputPhoneTelData.isValid}`"
          name="inputPhoneTelData"
        >
          <r-input-tel
            v-model="inputPhoneTelData"
            :default-country="$getCountry()"
            :error-text="$t('form.validation.phone')"
            :label="$t('form.label.phone')"
            :placeholder="$t('form.placeholder.your_phone')"
            :prefilled-input="prefilledPhoneNumber"
            :prefilled-input-country-code="prefilledPhoneCountryCodeIso"
            :validation-failed="validateEventTriggered ? !meta.valid : null"
            :validation-passed="validateEventTriggered ? meta.valid : null"
            @focus="validateEventTriggered = true"
          />
        </Field>
        <Field
          v-slot="{ meta }"
          v-model="termsAccepted"
          :rules="{ required: { allowFalse: false } }"
          name="termsAccepted"
        >
          <r-checkbox
            id="accept"
            v-model="termsAccepted"
            :error-text="$t('form.validation.accept_terms')"
            name="checkbox"
            :validation-failed="meta.validated && !meta.valid"
          >
            <span class="sign-in-modal__checkbox inline">
              <RichText
                v-if="signInBlok.termsAndConditions"
                :blok="signInBlok.termsAndConditions"
                class="inline"
              />
            </span>
          </r-checkbox>
        </Field>
        <div class="mt-5">
          <r-button
            :disabled="!valid"
            :is-loading="profileSubmitInProgress"
            type="submit"
            width="full"
            @click="validate().then((validateEventTriggered = true))"
          >
            {{ $t('buttons.continue') }}
          </r-button>
        </div>
      </form>
    </VeeForm>
    <ValidationObserver
      ref="validator"
      v-slot="{ validate, valid, handleSubmit }"
      tag="div"
    >
      <form
        autocomplete="on"
        @submit.prevent="handleSubmit(() => submitProfile(valid))"
      >
        <div class="mt-5">
          <r-button
            :disabled="!valid"
            :is-loading="profileSubmitInProgress"
            type="submit"
            width="full"
            @click="validate().then((validateEventTriggered = true))"
          >
            {{ $t('buttons.continue') }}
          </r-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Form as VeeForm, Field } from 'vee-validate';
import { trim, get, isPlainObject } from 'lodash';

export default {
  name: 'ProfileInfoForm',
  components: { VeeForm, Field },

  props: {
    title: {
      type: String,
    },
    contentRich: {
      type: Object,
    },
  },

  data() {
    return {
      prefilledPhoneNumber: '',
      prefilledPhoneCountryCodeIso: '',
      inputPhoneTelData: {},
      inputEmailData: null,
      inputFirstNameData: null,
      inputLastNameData: null,
      termsAccepted: false,
      signInBlok: null,
      validateEventTriggered: false,
      profileSubmitInProgress: false,
    };
  },

  computed: {
    ...mapGetters({
      getGlobalBloks: 'getGlobalBloks',
      userProfile: 'users/profile',
      getCountry: 'getCountry',
      getLocale: 'getLocale',
    }),
  },

  watch: {
    userProfile() {
      this.prefillForm();
    },
  },

  created() {
    this.signInBlok = this.getGlobalBloks.SignInModal;
  },

  mounted() {
    this.prefillForm();
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
    }),

    prefillForm() {
      const profile = { ...this.userProfile };
      if (isPlainObject(profile)) {
        this.inputEmailData = profile.email;
        this.inputFirstNameData = profile.firstName;
        this.inputLastNameData = profile.lastName;
        this.prefilledPhoneNumber = profile.phoneNumber;
        this.prefilledPhoneCountryCodeIso = profile.phoneCountryCodeIso;
      }
    },

    submitProfile(valid) {
      // validate parameter for disabling initial isValidTelInput false validation
      this.validateEventTriggered = true;
      if (valid) {
        this.profileSubmitInProgress = true;
        this.actionPutProfile({
          profile: {
            firstName: trim(this.inputFirstNameData),
            lastName: trim(this.inputLastNameData),
            phoneCountryCode: get(this.inputPhoneTelData, 'countryCallingCode'),
            phoneCountryCodeIso: get(this.inputPhoneTelData, 'countryCode'),
            phoneNumber: get(this.inputPhoneTelData, 'nationalNumber'),
          },
          id: this.userProfile.id,
        }).then(() => {
          this.profileSubmitInProgress = false;
        });
      }
    },
  },
};
</script>
