<template>
  <ul v-editable="blok" class="mb-4">
    <li
      v-for="item in blok.items"
      :key="item._uid"
      v-editable="blok"
      class="flex items-start mb-2"
    >
      <div class="flex-shrink-0">
        <div :class="checkMarkClasses">
          <r-icon icon-name="check" prefix="solid" size="sm" />
        </div>
      </div>
      <p
        v-if="typeof item.item === 'string'"
        class="ml-3 text-gray-600"
        :class="textClasses"
        v-html="item.item"
      ></p>
      <RichText
        v-else-if="typeof item.item === 'object'"
        :blok="item.item"
        class="ml-3 text-gray-600"
        :class="textClasses"
        plain
      />
    </li>
  </ul>
</template>

<script>
const listStyles = {
  GREEN_CHECKMARK_BACKGROUND: 'green-check-bg',
  ORANGE_CHECKMARK_BACKGROUND: 'orange-check-bg',
};

export default {
  name: 'SnippetCheckmarkList',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    checkMarkClasses() {
      const classes = [];
      classes.push('h-7 w-7 rounded-full inline-blok flex justify-center items-center');

      if (this.blok.style === listStyles.GREEN_CHECKMARK_BACKGROUND) {
        classes.push('bg-success-bg text-success-main');
      } else if (this.blok.style === listStyles.ORANGE_CHECKMARK_BACKGROUND) {
        classes.push('bg-rendin-50 text-rendin-500');
      } else {
        classes.push('bg-none text-rendin-500');
      }

      return classes;
    },

    textClasses() {
      const classes = [];

      if (!this.blok.style) {
        classes.push('text-base font-medium');
      }

      return classes;
    },
  },
};
</script>
