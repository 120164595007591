<template>
  <div v-editable="blok" :class="classes">
    <div class="r-rich-text" v-html="content"></div>
  </div>
</template>

<script>
import { backgroundColors } from '~/utils/constants';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';

export default {
  name: 'RichTextBlok',
  props: ['blok'],
  computed: {
    content() {
      return localizedRenderRichText(this.blok.text, this.$localizedPath);
    },
    classes() {
      const classes = [];
      classes.push('r-rich-text');
      if (!this.blok.noBottomPadding) {
        classes.push('mb-8');
      }
      if (this.blok.alignText) {
        if (this.blok.alignText === 'left') classes.push('text-left');
        if (this.blok.alignText === 'center') classes.push('text-center');
        if (this.blok.alignText === 'right') classes.push('text-right');
        if (this.blok.alignText === 'justify') classes.push('text-justify');
      }

      if (this.blok.textColor) {
        if (this.blok.textColor === 'default') classes.push('text-gray-600');
        if (this.blok.textColor === 'black') classes.push('text-gray-900');
        if (this.blok.textColor === 'white') classes.push('text-white');
        if (this.blok.textColor === 'orange') classes.push('text-rendin-500');
      } else {
        classes.push('text-gray-600');
      }
      if (backgroundColors[this.blok?.backgroundColor]) {
        classes.push('px-4');
        classes.push(backgroundColors[this.blok.backgroundColor]);
      }

      if (this.blok.mobileFontSizeOptimization) {
        classes.push('r-mobile-optimized-font-size');
      }

      return classes;
    },
  },
};
</script>
<style lang="scss">
.r-rich-text .underlined {
  @apply relative inline-block;

  z-index: 1;

  &::before {
    @apply absolute block bg-no-repeat bg-contain;
    content: '';
    width: 100%;
    height: 20px;
    top: 100%;
    left: 0;
    z-index: -1;
  }

  &--short::before {
    background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-short.svg');
  }

  &--medium::before {
    background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-medium.svg');
  }

  &--long::before {
    background-image: url('/illustrations/hand-drawn-arrows/hand-drawn-underline-long.svg');
  }
}
</style>
