<template>
  <div>
    <div>
      <div class="bg-gray-100 px-6 py-4 opacity-60">
        {{ $t('administrative_rent.paid_by_tenant') }}
      </div>
      <div
        v-if="tenantSortedServicesList.length > 0"
        class="flex flex-col gap-2 px-6 py-4"
        :data-testid="'component.services-and-utilities.tenant-list'"
      >
        <div
          v-for="service in tenantSortedServicesList"
          :key="service.type"
          class="group flex justify-between w-full shadow-sm border rounded-lg px-4 py-2 bg-white cursor-pointer"
          :data-testid="'component.services-and-utilities-list.' + service.type"
        >
          <div
            class="py-2 w-full flex flex-col sm:flex-row gap-2"
            @click="emitEditService(service)"
          >
            <span class="group-hover:text-rendin-500">
              {{ servicesAndUtilitiesName(service.type, service.typeName) }}
            </span>
            <div
              v-if="service.payment?.amount || service.payment?.details"
              class="flex flex-row gap-2"
            >
              <div
                v-if="service.payment?.amount"
                class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
              >
                <span
                  class="px-2"
                  data-testid="component.services-and-utilities.amount"
                >
                  {{ localizedCurrencyFormat(service.payment.amount, getLocale) }}
                  {{ currency }}
                </span>
              </div>
              <div
                v-if="service.payment?.details"
                class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
              >
                <span
                  class="px-2"
                  data-testid="component.services-and-utilities.details"
                >
                  {{ $t('administrative_rent.payment_details') }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="hidden sm:group-hover:flex text-xl flex flex-row items-center min-w-max"
          >
            <r-icon
              class="mr-6 px-2 rounded-full hover:text-rendin-500 hover:bg-gray-100"
              data-testid="button.services-and-utilities-list.edit-service"
              icon-name="pen"
              solid-on-hover
              @click="emitEditService(service)"
              @mouseout="isPenIconSolid = false"
              @mouseover="isPenIconSolid = true"
            />
            <r-icon
              class="px-2 rounded-full"
              :class="{
                'hover:text-rendin-500 hover:bg-gray-100': !isDeleteInProgress,
              }"
              data-testid="button.services-and-utilities-list.delete-service"
              icon-name="trash"
              :solid-on-hover="!isDeleteInProgress"
              @click="isDeleteInProgress ? null : emitDeleteService(service)"
              @mouseout="isTrashIconSolid = false"
              @mouseover="isTrashIconSolid = true"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <span class="py-6 flex justify-center text-xs opacity-60">
          {{ $t('services.label.no_services_tenant') }}
        </span>
      </div>
    </div>
    <div class="pb-20">
      <div class="bg-gray-100 px-6 py-4 opacity-60">
        {{ $t('administrative_rent.paid_by_landlord') }}
      </div>
      <div
        v-if="landlordSortedServicesList.length > 0"
        class="flex flex-col gap-2 px-6 py-4"
        :data-testid="'component.services-and-utilities.landlord-list'"
      >
        <div
          v-for="service in landlordSortedServicesList"
          :key="service.type"
          class="group flex justify-between w-full shadow-sm border rounded-lg px-4 py-2 bg-white cursor-pointer"
          :data-testid="'component.services-and-utilities-list.' + service.type"
          @click="emitEditService(service)"
        >
          <div
            class="py-2 flex flex-col sm:flex-row gap-2"
            @click="emitEditService(service)"
          >
            <span class="group-hover:text-rendin-500">
              {{ servicesAndUtilitiesName(service.type, service.typeName) }}
            </span>
            <div
              v-if="service.payment?.amount || service.payment?.details"
              class="flex flex-row flex-wrap gap-2"
            >
              <div
                v-if="service.payment?.amount"
                class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
              >
                <span
                  class="px-2"
                  data-testid="component.services-and-utilities.amount"
                >
                  {{ localizedCurrencyFormat(service.payment.amount, getLocale) }}
                  {{ currency }}
                </span>
              </div>
              <div
                v-if="service.payment?.details"
                class="flex flex-nowrap justify-between items-center text-sm bg-rendin-100 rounded-lg"
              >
                <span
                  class="px-2"
                  data-testid="component.services-and-utilities.details"
                >
                  {{ $t('administrative_rent.payment_details') }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="hidden group-hover:flex text-xl flex flex-row items-center min-w-max"
          >
            <r-icon
              class="mr-6 px-2 rounded-full hover:text-rendin-500 hover:bg-gray-100"
              icon-name="pen"
              solid-on-hover
              @click="emitEditService(service)"
              @mouseout="isPenIconSolid = false"
              @mouseover="isPenIconSolid = true"
            />
            <r-icon
              class="px-2 rounded-full"
              :class="{
                'hover:text-rendin-500 hover:bg-gray-100': !isDeleteInProgress,
              }"
              icon-name="trash"
              :solid-on-hover="!isDeleteInProgress"
              @click="isDeleteInProgress ? null : emitDeleteService(service)"
              @mouseout="isTrashIconSolid = false"
              @mouseover="isTrashIconSolid = true"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <span class="py-6 flex justify-center text-xs opacity-60">
          {{ $t('services.label.no_services_landlord') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { userRole } from '~/utils/constants';
import { servicesAndUtilitiesNames } from '~/composables/servicesAndUtilitiesNames';
import { localizedCurrencyFormat } from '~/utils/localizedCurrencyFormat';

export default {
  name: 'AgreementUtilitiesListView',

  props: {
    servicesAndUtilitiesList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    currency: {
      type: String,
      default: 'EUR',
    },
    isDeleteInProgress: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['editService', 'deleteService'],

  setup() {
    return {
      localizedCurrencyFormat,
    };
  },

  data() {
    return {
      isTrashIconSolid: false,
      isPenIconSolid: false,
    };
  },

  computed: {
    ...mapGetters({
      getLocale: 'getLocale',
    }),
    tenantSortedServicesList() {
      const tenantList = [];
      for (const service of this.servicesAndUtilitiesList) {
        if (service.whoIsPaying === userRole.TENANT) {
          tenantList.push(service);
        }
      }
      return tenantList.sort((a, b) =>
        this.servicesAndUtilitiesName(a.type, a.typeName).localeCompare(
          this.servicesAndUtilitiesName(b.type, b.typeName),
        ),
      );
    },

    landlordSortedServicesList() {
      const landlordList = [];
      for (const service of this.servicesAndUtilitiesList) {
        if (service.whoIsPaying === userRole.LANDLORD) {
          landlordList.push(service);
        }
      }
      landlordList.sort((a, b) =>
        this.servicesAndUtilitiesName(a.type, a.typeName).localeCompare(
          this.servicesAndUtilitiesName(b.type, b.typeName),
        ),
      );
      return landlordList;
    },
  },

  methods: {
    servicesAndUtilitiesName(key, name) {
      return servicesAndUtilitiesNames(key, name);
    },

    emitEditService(serviceItem) {
      this.$emit('editService', serviceItem);
    },

    emitDeleteService(serviceItem) {
      this.$emit('deleteService', serviceItem);
    },
  },
};
</script>
