<template>
  <section
    class="agreement-tenant-draft-view bg-white w-full rounded-xl shadow-xl p-4 text-center"
  >
    <img alt="img" class="w-52 m-auto" src="/illustrations/list-task.svg" />
    <h2 class="text-xl font-semibold my-4">{{ $t('agreement.draft_tenant.title') }}</h2>

    <div class="text-gray-600 whitespace-pre-line mb-8">
      {{ $t('agreement.draft_tenant.content') }}
    </div>
    <r-button
      :href="$localizedPath(redirectLinks.TENANTS)"
      :label="$t('buttons.see_more')"
      width="full"
    />
  </section>
</template>
<script>
import { redirectLinks } from '~/utils/constants';

export default {
  name: 'AgreementTenantDraftView',

  setup() {
    return {
      redirectLinks,
    };
  },
};
</script>
