<template>
  <div>
    <r-dropdown v-model="showSessionMenu" align="right" width="w-80">
      <div
        class="flex flex-row items-center gap-3 cursor-pointer"
        data-testid="button.navigation.toggle-mobile-menu"
      >
        <r-icon icon-name="user" />
        <div class="flex flex-col">
          <p class="text-sm">{{ fullName }}</p>
          <p class="text-xxs uppercase text-gray-600">
            <span v-if="userRole === userRoles.TENANT">
              {{ $t('tenant') }}
            </span>
            <span v-else-if="userRole === userRoles.LANDLORD">
              {{ $t('landlord') }}
            </span>
          </p>
        </div>
        <r-icon
          class="text-xl"
          :icon-name="showSessionMenu ? 'chevron-up' : 'chevron-down'"
        />
      </div>
      <template #dropdown>
        <nav-mobile-full
          v-model:show-activity-hub="showActivityHub"
          v-model:show-language-selector="showLanguageSelector"
          class="p-4"
          @menu-item-click="showSessionMenu = false"
        />
      </template>
    </r-dropdown>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { userRole } from '~/utils/constants';
import NavMobileFull from '~/components/storyblok/NavMobileFull.vue';
import { navigationDropdownMenuTypes } from '~/utils/trackerConstants';

export default {
  name: 'SessionMenu',
  components: { NavMobileFull },
  data() {
    return {
      showSessionMenu: false,
      showLanguageSelector: false,
      showActivityHub: false,
      userRoles: userRole,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'users/userRole',
      profile: 'users/profile',
    }),
    fullName() {
      if (!this.profile) {
        return '...';
      } else if (!this.profile.firstName && !this.profile.lastName) {
        return '-----';
      } else {
        return `${this.profile.firstName} ${this.profile.lastName}`;
      }
    },
  },
  watch: {
    showSessionMenu(newValue) {
      if (!newValue) {
        this.showLanguageSelector = false;
        this.trackDropdownMenuClose({ menuType: navigationDropdownMenuTypes.DESKTOP });
      } else {
        this.trackDropdownMenuOpen({ menuType: navigationDropdownMenuTypes.DESKTOP });
      }
    },
  },
  methods: {
    ...mapActions({
      trackDropdownMenuOpen: 'tracker/trackDropdownMenuOpen',
      trackDropdownMenuClose: 'tracker/trackDropdownMenuClose',
    }),
  },
};
</script>
