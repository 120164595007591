<template>
  <section
    v-editable="blok"
    class="property-listings bg-gray-50 pb-20 relative flex-grow"
  >
    <r-banner class="hidden lg:block" :images="blok.headerImage" />
    <div class="relative lg:py-8">
      <div class="px-4">
        <PropertiesPreferenceFilter
          v-model="preferences"
          :available-geo-regions="availableGeoRegions"
          :filter-open-in-mobile="blok.preferenceFilterOpenInMobile"
          @click-clear="clearPreferences()"
          @click-search="searchListings(searchActions.SEARCH_BUTTON)"
        />
      </div>

      <!--        Loader-->
      <div
        v-if="loadingListings"
        class="h-72 flex justify-center items-center bg-gray-50 w-full p-10 mt-10"
      >
        <r-loader type="animatedLogo" />
      </div>
      <div v-else-if="noResultsFound" class="bg-gray-50 w-full">
        <div class="mx-auto max-w-5xl grid sm:grid-cols-2 sm:gap-10 px-4 py-12 mt-10">
          <div>
            <img :src="blok.no_offers_image.filename" />
          </div>
          <div class="flex flex-col justify-center">
            <h2 class="text-3xl md:text-4xl leading-tight font-bold mb-4 mt-1">
              {{ blok.no_offers_title }}
            </h2>

            <p class="text_content" v-html="blok.no_offers_text"></p>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="relative mx-auto max-w-5xl my-6 bg-white rounded-lg px-4 pt-2 pb-1">
          <div class="flex sm:flex-row flex-wrap gap-2 justify-between items-center">
            <h1 class="text-lg font-medium text-gray-500">{{ listingsHeader }}</h1>
            <div class="flex flex-wrap gap-2 md:gap-4 w-full md:w-fit">
              <div class="flex items-center w-full md:w-fit">
                <r-button
                  v-if="showListingSubscriptionComponents"
                  border-color="light-gray"
                  class="p-1"
                  color="black"
                  :icon="blok.listingSubscriptionButtonIcon || 'bell'"
                  :icon-color="blok.listingSubscriptionButtonIconColor"
                  :icon-prefix="blok.listingSubscriptionButtonIconStyle"
                  inverted
                  :label="blok.listingSubscriptionButtonTitle"
                  size="small"
                  width="full"
                  @click="openListingSubscriptionModal()"
                />
              </div>
              <r-select
                v-model="selectedSorting"
                class="border-1 rounded-full px-3 border-gray-200 mb-1 w-full md:w-fit"
                hide-keyboard
                name="agreementSorting"
                no-border
                option-label="text"
                :options="selectedSortingOptions"
                :placeholder="$t('sort_filter.sort')"
              />
            </div>
          </div>
        </div>
        <div class="mx-auto max-w-5xl px-4 lg:px-0">
          <div
            v-for="(offer, index) in sortedListings"
            :key="offer.invitationCode"
            class="mb-8"
          >
            <r-property-item
              :id="offer.invitationCode"
              :address="offer.address"
              :area-label="$t('offer.size')"
              :city="offer.city"
              :currency="offer.currency"
              :deposit-label="$t('offer.deposit')"
              :fee-label="$t('offer.rendin_fee')"
              find-listing-view
              :floor="offer.floor"
              :floor-label="$t('offer.floor')"
              :floors-total="offer.floorsTotal"
              :image="offer.images[0]"
              :link-text="blok.button_label"
              :link-url="linkToInvite(offer.invitationCode)"
              :object-area="offer.objectArea"
              :parking="offer.hasParking ? $t('yes') : $t('no')"
              :parking-label="$t('offer.parking')"
              :pets-allowed="offer.petsAllowed ? $t('yes') : $t('no')"
              :pets-label="$t('offer.pets')"
              :price="offer.price"
              :property-type="offer.propertyType"
              :rendin-fee="rendinFeeCalculation(offer.price, country ? country : 'PL')"
              :rendin-fee-percent="rendinFee"
              :rent-label="$t('offer.price')"
              :rooms="offer.rooms"
              :rooms-label="$t('offer.rooms')"
              :storage="offer.hasStorage ? $t('yes') : $t('no')"
              :storage-label="$t('offer.storage')"
            />
            <div v-if="showDynamicSection(index)" class="mt-8">
              <div
                v-for="contentBlok in blok.dynamicSectionContent.filter((item) =>
                  dynamicSectionAllowedComponents.includes(item.component),
                )"
                :id="contentBlok._uid"
                :key="contentBlok._uid"
                class="mb-8"
              >
                <ListingSubscriptionSection
                  v-if="showListingSubscriptionComponents"
                  :blok="contentBlok"
                  :search-details="preferences"
                  @button-click="listingSubscriptionButtonClick()"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <client-only>
      <ListingSubscriptionModal
        :listing-filters="preferences"
        :visible="showListingSubscriptionModal"
        @close="
          () => {
            showListingSubscriptionModal = false;
          }
        "
      />
    </client-only>
  </section>
</template>
<script>
/* HYBRID COMPONENT - SESSION & OUT OF SESSION */
import { mapActions, mapGetters } from 'vuex';
import {
  listingSubscription,
  searchTrackingEventNames,
  sortingOptionsForTracking,
} from '~/utils/trackerConstants';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation';
import { redirectLinks, sortingOptions } from '~/utils/constants';
import { defaultListingPreferences } from '~/utils/objectStructures';

const ListingSubscriptionModal = () =>
  Promise.all([import('~/components/modals/ListingSubscriptionModal.vue')]).then(
    ([FeedbackModal]) => FeedbackModal.default,
  );

export default {
  name: 'PropertyListings',
  components: { ListingSubscriptionModal },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      rendinFeeCalculation,
    };
  },
  data() {
    return {
      dynamicSectionAllowedComponents: ['ListingSubscriptionSection'],
      rendinFee: 2.5,
      /* Preferences are stored both as tenantProfile preferences (in DB) & in URL (search params) */
      /* Currently only primitives (string, number) and arrays are supported, for objects need to rewrite preferencesToUrl & preferencesFromUrl */
      preferences: defaultListingPreferences(),
      lastSearchPreferences: {
        city: '',
        districts: [],
        propertyTypes: [],
        roomsMin: null,
        roomsMax: null,
        priceMin: null,
        priceMax: null,
        areaMin: null,
        areaMax: null,
        floorMin: null,
        floorMax: null,
        sort: null,
        hasStorage: false,
        hasParking: false,
        petsAllowed: false,
      },
      profileSubmitInProgress: false,
      /* When set to true, no preferences from tenant profile will be used */
      urlOverridesTenantPreferences: false,
      initialFetchDone: false,
      isAlreadyScrolledToLastListing: false,
      showListingSubscriptionModal: false,
      searchActions: {
        INITIAL: 'Initial Load',
        SEARCH_BUTTON: 'Update Filter',
        CLEAR_PREFERENCES: 'Clear Filter',
      },
      selectedSorting: {
        text: this.$t('sort_filter.newest'),
        value: sortingOptions.NEWEST_FIRST,
        trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
      },
      selectedSortingOptions: [
        {
          text: this.$t('sort_filter.newest'),
          value: sortingOptions.NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
        },
        {
          text: this.$t('sort_filter.highest_price'),
          value: sortingOptions.HIGHER_PRICE_FIRST,
          trackingValue: sortingOptionsForTracking.HIGHER_PRICE_FIRST,
        },
        {
          text: this.$t('sort_filter.lowest_price'),
          value: sortingOptions.LOWER_PRICE_FIRST,
          trackingValue: sortingOptionsForTracking.LOWER_PRICE_FIRST,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      suggestedApartments: 'suggestions/suggestedApartments',
      suggestedApartmentsFetching: 'suggestions/suggestedApartmentsFetching',
      suggestedApartmentsFetchingFailed:
        'suggestions/suggestedApartmentsFetchingFailed',
      availableGeoRegions: 'suggestions/availableGeoRegions',
      tenantProfile: 'tenants/tenantProfileFromFirebase',
      hasTenantProfileFromFirebase: 'tenants/hasTenantProfileFromFirebase',
      hasSessionUser: 'users/hasSessionUser',
      country: 'getCountry',
      getRouterHistory: 'routerHistory/getRouterHistory',
      getPreviousDirtyPagePath: 'routerHistory/getPreviousDirtyPagePath',
    }),
    sortedListings() {
      if (this.selectedSorting?.value === sortingOptions.NEWEST_FIRST) {
        return this.listings;
      } else if (this.selectedSorting?.value === sortingOptions.HIGHER_PRICE_FIRST) {
        return [...this.listings].sort((a, b) => b.price - a.price);
      } else if (this.selectedSorting?.value === sortingOptions.LOWER_PRICE_FIRST) {
        return [...this.listings].sort((a, b) => a.price - b.price);
      }
      return this.listings;
    },
    listings() {
      if (this.suggestedApartments && this.suggestedApartments.length > 0) {
        return this.suggestedApartments;
      }
      return [];
    },
    loadingListings() {
      return this.suggestedApartmentsFetching || !this.initialFetchDone;
    },
    noResultsFound() {
      return (
        !this.suggestedApartmentsFetching &&
        Array.isArray(this.listings) &&
        this.listings.length === 0
      );
    },
    selectedRegion() {
      return this.availableGeoRegions.find(
        (region) => region.city === this.preferences.city,
      );
    },
    listingsHeader() {
      const region =
        this.lastSearchPreferences.city ||
        this.$t(this.country === 'ee' ? 'country.estonia' : 'country.poland');
      const numberOfAds = this.listings.length;

      return this.$t('propertyListings.header', {
        numberOfAds: numberOfAds,
        region: region,
      });
    },

    showListingSubscriptionComponents() {
      return !!this.preferences.city;
    },
  },
  watch: {
    selectedSorting(newValue, oldValue) {
      if (newValue.value !== oldValue.value) {
        this.preferences.sort = newValue.value.toLowerCase();
        this.preferencesToUrl();
        const props = {
          sortMethod: sortingOptionsForTracking[newValue.value],
          country: this.country,
        };

        this.trackSearchListingsEvent({
          eventName: searchTrackingEventNames.SUGGESTIONS_SORT,
          props: props,
        });
      }
    },
    tenantProfile() {
      if (!this.urlOverridesTenantPreferences) {
        this.loadPreferencesFromTenantProfile();
        this.matchPreferencesToAvailableRegions();
        this.submitTenantProfilePreferences();
        this.preferencesToUrl();
      }
    },
    /* We need a safeguard for when a region or it's districts are not available anymore in regions */
    /* When that happens, either just reset districts or both */
    availableGeoRegions() {
      this.matchPreferencesToAvailableRegions();
    },
    listings(value) {
      if (value.length > 0) {
        setTimeout(() => {
          if (this.getPreviousDirtyPagePath?.includes(redirectLinks.INVITE)) {
            const previouslyVisitedInviteId =
              this.getPreviousDirtyPagePath.split(redirectLinks.INVITE).length === 2
                ? this.getPreviousDirtyPagePath.split(redirectLinks.INVITE)[1]
                : null;
            if (
              document.getElementById(previouslyVisitedInviteId) &&
              !this.isAlreadyScrolledToLastListing
            ) {
              this.isAlreadyScrolledToLastListing = true;
              document.getElementById(previouslyVisitedInviteId).scrollIntoView({
                block: 'center',
              });
            }
          }
        }, 1);
      }
    },
  },
  created() {
    this.preferencesFromUrl();
    if (this.hasSessionUser && !this.urlOverridesTenantPreferences) {
      this.loadPreferencesFromTenantProfile();
    }
  },
  beforeMount() {
    this.searchListings(this.searchActions.INITIAL);
    this.getAvailableGeoRegions();
  },
  methods: {
    ...mapActions({
      actionPutTenantProfile: 'tenants/putTenantProfile',
      fetchSuggestedApartments: 'suggestions/fetchSuggestedApartments',
      getAvailableGeoRegions: 'suggestions/getAvailableGeoRegions',
      trackSearchListingsEvent: 'tracker/trackSearchListingsEvent',
      actionTrackListingSubscriptionTrigger: 'tracker/trackListingSubscriptionTrigger',
    }),

    showDynamicSection(index) {
      const showFirstSection = (this.blok.dynamicSectionStartIndex || 0) === index + 1; // dynamicSectionStartIndex tell as from what index show section
      const showNextSectionBasedOnFrequency =
        this.blok.dynamicSectionFrequency &&
        (index + 1 - (this.blok.dynamicSectionStartIndex || 0)) %
          this.blok.dynamicSectionFrequency ===
          0; // dynamicSectionFrequency tell as how many items should be between sections
      return showFirstSection || showNextSectionBasedOnFrequency;
    },

    openListingSubscriptionModal() {
      this.showListingSubscriptionModal = true;
      this.actionTrackListingSubscriptionTrigger({
        eventName: listingSubscription.OPEN_MODAL,
        props: {
          userPreferences: this.preferences,
        },
      });
    },

    listingSubscriptionButtonClick() {
      this.actionTrackListingSubscriptionTrigger({
        eventName: listingSubscription.SECTION_CLICK,
        props: {
          userPreferences: this.preferences,
        },
      });
      this.showListingSubscriptionModal = true;
      this.actionTrackListingSubscriptionTrigger({
        eventName: listingSubscription.OPEN_MODAL,
        props: {
          userPreferences: this.preferences,
        },
      });
    },

    setSelectedSortingFromUrl(selectedSorting) {
      for (const sorting of this.selectedSortingOptions) {
        if (selectedSorting.toUpperCase() === sorting.value) {
          this.selectedSorting = sorting;
          return;
        }
      }
    },

    matchPreferencesToAvailableRegions() {
      if (!this.hasTenantProfileFromFirebase || this.availableGeoRegions.length === 0) {
        return;
      }
      const matchingRegion = this.availableGeoRegions.find(
        (region) => region.city === this.preferences.city,
      );
      /* City check */
      if (matchingRegion === undefined) {
        this.preferences.city = '';
        this.preferences.districts = [];
      } else if (
        /* District check */
        matchingRegion.districts.filter((district) =>
          this.preferences.districts.includes(district),
        ).length !== this.preferences.districts.length
      ) {
        this.preferences.districts = [];
      } else {
        return;
      }
      this.preferencesToUrl();
    },
    searchListings(searchAction) {
      this.normalizePreferencesToNumber();
      this.preferencesToUrl();
      const searchParameters = this.copyPreferences();
      this.fetchSuggestedApartments(searchParameters).then((results) => {
        const props = {
          resultsCount: results.length,
          country: this.country,
          searchAction: searchAction,
          results: results.map((result) => result.invitationCode),
          ...this.preferences,
        };
        this.initialFetchDone = true;
        this.lastSearchPreferences = searchParameters;
        return this.trackSearchListingsEvent({
          eventName: searchTrackingEventNames.SEARCH_LISTINGS,
          props: props,
        });
      });

      if (this.hasSessionUser && this.initialFetchDone) {
        this.submitTenantProfilePreferences();
      }
    },
    clearPreferences() {
      const defaultValues = defaultListingPreferences();
      for (let i = 0; i < Object.keys(defaultValues).length; i++) {
        const key = Object.keys(defaultValues)[i];
        this.preferences[key] = defaultValues[key];
      }

      this.preferencesToUrl();
      this.searchListings(this.searchActions.CLEAR_PREFERENCES);
    },
    linkToInvite(id) {
      return this.$localizedPath(`/invite/form/${id}`);
    },
    normalizePreferencesToNumber() {
      this.preferences.roomsMin = Number(this.preferences.roomsMin) || null;
      this.preferences.roomsMax = Number(this.preferences.roomsMax) || null;
      this.preferences.priceMin = Number(this.preferences.priceMin) || null;
      this.preferences.priceMax = Number(this.preferences.priceMax) || null;
      this.preferences.areaMin = Number(this.preferences.areaMin) || null;
      this.preferences.areaMax = Number(this.preferences.areaMax) || null;
      this.preferences.floorMin = Number(this.preferences.floorMin) || null;
      this.preferences.floorMax = Number(this.preferences.floorMax) || null;
    },
    loadPreferencesFromTenantProfile() {
      if (this.tenantProfile) {
        if (this.tenantProfile.city) this.preferences.city = this.tenantProfile.city;
        if (this.tenantProfile.districts)
          this.preferences.districts = this.tenantProfile.districts;
        if (this.tenantProfile.roomsMin)
          this.preferences.roomsMin = this.tenantProfile.roomsMin;
        if (this.tenantProfile.roomsMax)
          this.preferences.roomsMax = this.tenantProfile.roomsMax;
        if (this.tenantProfile.priceMin)
          this.preferences.priceMin = this.tenantProfile.priceMin;
        if (this.tenantProfile.priceMax)
          this.preferences.priceMax = this.tenantProfile.priceMax;
      }
    },
    submitTenantProfilePreferences() {
      if (this.profileSubmitInProgress) {
        return;
      }

      this.profileSubmitInProgress = true;
      this.actionPutTenantProfile(this.preferences).then(() => {
        this.profileSubmitInProgress = false;
      });
    },
    preferencesFromUrl() {
      /* We check if some of the preferences is encoded in the URL and try to parse them */
      Object.keys(this.preferences).forEach((key) => {
        if (this.$route.query[key]) {
          this.urlOverridesTenantPreferences = true;
          if (Array.isArray(this.preferences[key])) {
            const parsedArray = JSON.parse(this.$route.query[key]);
            if (parsedArray) {
              this.preferences[key] = [...parsedArray];
            }
          } else if (Number.isFinite(Number(this.$route.query[key]))) {
            this.preferences[key] = Number(this.$route.query[key]);
          } else if (
            typeof this.fromStringToBoolean(this.$route.query[key]) === 'boolean'
          ) {
            this.preferences[key] = this.fromStringToBoolean(this.$route.query[key]);
          } else {
            this.preferences[key] = this.$route.query[key];
            if (key === 'sort') {
              this.setSelectedSortingFromUrl(this.$route.query[key]);
            }
          }
        }
      });
    },
    preferencesToUrl() {
      /* We check if some of the preferences is encoded in the URL and try to parse them */
      const query = {};
      Object.keys(this.preferences).forEach((key) => {
        if (this.preferences[key]) {
          if (Array.isArray(this.preferences[key])) {
            if (this.preferences[key].length === 0) {
              query[key] = undefined;
              return;
            }

            const json = JSON.stringify(this.preferences[key]);
            query[key] = json;
          } else {
            query[key] = this.preferences[key];
          }
        } else {
          query[key] = undefined;
        }
      });
      this.$router
        .replace({ path: this.$route.path, query: { ...this.$route.query, ...query } })
        .catch(() => {
          /* DUMMY CATCH */
          // TODO - better solution, needed because we don't check if query has changed or not (same path throws error from Vue router)
        });
    },
    /* Store preferences when a search is performed */
    copyPreferences() {
      if (this.preferences.roomsMax === 5) {
        return {
          ...this.preferences,
          districts: [...this.preferences.districts],
          roomsMax: null,
        };
      } else {
        return { ...this.preferences, districts: [...this.preferences.districts] };
      }
    },

    fromStringToBoolean(value) {
      if (value.toLowerCase() === 'true') {
        return true;
      } else if (value.toLowerCase() === 'false') {
        return false;
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss">
.property-listings {
  .text_content a {
    @apply text-rendin-500 hover:text-rendin-600 hover:underline focus:text-rendin-600 focus:underline;
  }

  .r-select {
    .vs__selected-options {
      min-width: 180px;
    }
  }
}
</style>
