import { toUpper, get } from 'lodash';

/**
 * @typedef {{
 *   city: string,
 *   adsCount:  number,
 *   showDistricts:  boolean,
 *   districts:  string[],
 * }} AdSearchGeoRegionPublic
 */

export const state = () => ({
  suggestedApartments: null,
  suggestedApartmentsInitialList: null,
  fetchSuggestedApartmentsRequestsInProgress: 0,
  suggestedApartmentsFetchingFailed: false,
  /**
   @returns AdSearchGeoRegionPublic[]
   */
  availableGeoRegions: [],
});

export const getters = {
  /**
   @returns AdSearchGeoRegionPublic[]
   */
  availableGeoRegions: (state) => state.availableGeoRegions,
  suggestedApartments: (state) => state.suggestedApartments,
  suggestedApartmentsInitialList: (state) => state.suggestedApartmentsInitialList,
  suggestedApartmentsFetching: (state) =>
    state.fetchSuggestedApartmentsRequestsInProgress > 0,
  suggestedApartmentsFetchingFailed: (state) => state.suggestedApartmentsFetchingFailed,
};

export const mutations = {
  setAvailableGeoRegions: function (state, availableGeoRegions) {
    state.availableGeoRegions = [...availableGeoRegions];
  },
  setSuggestedApartments: function (state, suggestedApartments = []) {
    state.suggestedApartments = [...suggestedApartments];
  },
  setSuggestedApartmentsInitialList: function (
    state,
    suggestedApartmentsInitialList = []
  ) {
    state.suggestedApartmentsInitialList = [...suggestedApartmentsInitialList];
  },
  setSuggestedApartmentsFetching: function (state, isFetching) {
    if (isFetching) {
      state.fetchSuggestedApartmentsRequestsInProgress += 1;
    } else {
      state.fetchSuggestedApartmentsRequestsInProgress -= 1;
    }
  },
  setSuggestedApartmentsFetchingFailed: function (state, isFailure) {
    state.suggestedApartmentsFetchingFailed = isFailure;
  },
};

export const actions = {
  getSimilarSuggestionsForAdvertisement: function (
    { commit, dispatch },
    { advertisementId }
  ) {
    const requestData = {
      advertisementId: advertisementId,
    };

    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'getSearchAdvertisementSimilar', data: requestData },
          { root: true }
        )
      )
      .then(({ data }) => data.foundApartments)
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        throw err;
      });
  },
  getAvailableGeoRegions: function ({ commit, dispatch, rootGetters }) {
    const requestData = {
      country: toUpper(rootGetters.getCountry),
    };
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'getAdSearchAvailableGeoRegions', data: requestData },
          { root: true }
        )
      )
      .then(({ data }) => {
        const availableGeoRegions = data;
        if (!Array.isArray(availableGeoRegions) || availableGeoRegions.length === 0) {
          dispatch(
            'tracker/reportErrorToSentry',
            new Error(
              `getAdSearchAvailableGeoRegions: no regions found for country: '${requestData.country}'`
            ),
            { root: true }
          );
          return Promise.resolve(null);
        }

        /* Order regions by adsCount DESC, city DESC */
        availableGeoRegions.sort(function (a, b) {
          if (a.adsCount === b.adsCount) {
            return a.city.localeCompare(b.city);
          }
          return b.adsCount - a.adsCount;
        });

        commit('setAvailableGeoRegions', availableGeoRegions);
        return Promise.resolve(availableGeoRegions);
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        throw err;
      });
  },
  fetchSuggestedApartments: function (
    { commit, dispatch, rootGetters },
    { areaMax, areaMin, priceMin, priceMax, roomsMin, roomsMax, city, districts, maxReturn, initialList, floorMin, floorMax, hasParking, hasStorage, petsAllowed, propertyTypes }
  ) {
    commit('setSuggestedApartmentsFetching', true);
    commit('setSuggestedApartmentsFetchingFailed', false);
    const request = {
      data: {
        areaMax: Number(areaMax) || undefined,
        areaMin: Number(areaMin) || undefined,
        priceMin: Number(priceMin) || undefined,
        priceMax: Number(priceMax) || undefined,
        roomsMin: Number(roomsMin) || undefined,
        roomsMax: Number(roomsMax) || undefined,
        floorMin: Number(floorMin) || undefined,
        floorMax: Number(floorMax) || undefined,
        city: city,
        districts: districts,
        propertyTypes: propertyTypes ?? [],
        country: toUpper(rootGetters.getCountry),
        maxReturn: maxReturn,
        hasParking: hasParking,
        hasStorage: hasStorage,
        petsAllowed: petsAllowed,
      },
    };
    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          { functionName: 'getSearchApartments', data: request.data },
          { root: true }
        )
      )
      .then(({ data }) => {
        const result = get(data, 'foundApartments', null);
        if (!result || result.length === 0) {
          dispatch(
            'tracker/reportErrorToSentry',
            new Error(
              `fetchSuggestedApartments: no apartments found for: priceMin: '${priceMin}' priceMax: '${priceMax}' roomsMin: '${roomsMin}' roomsMax: '${roomsMax}'  city: '${city}' districts: '${districts.toString()}' country '${toUpper(
                rootGetters.getCountry
              )}'`
            ),
            { root: true }
          );
        }
        if (!initialList) commit('setSuggestedApartments', result);
        if (initialList) commit('setSuggestedApartmentsInitialList', result);
        return result;
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        commit('setSuggestedApartmentsFetchingFailed', true);
        throw err;
      })
      .finally(() => commit('setSuggestedApartmentsFetching', false));
  },
};
