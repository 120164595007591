import {
  requestKeys,
  useVuexFirebaseRequests,
} from '~/components/composables/useVuexFirebaseRequests';
import { agreementSourceTypes } from '~/utils/constants.js';

const requestGetAnnex = 'getAnnex';
const requestPostAnnex = 'postAnnex';

const requests = [requestGetAnnex, requestPostAnnex];

const firebaseRequestsSetup = useVuexFirebaseRequests(requests);

export const state = () => ({ ...firebaseRequestsSetup.state });
export const getters = { ...firebaseRequestsSetup.getters };
export const mutations = { ...firebaseRequestsSetup.mutations };

export const actions = {
  ...firebaseRequestsSetup.actions,
  /**
   * @param {string} agreementId
   * @param {string} annexId
   */
  getAnnex: function ({ dispatch }, { agreementId, annexId }) {
    const getAnnexKeys = requestKeys(requestGetAnnex);
    const request = {
      source: agreementSourceTypes.WEB,
      agreementId: agreementId,
      annexId: annexId,
    };
    return dispatch(getAnnexKeys.ACTION_MAKE_REQUEST, { request: request });
  },

  /**
   * @param {string} agreementId
   * @param {string} endDate
   */
  postAnnex: function ({ dispatch }, { agreementId, endDate }) {
    const postAnnexKeys = requestKeys(requestPostAnnex);
    const changes = {
      endDate: endDate,
    };
    const request = {
      source: agreementSourceTypes.WEB,
      agreementId: agreementId,
      changes: changes,
    };

    return dispatch(postAnnexKeys.ACTION_MAKE_REQUEST, {
      request: request,
    });
  },
};
