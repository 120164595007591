<template>
  <section class="pb-6">
    <h1 class="font-medium text-xl">{{ $t('dialog.general_terms.title') }}</h1>
    <p class="text-gray-600 mt-2 mb-4">{{ $t('dialog.general_terms.subtitle') }}</p>
    <r-accordion-group
      :active-index="accordionGroupActiveIndex"
      flavor="flat"
      :accordions="terms"
      @switch="(index) => openTerm(index)"
    >
      <template #no_smoking>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.no_smoking_text')"
        ></div>
      </template>

      <template #handover_act>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.handover_act_text')"
        ></div>
      </template>
      <template #rental_payments>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.rental_payments_text')"
        ></div>
      </template>
      <template #rendin_fee>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.rendin_fee_text')"
        ></div>
      </template>

      <template #office_bad>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.office_bad_text')"
        ></div>
      </template>

      <template #depreciation>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.depreciation_text')"
        ></div>
      </template>

      <template #clean_up>
        <div
          class="break-words whitespace-pre-line text-sm"
          v-html="$t('dialog.general_terms.clean_up_text')"
        ></div>
      </template>
    </r-accordion-group>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';

export default {
  name: 'RendinGeneralTermsModal',

  props: {
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      accordionGroupActiveIndex: -1,
      previousAccordionGroupActiveIndex: null,
      trackingProcessNames: trackingProcessNames,
      agreementTrackingEventNames: agreementTrackingEventNames,
    };
  },

  computed: {
    terms() {
      return [
        {
          id: 'no_smoking',
          icon: 'ban-smoking',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.no_smoking_title'),
        },
        {
          id: 'handover_act',
          icon: 'copy',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.handover_act_title'),
        },
        {
          id: 'rental_payments',
          icon: 'people-arrows',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.rental_payments_title'),
        },
        {
          id: 'rendin_fee',
          icon: 'file-invoice-dollar',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.rendin_fee_title'),
        },
        {
          id: 'office_bad',
          icon: 'building',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.office_bad_title'),
        },
        {
          id: 'depreciation',
          icon: 'couch',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.depreciation_title'),
        },
        {
          id: 'clean_up',
          icon: 'broom',
          iconClass: 'text-rendin-500 text-xl',
          title: this.$t('dialog.general_terms.clean_up_title'),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    openTerm(index) {
      this.accordionGroupActiveIndex = index;
      if (index > 0) this.previousAccordionGroupActiveIndex = index;

      const proprties = {
        action: this.accordionGroupActiveIndex === -1 ? 'Close' : 'Open',
        termName:
          this.accordionGroupActiveIndex === -1
            ? this.previousAccordionGroupActiveIndex > 0
              ? this.terms[this.previousAccordionGroupActiveIndex].id
              : 'unknown'
            : this.terms[this.accordionGroupActiveIndex].id,
      };

      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.INSPECT_GENERAL_TERMS,
        proprties,
      );
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
};
</script>
